import { Checkbox, Transfer } from 'antd';
import FormIcon from 'components/layouts/FormIcon';
import moment from 'moment';
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';

const StepInspection = (props) => {
    const {
        changeInspection,
        checkedOldInspection,
        data,
        handleChange,
        inspections,
        segments,
        targetKeys,
        selectedKeys,
        onSelectChange,
        onChange,
        users,
        user_code
    } = props;
    return (
        <>
            <Checkbox className='mt-4 mb-3' onChange={changeInspection} checked={checkedOldInspection}>
                Editar una inspección existente (Sólo se pueden editar las inspecciones que no han sido cerradas).
            </Checkbox>
            {
                checkedOldInspection ? <>
                    <Row className='mb-3 mt-4'>
                        <FormIcon title='Fecha inicial' type='date' name='created_at_start' defaultValue={data?.created_at_start}
                            value={data?.created_at_start} handleChange={handleChange} />
                        <FormIcon title='Fecha final' type='date' name='created_at_end' defaultValue={data?.created_at_end}
                            value={data?.created_at_end} handleChange={handleChange} />
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <Form.Label>Inspecciones</Form.Label>
                            <select name='inspection_id' className='form-control' defaultValue={data?.inspection_id}
                                value={data?.inspection_id} onChange={handleChange}>
                                <option value={0}>Seleccione una opción</option>
                                {
                                    inspections.map((item) =>
                                        <option key={item.id} value={item.id}>
                                            #{item.id} | Creada por: {item.created_by?.first_name} {item.created_by?.last_name} | Creada en: {moment(item.creation_date).format('LLL')}
                                        </option>
                                    )
                                }
                            </select>
                        </Col>
                    </Row>
                </> : <div className='mb-4'>
                    <h4>Seleccionar los tramos de inspección</h4>
                    {/* Gerente responsable de atender los incidentes, este dato no es editable */}
                    <Row className='mb-3'>
                        <Col>
                            <Form.Label>{user_code}</Form.Label>
                            <select name='responsable_id' className='form-control' defaultValue={data?.responsable_id}
                                value={data?.responsable_id} onChange={handleChange} disabled>
                                <option value={0}>Seleccione una opción</option>
                                {
                                    users.map((user) =>
                                        <option key={user.id} value={user.id}>
                                            {user.first_name} {user.last_name}
                                        </option>
                                    )
                                }
                            </select>
                        </Col>
                    </Row>
                    <Transfer
                        listStyle={{
                            width: 800,
                            height: 400,
                        }}
                        dataSource={segments}
                        titles={['Segmentos disponibles', 'Segmentos a inspeccionar']}
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={onChange}
                        onSelectChange={onSelectChange}
                        render={item => item.title}
                    />
                </div>
            }
        </>
    )
}

export default StepInspection