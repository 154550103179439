import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexAssetsEvaluations = async ({ project_id, table, params }) => {
    const url = `/projects/${project_id}/${table}_evaluations`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: assets_evaluations.js:14 ~ indexAssetsBaseLines ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const showAssetsEvaluations = async ({ project_id, table, id }) => {
    const url = `/projects/${project_id}/${table}_evaluations/${id}`;
    console.log(url)
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: assets_evaluations.js:32 ~ showAssetsEvaluations ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}