import React, { useEffect } from 'react';
import './Billing.scss';
import ClientsComp from './Clients/Index';
import InvoicesComp from './Invoices/Index';
import OrganizationsComp from './Organizations/Index';
import TabsComp from 'components/layouts/Tabs';
import { Heading } from '@chakra-ui/react';

const Billing = (props) => {
    const { code, name } = props;
    useEffect(() => {
        return () => {
            document.getElementById('contenido-id').classList.remove('back-fff');
        }
    }, [])

    return (
        <div className="body_">
            <Heading className='tituloSub'>{code} - {name}</Heading>

            <TabsComp
                tablist={['Facturas', 'Clientes', 'Organizaciones',]}
                tabpanels={[
                    <InvoicesComp key={'invoices-key'}/>,
                    <ClientsComp key={'clients-key'}/>,
                    <OrganizationsComp key={'organizations-key'}/>
                ]}
            />
        </div>
    )
}

export default Billing;
