import React, { useEffect, useState } from 'react'
import BreadCrumbRedux from './generales/BreadCrumbRedux'
import { Grid, Heading } from '@chakra-ui/react'
import { Skeleton } from 'antd'
import PaginationComp from './layouts/Pagination'
import { indexApps } from 'api/general/apps'
import { useSelector } from 'react-redux'
import Card from './Apps/Card';

const Apps = () => {
	const package_id = useSelector(state => state.login.package_id);

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [pagination, setPagination] = useState({
		page: 1, pageSize: 10, totalItems: 0
	});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const { response } = await indexApps({ package_id });
			const { data, totalItems } = response;
			setData(data);
			setPagination(prevState => ({ ...prevState, totalItems }));
			setLoading(false);
		}
		getData();
	}, []);

	const changePage = (page, pageSize) =>
		setPagination(prevState => ({ ...prevState, page, pageSize }));
	return (
		<div>
			<BreadCrumbRedux here="APPS" />
			<Heading className='tituloSub'>Aplicaciones de Victum SGA</Heading>
			<Grid
				templateColumns={{
					sm: "repeat(1, 1fr)",
					md: "repeat(2, 1fr)",
					lg: "repeat(3, 1fr)",
					xl: "repeat(5, 1fr)"
				}}
				gap={4}
				p={4}
				bgColor={'#FFF'}
			>
				{
					loading ? <Skeleton active /> : <>
						{
							data.map(item => <Card key={item.id} item={item} />)
						}
					</>
				}

			</Grid>
			<PaginationComp
				current={pagination.page}
				totalItems={pagination.totalItems}
				defaultPageSize={pagination.pageSize}
				onChange={changePage}
			/>
		</div>
	)
}



export default Apps