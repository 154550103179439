export const stateLogin = {
    session: null,
    dataUser: {},
    package_id: -1
}

export const stateProyectos = {
    projects: [],
    project_id: -1,
    modules: [],
    stages: [],
    stageChoosed: null,
    moduleChoosed: null,
    standardsO: [],
    standardOChoosed: -1,
    standardMChoosed: -1,
    standardsM: [],
    componentActive: null
}

/** BreadCrumb Proyectos */
export const stateBreadCrumb = {
    pathBC: [{ path: '/', key: 'root', level: 'root' }]
}

/** BreadCrumb Ajustes */
export const stateBreadCrumbAjustes = {
    pathBC: [{ path: '/', key: 'root', level: 'root' }],
    option: -1,
    componentActive: null,
    options: [
        {
            title: 'Usuarios',
            permission: 'WAA',
            sub: [
                { title: 'Administrar datos generales de usuarios', component: 'users', permission: 'WAAA' },
                { title: 'Administrar los roles de usuario', component: 'roles', permission: 'WAAB' }
            ]
        }, {
            title: 'Estándares',
            permission: 'WAB',
            sub: [
                { title: 'Registro de Suministros', component: 'supplies', permission: 'WABA' },
                { title: 'Presupuestos', component: 'budgets', permission: 'WABB' }
            ]
        }, {
            title: 'Seguridad',
            permission: 'WAC',
            sub: [
                { title: 'Autenticación en dos pasos', component: 'two_factor_auth', permission: 'WACA' },
                // { title: 'Cambiar contraseña', component: 'change_password' },
            ]
        }
    ],
}

/**
 * 	Estructura de la tabla de la que se lee la información
 * 	key: Nombre del atributo de la tabla
 * 	label: Etiqueta con la que se identificará en el formulario o tabla
 * 	description: Información adicional para mostrar en tooltips, PENDIENTE
 *  table: Los elementos con el valor TRUE se muestran en la tabla
 **/

export const stateItemsAssets = {
    its: [
        { key: '#', label: '#', description: '', table: true, width: 10 },
        { key: 'type_id', label: 'Tipo de elemento', description: '', table: true, hidden: true, width: 15 },
        { key: 'code', label: 'ID Registro', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true, pdf: true },
        { key: 'km', label: 'Cadenamiento', description: '', table: true, edit: true, add: true, type: 'number', width: 15, xlsx: true, pdf: true },
        { key: 'direction', label: 'Sentido', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true, pdf: false },
        { key: 'body', label: 'Cuerpo', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true, pdf: true },
        { key: 'latitude', label: 'Latitud', description: '', table: true, edit: true, width: 15, xlsx: true },
        { key: 'longitude', label: 'Longitud', description: '', table: true, edit: true, width: 15, xlsx: true },
        { key: 'assets_base_line_id', label: 'Línea base', description: '', table: true, width: 15, xlsx: true },
        { key: 'check_status', label: 'Estado de Verificación', description: '', table: true, width: 20, xlsx: true, pdf: true },
        { key: 'type_name', label: 'Tipo de Elemento', description: '', width: 20, xlsx: true, pdf: true },
        { key: 'id', label: 'ID', description: '', width: 10, xlsx: true },
        { key: 'project_id', label: 'ID Autopista', description: '', hidden: true, width: 15 },
        { key: 'segment_id', label: 'Segmento', description: '', hidden: true, width: 15, xlsx: true, pdf: true },
        { key: 'tollbooth', label: 'Plaza', description: '', edit: true, add: true, type: 'text', width: 15, xlsx: true, pdf: false },
        { key: 'description', label: 'Descripción', description: '', edit: true, add: true, type: 'text', width: 30, xlsx: true },
        { key: 'altitude', label: 'Altitud', description: '', edit: true, width: 15, xlsx: true },
        { key: 'others', label: 'Otros', description: '', edit: true, add: true, type: 'textarea', width: 15, xlsx: true },
        { key: 'created_at', label: 'Creado en', description: '', width: 30, xlsx: true },
        { key: 'updated_at', label: 'Actualizado en', description: '', width: 30, xlsx: true },
        { key: 'removed', label: 'Visibilidad', description: '', hidden: true, width: 15 },
        { key: 'extra', label: 'Valores Adicionales', description: '', hidden: true, width: 40, xlsx: true },
        { key: 'component_id', hidden: true },
        { key: 'monitoring_id', hidden: true },
    ],
    infrastructure: [],
    edification: [],
    environment: []
}

export const stateAjustes = {
}