import Axios from 'axios';
import React from 'react';
import { Modal, Button, Container, Row, Col, InputGroup, FormControl, OverlayTrigger, Popover, Form } from 'react-bootstrap'
import { __SERVER__ } from 'server/info';
import Swal from 'sweetalert2';
import { Variables } from 'variables/Variables';

class MyOverlay extends React.Component {
    constructor(props) {
        super(props)
    }

    render = () => {
        return (
            <OverlayTrigger trigger="click" placement="right" overlay={
                <Popover id="popover-basic">
                    <Popover.Title as="h3">{this.props.title}</Popover.Title>
                    <Popover.Content> {this.props.description} </Popover.Content>
                </Popover>
            }>
                <Button variant="outline-info">?</Button>
            </OverlayTrigger>
        )
    }
}
class RowFields extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rowFields_criterio: [],
            rowFields_pum: [],
            refresh: false
        }
    }

    componentDidMount = () => {
        this.setState({
            rowFields_criterio: this.props.rowFields_criterio,
            rowFields_pum: this.props.rowFields_pum
        })
    }

    render = () => {
        console.log("row fields criterio: ", this.state.rowFields_criterio)
        return (
            <Form.Row>
                <Col xs={3}>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        placeholder={'Nombre'}
                        readOnly={(this.props.readonly === 1 ? true : false)}
                        value={(this.props.type === 'criterio') ?
                            this.state.rowFields_criterio.name
                            : this.state.rowFields_pum.name}
                        onChange={(e) => {
                            if (this.props.type === 'criterio') {
                                this.state.rowFields_criterio.name = e.target.value
                            } else {
                                this.state.rowFields_pum.name = e.target.value
                            }
                            this.setState({ refresh: !this.state.refresh });
                        }}
                    />
                </Col>
                <Col xs={3}>
                    <Form.Label>Descripción</Form.Label>
                    <FormControl
                        placeholder="Descripción"
                        readOnly={(this.props.readonly === 1 ? true : false)}
                        value={(this.props.type === 'criterio') ?
                            this.state.rowFields_criterio.description
                            : this.state.rowFields_pum.description}
                        onChange={(e) => {
                            if (this.props.type === 'criterio') {
                                this.state.rowFields_criterio.description = e.target.value
                            } else {
                                this.state.rowFields_pum.description = e.target.value
                            }
                            this.setState({ refresh: !this.state.refresh });
                        }}
                    />
                </Col>
                <Col xs={3}>
                    <Form.Label>Valor</Form.Label>
                    <FormControl
                        placeholder="Valor"
                        readOnly={(this.props.readonly === 1 ? true : false)}
                        value={(this.props.type === 'criterio') ?
                            this.state.rowFields_criterio.value
                            : this.state.rowFields_pum.value}
                        onChange={(e) => {
                            if (this.props.type === 'criterio') {
                                this.state.rowFields_criterio.value = e.target.value
                            } else {
                                this.state.rowFields_pum.value = e.target.value
                            }
                            this.setState({ refresh: !this.state.refresh });
                        }}
                    />
                </Col>
                <Col xs={3}>
                    <Form.Label>Sólo lectura</Form.Label>
                    <Form.Control as="select" disabled={(this.props.readonly === 1 ? true : false)} onChange={(e) => {
                        if (this.props.type === 'criterio') {
                            this.state.rowFields_criterio.readonly = e.target.value
                        } else {
                            this.state.rowFields_pum.readonly = e.target.value
                        }
                        this.setState({ refresh: !this.state.refresh });
                    }}>
                        <option key={this.props.readonly === 1 ? 1 : 0} value={this.props.readonly === 1 ? 1 : 0}>{this.props.readonly === 1 ? 'Si' : 'No'}</option>
                        <option key={this.props.readonly === 1 ? 0 : 1} value={this.props.readonly === 1 ? 0 : 1}>{this.props.readonly === 1 ? 'No' : 'Si'}</option>
                    </Form.Control>
                </Col>
            </Form.Row>
        )
    }

}
class Deductiva extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true,
            valuesCum: new Array(this.props.cump.length).fill(0),
            refresh: false,
            valuesPum: new Array(this.props.pum.length).fill(0),
            formula_criterio: '',
            formula_pum: '',
            rowFields_criterio: [],
            rowFields_pum: []
        }
        // console.log('Cum -> ', this.props.cump)
    }

    componentDidMount = () => {
        // setTimeout(() => this.setState({ show: true }), 100);
        // console.log('valuesCum -> ', this.state.valuesCum.length)

        // Obtenemos y asignamos a array valores de cumplimiento
        for (let i = 0; i < this.props.cump.length; i++) {
            if (this.props.cump[i].value !== 0) {
                this.state.valuesCum[i] = this.props.cump[i].value
            }
        }
        // Obtenemos y asignamos a array valores de pum
        for (let j = 0; j < this.props.pum.length; j++) {
            if (this.props.pum[j].value !== 0) {
                this.state.valuesPum[j] = this.props.pum[j].value
            }
        }
        // console.log('valuesCum2 -> ', this.state.valuesCum.length)
        this.setState({ refresh: !this.state.refresh });

        this.addRow('criterio', {
            name: '%Cump',
            description: 'Porcentaje de cumplimiento',
            value: 0,
            readonly: 1
        }) //Inicializar con una variable la formula de criterio
        this.addRow('pum', {
            name: 'PUM',
            description: 'Cálculo al PUM',
            value: 0,
            readonly: 1
        })      //Inicializar con una variable la formula de pum
    }

    // Agrega campos para una variable para pum y criterio
    addRow = (type, data) => {
        let row = (data == null) ? {
            name: '',
            description: '',
            value: 0,
            readonly: 0
        } : data
        if (type === 'criterio') {
            this.state.rowFields_criterio.push(row)
        } else {
            this.state.rowFields_pum.push(row)
        }
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    async addFormulasToDB(type, formula, vars) {
        let formula_id = 0
        await Axios.post(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation', {
            type: type, formula: formula
        }).then(response => {
            //console.log('ID -> ', response.data[0])
            formula_id = response.data[0]
        }).catch(error => {
            console.log(error)
        });

        for (let i = 0; i < vars.length; i++) {
            await Axios.post(__SERVER__ + '/formulas_operation/' + formula_id + '/variables', {
                field_name: vars[i].name,
                value: vars[i].value,
                description: vars[i].description,
                readonly: vars[i].readonly
            }).then(response => {
                console.log(response)
            }).catch(error => {
                console.log(error)
            })
        }
        this.setState({ refresh: !this.state.refresh })
    }

    render = () => {
        let valuesCum = this.state.valuesCum
        let valuesPum = this.state.valuesPum
        console.log('RENDER -> ', this.props)
        return (
            <Modal //Propiedades del modal
                animation={true}
                show={this.state.show}
                onHide={this.handleClose}
                size="xl"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {this.props.hayFormula === false ?
                        <Modal.Title>{this.props.title} - Agregar formulas</Modal.Title>
                        :
                        <Modal.Title>{this.props.title} - Cálculo de Deductivas</Modal.Title>
                    }
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        {this.props.hayFormula === false ?
                            <Row>
                                <Col>
                                    <strong>Criterio de cumplimiento</strong><br />
                                    <Form>
                                        <Form.Row>
                                            <Col><Form.Label> %Cump = </Form.Label></Col>
                                            <Col xs={9}>
                                                <InputGroup className="mb-3">
                                                    <FormControl
                                                        placeholder={"Fórmula"}
                                                        onChange={(e) => {
                                                            this.state.formula_criterio = e.target.value;
                                                            this.setState({ refresh: !this.state.refresh });
                                                            //console.log(this.state.formula_criterio)
                                                        }}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <MyOverlay
                                                            title={"Criterio de cumplimiento"}
                                                            description={"Escriba la fórmula de criterio de cumplimiento"}>
                                                        </MyOverlay>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                            </Col>

                                        </Form.Row>
                                        {
                                            this.state.rowFields_criterio.map((row, i) =>
                                                <RowFields key={i} type={'criterio'} index={i} readonly={row.readonly}
                                                    rowFields_criterio={row} />
                                            )
                                        }
                                        <Button variant="outline-primary" onClick={() => {
                                            this.addRow('criterio', null)
                                            this.setState({ refresh: !this.state.refresh })
                                        }}>Agregar variable</Button>
                                    </Form>

                                </Col>
                                <Col>
                                    <strong>Cálculo del PUM al MRO</strong><br />
                                    <Form>
                                        <Form.Row>
                                            <Col><Form.Label> PUM = </Form.Label></Col>
                                            <Col xs={9}>
                                                <InputGroup className="mb-3">
                                                    <FormControl
                                                        placeholder={"Fórmula"}
                                                        onChange={(e) => {
                                                            this.state.formula_pum = e.target.value;
                                                            this.setState({ refresh: !this.state.refresh });
                                                            //console.log(this.state.formula_pum)
                                                        }}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <MyOverlay
                                                            title={"Cálculo al PUM"}
                                                            description={"Escriba la fórmula del cálculo al PUM"}>
                                                        </MyOverlay>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                            </Col>

                                        </Form.Row>

                                        {
                                            this.state.rowFields_pum.map((row, i) =>
                                                <RowFields key={i} type={'pum'} index={i} readonly={row.readonly}
                                                    rowFields_pum={row} />
                                            )
                                        }
                                        <Button variant="outline-primary" onClick={() => {
                                            this.addRow('pum', null)
                                            this.setState({ refresh: !this.state.refresh })
                                        }}>Agregar variable</Button>
                                    </Form>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>   {/* Criterio de cumplimiento */}
                                    <strong>Criterio de cumplimiento</strong><br />
                                    <strong>%Cump</strong> = {this.props.formulaCump}
                                    {this.props.cump.map((item, i) =>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder={item.field_name}
                                                value={valuesCum[i] === 0 ? '' : valuesCum[i]}
                                                readOnly={item.readonly}
                                                onChange={(e) => {
                                                    this.state.valuesCum[i] = e.target.value;
                                                    this.setState({ refresh: !this.state.refresh });
                                                }}
                                            />
                                            <InputGroup.Prepend>
                                                <MyOverlay
                                                    title={item.field_name}
                                                    description={item.description}>
                                                </MyOverlay>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    )}
                                    <Button variant="outline-primary" size="sm" onClick={() => {
                                        console.log(this.state.valuesCum)
                                        let formula = this.props.formulaCump
                                        for (let i = 1; i < this.props.cump.length; i++) {
                                            try {
                                                //Sustituir en la fórmula
                                                formula = formula.replace(this.props.cump[i].field_name, parseFloat(this.state.valuesCum[i], 10));
                                            } catch (error) {
                                                formula = formula.replace(this.props.cump[i].field_name, 0);
                                            }
                                        }
                                        console.log(formula)
                                        this.state.valuesCum[0] = eval(formula).toFixed(4)
                                        this.setState({ refresh: !this.state.refresh }) //Renderiza los valores
                                    }}>Calcular cumplimiento</Button>
                                </Col>
                                <Col>   {/* Cálculo del PUM */}
                                    <strong>Cálculo del PUM al MRO</strong><br />
                                    <strong>PUM</strong> = {this.props.formulaPUM}
                                    {this.props.pum.map((item, i) =>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder={item.field_name}
                                                value={valuesPum[i] === 0 ? '' : valuesPum[i]}
                                                readOnly={item.readonly}
                                                onChange={(e) => {
                                                    this.state.valuesPum[i] = e.target.value;
                                                    this.setState({ refresh: !this.state.refresh });
                                                }}
                                            />
                                            <InputGroup.Prepend>
                                                <MyOverlay
                                                    title={item.field_name}
                                                    description={item.description}>
                                                </MyOverlay>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    )}

                                    <Button variant="outline-primary" size="sm" onClick={() => {
                                        console.log(this.state.valuesPum)
                                        let formula = this.props.formulaPUM
                                        console.log(formula)
                                        for (let i = 1; i < this.props.pum.length; i++) {
                                            try {
                                                //Sustituir en la fórmula
                                                formula = formula.replace(this.props.pum[i].field_name, parseFloat(this.state.valuesPum[i], 10));
                                            } catch (error) {
                                                formula = formula.replace(this.props.pum[i].field_name, 0);
                                            }
                                        }
                                        formula = formula.replace('%Cump', this.state.valuesCum[0])
                                        this.state.valuesPum[0] = eval(formula).toFixed(4)
                                        this.setState({ refresh: !this.state.refresh }) //Renderiza los valores
                                    }}>Calcular Pum</Button>
                                </Col>
                            </Row>
                        }

                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    {this.props.hayFormula === false ?
                        <Button variant="outline-success" size="lg" onClick={() => {
                            this.addFormulasToDB(0, this.state.formula_criterio, this.state.rowFields_criterio)
                            this.addFormulasToDB(1, this.state.formula_pum, this.state.rowFields_pum)
                        }}>Agregar fórmulas</Button>
                        :
                        <Button variant="outline-success" size="lg">Crear Deductiva</Button>
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}

export default Deductiva;