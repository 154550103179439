import React, { useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import EventAgenda from '../../UOV-5/fragments/EventAgenda';
import EventCalendar from './EventCalendar';
import ModalDetails from './ModalDetails';
import ModalWorkOrders from './ModalWorkOrders';
import { deleteMaintenanceProgramFlow, indexMaintenanceProgramFlow, showMaintenanceProgramFlow } from 'api/maintenance_standards/maintenance_program_flow';
import Filters from '../MaintenanceProgram/Filters';
import { useSelector } from 'react-redux';
import { getSegments } from 'api/general/getSegments';
import Swal from 'sweetalert2';
import { Skeleton } from 'antd';
import WorkOrderModal from '../WorkOrderModal';

require('moment/locale/es.js');
const localizer = momentLocalizer(moment);

const CalendarComp = (props) => {
    const { project_id, standard_id } = props;
    // No se trata de orden de trabajo para incidentes
    const is_incident = 0;
    const package_id = useSelector(state => state.login.dataUser.package.id);

    const [events, setEvents] = useState([]);
    const [eventSel, setEventSel] = useState({});
    const [data, setData] = useState({});
    const [dataDetails, setDataDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingCalendar, setLoadingCalendar] = useState(false);
    const [modalDetails, setModalDetails] = useState(false);
    const [modalNewOrder, setModalNewOrder] = useState(false);
    const [modalViewOrders, setModalViewOrders] = useState(false);
    const [filters, setFilters] = useState({});
    const [segments, setSegments] = useState([]);

    useEffect(() => {
        getAllSegments();
    }, []);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data.length > 0) generateEventList();
        else setEvents([]);
    }, [data]);

    const getData = async () => {
        setLoadingCalendar(true);
        let response = await indexMaintenanceProgramFlow({ project_id, maintenance_standard_id: standard_id, ...filters });
        setData(response.data);
        setLoadingCalendar(false);
    }

    const getAllSegments = async () => {
        const response = await getSegments();
        setSegments(response);
    }

    const getDataDetails = async (id) => {
        setLoading(true);
        let response = await showMaintenanceProgramFlow({ project_id, id });
        if (response) setDataDetails(response);
        setLoading(false);
    }
    const generateEventList = async () => {
        let eventsList = [];
        for (const program_flow of data) {
            const { id, responsable_name, start_at, end_at, activity_code, activity_name, status, work_order_id } = program_flow;
            eventsList.push({
                id,
                title: responsable_name,
                start: new Date(start_at),
                end: new Date(end_at),
                desc: activity_code + '-' + activity_name,
                status,
                details: onEventClick,
                detailsOrder,
                generateOrder,
                deleteOrder,
                work_order_id
            });

        }
        setEvents(eventsList);

    }

    // Evento Click: Get "victum_maintenance_program_flow"
    const onEventClick = async (event) => {
        await getDataDetails(event?.id);
        setModalDetails(true);
    }
    // Evento: Crear orden de trabajo
    const generateOrder = async (event) => {
        setEventSel(event);
        setModalNewOrder(true);
    }
    // Evento: Ver órdenes de trabajo
    const detailsOrder = async (event) => {
        setEventSel(event);
        await getDataDetails(event?.id);
        setModalViewOrders(true);
    }
    // Evento: eliminar programa de mantenimiento
    const deleteOrder = async (event) => {
        const { id, desc, status, start, end } = event;
        const start_at = moment(start).format('LLLL');
        const end_at = moment(end).format('LLLL');
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: `Usted está tratando de eliminar la actividad de mantenimiento ${desc} con estado ${status}. 
        	Programada de ${start_at} al ${end_at}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { response } = await deleteMaintenanceProgramFlow({ project_id, id });
                if (response) {
                    setEvents(prevState => prevState.filter(item => item.id !== id));
                    Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
                } else
                    Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
            }
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }
    // Ver ordenes de trabajo del programa de mantenimiento seleccionado
    const viewOrders = () => {
        detailsOrder(eventSel)
    }
    // Crear una orden desde el modal de ordenes de trabajo
    const newOrder = () => {
        setModalViewOrders(false);
        generateOrder(eventSel);
    }
    // Actualizar el estado del calendario
    const updateStatusCalendar = () => {
        setData(prevState => prevState.map((item) => {
            if (item.id === eventSel.id) item.status = 'ASIGNADO';
            return item;
        }));
    }
    return (
        <>
            <Filters filters={filters} handleChange={handleChange} getData={getData} />
            <div style={{ height: `60em` }} className="bigCalendar-container calendar">
                {/* Mostrar flujo de programa de mantenimiento en el calendario a través de eventos */}
                {
                    loadingCalendar ? <Skeleton active /> :
                        <BigCalendar
                            selectable
                            localizer={localizer}
                            views={['month', 'week', 'day', 'agenda']}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            messages={{
                                next: "sig",
                                previous: "ant",
                                today: "Hoy",
                                month: "Mes",
                                week: "Semana",
                                day: "Día"
                            }}
                            components={{
                                event: EventCalendar,
                                agenda: { event: EventAgenda } // Componente de OBAC
                            }}
                        />
                }

                {/* Modal para ver los detalles del evento seleccionado */}
                {
                    modalDetails && <ModalDetails
                        onClose={() => setModalDetails(false)}
                        dataDetails={dataDetails}
                        loading={loading}
                        project_id={project_id}
                    />
                }
                {/* Modal para ver las órdenes de trabajo */}
                {
                    modalViewOrders && segments.length > 0 && <ModalWorkOrders
                        onClose={() => setModalViewOrders(false)}
                        dataDetails={dataDetails}
                        loading={loading}
                        package_id={package_id}
                        segment_id={segments[0].id}
                        standard_id={standard_id}
                        project_id={project_id}
                        newOrder={newOrder}
                        updateStatusCalendar={updateStatusCalendar}
                    />
                }
                {/* Modal para crear una orden de trabajo */}
                {
                    modalNewOrder && segments.length > 0 && <WorkOrderModal
                        onClose={() => setModalNewOrder(false)}
                        eventSel={eventSel}
                        package_id={package_id}
                        segment_id={segments[0].id}
                        standard_id={standard_id}
                        viewOrders={viewOrders}
                        is_incident={is_incident}
                        setEvents={setEvents}
                    />
                }
            </div>
        </>
    )
}

export default CalendarComp