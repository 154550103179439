import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxxDS = () => {
    const table = 'surface_deterioration_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 70 },
        { label: 'KM_INICIAL', description: 'Kilometro incial', value: 'start_km', type: 'String', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 100 },
        { label: 'KM_FINAL', description: 'Kilometro final', value: 'end_km', type: 'String', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 100 },
        { label: 'TIPO', description: 'Tipo', value: 'type', type: 'String', allow: null, show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 70 },
        { label: 'INTENSIDAD', description: 'Intensidad', value: 'intensity', type: 'String', allow: null, show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'LONGITUD_DET', description: 'Longitud deterioro', value: 'det_length', type: 'Number', allow: null, edit: true, add: true, width: 10, pdf: false, widthXLSX: 120 },
        { label: 'ANCHO_DET', description: 'Ancho deterioro', value: 'det_width', type: 'Number', allow: null, edit: true, add: true, width: 10, pdf: false, widthXLSX: 100 },
        { label: 'TOTAL_M', description: 'Total m', value: 'm', type: 'Number', allow: null, edit: true, add: true, width: 10, pdf: false, widthXLSX: 100 },
        { label: 'TOTAL_M2', description: 'Total m2', value: 'm2', type: 'Number', allow: null, edit: true, add: true, width: 10, pdf: false, widthXLSX: 100 },
        { label: 'LONGITUD_CARRIL', description: 'LongitudCarril', value: 'rail_length', type: 'Number', allow: null, show: true, edit: true, add: true, width: 15, pdf: false, widthXLSX: 140 },
        { label: 'ANCHO_CARRIL', description: 'AnchoCarril', value: 'rail_width', type: 'Number', allow: null, show: true, edit: true, add: true, width: 15, pdf: false, widthXLSX: 120 },
        { label: 'LONGITUD', description: 'Longitud', value: 'longitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'LATITUD', description: 'Latitud', value: 'latitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'PORCENTAJE_OBSERVACION', description: 'Porcentaje', value: 'percentage_observation', type: 'String', allow: null, show: true, edit: true, add: true, width: 30, widthXLSX: 150 },
        { label: 'OBSERVACIONES', description: 'Observaciones', value: 'observations', type: 'String', allow: null, edit: true, add: true, width: 30, widthXLSX: 130 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
    ];

    const km = [{ index: 1 }, { index: 2 }];    // Arreglo de indices que irán con formato 000+000
    const percentage = [{ index: 13, accuracy: 5 }];         // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxxDS;