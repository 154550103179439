import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexUserTypeResponsable = async ({ package_id }) => {
    const url = `/packages/${package_id}/user_type_responsable`;
    let response = { data: [], totalItems: 0 };
    let params = {};
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}

export const storeUserTypeResponsable = async ({ package_id, data }) => {
    const url = `/packages/${package_id}/user_type_responsable`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}