import React, { Component } from 'react';
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import '../UOV-0/Operacion.css';
import '../UOV-0/Resultados.css';
import { Variables } from 'variables/Variables';
import { __SERVER__ } from 'server/info';
import axios from 'axios';
import ModalUpload from '../Modal.jsx';
import Deductiva from '../Deductiva.jsx';
import { Heading } from '@chakra-ui/react';
import FlexButton from 'components/layouts/FlexButton';
import { faCarCrash, faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';

import FiltroBusqueda from './UOV-21/fragments/FiltroBusqueda'
import FiltroBusqueda_ from './UOV-21/fragments/FiltroBusqueda_'
import Grid from './UOV-21/fragments/Grid'
import Grid_ from './UOV-21/fragments/Grid_'

import DownloadXlsxModal from './UOV-21/modals/DownloadXlsxModal';


const _ = require('underscore');

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			result2: [],
			refresh: false,
			page: 1,
			page2: 1,
			statusOne: { backgroundColor: '#FF0000', cursor: 'pointer', margin: 5, color: '#FFF', borderWidth: 0, padding: 10, borderRadius: 5 },
			statusTwo: { backgroundColor: '#9C9D9C', cursor: 'pointer', margin: 5, color: '#FFF', borderWidth: 0, padding: 10, borderRadius: 5 },
			status: 1,
			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false,
			buttonVisible: false,
			modalXlsx: false
		}
	}

	componentDidMount = async () => {
		// GET operating_indicator_id
		let res = await axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')

		if (res.data.length !== 0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	updatePage = (page) => {
		if (this.state.status === 1) {
			this.setState({ page }, this.getEmergencyCoordination);
		} else {
			this.setState({ page2: page }, this.getEmergencyCoordination);
		}
	}

	generateTable = () => {
		let data = [];
		if (this.state.status === 1) {
			data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];
		} else {
			data = this.state.result2;
		}

		this.setState({
			grid: null
		}, () => {
			if (data !== undefined) {
				if (this.state.status === 1) {
					this.setState({ grid: <Grid updatePage={this.updatePage} data={data} /> })
				} else if (this.state.status === 2) {
					this.setState({ grid: <Grid_ updatePage={this.updatePage} data={data} /> })
				} else {
					this.setState({ grid: null });
				}
			} else {
				this.setState({ grid: null });
			}
		});
	}

	getEmergencyCoordination = () => {
		let table = "/projects/" + this.props.project_id + "/emergency_coordination";
		let query = this.state.query
		if (this.state.status === 1) {
			query = this.state.query + (this.state.query === '' ? `?page=${this.state.page}` : `&page=${this.state.page}`);
		}
		if (this.state.status === 2) {
			table = "/victum_users/victum_incidents";
		}
		table += query;
		console.log(__SERVER__ + table)
		fetch(__SERVER__ + table, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					if (this.state.status === 1) {
						this.state.result.push(result);
					} else {
						this.state.result2 = result;
					}
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			Swal.fire('Error', e, 'error');
		})
	}

	setProps = (query, headersTable) => {
		if (this.state.status === 1) {
			this.setState({
				page: 1,
				result: [],
				query: query,
				headersTable: headersTable
			}, this.getEmergencyCoordination)
		} else {
			this.setState({
				page2: 1,
				result2: [],
				query: query,
				headersTable: headersTable
			}, this.getEmergencyCoordination)
		}
	}

	status = (status) => {
		this.setState({
			statusOne: this.state.statusTwo,
			statusTwo: this.state.statusOne,
			status: status,
			grid: null
		});
	}

	openOrCloseModal = () => {
		this.setState({ modalUploadData: !this.state.modalUploadData });
	}

	openOrCloseModalDeductivas = () => {
		this.setState({ modalDeductivas: !this.state.modalDeductivas });
	}

	render() {
		return (
			<div className="body_">
				<div className="buttons">
					<button onClick={() => { this.status(1) }} style={this.state.statusOne}>UOV 2.1</button>
					<button onClick={() => { this.status(2) }} style={this.state.statusTwo}>VICTUM</button>
				</div>
				{this.state.status === 1 &&
					<div>
						<Heading className='tituloSub'>UOV 2.1 Coordinación de emergencias</Heading>
						<FiltroBusqueda setProps={this.setProps} />
						{this.state.grid}
					</div>
				}

				{this.state.status === 2 &&
					<div>
						<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
						<FiltroBusqueda_ setProps={this.setProps} />
						{this.state.grid}
					</div>
				}
				{
					this.state.buttonVisible &&
					<FlexButton style={{ bottom: 70, right: 10 }} icon={faCarCrash} onClick={this.openOrCloseModalDeductivas} />
				}
				<FlexButton style={{ bottom: 10, right: 10 }} icon={faPlus} onClick={this.openOrCloseModal} />
				<FlexButton style={{ bottom: 130, right: 10 }} icon={faFileExcel} onClick={() => this.setState({ modalXlsx: true })} />
				{
					this.state.modalUploadData &&
					<ModalUpload
						server={`${__SERVER__}/projects/${Variables.project_id}/emergency_coordination`}
						items={[
							{ label: 'Código', description: 'Código', value: 'code', type: 'String' },
							{ label: 'Fecha de aviso', description: 'Fecha de aviso', value: 'notice_date', type: 'String', toFormat: 'Date' },
							{ label: 'Hora de aviso', description: 'Hora de aviso', value: 'notice_time', type: 'String', toFormat: 'Time' },
							{ label: 'Cuerpo', description: 'Cuerpo', value: 'body', type: 'String' },
							{ label: 'PK inicial', description: 'PK inicial', value: 'initial_pk', type: 'String' },
							{ label: 'Tipo de evento', description: 'Tipo de evento', value: 'type_event', type: 'String' },
							{ label: 'Siniestro', description: 'Siniestro', value: 'sinister', type: 'String' },
							{ label: 'Medio de notificación', description: 'Medio de notificación', value: 'means_notification', type: 'String' },
							{ label: 'Unidad', description: 'Unidad', value: 'unit', type: 'String' },
							{ label: 'Hora de intervención', description: 'Hora de intervención', value: 'intervention_time', type: 'String', toFormat: 'Time' },
							{ label: 'Intervención de cumplimiento', description: 'Intervención de cumplimiento', value: 'compliance_intervention', type: 'String' },
							{ label: 'Hora de señalización', description: 'Hora de señalización', value: 'signaling_time', type: 'String', toFormat: 'Time' },
							{ label: 'Hora de notificación al seguro', description: 'Hora de notificación al seguro', value: 'insurance_notification_time', type: 'String', toFormat: 'Time' },
							{ label: 'Cumplimiento del seguro', description: 'Cumplimiento del seguro', value: 'compliance_insurance', type: 'String' },
							{ label: 'Hora de llegada del seguro', description: 'Hora de llegada del seguro', value: 'insurance_arrival_time', type: 'String', toFormat: 'Time' },
							{ label: 'Hora de notificación a PFP', description: 'Hora de notificación a PFP', value: 'PFP_notification_time', type: 'String', toFormat: 'Time' },
							{ label: 'Fecha límite', description: 'Fecha límite', value: 'compliance_PFP', type: 'String' },
							{ label: 'Hora de notificación a la ambulancia', description: 'Hora de notificación a la ambulancia', value: 'ambulance_notification_time', type: 'String', toFormat: 'Time' },
							{ label: 'Cumplimiento de la ambulancia', description: 'Cumplimiento de la ambulancia', value: 'compliance_ambulance', type: 'String' },
							{ label: 'Hora de notificación a los bomberos', description: 'Hora de notificación a los bomberos', value: 'firefighter_notification_time', type: 'String', toFormat: 'Time' },
							{ label: 'Cumplimiento de los bomberos', description: 'Cumplimiento de los bomberos', value: 'compliance_firefighter', type: 'String' },
							{ label: 'Hora de notificación a SEMEFO', description: 'Hora de notificación a SEMEFO', value: 'SEMEFO_notification_time', type: 'String', toFormat: 'Time' },
							{ label: 'Cumplimiento de SEMEFO', description: 'Cumplimiento de SEMEFO', value: 'compliance_SEMEFO', type: 'String' },
							{ label: 'Hora de notificación al MR', description: 'Hora de notificación al MR', value: 'MR_notification_time', type: 'String', toFormat: 'Time' },
							{ label: 'Cumplimiento del MR', description: 'Cumplimiento del MR', value: 'compliance_MR', type: 'String' },
							{ label: 'Tasa de accidentes', description: 'Tasa de accidentes', value: 'accident_rate', type: 'String' },
							{ label: 'Estado del incidente', description: 'Estado del incidente', value: 'incident_status', type: 'String' },
							{ label: 'Tipo de camino', description: 'Tipo de camino', value: 'type_road', type: 'String' },
							{ label: 'Factores atmosféricos', description: 'Factores atmosféricos', value: 'atmospheric_factors', type: 'String' },
							{ label: 'Número de vehículos', description: 'Número de vehículos', value: 'number_vehicles', type: 'Number' },
							{ label: 'Número de ocupantes', description: 'Número de ocupantes', value: 'number_occupants', type: 'Number' },
							{ label: 'Número de muertos', description: 'Número de muertos', value: 'number_dead', type: 'Number' },
							{ label: 'Número de heridos', description: 'Número de heridos', value: 'number_injured', type: 'Number' }
						]}
						title={`${this.props.code} ${this.props.name}`}
						onClose={this.openOrCloseModal}
					/>
				}
				{/* Modal para calcular las deductivas */}
				{
					this.state.modalDeductivas &&
					<Deductiva
						title={this.props.code}
						formula={this.state.formula}
						pum={this.state.PUMObject}
						cump={this.state.CumplObject}
						formulaCump={this.state.formulaCump}
						formulaPUM={this.state.formulaPUM}
						hayFormula={this.state.hayFormula}
					/>
				}
				{/* Modal para descargar reporte XLSX */}
				{
					this.state.modalXlsx && <DownloadXlsxModal
						onClose={() => this.setState({ modalXlsx: false })}
						project_id={this.props.project_id}
					/>
				}
			</div>
		);
	}
}