import React, { useState, useEffect } from 'react';
import axios from 'axios';

function useFetch(url){
  const [data, setDataa] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
      setLoading('loading...')
      setDataa(null);
      setError(null);
      const source = axios.CancelToken.source();
      axios.get(url, { cancelToken: source.token })
      .then(res => {
          res && setDataa(res);
          setLoading(false);
      })
      .catch(err => {
          setLoading(false)
          setError('Ocurrió un error')
      })
      return () => {
          source.cancel();
      }
  }, [url])

  return { data, loading, error }
}

export default useFetch