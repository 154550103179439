import fetcher from "api/fetcher";

// const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param {package_id, operating_indicator_code} props 
 * @returns { data: [], totalItems: 0 }
 */
export const indexMaintenanceSections = async (props) => {
    const { package_id, operating_indicator_code } = props;
    const params = {};
    if (operating_indicator_code)
        params.operating_indicator_code = operating_indicator_code;
    const url = `/packages/${package_id}/victum_maintenance_sections`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_sections.js ~ line 23 ~ indexMaintenanceSections ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}