import fetcher from "api/fetcher";

// const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param {package_id, operating_indicator_code} props 
 * @returns { data: [], totalItems: 0 }
 */
export const indexMaintenanceTypes = async ({ package_id }) => {
    const url = `/packages/${package_id}/victum_maintenance_types`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}