import React, { useEffect, useState } from 'react';
import img from '../../img/angle-right-solid.svg';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    BREADCRUMB_BEFORE,
    BREADCRUMB_BEFORE_AJUSTES,
    STAGE_CHOOSED,
    UPDATE_COMPONENT,
    UPDATE_COMPONENT_AJUSTES
} from 'store/actions/actionTypes';

const BreadCrumb = ({ here, stages }) => {
    const [paths, setPaths] = useState([]);
    const pathBC = useSelector(state => state.breadCrumb.pathBC);
    const pathBCA = useSelector(state => state.ajustes.pathBC);
    const stageChoosed = useSelector(state => state.proyectos.stageChoosed);

    const dispatch = useDispatch();

    useEffect(() => {
        setPaths(here === 'PROYECTOS' ? pathBC : pathBCA);
    }, [here, pathBC, pathBCA]);

    // Se elige la fase y se guarda en redux
    const changeStage = (event) => {
        const { value } = event.target;
        dispatch({ type: STAGE_CHOOSED, stage_id: value });
    }

    return (
        <div className={'caja'}>
            <div className='bc-text'>
                {
                    paths.map((item, i) =>
                        <label key={item.id} className='bc-label'>
                            <small style={{
                                color: paths.length === (i + 1) ? '#A48A0B' : '#FFF',
                                cursor: paths.length === (i + 1) ? 'default' : 'pointer'
                            }}
                                onClick={() => {
                                    if (paths.length !== (i + 1)) {
                                        if (here === 'PROYECTOS') {
                                            dispatch({ type: UPDATE_COMPONENT, componentActive: null });
                                            dispatch({ type: BREADCRUMB_BEFORE, index: i });
                                        } else {
                                            dispatch({ type: UPDATE_COMPONENT_AJUSTES, componentActive: null });
                                            dispatch({ type: BREADCRUMB_BEFORE_AJUSTES, index: i });
                                        }

                                    }
                                }}>
                                {item.path}
                            </small>
                            {
                                paths.length !== (i + 1) &&
                                <img style={{ width: 10, height: 10, marginLeft: 2, marginRight: 5, display: "inline" }} src={img} />
                            }
                        </label>
                    )
                }
            </div>
            <div className='select-stages-div'>
                {
                    stages && stages.length > 0 &&
                    <select className='select-stages' onChange={changeStage} value={stageChoosed}>
                        {
                            stages.map((stage) =>
                                <option key={stage.id} value={stage.id}>{stage.name}</option>
                            )
                        }
                    </select>
                }
            </div>
        </div>
    )
}

export default BreadCrumb;
