import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexAssetsComponentTypes = async ({ package_id, table }) => {
    const url = `/packages/${package_id}/${table}/component_types`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: assets_component_types.js:11 ~ indexAssetsComponentTypes ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = { data: fetch.data, totalItems: fetch.data.length };
        return { response, cancel };
    } catch (error) {
        return { response };
    }
}