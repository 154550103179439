import { Col, Row } from 'antd';
import React from 'react';
import { Variables } from 'variables/Variables';

export default class extends React.Component {
	render = () => {
		return (
			<section>
				{
					Variables.package_id === 12 ?
						<Row>
							<Col xs={24} lg={12} xxl={8}>
								<iframe
									width="100%"
									height="400"
									src="https://www.youtube.com/embed/Hf4MrcI5cDE"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
							</Col>
							<Col xs={24} lg={12} xxl={8}>
								<iframe
									width="100%"
									height="400"
									src="https://www.youtube.com/embed/njYZd376hh8"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
							</Col>
							<Col xs={24} lg={12} xxl={8}>
								<iframe
									width="100%"
									height="400"
									src="https://issuu.com/one-aleatica/docs/calendario_laboral_2023?fr=sNWNiYTUzNTE5NDA"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
							</Col>
						</Row> : null
				}
			</section >
		);
	}
}
