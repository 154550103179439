import { PDFDownloadLink } from '@react-pdf/renderer';
import useFetch from 'hooks/useFetch';
import React from 'react'
import { useState } from 'react';
import { Alert, Button, Col, Form, Jumbotron, Row } from 'react-bootstrap'
import { FaFilePdf } from 'react-icons/fa';
import DocPDF from './DocPDF';
import { Heading } from '@chakra-ui/react';
const { __SERVER__ } = require("../../../server/info");

const Reports = React.memo(({package_id, project_id}) => {

    const url = `${__SERVER__}/packages/${package_id.id}/maintenance_standards`;
    const { data, loading, error } = useFetch(url);
    const [Incidents, setIncidents] = useState(null);
    const [Date, setDate] = useState({
        creation_dateS: null,
        creation_dateE: null	
    })

    const hanldeReport = async id => {
        let req = `${__SERVER__}/projects/${project_id}/inspections/incidents?standard_id=${id}&pageSize=10000`

        const { creation_dateE, creation_dateS } = Date;
        if(creation_dateE !== null && creation_dateS !== null){
             req = `${__SERVER__}/projects/${project_id}/inspections/incidents?standard_id=${id}&creation_dateS=${creation_dateS}&creation_dateE=${creation_dateE}&pageSize=10000`;
        }
        
        try {
            const res = await fetch(req);
            const data = await res.json();
            setIncidents(data.data);
        } catch (err) { console.log(err) }
    }

    const hanldeDate  = e => {
        const { name, value } = e.target;
		setDate({
			...Date,
			[name]: value
		})
    }
    
    return (
        <Jumbotron style={{ marginTop: 10, marginLeft: 10 }} >     
            <Form>
                <Heading color={'black'}>Reporte de Incidencias</Heading>
                <Row>
                    <Alert variant="info">
                        <Alert.Heading>Información</Alert.Heading>
                        <p>
                            En esta sección se crean los reportes mensuales de Incidencias.
                            Es importante mencionar que el rango de fechas se puede ajustar para modificar la fecha de consulta.
                        </p>
                    </Alert>
                </Row>
                <Row>
                    <Col>
                        <label>Fecha inicial</label>
                        <input type="date" name="creation_dateS" onChange={hanldeDate} className="form-control"/>
                    </Col>
                    <Col>
                        <label>Fecha final</label>
                        <input type="date" onChange={hanldeDate} name="creation_dateE" className="form-control" />
                    </Col>
                </Row>
                <Row className='mt-2'>
                {
                    Incidents && <PDFDownloadLink document={<DocPDF Incidents={Incidents} />} fileName="Reporte_Incidentes.pdf">
                    <Button className="mx-2" variant="success"><FaFilePdf className="mr-1" />Reporte Listo para descargar</Button>
                    </PDFDownloadLink>              
                }

                {
                    (data) ? data.data.map((item, i) => 
                        <Button key={item.id} variant="danger" className="m-2" onClick={()=>hanldeReport(item.id)} >{i+1}. {item.name}</Button>
                    ) : (<h3>Cargando...</h3>)
                }
                </Row>
            </Form> 
        
        </Jumbotron>
    )
})

export default Reports