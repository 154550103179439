import React, { useEffect, useState } from 'react'
import './css/UsersNew.css';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { indexUsersSimple } from 'api/users/users';
import { useDisclosure } from '@chakra-ui/react';
import { indexRolePermissions, indexRoles, indexUserPermissions } from 'api/general/permissions';
import PermissionsComp from './users/Permissions';
import UsersList from './users/UsersList';
import EditUsers from './users/EditUsers';

const Users = () => {
    const [initialCheckedKeys, setInitialCheckedKeys] = useState([]);   // Lista de permisos del rol seleccionado (no editable)
    const [checkedKeys, setCheckedKeys] = useState([]);                 // Permisos seleccionados (editable)
    const [users, setUsers] = useState([]);                             // Lista de usuarios
    const [roles, setRoles] = useState([]);                             // Lista de roles de usuario existentes
    const [userSel, setUserSel] = useState(null);                       // Usuario seleccionado
    const [userForm, setUserForm] = useState({ status: 1 });            // Formulario para enviar en POST o PUT
    const [btnUser, setButtonUser] = useState(true);                    // Botón para actualizar o agregar usuarios
    const [addUser, setAddUser] = useState(false);
    const [view, setView] = useState('package');
    const [disabledTree, setDisabledTree] = useState(true);             // Permisos editables

    const loadingUsers = useDisclosure();
    const loadingRoles = useDisclosure();
    const loadingUserPermissions = useDisclosure();

    const package_id = useSelector(state => state.login.dataUser.package_id);

    // Obtener la lista de usuarios
    useEffect(() => {
        const getUsers = async () => {
            loadingUsers.onOpen();
            const response = await indexUsersSimple({ package_id });
            setUsers(response.data);
            loadingUsers.onClose();
        }
        getUsers();
    }, []);

    // Todos los roles que existen
    useEffect(() => {
        const getRoles = async () => {
            loadingRoles.onOpen();
            const { response } = await indexRoles({ package_id });
            if (response.length > 0)
                setUserForm(prevState => ({ ...prevState, user_type_id: response[0].id }));
            setRoles(response);
            loadingRoles.onClose();
        }
        getRoles();
    }, []);

    // Si se selecciona un rol se obtienen los permisos
    useEffect(() => {
        if (userSel) {
            if (userSel.custom_permissions) {
                setDisabledTree(false);
                getUserPermissions();
            } else {
                setDisabledTree(true);
                getRolePermissions();
            }
            setButtonUser(false);                                   // Habilitar botón para actualizar datos de usuario
            if (userSel.package_id) setView('package');
            else if (userSel.road_section_id) setView('road');
            else if (userSel.project_id) setView('project');
            setUserForm(userSel);
        }
    }, [userSel]);

    useEffect(() => {
        if (addUser) setButtonUser(false);
    }, [addUser]);

    // Obtener permisos del rol
    const getRolePermissions = async () => {
        loadingUserPermissions.onOpen();
        const { response } = await indexRolePermissions({ user_type_id: userSel.user_type_id, package_id });
        const nodes = getLastNodes(response);
        setInitialCheckedKeys(nodes);
        setCheckedKeys(nodes);
        loadingUserPermissions.onClose();
    }
    // Obtener permisos del usuario
    const getUserPermissions = async () => {
        loadingUserPermissions.onOpen();
        const { response } = await indexUserPermissions({ user_id: userSel.id });
        const nodes = getLastNodes(response);
        setInitialCheckedKeys(nodes);
        setCheckedKeys(nodes);
        loadingUserPermissions.onClose();
    }

    const recursive = (array) => {
        let response = [];
        for (const element of array) {
            const { id, description, sub } = element;
            let children = [];
            if (sub) children = recursive(sub);
            response.push({ title: `${description}`, key: id, children });
        }
        return response;
    }

    const getLastNodes = (array) => {
        let response = [];
        for (const element of array) {
            const { permission_id, sub } = element;
            if (sub) {
                response = response.concat(getLastNodes(sub));
            } else {
                response.push(permission_id)
            }
        }
        return response;
    }

    const onClickUser = (item) => {
        setUserSel(item);
        setAddUser(false);
    }

    return (
        <Row className='users-main'>
            <UsersList
                loadingUsers={loadingUsers}
                users={users}
                userSel={userSel}
                onClickUser={onClickUser}
            />
            <EditUsers
                package_id={package_id}
                roles={roles}
                userSel={userSel}
                setUserSel={setUserSel}
                addUser={addUser}
                setAddUser={setAddUser}
                setUserForm={setUserForm}
                userForm={userForm}
                setUsers={setUsers}
                view={view}
                setView={setView}
                btnUser={btnUser}
            />
            <PermissionsComp
                userSel={userSel}
                disabledTree={disabledTree}
                package_id={package_id}
                userForm={userForm}
                setUsers={setUsers}
                setUserSel={setUserSel}
                initialCheckedKeys={initialCheckedKeys}
                checkedKeys={checkedKeys}
                setCheckedKeys={setCheckedKeys}
                setDisabledTree={setDisabledTree}
                loadingUserPermissions={loadingUserPermissions}
            />
        </Row>
    )
}

export default Users