import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxxDB = () => {
    const table = 'defenses_barriers_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, add: true, width: 10, pdf: true, widthXLSX: 100 },
        { label: 'KM_INICIAL', description: 'Kilometro incial', value: 'start_km', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'KM_FINAL', description: 'Kilometro final', value: 'end_km', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'DISTANCIA', description: 'Logitud en metros', value: 'length', type: 'Number', allow: null, show: true, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'CUERPO', description: 'Cuerpo', value: 'body', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'LATITUD INICIAL', description: 'Latitud', value: 'latitude', type: 'Number', allow: null, add: true, edit: true, width: 15, widthXLSX: 100 },
        { label: 'LONGITUD INICIAL', description: 'Longitud', value: 'longitude', type: 'Number', allow: null, add: true, edit: true, width: 15, widthXLSX: 100 },
        { label: 'ALTITUD INICIAL', description: 'altitude', value: 'altitude', type: 'Number', allow: null, add: true, edit: true, width: 15, widthXLSX: 100 },
        { label: 'LATITUD FINAL', description: 'Latitud final', value: 'final_latitude', type: 'Number', allow: null, add: true, edit: true, width: 15, widthXLSX: 100 },
        { label: 'LONGITUD FINAL', description: 'Longitud final', value: 'final_longitude', type: 'Number', allow: null, add: true, edit: true, width: 15, widthXLSX: 100 },
        { label: 'ALTITUD FINAL', description: 'altitude final', value: 'final_altitude', type: 'Number', allow: null, add: true, edit: true, width: 15, widthXLSX: 100 },
        { label: 'LADO', description: 'Lado', value: 'side', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'TIPO', description: 'Tipo', value: 'type', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'DAÑO', description: 'Daño', value: 'damaged', type: 'String', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'OBSERVACIONES', description: 'Observaciones', value: 'observations', type: 'String', allow: null, edit: true, add: true, width: 30, widthXLSX: 100 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
    ];

    const km = [{ index: 1 }, { index: 2 }];    // Arreglo de indices que irán con formato 000+000
    const percentage = [];                      // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxxDB;