import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { indexAssetsBaseLines } from 'api/operation_standards/assets_base_lines';
import FlexButton from 'components/layouts/FlexButton';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table'
import React, { useEffect, useState } from 'react'
import AddBaseLine from '../modals/AddBaseLine';
import UpdateBaseLine from '../modals/updateBaseLine';

const BaseLinesTab = (props) => {
    const { package_id } = props;

    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [baseLineSel, setBaseLineSel] = useState({});

    useEffect(() => {
        getBaseLines();
    }, [page, pageSize]);

    const getBaseLines = async () => {
        setLoading(true);
        let response = await indexAssetsBaseLines({ package_id });
        setData(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const editBaseLine = (item) => {
        setBaseLineSel(item);
        setModalUpdate(!modalUpdate);
    }

    return (
        <>
            <TableComp
                headers={['#', 'Nombre', 'Descripción', 'Color', 'Creado en', 'Acciones']}
                keys={['#', 'name', 'description', 'color', 'created_at']}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faEdit, variant: 'outline-primary', handleClick: editBaseLine, tooltip: 'Actualizar registro' }
                ]}
                badges={[{ index: 3, color: 'color' }]}

            />
            {/* Paginación */}
            <PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
            {/* Agregar un registro */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModal(!modal)} />
            {/* Modal para agregar un registro */}
            {
                modal && <AddBaseLine
                    onClose={() => setModal(!modal)}
                    package_id={package_id}
                    update={getBaseLines}
                />
            }
            {/* Modal para actualizar los registros de línea base */}
            {
                modalUpdate && <UpdateBaseLine
                    onClose={() => setModalUpdate(!modalUpdate)}
                    package_id={package_id}
                    update={getBaseLines}
                    baseLineSel={baseLineSel}
                />
            }
        </>
    )
}

export default BaseLinesTab