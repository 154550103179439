import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { dateToString } from 'utilidades/dates';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { Skeleton } from 'antd';

const ListWorkOrder = (props) => {
    const {
        addWorkOrder,
        onClickWorkOrder,
        workOrders,
        workOrderSel,
        loadingWorkOrders
    } = props;

    return (
        <section className="listWorkOrder">
            {/* <article>
                <ButtonIcon name='Añadir orden' icon={faPlus} variant='outline-success' onClick={addWorkOrder} tooltipDisabled={true} />
            </article> */}
            {
                loadingWorkOrders ? <Skeleton active /> : <>
                    {
                        workOrders.map((item, i) =>
                            <article key={item.id} id={"card_workOrder" + i} onClick={() => onClickWorkOrder(i, item)} className={workOrderSel === i ? "active_card" : ""}>
                                <h2 for={"card_workOrder" + i}>{item.code}</h2>
                                <label for={"card_workOrder" + i}>{dateToString(item.creation_date)}</label>
                            </article>
                        )
                    }
                </>
            }
        </section>
    )
}

export default ListWorkOrder