import React, { useEffect, useState } from 'react';
import { faBoxes, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table';
import FlexButton from 'components/layouts/FlexButton';
import { indexMaintenanceActivities } from 'api/maintenance_standards/maintenance_activities';
import AddOrEdit from './AddOrEdit/Index';
import AssignSuppliesModal from './AssignSupplies/Index';

const Activities = (props) => {
	const { project_id, code, package_id, maintenance_standard_id } = props;

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalItems, setTotalItems] = useState(10);
	const [activitySel, setActivitySel] = useState({});				// Programa de Mantenimiento seleccionado
	const [modalActivity, setModalActivity] = useState(false);
	const [modalActivityUpdate, setModalActivityUpdate] = useState(false);
	const [modalAssign, setModalAssign] = useState(false);

	useEffect(() => { getData(); }, [page, pageSize]);

	const getData = async () => {
		setLoading(true);
		const response = await indexMaintenanceActivities({ project_id, maintenance_standard_id, page, pageSize });
		setData(response.data);
		setTotalItems(response.totalItems);
		setLoading(false);
	}

	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	}

	const editMaintenanceActivity = (item) => {
		setActivitySel(item);
		setModalActivityUpdate(true);
	}

	const assigSupplies = (item) => {
		setActivitySel(item);
		setModalAssign(true);
	}
	return (
		<>
			{/* Lista de las actividades de mantenimiento */}
			<TableComp
				headers={['#', 'Código', 'Nombre', 'Unidad de Medida', 'Volumen', 'Creado en', 'Acciones']}
				keys={['#', 'code', 'name', 'unit', 'total_amount', 'created_at', '']}
				body={data}
				loading={loading}
				loadingItems={pageSize}
				page={page}
				actions={[
					{ icon: faEdit, variant: 'outline-primary', tooltip: 'Editar actividad de mantenimiento', handleClick: editMaintenanceActivity },
					{ icon: faBoxes, variant: 'outline-success', tooltip: 'Asignar suministros', handleClick: assigSupplies },
				]}
			/>

			{/* Componente para paginación */}
			<PaginationComp totalItems={totalItems} current={page} onChange={changePage} defaultPageSize={pageSize} />

			{/* Agregar nueva actividad */}
			<FlexButton icon={faPlus} style={{ bottom: 20, right: 20 }} onClick={() => setModalActivity(true)} />
			{/* Modal para la nueva actividad */}
			{
				modalActivity && <AddOrEdit
					type='store'
					onClose={() => setModalActivity(false)}
					syncServer={getData}
					code={code}
					project_id={project_id}
					package_id={package_id}
					maintenance_standard_id={maintenance_standard_id}
				/>
			}
			{/* Modal para editar o agregar una actividad */}
			{
				modalActivityUpdate && <AddOrEdit
					type='update'
					onClose={() => setModalActivityUpdate(false)}
					activity={activitySel}
					syncServer={getData}
					code={code}
					project_id={project_id}
					package_id={package_id}
				/>
			}
			{/* Modal para la asignación de suministros */}
			{
				modalAssign && <AssignSuppliesModal
					onClose={() => setModalAssign(false)}
					activity={activitySel}
					package_id={package_id}
					standard_id={maintenance_standard_id}
				/>
			}
		</>
	)
}

export default Activities