import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexPackages = async () => {

    const url = `/packages`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        if (fetch.status === STATUS_OK){
            response = { data: fetch.data, totalItems: fetch.data.length };
        }
        return response;
    } catch (error) {
        return response;
    }
}