export const dateToString = (date) => {
    /* date: String #Format = 0000-00-00T00:00:00.000Z, 0000-00-00 00:00:00*/
    if(date !== null){
        if(date !== ''){
            let separador = date.indexOf("T") !== -1 ? "T" : " ";
            let fecha = (date.split(separador)[0]);
            let hora = date.split(separador)[1];
            if(hora.indexOf(".") !== -1){
                hora = hora.split(".")[0];
            }
            let meses = ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
            let dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
            let dia = (new Date(parseInt(fecha.split('-')[0],10), parseInt(fecha.split('-')[1],10) - 1, parseInt(fecha.split('-')[2],10))).getDay();
            let mes = parseInt(fecha.split('-')[1], 10);
            let format = dias[dia] + ", " + fecha.split('-')[2] + " de " + meses[mes] + " de " + fecha.split('-')[0] + ' a las ' + hora;
            return format;
        }
    }
    return date;
}

export const dateToString2 = (date) => {
    /* date: String #Format = 0000-00-00T00:00:00.000Z, 0000-00-00 00:00:00, 0000-00-00*/
    if(date !== null){
        if(date !== ''){
            if(date.length === 10){                
                
                let meses = ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
                let dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

                let dia = (new Date(parseInt(date.split('-')[0],10), parseInt(date.split('-')[1],10) - 1, parseInt(date.split('-')[2],10))).getDay();
                let mes = parseInt(date.split('-')[1], 10);
                let format = dias[dia] + ", " + date.split('-')[2] + " de " + meses[mes] + " de " + date.split('-')[0];
                return format;
            }else{
                let separador = date.indexOf("T") !== -1 ? "T" : " ";
                let fecha = (date.split(separador)[0]);
                
                let meses = ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
                let dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
                let dia = (new Date(parseInt(fecha.split('-')[0],10), parseInt(fecha.split('-')[1],10) - 1, parseInt(fecha.split('-')[2],10))).getDay();
                let mes = parseInt(fecha.split('-')[1], 10);
                let format = dias[dia] + ", " + fecha.split('-')[2] + " de " + meses[mes] + " de " + fecha.split('-')[0];
                return format;
            }
            
        }
    }
    return date;
}

export const stringToDate = (date) => {
    if(date !== null){
        if(date !== ''){
            let anio = parseInt(date.split('-')[0], 10);
            let mes = parseInt(date.split('-')[1], 10) - 1;
            let dia = 1;
            if(date.length === 10){
                dia = parseInt(date.split('-')[2], 10);
                return new Date(anio, mes, dia);
            }else{
                let separador = date.indexOf('T') !== -1 ? 'T' : ' ';
                let fecha_hora = date.split(separador);
                dia = parseInt(fecha_hora[0].split('-')[2], 10);
                let hora = parseInt(fecha_hora[1].split(':')[0], 10);
                let minuto = parseInt(fecha_hora[1].split(':')[1], 10);
                let segundo = parseInt(fecha_hora[1].split(':')[2], 10);
                return new Date(anio, mes, dia, hora, minuto, segundo);
            }
        }
    }
    return new Date();
}

export const stringToDateUTC = (date) => {
    dateUTC(stringToDate(date));
}

export const normalizeDate = (date) => {
    /* date: String #Format = 0000-00-00T00:00:00.000Z, 0000-00-00 00:00:00*/
    if(date !== null){
        if(date !== ''){
            if(date.indexOf("T") !== -1){
                date = date.replace("T", " ");
            }
            if(date.indexOf(".") !== -1){
                date = date.split(".")[0];
            }
        }
    }
    return date;
}

export const dateUTC = (date) => {
    if(date !== null){
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }
    return date;
}