import React, { Component } from "react";
import { getKmFormat } from 'components/libs/functions';
import Pagination from '../../../Pagination'

const Grid = class extends Component {
    render() {
        return (
            <div>
                <Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
                <section className="dataPage">
                    <label>Número de elementos: {this.props.data.data_per_page}</label>
                </section>
                <div className="cards">
                    {
                        this.props.data.data.map(item =>
                            <div className="card" key={item.id}>
                                <div className="card-header"><strong>Código: {item.code}</strong> {item.type_event.name}</div>
                                <div><strong>Fecha de aviso: </strong> {item.notice_date.split('T')[0]} {item.notice_time} </div>
                                <div><strong>Cuerpo: </strong> {item.body} <strong>PK inicial (KM ± m): </strong>{getKmFormat(item.initial_pk)} </div>
                                <div><strong>Número de siniestro: </strong>{item.sinister}</div>
                                <div><strong>Notificación: </strong>{item.means_notification}</div>
                                <div><strong>Unidad: </strong>{item.unit}</div>
                                <br />
                                <div><strong>Notificaciones</strong></div>
                                {(item.intervention_time !== null) ? <div><strong>Intervención: </strong> {item.intervention_time} <strong> Cumplimiento: </strong>{item.compliance_intervention}</div> : null}
                                {(item.signaling_time !== null) ? <div><strong>Señalización: </strong> {item.signaling_time}</div> : null}
                                {(item.insurance_notification_time !== null) ? <div><strong>Seguro: </strong> {item.insurance_notification_time} <strong> Cumplimiento: </strong>{item.compliance_insurance}</div> : null}
                                {(item.insurance_arrival_time !== null) ? <div><strong>Llegada del seguro: </strong> {item.insurance_arrival_time}</div> : null}
                                {(item.PFP_notification_time !== null) ? <div><strong>PFP: </strong> {item.PFP_notification_time} <strong> Cumplimiento: </strong>{item.compliance_PFP}</div> : null}
                                {(item.ambulance_notification_time !== null) ? <div><strong>Ambulancia: </strong> {item.ambulance_notification_time} <strong> Cumplimiento: </strong>{item.compliance_ambulance}</div> : null}
                                {(item.firefighter_notification_time !== null) ? <div><strong>Bomberos: </strong> {item.PFP_notification_time} <strong> Cumplimiento: </strong>{item.compliance_firefighter}</div> : null}
                                {(item.SEMEFO_notification_time !== null) ? <div><strong>SEMEFO: </strong> {item.SEMEFO_notification_time} <strong> Cumplimiento: </strong>{item.compliance_SEMEFO}</div> : null}
                                {(item.MR_notification_time !== null) ? <div><strong>M&R: </strong> {item.MR_notification_time} <strong> Cumplimiento: </strong>{item.compliance_MR}</div> : null}
                                <br />
                                <div><strong>Detalles</strong></div>
                                {(item.accident_rate !== null) ? <div><strong>Siniestralidad: </strong> {item.accident_rate}</div> : null}
                                <div><strong>Estado: </strong> {item.incident_status} <strong> Tipo: </strong>{item.type_road} </div>
                                <div><strong>Factores atmosféricos: </strong> {item.atmospheric_factors}</div>
                                <table>
                                    <tr>
                                        <th>Vehículos</th>
                                        <th>Ocupantes</th>
                                        <th>Muertos</th>
                                        <th>Heridos</th>
                                    </tr>
                                    <tr>
                                        <td>{item.number_vehicles}</td>
                                        <td>{item.number_occupants}</td>
                                        <td>{item.number_dead}</td>
                                        <td>{item.number_injured}</td>
                                    </tr>
                                </table>
                            </div>)
                    }
                </div>
                <Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
            </div>
        );
    }
}

export default Grid;