import React, { useEffect, useState } from 'react'
import '../../css/Budgets.css';
import { getMaintenanceStandards } from 'api/maintenance_standards/getMaintenanceStandards';
import { indexMaintenanceBudgets } from 'api/maintenance_standards/budgets'
import usePagination from 'hooks/usePagination';
import { getProjects } from 'api/general/getProjects';
import { Col, Row } from 'antd';
import TableComp from 'components/layouts/Table';
import PaginationComp from 'components/layouts/Pagination';
import StandardList from './StandardList';
import Header from './Header';
import AddBudget from './AddBudget';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const moment = require('moment');

const BudgetsComp = () => {
    const hookProjects = usePagination({ defaultPageSize: 10 });
    const hookStandards = usePagination({ defaultPageSize: 50 });
    const hookBudgets = usePagination({ defaultPageSize: 20 });
    const { page, pageSize, totalItems } = hookBudgets.pagination;
    const [modalAdd, setModalAdd] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);

    useEffect(() => {
        const getProjectsData = async () => {
            hookProjects.setLoading(true);
            const fetch = await getProjects();
            if (Array.isArray(fetch)) {
                hookProjects.setData(fetch);
                hookProjects.setDataSel(fetch[0]);
            }
            hookProjects.setLoading(false);
        }
        getProjectsData();
    }, []);

    useEffect(() => {
        const getMStandards = async () => {
            hookStandards.setLoading(true);
            const fetch = await getMaintenanceStandards();
            hookStandards.setData(fetch);
            hookStandards.setLoading(false);
        }
        getMStandards();
    }, []);

    useEffect(() => {
        const getBudgets = async () => {
            hookBudgets.setLoading(true);
            const maintenance_standard_id = hookStandards.dataSel?.id;
            const project_id = hookProjects.dataSel?.id;
            const fetch = await indexMaintenanceBudgets({ project_id, maintenance_standard_id, page, pageSize });
            hookBudgets.setData(fetch.data.map(item => {
                // Especifica que la fecha está en formato UTC desde el principio
                const month_year = moment.utc(item.month_year, 'YYYY-MM-DD').startOf('day').format('MMMM YYYY');
                return {
                    ...item,
                    month_year
                }
            }));
            hookBudgets.setTotalItems(fetch.totalItems);
            hookBudgets.setLoading(false);
        }
        if (hookStandards.dataSel && hookProjects.dataSel) getBudgets();
    }, [hookStandards.dataSel, hookProjects.dataSel, page, pageSize]);

    const addBudget = () => setModalAdd(true)

    const onEdit = (item) => {
        hookBudgets.setDataSel(item);
        setModalEdit(true)
    }

    return (
        <Row id='budgets-container'>
            <StandardList hookStandards={hookStandards} />
            <Col id='budgets-panel' xs={24} sm={24} md={18} lg={16}>
                <Header
                    hookProjects={hookProjects}
                    hookStandards={hookStandards}
                    addBudget={addBudget}
                />
                <Row>
                    <div style={{ width: '100%' }}>
                        <TableComp
                            headers={['#', 'Mes-Año', 'Presupuesto', 'Fecha de creación', 'Acciones']}
                            keys={['#', 'month_year', 'budget', 'created_at']}
                            body={hookBudgets.data}
                            loading={hookBudgets.loading}
                            moment_dates={[{ index: 3, format: 'LLL' }]}
                            currency={[{ index: 2 }]}
                            loadingItems={pageSize}
                            page={page}
                            actions={[{ icon: faEdit, variant: 'outline-primary', tooltip: 'Editar presupuesto', handleClick: onEdit }]}
                        />
                        <PaginationComp
                            current={page}
                            defaultPageSize={pageSize}
                            totalItems={totalItems}
                            onChange={hookBudgets.changePage}
                        />
                    </div>
                </Row>
            </Col>
            {/* Modal para crear registros */}
            {
                modalAdd && <AddBudget
                    onClose={() => setModalAdd(false)}
                    hookProjects={hookProjects}
                    hookStandards={hookStandards}
                    hookBudgets={hookBudgets}
                />
            }
            {/* Modal para actualizar registros */}
            {
                modalEdit && <AddBudget
                    isEdit
                    onClose={() => setModalEdit(false)}
                    hookProjects={hookProjects}
                    hookStandards={hookStandards}
                    hookBudgets={hookBudgets}
                />
            }
        </Row>
    )
}

BudgetsComp.propTypes = {}

export default BudgetsComp