import React from 'react'
import { Col, Form } from 'react-bootstrap';

const AssignSchedule = (props) => {
    const {
        users,
        activities,
        handleChange,
        form,
    } = props;
    return (
        <>
            <Form.Group as={Col}>
                <Form.Label>Responsable</Form.Label>
                <select className="form-control" name={"responsable_id"} disabled={false} onChange={handleChange} value={form?.responsable_id}>
                    {
                        users.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.first_name} {item.last_name}
                            </option>
                        ))
                    }
                </select>
            </Form.Group>

            <Form.Group as={Col}>
                <Form.Label>Actividad</Form.Label>
                <select className="form-control" disabled={false} name={"activity_id"} onChange={handleChange} value={form?.activity_id}>
                    {activities.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.code} - {item.name}
                        </option>
                    ))}
                </select>
            </Form.Group>
            <Form.Group>
                <Form.Label>Periodicidad</Form.Label>
                <select name={"periodicity"} className={"form-control"}
                    defaultValue={"dontrepeat"} onChange={handleChange}
                >
                    <option value={"dontrepeat"}>No se repite</option>
                    <option value={"everyday"}>Cada día</option>
                    {/* <option value={"everyweek"}>
                        Cada semana el{" "}
                        {formatDate(form?.start_date, "dddd")}
                    </option>
                    <option value={"everymonth"}>
                        Cada mes el último{" "}
                        {formatDate(form?.start_date, "dddd")}
                    </option>
                    <option value={"everyyear"}>
                        Anualmente el{" "}
                        {formatDate(form?.start_date, "DD")} de{" "}
                        {formatDate(form?.start_date, "MMMM")}
                    </option> */}
                    <option value={"lmxjv"}>De lunes a viernes</option>
                    <option value={"custom"}>Personalizar...</option>
                </select>
            </Form.Group>
        </>
    )
}

export default AssignSchedule