import fetcherFa from "components/libs/fetcherfacturapi";

const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_TEST_SECRET_KEY}`,
}

export const indexCustomers = async () => {
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcherFa({
            method: 'GET',
            url: '/customers',
            headers
        });
        response = {
            data: fetch.data.data,
            totalItems: fetch.data.total_results
        }
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response
    }
}