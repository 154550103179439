import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param {number} project_id Identificador de la autopista
 * @param {number} release_id Identificador del comunicado
 * @returns Información acerca del correo que fue enviado | False en caso de que no se hayan enviado los emails
 */
export const sendEmailReleases = async ({ project_id, release_id }) => {
    let response = false;
    try {
        let fetch = await fetcher({
            method: 'GET',
            url: `/projects/${project_id}/releases/${release_id}/send-email`
        });
        // console.log("🚀 ~ file: releases.js:19 ~ sendEmailReleases ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        // console.log("🚀 ~ file: releases.js:10 ~ sendEmailReleases ~ error", error)
        return response;
    }
}