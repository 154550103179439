import React, { useState } from 'react'
import ModalComp from './layouts/Modal'
import TabsComp from './layouts/Tabs'
import ButtonIcon from './layouts/ButtonIcon'
import { faCheckCircle, faEye, faEyeSlash, faSave, faSignOutAlt, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { updateUsers } from 'api/users/users'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FormControl, FormErrorMessage, FormLabel, Heading, Input, InputGroup, InputRightElement, List, ListIcon, ListItem } from '@chakra-ui/react'
import { Skeleton } from 'antd'
import { UPDATE_DATA_USER } from 'store/actions/actionTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'react-bootstrap'

const moment = require('moment');

// Componente de información general
const GeneralComp = ({ userData, package_id }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().max(20),
        last_name: Yup.string(),
        email: Yup.string().email('Correo electrónico inválido'),
        phone: Yup.string().max(10).min(10)
    });

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const { id, project_id, road_section_id } = userData;
        const data = { package_id: userData.package_id, project_id, road_section_id, ...values };
        const { response } = await updateUsers({ package_id, user_id: id, data });
        if (response) {
            dispatch({ type: UPDATE_DATA_USER, data: values });
            Swal.fire('¡Operación exitosa!', 'Su información se ha actualizado satisfactoriamente.', 'success');
        } else {
            Swal.fire('¡Operación fallida!', 'Hubo un error al tratar de realizar la solicitud.', 'warning');
        }
        setLoading(false);
    }
    return (<>
        {
            loading ? <Skeleton active /> :
                <Formik
                    initialValues={{
                        first_name: userData.first_name || '',
                        last_name: userData.last_name || '',
                        email: userData.email || '',
                        phone: userData.phone || ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form className='d-flex flex-column'>
                            <Field name='first_name' className='mt-4'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.first_name && form.touched.first_name}>
                                        <FormLabel htmlFor='first_name'>Nombre(s)</FormLabel>
                                        <Input {...field} id='first_name' placeholder='Nombre(s)' />
                                        <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='last_name' className='mt-4'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.last_name && form.touched.last_name}>
                                        <FormLabel htmlFor='last_name'>Apellido(s)</FormLabel>
                                        <Input {...field} id='last_name' placeholder='Apellido(s)' />
                                        <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='email' className='mt-4'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                        <FormLabel htmlFor='email'>Correo electrónico</FormLabel>
                                        <Input {...field} id='email' placeholder='Correo electrónico' />
                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='phone' className='mt-4'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                                        <FormLabel htmlFor='phone'>Número Telefónico</FormLabel>
                                        <Input {...field} id='phone' placeholder='Número Telefónico' />
                                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <ButtonIcon className='mt-4 align-self-end' type='submit' icon={faSave} name='Guardar cambios' variant='outline-success' onClick={() => { }} />
                        </Form>
                    )}
                </Formik>
        }
    </>)
}

// Componente para cambiar la contraseña
const PasswordComp = ({ userData, package_id }) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState({
        password: false,
        confirm_password: false
    });
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8)
            .required('Inserte su nueva contraseña'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
            .min(8)
            .required('Confirme su contraseña')
    });

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const { password } = values;
        const { id, project_id, road_section_id } = userData;
        const data = { package_id: userData.package_id, project_id, road_section_id, password };
        const { response } = await updateUsers({ package_id, user_id: id, data });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'Su contraseña se ha cambiado de manera satisfactoria.', 'success');
        } else {
            Swal.fire('¡Operación fallida!', 'Hubo un error al tratar de realizar la solicitud.', 'warning');
        }
        setLoading(false);
    }

    return (<>
        {
            loading ? <Skeleton active /> :
                <Formik
                    initialValues={{ password: '', confirm_password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form className='d-flex flex-column'>
                            <Field name='password' className='mt-4'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.password && form.touched.password}>
                                        <FormLabel htmlFor='password'>Nueva contraseña</FormLabel>
                                        <InputGroup>
                                            <Input {...field} type={show.password ? 'text' : 'password'} id='password' placeholder='Nueva contraseña' />
                                            <InputRightElement
                                                color='gray.300'
                                                children={<FontAwesomeIcon icon={show.password ? faEye : faEyeSlash} />}
                                                onClick={() => setShow(prevState => ({ ...prevState, password: !show.password }))}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='confirm_password' className='mt-4'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.confirm_password && form.touched.confirm_password}>
                                        <FormLabel htmlFor='confirm_password'>Confirmar contraseña</FormLabel>
                                        <InputGroup>
                                            <Input {...field} type={show.confirm_password ? 'text' : 'password'} id='confirm_password' placeholder='Confirmar contraseña' />
                                            <InputRightElement
                                                color='gray.300'
                                                children={<FontAwesomeIcon icon={show.confirm_password ? faEye : faEyeSlash} />}
                                                onClick={() => setShow(prevState => ({ ...prevState, confirm_password: !show.confirm_password }))}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.confirm_password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <ButtonIcon className='mt-4 align-self-end' type='submit' icon={faUserLock} name='Actualizar contraseña' variant='outline-success' onClick={() => { }} />
                        </Form>
                    )}
                </Formik>
        }
    </>
    )
}

// Datos del paquete
const DataPackageComp = ({ userData, stages, closeSession }) => {
    const { has_two_factor, two_factor_since } = userData;
    return (
        <>
            <Heading className='mt-4' style={{ color: '#000' }} size='lg'>{userData?.package.name}</Heading>
            <Heading className='mt-4 mb-4' style={{ color: '#000' }} size='sm'>{userData?.position}</Heading>

            {
                stages.length > 0 && <>
                    <List spacing={3} className='mt-4 mb-4'>
                        {
                            stages.map((stage) => <>
                                <ListItem key={stage.id}>
                                    <ListIcon as={FontAwesomeIcon} icon={faCheckCircle} color='green.500' />
                                    {stage.name}
                                </ListItem>
                            </>)
                        }
                    </List>
                </>
            }

            {
                has_two_factor ?
                    <small> Autenticación en dos pasos activa desde {moment(two_factor_since).format('LLLL')} </small> : null
            }
            <div className='mt-4'>
                <ButtonIcon
                    icon={faSignOutAlt}
                    name='Cerrar sesión'
                    variant='outline-secondary'
                    tooltip='Cerrar sesión'
                    onClick={closeSession}
                />
            </div>
        </>
    )
}

// Componente principal, tabs
const Profile = ({ onClose, stages, closeSession }) => {
    const userData = useSelector(state => state.login.dataUser);
    const package_id = useSelector(state => state.login.package_id);
    return (<div className='modal-profile'>
        <ModalComp
            onClose={onClose}
            title={'Perfil de usuario: ' + userData?.username}
            size='lg'
            keyboard={true}
            body={<>
                <Row className='align-items-start'>
                    <Col sm={12} lg={6}>
                        <DataPackageComp userData={userData} stages={stages} closeSession={closeSession} />
                    </Col>
                    <Col sm={12} lg={6}>
                        <TabsComp
                            tabColor='black'
                            tablist={['Información general', 'Cambiar contraseña']}
                            tabpanels={[
                                <GeneralComp key='tab-1' userData={userData} package_id={package_id} />,
                                <PasswordComp key='tab-2' userData={userData} package_id={package_id} />
                            ]}
                        />
                    </Col>
                </Row>

            </>}
        />
    </div>
    )
}

export default Profile