import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import './NivelServicioPlazaCobro.css'

import './Operacion.css';
import './Resultados.css';

import { __SERVER__ } from '../../../../server/info';
import Pagination from '../Pagination';
import FAB from '../../../generales/FAB.jsx';
import { Variables } from '../../../../variables/Variables';
import ModalUpload from '../Modal.jsx';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Deductiva from '../Deductiva.jsx';
import Axios from 'axios';
import { Heading } from '@chakra-ui/react';

const _ = require('underscore');
const MySwal = withReactContent(Swal)


const FiltroBusqueda = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				creation_dateS: "2019-11-01",
				creation_dateE: "2019-11-02",
				tollbooth_id: 0,
				lane_id: 0
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: "",
			tollbooths: [],
			lanes: []
		};
	}

	generateQuery = () => {
		if (this.state.query.creation_dateE === "" || this.state.query.creation_dateS === "") {
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "0") {
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
		this.props.setProps(this.state.query_, headersTable)
	}

	componentDidMount = () => {
		this.getTollbooths()
	}

	getLanes = async () => {
		if (this.state.query.tollbooth_id === "") {
			let lanes = []
			this.setState({ lanes: lanes })
			return;
		}
		fetch(__SERVER__ + "/projects/tollbooths/" + this.state.query.tollbooth_id + "/lanes", {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(res => {
			let lanes = []
			if (res !== null) {
				lanes.push(<option value={0} key={'L'}></option>)
				for (let i = 0; i < res.length; i++) {
					lanes.push(<option value={res[i].id} key={'L' + i}>{res[i].name}</option>)
				}
			}
			this.setState({ lanes: lanes })
		}).catch(e => {
			this.getLanes()
		})
	}

	getTollbooths = async () => {
		fetch(__SERVER__ + "/projects/" + this.props.project_id + "/tollbooths", {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(res => {
			let tollbooths = []
			if (res !== null) {
				tollbooths.push(<option value={0} key={'T'}></option>)
				for (let i = 0; i < res.length; i++) {
					tollbooths.push(<option value={res[i].id} key={'T' + i}>{res[i].code}</option>)
				}
			}
			this.getLanes()
			this.setState({ tollbooths: tollbooths })
		}).catch(e => {
			this.getTollbooths()
		})
	}

	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="creation_dateS" value={this.state.query.creation_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="creation_dateE" value={this.state.query.creation_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<label>Plaza</label>
					<select name="tollbooth_id" onChange={(e) => { this.changeInput(e); this.getLanes(); }}>
						{this.state.tollbooths.map(item => item)}
					</select>
				</article>
				<article>
					<label>Carril</label>
					<select name="lane_id" onChange={this.changeInput}>
						{this.state.lanes.map(item => item)}
					</select>
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const FiltroBusqueda_ = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				date_reportS: "2017-11-01",
				date_reportE: "2019-11-02",
				lane: "0",
				cameraE: "0"
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: "",
		};
		this.generateQuery = this.generateQuery.bind(this)
	}

	generateQuery = () => {
		if (this.state.query.date_reportS === "" || this.state.query.date_reportE === "") {
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "0") {
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
		this.props.setProps(this.state.query_, headersTable)
	}


	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="date_reportS" value={this.state.query.date_reportS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="date_reportE" value={this.state.query.date_reportE} onChange={this.changeInput} />
				</article>
				<article>
					<label>Carril</label>
					<select name="lane" onChange={this.changeInput}>
						<option value=""></option>
						<option value="Carril 1">Carril 1</option>
						<option value="Carril 2">Carril 2</option>
					</select>
				</article>
				<article>
					<label>Cámara</label>
					<select name="cameraE" onChange={this.changeInput}>
						<option value=""></option>
						<option value="ALTA">ALTA</option>
						<option value="BAJA">BAJA</option>
					</select>
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const Grid = class extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.data_per_page}</label>
				</section>
				<table>
					<tr>
						<th>Fecha</th>
						<th>Parcial</th>
						<th>Plaza</th>
						<th>Carril</th>
						<th>Estado</th>
						<th>Tiempo de cruce</th>
					</tr>
					{
						this.props.data.data.map((item, i) => <tr key={i}>
							<td>{item.creation_date.split('T')[0]}</td>
							<td>{item.partial.split('T')[1].split('.')[0]}</td>
							<td>{item.tollbooth.code}</td>
							<td>{item.lane.name}</td>
							<td>{item.status}</td>
							<td>{item.cross_time}</td>
						</tr>)
					}
				</table>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
			</div>
		);
	}
}

const Grid_ = class extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.data_per_page}</label>
				</section>
				<table>
					<tr>
						<th>Fecha</th>
						<th>Carril</th>
						<th>Cámara</th>
						<th>Placa de entrada</th>
						<th>Hora de entrada</th>
						<th>Placa de salida</th>
						<th>Hora de salida</th>
						<th>Tiempo (s)</th>
					</tr>
					{
						this.props.data.data.map((item, i) => <tr key={i}>
							<td>{item.date_report.split('.')[0].replace('T', ' ')}</td>
							<td>{item.lane}</td>
							<td>{item.cameraE}</td>
							<td>{item.plateE}</td>
							<td>{item.timeE}</td>
							<td>{item.plateS}</td>
							<td>{item.timeS}</td>
							<td>{item.cross_time}</td>
						</tr>)
					}
				</table>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
			</div>
		);
	}
}

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalUploadData: [false, false],
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			result2: [],
			refresh: false,
			tap: 1,
			page: 1,
			page2: 1,

			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false,
			buttonVisible: false
		}
	}

	updatePage = (page) => {
		if (this.state.tap === 1) {
			this.setState({ page }, this.getTollboothLevel);
		} else {
			this.setState({ page2: page }, this.getTollboothLevel);
		}
	}

	setProps = (query, headersTable) => {
		if (this.state.tap === 1) {
			this.setState({ page: 1, query: query, result: [], headersTable: headersTable }, this.getTollboothLevel)
		} else {
			this.setState({ page2: 1, query: query, result2: [], headersTable: headersTable }, this.getTollboothLevel)
		}

	}

	generateTable = () => {
		let data = [];
		if (this.state.tap === 1) {
			data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];
		} else {
			data = this.state.result2[_.findIndex(this.state.result2, { page: this.state.page2 })];
		}

		this.setState({
			grid: null
		}, () => {
			if (data !== undefined) {
				if (this.state.tap === 1) {
					this.setState({ grid: <Grid updatePage={this.updatePage} data={data} /> })
				} else if (this.state.tap === 2) {
					this.setState({ grid: <Grid_ updatePage={this.updatePage} data={data} /> })
				} else {
					this.setState({ grid: null });
				}
			} else {
				this.setState({ grid: null });
			}
		});
	}

	getTollboothLevel = () => {
		let index = -1;
		if (this.state.tap === 1) {
			index = _.findIndex(this.state.result, { page: this.state.page });
		} else {
			index = _.findIndex(this.state.result2, { page: this.state.page2 });
		}

		if (index !== -1) {
			this.generateTable();
			return;
		}
		let query = this.state.query + (this.state.query === '' ? '?' : `&`) + (`page=${this.state.tap === 1 ? this.state.page : this.state.page2}`);

		let table = "";
		if (this.state.tap === 2) {
			table = "VICTUM_";
		}
		fetch(`${__SERVER__}/projects/${this.props.project_id}/${table}tollbooth_level${query}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					if (this.state.tap === 1) {
						this.state.result.push(result);
					} else {
						this.state.result2.push(result);
					}
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			Swal.fire('Error', e, 'error');
		})
	}

	componentDidMount = async () => {
		// GET operating_indicator_id
		let res = await Axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')
		
		if (res.data.length!==0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	setTap = (tap) => {
		this.setState({
			tap
		}, this.generateTable);
	}

	openOrCloseModal = () => {
		this.state.modalUploadData[this.state.tap - 1] = !this.state.modalUploadData[this.state.tap - 1];
		this.setState({ refresh: !this.state.refresh });
	}

	openOrCloseModalDeductivas = () => {
		this.setState({ modalDeductivas: !this.state.modalDeductivas });
	}

	render() {
		const options = [{ title: this.props.code, value: 1 }, { title: 'VICTUM', value: 2 }];
		return (
			<div className="body_">
				<div style={{ background: '#1a2530', width: '100%', paddingLeft: 10, paddingBottom: 10, marginTop: -10, paddingTop: 10 }}>
					<ToggleButtonGroup type="radio" name="options" defaultValue={this.state.tap}>
						{
							options.map(item =>
								<ToggleButton
									variant="primary"
									value={item.value}
									checked={this.state.tap === item.value}
									style={{ background: this.state.tap === item.value ? "#FFF" : '#2d4050', color: this.state.tap === item.value ? '#2d4050' : "#FFF" }}
									onChange={(e) => this.setTap(item.value)} >
									{item.title}
								</ToggleButton>
							)
						}
					</ToggleButtonGroup>
				</div>
				{this.state.tap === 1 ?
					<div>
						<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
						<FiltroBusqueda project_id={this.props.project_id} setProps={this.setProps} />
						{this.state.grid}
					</div>
					: null}

				{this.state.tap === 2 ?
					<div>
						<Heading className='tituloSub'>{"VICTUM " + this.props.code + " " + this.props.name}</Heading>
						<FiltroBusqueda_ project_id={this.props.project_id} setProps={this.setProps} />
						{this.state.grid}
					</div>
					: null}
				{
					this.state.buttonVisible ?
						<FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faCarCrash'} onClick={this.openOrCloseModalDeductivas} /> : null
				}
				<FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />

				{
					this.state.tap === 1 ? this.state.modalUploadData[0] ?
						<ModalUpload
							server={`${__SERVER__}/projects/${Variables.project_id}/tollbooth_level`}
							items={[
								{ label: 'DIA AUTOCONTROL', description: 'Dia de autocontrol', value: 'creation_date', type: 'String', toFormat: 'Date' },
								{ label: 'PARCIAL', description: 'Hora parcial', value: 'partial', type: 'String', toFormat: 'Time' },
								{ label: 'PLAZA COBRO', description: 'Nombre de la plaza de cobro', value: 'tollbooth', type: 'String' },
								{ label: 'CARRIL', description: 'Nombre del carril', value: 'lane', type: 'String' },
								{ label: 'ESTADO CARRIL', description: 'Estado del carril', value: 'status', type: 'String' },
								{ label: 'TIEMPO CRUCE', description: 'Tiempo de cruce', value: 'cross_time', type: 'String', allow: null }
							]}
							title={`${this.props.code} ${this.props.name}`}
							onClose={this.openOrCloseModal} />
						: null : null
				}

				{
					this.state.tap === 2 ? this.state.modalUploadData[1] ?
						<ModalUpload
							server={`${__SERVER__}/projects/${Variables.project_id}/VICTUM_tollbooth_level`}
							items={[
								{ label: 'CAMARAE', description: 'Camara', value: 'cameraE', type: 'String', allow: null },
								{ label: 'CAMARA', description: 'Nombre del Carril', value: 'lane', type: 'String' },
								{ label: 'FECHA', description: 'Fecha', value: 'date_report', type: 'String', toFormat: 'Date' },
								{ label: 'PLACAE', description: 'Placa de entrada', value: 'plateE', type: 'String', allow: null },
								{ label: 'HORAE', description: 'Hora de entrada', value: 'timeE', type: 'String', toFormat: 'Time', allow: null },
								{ label: 'PLACA', description: 'Placa de salida', value: 'plateS', type: 'String', allow: null },
								{ label: 'HORA', description: 'Hora de salida', value: 'timeS', type: 'String', toFormat: 'Time', allow: null },
								{ label: 'TIEMPO DE CRUCE', description: 'Tiempo de cruce en segundos', value: 'cross_time', type: 'Number', allow: null },
							]}
							title={`${this.props.code} ${this.props.name}`}
							onClose={this.openOrCloseModal} />
						: null : null
				}
				{
					this.state.modalDeductivas ?
						<Deductiva
							title={this.props.code}
							formula={this.state.formula}
							pum={this.state.PUMObject}
							cump={this.state.CumplObject}
							formulaCump={this.state.formulaCump}
							formulaPUM={this.state.formulaPUM}
							hayFormula={this.state.hayFormula}
						/> : null
				}
			</div>
		);
	}
}
