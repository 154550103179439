import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

const LoadingDoc = () => {
    return (
        <section className='d-flex align-item-center'>
            <LoadingOutlined className='mr-2' />Cargando documento
        </section>
    )
}

export default LoadingDoc;