import FormIcon from 'components/layouts/FormIcon';
import React, { Fragment } from 'react';
import { Row } from 'react-bootstrap';

const AddressTab = (props) => {
    const { address } = props;
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title='Calle' name='street' value={address?.street} />
                <FormIcon title='Número interior' name='interior' value={address?.interior} />
                <FormIcon title='Número exterior' name='exterior' value={address?.exterior} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Colonia' name='neighborhood' value={address?.neighborhood} />
                <FormIcon title='Municipio' name='municipality' value={address?.municipality} />
                <FormIcon title='Ciudad' name='city' value={address?.city} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Código Postal' name='zip' value={address?.zip} />
                <FormIcon title='Estado' name='state' value={address?.state} />
                <FormIcon title='País' name='country' value={address?.country} />
            </Row>
        </Fragment>
    )
}

export default AddressTab