import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexApps = async ({ package_id, ...query }) => {
    const url = `/packages/${package_id}/apps`;
    let response = { data: [], totalItems: 0 };
    let params = {};
    if (query) {
        const queryValues = Object.values(query);
        const queryKeys = Object.keys(query);
        for (let index = 0; index < queryValues.length; index++) {
            const element = queryValues[index];
            if (element !== '-1')
                params[queryKeys[index]] = element;
        }
    }
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: apps.js:23 ~ indexApps ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            const { data, totalItems } = fetch.data;
            response = { data, totalItems };
        }
        return { response };
    } catch (error) {
        return response;
    }
}

export const downloadLogoApps = async ({ package_id, id, file_name }) => {
    const url = `/packages/${package_id}/apps/${id}/logo/download`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: { file_name }
        });
        // console.log("🚀 ~ file: apps.js:44 ~ downloadApps ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const getUrlApps = async ({ package_id, id, file_name }) => {
    const url = `/packages/${package_id}/apps/${id}/apk/download`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { file_name }
        });
        // console.log("🚀 ~ file: apps.js:64 ~ getUrlApps ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const createAppRequest = async ({ package_id, data }) => {
    const url = `/packages/${package_id}/app_requests`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: apps.js:82 ~ createAppRequest ~ fetch:", fetch)
        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok)
            response = fetch.data
        return { response };
    } catch (error) {
        return response;
    }
}