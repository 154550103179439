import React, { useState, useEffect } from 'react'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import FormIcon from 'components/layouts/FormIcon';
import { storeMaintenanceActivities, updateMaintenanceActivities } from 'api/operation_standards/maintenance_activities';
import Swal from 'sweetalert2';
import { indexMaintenanceSections } from 'api/operation_standards/maintenance_sections';

const AddOrEditActivity = (props) => {
    const { onClose,
        activity,
        syncServer,
        project_id,
        package_id,
        type,
        code
    } = props;

    const [data, setData] = useState({});

    useEffect(() => {
        if (type === 'store')
            getMaintenanceSection();
    }, []);

    const getMaintenanceSection = async () => {
        let response = await indexMaintenanceSections({ package_id, operating_indicator_code: code });
        if (response.data.length > 0) {
            setData({ ...data, maintenance_sections_id: response.data[0].id });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const store = async () => {
        let response = await storeMaintenanceActivities({ project_id, data });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'La actividad de mantenimiento ha sido agregada satisfactoriamente', 'success');
            syncServer();
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de almacenar la actividad de mantenimiento', 'error');
        }
    }

    const update = async () => {
        let response = await updateMaintenanceActivities({ id: activity?.id, data });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'La actividad de mantenimiento ha sido actualizada con exito.', 'success');
            syncServer();
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al actualizar los datos.', 'error');
        }
    }
    return (
        <ModalComp
            onClose={onClose}
            title={`${type === 'store' ? 'Registrar' : 'Actualizar'} actividad`}
            body={<>
                <Row>
                    <FormIcon
                        title='Código'
                        name='code'
                        defaultValue={activity?.code}
                        value={data.code}
                        handleChange={handleChange} />
                </Row>
                <Row>
                    <FormIcon
                        title='Descripción'
                        name='description'
                        defaultValue={activity?.description}
                        value={data.description}
                        handleChange={handleChange} />
                </Row>
                <Row>
                    <FormIcon
                        title='Unidad'
                        name='unit'
                        defaultValue={activity?.unit}
                        value={data.unit}
                        handleChange={handleChange} />
                </Row>
                <Row>
                    <FormIcon
                        title='Volumen'
                        type='number'
                        name='total_amount'
                        defaultValue={activity?.total_amount}
                        value={data.total_amount}
                        handleChange={handleChange} />
                </Row>
                <Row>
                    <FormIcon
                        title='Precio Unitario'
                        type='number'
                        name='unit_price'
                        defaultValue={activity?.unit_price}
                        value={data.unit_price}
                        handleChange={handleChange} />
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <Form.Label>Instrucciones</Form.Label>
                        <textarea
                            className='form-control'
                            name='instructions'
                            defaultValue={activity?.instructions}
                            value={data.instructions}
                            onChange={handleChange} />
                    </FormGroup>
                </Row>
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                {
                    type === 'store' ?
                        <ButtonIcon icon={faSave} name='Agregar' variant='outline-success' onClick={store} /> :
                        <ButtonIcon icon={faSave} name='Actualizar' variant='outline-success' onClick={update} />
                }
            </>}
        />
    )
}

export default AddOrEditActivity