import { OPEN_SESSION, UPDATE_DATA_USER } from '../actions/actionTypes';
import { stateLogin as initialState } from '../initialState/InitialState';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SESSION:
            return {
                ...state,
                session: action.session,
                package_id: action.package_id,
                dataUser: action.dataUser
            };
        case UPDATE_DATA_USER:
            const { session, package_id, dataUser } = state;
            return {
                session,
                package_id,
                dataUser: {
                    ...dataUser,
                    ...action.data
                }
            }
        default:
            return state;
    }
};

export default reducer;