import { gql } from "@apollo/client";

export const INSERT_ROOM = gql`
  mutation InsertRoom(
    $name: name = "_"
    $room_type_id: Int = 1
    $data: [messenger_room_users_insert_input!]!
  ) {
    insert_messenger_rooms(
      objects: {
        room_type_id: $room_type_id
        name: $name
        room_users: { data: $data }
      }
    ) {
      returning {
        id
        room_users {
          id
        }
      }
    }
  }
`;

// Actualizar el nombre de la sala
export const UPDATE_ROOM = gql`
  mutation UpdateRoom($name: name, $_eq: Int) {
    update_messenger_rooms(
      _set: { name: $name }
      where: { id: { _eq: $_eq } }
    ) {
      affected_rows
    }
  }
`;

// Eliminar la relación de ROOM-USER para que no tenga acceso a la sala
export const DESTROY_ROOM_USER = gql`
  mutation DestroyRoom($user_id: Int, $room_id: Int) {
    delete_messenger_room_users(where: { 
      user_id: { _eq: $user_id }, 
      room_id: {_eq: $room_id} 
      }) {
      affected_rows
    }
  }
`;
