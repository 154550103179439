import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';

const Filters = ({ searchStr, setFilters, filters }) => {

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevState => ({ ...prevState, [name]: value }));
    }
    return (
        <Row className='mb-2'>
            <Col xs={10}>
                <input
                    className='form-control'
                    name='str'
                    placeholder={'Buscar Descripción'}
                    value={filters?.str}
                    onChange={handleChange}
                />
            </Col>
            <Col xs={2}>
                <ButtonIcon size='md' name='Buscar' icon={faSearch} variant='outline-primary' onClick={searchStr} />
            </Col>
        </Row>
    )
}

export default Filters