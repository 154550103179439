import React, { useEffect, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import { showMaintenanceProgram } from 'api/maintenance_standards/maintenance_program';
import { Skeleton } from 'antd';
import moment from 'moment';

const ModalDetails = (props) => {
    const { onClose, programSel, project_id } = props;

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { id } = programSel;
        let response = await showMaintenanceProgram({ project_id, id });
        setData(response);
        setLoading(false);
    }
    return (
        <ModalComp
            onClose={onClose}
            title='Detalles del programa'
            size='lg'
            body={<>
                {
                    loading ? <Skeleton /> : <>
                        <h4>{data?.activity?.code} - {data?.activity?.name}</h4>
                        <label><strong>Responsable: </strong>{data?.responsable_name}</label><br />
                        <label><strong>ID Activo: </strong>{data?.asset_table_id}</label><br />
                        <label><strong>Periodo de Mantenimiento: </strong>{
                            moment(data?.start_date).utc().format('LL')}
                            - {moment(data?.end_date).utc().format('LL')}
                        </label><br />
                        <label><strong>Horario: </strong>De {data?.start_time} - {data?.end_time} Hrs</label><br />
                        <label><strong>Días programados: </strong>{data?.flow?.totalItems}</label><br />
                    </>
                }
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
            </>}
        />
    )
}

export default ModalDetails