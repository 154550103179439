import { getSegments } from 'api/general/getSegments';
import { getStatus } from 'api/general/getStatus';
import { getMaintenanceStandards } from 'api/maintenance_standards/getMaintenanceStandards';
import React, { useState, useEffect } from 'react'
import { notification } from 'antd';
import { Button } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import ModalComp from 'components/layouts/Modal';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import LoadingDoc from '../Entregables/LoadingDoc';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import ReportGeneral from './ReportGeneral';

const Search = ({form, setForm, setfilters,filters, setDatafilters, data}) => {
	const [status, setStatus] = useState(null)
	const [Show, setShow] = useState(false);
	const [segments, setSegments] = useState(null)
	const [MaintenanceStandards, setMaintenanceStandards] = useState(null)

	useEffect(() => {
		getData()
	}, [])
	
	const getData = async () => {
		const resStatus = await getStatus()
		setStatus(resStatus);
		
		const resSegments = await getSegments()
		setSegments(resSegments);

		const resMaintenanceStandards = await getMaintenanceStandards();
		setMaintenanceStandards(resMaintenanceStandards)
	}

	const handleSearch = () => {
		if(((form.creation_dateS !== '') && (form.creation_dateE === '')) || 
		   ((form.creation_dateS === '') && form.creation_dateE !== '')){
			openNotificationWithIcon('info')
		} else {
			const filter = Object.fromEntries(
				Object.entries(form).filter(([key, value]) => value !== '')
			);
			setDatafilters(filter)
			setfilters(filters+1)
		}
	}
	
	const handleChange = ({target}) => {
		const { name, value } = target;
		setForm({ 
			...form, 
			[name]: value 
		});
	}

	const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = (type) => {
		api[type]({
		message: 'Importante',
		description:'El rango de fechas es obligatorio'});
	};

	const handleCreateReport = () => setShow(true)
	const closeButton = () => setShow(false)
	
	return (
		<section className="sectionSearch">
			{contextHolder}
			<article>
				<label>Fecha inicial</label>
				<input type='date' name="creation_date_start" onChange={handleChange} />
			</article>
			<article>
				<label>Fecha final</label>
				<input type='date' name="creation_date_end" onChange={handleChange} />
			</article>
			<article>
				<label>Estado</label>
				<select name="status_id" onChange={handleChange}>
					<option value=''>Todos</option>
					{
						status && status.map(({id, name}) => <option key={id} value={id}>{name}</option>)
					}
				</select>
			</article>
			<article>
				<label>Estándar</label>
				<select name="standard_id" onChange={handleChange}>
					<option value=''>Todos</option>
					{
						MaintenanceStandards && MaintenanceStandards.map(({id, name}) => 
							<option key={id} value={id}>{name}</option>
						)
					}
				</select>
			</article>
			<article>
				<label>Segmento</label>
				<select name="segment_id" onChange={handleChange}>
					<option value=''>Todos</option>
					{
						segments && segments.map(({id, name}) => <option key={id} value={id}>{name}</option>)
					}
				</select>
			</article>
			<article>
				<button className="botonBusqueda" onClick={handleSearch}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 20 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
			</article>
			<Button variant='danger' className="mx-2" onClick={handleCreateReport}>
				<FaFilePdf />
			</Button>

			{
				Show && <ModalComp
				onClose={closeButton}
				size="sm"
				title="Reporte General"
				body={<>
							<PDFDownloadLink
								document={<ReportGeneral data={data} />}
								fileName="Reporte_de_General_Incidencias.pdf">
								{
									({ loading }) => loading ? <LoadingDoc /> : <Button className='w-100' variant="success" ><FaFilePdf className="mr-1" />Reporte Listo para descargar</Button>
								}
							</PDFDownloadLink>
		
							<BlobProvider document={<ReportGeneral data={data} />}>
								{({ url, loading }) =>
									loading ? <LoadingDoc /> : (<a className="text-white btn btn-primary mt-1" href={url} target="_blank" rel="noopener noreferrer"><ExternalLinkIcon className="mr-1" />Abrir reporte en una nueva ventana</a>
								)}
							</BlobProvider>
						</>}
				/>
			}
			
		</section>
	)
}

export default Search;