import React from 'react';
import Main from './Main';

const OBAC1 = (props) => {
	const { project_id, code, name, permission } = props;
	/**
	 * 	Estructura de la tabla de la que se lee la información
	 * 	key: Nombre del atributo de la tabla
	 * 	label: Etiqueta con la que se identificará en el formulario o tabla
	 * 	description: Información adicional para mostrar en tooltips, PENDIENTE
	 * 	table: Los elementos con el valor TRUE se muestran en la tabla
	 * 	hidden: Elementos que no se muestran en el formulario
	 */
	const items = [
		{ key: '#', label: '#', description: '', table: true, width: 15 },
		{ key: 'type_id', label: 'Tipo de elemento', description: '', table: true, hidden: true, width: 15 },
		{ key: 'code', label: 'Código', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true, pdf: true },
		{ key: 'description', label: 'Descripción', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true },
		{ key: 'n_serie', label: 'No. Serie', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true, pdf: true },
		{ key: 'way', label: 'Vía', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true },
		{ key: 'tollbooth', label: 'Plaza', description: '', table: true, edit: true, add: true, type: 'text', width: 15, xlsx: true },
		{ key: 'assets_base_line_id', label: 'Línea base', description: '', table: true, width: 15, xlsx: true },
		{ key: 'check_status', label: 'Estado de Verificación', description: '', table: true, width: 15, xlsx: true, pdf: true },
		{ key: 'id', label: 'ID', description: '', width: 15, xlsx: true, pdf: true },
		{ key: 'project_id', label: 'ID Autopista', description: '', hidden: true, width: 15 },
		{ key: 'km', label: 'Cadenamiento', description: '', edit: true, add: true, type: 'number', width: 15, xlsx: true, pdf: false },
		{ key: 'body', label: 'Cuerpo', description: '', edit: true, add: true, type: 'text', width: 15, xlsx: true },
		{ key: 'direction', label: 'Sentido', description: '', edit: true, add: true, type: 'text', width: 15, xlsx: true },
		{ key: 'latitude', label: 'Latitud', description: '', edit: true, width: 15, xlsx: true },
		{ key: 'longitude', label: 'Longitud', description: '', edit: true, width: 15, xlsx: true },
		{ key: 'altitude', label: 'Altitud', description: '', edit: true, width: 15, xlsx: true },
		{ key: 'others', label: 'Otros', description: '', edit: true, add: true, type: 'text', width: 15, xlsx: true },
		{ key: 'extra_columns', label: 'Atributos extra', description: '', width: 15, xlsx: true },
		{ key: 'type_name', label: 'Tipo de Elemento', description: '', width: 15, xlsx: true, pdf: true },
		{ key: 'created_at', label: 'Creado en', description: '', width: 15, xlsx: true },
		{ key: 'updated_at', label: 'Actualizado en', description: '', width: 15, xlsx: true },
		{ key: 'removed', label: 'Visibilidad', description: '', hidden: true, width: 15 },
		{ key: 'segment_id', label: 'Segmento', description: '', hidden: true, width: 15, xlsx: true },
		{ key: 'extra', label: 'Valores Adicionales', description: '', hidden: true, width: 15, xlsx: true },
	];

	return (
		<Main
			project_id={project_id}		// Identificador de la autopista
			code={code}					// Código de la sección, ej. UOV-5.2
			name={name}					// Nombre de la sección, ej. ITS
			table='assets_infrastructure'			// Nombre de la tabla de los activos
			badges={[{ index: 1 }, { index: 7 }, { index: 8 }]}		// Posiciones de la tabla que tendrán badges
			items={items}
			user_code='Gerente de Sistemas'
			km={[]}
			permission={permission}
		/>
	)
}

export default OBAC1