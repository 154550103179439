import React from 'react'
import { notification } from 'antd';

const Search = ({project, setForm, form, setfilters}) => {

    const handleChange = ({target}) => {
		const { name, value } = target;
		setForm({ 
			...form, 
			[name]: value 
		});
	}

    const handleSearch = () => {
        if(form.creation_dateS === '' && form.creation_dateE !== ''){
            openNotificationWithIcon('info')
        } else {
            const filter = Object.fromEntries(
				Object.entries(form).filter(([key, value]) => value !== '')
			);
            setfilters(filter)
        }
    }

    const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = type => {
		api[type]({
		message: 'Importante',
		description:'La primer fecha es obligatorio'});
	};

    return (
        <div>
            <section className="sectionSearch d-flex justify-content-around align-items-center">
                {contextHolder}
                <article>
                    <label>Fecha inicial</label>
                    <input type='date' name="creation_dateS" onChange={handleChange} />
                </article>
                <article>
                    <label>Fecha final</label>
                    <input type='date' name="creation_dateE" onChange={handleChange} />
                </article>
                <article>
                    <label>Proyecto</label>
                    <select name="project_id" onChange={handleChange}>
                        <option value=''>Elige un proyecto</option>
                        {
                            project?.map(item => <option value={item.id} key={item.id}>{item.name}</option>)
                        }
                    </select>
                </article>
                <article>
                    <button className="botonBusqueda" onClick={handleSearch}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 20 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
                </article>
            </section>
        </div>
    )
}

export default Search