import React from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { __SERVER__ } from 'server/info';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { Variables } from 'variables/Variables';

class EditarPrograma extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            uploading: false,
            start_date: this.props.program.start_date,
            end_date: this.props.program.end_date,
            start_time: this.props.program.start_time,
            end_time: this.props.program.end_time,
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + (d.getDate() + 1),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    saveDateTimeProgram = async () => {
        // Actualizar victum maintenance program
        await Axios.put(__SERVER__ + `/projects/${Variables.project_id}/victum_maintenance_program/${this.props.program.id}`, {
            asset_activity_id: this.props.program.asset_activity_id,
            start_date: this.formatDate(this.state.start_date),
            end_date: this.formatDate(this.state.end_date),
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            maintenance_types_id: this.props.program.maintenance_types_id,
            maintenance_sections_id: this.props.program.maintenance_sections_id
        }).then((response) => {
            console.log(response);
        });
        this.handleClose();
    }

    render = () => {
        return (
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Editar programa de mantenimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <center>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Responsable</Form.Label>
                                    <select className="form-control" defaultValue={this.props.program.asset_activity.responsable_id}
                                        disabled={true}>
                                        {
                                            this.props.users.map((item, i) =>
                                                <option key={i} value={item.id}>{item.first_name} {item.last_name}</option>
                                            )
                                        }
                                    </select>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Actividad</Form.Label>
                                    <select className="form-control" defaultValue={this.props.program.asset_activity.maintenance_activity_id}
                                        disabled={true}>
                                        {
                                            this.props.maintenance_activities.map((item, i) =>
                                                <option key={i} value={item.id}>{item.description}</option>
                                            )
                                        }
                                    </select>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Fecha inicial</Form.Label>
                                    <input type="date" name="fecha_inicio" defaultValue={this.formatDate(this.state.start_date)} className="form-control" onChange={e => {
                                        console.log(e.target.value);
                                        this.setState({ start_date: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Fecha final</Form.Label>
                                    <input type="date" name="fecha_final" defaultValue={this.formatDate(this.state.end_date)} className="form-control" onChange={e => {
                                        console.log(e.target.value);
                                        this.setState({ end_date: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Hora inicial</Form.Label>
                                    <input type="time" name="fecha_inicio" defaultValue={this.state.start_time} className="form-control" onChange={e => {
                                        console.log(e.target.value + ":00");
                                        this.setState({ start_time: e.target.value + ":00" });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Hora final</Form.Label>
                                    <input type="time" name="fecha_final" defaultValue={this.state.end_time} className="form-control" onChange={e => {
                                        console.log(e.target.value + ":00");
                                        this.setState({ end_time: e.target.value + ":00" });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </center>

                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.action === 'edit' ? <Button variant="primary" onClick={this.handleClose}>Guardar cambios</Button> : null
                    }

                    {
                        this.props.action === 'delete' ? <Button variant="danger" onClick={this.handleClose}>Eliminar programa</Button> : null
                    }

                    {
                        this.props.action === 'view' ? <Button variant="secondary" onClick={this.handleClose}>Cerrar</Button> : null
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}

export default EditarPrograma;