import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal';
import AssetInfo from '../fragments/AssetDetails';
import TabsComp from 'components/layouts/Tabs';
import { indexAssetsComponentTypes } from 'api/operation_standards/assets_component_types';
import AssetComponents from '../fragments/AssetComponent/Index'
import { indexAssetsGlobal } from 'api/operation_standards/assets_global';
import AssetGlobal from '../fragments/AssetGlobal';

const AssetDetails = (props) => {
    const { onClose, asset, items, table, project_id, syncServer, package_id } = props;
    const childRef = useRef(null);

    const [buttonUpdate, setButtonUpdate] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [tablist, setTablist] = useState(['Información general']);
    const [componentTypes, setComponentTypes] = useState([]);
    const [global, setGlobal] = useState([]);

    useEffect(() => {
        const getTabs = async () => {
            // Se consulta si pertenece a un activo global
            const global = await indexAssetsGlobal({ project_id, table, assets_its_id: asset.id });
            let h = [];
            if (global.response.data.length > 0) {
                const { data, totalItems } = global.response;
                // Se guarda el activo global para mostrarlo en una pestaña
                setGlobal([<AssetGlobal
                    key={'global'}
                    data={data}
                    totalItems={totalItems}
                    table={table}
                    project_id={project_id}
                />]);
                h = ['Global']
            }
            // Se obtienen los tipos de componentes de ITS
            const { response } = await indexAssetsComponentTypes({ package_id, table });
            setComponentTypes(response.data)
            // Se almacenan las pestañas que se mostrarán
            setTablist([...tablist, ...h, ...response.data.map(item => item.name)]);
        }
        // Funcionalidad sólo para ITS
        if (table === 'assets_its') getTabs();

    }, []);

    const onUpdate = () => {
        childRef.current.updateData();
    }

    return (
        <ModalComp
            onClose={onClose}
            title='Detalles del elemento'
            size='xl'
            body={
                <TabsComp
                    tabColor='black'
                    tablist={tablist}
                    tabpanels={[
                        // Panel principal, detalles del activo
                        <AssetInfo
                            key='1'
                            ref={childRef}
                            items={items}
                            asset={asset}
                            setButtonUpdate={setButtonUpdate}
                            table={table}
                            project_id={project_id}
                            syncServer={syncServer}
                            onClose={onClose}
                            setButtonDisabled={setButtonDisabled}
                            buttonDisabled={buttonDisabled}
                        />,
                        // Panel de los activos globales
                        ...global,
                        // Paneles de los tipos de componentes
                        ...componentTypes.map(item =>
                            <AssetComponents
                                key={item.id}
                                component={item}
                                asset={asset}
                                table={table}
                            />
                        )
                    ]}
                />
            }
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                {
                    buttonUpdate &&
                    <ButtonIcon icon={faSave} name='Actualizar' variant='outline-success' onClick={onUpdate} disabled={buttonDisabled} />
                }
            </>}
        />
    )
}

AssetDetails.propTypes = {
    onClose: PropTypes.func,
    asset: PropTypes.object,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string,
            description: PropTypes.string,
            table: PropTypes.bool,
            hidden: PropTypes.bool,
            width: PropTypes.number,
            edit: PropTypes.bool,
            add: PropTypes.bool,
            type: PropTypes.oneOf([
                'text',
                'number',
                'textarea'
            ]),
            xlsx: PropTypes.bool,
            pdf: PropTypes.bool,
        })
    ),
    project_id: PropTypes.number.isRequired,
    table: PropTypes.oneOf([
        'assets_its',
        'assets_environment',
        'assets_edification',
        'assets_infrastructure'
    ]).isRequired,
    syncServer: PropTypes.func.isRequired,
    package_id: PropTypes.number.isRequired,
}

export default AssetDetails