import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faEye, faFileExcel, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { deleteMaintenanceProgram, indexMaintenanceProgram } from 'api/maintenance_standards/maintenance_program';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table';
import ModalDetails from './ModalDetails';
import Filters from './Filters';
import Swal from 'sweetalert2';
import FlexButton from 'components/layouts/FlexButton';
import ModalReport from './ModalReport';

const moment = require('moment');

const MaintenanceProgram = ({ project_id, package_id, maintenance_standard_id }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalItems, setTotalItems] = useState(10);
	const [programSel, setProgramSel] = useState({});				// Programa de Mantenimiento seleccionado
	const [modalDetails, setModalDetails] = useState(false);
	const [modalReport, setModalReport] = useState(false);
	const [filters, setFilters] = useState({});

	useEffect(() => { getData(); }, [page, pageSize]);

	const getData = async () => {
		setLoading(true);
		const response = await indexMaintenanceProgram({ project_id, page, pageSize, ...filters, maintenance_standard_id });
		setData(response.data.map((item) => {
			const { start_date, start_time, end_date, end_time, created_at } = item;
			const s_date = moment(start_date).utc().format('LL');
			const e_date = moment(end_date).utc().format('LL');
			return {
				...item,
				date_range: `${s_date} a ${e_date}`,
				time_range: `De ${start_time} a ${end_time} hrs`,
				created_at: `${moment(created_at).format('LLLL')}`
			};
		}));
		setTotalItems(response.totalItems);
		setLoading(false);
	}
	const viewDetails = (item) => {
		setProgramSel(item);
		setModalDetails(true);
	}
	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	}
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFilters({ ...filters, [name]: value });
	}
	const actionDelete = (item) => {
		const { id, responsable_name } = item;
		Swal.fire({
			title: '¿Está seguro que desea realizar esta operación?',
			text: `Usted está tratando de eliminar un Programa de Mantenimiento asignada a ${responsable_name}, 
			lo que significa que también se borrarán los días que fueron programados.`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, eliminarlo'
		}).then(async (result) => {
			if (result.isConfirmed) {
				const { response } = await deleteMaintenanceProgram({ project_id, id });
				if (response) {
					setData(prevState => prevState.filter(item => item.id !== id));
					Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
				} else
					Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
			} else {
				Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
			}
		})
	}
	// Método para descargar reporte de rendimientos
	const downloadReport = async (item) => {
		setModalReport(true);
	}
	return (
		<>
			{/* Filtros de búsqueda */}
			<Filters filters={filters} handleChange={handleChange} getData={getData} />
			{/* Tabla para mostrar el programa de mantenimiento */}
			<TableComp
				headers={['#', 'Actividad', 'Responsable', 'Período fechas', 'Período horas', 'Fecha de asignación', 'Acciones']}
				keys={['#', 'asset_name', 'responsable_name', 'date_range', 'time_range', 'created_at']}
				body={data}
				loading={loading}
				loadingItems={pageSize}
				page={page}
				actions={[
					{ icon: faEye, variant: 'outline-primary', tooltip: 'Ver detalles del programa de mantenimiento', handleClick: viewDetails },
					{ icon: faTrashAlt, variant: 'outline-danger', tooltip: 'Eliminar programa de mantenimiento', handleClick: actionDelete }
				]}
			/>

			{/* Componente para paginación */}
			<PaginationComp totalItems={totalItems} current={page} onChange={changePage} defaultPageSize={pageSize} />

			{/* Modal para visualizar los detalles del programa seleccionado */}
			{
				modalDetails && <ModalDetails
					onClose={() => setModalDetails(false)}
					programSel={programSel}
					project_id={project_id}
				/>
			}
			{/* Botón para descargar el reporte de rendimientos */}
			<FlexButton style={{ bottom: 20, right: 20 }} onClick={downloadReport} icon={faFileExcel} />
			{/* Modal para ajustar parámetros de descarga */}
			{
				modalReport && <ModalReport
					onClose={() => setModalReport(false)}
					project_id={project_id}
					package_id={package_id}
					maintenance_standard_id={maintenance_standard_id}
				/>
			}
		</>
	)
}

MaintenanceProgram.propTypes = {
	project_id: PropTypes.number.isRequired,
	package_id: PropTypes.number.isRequired,
	maintenance_standard_id: PropTypes.number.isRequired
}

export default MaintenanceProgram