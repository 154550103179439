import React, { useEffect, useState } from 'react'
import { deleteMaintenanceProgram, indexMaintenanceProgram } from 'api/operation_standards/maintenance_program';
import EditMaintenanceProgram from '../modals/EditMaintenanceProgram';
import ViewMaintenanceProgram from '../modals/ViewMaintenanceProgram';
import DeleteMaintenanceProgram from '../modals/DeleteMaintenanceProgram';
import { indexMaintenanceActivities } from 'api/operation_standards/maintenance_activities';
import MaintenanceProgramTable from '../fragments/MaintenanceProgramTable';
import FiltersProgram from '../fragments/FiltersProgram';
import Swal from 'sweetalert2';

const MaintenanceProgramTab = ({ project_id, package_id, maintenance_sections_id }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);							// Colección de programa de mantenimiento
	const [filters, setFilters] = useState({});
	const [activities, setActivities] = useState([]);
	const [programSel, setProgramSel] = useState({});				// Programa de Mantenimiento seleccionado

	const [modalUpdate, setModalUpdate] = useState(false);			// Estado del modal para editar un elemento de programa de mantenimiento
	const [modalDelete, setModalDelete] = useState(false);			// Estado del modal para eliminar un elemento de programa de mantenimiento
	const [modalView, setModalView] = useState(false);				// Estado del modal para ver los detalles del programa de mantenimiento

	useEffect(() => {
		getMaintenanceProgram();
	}, []);

	const getMaintenanceProgram = async () => {
		setLoading(true);
		await getActivities();
		let response = await indexMaintenanceProgram({ package_id, project_id, maintenance_sections_id, ...filters });
		setData(response.data);
		setLoading(false);
	}

	const getActivities = async () => {
		let response = await indexMaintenanceActivities({ project_id });
		setActivities(response.data);

	}

	const actionEdit = (item) => {
		setProgramSel(item);
		setModalUpdate(!modalUpdate);
	}

	const actionView = (item) => {
		setProgramSel(item);
		setModalView(!modalView);
	}

	const actionDelete = (item) => {
		const { id, asset_activity } = item;
		const { responsable, maintenance_activity_id } = asset_activity;
		Swal.fire({
			title: '¿Está seguro que desea realizar esta operación?',
			text: `Usted está tratando de eliminar un Programa de Mantenimiento asignada a ${responsable.first_name} ${responsable.last_name}, 
			lo que significa que también se borrarán los días que fueron programados para llevar a cabo la actividad 
			"${getAssetActivityName(maintenance_activity_id)}".`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, eliminarlo'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let response = await deleteMaintenanceProgram({ project_id, id });
				if (response) {
					setData(prevState => prevState.filter(item => item.id !== id));
					Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
				} else
					Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
			} else {
				Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
			}
		})
	}
	// Método para obtener el nombre de la actividad de mantenimiento
	const getAssetActivityName = (id) => {
		const activity = activities.find((activity) => activity.id === id);
		if (activity) {
			return `${activity.code}-${activity.description}`;
		}
		return '';
	};


	const handleChange = (e) => {
		const { name, value } = e.target;
		setFilters({ ...filters, [name]: value });
	}

	return (
		<>
			<FiltersProgram filters={filters} handleChange={handleChange} getData={getMaintenanceProgram} />
			{/* Programa de mantenimiento existente */}
			<MaintenanceProgramTable
				theaders={['#', 'Actividad', 'Responsable', 'Rango de fechas', 'Horario', 'Fecha de asignación', 'Acciones']}
				getAssetActivityName={getAssetActivityName}
				loading={loading}
				data={data}
				actionDelete={actionDelete}
				actionEdit={actionEdit}
				actionView={actionView}
			/>
			{/* Modal para editar programa de mantenimiento */}
			{
				modalUpdate && <EditMaintenanceProgram
					onClose={() => setModalUpdate(!modalUpdate)}
					programSel={programSel}
				/>
			}
			{/* Modal para ver programa de mantenimiento */}
			{
				modalView && <ViewMaintenanceProgram
					onClose={() => setModalView(!modalView)}
					programSel={programSel}
				/>
			}
			{/* Modal para eliminar programa de mantenimiento */}
			{
				modalDelete && <DeleteMaintenanceProgram
					onClose={() => setModalDelete(!modalDelete)}
					programSel={programSel}
				/>
			}
		</>
	)
}

export default MaintenanceProgramTab