import React from 'react';
export const styles = {
    input_file: {
        display: 'none'
    },
    input_file_label: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2d4050',
        color: 'white',
        flexDirection: 'column',
        padding: 20,
        borderRadius: 5,
        width: 250,
        cursor: 'pointer',
        textAlign: 'center' 
    },
}


export function Temp({props}) {
    return(
        <div style={{
           textAlign: 'center' 
        }}>

        </div>)
}

