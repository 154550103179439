import React, { useEffect, useState } from 'react';
import 'moment/locale/es';
import { Col, Row } from 'antd';
import _ from 'underscore';
const moment = require('moment')

const Message = (props) => {
    const { alignMsg, msg, user_id, users } = props;
    const [nickname, setNickname] = useState('Desconocido');

    useEffect(() => {
        getNickname();
        moment.locale('es-MX');
    }, []);

    const getNickname = () => {
        if (user_id === msg.user_id) {
            setNickname('Tú');
        } else {
            let pos = _.findIndex(users, { id: msg.user_id });
            if (pos !== -1) setNickname(`${users[pos].first_name} ${users[pos].last_name}`);
        }
    }

    return (
        <Row style={{
            marginTop: '5px'
        }}>
            <Col span={11} offset={alignMsg === 'end' ? 13 : 0} style={{
                border: 'solid 0.5px',
                borderColor: alignMsg === 'end' ? '#eeffde' : '#fff',
                borderRadius: '7.5px',
                backgroundColor: alignMsg === 'end' ? '#eeffde' : '#fff',
                padding: '10px 15px 0px 15px'
            }}>
                <p style={{ fontWeight: 'bold' }}>{nickname}</p>
                <p>{msg.message}</p>
                <div className='d-flex justify-content-end mt-0 pt-0 mb-0 pb-0'>
                    <p>{moment(msg.created_at).startOf('second').fromNow()}</p>
                </div>
            </Col>
        </Row>
    )
}

export default Message;