import { faInfo, faPlus, faSuitcaseRolling, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { indexInventoryData, updateInventoryData } from 'api/maintenance_standards/inventory_data';
import FlexButton from 'components/layouts/FlexButton';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import ModalAddInventory from './ModalAddInventory';
import ModalAssignActivity from './ModalAssignActivity';
import ModalDetailsInventory from './ModalDetailsInventory';

const InventoryData = (props) => {
    const {
        items,
        inventory,
        table,
        onClose,
        project_id,
        percentage,
        km
    } = props;
    //                              HOOKS
    // -----------------------------------------------------------------------------------------
    const package_id = useSelector(state => state.login.package_id);
    const [theaders, setTheaders] = useState([]);               // Encabezados de la tabla
    const [tkeys, setTkeys] = useState([]);                     // Claves del arreglo data que se mostraran
    const [data, setData] = useState([]);                       // Información que se va a mostrar como tuplas
    const [loading, setLoading] = useState(true);               // Indicador de carga de registros
    // Paginación - Optimizar para que los que ya se hayan recuperado se guarden con REDUX
    const [totalItems, setTotalItems] = useState(0);            // Cantidad de registros existentes
    const [page, setPage] = useState(1);                        // Índice de la página que se está mostrando
    const [pageSize, setPageSize] = useState(10);               // Tamaño de la página, es decir, cantidad de registros que se muestran
    const [modalDetails, setModalDetails] = useState(false);    // Modal para ver los detalles del registro seleccionado
    const [modalAdd, setModalAdd] = useState(false);            // Modal para agregar un elemento
    const [modalAssignActivity, setModalAssignActivity] = useState(false);   // Modal para asignar actividad de mantenimiento
    const [rowSel, setRowSel] = useState(null);                 // Registro seleccionado
    //                             EFFECTS
    // -----------------------------------------------------------------------------------------
    useEffect(() => {
        setTheaders(items.map((item) => item.show ? item.label : null));
        setTkeys(items.map((item) => item.show ? item.value : null));
    }, []);

    useEffect(() => {
        getInventoryData();
    }, [page, pageSize]);
    //                             METHODS
    // -----------------------------------------------------------------------------------------
    const getInventoryData = async () => {
        setLoading(true);
        let response = await indexInventoryData({
            table, page, pageSize,
            inventory_id: inventory?.id,
        });
        setData(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const changePage = (_page, _pageSize) => {
        setPage(_page);
        setPageSize(_pageSize);
    }

    const openOrCloseModalDetails = () => setModalDetails(!modalDetails);
    const openOrCloseModalAdd = () => setModalAdd(!modalAdd);
    const openOrCloseAssignActivity = () => setModalAssignActivity(!modalAssignActivity);

    const showDetails = (item) => {
        setRowSel(item);
        openOrCloseModalDetails();
    }

    const changeVisibility = (item) => {
        const { id } = item;
        Swal.fire({
            title: '¿Está seguro que desea eliminar este elemento?',
            text: 'Al realizar esta operación el elemento ya no será visible en las estadísticas ni en los reportes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, eliminar!',
            cancelButtonText: 'No, cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await updateInventoryData({ project_id, table, inventory_id: inventory?.id, id, data: { removed: 'removed' } });
                getInventoryData();	// Actualizar inventario
                Swal.fire(
                    'Operación exitosa!',
                    'Transacción realizada con éxito',
                    'success'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Operación cancelada',
                    'Esta transacción no se pudo realizar satisfactoriamente ',
                    'error'
                );
            }
        });
    }
    // Método para asignar actividad
    const assignActivity = (item) => {
        setRowSel(item);
        openOrCloseAssignActivity();
    }
    //                             RENDER
    // -----------------------------------------------------------------------------------------
    return (
        <div className="body_">
            {/* Tabla de los registros del inventario */}
            <TableComp
                headers={[...theaders, 'Verificación', 'Acciones']}
                keys={[...tkeys, 'check_status']}
                body={data}
                loading={loading}
                badges={[{ index: tkeys.length }]}
                badges_values={[{
                    values: [
                        { color: 'red', value: 'Sin Verificar' },
                        { color: 'orange', value: 'Parcialmente Verificado' },
                        { color: 'green', value: 'Verificado' }
                    ],
                    col: 'check_status'
                }]}
                percentage={percentage}
                km={km}
                actions={[
                    { icon: faInfo, handleClick: showDetails, tooltip: 'Ver detalles' },
                    { icon: faSuitcaseRolling, handleClick: assignActivity, tooltip: 'Asignar Actividad', variant: 'outline-success' },
                    { icon: faTrashAlt, handleClick: changeVisibility, tooltip: 'Eliminar elemento del inventario', variant: 'outline-danger' }
                ]}
            />
            {/* Componente de paginación */}
            <PaginationComp
                totalItems={totalItems}
                current={page}
                onChange={changePage}
            />
            {/* Modal para ver los detalles */}
            {
                modalDetails && <ModalDetailsInventory
                    onClose={openOrCloseModalDetails}
                    itemSel={rowSel}
                    items={items}
                    labelExtra={inventory?.labelExtra}
                    project_id={project_id}
                    table={table}
                    getInventoryData={getInventoryData}
                />
            }
            {/* Modal para agregar un elemento */}
            {
                modalAdd && <ModalAddInventory
                    onClose={openOrCloseModalAdd}
                    items={items}
                    labelExtra={inventory?.labelExtra}
                    project_id={project_id}
                    table={table}
                    inventory={inventory}
                    getInventoryData={getInventoryData}
                />
            }
            {/* Modal para asigna una actividad de Mantenimiento */}
            {
                modalAssignActivity && <ModalAssignActivity
                    onClose={openOrCloseAssignActivity}
                    project_id={project_id}
                    package_id={package_id}
                    table={table}
                    itemSel={rowSel}
                    type='RUTINARIO'
                    title='Programa de Mantenimiento Rutinario'
                />
            }
            {/* Regresar a la vista anterior */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={onClose} icon={faTimes} />
            {/* Agregar nuevo elemento al almacén */}
            <FlexButton style={{ bottom: 80, right: 20 }} onClick={openOrCloseModalAdd} icon={faPlus} />
        </div>
    )
}

export default InventoryData