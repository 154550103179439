import React from 'react';
import './App.css';
import App from './components/App';
import Login from './components/Login';
import { connect } from 'react-redux';

class Index extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
        this.props.session === 'active' ?
          <App />
        :<Login />
    );
  }

}

const mapStateToProps = state => ({
  session: state.login.session
});


export default connect(mapStateToProps, {})(Index);