import React, { useState } from 'react'
import ButtonIcon from 'components/layouts/ButtonIcon'
import { faFileExcel, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import ModalComp from 'components/layouts/Modal'
import FormIcon from 'components/layouts/FormIcon'
import { Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { getUrlMovementCapacityXlsx } from 'api/operation_standards/movement_capacity'
import { useSelector } from 'react-redux'

const DownloadXlsxModal = ({ onClose, project_id }) => {
    const package_id = useSelector(state => state.login.package_id);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const validate = () => {
        const { start_date, end_date } = data;
        return !!(start_date && end_date);
    }

    const onDownload = async () => {
        if (validate()) {
            setLoading(true);
            const { response } = await getUrlMovementCapacityXlsx({ project_id, package_id, ...data });
            if (response) {
                const link = document.createElement('a')
                link.href = response.signedUrl;
                document.body.appendChild(link)
                link.click();
            } else {
                Swal.fire('¡Error de red!', 'Hubo un error al realizar la petición, intentelo más tarde.', 'warning');
            }
            setLoading(false);
            onClose();
        } else {
            Swal.fire('¡Formulario inválido!', 'Revise el formulario.', 'warning');
        }
    }
    const handleChange = (e) => {
        const { value, name } = e.target;
        setData(prevState => ({ ...prevState, [name]: value }))
    }

    return (
        <ModalComp
            onClose={onClose}
            title={'Descargar Excel'}
            size='md'
            body={<>
                <Row>
                    <FormIcon
                        type='date'
                        title='Fecha de inicio'
                        name='start_date'
                        value={data?.start_date}
                        handleChange={handleChange}
                    />
                </Row>
                <Row>
                    <FormIcon
                        type='date'
                        title='Fecha de fin'
                        name='end_date'
                        value={data?.end_date}
                        handleChange={handleChange}
                    />
                </Row>
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' onClick={onClose} variant='outline-secondary' />
                <ButtonIcon
                    name={loading ? 'Obteniendo...' : 'Descargar'}
                    variant='outline-success'
                    onClick={onDownload}
                    icon={loading ? faSpinner : faFileExcel}
                    disabled={loading}
                    tooltipDisabled={true}
                />
            </>}
        />
    )
}

export default DownloadXlsxModal