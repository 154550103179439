import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Col, Image, Layout, Row } from 'antd'
import ButtonIcon from 'components/layouts/ButtonIcon';
import React, { useState } from 'react'
import MenuRoom from './MenuRoom';

const { Header } = Layout;

const HeaderRoom = (props) => {
    const { chatRoomSel, title, users, rooms, getRooms, user_id } = props;

    const [modalMenu, setModalMenu] = useState(false);

    const openOrCloseModalMenu = () => {
        setModalMenu(!modalMenu);
    }
    return (
        <Header style={{ color: '#fff', fontWeight: 'bold' }}>
            {
                chatRoomSel &&
                <Row>
                    <Col span={2}>
                        <Avatar
                            size={{ xs: 24, sm: 32, md: 35, lg: 40, xl: 45, xxl: 50 }}
                            src={<Image src="https://joeschmoe.io/api/v1/random" />}
                        />
                    </Col>
                    <Col span={20}>
                        {title}
                    </Col>
                    <Col offset={1} span={1}>
                        <ButtonIcon icon={faEllipsisV} variant='secondary' tooltip='Menú' onClick={openOrCloseModalMenu} />
                    </Col>
                </Row>
            }
            {
                modalMenu && <MenuRoom
                    onClose={openOrCloseModalMenu}
                    users={users}
                    room_id={chatRoomSel}
                    rooms={rooms}
                    getRooms={getRooms}
                    user_id={user_id}
                />
            }
        </Header>
    )
}

export default HeaderRoom