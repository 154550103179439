import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexObacTimes = async ({ project_id, segment_id, obac_factor_type_id }) => {
    const url = `/projects/${project_id}/obac_times`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params: {
                segment_id,
                obac_factor_type_id
            }
        });
        // console.log("🚀 ~ file: times.js:18 ~ indexObacTimes ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}