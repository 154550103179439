import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxxRE = () => {
    const table = 'emergency_braking_ramps_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 100 },
        { label: 'ELEMENTO', description: 'Elemento', value: 'element', type: 'Number', show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'FECHA', description: 'Fecha de inspección', value: 'inspection_date', type: 'Date', show: true, edit: true, add: true, width: 30, widthXLSX: 100 },
        { label: 'TRAMO', description: 'Tramo', value: 'section', type: 'String', show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'SENTIDO', description: 'Sentido', value: 'direction', type: 'String', show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'LONGITUD_INICIAL', description: 'Longitud inicial', value: 'initial_longitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'LATITUD_INICIAL', description: 'Latitud inicial', value: 'initial_latitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'CADENAMIENTO', description: 'Cadenamiento', value: 'km', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'ELEVACION', description: 'Elevación', value: 'elevation', type: 'String', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'ESTADO', description: 'Estado', value: 'status', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'OBSERVACIONES', description: 'Observaciones', value: 'observations', type: 'String', allow: null, edit: true, add: true, width: 30, widthXLSX: 100 },
        { label: 'FOTOGRAFIA', description: 'Fotografía', value: 'file_name', type: 'String', allow: null, width: 15, widthXLSX: 100 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
    ];

    const km = [{ index: 7 }];    // Arreglo de indices que irán con formato 000+000
    const percentage = [];                      // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxxRE;