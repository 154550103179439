import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexAssetsGlobal = async ({ project_id, table, ...params }) => {
    const url = `/projects/${project_id}/${table}/global`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: assets_global_components.js:13 ~ indexAssetsGlobalComponents ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}