import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const storeAssetActivity = async ({ project_id, data }) => {
    const url = `/projects/${project_id}/asset_activity`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: asset_activity.js ~ line 15 ~ storeAssetActivity ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}