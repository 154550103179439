import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';

const PartialTable = (props) => {
    const {
        title,
        array,
        arrayValues,
        setArrayValues,
        decimalAdjust,
        columns,
        long_description,
        total,
        setTotal,
        arrayObservations,
        setArrayObservations
    } = props;

    return (
        <>
            <tr>
                <th colSpan={columns}>{title}</th>
            </tr>
            <tr>
                <th className={'center'}>Código</th>
                <th className={'center'}>Descripción</th>
                <th className={'center'}>Unidad</th>
                <th className={'center'}>Disponible</th>
                <th className={'center'}>PU</th>
                <th className={'center'}>Asignación</th>
                <th className={'center'}>Costo</th>
                <th className={'center'}>Observaciones</th>
            </tr>
            {
                array.map((item, i) =>
                    <tr key={item.id}>
                        <td className={"center"}>{item.supplie.code}</td>
                        <td className={"left"}>
                            {item.supplie.description.length > long_description ?
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id={'tooltip-right'}>
                                            {item.supplie.description}
                                        </Tooltip>}>
                                    <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description.substring(0, long_description - 3)}...</Button>
                                </OverlayTrigger>
                                : <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description}</Button>}
                        </td>
                        <td className={"center"}>{item.supplie.unit}</td>
                        <td className={"right"}>{item.remaining.count}</td>
                        <td className={"right"}>$ {item.supplie.cost}</td>
                        <td className={"center"}><form onSubmit={(e) => e.preventDefault()}>
                            <input value={arrayValues[i]} min={0}
                                onChange={e => {
                                    if (e.target.value <= item.remaining.count) {
                                        let _total = total;
                                        _total -= decimalAdjust('round', (arrayValues[i] * item.supplie.cost), - 2);
                                        _total += decimalAdjust('round', (e.target.value * item.supplie.cost), -2);
                                        setTotal(_total);
                                        setArrayValues(arrayValues.map((item, index) => {
                                            let _item = item;
                                            if (index === i) _item = e.target.value;
                                            return _item;
                                        }));
                                    } else {
                                        Swal.fire('Cantidad incorrecta', `La cantidad elegida debe ser menor o igual a lo disponible\nDisponible: ${item.remaining.count}\nValor especificado: ${e.target.value}`, 'warning');
                                    }

                                }} type="number" max={item.remaining.count} /></form></td>
                        <td className={"right"}>$ {decimalAdjust('round', item.supplie.cost * arrayValues[i], -2)}</td>
                        <td className="left">
                            <form onSubmit={e => e.preventDefault()}>
                                <input
                                    value={arrayObservations[i] || ''}
                                    onChange={e => {
                                        const newValue = e.target.value;
                                        setArrayObservations(prevState =>
                                            prevState.map((item, index) => (index === i ? newValue : item))
                                        );
                                    }}
                                />
                            </form>
                        </td>
                    </tr>
                )
            }
            {
                array.length !== 0 ?
                    <tr>
                        <td colSpan={columns - 1} className={"right"}>
                            <strong>Suma de {title}</strong>
                        </td>
                        <td className={"right"}>
                            <strong>$ {decimalAdjust('round', total, -2)}</strong>
                        </td>
                    </tr>
                    : null
            }
        </>
    );
}
const MaterialsTable = ({
    columns, long_description, decimalAdjust,
    materials, materialsValues, setMaterialsValues, materialsTotal, setMaterialsTotal, materialsObservations, setMaterialsObservations,
    tools, toolsValues, setToolsValues, toolsTotal, setToolsTotal, toolsObservations, setToolsObservations,
    equipments, equipmentsValues, setEquipmentsValues, equipmentsTotal, setEquipmentsTotal, equipmentsObservations, setEquipmentsObservations,
    workforce, workforceValues, setWorkforceValues, workforceTotal, setWorkforceTotal, workforceObservations, setWorkforceObservations, additionalCharges
}) => {
    return (
        <table className="tableTools">
            {/** Materiales */}
            <PartialTable
                title={'Materiales'}
                array={materials}
                arrayValues={materialsValues}
                setArrayValues={setMaterialsValues}
                decimalAdjust={decimalAdjust}
                columns={columns}
                long_description={long_description}
                total={materialsTotal}
                setTotal={setMaterialsTotal}
                arrayObservations={materialsObservations}
                setArrayObservations={setMaterialsObservations}
            />
            {/* Mano de Obra */}
            <PartialTable
                title={'Mano de Obra'}
                array={workforce}
                arrayValues={workforceValues}
                setArrayValues={setWorkforceValues}
                decimalAdjust={decimalAdjust}
                columns={columns}
                long_description={long_description}
                total={workforceTotal}
                setTotal={setWorkforceTotal}
                arrayObservations={workforceObservations}
                setArrayObservations={setWorkforceObservations}
            />
            {/* Herramientas */}
            <PartialTable
                title={'Herramientas'}
                array={tools}
                arrayValues={toolsValues}
                setArrayValues={setToolsValues}
                decimalAdjust={decimalAdjust}
                columns={columns}
                long_description={long_description}
                total={toolsTotal}
                setTotal={setToolsTotal}
                arrayObservations={toolsObservations}
                setArrayObservations={setToolsObservations}
            />
            {/* Equipos */}
            <PartialTable
                title={'Equipos'}
                array={equipments}
                arrayValues={equipmentsValues}
                setArrayValues={setEquipmentsValues}
                decimalAdjust={decimalAdjust}
                columns={columns}
                long_description={long_description}
                total={equipmentsTotal}
                setTotal={setEquipmentsTotal}
                arrayObservations={equipmentsObservations}
                setArrayObservations={setEquipmentsObservations}
            />

            <tr>
                <td colSpan={columns - 1} className={"right"}>
                    <strong>Costo directo</strong>
                </td>
                <td className={"right"}>
                    <strong>{"$ " + decimalAdjust('round', (equipmentsTotal + toolsTotal + materialsTotal + workforceTotal), -2)}</strong>
                </td>
            </tr>
            {
                additionalCharges.map((item) =>
                    <tr key={item.id}>
                        <td colSpan={columns - 1} className={"right"}>
                            <strong>{item.name}</strong>
                        </td>
                        <td className={"right"}>
                            <strong>{"$ " + decimalAdjust('round', item.value, -2)}</strong>
                        </td>
                    </tr>
                )
            }
        </table>
    )
}

export default MaterialsTable