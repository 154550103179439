import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexAssetsTypes = async ({ package_id, table }) => {
    const url = `/packages/${package_id}/${table}_types`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: assets_types.js:14 ~ indexAssetsTypes ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data ;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeAssetsTypes = async ({ package_id, table, data }) => {
    const url = `/packages/${package_id}/${table}_types`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: assets_types.js:32 ~ storeAssetsTypes ~ fetch", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const updateAssetsTypes = async ({ package_id, table, data, id }) => {
    const url = `/packages/${package_id}/${table}_types/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: assets_types.js:32 ~ storeAssetsTypes ~ fetch", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}