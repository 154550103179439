import React, { Component } from 'react';
import { Variables } from 'variables/Variables';
import { Badge, ProgressBar, Button, Modal, Form } from 'react-bootstrap';
import Axios from 'axios';
import { __SERVER__ } from 'server/info';
import { FaEdit, FaCheck, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import Swal from 'sweetalert2';
import FAB from 'components/generales/FAB.jsx';
import { Heading } from '@chakra-ui/react';

const ModalEdit = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            notification: '',   //Mensaje
            active: true,          //Estado de la notificación
            priority: 'media'   //Prioridad
        }
    }

    componentDidMount = async () => {
        if (this.props.notification !== null) {
            this.setState({
                notification: this.props.notification.notification,
                active: this.props.notification.active === 1,
                priority: this.props.notification.priority
            })
        }
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    getVerify = () => {
        return this.state.notification !== '';
    }

    saveChanges = async () => {
        if (this.props.notification === null) {
            if (this.getVerify()) {
                let url = `/projects/${Variables.project_id}/victum_mobile_notifications`;
                await Axios.post(`${__SERVER__}${url}`, {
                    notification: this.state.notification,
                    active: this.state.active,
                    priority: this.state.priority
                });
                Swal.fire('Creada', 'Notificación agregada con éxito', 'success');
            } else {
                Swal.fire('Advertencia', 'Verifique que los campos sean correctos', 'warning');
            }
        } else {

            let url = `/projects/${Variables.project_id}/victum_mobile_notifications/${this.props.notification.id}/victum_mobile_notifications`
            await Axios.put(`${__SERVER__}${url}`, {
                notification: this.state.notification,
                active: this.state.active,
                priority: this.state.priority
            });
            Swal.fire('Actualizada', 'Información actualizada con éxito', 'success');
        }
        this.handleClose();
    }

    render = () => {
        return (
            <Modal //Propiedades del modal
                animation={true}
                show={this.state.show}
                onHide={this.handleClose}
                size="md"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Notificación</Form.Label>
                            <textarea name={"notification"} onChange={this.handleChange} className={"form-control"} defaultValue={this.state.notification} maxLength={"200"} />
                            <Form.Text className="text-muted">
                                Escriba un breve mensaje. Hasta 200 caracteres.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Estado</Form.Label>
                            <select name={"active"} onChange={this.handleChange} defaultValue={this.state.active} className={"form-control"}>
                                <option value={true}>Activo</option>
                                <option value={false}>Inactivo</option>
                            </select>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Prioridad</Form.Label>
                            <select name={"priority"} onChange={this.handleChange} defaultValue={this.state.priority} className={"form-control"}>
                                <option value={"alta"}>Alta</option>
                                <option value={"media"}>Media</option>
                                <option value={"baja"}>Baja</option>
                            </select>
                        </Form.Group>

                        {
                            this.props.notification !== null ?
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Fecha de creación</Form.Label>
                                    <Form.Control type="text" value={this.props.notification.created_at} readOnly />
                                </Form.Group> : null
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={this.handleClose}><FaTimes />Cerrar</Button>
                    <Button variant="outline-success" onClick={this.saveChanges}><FaCheck />Guardar cambios</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttons_active: ['button_active', 'button_desactive'],
            tap: 0, //Pestaña activa
            victum_users: [], //Usuarios de la app
            notifications: [], //Notificaciones Victum Mobile Notifications
            modalEdit: false,
            notification: null
        }
    }

    componentDidMount = () => {
        console.log("Loading...");
        this.getVictumUsers();
        this.getNotifications();
    }

    getNotifications = async () => {
        let notifications = await Axios.get(`${__SERVER__}/projects/${Variables.project_id}/victum_mobile_notifications`);
        this.setState({ notifications: notifications.data });
    }

    getNotificationByID = async (notification_id) => {
        console.log("notification_id: ", notification_id);
        let notification = await Axios.get(`${__SERVER__}/projects/${Variables.project_id}/notification/${notification_id}/victum_mobile_notifications`);
        console.log("notification: ", notification.data[0]);
        this.setState({ notification: notification.data[0] });
        this.openOrCloseModal();
    }

    getVictumUsers = async () => {
        let victum_users = await Axios.get(`${__SERVER__}/victum_users`);
        this.setState({ victum_users: victum_users.data });
    }

    tapActive = (tap) => {
        this.setState(prevState => {
            const buttons_active = prevState.buttons_active.map((item, index) => {
                let value = tap === index ? 'button_active' : 'button_desactive'
                return value
            })
            return { buttons_active }
        });
        this.setState({ tap });
    }

    openOrCloseModal = () => {
        this.setState({ modalEdit: !this.state.modalEdit });
        this.getNotifications();
    }

    render = () => {
        return (
            <>
                <section>
                    <Heading>Aplicación Móvil de Usuarios</Heading>
                </section>

                {/* <section className="sectionSearch">
                    <Heading>Filtros de búsqueda</Heading>
                </section> */}

                <header className="assets_tap">
                    <button className={this.state.buttons_active[0]} onClick={() => this.tapActive(0)}>Avisos y comunicados</button>
                    <button className={this.state.buttons_active[1]} onClick={() => this.tapActive(1)}>Usuarios</button>
                </header>
                {/* Agregar notification */}
                <FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />
                {/* Avisos y comunicados */}
                {
                    this.state.tap === 0 ?
                        <table className="tableAssets">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Notificación</th>
                                    <th>Estado</th>
                                    <th>Prioridad</th>
                                    <th>Fecha de creación</th>
                                    <th>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.notifications.map((item, i) =>
                                        <tr key={item.id}>
                                            <td>{i + 1}</td>
                                            <td>{item.notification}</td>
                                            <td>
                                                {
                                                    item.active === 1 ?
                                                        <ProgressBar striped variant="success" animated now={100} /> :
                                                        <ProgressBar striped variant="danger" now={100} />
                                                }
                                            </td>
                                            <td>
                                                {item.priority === 'alta' ? <Badge pill variant="danger">Alta</Badge> : null}
                                                {item.priority === 'media' ? <Badge pill variant="warning">Media</Badge> : null}
                                                {item.priority === 'baja' ? <Badge pill variant="info">Baja</Badge> : null}
                                            </td>
                                            <td>{moment(item.created_at).format('LLL')}</td>
                                            <td>
                                                <Button variant="outline-secondary" onClick={(e) => { this.getNotificationByID(item.id); }}><FaEdit /></Button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> : null
                }

                {/* Usuarios */}
                {
                    this.state.tap === 1 ?
                        <table className="tableAssets">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Correo electrónico</th>
                                    <th>Teléfono</th>
                                    <th>Openpay ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.victum_users.map((item, i) =>
                                        <tr key={item.id}>
                                            <td>{i + 1}</td>
                                            <td>{item.first_name} {item.last_name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.openpay_id}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> : null
                }

                {/* Modal para editar */}
                {
                    this.state.modalEdit ?
                        <ModalEdit
                            title={`Configurar notificación`}
                            onClose={this.openOrCloseModal}
                            notification={this.state.notification}
                        /> : null
                }
            </>
        );
    }
}