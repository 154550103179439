import React, { useEffect, useState } from 'react'
import ModalComp from 'components/layouts/Modal'
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Collapse, Divider, Row, Skeleton, Statistic } from 'antd';
import { indexAssetsEvaluations, showAssetsEvaluations } from 'api/operation_standards/assets_evaluations';
import moment from 'moment';
import NoData from 'components/layouts/NoData';
const { Panel } = Collapse;

const ModalAssetsEvaluations = (props) => {
    const { onClose, table, asset, project_id } = props;

    const [loading, setLoading] = useState(true);
    const [evaluations, setEvaluations] = useState([]);
    const [evaluationsData, setEvaluationsData] = useState([]);

    useEffect(() => {
        getEvaluations();
    }, []);

    const getEvaluations = async () => {
        const params = { assets_its_id: asset.id };
        let response = await indexAssetsEvaluations({ project_id, table, params });
        setEvaluations(response.data);
        let data = [];
        for (const evaluation of response.data) {
            const { id } = evaluation;
            const showData = await showAssetsEvaluations({ project_id, table, id });
            if (showData) data.push(showData);
        }
        setEvaluationsData(data);
        setLoading(false);
    }

    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title='Evaluaciones'
            body={<>
                {
                    loading && <Skeleton />
                }
                {
                    !loading && evaluations.length === 0 && <>
                        <NoData />
                        <center><h5>Sin evaluaciones</h5></center>
                    </>
                }
                {
                    !loading && evaluations.length > 0 &&
                    <Collapse Collapse defaultActiveKey={['1']}>
                        {
                            evaluations.map((item, index) =>
                                <Panel key={item.id}
                                    header={`${item.first_name} ${item.last_name} | ${moment(item.created_at).format('llll')}`}
                                >
                                    {
                                        evaluationsData[index].form.map((item) => {
                                            return <>
                                                <Divider orientation='left'>
                                                    <h6 color='back' className='mb-3'>{item.title}</h6>
                                                </Divider>
                                                <Row gutter={24} className='mb-4'>
                                                    {
                                                        item.items.map((element) => {
                                                            return <>
                                                                <Col xs={24} md={12} lg={6} xl={6}>
                                                                    <Statistic title={element.question} value={element.answer} />
                                                                </Col>
                                                            </>
                                                        })
                                                    }
                                                </Row>
                                            </>
                                        })
                                    }
                                </Panel>
                            )
                        }
                    </Collapse>
                }
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} variant='outline-secondary' onClick={onClose} />
            </>}
        />
    )
}

export default ModalAssetsEvaluations