import moment from 'moment/moment';
import Modal from '../../../layouts/Modal'
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import ReporteModal from './Deductivas/ReporteModal';
const { __SERVER__ } = require("../../../../server/info");

const Deductivas = ({project_id, standard_id, package_id}) => {

    const [dataIncidents, setDataIncidents] = useState(null)
    const [dataIncident, setDataIncident] = useState(null)
    const [Loading, setLoading] = useState(true)
    const closeButton = () => setshow(false)
    const [show, setshow] = useState(false)
    const [pum, setPum] = useState(null)

    useEffect(() => {
        ( async () =>{
            try {
                const resPum = await fetch(`${__SERVER__}/packages/${package_id}/pum`);
                const dataPum = await resPum.json();
                setPum(dataPum);
            } catch (err) { console.log(err) }
            
            try {
                const res = await fetch(`${__SERVER__}/projects/${project_id}/inspections/incidents?standard_id=${standard_id}`);
                const data = await res.json();
                setDataIncidents(data.data);
                setLoading(false)
            } catch (err) { console.log(err) }
        } )()
    }, [])

    const handleReport = (incidents) => {
        setDataIncident(incidents)
        setshow(true);
    }

    return (
        <>
            {
                (!Loading /* && (dataIncidents.lenght > 0) */) ? (
                    <Table className='tableReleases' striped bordered hover variant="light" size="sm" id="cssTable">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Incidente</th>
                                <th>Estado</th>
                                <th>Creado por</th>
                                <th>Fecha de creación</th>
                                <th>Generar reporte</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataIncidents.map((incidents, i) =>{
                                    return (
                                        <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>{incidents.standard.name}</td>
                                            <td>{incidents.status.name}</td>
                                            <td>{incidents.created_by.first_name} {incidents.created_by.last_name}</td>
                                            <td>{moment(incidents.creation_date).format("D MMM YYYY, h:mm:ss a")}</td>
                                            <td><Button onClick={() => handleReport(incidents)} >Generar reporte</Button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                ) : ( <h1>Cargando...</h1>)
            }

            {
               show && <Modal
                            onClose = { closeButton }
                            title   = "Reporte de deductivas"
                            body    = { <ReporteModal
                                            dataIncident={dataIncident}
                                            pum={pum}
                                        /> }
                            size    = 'xl'
                        />
            }
        </>
    )
}

export default Deductivas