import moment from 'moment/moment';
import React from 'react';
import { Document, Page, Text, View, StyleSheet,  Image } from '@react-pdf/renderer';
import { getInfoPackage } from 'variables/Variables';

const styles = StyleSheet.create({
    body:{ padding: 15 },
    Head:{
        display: "flex",
        flexDirection: "row"
    },
    Head_Image:{ width: 110 },
    table: { 
      display: "table",
      borderStyle: "solid",  
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      marginBottom: 15,
      borderColapse: "collapse",
      tableLayout: "auto",
      width: "auto", 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderTopWidth: 1,
      width: "100%" 
    }, 
    tableCol: { 
      borderStyle: "solid",
      borderRightWidth: 1,
      width: "100%",
      height: "auto"
    }, 
    tableCell: { 
      margin: "auto", 
      marginVertical: 5,
      marginHorizontal: 3,
      fontSize: 10,
      textAlign: "center"
    },
    text_center: { textAlign: "center" , fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_title: { textAlign: "center", fontWeight: "bold", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_center50: { textAlign: "center" , fontSize: 12, width: "50%", borderStyle: "solid", borderBottomWidth: 1, borderRightWidth:1 },
    logo:{
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    },
    logo1:{
        width: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid", 
        borderWidth: 1,
    },
    d_flex:{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    }, txt_center: { textAlign: "center" } ,
    text_logo: {textAlign: "center" , fontSize: 19, fontWeight: "heavy", width: "100%", borderStyle: "solid", borderTopWidth: 1, color: "#009644"},
    mb_5:  { marginBottom: 14, borderStyle: "solid", 
    borderWidth: 1, },
    fz_12: {fontSize: 12, textAlign: "center",  width: "100%"},
    flex:{
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" },
    cuad: { display: "flex", flexDirection: "row" , flexWrap: "wrap", justifyContent: "center" }
});

const DocPDF = React.memo(({Incidents}) => {
    const info = getInfoPackage()
    return (
        <Document>
            <Page size='A4' style={styles.body} orientation="landscape">
            <View style={styles.mb_5} fixed>
                    <View style={styles.Head}>
                        <View style={styles.logo}>
                            <Image src={info.img} style={styles.Head_Image}/>
                        </View>

                        <View style={styles.d_flex}>
                            <Text style={{fontWeight: 900}}>{info.title}</Text>
                            <Text style={styles.text_center}>GESTIÓN DE ACTIVOS </Text>
                            <Text style={styles.text_center}>CM-{info.code}-MTTO SGA</Text>
                            <Text style={styles.fz_12}> Reporte de Incidentes</Text>
                        </View>

                        <View style={styles.logo1}>
                            <Text style={styles.text_center}>Autopista {info.highway}</Text>
                           {/*  <Text style={styles.text_center}>CÓDIGO:  {inventoryItem.code}</Text> */}
                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                <Text style={styles.text_center50}>VERSIÓN: 1</Text>
                                <Text style={styles.text_center50}>FECHA DE EMISIÓN: {moment().format("D/MM/YY")}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                   
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    {
                        ["Código Incidente", "Estandar", "Estatus", "Fecha de creacíon", "Tiempo de Cierre"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                    
                    {
                        Incidents && Incidents.map(incident => 
                            <View style={styles.tableRow} key={incident.id} >
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.id}</Text> 
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.standard.code}</Text>
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.status.name}</Text>
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{ moment(incident.creation_date).format("D MMM YYYY, h:mm:ss a") }</Text>
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{moment(incident.data.estimated_date).format("D MMM YYYY, h:mm:ss a")}</Text>
                                </View>
                            </View> 
                        )
                    }      
                </View>

                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    {
                        ["Código Incidente", "Creado por:", "Ubicación", "KM Inicial", "KM Final"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                    
                    {
                        Incidents && Incidents.map(incident => 
                            <View style={styles.tableRow} key={incident.id} >
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.id}</Text> 
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{`${incident.created_by.first_name} ${incident.created_by.last_name}`}</Text>
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.segment.location}</Text>
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.segment.start_km }</Text>
                                </View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.segment.end_km}</Text>
                                </View>
                            </View> 
                        )
                    }      
                </View>

                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    {
                        ["Código Incidente", "Evaluación del incidente"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                    
                    {
                        Incidents && Incidents.map((incident,i) => 
                            <View style={styles.tableRow} key={i}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.id}</Text> 
                                </View>

                                <View style={styles.tableCol}><Text style={styles.tableCell}>{incident.data.evaluation.name}</Text>
                                </View>
                            </View> 
                        )
                    }      
                </View>
            </Page>
        </Document>
    )
})

export default DocPDF