import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'

const DeleteMaintenanceProgram = (props) => {
    const { onClose } = props;
    return (
        <ModalComp
            onClose={onClose}
            title='Eliminar Programa de Mantenimiento'
            size='lg'
            body={<>
                <p>En construcción</p>
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' onClick={onClose} variant='outline-secondary' icon={faTimes} />
            </>}
        />
    )
}

export default DeleteMaintenanceProgram