import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexMaintenanceActivities = async ({ project_id, maintenance_sections_id }) => {
    const url = `/projects/${project_id}/maintenance_activity`;
    let response = { data: [], totalItems: 0 };
    let params = {};
    if (maintenance_sections_id) params.maintenance_sections_id = maintenance_sections_id;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_activities.js ~ line 14 ~ indexMaintenanceActivities ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeMaintenanceActivities = async ({ project_id, data }) => {
    const url = `/projects/${project_id}/maintenance_activity`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: maintenance_activities.js ~ line 32 ~ storeMaintenanceActivities ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = true
        return response;
    } catch (error) {
        return response;
    }
}

export const updateMaintenanceActivities = async ({ id, data }) => {
    const url = `/projects/maintenance_activity/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: maintenance_activities.js ~ line 50 ~ updateMaintenanceActivities ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = true
        return response;
    } catch (error) {
        return response;
    }
}
