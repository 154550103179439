import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

const CREATED = 201;
const STATUS_OK = 200;

const url = `maintenance_standard/program`

export const indexMaintenanceProgram = async (props) => {
    const { project_id } = props;
    let response = { data: [], totalItems: 0 };
    let query = props;
    delete query.project_id;
    let params = {};
    if (query) {
        const queryValues = Object.values(query);
        const queryKeys = Object.keys(query);
        for (let index = 0; index < queryValues.length; index++) {
            const element = queryValues[index];
            if (element !== '0')
                params[queryKeys[index]] = element;
        }
    }
    try {
        let fetch = await fetcher({
            url: `/projects/${project_id}/${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_program.js:16 ~ indexMaintenanceProgram ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const showMaintenanceProgram = async (props) => {
    const { project_id, id } = props;
    let response = {};
    try {
        let fetch = await fetcher({
            url: `/projects/${project_id}/${url}/${id}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: maintenance_program.js:36 ~ showMaintenanceProgram ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeMaintenanceProgram = async ({ data, project_id }) => {
    let response = false;
    try {
        let fetch = await fetcher({
            url: `/projects/${project_id}/${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: maintenance_program.js:36 ~ storeMaintenanceProgram ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const getUrlMaintenanceProgram = async ({ package_id, project_id, start_date, end_date }) => {
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `/packages/${package_id}/projects/${project_id}/${url}/xlsx`,
            method: 'GET',
            params: { start_date, end_date }
        });
        // console.log("🚀 ~ file: maintenance_program.js:81 ~ getUrlMaintenanceProgram ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}


export const getUrlMaintenanceProgramPerformanceXlsx = async ({ package_id, project_id, start_date, end_date, maintenance_standard_id }) => {
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `/packages/${package_id}/projects/${project_id}/${url}/performance/xlsx`,
            method: 'GET',
            params: { start_date, end_date, maintenance_standard_id }
        });
        // console.log("🚀 ~ getUrlMaintenanceProgramPerformanceXlsx ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const deleteMaintenanceProgram = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/maintenance_standard/program/${id}`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: maintenance_program.js:99 ~ deleteMaintenanceProgram ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data
        return { response };
    } catch (error) {
        return { response };
    }
}