import React from 'react';
import TabsComp from 'components/layouts/Tabs';
import CostWorkOrdersComp from '../fragments/CostWorkOrders';
import ProductividadHoraria from './fragments/ProductividadHoraria';
import Reportes from './fragments/Reportes';

const HumanResources = (props) => {
	const { package_id, standard_id } = props;
	return (
		<>
			<TabsComp
				tabPosition='left'
				tablist={['Mano de Obra', 'Productividad Horaria', 'Reportes']}
				tabpanels={[
					<CostWorkOrdersComp
						key='1'
						package_id={package_id}
						standard_id={standard_id}
						type_description_id={2} // Mano de obra
					/>,
					<ProductividadHoraria key='2' />,
					<Reportes key='3' />
				]}
			/>
		</>
	)
}

export default HumanResources