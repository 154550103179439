import React, { useEffect, useState } from 'react'
import { downloadInventoryFiles } from 'api/maintenance_standards/inventory_files';
import moment from 'moment';
import { Col, Collapse, Row } from 'antd';
import { Alert, Form } from 'react-bootstrap';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const { Panel } = Collapse;

const InventoryAudios = (props) => {
    const { data, project_id, table, deleteFile, storeFile } = props;

    const [audios, setAudios] = useState([]);               // Lista de audios
    const [loading, setLoading] = useState(true);           // Indicador de carga
    const [count, setCount] = useState(0);                  // Cantidad de archivos
    const [currentCount, setCurrentCount] = useState(0);
    const [file, setFile] = useState(null);                 // Archivo para cargar

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];

        const getAudios = async () => {
            let files = data.filter((file) => file.file_type === 'audio');
            setCount(files.length);
            for (const element of files) {
                if (cancelLoop) break;
                const { file_path, inventory_data_id, created_at } = element;
                const { response, cancel } = await downloadInventoryFiles({ project_id, table: `${table}_files`, id: inventory_data_id, file_path });
                cancelTokens.push({ cancel });
                if (response) {
                    setAudios(audios => [...audios, {
                        audio: URL.createObjectURL(response),
                        created_at: `${moment(created_at).format('LLLL')}`,
                        inventory_data_id,
                        file_path
                    }]);
                    setCurrentCount(currentCount => currentCount + 1);  // Contador del archivo obtenido
                    setLoading(false);
                }
            }
            setLoading(false);
        };
        getAudios();
        return () => {
            cancelLoop = true;  // cancelar la petición del resto de las fotografías
            cancelTokens.forEach((token) => token.cancel()); // cancelar cada uno de los tokens creados
        }
    }, []);

    // Manejador de carga de archivos
    const handleLoadFile = (event) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    }
    // Método para subir el archivo
    const handleUploadClick = () => {
        if (!file) {
            Swal.fire('¡Sin archivos!', 'Necesita elegir un archivo.', 'warning');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        storeFile({ data: formData, file_type: 'audio' });
    };

    return (
        <>
            {
                count > 0 &&
                <p>Obteniendo {currentCount}/{count} audio{count === 1 ? '' : 's'}...</p>
            }
            {
                !loading && audios.map((item, index) =>
                    <Row align="middle" key={item.id}>
                        <Col span={2}>{index + 1}</Col>
                        <Col span={10}>{item.created_at}</Col>
                        <Col span={10}>
                            <audio key={item.id} src={item.audio} controls />
                        </Col>
                        <Col span={2}>
                            <ButtonIcon icon={faTrashAlt} variant='outline-danger' onClick={() => deleteFile(item)} tooltip='Eliminar audio' />
                        </Col>
                    </Row>
                )
            }
            {
                !loading && audios.length === 0 &&
                <Alert variant='info'>
                    No se encontraron registros de audios.
                </Alert>
            }
            {/* Agregar archivo de audio */}
            <Collapse>
                <Panel header="Agregar archivo de audio" key="1">
                    <Row align='middle'>
                        <Col span={20}>
                            <Form.Group className="mb-3">
                                <Form.Label>Cargar un archivo de audio</Form.Label>
                                <Form.Control type="file" size="sm" accept='audio/*' onChange={handleLoadFile} />
                            </Form.Group>
                        </Col>
                        <Col span={4}>
                            <ButtonIcon name='Agregar' icon={faPlus} variant='success' tooltip='Agregar' onClick={handleUploadClick} />
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </>
    )
}

export default InventoryAudios