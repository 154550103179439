import React, { useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import _ from 'underscore';
import { deleteMaintenanceProgramFlow, indexMaintenanceProgramFlow, showMaintenanceProgramFlow } from 'api/operation_standards/maintenance_program_flow';
import EventCalendar from '../fragments/EventCalendar';
import EventAgenda from '../fragments/EventAgenda';
import ViewOrders from '../modals/ViewOrders';
import AddWorkOrder from '../modals/AddWorkOrder';
import EventDetails from '../modals/EventDetails';
import FiltersProgram from '../fragments/FiltersProgram';
import { indexMaintenanceProgram } from 'api/operation_standards/maintenance_program';
import Swal from 'sweetalert2';
import { Skeleton } from 'antd';

require('moment/locale/es.js');
const localizer = momentLocalizer(moment);

const CalendarTab = (props) => {
	const {
		package_id,
		project_id,
		name,
		maintenance_sections_id
	} = props;

	const [events, setEvents] = useState([]);
	const [eventSel, setEventSel] = useState({});
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingCalendar, setLoadingCalendar] = useState(false);
	const [modalDetails, setModalDetails] = useState(false);
	const [modalNewOrder, setModalNewOrder] = useState(false);
	const [modalViewOrders, setModalViewOrders] = useState(false);
	const [maintenanceProgram, setMaintenanceProgram] = useState([]);
	const [maintenanceProgramFlow, setMaintenanceProgramFlow] = useState([]);
	const [filters, setFilters] = useState({});

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (maintenanceProgram.length > 0 && maintenanceProgramFlow.length > 0)
			generateEventList();
	}, [maintenanceProgram, maintenanceProgramFlow]);

	const getData = async () => {
		setLoadingCalendar(true);
		setEvents([]);
		setMaintenanceProgram([]);
		setMaintenanceProgramFlow([]);
		await getMaintenanceProgram();
		await getMaintenanceProgramFlow();
		setLoadingCalendar(false);
	}
	// Método para obtener el programa de mantenimiento
	const getMaintenanceProgram = async () => {
		let response = await indexMaintenanceProgram({ package_id, project_id, maintenance_sections_id, ...filters });
		// console.log("🚀 ~ file: CalendarTab.jsx:60 ~ getMaintenanceProgram ~ response:", response)
		setMaintenanceProgram(response.data);
	}
	// Método para ontener el flujo de programa de mantenimiento
	const getMaintenanceProgramFlow = async () => {
		let response = await indexMaintenanceProgramFlow({ package_id, project_id, maintenance_sections_id, ...filters });
		// console.log("🚀 ~ file: CalendarTab.jsx:66 ~ getMaintenanceProgramFlow ~ response:", response)
		setMaintenanceProgramFlow(response.data);
	}

	const generateEventList = async () => {
		if (maintenanceProgram.length !== 0 && maintenanceProgramFlow.length !== 0) {
			let eventsList = [];
			for (const element_mp of maintenanceProgram) {
				let program_flow = _.where(maintenanceProgramFlow, { program_id: element_mp.id });
				for (const element_mpf of program_flow) {
					eventsList.push({
						id: element_mpf.id,
						title: element_mpf?.asset?.code + ' - ' + element_mpf?.asset?.km + ' | ' + element_mpf.responsable_permanent,
						start: new Date(element_mpf.start_date.split('T')[0] + " " + element_mpf.start_time),
						end: new Date(element_mpf.end_date.split('T')[0] + " " + element_mpf.end_time),
						desc: element_mpf.activity_permanent,
						status: element_mpf.status,
						details: onEventClick,
						detailsOrder: detailsOrder,
						generateOrder: generateOrder,
						deleteOrder
					});
				}
			}
			setEvents(eventsList);
		}
	}
	// Método para obtener detalles del evento "victum_maintenance_program_flow"
	const getDataDetails = async (id) => {
		setLoading(true);
		let response = await showMaintenanceProgramFlow({ project_id, package_id, id });
		setData(response);
		setLoading(false);
	}
	// Evento Click: Get "victum_maintenance_program_flow"
	const onEventClick = async (event) => {
		getDataDetails(event?.id);
		setModalDetails(!modalDetails);
	}

	const generateOrder = async (event) => {
		setEventSel(event);
		getDataDetails(event?.id);
		setModalNewOrder(!modalNewOrder);
	}

	const detailsOrder = (event) => {
		getDataDetails(event?.id);
		setModalViewOrders(!modalViewOrders);
	}

	const deleteOrder = (event) => {
		const { id, title, desc, status, start, end } = event;
		Swal.fire({
			title: '¿Está seguro que desea realizar esta operación?',
			text: `Usted está tratando de eliminar la actividad de mantenimiento ${desc} con título "${title}" y estado ${status}. 
			Programada de ${moment(start).format('LLLL')} al ${moment(end).format('LLLL')}`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, eliminarlo'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let response = await deleteMaintenanceProgramFlow({ project_id, id });
				if (response) {
					setEvents(prevState => prevState.filter(item => item.id !== id));
					Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
				} else
					Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
			} else {
				Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
			}
		})
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFilters({ ...filters, [name]: value });
	}

	return (
		<>
			<FiltersProgram filters={filters} handleChange={handleChange} getData={getData} />
			<div style={{ height: `60em` }} className="bigCalendar-container calendar">
				{/* Mostrar flujo de programa de mantenimiento en el calendario a través de eventos */}
				{
					loadingCalendar ? <Skeleton active /> :
						< BigCalendar
							selectable
							localizer={localizer}
							views={['month', 'week', 'day', 'agenda']}
							events={events}
							startAccessor="start"
							endAccessor="end"
							messages={{
								next: "sig",
								previous: "ant",
								today: "Hoy",
								month: "Mes",
								week: "Semana",
								day: "Día"
							}}
							components={{
								event: EventCalendar,
								agenda: { event: EventAgenda }
							}}
						/>
				}
				{/* Modal para ver la lista de ordenes de trabajo con respecto a un evento */}
				{
					modalViewOrders && <ViewOrders
						onClose={() => setModalViewOrders(!modalViewOrders)}
						data={data}
					/>
				}
				{/* Modal para agregar una orden de trabajo al evento seleccionado */}
				{
					modalNewOrder && <AddWorkOrder
						onClose={() => setModalNewOrder(!modalNewOrder)}
						data={data}
						loading={loading}
						project_id={project_id}
						name={name}
						package_id={package_id}
						eventSel={eventSel}
					/>
				}
				{/* Modal para ver los detalles del evento seleccionado */}
				{
					modalDetails && <EventDetails
						onClose={() => setModalDetails(!modalDetails)}
						data={data}
						loading={loading}
						project_id={project_id}
						package_id={package_id}
					/>
				}
			</div>
		</>
	)
}

export default CalendarTab