import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import React, { useRef, useState } from 'react'
import { Button, Overlay, Popover } from 'react-bootstrap';

const EventCalendar = ({ event }) => {
	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);

	const handleClick = (event) => {
		if (!show) {
			setTimeout(() => {
				setShow(false);
			}, 5000);
		}
		setShow(!show);
		setTarget(event.target);
	};

	let status = event.status;
	let variant = "dark";
	switch (status) {
		case 'PENDIENTE': variant = "secondary"; break;
		case 'INICIADO': variant = "primary"; break;
		case 'NO REALIZADO': variant = "danger"; break;
		case 'CON RETRASO': variant = "warning"; break;
		case 'COMPLETADO': variant = "success"; break;
		default: break;
	}
	return (
		<div>
			<Button title={status} onClick={handleClick} size="sm" variant={variant}>
				{event.title}
			</Button>
			<Overlay
				show={show}
				target={target}
				placement="bottom"
				container={ref.current}
				containerPadding={20}>
				<Popover id="popover-contained">
					<Popover.Title as="h3">{status}</Popover.Title>
					<Popover.Content>
						<Button variant={'link'} onClick={() => {
							setShow(false)
							event.generateOrder(event)
						}}>Generar orden de trabajo</Button><br />
						<Button variant={'link'} onClick={() => {
							setShow(false)
							event.detailsOrder(event)
						}}>Ver ordenes de trabajo</Button><br />
						<Button variant={'link'} onClick={() => {
							setShow(false)
							event.details(event)
						}}>Ver detalles</Button>
						<ButtonIcon icon={faTrashAlt} name='Eliminar' variant='link' onClick={() => {
							setShow(false)
							event.deleteOrder(event)
						}} />
					</Popover.Content>
				</Popover>
			</Overlay>
		</div>
	);
}

export default EventCalendar