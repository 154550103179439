import fetcher from "api/fetcher";
import { Variables } from "variables/Variables";

const STATUS_OK = 200;

export const getMaintenanceStandards = async () => {

    const url = `/packages/${Variables.package_id}/maintenance_standards`;
    let response = [];
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        if (fetch.status === STATUS_OK){
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}