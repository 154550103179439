import { useState } from 'react';
import PropTypes from 'prop-types';

const usePagination = ({ page, totalItems, defaultPageSize }) => {
    const [data, setData] = useState([]);
    const [dataSel, setDataSel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: page || 1,
        pageSize: defaultPageSize || 10,
        totalItems: totalItems || 0
    });

    const changePage = (page, pageSize) => {
        setPagination(prevState => ({ ...prevState, page, pageSize }));
    };

    const setTotalItems = (totalItems) => {
        setPagination(prevState => ({ ...prevState, totalItems }));
    }

    const incrementTotalItems = (increment) => {
        setPagination(prevState => ({
            ...prevState,
            totalItems: parseInt(prevState.totalItems) + increment
        }));
    }

    const addElement = (item) => {
        setData(prevState => {
            const currentLength = prevState.length;
            let currentItems = prevState;
            // Quitar el último elemento y agregar el nuevo al principio
            if (currentLength === pagination.pageSize) {
                currentItems = prevState.slice(0, -1);
            }
            return [
                item,
                ...currentItems
            ]
        });
        // Incrementar en uno el contador
        incrementTotalItems(1);
    }

    // Función de comparación para claves primarias compuestas
    const areKeysEqual = (a, b, keys) => {
        return keys.every(key => a[key] === b[key]);
    };
    const updateElement = (item, primaryKeys = ['id']) => {
        setData(prevState =>
            prevState.map(element =>
                (areKeysEqual(element, item, primaryKeys) ? item : element)
            )
        );
    };
    const updateElementOld = (item) => {
        setData(prevState =>
            prevState.map(element =>
                (element.id === item.id ? item : element)
            )
        );

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData(prevState => ({ ...prevState, [name]: value }));
    }

    return {
        data,
        pagination,
        changePage,
        setData,
        setTotalItems,
        addElement,
        loading,
        setLoading,
        handleChange,
        dataSel,
        setDataSel,
        updateElement
    };
};

usePagination.propTypes = {
    page: PropTypes.number,
    totalItems: PropTypes.number,
    defaultPageSize: PropTypes.number
}

export default usePagination;