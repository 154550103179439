import React, { useState, useEffect } from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Skeleton, Steps, Upload } from 'antd';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { getSegments } from 'api/general/getSegments';
import { indexAssetsBaseLines } from 'api/operation_standards/assets_base_lines';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import { useGeolocated } from 'react-geolocated';
import ModalComp from 'components/layouts/Modal';
import { getKmNumbers } from 'components/libs/functions';
import SkeletonForm from 'components/layouts/SkeletonForm';
import { indexInventory } from 'api/maintenance_standards/inventory';
import { indexInventoryData } from 'api/maintenance_standards/inventory_data';
import { closeMrInspections, indexMrInspections, storeMrInspections } from 'api/maintenance_standards/inspections';
import { indexIncidents, storeIncidents } from 'api/maintenance_standards/incidents';
import { indexFactorTypes } from 'api/maintenance_standards/factor_incident_type';
import { indexIncidentEvaluations } from 'api/maintenance_standards/incident_evaluations';
import { indexIncidentTimes } from 'api/maintenance_standards/incident_times';
import { indexIncidentDeductive } from 'api/maintenance_standards/incident_deductive';
import moment from 'moment';
import { uploadIncidentFiles } from 'api/maintenance_standards/incident_files';
import FooterButtons from './FooterButtons';
import { stepClass } from './steps';
import StepInspection from './StepInspection';
import StepIncidents from './StepIncidents';
import StepInventory from './StepInventory';
import StepData from './StepData';
import { indexUsersSimple } from 'api/users/users';
import { indexUserTypeResponsable } from 'api/users/user_type_responsable';

const { Step } = Steps;

const NewIncident = (props) => {
    const { onClose, project_id, package_id, google, maintenance_standard_id, table } = props;

    const user_id = useSelector(state => state.login.dataUser.id);

    const { coords, isGeolocationAvailable } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });

    const [current, setCurrent] = useState(stepClass.stepInspection);
    const [segments, setSegments] = useState([]);
    const [targetKeys, setTargetKeys] = useState(segments);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [assets, setAssets] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalItems, setTotalItems] = useState(0);
    const [inventory, setInventory] = useState([]);
    const [assetBaseLines, setAssetBaseLines] = useState([]);
    const [data, setData] = useState({ altitude: 0 });
    const [factorTypes, setFactorTypes] = useState([]);
    const [times, setTimes] = useState([]);
    const [evaluations, setEvaluations] = useState([]);
    const [deductive, setDeductive] = useState([]);
    const [markerPos, setMarkerPos] = useState({});
    const [showInfoWindow, setShowInfoWindow] = useState(true);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [activeMarker, setActiveMarker] = useState({});
    const [fileList, setFileList] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [incidents, setIncidents] = useState([]);
    const [inspections, setInspections] = useState([]);
    const [checkedOldInspection, setCheckedOldInspection] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers();
        getSegs();
        getInventory();
        getAssetBaseLines();
        getFactorTypes();
        getIncidentEvaluations();
    }, []);

    useEffect(() => {
        if (data.inspection_id) getIncidents();
    }, [data.inspection_id]);

    useEffect(() => {
        if (checkedOldInspection
            && inspections.length === 0
            && data.creation_dateS
            && data.creation_dateE) getInspections();
    }, [checkedOldInspection, data.creation_dateS, data.creation_dateE]);

    useEffect(() => {
        if (data.inventory_id) getAssets();
    }, [data.inventory_id, page, pageSize]);

    useEffect(() => {
        if (data.factor_type_id && data.segment_id && data.evaluation_id) getIncidentTimes();
    }, [data.factor_type_id, data.segment_id, data.evaluation_id]);

    useEffect(() => {
        if (data.factor_type_id && data.segment_id) getIncidentDeductive();
    }, [data.factor_type_id, data.segment_id]);

    useEffect(() => {
        if (data.asset_data_id) getMarkerPos();
    }, [data.asset_data_id]);

    /** Obtener usuario que es Gerente Técnico */
    const getUsers = async () => {
        let response = await indexUsersSimple({ package_id });
        setUsers(response.data);
        // Se consulta al usuario que es responsable del estándar de mantenimiento
        const user_types = await indexUserTypeResponsable({ package_id });
        const items = user_types.data.filter((item) => item.code === 'Gerente Técnico');
        if (items.length > 0) {
            setData({ ...data, responsable_id: items[0].user_id });
        }
    }
    /** Obtener posición actual o del elemento del inventario */
    const getMarkerPos = () => {
        let lat = 0, lng = 0;
        const { asset_data_id } = data;
        const { latitude, longitude, km } = assets.find((asset) => asset.id == asset_data_id) || {};
        const { meter, kilometer } = getKmNumbers(km);
        // Convertir cadena a número
        if (typeof latitude === 'number' && typeof longitude === 'number') {
            lat = latitude;
            lng = longitude;
        } else {
            if (isGeolocationAvailable) {
                lat = coords?.latitude;
                lng = coords?.longitude;
            }
        }
        setData({ ...data, meter, kilometer, latitude: lat, longitude: lng });
        setMarkerPos({ lat, lng });
    };
    /** Pasos para la creación del un nuevo incidente */
    const steps = [
        <Step key={0} title="Inspección" />,
        <Step key={1} title="Incidentes" />,
        <Step key={2} title="Inventario" />,
        <Step key={3} title="Datos" />,
        <Step key={4} title="Ubicación" />,
        <Step key={5} title="Evidencias" />
    ];
    /** Al dar click al pin en el mapa */
    const onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };
    /** Al clickear en el mapa */
    const onMapClicked = (props) => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };
    /** Al arrastrar el pin se cambian las coordenadas */
    const onDragend = (coord) => {
        const { latLng } = coord;
        setData({
            ...data,
            latitude: latLng.lat(),
            longitude: latLng.lng()
        });
        setMarkerPos({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
    };
    const next = () => setCurrent(current + 1);     /** Paso siguiente*/
    const prev = () => setCurrent(current - 1);     /** Paso anterior*/
    /** Confirmación de creación del incidente*/
    const save = async () => {
        try {
            Swal.fire({
                title: '¿Está seguro que desea realizar esta operación?',
                text: "Usted está creando un incidente",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, crearlo'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    saveAll();
                } else {
                    Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
                }
            })
        } catch (error) {
            Swal.fire('¡Operación incompleta!', 'Verifique el formulario.', 'warning')
        }
    };
    /** Llamar métodos para guardar inspección, incidente y evidencias */
    const saveAll = async () => {
        setLoadingSave(true);
        const { inspection_id } = data;
        if (inspection_id) {
            let incident_id = await saveIncident(inspection_id);
            if (incident_id) {
                let files_count = await saveFiles(incident_id, inspection_id);
                if (fileList.length > 0) {
                    Swal.fire('¡Operación exitosa!', `El incidente ha sido registrado. Se agregaron ${files_count} archivos como evidencia.`, 'success');
                } else {
                    Swal.fire('¡Operación exitosa!', 'El incidente ha sido registrado.', 'success');
                }
                // Eliminar datos del incidente para agregar uno nuevo
                const { creation_dateS, creation_dateE } = data;
                setData({ altitude: 0, inspection_id, creation_dateS, creation_dateE });
                setFileList([]);
                setLoadingSave(false);
                setCurrent(stepClass.stepIncidents);
                getIncidents();
            } else {
                setLoadingSave(false);
                Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de registrar el incidente.', 'error')
            }
        } else {
            setLoadingSave(false);
            Swal.fire('¡Operación abortada!', 'La inspección no se pudo crear.', 'warning')
        }
    };
    /** Obtener las inspecciones */
    const getInspections = async () => {
        const { creation_dateS, creation_dateE } = data;
        let response = await indexMrInspections({ project_id, status: 'En progreso', creation_dateS, creation_dateE });
        setInspections(response.data);
    }
    /** Cambiar de tipo de inspección */
    const changeInspection = (e) => {
        setCheckedOldInspection(e.target.checked);
    }
    /** Crear una inspeción */
    const saveInspection = async () => {
        const { responsable_id } = data;
        let inspection_id = await storeMrInspections({
            project_id, data: {
                segments_id: JSON.stringify(targetKeys),
                creatorId: user_id, responsable_id
            }
        });
        if (inspection_id) {
            setData({ ...data, inspection_id });
            next();
        }
    };
    /** Cerrar la inspección */
    const closeInspection = async () => {
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Usted está cerrando la inspección. Lo que significa que ya no la podrá abrir para agregar nuevos incidentes.",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar inspección'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { inspection_id } = data;
                let response = await closeMrInspections({ project_id, inspection_id, user_id });
                if (response) {
                    Swal.fire('¡Inspección cerrada!', 'La inspección fue cerrada de manera correcta. Ya no es posible agregar más incidentes a esta inspección.', 'success');
                } else {
                    Swal.fire('¡Error al cerrar la inspección!', 'Ha ocurrido un problema al tratar de cerrar la inspección. Intente más tarde.', 'warning');
                }
                onClose();
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'info')
            }
        })
    }
    /** Salir de la inspección, esto implica poder agregar más incidentes */
    const leaveInspection = () => {
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Usted está saliendo de la inspección. Lo que significa que la puede abrir más tarde para agregar nuevos incidentes.",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, salir'
        }).then(async (result) => {
            if (result.isConfirmed) {
                onClose();
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'info')
            }
        })
    }
    /** Obtener incidentes de la inspección en curso */
    const getIncidents = async () => {
        const { inspection_id } = data;
        let response = await indexIncidents({ project_id, inspection_id });
        setIncidents(response.data);
    }
    /** Guardar el incidente */
    const saveIncident = async (inspection_id) => {
        const { asset_data_id, segment_id, latitude, longitude, estimated_date,
            altitude, kilometer, meter, inventory_id, evaluation_id } = data;

        let response = false;
        if (times.length > 0) {
            let formData = {
                standardId: maintenance_standard_id,
                segmentId: segment_id,
                inventory_id,
                inventory_data_id: asset_data_id,
                latitude,
                longitude,
                altitude,
                kilometer,
                meter,
                creatorId: user_id,
                data: {
                    evaluation_id,
                    time_id: times[0].id,
                    estimated_date,
                    deductive_id: deductive[0].id
                }
            };
            response = await storeIncidents({ project_id, inspection_id, data: formData });
        }
        return response;
    };
    /** Agregar archivos multimedia como evidencia */
    const saveFiles = async (incident_id, inspection_id) => {
        let files_count = 0;
        for (const iterator of fileList) {
            const { type } = iterator;
            let formDataFile = new FormData();
            formDataFile.append('file', iterator);
            let file_type = 'video';
            if (/audio\//i.test(type)) file_type = 'audio';
            if (/image\//i.test(type)) file_type = 'images';
            let status_upload = await uploadIncidentFiles({
                project_id, incident_id, inspection_id,
                moment: 'before', user_id, file_type, data: formDataFile
            });
            if (status_upload) files_count++;
        }
        return files_count;
    };
    /** Obtener los tipos de factor | Inicial, Correctivo y Reiterativo */
    const getFactorTypes = async () => {
        let response = await indexFactorTypes({ package_id });
        setFactorTypes(response.data);
    };
    /** Obtener las evaluaciones que se realizan a los distintos tipos de elementos */
    const getIncidentEvaluations = async () => {
        let response = await indexIncidentEvaluations({ package_id, maintenance_standard_id });
        setEvaluations(response.data);
    };
    /** Obtener el tiempo de resolución en el que se tiene que solucionar el incidente */
    const getIncidentTimes = async () => {
        const { segment_id, factor_type_id, evaluation_id } = data;
        let response = await indexIncidentTimes({
            segment_id, factor_type_id,
            incident_evaluation_id: evaluation_id
        });
        setTimes(response.data);
        if (response.data.length > 0) {
            const { days, hours, minutes } = response.data[0];
            const estimated_date = moment()
                .add(days, 'days')
                .add(hours, 'hours')
                .add(minutes, 'minutes')
                .format('YYYY-MM-DD');
            setData({
                ...data,
                estimated_date
            });
        }
    };
    const getIncidentDeductive = async () => {
        const { factor_type_id, segment_id } = data;
        let response = await indexIncidentDeductive({ factor_type_id, segment_id, standard_id: maintenance_standard_id });
        setDeductive(response.data);
    }
    /** Obtener los segmentos de la autopista */
    const getSegs = async () => {
        let response = await getSegments();
        setSegments(response.map((segment) => {
            const { id, name, location, start_km, end_km } = segment;
            return {
                key: id,
                title: name + ' ' + location,
                description: start_km + ' ' + end_km
            };
        }));

    };
    /** Segmentos que se van a inspeccionar */
    const onChange = (nextTargetKeys, _direction, _moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    /** Mover los segmentos del componente Transfer */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    /** Obtener los tipos de elementos|activos */
    const getInventory = async () => {
        let response = await indexInventory({ project_id, table });
        setInventory(response.data);
    };
    /** Obtener las líneas base */
    const getAssetBaseLines = async () => {
        let response = await indexAssetsBaseLines({ package_id });
        setAssetBaseLines(response.data);
    };
    /** Obtener los elementos|activos de acuerdo al tipo y línea base */
    const getAssets = async () => {
        const { inventory_id } = data;
        let response = await indexInventoryData({ table, inventory_id, page, pageSize });
        setAssets(response.data);
        setTotalItems(response.totalItems);
    };
    /** Cambiar la paginación */
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    /** Guadar datos del formulario */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    /** Propiedades del componente Upload, eliminar y agregar archivos*/
    const propsUpload = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const { size, type } = file;
            let allow = false;
            if (/audio\//i.test(type)) allow = true;
            if (/image\//i.test(type)) allow = true;
            if (/video\//i.test(type)) allow = true;

            if (allow) {
                const maxSize = 30 * 1024 * 1024;   // Archivos menores a 30MB
                if (size < maxSize) {
                    setFileList([...fileList, file]);
                } else {
                    Swal.fire('¡Archivo muy grande!', 'El archivo no debe pasar de 20 MB.', 'info');
                }
            } else {
                Swal.fire('¡Archivo desconocido!', 'El archivo debe ser imagen, audio o video.', 'info');
            }
            return false;
        },
        fileList,
        listType: "picture-card"
    };
    /** Habilitar botón siguiente */
    const enableNext = () => {
        let response = false;
        switch (current) {
            case stepClass.stepInspection:
                if (targetKeys.length > 0) response = true;
                break;
            case stepClass.stepIncidents:

                break;
            case stepClass.stepInventory:
                const { inventory_id, asset_data_id } = data;
                if (inventory_id && asset_data_id) response = true;
                break;
            case stepClass.stepData:
                const { segment_id, kilometer, meter, factor_type_id, evaluation_id } = data;
                if (segment_id && kilometer && meter && factor_type_id && evaluation_id) response = true;
                break;
            case stepClass.stepUbication:
                const { latitude, longitude } = data;
                if (latitude && longitude) response = true;
                break;
            default: break;
        }
        return response;
    }
    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={'Registrar Incidente'}
            body={<div style={{ height: '60vh' }}>
                {
                    loadingSave ? <SkeletonForm cols={3} rows={5} /> : <>
                        <Steps current={current}>
                            {steps}
                        </Steps>
                        {
                            current === stepClass.stepInspection && (<StepInspection
                                changeInspection={changeInspection}
                                checkedOldInspection={checkedOldInspection}
                                data={data}
                                handleChange={handleChange}
                                inspections={inspections}
                                segments={segments}
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onSelectChange={onSelectChange}
                                onChange={onChange}
                                users={users}
                            />)
                        }
                        {
                            current === stepClass.stepIncidents && (<StepIncidents
                                incidents={incidents}
                            />)
                        }
                        {
                            current === stepClass.stepInventory && (<StepInventory
                                data={data}
                                handleChange={handleChange}
                                inventory={inventory}
                                assetBaseLines={assetBaseLines}
                                assets={assets}
                                page={page}
                                pageSize={pageSize}
                                changePage={changePage}
                                totalItems={totalItems}
                            />)
                        }
                        {
                            current === stepClass.stepData && (<StepData
                                data={data}
                                handleChange={handleChange}
                                segments={segments}
                                factorTypes={factorTypes}
                                evaluations={evaluations}
                            />)
                        }
                        {
                            current === stepClass.stepUbication && (<>
                                <div className='mt-3 mb-4'>
                                    <Map
                                        google={google}
                                        zoom={14}
                                        center={markerPos}
                                        onClick={onMapClicked}
                                        initialCenter={markerPos}
                                    >
                                        <Marker
                                            onClick={onMarkerClick}
                                            name={'Ubicación del activo'}
                                            draggable={true}
                                            position={markerPos}
                                            onDragend={(_t, _map, coord) => onDragend(coord)}
                                        />
                                        <InfoWindow
                                            marker={activeMarker}
                                            visible={true}>
                                            <div>
                                                <h5 style={{ color: 'black' }}>{selectedPlace.name}</h5>
                                            </div>
                                        </InfoWindow>
                                    </Map>
                                </div>
                            </>)
                        }
                        {
                            current === stepClass.stepFiles && (<>
                                <div className='mt-4 mb-4'>
                                    <h4>Seleccionar los archivos multimedia</h4>
                                    <Upload {...propsUpload}>
                                        <ButtonIcon icon={faUpload} tooltip='Seleccionar archivos como evidencia' />
                                    </Upload>
                                </div>
                            </>)
                        }
                    </>
                }
            </div>}
            footer={
                <FooterButtons
                    current={current}
                    stepClass={stepClass}
                    onClose={onClose}
                    loadingSave={loadingSave}
                    enableNext={enableNext}
                    checkedOldInspection={checkedOldInspection}
                    data={data}
                    next={next}
                    prev={prev}
                    targetKeys={targetKeys}
                    incidents={incidents}
                    save={save}
                    saveInspection={saveInspection}
                    leaveInspection={leaveInspection}
                    closeInspection={closeInspection}
                />
            }
        />
    )
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
    LoadingContainer: Skeleton
})(NewIncident)