import React, { Component } from 'react';

export default class extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        console.log("Facturas electrónicas...");
    }

    render = () => {
        return (
            <>
                <h1>Facturación electronica</h1>
            </>
        );
    }
}