import React, { useState } from 'react'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Col, Form, Row } from 'react-bootstrap'
import { Skeleton } from 'antd'
import { storeSuppliesPumServices } from 'api/maintenance_standards/supplies_pum_services'

import ModalComp from 'components/layouts/Modal'
import ButtonIcon from 'components/layouts/ButtonIcon'
import FormIcon2 from 'components/layouts/FormIcon2'
import Swal from 'sweetalert2'

const ModalAddSupply = ({ onClose, form, setForm, syncServer, package_id }) => {
	const [loading, setLoading] = useState(false);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setForm(prevState => ({ ...prevState, [name]: value }));
	}

	const validateForm = () => {
		let response = true;
		const fields = ['code', 'description', 'unit', 'cost', 'count', 'type_description_id'];
		for (const field of fields) {
			if (!form[field]) {
				response = false;
				break;
			}
		}
		return response;
	}

	const saveSupply = async () => {
		if (validateForm()) {
			setLoading(true);
			const data = {
				...form,
				package_id,
				amount: form.count * form.cost
			}
			const { response, error } = await storeSuppliesPumServices({ data });
			if (response) {
				Swal.fire('¡Operación exitosa!', 'El registro ha sido agregado', 'success');
				syncServer();
				onClose();
			} else {
				Swal.fire('¡Operación fallida!', error, 'error');
			}
			setLoading(false);
		} else {
			Swal.fire('¡Formulario incompleto!', 'Verifique los datos del formulario', 'warning');
		}
	}

	return (
		<ModalComp
			onClose={onClose}
			size='lg'
			title='Agregar suministro'
			body={<>
				{
					loading ? <Skeleton active /> : <>
						<Row>
							<FormIcon2 title='Código' name='code' value={form?.code} handleChange={handleChange} />
							<FormIcon2 title='Description' name='description' value={form?.description} handleChange={handleChange} />
						</Row>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Tipo</Form.Label>
								<select name='type_description_id' className='form-control'
									onChange={handleChange} value={form?.type_description_id}>
									<option value={'1'}>Materiales</option>
									<option value={'2'}>Mano de Obra</option>
									<option value={'3'}>Herramienta</option>
									<option value={'4'}>Equipo</option>
								</select>
							</Form.Group>
							<FormIcon2 title='Unida de medida' name='unit' value={form?.unit} handleChange={handleChange} />
						</Row>
						<Row>
							<FormIcon2 type='number' title='Cantidad' name='count' value={form?.count} handleChange={handleChange} />
							<FormIcon2 type='number' title='Precio unitario' name='cost' value={form?.cost} handleChange={handleChange} />
							{/* <FormIcon2 type='number' title='Precio total' name='amount' value={form?.amount} readOnly={true} /> */}
						</Row>
					</>
				}

			</>}
			footer={<>
				<ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' tooltipDisabled={true} />
				<ButtonIcon name='Guardar suministro' icon={faSave} onClick={saveSupply} variant='outline-success' tooltipDisabled={true} disabled={loading} />
			</>}
		/>
	)
}

export default ModalAddSupply