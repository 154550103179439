import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { ButtonToolbar, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { HotTable } from '@handsontable/react';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faFileExcel, faFileUpload, faSave, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { downloadXLSXByArray } from 'components/libs/exportData';
import ModalComp from 'components/layouts/Modal';
import { validateBoolean, validateDate, validateNumber, validateString } from 'components/libs/validate';
import { Skeleton } from 'antd';
const _ = require('underscore');
const xlsx = require('xlsx');

class Inv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            headersInput: new Array(this.props.items.length).fill(""),
            excel_name: '',     //Nombre del libro
            excel: null,        //
            sheets: [],         //
            loading: false,     //Estado de carga
            headers: [],        //Cabeceras
            excel_rows: [],     //Datos de excel
            data_valid: true,   //Datos Validos (bandera)
            reading: false,     //
            refresh: false
        }
    }

    componentDidMount = () => {
        //Obtenemos las cabeceras de las propiedades
        for (let i = 0; i < this.props.items.length; i++) {
            this.state.headersInput[i] = this.props.items[i].label.toUpperCase();
        }
        setTimeout(() => this.setState({ show: true }), 100);
    }

    inputFileRef = React.createRef();

    handleButtonClick = () => {
        this.inputFileRef.current.click();
    };

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    // Método para obtener las hojas contenidas en el libro
    readExcel = (e) => {
        //Si no hay libro
        if (e.target.files.length === 0) {
            return;
        }
        // Asignamos nombre de libro e inicializamos en vacio los datos de carga
        this.setState({ excel_name: e.target.files[0].name, excel_rows: [] });
        if (this.props.readExcel === undefined) {
            let type = e.target.files[0].name.split('.');
            type = type[type.length - 1];
            this.state.excel = e.target.files[0];
            if (type === 'xlsx' || type === 'xls') {
                let reader = new FileReader();
                reader.onload = (evt) => {
                    let data = evt.target.result;
                    try {
                        let sheets = xlsx.read(data, { type: 'binary', sheetRows: true });
                        this.setState({ sheets: sheets.SheetNames });
                    } catch (e) {
                        Swal.fire('Error', e, 'error');
                        this.setState({ sheets: [], excel: null });
                    }
                }
                reader.readAsBinaryString(this.state.excel);
            } else {
                this.setState({ sheets: [], excel: null });
                Swal.fire('Advertencia', 'El archivo no es de extensión XLSX o XLS', 'warning');
            }
        } else {
            let excel_rows = this.props.readExcel();
            this.setState({ excel_rows });
        }
    }

    getIndexs = (ref) => {
        let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        ref = ref.split('');
        let columna_inicial = "";
        let columna_final = "";
        let fila_inicial = "";
        let fila_final = "";
        let dos_puntos = false;
        for (const element of ref) {
            if (element === ":") {
                dos_puntos = true;
            } else {
                if (!dos_puntos) {
                    if (numbers.indexOf(element) === -1) {
                        columna_inicial += element;
                    } else {
                        fila_inicial += element;
                    }
                } else {
                    if (numbers.indexOf(element) === -1) {
                        columna_final += element;
                    } else {
                        fila_final += element;
                    }
                }
            }
        }
        return { c_s: columna_inicial, c_e: columna_final, r_s: fila_inicial, r_e: fila_final };
    }

    generateNextLetter = (letter) => {
        letter = ((parseInt(letter, 36) + 1).toString(36)).replace(/0/g, 'a');
        return letter.replace('1', 'A').toUpperCase();
    }

    toFormatDate = (value) => {
        const expresion = /^[0-9]{4}-(0[1-9]|1[0-2])-([0][1-9]|[1-2][0-9]|3[0-1])T([0-1][0-9]|[2][0-3]):[0-5][0-9]:[0-5][0-9]$/;

        if (typeof value === 'number') {
            try {
                value = new Date(new Date((value - 25569) * 86400 * 1000).getTime()).toISOString().split('.')[0];
                if (!expresion.test(value)) {
                    this.state.data_valid = false;
                    return '#¡ERROR!#';
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'object') {
            try {
                let milisegundos = value.getTime();
                value = new Date(milisegundos - 1000 * 60 * 60 * 12).toISOString().split('.')[0];
                if (!expresion.test(value)) {
                    this.state.data_valid = false;
                    return '#¡ERROR!#';
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'string') {
            if (value.length >= 10) {
                if (value.length === 10) {
                    let separador = value.indexOf('/') !== -1 ? '/' : '-';
                    value = value.split(separador);
                    try {
                        if (value[0].length === 4) {
                            value = `${value.join('-')}T00:00:00`;
                        } else {
                            separador = value[0];
                            value[0] = value[2];
                            value[2] = separador;
                            value = `${value.join('-')}T00:00:00`;
                        }
                        if (!expresion.test(value)) {
                            this.state.data_valid = false;
                            return '#¡ERROR!#';
                        }
                    } catch (e) {
                        this.state.data_valid = false;
                        return '#¡ERROR!#';
                    }
                } else {
                    try {
                        let separador = value.indexOf('T') !== -1 ? 'T' : ' ';
                        value = value.split(separador);
                        separador = value[0].indexOf('/') !== -1 ? '/' : '-';
                        value[0] = value[0].split(separador);
                        try {
                            if (value[0][0].length === 4) {
                                value[0] = value[0].join('-');
                            } else {
                                separador = value[0][0];
                                value[0][0] = value[0][2];
                                value[0][2] = separador;
                                value[0] = value[0].join('-');
                            }
                        } catch (e) {
                            this.state.data_valid = false;
                            return '#¡ERROR!#';
                        }
                        if (value[1].indexOf('.') !== -1) {
                            value[1] = value[1].split('.')[0];
                        }
                        value = value.join('T');
                        if (!expresion.test(value)) {
                            this.state.data_valid = false;
                            return '#¡ERROR!#';
                        }
                    } catch (e) {
                        this.state.data_valid = false;
                        return '#¡ERROR!#';
                    }
                }
            } else {
                try {
                    let separador = value.indexOf('/') !== -1 ? '/' : '-';
                    value = value.split(separador);
                    if (value[2].length === 4) {
                        separador = value[2];
                        value[2] = value[0];
                        value[0] = separador;
                    }
                    value = value.join('-');

                    let express = /^[0-9]{4}-[1-9]-[1-9]$/;
                    if (express.test(value)) {
                        value = value.split('-');
                        value[1] = `0${value[1]}`;
                        value[2] = `0${value[2]}`;
                        return `${value.join('-')}T00:00:00`;
                    }

                    express = /^[0-9]{4}-[1-9]-([0][1-9]|[1-2][0-9]|3[0-1])$/;
                    if (express.test(value)) {
                        value = value.split('-');
                        value[1] = `0${value[1]}`;
                        return `${value.join('-')}T00:00:00`;
                    }

                    express = /^[0-9]{4}-(0[1-9]|1[0-2])-[1-9]$/;
                    if (express.test(value)) {
                        value = value.split('-');
                        value[2] = `0${value[2]}`;
                        return `${value.join('-')}T00:00:00`;
                    }

                } catch (e) {
                    this.state.data_valid = false;
                    return '#¡ERROR!#';
                }
            }
        } else {
            value = null;
        }
        return value;
    }

    toFormatTime = (value) => {
        const expresion = /^([0-1][0-9]|[2][0-3]):[0-5][0-9]:[0-5][0-9]$/;
        const expresion2 = /^([0-1][0-9]|[2][0-3]):[0-5][0-9]$/;
        if (typeof value === 'number') {
            try {
                value = new Date(new Date((value - 25569) * 86400 * 1000).getTime()).toISOString().split('.')[0].split('T')[1];
                if (!expresion.test(value)) {
                    if (expresion2.test(value)) {
                        value += ':00'
                    } else {
                        this.state.data_valid = false;
                        value = '#¡ERROR!#';
                    }
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'object') {
            try {
                let milisegundos = value.getTime();
                value = new Date(milisegundos - 1000 * 60 * 60 * 12).toISOString().split('.')[0].split('T')[1];
                if (!expresion.test(value)) {
                    if (expresion2.test(value)) {
                        value += ':00'
                    } else {
                        this.state.data_valid = false;
                        value = '#¡ERROR!#';
                    }
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'string') {
            if (!expresion.test(value)) {
                if (expresion2.test(value)) {
                    value += ':00'
                } else {
                    this.state.data_valid = false;
                    value = '#¡ERROR!#';
                }
            }
        } else {
            value = null;
        }
        return value;
    }

    // Obtener la información de una hoja a través de su índice
    getInformationSheet = (index) => {
        this.setState({ loading: true, excel_rows: [], data_valid: true });
        const reader = new FileReader();
        reader.onload = async (evt) => {
            try {
                const data = evt.target.result;
                const info = xlsx.read(data, { type: 'binary', sheets: index });
                const sheet = info.Sheets[this.state.sheets[index]];
                const indexs = this.getIndexs(sheet["!ref"]);
                const headers = [];
                const body = [];
                let valid = true;

                for (let i = indexs.r_s; i <= indexs.r_e && valid; i++) {

                    if (i === indexs.r_s) {
                        // Process headers
                        for (let l = indexs.c_s; parseInt(l, 36) <= parseInt(indexs.c_e, 36); l = this.generateNextLetter(l)) {
                            const headerValue = sheet[l + i].v.trim().toUpperCase();
                            headers.push({ v: headerValue, l });

                            if (parseInt(this.generateNextLetter(l), 36) === parseInt(index.c_e, 36)) {
                                for (const element of this.state.headersInput) {
                                    const pos = headers.findIndex(header => header.v === element);
                                    if (pos === -1) {
                                        valid = false;
                                    }
                                }
                            }
                        }
                    } else {
                        // Process body rows
                        const rowData = {};
                        for (let j = 0; j < this.state.headersInput.length; j++) {
                            const item = headers.find(header => header.v === this.state.headersInput[j]);
                            const headerCell = this.props.items[j];

                            if (sheet[item.l + i] !== undefined) {
                                let value = sheet[item.l + i].v;
                                if (headerCell.toFormat !== undefined) {
                                    if (headerCell.toFormat === 'Date') {
                                        value = this.toFormatDate(value);
                                    }
                                    if (headerCell.toFormat === 'Time') {
                                        value = this.toFormatTime(value);
                                    }
                                }

                                switch (headerCell.type) {
                                    case 'String':
                                        value = validateString(value);
                                        break;
                                    case 'Number':
                                        value = validateNumber(value);
                                        break;
                                    case 'Boolean':
                                        value = validateBoolean(value);
                                        break;
                                    case 'Date':
                                        value = validateDate(value);
                                        break;
                                    default:
                                        break;
                                }
                                this.setState({ data_valid: value });
                                rowData[headerCell.value] = value;
                            } else {
                                if (headerCell.allow !== undefined) {
                                    rowData[headerCell.value] = headerCell.allow;
                                } else {
                                    rowData[headerCell.value] = null;
                                }
                            }
                        }
                        body.push(rowData);
                    }
                }
                this.setState({ headers, excel_rows: body, loading: false });
            } catch (e) {
                Swal.fire('Error', 'Ocurrio un error en la lectura de los datos, por favor verifique que las cabeceras del EXCEL corresponden a lo esperado y que los datos tengan el formato correcto, según se requiera.', 'error');
                this.setState({ loading: false, excel_rows: [], headers: [] });
            }
        }
        reader.readAsBinaryString(this.state.excel);
    }

    saveToServer = () => {
        if (this.state.data_valid) {
            if (this.state.excel_rows.length !== 0) {
                this.setState({ uploading: true });
                let main_data = []
                this.state.excel_rows.map((item, i) => {
                    let extra = []
                    this.props.extra_items.map((extra_item) => {
                        extra.push(item[extra_item.value])
                        delete item[extra_item.value]
                    })
                    item.extras = JSON.stringify(extra)
                    main_data.push(item)
                })

                fetch(this.props.server, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json'
                    },
                    body: JSON.stringify(main_data)
                }).then(res => {
                    if (res.status === 200) {
                        res.json().then(response => {
                            Swal.fire('Registro correcto', 'Los datos se registraron correctamente', 'success');
                            this.props.onClose();
                        })
                    } else if (res.status === 401) {
                        res.json().then(error => {
                            Swal.fire(`${error.statusCode}: ${error.error}`, `<div style="text-align: left;"><strong>La carga de datos falló por una de las siguientes razones:</strong> <br />1.- De los datos estáticos que se intentan cargar, posiblemente no están registrados previamente. <br />2.- El formato de los datos no es válido para el registro.<br />3.- La longitud de los campos sobrepasa lo permitido.<br /><br /><strong>Solución:</strong><br />1.- Corregir o cambiar los campos del excel.<br />2.- Comunicarse con el administrador del Sistema.<br />3.- Comunicarse a soporte del Sistema.<div>`, 'error');
                        })
                    } else {
                        res.json().then(error => {
                            Swal.fire(error.error, `${error.statusCode}: ${error.message}`, 'error');
                        })
                    }
                    this.setState({ uploading: false });
                }).catch(e => {
                    this.setState({ uploading: false });
                    Swal.fire("Error", e.toString(), 'error');
                })
            } else {
                Swal.fire('Advertencia', 'No hay datos disponibles para carga.', 'warning');
            }
        } else {
            Swal.fire('Advertencia', 'Existen datos no válidos en el documento, posiblemente por un formato incorrecto.', 'warning');
        }
    }

    afterChange = (changes, source) => {
        if (source === 'edit') {
            changes.forEach(([row, col, oldValue, newValue]) => {
                if (row === 0)
                    this.setState(prevState => {
                        const headersInput = [...prevState.headersInput];
                        headersInput[col] = newValue.toUpperCase();
                        return { headersInput };
                    });
            });
        }
    }

    downloadTemplate = () => {
        const data = [this.state.headersInput];
        const widthCells = this.props.items.map(item => ({ width: item.width }))
        const { code, inventory } = this.props.inventory;
        downloadXLSXByArray({ fileName: 'Template - ' + code + ' | ' + inventory, data, widthCells });
    }

    getBodyData = () => {
        const rows = this.state.excel_rows.map((row) => this.props.items.map((item) => row[item.value]));
        return rows;
    }

    render = () => {
        return (
            <div>
                <ModalComp
                    title={this.props.title}
                    size='xl'
                    onClose={this.handleClose}
                    body={<>
                        {/* Importante: Mostrar HotTable hasta que se terminen de leer los datos para evitar multiples renderizaciones */}
                        {
                            this.state.loading ? <>
                                <Skeleton active />
                            </> :
                                <HotTable
                                    className='mt-3 mb-4'
                                    data={[
                                        this.state.headersInput,
                                        ...this.getBodyData()
                                    ]}
                                    rowHeaders={true}
                                    colHeaders={true}
                                    height="auto"
                                    width="100%"
                                    colWidths={this.props.items.map(item => item.widthXLSX)}
                                    manualColumnResize={true}
                                    licenseKey="non-commercial-and-evaluation"
                                    afterChange={this.afterChange}
                                />
                        }
                        {/* Seleccionar archivos */}
                        <input type="file" ref={this.inputFileRef} style={{ display: 'none' }} onChange={this.readExcel} />

                    </>}
                    footer={<>
                        <ButtonIcon name='Cerrar' onClick={this.handleClose} icon={faTimes} variant='outline-secondary' />
                        <ButtonIcon name='Descargar plantilla' icon={faFileExcel} variant='outline-primary' onClick={this.downloadTemplate} />
                        <ButtonIcon name='Elegir Excel' icon={faFileUpload} onClick={this.handleButtonClick} variant='outline-success' />
                        {
                            this.state.sheets.length !== 0 &&
                            <div>
                                <ButtonToolbar style={{ padding: 10, flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <ButtonGroup>
                                        <DropdownButton as={ButtonGroup} title='Hojas' id="bg-nested-dropdown" variant={'danger'} disabled={this.state.uploading}>
                                            {
                                                this.state.sheets.map((item, i) =>
                                                    <Dropdown.Item key={i} onClick={() => this.getInformationSheet(i)} disabled={this.state.loading}>{item}</Dropdown.Item>
                                                )
                                            }
                                        </DropdownButton>
                                    </ButtonGroup>
                                    {
                                        this.state.loading &&
                                        <ButtonIcon name='Cargando...' icon={faSpinner} variant='outline-secondary' disabled={true} />
                                    }
                                    {
                                        this.state.excel_rows.length !== 0 &&
                                        <ButtonIcon name={!this.state.uploading ? 'Cargar al servidor' : 'Cargando en el servidor...'} icon={faSave}
                                            onClick={this.saveToServer} variant='success' disabled={this.state.uploading} />

                                    }
                                </ButtonToolbar>
                            </div>
                        }
                    </>}
                />
            </div>
        );
    }
}

Inv.propTypes = {
    server: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['String', 'Number', 'Boolean', 'Date']).isRequired,
        allow: PropTypes.string,
        toFormat: PropTypes.oneOf(['Date', 'Time'])
    }).isRequired).isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    headers: PropTypes.element,
    readExcel: PropTypes.func
}

export default Inv;