import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import '../UOV-0/Operacion.css';
import '../UOV-0/Resultados.css';
import { __SERVER__ } from '../../../../server/info';
import { Modal, Button } from 'react-bootstrap';
import Pagination from '../Pagination';
import { Variables } from '../../../../variables/Variables';
import FAB from '../../../generales/FAB.jsx';
import ModalUpload from '../Modal.jsx';
import Deductiva from '../Deductiva.jsx';
import Axios from 'axios';
import { Heading } from '@chakra-ui/react';

const MySwal = withReactContent(Swal);
const _ = require('underscore');

const FiltroBusqueda = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				receptionS: "2019-11-01",
				receptionE: "2019-11-02"
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: ""
		};
	}

	generateQuery = () => {
		if (this.state.query.receptionE === "" || this.state.query.receptionS === "") {
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "0") {
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
		this.props.setProps(this.state.query_, headersTable)
	}

	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="receptionS" value={this.state.query.receptionS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="receptionE" value={this.state.query.receptionE} onChange={this.changeInput} />
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const Grid = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			item: {}
		}
	}

	render() {
		return (
			<div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.data_per_page}</label>
				</section>
				<div className="cards">
					{
						this.props.data.data.map(item =>
							<div className="card">
								<div className="card-header"><strong>Código: {item.code}</strong></div>
								<div><strong>Tipo:</strong> {item.type} </div>
								<div><strong>Medio:</strong> {item.means}  </div>
								{(item.folio !== null) ? <div><strong>Folio:</strong>{item.folio}</div> : null}
								<div><strong>Fecha de recepción: </strong> {item.reception.split('T')[0]} </div>
								{(item.incidence_date !== null) ? <div><strong> Fecha del incidente: </strong> {item.incidence_date.split('T')[0]}</div> : null}
								{(item.time_ !== null) ? <div><strong> Hora: </strong>{item.time_}</div> : null}
								<div><strong>Nombre:</strong> {item.name} </div>
								{(item.email !== null) ? <div><strong>Correo:</strong> {item.email}</div> : null}
								{(item.phone !== null) ? <div><strong>Teléfono:</strong> {item.phone}</div> : null}
								{(item.answer_date !== null) ? <div><strong>Fecha de respuesta: </strong> {item.answer_date.split('T')[0]}</div> : null}
								{(item.compliance !== null) ? <div><strong>Cumplimiento: </strong>{item.compliance}</div> : null}
								{(item.status !== null) ? <div><strong>Estado: </strong>{item.status}</div> : null}
								{(item.deadline !== null) ? <div><strong>Fecha de cierre: </strong>{item.deadline.split('T')[0]}</div> : null}
								<Button variant='link' onClick={() => this.setState({ modal: true, item })}>Más detalles</Button>
							</div>)
					}
				</div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<Modal show={this.state.modal} backdrop="static" keyboard={false} onHide={() => this.setState({ modal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.item.code}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<strong>Asunto</strong>
						<p>
							{this.state.item.subject}
						</p>
						<strong>Respuesta</strong>
						<p>
							{this.state.item.reply}
						</p>

					</Modal.Body>
					<Modal.Footer onClick={() => this.setState({ modal: false })}>
						<Button variant="danger">Cerrar</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			refresh: false,
			page: 1,
			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false,
			buttonVisible: false
		}
	}

	componentDidMount = async () => {
		// GET operating_indicator_id
		let res = await Axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')
		
		if (res.data.length!==0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	generateTable = () => {
		let data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];
		this.setState({
			grid: null
		}, () => {
			if(data !== undefined){
				this.setState({ grid: <Grid updatePage={this.updatePage} project_id={this.props.project_id} data={data} headersTable={this.state.headersTable} /> });
			}
		})
	}

	updatePage = (page) => {
		this.setState({ page }, this.getUserSupport);
	}

	getUserSupport = () => {
		let index = _.findIndex(this.state.result, { page: this.state.page });
		if (index !== -1) {
			this.generateTable();
			return;
		}
		let query = `${this.state.query}${this.state.query === '' ? '?':'&'}page=${this.state.page}`;
		console.log(query)
		fetch(__SERVER__ + "/projects/" + this.props.project_id + "/user_support" + query, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					this.state.result.push(result);
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			Swal.fire('Error', e, 'error');
		})
	}

	setProps = (query, headersTable) => {
		this.setState({ page: 1, query: query, result: [], headersTable: headersTable }, this.getUserSupport)
	}

	openOrCloseModal = () => {
		this.setState({modalUploadData: !this.state.modalUploadData});
	}

	openOrCloseModalDeductivas = () => {
		this.setState({ modalDeductivas: !this.state.modalDeductivas });
	}

	render() {
		return (
			<div className="body_">
				<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
				<FiltroBusqueda setProps={this.setProps} />
				{this.state.grid}

				{
					this.state.buttonVisible ?
						<FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faCarCrash'} onClick={this.openOrCloseModalDeductivas} /> : null
				}
				<FAB style={{position: 'fixed', bottom: 10, right: 10}} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />
				{
					this.state.modalUploadData ?
						<ModalUpload 
							server={`${__SERVER__}/projects/${Variables.project_id}/user_support`}
							items={[
								{label: 'Código', description: 'Código', value: 'code', type: 'String'},
								{label: 'Recepción', description: 'Recepción', value: 'reception', type: 'String'},
								{label: 'Tipo', description: 'Tipo', value: 'type', type: 'String'},
								{label: 'Medio', description: 'Medio', value: 'means', type: 'String'},
								{label: 'Folio', description: 'Folio', value: 'folio', type: 'String'},
								{label: 'Fecha de incidente', description: 'Fecha de incidente', value: 'incedence_date', type: 'String'},
								{label: 'Tiempo', description: 'Tiempo', value: 'time', type: 'String'},
								{label: 'Nombre', description: 'Nombre', value: 'name', type: 'String'},
								{label: 'Correo electrónico', description: 'Correo electrónico', value: 'email', type: 'String'},
								{label: 'Teléfono', description: 'Teléfono', value: 'phone', type: 'String'},
								{label: 'Asunto', description: 'Asunto', value: 'subject', type: 'String'},
								{label: 'Fecha de respuesta', description: 'Fecha de respuesta', value: 'answer_date', type: 'String'},
								{label: 'Comformidad', description: 'Comformidad', value: 'compliance', type: 'Number'},
								{label: 'Respuesta', description: 'Respuesta', value: 'reply', type: 'String'},
								{label: 'Rastreo', description: 'Rastreo', value: 'tracing', type: 'String'},
								{label: 'Estado', description: 'Estado', value: 'status', type: 'String'},
								{label: 'Fecha límite', description: 'Fecha límite', value: 'deadline', type: 'String'}
							]}
							title={`${this.props.code} ${this.props.name}`} 
							onClose={this.openOrCloseModal} />
						:null
				}
				{
					this.state.modalDeductivas ?
						<Deductiva
							title={this.props.code}
							formula={this.state.formula}
							pum={this.state.PUMObject}
							cump={this.state.CumplObject}
							formulaCump={this.state.formulaCump}
							formulaPUM={this.state.formulaPUM}
							hayFormula={this.state.hayFormula}
						/> : null
				}
			</div>
		);
	}
}