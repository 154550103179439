import Axios from 'axios';
import React, { Component } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { __SERVER__ } from 'server/info';
import Swal from 'sweetalert2';
import { Variables } from 'variables/Variables';
import Map from '../Proyectos/Mantemiento/Inventarios/Map';

const ModalMap = class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            uploading: false,
            initialCenter: undefined,
            center: undefined,
            ubications: [],
            height: '500px',
            width: '100%'
        };
    }

    componentDidMount = () => {
        this.getUbication();
        setTimeout(() => this.setState({ show: true }), 100);
    }

    getUbication = async () => {
        let { package_id, project_id } = Variables;
        let { bridge_id, inspection_id } = this.props;
        let ubication = await Axios(`${__SERVER__}/packages/${package_id}/projects/${project_id}/bridges/${bridge_id}/inspection/${inspection_id}/1/geographical_position`);
        console.log("ubication data: ", ubication.data);
        if (ubication.data.length > 0) {
            this.setState({
                center: {
                    lat: ubication.data[0].latitude,
                    lng: ubication.data[0].longitude
                },
                ubications: [
                    {
                        id: 1,
                        title: 'Incidencia',
                        name: 'Ubicación de la incidencia',
                        position: {
                            lat: ubication.data[0].latitude,
                            lng: ubication.data[0].longitude
                        }
                    }
                ]
            });
        } else {
            this.setState({ height: '500px' });
        }
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    render = () => {
        // console.log("ModalMap [state]: ", this.state)
        let { width, height } = this.state;
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height, width }}>
                    <center>
                        {
                            this.state.ubications.length === 0 ?
                                <Alert variant={"warning"}>
                                    ¡La ubicación no fue registrada!</Alert> : null
                        }

                        <Map
                            ubications={this.state.ubications}
                            initialCenter={this.state.initialCenter}
                            center={this.state.center}
                        />
                    </center>
                </Modal.Body>
            </Modal >
        );
    }

};

export default ModalMap;