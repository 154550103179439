import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexCostWorkOrders = async ({ package_id, ...params }) => {
    const url = `/packages/${package_id}/cost_work_order`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: cost_work_order.js:13 ~ indexCostWorkOrders ~ fetch:", fetch)

        if (fetch.status === httpStatus.ok) {
            response.data = fetch.data;
            response.totalItems = fetch.data.length;
        }
        return { response, cancel };
    } catch (error) {
        return { response: false, cancel: () => { } };
    }
}

export const indexCostWorkOrdersWithPag = async ({ ...params }) => {
    const url = `/maintenance_standard/cost_work_order`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: cost_work_order.js:34 ~ indexCostWorkOrdersWithPag ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data;
        }
        return { response, cancel };
    } catch (error) {
        return { response: false, cancel: () => { } };
    }
}

export const indexCostWorkOrdersByStandard = async ({ package_id, standard_id }) => {
    const url = `/maintenance_standard/activities/cost_work_order`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { package_id, standard_id }
        });
        // console.log("🚀 ~ file: cost_work_order.js:53 ~ indexCostWorkOrdersByStandard ~ fetch:", fetch)

        if (fetch.status === httpStatus.ok) {
            response.data = fetch.data;
            response.totalItems = fetch.data.length;
        }
        return { response, cancel };
    } catch (error) {
        return { response: false, cancel: () => { } };
    }
}

export const storeCostWorkOrder = async ({ data }) => {
    const url = `/maintenance_standard/cost_work_order`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: cost_work_order.js:53 ~ storeCostWorkOrder ~ fetch:", fetch)

        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok)
            response = fetch.data
        return { response };
    } catch (error) {
        return { response };
    }
}