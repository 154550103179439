import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexAssetsComponents = async ({ asset_id, table, component_type_id, ...rest }) => {
    const url = `/${table}/${asset_id}/components`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { component_type_id, ...rest }
        });
        // console.log("🚀 ~ file: assets_componets.js:12 ~ indexAssetsComponents ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const storeAssetsComponents = async ({ asset_id, data, table }) => {
    const url = `/${table}/${asset_id}/components`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: assets_componets.js:27 ~ storeAssetsComponents ~ fetch:", fetch)
        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}