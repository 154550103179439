import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Map from 'components/subComponents/Proyectos/Mantemiento/Inventarios/Map'

const IncidentMap = (props) => {
    const { incidentSel } = props;

    const [ubications, setUbications] = useState([]);

    useEffect(() => {
        const { id, segment, created_at, latitude, longitude, user, latitude_ends, longitude_ends } = incidentSel;
        const info = {
            title: `${segment?.name} ${segment?.location}`,
            name: `
            Incidente creado por: ${user?.first_name} ${user?.last_name}
            Puesto: ${user?.position}
            Fecha de creación: ${moment(created_at).format('llll')}
        `}
        let arr_ubi = [{
            id,
            ...info,
            position: {
                lat: latitude,
                lng: longitude
            }
        }];
        if (latitude_ends) {
            arr_ubi.push({
                id: id + '_2',
                ...info,
                position: {
                    lat: latitude_ends,
                    lng: longitude_ends
                }
            });
        }
        setUbications(arr_ubi);
    }, []);

    return (
        <center>
            <div style={{ height: '40vh', width: '100%' }}>
                <Map
                    ubications={ubications}
                    center={ubications[0]?.position}
                    initialCenter={ubications[0]?.position}
                />
            </div>
        </center>
    )
}

export default IncidentMap