import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexEmergencyCoordination = async ({ project_id, package_id, ...params }) => {
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/emergency_coordination`,
            method: 'GET',
            params: {
                package_id,
                project_id,
                ...params
            }
        });
        // console.log("🚀 ~ file: emergency_coordination.js:16 ~ indexEmergencyCoordination ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            const { data, totalItems } = fetch.data;
            response = { data, totalItems };
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const getUrlEmergencyCoordinationXlsx = async ({ project_id, package_id, start_date, end_date }) => {
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `/emergency_coordination/xlsx`,
            method: 'GET',
            params: { package_id, project_id, start_date, end_date }
        });
        // console.log("🚀 ~ file: emergency_coordination.js:12 ~ getUrlEmergencyCoordinationXlsx ~ fetch:", fetch)

        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}