import Axios from 'axios';
import React, { Component } from 'react';
import { Modal, Col, Form, Button, Card } from 'react-bootstrap';
import { __SERVER__ } from 'server/info';
import Swal from 'sweetalert2';
import { Variables } from 'variables/Variables';

const Photographs = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photographs: [],
            file_names: []
        }
    }

    componentDidMount = () => {
        console.log('photos get files: ', this.props.photographs)
        this.getPhotograph();
    }

    getPhotograph = async (file_name) => {
        let { inspection_id, bridge_id } = this.props;
        let photos = await
            Axios.get(`${__SERVER__}/packages/${Variables.package_id}/projects/${Variables.project_id}/bridges/${bridge_id}/inspection/${inspection_id}/10/visual_inspection_photographs?path=${file_name}`, {
                method: "GET",
                responseType: "blob"
            });
        console.log("responsedata type:: ", photos.data.type)
        const file = new Blob([photos.data], {
            type: photos.data.type
        });
        let blob_file = URL.createObjectURL(file);
        console.log(`file_name: `, blob_file);
        return blob_file;
    }

    render = () => {
        return (
            <section fluid>
                {
                    this.props.photographs.map((item, i) =>
                        <Card key={i} style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={this.getPhotograph(item.file_name)} />
                            <Card.Body>
                                <Card.Title>{item.description}</Card.Title>
                                <Card.Text>{item.creation_date}</Card.Text>
                            </Card.Body>
                        </Card>
                    )
                }
            </section>
        );
    }
}
const ModalMap = class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            uploading: false,
            longitude: null,
            latitude: null,
            photographs: [],
        };
    }

    componentDidMount = () => {
        this.getGeographicalPosition();
        this.getVisualInspectionsPhotographs();
        setTimeout(() => this.setState({ show: true }), 100);
    }

    getVisualInspectionsPhotographs = async () => {
        let photographs = await Axios.get(`${__SERVER__}/packages/${Variables.package_id}/projects/${Variables.project_id}/bridges/${this.props.bridge_id}/inspection/${this.props.inspection_id}/10/visual_inspection_photographs`);
        console.log("photographs: ", photographs.data);
        if (photographs.data.length === 0) { return; }
        this.setState({ photographs: photographs.data });
    }
    getGeographicalPosition = async () => {
        let geographical_position = await
            Axios.get(`${__SERVER__}/packages/${Variables.package_id}/projects/${Variables.project_id}/bridges/${this.props.bridge_id}/inspection/${this.props.inspection_id}/1/geographical_position`);

        if (geographical_position.data.length === 0) { return; }
        this.setState({
            longitude: geographical_position.data[0].longitude,
            latitude: geographical_position.data[0].latitude,
        });
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    render = () => {
        let { latitude, longitude } = this.state;
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Latitude</Form.Label>
                                <Form.Control type="text" placeholder="Latitud" defaultValue={latitude} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Longitud</Form.Label>
                                <Form.Control type="text" placeholder="Longitud" defaultValue={longitude} />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    {
                        this.state.photographs.length === 0 ?
                            <h1>Sin fotografías</h1> :
                            <Photographs
                                photographs={this.state.photographs}
                                bridge_id={this.props.bridge_id}
                                inspection_id={this.props.inspection_id}
                            />
                    }
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal >
        );
    }

};

export default ModalMap;