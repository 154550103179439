import { Skeleton } from 'antd'
import { GoogleApiWrapper, Map, Marker, Polyline } from 'google-maps-react'
import React, { useEffect, useState } from 'react'

const Ubication = (props) => {
    const { google, data } = props;

    const [coords, setCoords] = useState([]);

    useEffect(() => {
        const getCoords = () => {
            const { latitude, longitude, latitude_E, longitude_E } = data;
            let arrCoords = [{ lat: latitude, lng: longitude }];
            if (latitude_E && longitude_E) {
                arrCoords.push({ lat: latitude_E, lng: longitude_E });
            }
            setCoords(arrCoords)
        }
        getCoords();
    }, []);

    const onPolylineReady = (_, map) => {
        const bounds = new props.google.maps.LatLngBounds();
        coords.forEach(point => bounds.extend(point));
        map.fitBounds(bounds);
    };

    return (
        <div style={{ height: '40vh', width: '100%' }}>
            <Map
                google={google}
                style={{ width: '100%', height: '100%', position: 'relative' }}
                center={coords[0]}
                className={'map'}
                zoom={14}
                onReady={onPolylineReady}
            >
                <Polyline
                    path={coords}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={3}
                />
                {
                    coords.map((coord) =>
                        <Marker
                            key={coord}
                            name={'Current location'}
                            position={coord}
                        />
                    )
                }
            </Map>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
    LoadingContainer: Skeleton
})(Ubication)