import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "../../variables/Variables";

export const indexMaintenanceStandards = async ({ package_id }) => {
    const url = `/packages/${package_id}/maintenance_standards`;
    let response = { data: [], totalItems: 0, error: true };
    try {
        const { fetch } = await fetcherCancelToken({
            url,
            method: 'GET'
        });
        // console.log("🚀 ~ file: maintenance_standards.js:12 ~ indexMaintenanceStandards ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response.data = fetch.data;
            response.error = false;
            response.totalItems = fetch.data.length;
        }
        return { response };
    } catch (error) {
        return { response };
    }
}