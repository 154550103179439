import React, { Component } from 'react';
import './PerfilInformativo.css';
import axios from 'axios';
import { __SERVER__ } from '../../server/info';
import CanvasJSReact from '../../assets/canvasjs.react';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Tree = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: [],
      formato: []
    }
  }

  componentDidMount = () => {
    this.getTree();
    this._interval = setTimeout(this.getTree, 30000);
  }

  componentWillUnmount = () => {
    clearInterval(this._interval);
  }

  openFolder = () => {

  }

  extendsTree = (name) => {
    fetch(__SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/tree', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
  }

  getChildren = (margin, children) => {
    margin = 15;
    let tree = [];
    if (children.length !== 0) {
      /// Las primeras carpetas serías las carpetas root
      for (let i = 0; i < children.length; i++) {
        if (children[i].type === "directory") {
          tree.push(
            <div style={{ paddingLeft: margin }} key={children[i].name + i} className="tree">
              <label>{children[i].name}</label>
              {
                children[i].children.length !== 0 ?
                  this.getChildren(margin, children[i].children).map(item => item)
                  : null
              }
            </div>);
        }
      }
    }
    return tree;
  }

  getFormat = (children, p) => {
    p += 1;
    let child = []
    for (let i = 0; i < children.length; i++) {
      if (children[i].type === "directory") {
        child.push({ active: false });
        if (children[i].children.length !== 0) {
          this.getFormat(children[i].children);
        }
      }
    }

    console.log(this.state.formato);

    //res.push(child);
    this.state.formato[p - 1].sub = child;
  }

  getTree = () => {
    fetch(__SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/tree', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    }).then(res => {
      if (res !== null) {
        res = res.children;
        let tree = [];
        if (res.length !== 0) {
          /// Las primeras carpetas serías las carpetas root
          for (let i = 0; i < res.length; i++) {
            if (res[i].type === "directory") {
              //this.state.formato.push({active: false})
              tree.push(
                <div key={"root" + i} className="tree">
                  <label onClick={() => this.props.openFolder(res[i].name)}>{res[i].name}</label>
                </div>);
            }
          }
        }
        console.log(this.state.formato)
        this.setState({ tree: tree });
        console.log(res);
      } else {
        throw ("null");
      }
    }).catch(e => {
      console.log("Error: ", e);
    })
  }

  render = () => {
    return (
      <section className="leftMenu">
        {
          this.state.tree.map(item => item)
        }
      </section>
    );
  }
}

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      bitacoraOnline: [],
      query: "",
      query_bread: [],
      addFolder: true,
      oldFolderName: "",
      newFolderName: {
        name: "",
        open: false
      },
      addFiles: {
        open: false
      },
      clicks: 0,
      menuContextual: [],
      files: null,
      queryFile: "",
      tree: [],
      treeOpen: [],
      classNameFolder: "folder",
      folderimg: "folder-img",
      bitacoraOnlineClass: "bitacoraOnline",
      type: false,
      view: null,
      componentView: null,
      modalTableView: null,
      modalGraphView: null,
      modalMapView: null
    }
    // console.log("Project ID: ", this.props.project_id);
    this.getBitacoraOnline = this.getBitacoraOnline.bind(this);
    this.getTree = this.getTree.bind(this);
    this.addTree = this.addTree.bind(this);
  }

  reopenFolder = (pos) => {
    if (pos === 0) {
      this.state.query = "";
      this.state.query_bread = [];
      this.getBitacoraOnline();
    } else {
      this.state.query = "?path=";
      let bread = [];
      bread.push("/");
      for (let i = 1; i < pos + 1; i++) {
        this.state.query += this.state.query_bread[i];
        if ((i) !== pos) {
          this.state.query += "/";
        }
        bread.push(this.state.query_bread[i])
      }
      this.state.query_bread = bread;
      console.log(this.state.query);
      console.log(this.state.query_bread);
      this.getBitacoraOnline();
    }
  }

  reopenFolderByName = (name) => {
    this.state.query = "?path=" + name;
    console.log(name);
    let bread = [];
    bread.push("/");
    bread.push(name);
    this.state.query_bread = bread;
    console.log(this.state.query);
    console.log(this.state.query_bread);
    this.getBitacoraOnline();
  }

  openFolder = (folder) => {
    if (this.state.query_bread.length === 0) {
      this.state.query = "?path=" + folder;
      this.state.query_bread.push("/");
    } else {
      this.state.query += "/" + folder;
    }
    this.state.query_bread.push(folder)
    this.getBitacoraOnline();
  }

  openFile = async (name) => {
    if (this.state.query === "") {
      this.state.queryFile = "?path=" + name;
    } else {
      this.state.queryFile = this.state.query + "/" + name;
    }
    let url = __SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/getFile' + this.state.queryFile;
    console.log("URL: ", url);
    axios(url, {
      method: "GET",
      responseType: "blob"
    })
      .then(response => {
        const file = new Blob([response.data], {
          type: response.data.type
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(error => {
        console.log(error);
      });
  }

  toDataURL = (url, callback) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = () => {
      let reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  updateFolder = async () => {
    let newpath = "/";
    let oldpath = "/";
    if (this.state.query_bread.length === 0) {
      newpath += this.state.newFolderName.name;
      oldpath += this.state.oldFolderName;
    } else {
      for (let i = 1; i < this.state.query_bread.length; i++) {
        newpath += this.state.query_bread[i] + "/";
        oldpath += this.state.query_bread[i] + "/";
      }
      newpath += this.state.newFolderName.name;
      oldpath += this.state.oldFolderName;
    }
    console.log(newpath);
    console.log(oldpath);
    fetch(__SERVER__ + "/projects/" + this.props.project_id + "/informative_profile/rename", {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify({
        newpath: newpath,
        oldpath: oldpath
      })
    }).then(re => {
      if (re.status === 200) {
        re.json().then(res => {
          this.state.newFolderName.open = false;
          this.state.newFolderName.name = "";
          this.state.oldFolderName = "";
          this.getBitacoraOnline();
          this.getTree();

        })
      }
    }).catch(e => {
      console.log(e);
    })
  }

  createFolder = async () => {
    let path = "/";
    if (this.state.query_bread.length === 0) {
      path += this.state.newFolderName.name;
    } else {
      for (let i = 1; i < this.state.query_bread.length; i++) {
        path += this.state.query_bread[i] + "/";
      }
      path += this.state.newFolderName.name;
    }
    console.log(path);
    fetch(__SERVER__ + "/projects/" + this.props.project_id + "/informative_profile", {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify({
        path: path
      })
    }).then(re => {
      if (re.status === 200) {
        re.json().then(res => {
          this.state.newFolderName.open = false;
          this.openFolder(this.state.newFolderName.name);
          this.state.newFolderName.name = "";
          this.getTree();

        })
      }
    }).catch(e => {
      console.log(e);
    })
  }

  getBitacoraOnline() {
    fetch(__SERVER__ + "/projects/" + this.props.project_id + "/informative_profile" + this.state.query, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then(res => {
      if (res.status === 200) {
        res.json().then(f => {
          this.state.menuContextual = new Array(f.length).fill(false);
          let createView = false;
          for (let i = 0; i < f.length; i++) {
            if (f[i].name === "info.json") {
              f.splice(i, 1);
              //eliminar nombre en la lista
              //llamar el creteView
              createView = true;
            }
          }

          this.setState({
            bitacoraOnline: f
          })

          if (createView) {
            this.createView();
          } else {
            this.setState({ componentView: null })
          }
        })
      } else {
        console.log('ERROR', res.json());
      }
    }).catch(e => {
      console.log(e);
    })
  }

  componentDidMount() {
    this.getBitacoraOnline();
    //this.getTree();
  }

  delete = async (name, i) => {
    console.log(name);
    console.log(this.state.query_bread);
    let path = "/";
    if (this.state.query_bread.length === 0) {
      path += name;
    } else {
      for (let i = 1; i < this.state.query_bread.length; i++) {
        path += this.state.query_bread[i] + "/"
      }
      path += name
    }
    console.log(JSON.stringify({
      path: path
    }))
    fetch(__SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/delete', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        path: path
      })
    }).then(res => {
      if (res.status === 200) {
        res.json().then(r => {
          this.getBitacoraOnline();
          this.getTree();
          this.state.menuContextual.fill(false);
          this.setState({ refresh: !this.state.refresh });
        });
      }
    }).catch(e => {
      console.log(e)
      this.state.menuContextual.fill(false);
      this.setState({ refresh: !this.state.refresh });
    })

  }

  options = (e, i) => {
    this.state.clicks = 0;
    this.state.menuContextual.fill(false);
    this.state.menuContextual[i] = true;
    this.setState({ refresh: !this.state.refresh });
  }

  uploadFiles = () => {
    let folders_names = "root";

    if (this.state.query_bread.length !== 0) {
      for (let i = 1; i < this.state.query_bread.length; i++) {
        folders_names += "," + this.state.query_bread[i];
      }
    }
    console.log(folders_names);
    if (this.state.files !== null) {
      console.log(this.state.files);
      const dataa = new FormData()
      for (let i = 0; i < this.state.files.length; i++) {
        dataa.append('file', this.state.files[i], this.state.files[i].name);
      }
      console.log(dataa);
      axios.post(__SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/upload/' + folders_names,
        dataa,
        {
          'Accept': 'application/json',
          'content-type': 'application/x-www-form-urlencoded'
        }
      ).then(res => {
        let json = res.data;
        console.log("JSON", json);
        this.getBitacoraOnline();
        this.state.addFiles.open = false;
        this.state.files = null;
        this.setState({ refresh: !this.state.refresh });
      }).catch(e => {
        console.log('Error', e);
      })
    } else {
      alert('No se ha seleccionado ningun archivo');
    }
  }

  onlyDirectory = (tree) => {
    if (tree.children !== 0) {
      for (let i = 0; i < tree.children.length; i++) {

      }
    }
  }

  getTree = () => {
    fetch(__SERVER__ + "/projects/" + this.props.project_id + "/informative_profile/tree", {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    }).then(res => {
      if (res.status === 200) {
        res.json().then(f => {
          this.onlyDirectory(f);

          this.state.treeOpen = []
          this.setState({
            tree: f.children
          })
          console.log(f.children)
        })
      } else {
        console.log('ERROR', res.json());
      }
    }).catch(e => {
      console.log(e);
    })
  }

  addTree = (pos) => {

  }

  change = () => {
    if (this.state.type) {
      this.state.classNameFolder = "folder";
      this.state.folderimg = "folder-img";
      this.state.bitacoraOnlineClass = "bitacoraOnline";
    } else {
      this.state.classNameFolder = "folder2";
      this.state.folderimg = "folder-img2";
      this.state.bitacoraOnlineClass = "bitacoraOnline2";
    }
    this.setState({ type: !this.state.type });
  }

  createView = () => {
    let query = this.state.query + "/info.json";
    if (this.state.query === "") {
      query = "?path=info.json";
    }
    let url = __SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/getInfo' + query;
    fetch(url, {
      method: 'GET',
      headers: {
        accept: 'application/json'
      }
    }).then(res => {
      if (res.status === 200) {
        res.json().then(r => {
          if (r.view !== undefined) {
            let buttons = [];
            for (let i = 0; i < r.view.length; i++) {
              let view = r.view[i];
              if (view === "table") {
                buttons.push(<button onClick={() => this.openModalTable(r.source)} key={"T" + i}>Tabla</button>);
              } else if (view === "map") {
                buttons.push(<button onClick={() => this.openModalMap(r.source)} key={"M" + i}>Mapa</button>);
              } else {
                buttons.push(<button onClick={() => this.openModalGraph(r.source, view)} key={"G" + i}>Gráfica</button>);
              }
            }
            this.setState({
              componentView:
                <div className="viewButtons">
                  {buttons.map(ite => ite)}
                </div>
            });
          }
        })
      }
    }).catch(e => {
      console.log("ERRORJSON", e);
    });
  }

  openModalTable = (source) => {
    fetch(__SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/source?table=' + source, {
      method: 'GET',
      headers: {
        accept: 'application/json'
      }
    }).then(res => {
      if (res.status === 200) {
        res.json().then(r => {
          let keys = Object.keys(r.headers);
          let headers = r.headers;
          let data = r.data;
          let modalTableView = <table>
            <tr>{keys.map((item, i) => Array.isArray(headers[item]) ? headers[item].map((it, j) => <th key={'H' + i + j}>{it}</th>) : <th key={'H' + i}>{headers[item]}</th>)}</tr>
            {
              data.map((item) =>
                <tr>{keys.map((ite, i) => Array.isArray(item[ite]) ? item[ite].map((it, j) => <th key={'H' + i + j}>{it}</th>) : <th key={'H' + i}>{item[ite]}</th>)}</tr>
              )
            }
          </table>;
          this.setState({ modalTableView, modalGraphView: null, modalMapView: null });
        })
      }
    }).catch(e => {
      console.log("Error", e);
    })
  }

  openModalGraph = (source, view) => {
    fetch(__SERVER__ + '/projects/' + this.props.project_id + '/informative_profile/source?table=' + source, {
      method: 'GET',
      headers: {
        accept: 'application/json'
      }
    }).then(res => {
      if (res.status === 200) {
        res.json().then(r => {
          console.log(r);
          let type = view.split(',')[1];
          let options = {};
          let modalGraphView = null;
          switch (type) {
            case 'series': options = this.getOptionsSeries(source, r);
              modalGraphView = <CanvasJSChart options={options} />;
              break;
            default: modalGraphView = null;
              break;
          }
          this.setState({ modalTableView: null, modalGraphView, modalMapView: null });
        })
      }
    }).catch(e => {
      console.log("Error", e);
    })
  }

  getOptionsSeries = (source, r) => {
    source = source.split('_').join(' ');
    let options = {
      animationEnabled: true,
      title: {
        text: source
      },
      axisY: {
        title: source,
        includeZero: false
      },
      toolTip: {
        shared: true
      },
      /*data: [
        {
          type: "spline",
          name: "2017",
          showInLegend: true,
          dataPoints: [
              { y: 172, label: "Jan" },
              { y: 173, label: "Feb" },
              { y: 175, label: "Mar" },
              { y: 172, label: "Apr" },
              { y: 162, label: "May" },
              { y: 165, label: "Jun" },
              { y: 172, label: "Jul" },
              { y: 168, label: "Aug" },
              { y: 175, label: "Sept" },
              { y: 170, label: "Oct" },
              { y: 165, label: "Nov" },
              { y: 169, label: "Dec" }
          ]
        }
      ]*/
    };
    let keys = Object.keys(r.headers);
    let headers = r.headers;
    let data = r.data;
    let dataPoints = [];
    let names = [];
    for (let i = 0; i < data.length; i++) {
      let label = "";
      for (let j = 0; j < keys.length; j++) {
        if (!Array.isArray(data[i][keys[j]])) {
          label += data[i][keys[j]] + " - "
        } else {
          if (i === 0) {
            for (let k = 0; k < headers[keys[j]].length; k++) {
              names.push(headers[keys[j]][k]);
            }
          }

          let arr = [];
          label = label.substr(0, label.length - 3);
          for (let k = 0; k < data[i][keys[j]].length; k++) {
            let value = null;
            try {
              value = parseFloat(data[i][keys[j]][k]);
            } catch (e) {
              value = null;
            }
            arr.push({ label, y: value });
          }
          dataPoints.push(arr);
        }
      }
    }

    data = [];

    for (let i = 0; i < names.length; i++) {
      let raw = [];
      for (let j = 0; j < dataPoints.length; j++) {
        raw.push(dataPoints[j][i]);
      }
      data.push({
        type: "spline",
        name: names[i],
        showInLegend: true,
        dataPoints: raw
      })
    }

    console.log(data);
    options.data = data;

    return options;
  }

  openModalMap = (source) => {

  }


  render = () => {
    return (
      <section className="bitacora" onClick={() => {
        this.state.clicks++;
        if (this.state.clicks === 2) {
          this.state.menuContextual.fill(false);
          this.state.clicks = 0;
          this.setState({ refresh: !this.state.refresh });

        }
      }}>
        {
          this.state.modalTableView !== null || this.state.modalGraphView !== null || this.state.modalMapView !== null ?
            <section className="modalView">
              <article className="modalViewContent">
                <header className="modalViewHeader">
                  <label>Resultados en la base de datos</label>
                  <button onClick={() => {
                    this.setState({
                      modalGraphView: null,
                      modalMapView: null,
                      modalTableView: null
                    })
                  }}>x</button>
                </header>
                {this.state.modalGraphView}
                {this.state.modalTableView}
                {this.state.modalMapView}
              </article>
            </section>
            : null
        }
        <article className="bread">
          {
            this.state.query_bread.map((item, i) =>
              <button className="breadOption" onClick={() => this.reopenFolder(i)}>
                {item}
                {
                  i !== 0 ?
                    "   /   "
                    : null
                }
              </button>
            )
          }
        </article>
        <section className="fileManager">
          <Tree openFolder={this.reopenFolderByName} project_id={this.props.project_id} />
          <article style={{ flex: 1, flexDirection: 'column' }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <button className="view" onClick={this.change}>Vista</button>
              {this.state.componentView}
            </div>
            <section className={this.state.bitacoraOnlineClass}>
              {
                this.state.bitacoraOnline.map((item, i) =>
                  <div>
                    <button className={this.state.classNameFolder} key={'CAB' + i}>
                      {item.type === "folder" ?
                        <svg onClick={() => this.openFolder(item.name)} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="folder" className={this.state.folderimg + " svg-inline--fa fa-folder fa-w-16"} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z"></path>
                        </svg>
                        : (item.name.split('.')[item.name.split('.').length - 1]).toUpperCase() === "PDF" ?
                          <svg onClick={() => this.openFile(item.name)} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-pdf" style={{ color: '#911C1C' }} className={this.state.folderimg + " svg-inline--fa fa-file-pdf fa-w-12"} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"></path></svg>
                          : <svg onClick={() => this.openFile(item.name)} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-alt" style={{ color: '#686858' }} className={this.state.folderimg + " svg-inline--fa fa-file-alt fa-w-12"} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path></svg>
                      }
                      <label onClick={() => {
                        if (item.type === "folder") {
                          this.openFolder(item.name)
                        } else {
                          this.openFile(item.name)
                        }
                      }}>{item.name}</label>
                      <div onClick={(e) => this.options(e, i)} className="buttonOptions">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-v" className="options-img svg-inline--fa fa-ellipsis-v fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                          <path fill="currentColor" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z">
                          </path>
                        </svg>
                      </div>
                      {this.state.menuContextual[i] ?
                        <article className="menu_derecho">
                          <ul>
                            <li><button onClick={() => {
                              this.state.oldFolderName = item.name;
                              this.state.newFolderName.name = item.name;
                              this.state.addFolder = false;
                              this.state.newFolderName.open = true;
                              this.setState({ refresh: !this.state.refresh });
                            }}>Cambiar nombre</button></li>
                            <li><button onClick={() => this.delete(item.name, i)}>Eliminar</button></li>
                          </ul>
                        </article>
                        : null
                      }
                    </button>
                  </div>

                )
              }
            </section>
          </article>
        </section>
        {/*<article className="botonesAdd">
            <button onClick={() => {
							this.state.addFolder = true;
              this.state.newFolderName.open = true;
              this.setState({refresh: !this.state.refresh});
            }}>Crear carpeta</button>
            <button onClick={() => {
							this.state.addFiles.open = true;
							this.setState({refresh: !this.state.refresh});
						}}>Añadir archivo</button>
          </article>*/}
        {
          this.state.newFolderName.open ?
            <article className="newFolderName">
              <section className="newFolderName_">
                <label>Nombre</label>
                <input type="text" value={this.state.newFolderName.name} onChange={(e) => {
                  this.state.newFolderName.name = e.target.value;
                  this.setState({ refresh: !this.state.refresh });
                }} />
                <section>
                  <button className="cancel" onClick={() => {
                    this.state.newFolderName.open = false;
                    this.state.newFolderName.name = "";
                    this.setState({ refresh: !this.state.refresh });
                  }}>Cancelar</button>
                  <button onClick={() => {
                    if (this.state.addFolder) {
                      this.createFolder()
                    } else {
                      this.updateFolder()
                    }
                  }}>Aceptar</button>
                </section>
              </section>
            </article>
            : null
        }
        {
          this.state.addFiles.open ?
            <article className="newFolderName">
              <section className="newFolderName_">
                <label>Seleccione sus archivos nuevos</label>
                <input type="hidden" name="MAX_FILE_SIZE" value="3000000000" />
                <input onChange={e => this.setState({ files: e.target.files })} className="file-upload" id="file-upload" name="file-upload" multiple accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" />
                <section>
                  <button className="cancel" onClick={() => {
                    this.state.addFiles.open = false;
                    this.state.files = null;
                    this.setState({ refresh: !this.state.refresh });
                  }}>Cancelar</button>
                  <button onClick={this.uploadFiles}>Aceptar</button>
                </section>
              </section>
            </article>
            : null
        }
      </section>
    );
  }
}
