import React, { Component } from 'react';
import ElectronicPayments from './subComponentsSettings/ElectronicPayments';
import UsersLogs from './subComponentsSettings/Logs';


class Super extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buttons_active: ['button_active', 'button_desactive'],
            tap: 0
        }
    }
    // Método para las pestañas activas
    tapActive = (tap) => {
        this.setState(prevState => {
            const buttons_active = prevState.buttons_active.map((item, index) => {
                let value = tap === index ? 'button_active' : 'button_desactive'
                return value
            })
            return { buttons_active }
        })
        this.setState({ tap });
    }

    render = () => {
        return (
            <section className="assets">
                {/**Opciones generales */}
                {this.state.tollbooth_id !== '' ? <header className="assets_tap">
                    <button className={this.state.buttons_active[0]} onClick={() => this.tapActive(0)}>Registro de usuarios</button>
                    <button className={this.state.buttons_active[1]} onClick={() => this.tapActive(1)}>Medios electrónicos de pago</button>
                </header> : null}

                {/** Registros de usuarios */}
                {
                    this.state.tap === 0 ? <UsersLogs /> : null
                }

                {/** Otras opciones */}
                {
                    this.state.tap === 1 ? <ElectronicPayments /> : null
                }
            </section>
        );
    }
}
export default Super;
