import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexRoadSections = async ({ package_id }) => {
    let response = [];

    try {
        const { fetch } = await fetcherCancelToken({
            url: `/packages/${package_id}/road_sections`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: road_sections.js:10 ~ indexRoadSections ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response };
    } catch (error) {
        return { response };
    }
}