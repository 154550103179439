import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

/**
 * 
 * @param {
 *      project_id: "Identificador del proyecto sobre el que se consultará el inventario de bienes (assets)",
 *      table: 'Tabla de bienes (assets) de la que se van a recuperar los registros'
 * } props 
 * @returns {
 *      data: 'Arreglo de los registros que se obtuvieron al realizar la petición',
 *      totalItems: 'Cantidad de registros devueltos (paginación)'
 * }
 */
export const indexAssets = async ({ project_id, table, query }) => {
    const url = `/projects/${project_id}/${table}`;
    let response = { data: [], totalItems: 0 };
    let params = {};
    if (query) {
        const queryValues = Object.values(query);
        const queryKeys = Object.keys(query);
        for (let index = 0; index < queryValues.length; index++) {
            const element = queryValues[index];
            if (element !== '-1')
                params[queryKeys[index]] = element;
        }
    }
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === httpStatus.ok) {
            const { data, totalItems } = fetch.data;
            response = { data, totalItems };
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const indexAssetsMonitoring = async ({ project_id, table, query }) => {
    const url = `/projects/${project_id}/${table}/components/monitoring`;
    let response = { data: [], totalItems: 0, cancel: () => { } };
    let params = {};

    if (query) {
        Object.entries(query).forEach(([key, value]) => {
            if (value !== '-1') {
                params[key] = value;
            }
        });
    }

    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: assets.js:64 ~ indexAssetsMonitoring ~ fetch:", fetch)

        if (fetch.status === httpStatus.ok) {
            const { data, totalItems } = fetch.data;
            response = { response: { data, totalItems }, cancel };
        }

        return response;
    } catch (error) {
        return response;
    }
}


export const valuesAssets = async ({ project_id, table, column }) => {
    const url = `/projects/${project_id}/${table}/values`;
    let response = [];
    const params = { column };

    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const showAssets = async ({ project_id, table, id }) => {
    const url = `/projects/${project_id}/${table}/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        if (fetch.status === httpStatus.ok)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}

/**
 * 
 * @param {
 *      project_id: 'Identificador del proyecto en que se agregará el nuevo registro', 
 *      data: 'La estructura de este objeto depende de la tabla sobre la que se hará la inserción',
 *      table: 'Tabla de bienes (assets) en la que se insertará el registro nuevo'
 * } param0 
 * @returns 'FALSE si no se realiza la inserción, de lo contrario retorna el identificador del registro que se agregó'
 */
export const storeAssets = async ({ project_id, data, table }) => {
    const url = `/projects/${project_id}/${table}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

/**
 * 
 * @param {
 *      project_id: 'Identificador del proyecto en que se actualizará el registro', 
 *      id: 'Identificador de la tupla que se va a actualizar'
 *      data: 'La estructura de este objeto depende de la tabla sobre la que se hará la inserción',
 *      table: 'Tabla de bienes (assets) en la que se actualizará el registro'
 * } props 
 * @returns TRUE | FALSE
 */
export const updateAssets = async ({ project_id, id, data, table }) => {
    const url = `/projects/${project_id}/${table}/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: assets.js:125 ~ updateAssets ~ fetch", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

/**
 * 
 * @param {
 *      project_id: 'Identificador del proyecto en que se eliminará el registro', 
 *      id: 'Identificador de la tupla que se va a eliminar'
 *      table: 'Tabla de bienes (assets) en la que se eliminará el registro'
 * } props 
 * @returns TRUE | FALSE
 */
export const deleteAssets = async ({ project_id, id, table }) => {
    const url = `/projects/${project_id}/${table}/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'DELETE'
        });
        if (fetch.status === httpStatus.ok)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteFileAssets = async ({ project_id, asset_id, id, table }) => {
    const url = `/projects/${project_id}/${table}_files/${asset_id}/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: assets.js:200 ~ deleteFileAssets ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const uploadAssets = async ({ project_id, id, table, data }) => {
    const url = `/projects/${project_id}/${table}_files/${id}/upload`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            contentType: 'application/x-www-form-urlencoded',
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: assets.js:169 ~ uploadAssets ~ fetch", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const downloadAssets = async ({ project_id, table, id, path }) => {
    const url = `/projects/${project_id}/${table}_files/${id}/download`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: { path }
        });
        if (fetch.status === httpStatus.ok)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return response;
    }
}