import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";

const CREATED = 201;
const STATUS_OK = 200;

export const indexMaintenanceIncidents = async (props) => {
    const url = `/maintenance_standard/inspections/incidents`;
    let response = { data: [], totalItems: 0, cancel: () => { } }
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url, method: 'GET',
            params: { ...props }
        });
        if (fetch.status === STATUS_OK)
            response = { ...fetch.data, cancel }
        return response;
    } catch (error) {
        return response;
    }
}

export const showMaintenanceIncident = async (id) => {
    const url = `/maintenance_standard/inspections/incidents/${id}`;
    let response = { data: {}, cancel: () => { } };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url, method: 'GET'
        });
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data, cancel }
        return response;
    } catch (error) {
        return response;
    }
}
export const indexIncidents = async (props) => {
    const {
        project_id,             // Identificador de la autopista
        mode,                   // Modo| basic: Datos principales, all: Toda la información incluyendo multimedia
    } = props;
    // Obtener información básica
    let props_params = props;
    delete props_params.project_id;

    let params = { mode: mode || 'basic' };
    params = { ...params, ...props_params };

    const url = `/projects/${project_id}/inspections/incidents`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: incidents.js ~ line 27 ~ indexIncidents ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            const { data, totalItems } = fetch.data;
            response = { data, totalItems };
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeIncidents = async ({ project_id, inspection_id, data }) => {
    const url = `/projects/${project_id}/inspections/${inspection_id}/incidents`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: incidents.js:46 ~ storeIncidents ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const updateIncidents = async ({ project_id, inspection_id, data, id }) => {
    const url = `/projects/${project_id}/inspections/${inspection_id}/incidents/${id}/update`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: incidents.js:64 ~ updateIncidents ~ fetch:", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}