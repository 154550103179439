import React from 'react';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table';

const EventList = ({ data, setData }) => {

    const changePage = (page, pageSize) => {
        setData(prevData => ({ ...prevData, page, pageSize }));
    }

    return (
        <>
            <TableComp
                headers={['ID', 'Evento', 'Elemento', 'Fecha del evento', 'Acciones']}
                keys={['id', 'type', 'active_user', 'created_at']}
                body={data.data}
                loading={data.loading}
                loadingItems={data.pageSize}
                page={data.page}
                moment_dates={[{ index: 3, format: 'LLLL' }]}
                actions={[
                    { icon: faInfo, variant: 'outline-primary', handleClick: () => { }, tooltip: 'Ver detalles', disabled: true }
                ]}
            />
            <PaginationComp
                current={data.page}
                totalItems={data.totalItems}
                defaultPageSize={data.pageSize}
                onChange={changePage}
            />
        </>
    )
}

export default EventList