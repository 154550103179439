import XLSX from 'xlsx';
/**
 * 
 * @param {Array} data Arreglo de datos a exportar
 * @param {String} file_name Nombre del archivo a descargar
 * @param {Array} widthCells Ancho de las celdas [{width: 20}]
 */
export const downloadXLSX = ({ data, file_name, widthCells }) => {
	const exportToExcel = () => {

		// Crear una hoja de cálculo
		const worksheet = XLSX.utils.json_to_sheet(data);

		// Establecer el estilo "auto" para ajustar el ancho de las celdas al contenido
		const cellStyle = {
			alignment: { wrapText: true, horizontal: 'center' },
			font: { name: 'Arial', size: 12 }
		};
		// Configurar el estilo para todas las celdas
		worksheet['!cols'] = widthCells;
		worksheet['!rows'] = [{ height: 25 }];
		for (let cell in worksheet) {
			if (cell[0] === '!') continue;
			worksheet[cell].s = cellStyle;
		}
		// Crear los datos que deseas exportar
		const workbook = XLSX.utils.book_new();
		// Añadir la hoja al libro
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

		// Exportar el archivo
		XLSX.writeFile(workbook, `${file_name}_${new Date().getTime()}.xlsx`);
	};

	exportToExcel();
}

export const downloadXLSXByArray = ({ fileName, data, widthCells }) => {
	const worksheet = XLSX.utils.aoa_to_sheet(data);
	const workbook = XLSX.utils.book_new();

	// Establecer el estilo "auto" para ajustar el ancho de las celdas al contenido
	const cellStyle = {
		alignment: { wrapText: true, horizontal: 'center' },
		font: { name: 'Arial', size: 12, bold: true }
	};
	// Configurar el estilo para todas las celdas
	worksheet['!cols'] = widthCells;
	worksheet['!rows'] = [{ height: 25 }];
	for (let cell in worksheet) {
		if (cell[0] === '!') continue;
		worksheet[cell].s = cellStyle;
	}

	XLSX.utils.book_append_sheet(workbook, worksheet, 'Plantilla');

	// Exportar el archivo
	XLSX.writeFile(workbook, `${fileName}_${new Date().getTime()}.xlsx`);
}