import React from 'react';
import { Dropdown } from 'react-bootstrap';
import XLSX from '../../Files/XLSX';
import PDF from '../../Files/PDF';
import WorkOrderTable from './WorkOrderTable';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { dateToString2 } from 'utilidades/dates';

const WorkOrderReports = (props) => {
    const {
        report,
        fileType, 
        typeFile
    } = props;
    return (
        <section>
            <div className="right-button">
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        Descargar
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <XLSX report={report} extension={'xlsx'} label={'EXCEL'} />
                        <XLSX report={report} extension={'csv'} label={'CSV'} />
                        <PDFDownloadLink document={<PDF report={report} />} fileName={`Orden_de_Trabajo_${report.headers.creation_date.split('T')[0].split('-').join('')}.pdf`} className={'PDFDownloadLink'}>
                            {({ url, loading, error }) => (loading ? 'PDF...' : 'PDF')}
                        </PDFDownloadLink>

                    </Dropdown.Menu>
                </Dropdown>

            </div>
            {
                fileType === typeFile.PDF && <PDF report={report} />
            }
            {
                fileType === typeFile.HTML ?
                    <article className="workOrderHeader">
                        <label><strong>Orden de Trabajo: </strong>{report.headers.code}</label><br />
                        <label><strong>Autorización: </strong>{report.headers.authorization}</label><br />
                        {report.headers.description !== null ? <label><strong>Descripción: </strong>{report.headers.description}</label> : null}
                        {report.headers.description !== null ? <br /> : null}
                        <label><strong>Fecha: </strong>{dateToString2(report.headers.creation_date.split('T')[0])}</label><br />
                    </article>
                    : null
            }
            {
                <WorkOrderTable
                    report_supplies={report.supplies}
                />
            }
        </section>
    )
}

export default WorkOrderReports