import React, { useState } from 'react'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import { Col, Form } from 'react-bootstrap';
import FormIcon from 'components/layouts/FormIcon';

const EditMaintenanceProgram = (props) => {
    const {
        onClose,
        users,
        activities,
        program,
        formatDate
    } = props;

    const [form, setForm] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    }

    return (
        <ModalComp
            onClose={onClose}
            title='Editar Programa de Mantenimiento'
            size='lg'
            body={<>
                <center>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Responsable</Form.Label>
                                <select className="form-control" defaultValue={program?.asset_activity?.responsable_id} disabled={true}>
                                    {
                                        users.map((item, i) =>
                                            <option key={i} value={item.id}>{item.first_name} {item.last_name}</option>
                                        )
                                    }
                                </select>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Actividad</Form.Label>
                                <select className="form-control" defaultValue={program?.asset_activity?.maintenance_activity_id} disabled={true}>
                                    {
                                        activities.map((item, i) =>
                                            <option key={i} value={item.id}>{item.description}</option>
                                        )
                                    }
                                </select>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <FormIcon title='Fecha inicial' type='date' name='start_date' value={formatDate(form?.start_date)}
                                handleChange={handleChange} />
                            <FormIcon title='Fecha final' type='date' name='end_date' value={formatDate(form?.end_date)}
                                handleChange={handleChange} />
                        </Form.Row>

                        <Form.Row>
                            <FormIcon title='Hora inicial' type='time' name='start_time' value={formatDate(form?.start_time)}
                                handleChange={handleChange} />
                            <FormIcon title='Hora final' type='time' name='end_time' value={formatDate(form?.end_time)}
                                handleChange={handleChange} />
                        </Form.Row>
                    </Form>
                </center>
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' onClick={onClose} variant='outline-secondary' icon={faTimes} />
                <ButtonIcon name='Actualizar' onClick={onClose} variant='outline-success' icon={faSave} />
            </>}
        />
    )
}

export default EditMaintenanceProgram