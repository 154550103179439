import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateAssetsTypes } from 'api/operation_standards/assets_types';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import ModalComp from 'components/layouts/Modal'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

const UpdateAssetType = (props) => {
    const { onClose, package_id, table, update, assetTypeSel } = props;
    const [data, setData] = useState({});
    const [hasChanges, setHasChanges] = useState(true);

    useEffect(() => {
        setData({ name: assetTypeSel.name });
    }, []);

    useEffect(() => {
        if (assetTypeSel.name !== data.name) setHasChanges(false)
        else setHasChanges(true)
    }, [data]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    const save = async () => {
        let response = await updateAssetsTypes({ package_id, table, data, id: assetTypeSel.id });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'El tipo ha sido actualizado de manera satisfactoria.', 'success');
            update();
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de actualizar el tipo. Intente más tarde.', 'error');
        }
    }

    return (
        <ModalComp
            title='Actualizar'
            size='md'
            onClose={onClose}
            body={<>
                <FormIcon title='Nombre' name='name' value={data?.name} handleChange={handleChange} />
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name='Actualizar' icon={faSave} variant='outline-success' onClick={save} disabled={hasChanges} />
            </>}
        />
    )
}

export default UpdateAssetType