import React from 'react';
import Main from './Main';
import { useSelector } from 'react-redux';

const OBAC2 = (props) => {
	const { project_id, code, name, permission } = props;
	
	// Estructura de los atributos de la tabla
	const items = useSelector(state => state.assets.its);

	return (
		<Main
			project_id={project_id}		// Identificador de la autopista
			code={code}					// Código de la sección, ej. UOV-5.2
			name={name}					// Nombre de la sección, ej. ITS
			table='assets_its'			// Nombre de la tabla de los activos
			badges={[{ index: 1 }, { index: 8 }, { index: 9 }]}		// Posiciones de la tabla que tendrán badges
			items={items}
			user_code='Gerente de Sistemas'
			km={[{ index: 3 }]}
			permission={permission}
		/>
	)
}

export default OBAC2