import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer';

class PDFReport extends React.Component {
    constructor(props) {
        super(props)
    }

    render = () => {
        return (
            <Document>
                <Page>
                    <View>
                        <View >
                            <Text>Calificación de los cuestionarios</Text>
                        </View>
                    </View>
                </Page>
            </Document >
        )
    }
}

export default PDFReport