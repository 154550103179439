import Axios from 'axios';
import React, { Component } from 'react';
import { __SERVER__ } from 'server/info';
import { Variables } from 'variables/Variables';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Card, Button, Container, Modal, Form, Image, ProgressBar } from 'react-bootstrap';
import { FaCheck, FaReply, FaTimes, FaDownload } from 'react-icons/fa';
import { Heading } from '@chakra-ui/react';
const moment = require('moment');

const MySwal = withReactContent(Swal);
const _ = require('underscore');

const FiltroBusqueda = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dates: {
                start_date: '',
                end_date: ''
            }
        }
    }

    componentDidMount = () => {

    }

    generateQuery = () => {
        if (this.state.dates.start_date === "" || this.state.dates.end_date === "") {
            MySwal.fire(<p>¡Inserte el rango de fechas de consulta!</p>)
        }
    }

    handleChange = (event) => {
        this.setState({
            dates: {
                ...this.state.dates,
                [event.target.name]: event.target.value
            }
        });
    }

    render = () => {
        return (
            <>
                <section className="sectionSearch">
                    <article>
                        <label>Fecha</label>
                        <input type='date' name="start_date" value={this.state.dates.start_date} onChange={this.handleChange} />
                    </article>
                    <article>
                        <label>Fecha</label>
                        <input type='date' name="end_date" value={this.state.dates.end_date} onChange={this.handleChange} />
                    </article>
                    <article>
                        <button className="botonBusqueda" onClick={this.generateQuery}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                        </button>
                    </article>
                </section>
            </>
        );
    }
}

const ModalCard = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            // Initially, no file is selected
            payment_id: 0
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    // On file select (from the pop up)
    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
    }

    // On file upload (click the upload button)
    onFileUpload = async (e) => {
        e.preventDefault();
        if (this.state.selectedFile === null) {
            Swal.fire('Error', '¡No hay un archivo seleccionado!');
        } else {
            // crear un objeto de formData
            const formData = new FormData();
            // Actualizar el objeto formData
            formData.append(
                "file",
                this.state.selectedFile
            );
            // Detalles del archivo cargado
            console.log(this.state.selectedFile);
            // Petición al backend, enviar el objeto formData
            await Axios.post(`${__SERVER__}/projects/${Variables.project_id}/right_of_way_payments/${this.props.payment_id}/upload`, formData);
            this.handleClose();
        }

    }

    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <h2>Detalles del archivo:</h2>
                    <p><strong>Nombre:</strong> {this.state.selectedFile.name}</p>
                    <p><strong>Tipo:</strong> {this.state.selectedFile.type}</p>
                    <p><strong>
                        Última modificación:</strong>{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h5>Elija su archivo antes de cargarlo</h5>
                </div>
            );
        }
    };

    render = () => {
        return (
            <Modal //Propiedades del modal
                animation={true}
                show={this.state.show}
                onHide={this.handleClose}
                size="md"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Form className={"form-inline"}>
                            <h3>Atendiendo solicitud!</h3>
                            <div>
                                <input type="file" className={"form-control"} onChange={this.onFileChange} />
                                <Button variant={'outline-success'} onClick={this.onFileUpload}>Subir!</Button>
                            </div>
                        </Form>
                        {this.fileData()}
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }
}

const DetallePago = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            fileURL: null,
            titleDownload: 'Comprobante de pago',
            isImage: false, //Indica si el comprobante es una imagen
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
        this.getFile(this.props.payment_id);
        let titleDownload = `${this.props.payments[_.findIndex(this.props.payments, { id: this.props.payment_id })].bank_name}`;
        titleDownload += `-${this.props.payments[_.findIndex(this.props.payments, { id: this.props.payment_id })].owners_name}`;
        titleDownload += `-${moment().format()}`;
        this.setState({ titleDownload });
    }

    getFile = async (payment_id) => {
        console.log("getting file...", payment_id);
        let response = await Axios.get(`${__SERVER__}/projects/${Variables.project_id}/right_of_way_payments/${payment_id}/download`, {
            method: "GET",
            responseType: "blob"
        });
        console.log("responsedata type:: ", response.data.type)
        const file = new Blob([response.data], {
            type: response.data.type
        });

        let fileURL = URL.createObjectURL(file);
        console.log("FILE: ", file, "DATA: ", response.data, "file url: ", fileURL)
        // console.log("payment", this.state.payments[_.findIndex(this.state.payments, {id: payment_id})])
        this.setState({ fileURL: fileURL, isImage: response.data.type.includes('image') });
    }
    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    downloadFile = () => {
        // window.open(this.state.fileURL)
        console.log("props: ", this.props)
        const link = document.createElement('a')
        link.href = this.state.fileURL
        link.setAttribute('download', this.state.titleDownload)
        document.body.appendChild(link)
        link.click();
    }

    approvePayment = async () => {
        try {
            await Axios.put(`${__SERVER__}/projects/${Variables.project_id}/right_of_way_payments/${this.props.payment_id}`, { status: 1 });
            Swal.fire('Aprobación exitosa', 'El pago se ha aprovado satisfactoriamente', 'success');
            this.props.getPayments();
        } catch (error) {
            console.error(error);
        }
        this.handleClose();
    }

    render = () => {
        console.log("includes: ", this.state.isImage);
        return (
            <Modal //Propiedades del modal
                animation={true}
                show={this.state.show}
                onHide={this.handleClose}
                size="lg"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <center>
                        {
                            this.state.isImage ?
                                <Image src={this.state.fileURL} fluid />
                                : <h2 style={{ color: "#000" }}>No hay vista previa</h2>
                        }

                    </center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={this.handleClose}><FaTimes />Cerrar</Button>
                    <Button variant="outline-primary" onClick={this.downloadFile}><FaDownload />Descargar comprobante</Button>
                    {
                        this.props.payments[_.findIndex(this.props.payments, { id: this.props.payment_id })].status === 0 ?
                            <Button variant="outline-success" onClick={this.approvePayment}><FaCheck />Aprobar pago</Button> : null
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contracts: [],  //Se almacenan los contratos}
            payments: [],   //Se almacenan los pagos que los usuarios de autopista han hecho
            victum_users: [], //Usuarios de autopista
            tap: 1,         //Índice de la pestaña
            buttons_active: ['button_desactive', 'button_active', 'button_desactive'], //Pestañas activas
            modal: false,   //Modal 
            payment_id: 0
        }
    }

    componentDidMount = async () => {
        await this.getVictumUsers();
        await this.getContracts();
        await this.getPayments();
        await this.getInformationAboutPayments();
    }

    getContracts = async () => {
        let contracts = await Axios.get(`${__SERVER__}/projects/${Variables.project_id}/contracts`);
        this.setState({ contracts: contracts.data });
    }

    getPayments = async () => {
        let payments = await Axios.get(`${__SERVER__}/projects/${this.props.project_id}/right_of_way_payments`);
        this.setState({ payments: payments.data });
    }

    getVictumUsers = async () => {
        let victum_users = await Axios.get(`${__SERVER__}/victum_users`);
        this.setState({ victum_users: victum_users.data });
    }

    getInformationAboutPayments = () => {
        let { contracts, payments } = this.state;

        contracts.map((contract, i) => {
            let real_amount = 0;
            payments.filter(item => item.contract_id === contract.id ).filter(item => item.status === 1).map(item => { real_amount += item.amount });
            let diff_days = moment().diff(moment(contract.assigned_date), 'days');
            let quantity_payments = Math.trunc(diff_days / contract.periodicity);
            this.setState(prevState => {
                const contracts = prevState.contracts.map((contract, index) => {
                    if (i === index) {
                        contract.total_amount = contract.amount * quantity_payments;
                        contract.real_amount = real_amount;
                        contract.quantity_payments = quantity_payments;
                    }
                    return contract;
                });
                return { contracts };
            });
        });
    }

    downloadFile = async (contract_id) => {
        let response = await Axios.get(`${__SERVER__}/projects/${this.props.project_id}/contracts/${contract_id}/download`, {
            method: "GET",
            responseType: "blob"
        });
        // console.log("file: ", response.data)
        const pdf = new Blob([response.data], {
            type: response.data.type
        });
        // console.log("blob: ", pdf)
        const fileURL = URL.createObjectURL(pdf);
        // window.open(fileURL);
        const link = document.createElement('a')
        link.href = fileURL
        let title = `${this.state.contracts[_.findIndex(this.state.contracts, { id: contract_id })].name}-${moment().format()}`;
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click();
    }

    tapActive = (tap) => {
        this.setState(prevState => {
            const buttons_active = prevState.buttons_active.map((item, index) => {
                let value = tap === index ? 'button_active' : 'button_desactive'
                return value
            })
            return { buttons_active }
        });
        this.setState({ tap });
    }

    openOrCloseModal = (payment_id) => {
        this.setState({ payment_id, modalCard: !this.state.modalCard });
    }

    getVictumUsersName = (victum_user_id) => {

        let name = `${this.state.victum_users[_.findIndex(this.state.victum_users, { id: victum_user_id })].first_name}`;
        name += ` ${this.state.victum_users[_.findIndex(this.state.victum_users, { id: victum_user_id })].last_name}`;

        return name;
    }

    render = () => {
        let { victum_users } = this.state;
        return (
            <>
                <Heading className='tituloSub'>{this.props.code}-{this.props.name}</Heading>
                <FiltroBusqueda />
                <header className="assets_tap">
                    <button className={this.state.buttons_active[1]} onClick={() => this.tapActive(1)}>Cobro de ingresos</button>
                    <button className={this.state.buttons_active[0]} onClick={() => this.tapActive(0)}>Contratos aprobados</button>
                    <button className={this.state.buttons_active[2]} onClick={() => this.tapActive(2)}>Estado de cuenta</button>
                </header>

                {/* Sección de Contratos */}
                {
                    this.state.tap === 0 ?
                        <Container fluid className={"cards"}>
                            {
                                this.state.contracts.map((item, i) =>
                                    <Card key={`card-${i}`}>
                                        <Card.Body>
                                            <Card.Title><strong>{item.name}</strong></Card.Title>
                                            <Card.Text>Descripción: {item.description}</Card.Text>
                                            <Card.Text>Usuario: {this.getVictumUsersName(item.victum_user_id)}</Card.Text>
                                            <Card.Text>Fecha de asignación: {item.assigned_date}</Card.Text>
                                            <Card.Text>Contrato: {item.file_name}</Card.Text>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant="outline-primary" onClick={() => this.downloadFile(item.id)}>Descargar contrato</Button>
                                        </Card.Footer>
                                    </Card>
                                )
                            }
                        </Container> : null
                }

                {/* Sección de pagos */}
                {
                    this.state.tap === 1 ?
                        <table className="tableAssets">
                            <thead>
                                <tr>
                                    <th>Folio</th>
                                    <th>Clave de rastreo</th>
                                    <th>Contrato</th>
                                    <th>Banco</th>
                                    <th>Propietario</th>
                                    <th>Monto</th>
                                    <th>Concepto</th>
                                    <th>Estado</th>
                                    <th>Ver comprobante</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.payments.map((payment, index) =>
                                        <tr key={`tr-${index}`}>
                                            <td>{payment.folio}</td>
                                            <td>{payment.tracking_key}</td>
                                            <td>{this.state.contracts[_.findIndex(this.state.contracts, { id: payment.contract_id })].name}</td>
                                            <td>{payment.bank_name}</td>
                                            <td>{payment.owners_name}</td>
                                            <td>{payment.amount}</td>
                                            <td>{payment.concept}</td>
                                            <td>
                                                {
                                                    payment.status === 1 ?
                                                        <ProgressBar striped variant="success" now={100} /> :
                                                        <ProgressBar striped variant="danger" animated now={60} />
                                                }
                                            </td>
                                            <td>
                                                <Button variant="outline-secondary" onClick={(e) => this.openOrCloseModal(payment.id)}><FaReply /></Button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> : null
                }

                {/* Estado de cuenta */}

                {
                    this.state.tap === 2 ?
                        <table className="tableAssets">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre de usuario</th>
                                    <th>Contrato</th>
                                    <th>Cantidad pagada</th>
                                    <th>Cantidad planeada</th>
                                    <th>Adeudo</th>
                                    <th>Inicio de contrato</th>
                                    <th>Finalización de contrato</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.contracts.map((item, i) =>
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{victum_users[_.findIndex(victum_users, { id: item.victum_user_id })].first_name} {victum_users[_.findIndex(victum_users, { id: item.victum_user_id })].last_name}</td>
                                            <td>{item.name}</td>
                                            <td>${item.real_amount}</td>
                                            <td>${item.total_amount}</td>
                                            <td>${item.total_amount>item.real_amount ? item.total_amount-item.real_amount: 0}</td>
                                            <td>{moment(item.assigned_date).format('ll')}</td>
                                            <td>{moment(item.completed_date).format('ll')}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> : null
                }

                {
                    this.state.modalCard ?
                        <DetallePago
                            title={`Comprobante de pago`}
                            onClose={this.openOrCloseModal}
                            payment_id={this.state.payment_id}
                            payments={this.state.payments}
                            getPayments={this.getPayments}
                        /> : null
                }

            </>
        );
    }
}