import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { styles } from './Modal';
import { Modal, ButtonToolbar, ButtonGroup, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
const _ = require('underscore');
const xlsx = require('xlsx');

class ModalUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            headersInput: new Array(this.props.items.length).fill(""),
            excel_name: '',
            excel: null,
            sheets: [],
            loading: false,
            headers: [],
            excel_rows: [],
            data_valid: true,
            reading: false,
            refresh: false
        }
    }

    componentDidMount = () => {
        for(let i = 0; i < this.props.items.length; i++){
            this.state.headersInput[i] = this.props.items[i].label.toUpperCase();
        }
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    readExcel = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        this.setState({ excel_name: e.target.files[0].name, excel_rows: [] });
        if (this.props.readExcel === undefined) {
            let type = e.target.files[0].name.split('.');
            type = type[type.length - 1];
            this.state.excel = e.target.files[0];
            if (type === 'xlsx' || type === 'xls') {
                let reader = new FileReader();
                reader.onload = (evt) => {
                    let data = evt.target.result;
                    try {
                        let sheets = xlsx.read(data, { type: 'binary', sheetRows: true });
                        console.log(sheets);
                        this.setState({ sheets: sheets.SheetNames });
                    } catch (e) {
                        console.log(e);
                        Swal.fire('Error', e, 'error');
                        this.setState({ sheets: [], excel: null });
                    }
                }
                reader.readAsBinaryString(this.state.excel);
            } else {
                this.setState({ sheets: [], excel: null });
                Swal.fire('Advertencia', 'El archivo no es de extensión XLSX o XLS', 'warning');
            }
        } else {
            let excel_rows = this.props.readExcel();
            this.setState({ excel_rows });
        }
    }

    getIndexs = (ref) => {
        let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        ref = ref.split('');
        let columna_inicial = "";
        let columna_final = "";
        let fila_inicial = "";
        let fila_final = "";
        let dos_puntos = false;
        for (const element of ref) {
            if (element === ":") {
                dos_puntos = true;
            } else {
                if (!dos_puntos) {
                    if (numbers.indexOf(element) === -1) {
                        columna_inicial += element;
                    } else {
                        fila_inicial += element;
                    }
                } else {
                    if (numbers.indexOf(element) === -1) {
                        columna_final += element;
                    } else {
                        fila_final += element;
                    }
                }
            }
        }
        return { c_s: columna_inicial, c_e: columna_final, r_s: fila_inicial, r_e: fila_final };
    }

    generateNextLetter = (letter) => {
        letter = ((parseInt(letter, 36) + 1).toString(36)).replace(/0/g, 'a');
        return letter.replace('1', 'A').toUpperCase();
    }

    toFormatDate = (value) => {
        const expresion = /^[0-9]{4}-(0[1-9]|1[0-2])-([0][1-9]|[1-2][0-9]|3[0-1])T([0-1][0-9]|[2][0-3]):[0-5][0-9]:[0-5][0-9]$/;
        if (typeof value === 'number') {
            try {
                value = new Date(new Date((value - 25569) * 86400 * 1000).getTime()).toISOString().split('.')[0];
                if (!expresion.test(value)) {
                    this.state.data_valid = false;
                    return '#¡ERROR!#';
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'object') {
            try {
                let milisegundos = value.getTime();
                value = new Date(milisegundos - 1000 * 60 * 60 * 12).toISOString().split('.')[0];
                if (!expresion.test(value)) {
                    this.state.data_valid = false;
                    return '#¡ERROR!#';
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'string') {
            if (value.length >= 10) {
                if (value.length === 10) {
                    let separador = value.indexOf('/') !== -1 ? '/' : '-';
                    value = value.split(separador);
                    try {
                        if (value[0].length === 4) {
                            value = `${value.join('-')}T00:00:00`;
                        } else {
                            separador = value[0];
                            value[0] = value[2];
                            value[2] = separador;
                            value = `${value.join('-')}T00:00:00`;
                        }
                        if (!expresion.test(value)) {
                            this.state.data_valid = false;
                            return '#¡ERROR!#';
                        }
                    } catch (e) {
                        this.state.data_valid = false;
                        return '#¡ERROR!#';
                    }
                } else {
                    try {
                        let separador = value.indexOf('T') !== -1 ? 'T' : ' ';
                        value = value.split(separador);
                        separador = value[0].indexOf('/') !== -1 ? '/' : '-';
                        value[0] = value[0].split(separador);
                        try {
                            if (value[0][0].length === 4) {
                                value[0] = value[0].join('-');
                            } else {
                                separador = value[0][0];
                                value[0][0] = value[0][2];
                                value[0][2] = separador;
                                value[0] = value[0].join('-');
                            }
                        } catch (e) {
                            this.state.data_valid = false;
                            return '#¡ERROR!#';
                        }
                        if (value[1].indexOf('.') !== -1) {
                            value[1] = value[1].split('.')[0];
                        }
                        value = value.join('T');
                        if (!expresion.test(value)) {
                            this.state.data_valid = false;
                            return '#¡ERROR!#';
                        }
                    } catch (e) {
                        this.state.data_valid = false;
                        return '#¡ERROR!#';
                    }
                }
            } else {
                try {
                    let separador = value.indexOf('/') !== -1 ? '/' : '-';
                    value = value.split(separador);
                    if (value[2].length === 4) {
                        separador = value[2];
                        value[2] = value[0];
                        value[0] = separador;
                    }
                    value = value.join('-');

                    let express = /^[0-9]{4}-[1-9]-[1-9]$/;
                    if (express.test(value)) {
                        value = value.split('-');
                        value[1] = `0${value[1]}`;
                        value[2] = `0${value[2]}`;
                        return `${value.join('-')}T00:00:00`;
                    }

                    express = /^[0-9]{4}-[1-9]-([0][1-9]|[1-2][0-9]|3[0-1])$/;
                    if (express.test(value)) {
                        value = value.split('-');
                        value[1] = `0${value[1]}`;
                        return `${value.join('-')}T00:00:00`;
                    }

                    express = /^[0-9]{4}-(0[1-9]|1[0-2])-[1-9]$/;
                    if (express.test(value)) {
                        value = value.split('-');
                        value[2] = `0${value[2]}`;
                        return `${value.join('-')}T00:00:00`;
                    }

                } catch (e) {
                    this.state.data_valid = false;
                    return '#¡ERROR!#';
                }
            }
        } else {
            value = null;
        }
        return value;
    }

    toFormatTime = (value) => {
        const expresion = /^([0-1][0-9]|[2][0-3]):[0-5][0-9]:[0-5][0-9]$/;
        const expresion2 = /^([0-1][0-9]|[2][0-3]):[0-5][0-9]$/;
        if (typeof value === 'number') {
            try {
                value = new Date(new Date((value - 25569) * 86400 * 1000).getTime()).toISOString().split('.')[0].split('T')[1];
                if (!expresion.test(value)) {
                    if (expresion2.test(value)) {
                        value += ':00'
                    } else {
                        this.state.data_valid = false;
                        value = '#¡ERROR!#';
                    }
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'object') {
            try {
                let milisegundos = value.getTime();
                value = new Date(milisegundos - 1000 * 60 * 60 * 12).toISOString().split('.')[0].split('T')[1];
                if (!expresion.test(value)) {
                    if (expresion2.test(value)) {
                        value += ':00'
                    } else {
                        this.state.data_valid = false;
                        value = '#¡ERROR!#';
                    }
                }
            } catch (e) {
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        } else if (typeof value === 'string') {
            if (!expresion.test(value)) {
                if (expresion2.test(value)) {
                    value += ':00'
                } else {
                    this.state.data_valid = false;
                    value = '#¡ERROR!#';
                }
            }
        } else {
            value = null;
        }
        return value;
    }

    validateNumber = (value) => {
        if (typeof value === 'number') {
            return value;
        } else {
            try {
                value = eval(value);
                if (value !== undefined && value !== null) {
                    return value;
                }
            } catch (e) {

            }
        }
        this.state.data_valid = false;
        return '#¡ERROR!#';
    }

    validateString = (value) => {
        if(typeof value === 'string'){
            return value;
        }
        try{
            return value.toString();
        }catch(e){}
        this.state.data_valid = false;
        return '#¡ERROR!#';
    }

    validateBoolean = (value) => {
        if(typeof value === 'boolean'){
            return value;
        }else{
            try{
                if(parseInt(value, 10) === 0){
                    return false;
                }else{
                    return true;
                }
            }catch(e){
                this.state.data_valid = false;
                return '#¡ERROR!#';
            }
        }
    }

    getInformationSheet = (index) => {
        this.setState({ loading: true, excel_rows: [], data_valid: true});
        let reader = new FileReader();
        reader.onload = async (evt) => {
            let data = evt.target.result;
            try {
                let info = await xlsx.read(data, { type: 'binary', sheets: index });
                let sheet = info.Sheets[this.state.sheets[index]];
                let indexs = this.getIndexs(sheet["!ref"]);
                let headers = [];
                let body = [];
                let index_body = 0;
                let valid = true;
                //Filas: Arriba hacia abajo
                for (let i = indexs.r_s; i <= indexs.r_e && valid; i++) {
                    //Columnas: De izquierda a Derecha
                    if (i === indexs.r_s) {
                        for (let l = indexs.c_s; parseInt(l, 36) <= parseInt(indexs.c_e, 36); l = this.generateNextLetter(l)) {
                            headers.push({ v: sheet[l + i].v.trim().toUpperCase(), l });
                            if (parseInt(this.generateNextLetter(l), 36) === parseInt(index.c_e, 36)) {
                                for (let x = 0; x < this.state.headersInput.length; x++) {
                                    let pos = _.findIndex(headers, { v: this.state.headersInput[x] });
                                    if (!(pos !== -1)) {
                                        valid = false;
                                    }
                                }
                            }
                        }
                    } else {
                        body[index_body] = {};
                        for (let j = 0; j < this.state.headersInput.length; j++) {
                            let item = headers[_.findIndex(headers, { v: this.state.headersInput[j] })];
                            if (sheet[item.l + i] !== undefined) {
                                body[index_body][this.props.items[j].value] = sheet[item.l + i].v;
                                if (this.props.items[j].toFormat !== undefined) {
                                    if (this.props.items[j].toFormat === 'Date') {
                                        body[index_body][this.props.items[j].value] = this.toFormatDate(body[index_body][this.props.items[j].value]);
                                    }
                                    if (this.props.items[j].toFormat === 'Time') {
                                        body[index_body][this.props.items[j].value] = this.toFormatTime(body[index_body][this.props.items[j].value]);
                                    }
                                }
                                if(this.props.items[j].type === "String"){
                                    body[index_body][this.props.items[j].value] = this.validateString(body[index_body][this.props.items[j].value]);
                                }
                                if(this.props.items[j].type === "Number"){
                                    body[index_body][this.props.items[j].value] = this.validateNumber(body[index_body][this.props.items[j].value]);
                                }
                                if(this.props.items[j].type === "Boolean"){
                                    body[index_body][this.props.items[j].value] = this.validateBoolean(body[index_body][this.props.items[j].value]);
                                }
                            } else {
                                if (this.props.items[j].allow !== undefined) {
                                    body[index_body][this.props.items[j].value] = this.props.items[j].allow;
                                } else {
                                    body[index_body][this.props.items[j].value] = "#¡ERROR!#";
                                }
                            }
                        }
                        index_body += 1;
                    }
                }
                this.setState({headers, excel_rows: body, loading: false });
            } catch (e) {
                console.log(e);
                Swal.fire('Error', 'Ocurrio un error en la lectura de los datos, por favor verifique que las cabeceras del EXCEL corresponden a lo esperado y que los datos tengan el formato correcto, según se requiera.', 'error');
                this.setState({ loading: false, excel_rows: [], headers: []});
            }
        }
        reader.readAsBinaryString(this.state.excel);        
    }

    saveToServer = () => {
        if (this.state.data_valid) {
            if (this.state.excel_rows.length !== 0) {
                this.setState({ uploading: true });
                fetch(this.props.server, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json'
                    },
                    body: JSON.stringify(this.state.excel_rows)
                }).then(res => {
                    if (res.status === 200) {
                        console.log(res);
                        res.json().then(response => {
                            console.log(response)
                            Swal.fire('Registro correcto', 'Los datos se registraron correctamente', 'success');
                        })
                    } else if (res.status === 401) {
                        res.json().then(error => {
                            console.log(error)
                            Swal.fire(`${error.statusCode}: ${error.error}`, `<div style="text-align: left;"><strong>La carga de datos falló por una de las siguientes razones:</strong> <br />1.- De los datos estáticos que se intentan cargar, posiblemente no están registrados previamente. <br />2.- El formato de los datos no es válido para el registro.<br />3.- La longitud de los campos sobrepasa lo permitido.<br /><br /><strong>Solución:</strong><br />1.- Corregir o cambiar los campos del excel.<br />2.- Comunicarse con el administrador del Sistema.<br />3.- Comunicarse a soporte del Sistema.<div>`, 'error');
                        })
                    } else {
                        res.json().then(error => {
                            Swal.fire(error.error, `${error.statusCode}: ${error.message}`, 'error');
                        })
                    }
                    this.setState({ uploading: false });
                }).catch(e => {
                    this.setState({ uploading: false });
                    Swal.fire("Error", e.toString(), 'error');
                })
            } else {
                Swal.fire('Advertencia', 'No hay datos disponibles para carga.', 'warning');
            }
        } else {
            Swal.fire('Advertencia', 'Existen datos no válidos en el documento, posiblemente por un formato incorrecto.', 'warning');
        }
    }

    render = () => {
        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            {
                                this.props.element !== undefined ?
                                    this.props.element
                                    :
                                    <div className="designTableExcel">
                                        <table>
                                            <thead>
                                                <tr>
                                                    {
                                                        this.state.headersInput.map((item, i) =>
                                                            <td key={i}><Form.Control type="text" placeholder={item} value={item} onChange={e => {this.state.headersInput[i] = e.target.value.toUpperCase(); this.setState({refresh: !this.state.refresh});}} /></td>
                                                        )
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {
                                                        this.props.items.map((item, i) =>
                                                            <td key={i}>{item.description}</td>
                                                        )
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            }
                        </center>
                        <div style={{ width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: 150 }}>
                            <center>
                                {!this.state.uploading ?
                                    <input type="file" name="choose_file" id="choose_file" style={styles.input_file} onChange={this.readExcel} />
                                    : null}
                                <label style={styles.input_file_label} htmlFor='choose_file'>
                                    <strong style={{ color: 'white', fontWeight: 'bold', fontSize: 14, textAlign: 'center' }}>Elegir EXCEL</strong><br />
                                    <span id="file_name" style={{ color: 'white', fontSize: 12, textAlign: 'center' }}>{this.state.excel_name}</span>
                                </label>
                            </center>
                        </div>
                        {
                            this.state.sheets.length !== 0 ?
                                <div>
                                    <ButtonToolbar style={{ padding: 10, flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <ButtonGroup>
                                            <DropdownButton as={ButtonGroup} title='Hojas' id="bg-nested-dropdown" variant={'danger'} disabled={this.state.uploading}>
                                                {
                                                    this.state.sheets.map((item, i) =>
                                                        <Dropdown.Item key={i} onClick={() => this.getInformationSheet(i)} disabled={this.state.loading}>{item}</Dropdown.Item>
                                                    )
                                                }
                                            </DropdownButton>
                                        </ButtonGroup>
                                        {
                                            this.state.loading ?
                                                <ButtonGroup>
                                                    <Button variant="secondary" disabled={true}>Cargando...</Button>
                                                </ButtonGroup>
                                                : null
                                        }
                                        {
                                            this.state.excel_rows.length !== 0 ?
                                                <ButtonGroup>
                                                    <Button disabled={this.state.uploading} variant={'success'} onClick={this.saveToServer}>{!this.state.uploading ? 'Cargar al servidor' : 'Cargando en el servidor...'}</Button>
                                                </ButtonGroup>
                                                : null
                                        }
                                    </ButtonToolbar>
                                </div>
                                : null}
                        {
                            this.state.excel_rows.length !== 0 ?
                                <div className="designTableExcel">
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.headers.map((item, i) => 
                                                        <th key={item.l + i}>{item.v}</th>
                                                    )
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.excel_rows.map((row, index) =>
                                                    <tr key={index}>
                                                        {
                                                            this.props.items.map((item, pos) =>
                                                                <td key={pos}>{row[item.value]}</td>
                                                            )
                                                        }
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                : null
                        }
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

ModalUpload.propTypes = {
    server: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['String', 'Number', 'Boolean']).isRequired,
        allow: PropTypes.string,
        toFormat: PropTypes.oneOf(['Date', 'Time'])
    }).isRequired).isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    headers: PropTypes.element,
    readExcel: PropTypes.func
}

export default ModalUpload;