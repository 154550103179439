import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxxSV = () => {
    const table = 'vertical_marking_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 100 },
        { label: 'TRAMO', description: 'Tramo', value: 'road_section', type: 'Number', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'SECCION', description: 'Sección', value: 'section', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'KM', description: 'km', value: 'km', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'LONGITUD', description: 'Longitud', value: 'longitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'LATITUD', description: 'Latitud', value: 'latitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'TIPO', description: 'Tipo', value: 'type', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true, widthXLSX: 100 },
        { label: 'LEYENDA_DESCRIPCION', description: 'Leyenda o descripción', value: 'legend_description', type: 'String', allow: null, edit: true, add: true, width: 20, widthXLSX: 100 },
        { label: 'CLASIFICACION_SCT', description: 'Clasificación SCT', value: 'sct_classification', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'DIMENSIONES_CMS', description: 'Dimensiones en cm', value: 'cms_dimensions', type: 'String', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'FOTOGRAFIA', description: 'Fotografía', value: 'file_name', type: 'String', allow: null, width: 15, widthXLSX: 100 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
    ];

    const km = [{ index: 3 }];      // Arreglo de indices que irán con formato 000+000
    const percentage = [];          // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxxSV;