import React, { useEffect, useState } from 'react';
import { indexMaintenanceActivities } from 'api/operation_standards/maintenance_activities';
import { indexMaintenanceProgram } from 'api/operation_standards/maintenance_program';
import { indexMaintenanceProgramFlow } from 'api/operation_standards/maintenance_program_flow';
import TabsComp from 'components/layouts/Tabs';
import { Variables } from 'variables/Variables';
import Filters from './fragments/Filters';
import ActivitiesTab from './tabs/ActivitiesTab';
import AssetsTab from './tabs/AssetsTab';
import CalendarTab from './tabs/CalendarTab';
import GanttChartTab from './tabs/GanttChartTab';
import MaintenanceProgramTab from './tabs/MaintenanceProgramTab';
import { indexMaintenanceSections } from 'api/operation_standards/maintenance_sections';
import AssetsTypesTab from './tabs/AssetsTypesTab';
import BaseLinesTab from './tabs/BaseLinesTab';
import IncidentsTab from './tabs/IncidentsTab';
import DeductivesTab from './tabs/DeductivesTab';
import Dashboard from '../Mantemiento/Dashboard';
import MonitoringTab from './tabs/MonitoringTab';
import GlobalTab from './tabs/GlobalTab';
import { allowModule } from 'components/libs/allowModule';
import HumanResourcesTab from './tabs/HumanResourcesTab';
import SubcontractsTab from './tabs/SubcontractsTab';
import MachineryEquipmentTab from './tabs/MachineryEquipmentTab';
import MaterialsTab from './tabs/MaterialsTab';
import { Skeleton } from 'antd';

const Main = (props) => {
    const { project_id, code, name, table, badges, items, user_code, km, permission } = props;
    const package_id = Variables.package_id;

    const [maintenanceProgram, setMaintenanceProgram] = useState([]);
    const [maintenanceProgramFlow, setMaintenanceProgramFlow] = useState([]);
    const [assetActivities, setAssetActivities] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [maintenance_sections_id, setMaintenanceSectionsId] = useState(0);
    const [maintenance_types_id, setMaintenanceTypesId] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [tabs, setTabs] = useState([]);
    const [mainTabs, setMainTabs] = useState([]);

    useEffect(() => {
        const getTabsExtra = () => {
            if (table === 'assets_its') {
                let _tabs = [];
                if (allowModule({ permission: permission + 'M' }))
                    _tabs.push({
                        title: 'Monitoreo ITS',
                        component: <MonitoringTab
                            key={permission + 'M'}
                            project_id={project_id}
                            table={table}
                            items={items}
                        />
                    });
                if (allowModule({ permission: permission + 'B' }))
                    _tabs.push({
                        title: 'Globales',
                        component: <GlobalTab
                            key={permission + 'B'}
                            project_id={project_id}
                            table={table}
                        />
                    });
                setTabs(_tabs);
            }
        }
        if (!loadingData) {
            getTabsByPermissions();
            getTabsExtra();
        }
    }, [loadingData]);

    useEffect(() => {
        // Método para obtener la sección en la que se encuentra
        const getMaintenanceSections = async () => {
            let response = await indexMaintenanceSections({ package_id, operating_indicator_code: code });
            if (response.data.length > 0) {
                setMaintenanceSectionsId(response.data[0].id);
            }
        }
        getMaintenanceSections();
    }, []);

    useEffect(() => {
        if (maintenance_sections_id) {
            getData();
            getAssetActivities();
        }
    }, [maintenance_sections_id]);

    const getTabsByPermissions = () => {
        let _tabs = [];

        const modules = {
            D: {
                title: 'Dashboard',
                component: <Dashboard
                    key={permission + 'D'}
                    project_id={project_id}
                    component={table}
                />
            },
            I: {
                title: 'Incidentes',
                component: <IncidentsTab
                    key={permission + 'I'}
                    project_id={project_id}
                    package_id={package_id}
                    table={table}
                    user_code={user_code}
                    maintenance_types_id={maintenance_types_id}
                    maintenance_sections_id={maintenance_sections_id}
                />
            },
            E: {
                title: 'Deductivas',
                component: <DeductivesTab
                    key={permission + 'E'}
                    project_id={project_id}
                    table={table}
                />
            },
            N: {
                title: 'Inventario',
                component: <AssetsTab
                    key={permission + 'N'}
                    project_id={project_id}
                    package_id={package_id}
                    table={table}
                    maintenance_types_id={maintenance_types_id}
                    maintenance_sections_id={maintenance_sections_id}
                    getData={getData}
                    badges={badges}
                    items={items}
                    code={code}
                    name={name}
                    km={km}
                />
            },
            T: {
                title: 'Tipo de Bienes',
                component: <AssetsTypesTab
                    key={permission + 'T'}
                    package_id={package_id}
                    table={table}
                />
            },
            L: {
                title: 'Líneas Base',
                component: <BaseLinesTab
                    key={permission + 'L'}
                    package_id={package_id}
                />
            },
            A: {
                title: 'Actividades',
                component: <ActivitiesTab
                    key={permission + 'A'}
                    package_id={package_id}
                    project_id={project_id}
                    code={code}
                    maintenance_sections_id={maintenance_sections_id}
                />
            },
            P: {
                title: 'Programa de Mantenimiento',
                component: <MaintenanceProgramTab
                    key={permission + 'P'}
                    project_id={project_id}
                    package_id={package_id}
                    maintenance_sections_id={maintenance_sections_id}
                />
            },
            C: {
                title: 'Calendario',
                component: <CalendarTab
                    key={permission + 'C'}
                    package_id={package_id}
                    project_id={project_id}
                    maintenanceProgram={maintenanceProgram}
                    maintenanceProgramFlow={maintenanceProgramFlow}
                    name={name}
                    maintenance_sections_id={maintenance_sections_id}
                />
            },
            G: {
                title: 'Diagrama de Gantt',
                component: <GanttChartTab
                    key={permission + 'G'}
                    maintenanceProgram={maintenanceProgram}
                    maintenanceProgramFlow={maintenanceProgramFlow}
                    assetActivities={assetActivities}
                />
            },
            H: {
                title: 'Recursos Humanos',
                component: <HumanResourcesTab
                    key={permission + 'H'}
                    package_id={package_id}
                    project_id={project_id}
                />
            },
            J: {
                title: 'Materiales',
                component: <MaterialsTab
                    key={permission + 'J'}
                />
            },
            K: {
                title: 'Maquinaria y Equipo',
                component: <MachineryEquipmentTab
                    key={permission + 'K'}
                />
            },
            O: {
                title: 'Subcontratos',
                component: <SubcontractsTab
                    key={permission + 'O'}
                />
            }
        };

        for (let module in modules) {
            if (allowModule({ permission: permission + module })) {
                _tabs.push(modules[module]);
            }
        }
        setMainTabs(_tabs);
    };

    const getData = async () => {
        setLoadingData(true);
        await getMaintenanceProgram();
        await getMaintenanceProgramFlow();
        setLoadingData(false);
    }

    // Método para obtener el programa de mantenimiento
    const getMaintenanceProgram = async () => {
        let response = await indexMaintenanceProgram({ package_id, project_id, maintenance_sections_id });
        setMaintenanceProgram(response.data);
    }
    // Método para ontener el flujo de programa de mantenimiento
    const getMaintenanceProgramFlow = async () => {
        let response = await indexMaintenanceProgramFlow({ package_id, project_id, maintenance_sections_id });
        setMaintenanceProgramFlow(response.data);
    }
    // Método para obtener las actividades de mantenimiento
    const getAssetActivities = async () => {
        let response = await indexMaintenanceActivities({ project_id });
        setAssetActivities(response.data);

    }
    // Método para obtener la pestaña seleccionada
    const onChangeTab = (activeKey) => {
        setActiveTab(parseInt(activeKey));
    }

    return (
        <div className="body_" >
            {/* Filtros de búsqueda */}
            <Filters
                activeTab={activeTab}
                package_id={package_id}
                code={code}
                name={name}
                setMaintenanceTypesId={setMaintenanceTypesId}
                getData={getData}
            />

            {/* Tabs de las opciones generales */}
            {
                loadingData ? <Skeleton active /> :
                    < TabsComp
                        onChange={onChangeTab}
                        tablist={[
                            ...mainTabs.map(item => item.title),
                            ...tabs.map(item => item.title)
                        ]}
                        tabpanels={[
                            ...mainTabs.map(item => item.component),
                            ...tabs.map(item => item.component)
                        ]}
                        activeKey={activeTab}
                    />
            }
        </div >
    )
}

export default Main