import React, { Component } from 'react';
import { Col, Modal, Row, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { downloadXLSX } from 'components/libs/exportData';

const ModalReport = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
        }
    }

    componentDidMount = () => {
        console.log("Generando reporte");
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    getExcel = () => {
        downloadXLSX({
            file_name: 'Title',
            data: [
                ['Nombre', 'Apellido', 'Edad'],
                ['Juan', 'Pérez', 25],
                ['María', 'García', 30],
                ['Pedro', 'Sánchez', 40]
            ]
        });
    }

    getPdf = () => {
        console.log("Getting PDF file...");
    }

    render = () => {
        console.log("inventory: ", this.props.inventory);
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '200px', width: '100%' }}>
                    <center>
                        <Row>
                            <Col>
                                <p style={{ color: 'black' }}>Hoja de cálculo</p>
                                <Button size={"lg"} variant="outline-success" onClick={this.getExcel}>
                                    <FaFileExcel />
                                </Button>
                            </Col>
                            <Col>
                                <p style={{ color: 'black' }}>Reporte en PDF</p>
                                <Button size={"lg"} variant="outline-danger" onClick={this.getPdf}>
                                    <FaFilePdf />
                                </Button>
                            </Col>
                        </Row>
                    </center>
                </Modal.Body>

            </Modal >
        );
    }
}

export default ModalReport;