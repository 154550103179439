import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { storeAssetsTypes } from 'api/operation_standards/assets_types';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import ModalComp from 'components/layouts/Modal'
import React, { useState } from 'react'
import Swal from 'sweetalert2';

const AddAssetType = (props) => {
    const { onClose, package_id, table, update } = props;
    const [data, setData] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    const validate = () => {
        let response = true;
        if (!data.name) response = false;
        return response;
    }
    
    const save = async () => {
        if (validate()) {
            let response = await storeAssetsTypes({ package_id, table, data });
            if (response) {
                Swal.fire('¡Operación exitosa!', 'El tipo ha sido almacenado de manera satisfactoria.', 'success');
                update();
                onClose();
            } else {
                Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de almarcenar el tipo. Intente más tarde.', 'error');
            }
        } else {
            Swal.fire('¡Formulario incompleto!', 'Verifique los datos del formulario.', 'warning');
        }
    }
    return (
        <ModalComp
            title='Agregar un tipo'
            size='md'
            onClose={onClose}
            body={<>
                <FormIcon title='Nombre' name='name' value={data?.name} handleChange={handleChange} />
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name='Guardar' icon={faSave} variant='outline-success' onClick={save} />
            </>}
        />
    )
}

export default AddAssetType