import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import ButtonIcon from "./ButtonIcon";
import { Switch, Tag } from 'antd';
import NoData from "./NoData";
import _ from 'underscore';
import SkeletonTable from "./SkeletonTable";
import { getCurrency, getKmFormat, getPercentaje } from "components/libs/functions";
import moment from "moment";


/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` } 
 * @param body Contiene el array para llenar el body de la tabla
 * @param keys Es un arreglo que contiene los indices que se van a utilizar del body
 * @param actions Es un array de objetos, estos objetos son dinámicos ya que pueden ser atributos para distintos componentes (switch, buttons, etc)
 * @param loading Es un dato booleano que indica si ya se terminó de obtener el body, para mostrar los estados de carga
 * @param type [Opcional] Indica que tipo de componente se va a utilizar en las acciones, default buttons
 * @param loadingItems [Opcional] Cantidad de tuplas que se mostrarán en el Skeleton, default 5 
 * @param page [Opcional] Página actual para calcular el index del los elementos sin importar en qué paǵina se encuentren
 * @param badges [Opcional] Array [{index:[indice de la columna], color:[indica el nombre de la tabla que tiene el color que se utiliza para la celda actual]}] que indica 
 * @param badges_values [Opcional] Object{values: [Arreglo de valores asociados a los colores que van a adquirir], col: [key que va a tener badge]} 
 * @param currency [Opcional] Array que indica las columnas que tendrán formato de moneda
 * @param icons [Opcional] Array de objetos que indica en que valores se reemplazará por un ícono
 * @param percentage [Opcional] Arreglo de {index: 'ïndice del key sobre el que se aplicará el formato', accuracy: 'Precisión'}
 * @param km [Opcional] Arreglo de {index: 'Índice del key sobre el que se aplicará el formato'}
 * @returns Table
 */
const TableComp = (props) => {
    const {
        headers,
        body,
        keys,
        actions,
        loading,
        type,
        loadingItems,       // PageSize
        page,               // Página actual
        badges,
        badges_values,
        currency,
        icons,
        percentage,         // Índices que irán con formato de porcentaje
        km,                 // Índices que irán en formato 000+000
        moment_dates,       // Índices que irán en formato de fecha
    } = props;

    // Este método sirve para renderizar el contenido de las acciones
    const renderSwitch = (item) => {
        // Se pueden agregar más componentes
        switch (type) {
            case 'switch': // Deslizar para activar o desactivar
                let {
                    checkedChildren,
                    unCheckedChildren,
                    onChange,
                    getChecked,
                    getDisabled
                } = actions;

                return <Switch
                    checkedChildren={checkedChildren || 'True'}
                    unCheckedChildren={unCheckedChildren || 'False'}
                    onChange={(checked) => onChange(checked, item.id)}
                    defaultChecked={() => getChecked(item.id)}
                    disabled={getDisabled(item.id)}
                />
            case 'btn_din':
                break;
            default: // Por default recorrer array de botones
                let new_actions = actions.filter((action) => {
                    // Si trae la propiedad dinamic se va a mostrar sólo si cumple las condiciones
                    if (action.dinamic) {
                        for (const element of action.dinamic) {
                            // Se obtiene el key y value que se van a comparar con la propiedad item
                            // Ejempo: Si key:'payment_method' y value: 'PPD', se tiene que buscar que item.payment_method === 'PPD', 
                            // de lo contrario no muestra el botón
                            let { key, value } = element;
                            if (item[key] !== value)
                                return false;
                        }
                    }
                    // Se evalua que el item puede adquirir mas de un valor
                    if (action.dinamics) {
                        for (const element of action.dinamics) {
                            let { key, values } = element;
                            if (!values.includes(item[key]))
                                return false;
                        }
                    }
                    if (action.showWhenNotNull) {
                        for (const key of action.showWhenNotNull) {
                            if (item[key] === null) {
                                return false;
                            }
                        }
                    }
                    return true;
                });
                return new_actions.map((action, a_index) =>
                    <ButtonIcon
                        key={a_index}
                        name={action.name}
                        onClick={() => action.handleClick(item)}
                        icon={action.icon}
                        tooltip={action.tooltip}
                        variant={action.variant}
                        disabled={action.disabled || false}
                    />
                )
        }
    }

    /**
     * 
     * @param {number} index_col Columna en la que se va a asignar el valor
     * @param {object} item Objeto que se está evaluando
     * @param {number} index_row  Tupla que se está recorriendo
     * @returns {*} Celda que se va a poner en la posición [index_col, index_row]
     */
    const setBadge = (index_col, item, index_row) => {
        let cell_changes = true;                                        // True para ejecutar formatos de moneda y badges, false si la celda se convierte en ícono
        const pos = _.findIndex(badges, { index: index_col });          // Busca si la celda tiene que estar en un badge
        const pos_c = _.findIndex(currency, { index: index_col });      // Busca si la celda debe tener formato de moneda
        const pos_p = _.findIndex(percentage, { index: index_col });    // Busca si la celda debe tener formato de porcentaje
        const pos_km = _.findIndex(km, { index: index_col });           // Busca si la celda debe tener formato de Cadenamiento 000+000  
        const pos_dates = _.findIndex(moment_dates, { index: index_col });// Busca si la celda debe tener formato de fecha 
        let cell = item;
        if (keys[index_col] === '#') {                                  // Si la key es #, se tomará el valor index de map
            cell = index_row + 1;
            if (page && loadingItems)                                   // Si recibe como parámetros page y loadingItems podemos crear un index para cada tupla 
                cell = (page - 1) * loadingItems + cell;                // Agregamos la cantidad correspondiente al tamaño de página y página actual
        } else {
            cell = getCellValue(keys[index_col], item);                 // Se obtiene el valor de la celda
            let pos_i = _.findIndex(icons, { value: cell });            // Buscamos si el contenido se tiene que reemplazar con un ícono
            if (pos_i !== -1) {                                         // Si el contenido si se reemplaza
                cell = icons[pos_i].icon;                               // Asignamos el icono que viene en el arreglo
                cell_changes = false
            }
        }
        if (cell_changes) {                                             // Ejecutar cambios de moneda y badges
            if (pos_dates !== -1) {
                cell = getMomentDate(cell, moment_dates[pos_dates].format);
            }
            if (pos_c !== -1) {                                         // Si la celda debe tener formato de moneda
                cell = getCurrency(cell);                               // Asigna el valor obtenido 
            }
            if (pos_p !== -1) {
                cell = getPercentaje(cell, percentage[pos_p].accuracy);
            }
            if (pos_km !== -1)
                cell = getKmFormat(cell);
            if (pos !== -1) {                                           // Si hay badges
                let color;                                              // Dark by default
                if (badges_values) {
                    color = getColor(item, keys[index_col]);
                    cell = getCellName(item, keys[index_col]);
                } else {
                    color = item[badges[pos].color];                    // Obtiene el color
                }
                cell = <Tag color={color}>{cell}</Tag>;                 // [Ant Design] Cambia contenido a bagde
            }
        }
        return (
            <td key={index_col}><center>{cell}</center></td>
        );
    }
    // obtiene el formato de la fecha, si falla al convertir retorna el valor de la celda
    const getMomentDate = (cell, format) => {
        try {
            return moment(cell).format(format);
        } catch (error) {
            return cell;
        }
    }
    const getCellValue = (value, item) => {
        try {
            let array_plus = value.split('+');                  // Separar por signo +, para concatenar atributos
            let band = false;
            if (value.includes('++')) {
                array_plus = value.split('++');                 // Separar por signo ++, para concatenar atributos
                band = true;
            }
            let response = '';
            for (const string of array_plus) {                  // Cantidad de atributos que se agregan en la celda. ej. usr.first_name + usr.last_name
                let cell2 = item;                               // Valor inicial
                const array_docs = string.split('.');           // Obtenemos el atributo(s) separando por punto, para determinar el nivel en el que se encuentra el item
                for (const val of array_docs) {                 // Recorremos todos los atributos
                    if (!cell2[val])                            // Si es nulo o undefined se deja vacio
                        cell2 = ''
                    else cell2 = cell2[val];                    // Se accede al atributo
                }
                if (band) response += cell2;                    // Se concatena con un espacios
                else response += cell2 + ' ';                   // Se concatena con un espacios
            }
            return response.trim();                             // Se eliminan espacios en blanco
        } catch (error) {
            return item[value];
        }
    }
    // Obtiene el color del badge 
    const getColor = (item, key) => {
        let color = 'purple';
        try {
            let pos_badges = _.findIndex(badges_values, { col: key });
            if (pos_badges !== -1) {
                const current_values = badges_values[pos_badges].values;
                const current = current_values.find((badge) => badge.value == getCellValue(key, item))
                color = current.color
            }
            return color;
        } catch (error) {
            return color;
        }
    }
    // Obtener el nombre si viene en el badge, de lo contrario dejar el de la tupla
    const getCellName = (item, key) => {
        let response = getCellValue(key, item);
        try {
            let pos_badges = _.findIndex(badges_values, { col: key });
            if (pos_badges !== -1) {
                let pos = _.findIndex(badges_values[pos_badges].values, { value: item[badges_values[pos_badges].col] });
                if (badges_values[pos_badges].values[pos].name)
                    response = badges_values[pos_badges].values[pos].name;
            }
            return response;
        } catch (error) {
            return response;
        }
    }

    return (
        <Fragment>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        {   // Recorremos el array headers y los agregamos al encabezado
                            headers.map((theader, index) =>
                                <th key={index}>{theader}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {   // El componente va a mostrar el skeleton table hasta que reciba un false en el loading
                        loading ? <SkeletonTable tr={loadingItems || 5} td={headers.length} /> :
                            body && body.map((item, index_row) => //Se recorre el arrar de objetos body para rellenar la tabla
                                <tr key={index_row} className="tr-table">
                                    {   // Se recorre el número de encabezados para crear las columnas. Nota Se resta 1 para poner las acciones en otra columna
                                        Array.from({ length: headers.length - 1 }).map((_, index_col) =>
                                            // Se inserta el valor del item  en base a la key y su index_col 
                                            setBadge(index_col, item, index_row)
                                        )
                                    }
                                    <td>{actions ? renderSwitch(item) : null}</td>
                                </tr>
                            )
                    }
                </tbody>
            </table>
            {/* Si ya no está cargando y el body es vacio */}
            {
                !loading && (body?.length === 0 || !body) ? <NoData /> : null
            }
        </Fragment>
    );
}

TableComp.propTypes = {
    headers: PropTypes.array,       // Título de los encabezados
    body: PropTypes.array,          // Objeto que contiene los datos del body de la tabla
    keys: PropTypes.array,          // Keys del objeto que se muestran en la tabla. Nota: poner en orden de los encabezados
    // Botones o componenetes que van en la sección de acciones
    actions: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                dinamic: PropTypes.array,
                dinamics: PropTypes.array,
                showWhenNotNull: PropTypes.array,
                name: PropTypes.string,
                handleClick: PropTypes.func,
                icon: PropTypes.object,
                tooltip: PropTypes.string,
                variant: PropTypes.string,
                disabled: PropTypes.bool,
            })
        ),
        PropTypes.shape({
            checkedChildren: PropTypes.func,
            unCheckedChildren: PropTypes.func,
            onChange: PropTypes.func,
            getChecked: PropTypes.func,
            getDisabled: PropTypes.func,
        })
    ]),
    loading: PropTypes.bool,        // Indica si la información se sigue obteniendo
    loadingItems: PropTypes.number, // Cantidad de tuplas que se muestran al cargar
    type: PropTypes.string,         // Indica que tipo de control llevan las acciones, defalt button
    badges: PropTypes.arrayOf(      // Indica si el contenido de la celda va dentro de un badge
        PropTypes.shape({
            index: PropTypes.number.isRequired,     // indica la posición en la que pintará el badge
            color: PropTypes.string                 // indica el nombre del atributo del que se tomará el color
        })
    ),
    badges_values: PropTypes.arrayOf(
        PropTypes.shape({
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    color: PropTypes.string,
                    value: PropTypes.any,
                    name: PropTypes.string
                })
            ),
            col: PropTypes.string
        })
    ),
    currency: PropTypes.array,
    icons: PropTypes.array,
    page: PropTypes.number,
    percentage: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired,
            accuracy: PropTypes.number.isRequired
        })
    ),
    km: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired,
        })
    ),
    moment_dates: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired,
            format: PropTypes.string.isRequired
        })
    )
}

export default TableComp;