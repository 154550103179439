import { gql } from "@apollo/client";

export const NEW_ASSETS_ITS_RECORD = gql`
  subscription NewAssetsItsRecord($_gt: timestamptz) {
    assets_its_records(
      where: { created_at:{_gt: $_gt} }
      limit: 1
      order_by: { id: desc }
    ) {
        id
        type
        active_user
        extras
        created_at
    }
  }
`;