import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { showWorkOrderOP, storeWorkOrderOP } from 'api/operation_standards/work_order_op';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import ModalComp from 'components/layouts/Modal'
import React, { useEffect, useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Variables } from 'variables/Variables';

const AddWorkOrder = (props) => {
    const { onClose, data, loading, name, package_id, project_id, eventSel } = props;
    const [form, setForm] = useState({});
    const indicator_id = Variables.indicator_id;

    useEffect(() => {
        getWorkOrderLastCode();
    }, []);

    const getWorkOrderLastCode = async () => {
        let response = await showWorkOrderOP({ package_id });
        if (response) {
            // Si ya hay algún código registrado, se encarga ded obtener el número consecuente
            let split_str = response.code.split('-');
            let last_code_min = parseInt(split_str[split_str.length - 1], 10);
            let last_code = last_code_min + 1 + '';
            if (last_code.length < 6) {
                let t = new Array(6 - last_code.length).fill("0")
                last_code = t.join('') + last_code;
            }
            setForm({ ...form, last_code, last_code_min });
        } else {
            // Si no existe ningún código, toma por default 000001
            setForm({
                ...form,
                last_code: "000001",
                last_code_min: 1
            });
        }
    }
    const save = async () => {
        const _data = {
            code: `OT-${Variables.project_code}-${form.last_code}`,
            authorization: form.autorization,
            vobo: form.vobo,
            description: form.description_wo !== '' ? form.description_wo : null,
            target_date: data.end_date.split('T')[0] + 'T' + data.end_time,
            asset_activity_id: eventSel.id
        };
        let response = await storeWorkOrderOP({ project_id, package_id, indicator_id, data: _data });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'La orden de trabajo ha sido agregada de manera satisfactoria', 'success');
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Hubo un error al tratar de agregar la Orden de Trabajo.', 'error');
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    }

    return (
        <ModalComp
            title='Generar orden de trabajo'
            size='lg'
            onClose={onClose}
            body={<>
                {
                    !loading &&
                    <Form>
                        <Form.Group>
                            <Form.Label><strong>{data.asset_permanent}</strong></Form.Label><br />
                            <Form.Label>{name}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={"2"}>Código OT</Form.Label>
                            <Col sm={"10"}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>OT-{Variables.project_code}-</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control value={form?.last_code} type={'number'} min={form.last_code_min} maxLength={6}
                                        name='last_code' onChange={handleChange} required={true} readOnly/>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={"2"}>Responsable</Form.Label>
                            <Col sm={"10"}>
                                <Form.Control type="text" readOnly={true} value={data.responsable_permanent} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={"2"}>Actividad</Form.Label>
                            <Col sm={"10"}>
                                <Form.Control readOnly={true} value={data.activity_permanent} multiple={true} />
                            </Col>
                        </Form.Group>
                        {
                            data.asset_activity.table_name === "toll_management_assets" &&
                            <Row>
                                <FormIcon title='Marca' readOnly value={data?.asset_activity.asset[0].brand} />
                                <FormIcon title='Modelo' readOnly value={data?.asset_activity.asset[0].model} />
                                <FormIcon title='Serial' readOnly value={data?.asset_activity.asset[0].serial} />
                            </Row>
                        }
                        <Form.Group as={Row}>
                            <Form.Label column sm={"2"}>Fecha planeada</Form.Label>
                            <Col sm={"10"}>
                                <Form.Control readOnly={true} value={`Del ${data.start_date.split('T')[0]} al ${data.end_date.split('T')[0]}`} multiple={true} />
                            </Col>
                        </Form.Group>
                        <Row>
                            <FormIcon title='Horario' readOnly value={data?.start_time + " - " + data?.end_time} />
                            <FormIcon title='Estado' readOnly value={data?.status} />
                        </Row>
                        <Row>
                            <FormIcon title='Autorización' name='autorization' value={data?.autorization} handleChange={handleChange} />
                            <FormIcon title='Visto bueno' name='vobo' value={data?.vobo} handleChange={handleChange} />
                        </Row>
                        <Form.Group as={Row}>
                            <Form.Label column sm={"2"}>Descripción (opcional)</Form.Label>
                            <Col sm={"10"}>
                                <Form.Control as="textarea" name='description_wo' value={form?.description_wo} onChange={handleChange} />
                            </Col>
                        </Form.Group>
                    </Form>
                }
            </>}
            footer={<>
                <ButtonIcon onClick={onClose} name='Cerrar' variant='outline-secondary' icon={faTimes} />
                <ButtonIcon onClick={save} name='Crear' variant='outline-success' icon={faSave} />
            </>}
        />
    )
}

export default AddWorkOrder