import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexPumActivities = async ({ activity_id }) => {
    const url = `/maintenance_standard/pum_activities`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { activity_id }
        });
        // console.log("🚀 ~ file: work_orders.js:13 ~ indexPumActivities ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data;
        }
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}

export const storePumActivities = async ({ data }) => {
    const url = `/maintenance_standard/pum_activities`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:33 ~ storePumActivities ~ fetch:", fetch)
        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok)
            response = fetch.data
        return { response };
    } catch (error) {
        return { response };
    }
}

export const updatePumActivities = async ({ id, data }) => {
    const url = `/maintenance_standard/pum_activities/${id}`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:150 ~ updatePumCostPumActivities ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data
        } else {
            throw fetch.statusText;
        }
        return { response };
    } catch (error) {
        return { response, error };
    }
}