import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Tabs } from "antd";

const TabsComp = (props) => {
    const {
        tablist,
        tabpanels,
        defaultIndex,
        onChange,
        size,
        tabColor,
        activeKey,
        tabPosition
    } = props;

    const [items, setItems] = useState([]);

    useEffect(() => {
        fillTabs();
    }, [tabpanels]);

    const fillTabs = () => {
        try {
            setItems(tablist.map((tab, index) => {
                return {
                    label: tab,
                    key: index,
                    children: tabpanels[index] ? tabpanels[index] : null,
                }
            }));
        } catch (error) { }
    }

    const onChangeLocal = () => { }

    return (
        <Tabs
            tabPosition={tabPosition || 'top'}
            defaultActiveKey={defaultIndex || 1}
            onChange={onChange || onChangeLocal}
            size={size || 'small'}
            items={items}
            activeKey={activeKey}
            tabBarStyle={{
                color: tabColor || 'white'
            }}
        />
    );
}

TabsComp.propTypes = {
    tablist: PropTypes.array.isRequired,
    tabpanels: PropTypes.array.isRequired,
    defaultIndex: PropTypes.number,
    tabColor: PropTypes.string,
    onChange: PropTypes.func,
    activeKey: PropTypes.number,
    tabPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    size: PropTypes.oneOf(['small', 'middle', 'large']) // Valores del framework de antd
}

export default TabsComp;