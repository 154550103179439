//Acciones de Sesión
export const OPEN_SESSION = "OPEN_SESSION";
export const CLOSE_SESSION = "CLOSE_SESSION";


//Acciones de actualización de datos
export const UPDATE_DATA_USER = "UPDATE_DATA_USER";
export const UPDATE_PROJECTS = "UPDATE_PROJECTS";


//Acciones del BreadCrumb
/**Acciones con respecto al BreadCumb de Proyectos */
export const BREADCRUMB_BEFORE = "BREADCRUMB_BEFORE";
export const BREADCRUMB_ADD = "BREADCRUMB_ADD";
export const UPDATE_COMPONENT = "UPDATE_COMPONENT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_MODULES = "UPDATE_MODULES";
export const UPDATE_STAGES = "UPDATE_STAGES";
export const UPDATE_STO = "UPDATE_STO";
export const UPDATE_STM = "UPDATE_STM";
export const STAGE_CHOOSED = "STAGE_CHOOSED";
export const MODULE_CHOOSED = "MODULE_CHOOSED";
export const STANDARD_O_CHOOSED = "STANDARD_O_CHOOSED";
export const STANDARD_M_CHOOSED = "STANDARD_M_CHOOSED";
/**Acciones con respecto al BreadCumb de Proyectos */
export const BREADCRUMB_BEFORE_AJUSTES = "BREADCRUMB_BEFORE_AJUSTES";
export const BREADCRUMB_ADD_AJUSTES = "BREADCRUMB_ADD_AJUSTES";
export const UPDATE_COMPONENT_AJUSTES = "UPDATE_COMPONENT_AJUSTES";
export const UPDATE_OPTION_AJUSTES = "UPDATE_OPTION_AJUSTES";

/**Acciones de Proyectos */


export const SET_ITEMS_ITS = "SET_ITEMS_ITS";
export const SET_ITEMS_INFRASTRUCTURE = "SET_ITEMS_INFRASTRUCTURE";
export const SET_ITEMS_EDIFICATION = "SET_ITEMS_EDIFICATION";
export const SET_ITEMS_ENVIRONMENT = "SET_ITEMS_ENVIRONMENT";