import { useMutation } from '@apollo/client';
import { faSave, faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Col, Divider, List, Row } from 'antd';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import ModalComp from 'components/layouts/Modal'
import { DESTROY_ROOM_USER, UPDATE_ROOM } from 'graphql/mutations/_messenger/RoomsMutations';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import _ from 'underscore';

const MenuRoom = (props) => {
    const { onClose, users, rooms, room_id, getRooms, user_id } = props;
    // Mutación para actualizar el nombre de la sala
    const [updateRoom, {
        data: dUpdateRoom, loading: lUpdateRoom, error: eUpdateRoom
    }] = useMutation(UPDATE_ROOM);
    // Mutación para eliminar de la relación User-Room al usuario que va a salir de la sala
    const [destroyRoomUser, {
        data: dDestroyRoom, loading: lDestroyRoom, error: eDestroyRoom
    }] = useMutation(DESTROY_ROOM_USER);

    const [usersGroup, setUsersGroup] = useState([]);
    const [room, setRoom] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        if (dUpdateRoom) {
            Swal.fire('¡Operación exitosa!', 'El nombre de la sala fue cambiado satisfactoriamente!.', 'success');
            getRooms();
        }
    }, [dUpdateRoom, lUpdateRoom, eUpdateRoom]);

    useEffect(() => {
        if (dDestroyRoom) {
            Swal.fire('¡Operación exitosa!', 'Usted ya no pertenece a esta sala.', 'success')
            console.log("🚀 ~ file: MenuRoom.jsx ~ line 39 ~ useEffect ~ dDestroyRoom", dDestroyRoom)
            onClose();
        }
        if (eDestroyRoom) {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de salir de sala. inténtelo más tarde.', 'error')
            console.log("🚀 ~ file: MenuRoom.jsx ~ line 43 ~ useEffect ~ eDestroyRoom", eDestroyRoom)
        }
    }, [dDestroyRoom, lDestroyRoom, eDestroyRoom]);

    useEffect(() => {
        getUsersGroup();
    }, [rooms]);
    // Método para obtener los nombres de los usuarios que pertenecen a la sala
    // Refactorizar al obtener los nombres desde GraphQL
    const getUsersGroup = () => {
        let pos = _.findIndex(rooms, { id: room_id });
        if (pos !== -1) {
            setRoom(rooms[pos]);
            setData({ name: rooms[pos].name });
            let usr_names = [];
            rooms[pos].room_users.map((usr) => {
                let pos_usr = _.findIndex(users, { id: usr.user_id });
                if (pos_usr !== -1) {
                    let user = users[pos_usr];
                    usr_names.push(`${user?.first_name} ${user?.last_name}`);
                } else {
                    usr_names.push('Usuario desconocido');
                }
            });
            setUsersGroup(usr_names);
        }
    }
    // Método para salir de una sala
    const leftRoom = () => {
        Swal.fire({
            title: '¿Está seguro que desea salir de la sala?',
            text: "Al aceptar esta operación, usted no podrá mandar mensajes en esta sala.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, salir'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Usar mutación para eliminar la relación de USER-ROOMS
                destroyRoomUser({ variables: { room_id, user_id } });
            }
        })
    }
    // Método para guardar cambios de nombre de sala en grupos
    const saveChanges = () => {
        // Usar mutación para actualizar el nombre de la sala
        const { name } = data;
        updateRoom({ variables: { name, _eq: room_id } });
    }
    // Método para verificar si se han realizado cambios en el nombre de la sala
    const hasChanges = () => {
        let response = false;
        if (room.name !== data?.name) response = true;
        return response;
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    return (
        <ModalComp
            title='Menú de la sala'
            onClose={onClose}
            body={<>
                <p>Sala creada el {moment(room?.created_at).format('LLLL')}</p>
                {
                    room?.room_users?.length > 2 &&
                    <FormIcon
                        title='Nombre de la sala'
                        name='name'
                        value={data?.name}
                        handleChange={handleChange}
                    />
                }
                <Divider orientation="left">Integrantes</Divider>
                <List
                    itemLayout="horizontal"
                    dataSource={usersGroup}
                    renderItem={user => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                title={`${user}`}
                            />
                        </List.Item>
                    )}
                />
                <Divider orientation="left">Opciones</Divider>
                <Row justify='center' align='middle'>
                    <Col span={8}>
                        <ButtonIcon name='Salir del grupo' icon={faSignOutAlt} variant='outline-danger' onClick={leftRoom} />
                    </Col>
                </Row>
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                {
                    hasChanges() &&
                    <ButtonIcon icon={faSave} name='Guardar cambios' variant='outline-success' onClick={saveChanges} />
                }
            </>}
        />
    )
}

export default MenuRoom