import { faCameraRetro, faFileDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { downloadIncidentFiles } from 'api/maintenance_standards/incident_files';
import ButtonIcon from 'components/layouts/ButtonIcon';
import TableComp from 'components/layouts/Table';
import React, { useEffect, useState } from 'react'

const Videos = ({ data, project_id, loading }) => {
	const [preview, setPreview] = useState(false);
	const [file, setFile] = useState(null);
	const [files, setFiles] = useState(null);
	const [loadVideo, setLoadVideo] = useState(true);

	useEffect(() => {
		setFiles(data.media.filter((item) => item.type === 'video'));
	}, []);

	const getFile = async (item) => {
		const { file_path, incident_id } = item;
		const { inspection_id } = data;
		const { response } = await downloadIncidentFiles({ project_id, inspection_id, incident_id, path: file_path });
		setLoadVideo(false);
		return response;
	}

	const handleClick = async (item) => {
		const { file_path } = item;
		const response = await getFile(item);
		if (response) {
			const fileURL = URL.createObjectURL(response);
			const link = document.createElement('a')
			link.href = fileURL
			link.setAttribute('download', file_path)
			document.body.appendChild(link)
			link.click();
		}
	}

	const viewVideo = async (item) => {
		let response = await getFile(item);
		if (response) setFile(URL.createObjectURL(response));
		else setFile(null)
		setPreview(true);
	}

	return (
		<>
			{
				preview && !loadVideo ? <>
					<ButtonIcon name='Cerrar vista previa' icon={faTimes} onClick={() => { setPreview(false); setLoadVideo(true); }} variant='link' />
					{/* Reproducir video */}
					<video width="100%" height="100%" src={file} controls autoPlay />
				</> : <div className="body_">
					<TableComp
						headers={['#', 'Archivo', 'Tipo', 'Acciones']}
						keys={['#', 'file_path', 'file_type']}
						body={files}
						loading={loading}
						actions={[
							{ icon: faFileDownload, tooltip: 'Descargar archivo', variant: 'outline-primary', handleClick },
							{ icon: faCameraRetro, tooltip: 'Reproducir video', variant: 'outline-success', handleClick: viewVideo }
						]}
					/>
				</div>
			}
		</>
	)
}

export default Videos