import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexMrInspections = async ({ project_id, creation_dateS, creation_dateE, created_by, status }) => {
    const url = `/projects/${project_id}/inspections`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params: {
                creation_dateS,
                creation_dateE,
                created_by,
                status
            }
        });
        // console.log("🚀 ~ file: inspections.js:18 ~ indexMrInspections ~ fetch", fetch);
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}


export const storeMrInspections = async ({ project_id, data }) => {
    const url = `/projects/${project_id}/inspections`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: inspections.js:38 ~ storeMrInspections ~ fetch", fetch);
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const closeMrInspections = async ({ project_id, inspection_id, user_id }) => {
    const url = `/projects/${project_id}/inspections/${inspection_id}/close`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data: { userId: user_id }
        });
        // console.log("🚀 ~ file: inspections.js:56 ~ closeMrInspections ~ fetch:", fetch);
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteMrInspections = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/inspections/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: inspections.js:55 ~ deleteMrInspections ~ fetch", fetch);
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}