import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { Variables, httpStatus } from "variables/Variables";

const STATUS_OK = 200;

export const getProjects = async () => {

    const url = `/packages/${Variables.package_id}/projects`;
    let response = [];
    try {
        let fetch = await fetcher({
            url,
            method: 'GET'
        });

        (fetch.status === STATUS_OK) && (response = fetch.data)

        return response;
    } catch (error) {
        return response;
    }
}

export const getProjectsByURL = async ({ url }) => {
    let response = { data: [], totalItems: 0, error: true };
    try {
        const { fetch } = await fetcherCancelToken({
            url,
            method: 'GET'
        });
        // console.log("🚀 ~ file: getProjects.js:32 ~ getProjectsByURL ~ fetch:", fetch)
        
        if (fetch.status === httpStatus.ok) {
            response.data = fetch.data;
            response.totalItems = fetch.data.length;
            response.error = false;
        }
        return { response };
    } catch (error) {
        return { response };
    }
}