import React from 'react';
import FormIcon from 'components/layouts/FormIcon';
import { Row } from 'react-bootstrap';
import { Box } from '@chakra-ui/react';

const ChangePasswordComp = () => {
    return (
        <Box p={5} shadow='md' borderWidth='1px' className='mt-3' bg={'white'}>
            <Row className='mt-4 mb-4'>
                <FormIcon title={'Contraseña actual'} />
                <FormIcon title={'Nueva contraseña'} />
                <FormIcon title={'Confirmar contraseña'} />
            </Row>
        </Box>
    );
};

export default ChangePasswordComp;
