import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";

const CREATED = 201;
const STATUS_OK = 200;

export const downloadMaintenanceProgramFlowFiles = async ({ project_id, program_flow_id, path }) => {
    const url = `/projects/${project_id}/maintenance_standard/program_flow_files/download`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: {
                program_flow_id,
                path
            }
        });
        if (fetch.status === STATUS_OK)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const destroyMaintenanceProgramFlowFiles = async ({ project_id, program_flow_id, id }) => {
    const url = `/projects/${project_id}/maintenance_standard/program_flow_files/${program_flow_id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'DELETE',
            params: {
                id
            }
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}