import React, { useEffect, useState } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { showMaintenanceIncident } from 'api/maintenance_standards/incidents';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import TabsComp from 'components/layouts/Tabs';
import Audios from './IncidentDetails/Audios';
import Info from './IncidentDetails/Info';
import Photos from './IncidentDetails/Photos';
import Videos from './IncidentDetails/Videos';
import { Skeleton } from 'antd';
import Ubication from './IncidentDetails/Ubication';

const IncidentDetails = (props) => {
    const { onClose, id, project_id, title } = props;

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getIncidentDetails = async () => {
            const { data } = await showMaintenanceIncident(id);
            setData(data);
            setLoading(false);
        }
        getIncidentDetails();
    }, [])

    return (<>
        <ModalComp
            title={
                loading ? 'Cargando...' :
                    `${title}`
            }
            size={'lg'}
            onClose={onClose}
            body={<>
                {
                    loading ? <Skeleton /> :
                        <TabsComp
                            tabColor='black'
                            tablist={['Información general', 'Ubicación', 'Fotografías', 'Audios', 'Videos']}
                            tabpanels={[
                                <Info
                                    key='info'
                                    data={data}
                                />,
                                <Ubication
                                    key='ubication'
                                    data={data}
                                />,
                                <Photos
                                    key='photos'
                                    data={data}
                                    project_id={project_id}
                                />,
                                <Audios
                                    key='audios'
                                    data={data}
                                    project_id={project_id}
                                />,
                                <Videos
                                    key='videos'
                                    data={data}
                                    project_id={project_id}
                                    loading={loading}
                                />,
                            ]}
                        />
                }
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} onClick={onClose} variant='outline-secondary' name='Cerrar' />
            </>}
        />
    </>
    )
}

export default IncidentDetails