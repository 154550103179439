import React from 'react'
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import stylesHeader from './styles_header';
import stylesTable from './styles_table';
import victum_logo from '../../../../imagenes-app/logo.png'
import moment from 'moment';
import _ from 'underscore';
import Axios from 'axios';
import { __SERVER__ } from 'server/info';
import { Variables } from 'variables/Variables';

class PDFReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consumables: [],
            cranes: []
        }
    }

    componentDidMount = async () => {
        await this.getConsumables();
        await this.getCranes();
    }

    getConsumables = async () => {
        let consumables = await Axios.get(`${__SERVER__}/victum_users/project/${Variables.project_id}/road_aid/consumable`);
        if (consumables.status === 200) {
            this.setState({ consumables: consumables.data });
        }
    }

    getCranes = async () => {
        let cranes = await Axios.get(`${__SERVER__}/victum_users/project/${Variables.project_id}/road_aid/crane`);
        if (cranes.status === 200) {
            this.setState({ cranes: cranes.data });
        }
    }

    getCrane = (id) => {
        let result = 0;
        if (id === null) return result;
        let pos = _.findIndex(this.state.cranes, { id });
        if (pos !== -1) result = this.state.cranes[pos].total_amount_paid;
        return result;
    }

    getConsumable = (id) => {
        let result = 0;
        if (id === null) return result;
        let pos = _.findIndex(this.state.consumables, { id });
        if (pos !== -1) result = this.state.consumables[pos].total_amount_paid;
        return result;
    }

    getTotal = (crane_id, consumable_id) => {
        return this.getConsumable(consumable_id) + this.getCrane(crane_id);
    }

    render = () => {
        return (
            <Document>
                <Page style={stylesTable.body} size={"LEGAL"} orientation={"landscape"}>
                    <View style={stylesHeader.header}>
                        <View style={stylesHeader.headerColLeft}>
                            <Image style={stylesHeader.imageSquare} source={victum_logo} />
                        </View>
                        <View style={stylesHeader.headerColCenter}>
                            <Text style={stylesHeader.title}>Costo de atención</Text>
                            <Text style={stylesHeader.subtitle}>{this.props.package.name}</Text>
                        </View>
                        <View style={stylesHeader.headerColRight}>
                            <Image style={stylesHeader.imageSquare} source={victum_logo} />
                        </View>
                    </View>

                    <View style={stylesTable.table}>
                        <View style={stylesTable.tableRowCenter}>
                            <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Costo de atención por reportes</Text>
                            </View>
                        </View>
                        <View style={stylesTable.tableRow}>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Folio</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Fecha</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Costo de grúa</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Costo de consumibles</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Costo total</Text>
                            </View>
                        </View>
                        {
                            this.props.data.length > 0 ?
                                this.props.data.map((item, index) =>
                                    <View key={index} style={stylesTable.tableRow}>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{item.code}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{moment(item.report_date).format('lll')}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>${this.getCrane(item.road_aid_crane_id).toFixed(2)}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>${this.getConsumable(item.road_aid_consumable_id).toFixed(2)}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>${this.getTotal(item.road_aid_crane_id, item.road_aid_consumable_id).toFixed(2)}</Text>
                                        </View>
                                    </View>
                                ) : <Text style={stylesTable.tableCell}>No hay datos para mostrar</Text>
                        }
                    </View>
                </Page>
            </Document >
        )
    }
}

export default PDFReport