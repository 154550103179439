import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@chakra-ui/react';
import { Skeleton } from 'antd';
import { indexAssetsComponents } from 'api/operation_standards/assets_componets';
import usePagination from 'hooks/usePagination';
import PaginationComp from 'components/layouts/Pagination';
import CardComp from './CardComp';

const AssetComponents = ({ component, asset, table }) => {
    const {
        loading,
        data,
        pagination,
        setLoading,
        setData,
        setTotalItems,
        changePage
    } = usePagination({ defaultPageSize: 6 });
    const { page, pageSize } = pagination;

    // Obtener los componetes de los activos por tipo de componente y paginarlos
    useEffect(() => {
        const getComponents = async () => {
            setLoading(true);
            const { response } = await indexAssetsComponents({
                table, asset_id: asset.id, component_type_id: component.id, page, pageSize
            });
            const { data, totalItems } = response;
            setData(data);
            setTotalItems(totalItems);
            setLoading(false);
        }
        getComponents();
    }, [page, pageSize]);

    return (<>
        <Grid
            templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={4}
        >
            {
                loading ? <Skeleton active /> : <>
                    {
                        data.map(item => <CardComp key={item.id} item={item} table={table} />)
                    }
                </>
            }

        </Grid>
        <PaginationComp
            current={pagination.page}
            totalItems={pagination.totalItems}
            defaultPageSize={pagination.pageSize}
            onChange={changePage}
        />
    </>)
}

AssetComponents.propTypes = {
    component: PropTypes.object,
    asset: PropTypes.object,
    table: PropTypes.oneOf([
        'assets_its',
        'assets_environment',
        'assets_edification',
        'assets_infrastructure'
    ]).isRequired,
}

export default AssetComponents