import { faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import SkeletonTable from 'components/layouts/SkeletonTable';
import moment from 'moment';
import React from 'react'

const MaintenanceProgramTable = (props) => {
    const {
        theaders,
        loading,
        data,
        getAssetActivityName,
        actionDelete,
        // actionEdit,
        actionView
    } = props;

    return (
        <table>
            <thead>
                <tr>
                    {
                        theaders.map((item, index) =>
                            <th key={index}>{item}</th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    loading ? <SkeletonTable tr={5} td={theaders.length} /> :
                        data.map((item, index) =>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {/* Actividad que se realizará */}
                                    {
                                        getAssetActivityName(item?.asset_activity?.maintenance_activity_id)
                                    }
                                </td>
                                <td>
                                    {/* Responsable de realizar la actividad */}
                                    {item?.asset_activity?.responsable?.first_name + ' '}
                                    {item?.asset_activity?.responsable?.last_name}
                                </td>
                                <td>
                                    {/* Rango de fechas */}
                                    {
                                        moment(item?.start_date).utc().format('LL') + ' - ' +
                                        moment(item?.end_date).utc().format('LL')
                                    }
                                </td>
                                <td>
                                    {/* Horario en el que se realizará la actividad */}
                                    {
                                        item?.start_time + ' - ' + item?.end_time
                                    }
                                </td>
                                <td>
                                    {/* Fecha en la que fue asignada la actividad */}
                                    {
                                        moment(item?.creation_date).format('LLLL')
                                    }
                                </td>
                                <td>
                                    {/* Operaciones que se pueden realizar */}
                                    {/* <ButtonIcon icon={faEdit} variant='outline-success' onClick={actionEdit} tooltip='Editar programa de mantenimiento' /> */}
                                    <ButtonIcon icon={faEye} variant='outline-primary' onClick={()=>actionView(item)} tooltip='Ver detalles del programa de mantenimiento' />
                                    <ButtonIcon icon={faTrashAlt} variant='outline-danger' onClick={()=>actionDelete(item)} tooltip='Eliminar programa de mantenimiento' />
                                </td>
                            </tr>
                        )
                }
            </tbody>
        </table>
    )
}

export default MaintenanceProgramTable