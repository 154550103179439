import { faBackward, faForward, faPlus, faSave, faTimes, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import React from 'react'

const FooterButtons = (props) => {
    const {
        current,
        stepClass,
        onClose,
        loadingSave,
        enableNext,
        checkedOldInspection,
        data,
        next,
        prev,
        targetKeys,
        incidents,
        save,
        saveInspection,
        leaveInspection,
        closeInspection,
    } = props;
    const { creation_dateS, creation_dateE, inspection_id, responsable_id } = data;
    return (
        <>
            {
                current === stepClass.stepInspection && (
                    <ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' disabled={loadingSave} />
                )
            }
            {
                current > stepClass.stepIncidents && (
                    <ButtonIcon name='Anterior' icon={faBackward} onClick={prev} variant='outline-primary' disabled={loadingSave} />
                )
            }
            {
                current === stepClass.stepIncidents && checkedOldInspection && (
                    <ButtonIcon name='Anterior' icon={faBackward} onClick={prev} variant='outline-primary' disabled={loadingSave} />
                )
            }
            {
                current < stepClass.stepFiles && current > stepClass.stepIncidents && (
                    <ButtonIcon name='Siguiente' icon={faForward} onClick={next} variant='outline-primary' disabled={!enableNext() || loadingSave} />
                )
            }
            {
                current === stepClass.stepInspection && checkedOldInspection && creation_dateS && creation_dateE && inspection_id && (
                    <ButtonIcon name='Siguiente' icon={faForward} onClick={next} variant='outline-primary' disabled={loadingSave} />
                )
            }
            {
                current === stepClass.stepFiles && (
                    <ButtonIcon name='Guardar Incidente' icon={faSave} onClick={save} variant='outline-success' disabled={loadingSave} />
                )
            }
            {
                current === stepClass.stepInspection && targetKeys.length > 0 && !checkedOldInspection && (responsable_id !== '0' && responsable_id) && (
                    <ButtonIcon name='Crear Inspección' icon={faSave} onClick={saveInspection} variant='outline-success' />
                )
            }
            {
                current === stepClass.stepIncidents && incidents.length > 0 && (
                    <ButtonIcon name='Salir' icon={faWindowClose} onClick={leaveInspection} variant='outline-secondary' tooltip='Esta acción le permitirá seguir agregando incidentes a esta inspección.' />
                )
            }
            {
                current === stepClass.stepIncidents && incidents.length > 0 && (
                    <ButtonIcon name='Cerrar Inspección' icon={faWindowClose} onClick={closeInspection} variant='outline-warning' tooltip='Al cerrar la inspección usted no podrá agregar más incidentes a dicha inspección.' />
                )
            }
            {
                current === stepClass.stepIncidents && (
                    <ButtonIcon name='Agregar Incidente' icon={faPlus} onClick={next} variant='outline-success' />
                )
            }
        </>
    )
}

export default FooterButtons