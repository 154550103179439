import fetcher from "api/fetcher";
import { Variables } from "variables/Variables";

const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param {table, inventory_id} props 
 * @returns { data: [], totalItems: 0 }
 */
export const indexInventoryData = async (props) => {
    const {
        table,
        inventory_id,
        page,
        pageSize
    } = props;
    let params = {};
    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;

    const url = `/projects/${Variables.project_id}/${table}/${inventory_id}/data`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === STATUS_OK) {
            const { data, totalItems } = fetch.data;
            response = { data, totalItems };
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const valuesInventoryData = async ({ project_id, column, table }) => {
    let response = [];
    try {
        const url = `/projects/${project_id}/${table}/values`;
        const params = { column };
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const indexValuesInventoryData = async ({ project_id, table, query }) => {
    let response = [];
    const { body, type, side, check_status, damaged } = query;
    let params = {};

    try {
        if (body) params.body = body;
        if (type) params.type = type;
        if (side) params.side = side;
        if (check_status) params.check_status = check_status;
        if (damaged) params.damaged = damaged;
        const url = `/projects/${project_id}/${table}/data`;
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeInventoryData = async ({ project_id, inventory_id, data, table }) => {
    const url = `/projects/${project_id}/${table}/${inventory_id}/data`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: inventory_data.js:95 ~ storeInventoryData ~ fetch", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const updateInventoryData = async ({ project_id, inventory_id, id, data, table }) => {
    const url = `/projects/${project_id}/${table}/${inventory_id}/data/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: inventory_data.js:113 ~ updateInventoryData ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}