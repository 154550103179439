import React from 'react'
import { Empty, Skeleton } from 'antd'
import { Jumbotron } from 'react-bootstrap'

const Jumb = ({empy, text}) => {
    return (
        <Jumbotron className='m-2 p-3'>
            {
                empy
                ? <Empty description={<span>No hay Datos de {text}</span>} />
                : <Skeleton active />
            }
        </Jumbotron>
    )
}

export default Jumb