import fetcher from "api/fetcher";
import { Variables } from "variables/Variables";

const STATUS_OK = 200;

export const getChartIncidents = async (params) => {

    const url = `/charts/packages/${Variables.package_id}/inspections/incidents`;
    let response = [];
    try {
        let fetch = await fetcher({
            url,
            method: 'GET',
            params
        });

        (fetch.status === STATUS_OK ) && (response = fetch.data)
        
        return response;
    } catch (error) {
        return response;
    }
}