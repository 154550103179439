import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "../../variables/Variables";

export const indexPermissions = async ({ package_id }) => {
    const url = `/permissions`;
    let response = [];
    try {
        const { fetch } = await fetcherCancelToken({
            url,
            method: 'GET',
            params: { package_id }
        });
        // console.log("🚀 ~ file: permissions.js:13 ~ indexPermissions ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;

        return { response };
    } catch (error) {
        return { response };
    }
}

export const indexRolePermissions = async ({ user_type_id, package_id }) => {
    const url = `/permissions/users_type`;
    let response = [];
    try {
        const { fetch } = await fetcherCancelToken({
            url,
            method: 'GET',
            params: { user_type_id, package_id }
        });
        // console.log("🚀 ~ file: permissions.js:32 ~ indexRolePermissions ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;

        return { response };
    } catch (error) {
        return { response };
    }
}

export const indexUserPermissions = async ({ user_id }) => {
    const url = `/permissions/users`;
    let response = [];
    try {
        const { fetch } = await fetcherCancelToken({
            url,
            method: 'GET',
            params: { user_id }
        });
        // console.log("🚀 ~ file: permissions.js:51 ~ indexUserPermissions ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response };
    } catch (error) {
        return { response };
    }
}

export const storeRolePermissions = async ({ data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            method: 'POST',
            url: `/permissions/users_type`,
            data
        });
        // console.log("🚀 ~ file: permissions.js:50 ~ storeRolePermissions ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok || fetch.status === httpStatus.created)
            response = true
        return { response }
    } catch (error) {
        return response;
    }
}

export const storeUserPermissions = async ({ data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            method: 'POST',
            url: `/permissions/user`,
            data
        });
        // console.log("🚀 ~ file: permissions.js:85 ~ storeUserPermissions ~ fetch:", fetch)
        
        if (fetch.status === httpStatus.ok || fetch.status === httpStatus.created)
            response = true
        return { response }
    } catch (error) {
        return response;
    }
}

export const indexRoles = async ({ package_id }) => {
    const url = `/packages/${package_id}/roles`;
    let response = [];
    try {
        const { fetch } = await fetcherCancelToken({
            url,
            method: 'GET'
        });
        // console.log("🚀 ~ file: permissions.js:50 ~ indexRoles ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;

        return { response };
    } catch (error) {
        return { response };
    }
}