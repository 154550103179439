import { useDisclosure } from '@chakra-ui/react';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { indexUsersSimple } from 'api/users/users';
import TableComp from 'components/layouts/Table'
import React, { useEffect, useState } from 'react'
import DownloadFileModal from '../modals/HumanResources/DownloadFileModal';

const HumanResourcesTab = (props) => {
	const { package_id, project_id } = props;
	const modal = useDisclosure();

	const [data, setData] = useState([]);
	const [userSel, setUserSel] = useState({});

	useEffect(() => {
		const getUsers = async () => {
			let response = await indexUsersSimple({ package_id, project_id });
			setData(response.data);
		}
		getUsers();
	}, []);

	const openModal = (item) => {
		setUserSel(item);
		modal.onOpen()
	}
	
	return (
		<>
			<TableComp
				headers={['ID', 'Nombre completo', 'Nombre de usuario', 'Correo electrónico', 'Fecha de creación', 'Acciones']}
				keys={['#', 'first_name+last_name', 'username', 'email', 'created_at']}
				body={data}
				actions={[
					{ icon: faFilePdf, variant: 'outline-danger', handleClick: openModal , tooltip: 'Descargar reporte de Recursos Humanos'}
				]}
			/>
			{/* Modal para introducir parámetros de la descarga */}
			{
				modal.isOpen && <DownloadFileModal
					onClose={modal.onClose}
					userSel={userSel}
					package_id={package_id}
				/>
			}
		</>
	)
}

export default HumanResourcesTab