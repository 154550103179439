import fetcherCancelToken from "components/libs/fetcherCancelToken";

const CREATED = 201;
const STATUS_OK = 200;

export const downloadAssetsComponentsFiles = async ({ path_file, table, asset_component_id }) => {
    const url = `/${table}/components/${asset_component_id}/files`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: { path_file }
        });
        // console.log("🚀 ~ file: assets_component_files.js:16 ~ downloadAssetsComponentsFiles ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const uploadAssetsComponentsFiles = async ({ table, asset_component_id, data }) => {
    const url = `/${table}/components/${asset_component_id}/files`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            contentType: 'application/x-www-form-urlencoded',
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: assets_component_files.js:37 ~ uploadAssetsComponentsFiles ~ fetch:", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const destroyAssetsComponentsFiles = async ({ table, asset_component_id, id, file_path }) => {
    const url = `/${table}/components/${asset_component_id}/files/${id}`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE',
            params: {
                id,
                file_path
            }
        });
        // console.log("🚀 ~ file: assets_component_files.js:58 ~ destroyAssetsComponentsFiles ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data
        return { response, cancel };
    } catch (error) {
        return response;
    }
}