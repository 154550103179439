import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

const CREATED = 201;
const STATUS_OK = 200;

export const indexUsers = async ({ package_id, project_id }) => {
    const url = `/packages/${package_id}/users`;
    let response = { data: [], totalItems: 0 };
    let params = {};
    if (project_id) params.project_id = project_id;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}
export const indexUsersSimple = async ({ package_id, project_id }) => {
    const url = `/packages/${package_id}/users/simple`;
    let response = { data: [], totalItems: 0 };
    let params = {};
    if (project_id) params.project_id = project_id;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}

export const storeUsers = async ({ package_id, data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/packages/${package_id}/user`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: users.js:52 ~ storeUsers ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok || fetch.status === httpStatus.created)
            response = fetch.data;
        return { response };
    } catch (error) {
        return { response };
    }
}

export const updateUsers = async ({ package_id, user_id, data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/packages/${package_id}/user/${user_id}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: users.js:70 ~ updateUsers ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok || fetch.status === httpStatus.created)
            response = fetch.data;
        return { response };
    } catch (error) {
        return { response };
    }
}