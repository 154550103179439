import { Col, Row } from 'antd';
import { downloadIncidentFiles } from 'api/maintenance_standards/incident_files';
import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap';

const moment = require('moment');

const Audios = ({ data, project_id }) => {
	const [audios, setAudios] = useState([]);               // Lista de audios
	const [loading, setLoading] = useState(true);           // Indicador de carga
	const [count, setCount] = useState(0);                  // Cantidad de archivos
	const [currentCount, setCurrentCount] = useState(0);

	useEffect(() => {
		let cancelLoop = false;
		let cancelTokens = [];

		const getAudios = async () => {
			let files = data.media.filter((file) => file.type === "audio");
			setCount(files.length);
			const { inspection_id } = data;
			for (const element of files) {
				if (cancelLoop) break;
				const { file_path, inventory_data_id, created_at, incident_id } = element;
				const { response, cancel } = await downloadIncidentFiles({ project_id, inspection_id, incident_id, path: file_path });
				cancelTokens.push({ cancel });
				if (response) {
					setAudios(audios => [...audios, {
						audio: URL.createObjectURL(response),
						created_at: `${moment(created_at).format('LLLL')}`,
						inventory_data_id,
						file_path
					}]);
					setCurrentCount(currentCount => currentCount + 1);
					setLoading(false);
				}
			}
			setLoading(false);
		};
		getAudios();
		return () => {
			cancelLoop = true;
			cancelTokens.forEach((token) => token.cancel());
		}
	}, []);

	return (
		<>
			{
				count > 0 &&
				<p>Obteniendo {currentCount}/{count} audio{count === 1 ? '' : 's'}...</p>
			}
			{
				!loading && audios.map((item, index) =>
					<Row align="middle" key={item.id}>
						<Col span={2}>{index + 1}</Col>
						<Col span={10}>{item.created_at}</Col>
						<Col span={10}>
							<audio key={item.id} src={item.audio} controls />
						</Col>
					</Row>
				)
			}
			{
				!loading && audios.length === 0 &&
				<Alert variant='info'>
					No se encontraron registros de audios.
				</Alert>
			}
		</>
	)
}

export default Audios