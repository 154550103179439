import { gql } from "@apollo/client";

export const MESSAGES_QUERY = gql`
  query MessagesQuery($room_id: Int) {
    messenger_messages(
        order_by: { id: asc }, 
        where: { room_id: { _eq: $room_id } }
    ) {
      id
      message
      created_at
      user_id
    }
  }
`;
