import React, { useState } from 'react'
import { Alert, Button, Col, Form, Jumbotron, Row } from 'react-bootstrap'
import useFetch from 'hooks/useFetch';
import Cards from './Cards'
import { Heading } from '@chakra-ui/react';
const { __SERVER__ } = require("../../../server/info");

const InventoryReports = React.memo(({ package_id, project_id }) => {
    const url = `${__SERVER__}/packages/${package_id.id}/maintenance_standards`;
    const { data, loading, error } = useFetch(url);
    const [getInventories, setInventories] = useState(null)
    const [show, setShow] = useState(true)
    const [inventoryName, setInventoryName] = useState(true)

    const search_estandar = code => {
        const states = {
            'MR-1-DS': 'surface_deterioration_inventory',
            'MR-2-IRI': false,
            'MR-3-PR': false,
            'MR-4-LCA': false,
            'MR-5-EPR': false,
            'MR-6-CF': false,
            'MR-7-T': 'slopes_inventory',
            'MR-8-E': 'structures_inventory',
            'MR-9-ODC': 'drainage_works_inventory',
            'MR-10-SH': 'horizontal_marking_inventory',
            'MR-11-SV': 'vertical_marking_inventory',
            'MR-12-DB': 'defenses_barriers_inventory',
            'MR-13-VDV': false,
            'MR-14-LDV': 'right_way_cleaning_inventory',
            'MR-15-CDV': false,
            'MR-16-V': 'road_lighting_inventory',
            'MR-17-RE': 'emergency_braking_ramps_inventory'
        }
        return states[code];
    }

    const handleGetInventories = async code => {

        let inventoryItemName = search_estandar(code);
        setInventoryName(inventoryItemName);

        try {
            const dataInventories = await fetch(`${__SERVER__}/projects/${project_id}/${inventoryItemName}`);
            const res = await dataInventories.json()
            setInventories(res);
            setShow(false)
        } catch (err) { console.log(err) }
    }

    return (
        <Jumbotron style={{ marginTop: 10, marginLeft: 10 }} >
            <Form>
                <Heading color={'black'}>Reporte de Inventarios</Heading>
                <Row>
                    <Alert variant="info">
                        <Alert.Heading>Información</Alert.Heading>
                        <p> En esta sección se crean los reportes mensuales de Inventarios.</p>
                    </Alert>
                </Row>
                <Row>
                    {
                        (!show && getInventories) && (
                            <>
                                <section className="w-100" ><Button variant="primary" className="ml-1" onClick={() => setShow(true)}>Regresar a la sección anterior</Button></section>
                                <Cards getInventories={getInventories} inventoryName={inventoryName} project_id={project_id} />
                            </>
                        )
                    }
                    {
                        (data && show) && data.data.map((item, i) =>
                            <Button key={item.id} variant="danger" className="m-2" onClick={() => handleGetInventories(item.code)} >{i + 1}. {item.name}</Button>
                        )
                    }
                </Row>
            </Form>
        </Jumbotron>
    )
})

export default InventoryReports