import React, { useEffect, useState } from 'react'
import "./Mantenimiento.css";
import Inventario from './___Inventario___';
import FiltrosBusqueda from './FiltroBusqueda.jsx';
import Incidents from './Incidentes.jsx';
import Deductivas from './Deductivas.jsx';
import Materiales from './Materiales.jsx';
import Materials from './CostWorkOrders/Materials/Index';
import MachineryEquipment from './CostWorkOrders/MachineryEquipment/Index';
import HumanResources from './CostWorkOrders/HumanResources/Index';
import Subcontracts from './Subcontracts/Index';
import IncidentDetails from './Incidents/IncidentDetails';
import WorkOrderModal from './WorkOrderModal';
import Swal from 'sweetalert2'
import Dashboard from './Dashboard';
import Activities from './Activities/Index';
import MaintenanceProgram from './MaintenanceProgram/Index';
import CalendarComp from './Calendar/Index';
import GanttChart from './GanttChart/Index';
import {
    ___INCIDENTES__,
    ___DEDUCTIVAS__,
    ___INVENTARIO__,
    ___TYPE_MODAL,
    ___DASHBOARD___,
    ___ACTIVIDADES_,
    ___PROGRAMA____,
    ___CALENDAR____,
    ___DIAGRAMA____,
    ___RH__________,
    ___MATERIALES__,
    ___MAQEQ_______,
    ___SUBCONT_____
} from './constantes/Interfaces';
import { Heading } from '@chakra-ui/react';
import { allowModule } from 'components/libs/allowModule';
import TabsComp from 'components/layouts/Tabs';
import { indexIncidents } from 'api/maintenance_standards/incidents';

const MaintenaceMainComp = (props) => {
    const { code, name, standard_id, permission, project_id, package_id, component } = props;
    const options = [
        { component: ___DASHBOARD___, permission: permission + 'A' },
        { component: ___INCIDENTES__, permission: permission + 'B' },
        { component: ___DEDUCTIVAS__, permission: permission + 'C' },
        { component: ___INVENTARIO__, permission: permission + 'D' },
        { component: ___ACTIVIDADES_, permission: permission + 'E' },
        { component: ___PROGRAMA____, permission: permission + 'F' },
        { component: ___CALENDAR____, permission: permission + 'G' },
        { component: ___DIAGRAMA____, permission: permission + 'H' },
        { component: ___RH__________, permission: permission + 'I' },
        { component: ___MATERIALES__, permission: permission + 'J' },
        { component: ___MAQEQ_______, permission: permission + 'K' },
        { component: ___SUBCONT_____, permission: permission + 'L' }
    ];
    // indica que las ordednes de trabajo se crean para un incidente
    const is_incident = 1;
    const [id, setId] = useState(0);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [incident, setIncident] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [modalIncidentDetails, setModalIncidentDetails] = useState(false);
    const [modalWorkOrder, setModalWorkOrder] = useState(false);
    const [modalWorkOrders, setModalWorkOrders] = useState(false);
    const [type, setType] = useState(null);
    const [componentActive, setComponentActive] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);

    // Filtrar la lista de pestañas de acuerdo al permiso que se necesite
    useEffect(() => {
        const filteredOptions = options.filter((item) => allowModule(item));
        setFilteredOptions(filteredOptions);
    }, []);
    // Componente activo, es decir, tab seleccionado
    useEffect(() => {
        if (filteredOptions.length > 0) {
            const componentActive = filteredOptions[activeTab].component;
            setComponentActive(componentActive);
        }
    }, [activeTab, filteredOptions]);
    // Cuando se elige una opción de la lista de incidentes, se obtiene el detalle
    useEffect(() => {
        const getIncidentDetails = async () => {
            if (type === ___TYPE_MODAL.___incidente) {
                setModalIncidentDetails(true);
            } else {
                setLoading(true);
                const response = await indexIncidents({ project_id, mode: 'all', id });
                setData(response.data);
                setIncident(null)
                setLoading(false);
            }
        }
        if (type) getIncidentDetails();
    }, [type]);

    useEffect(() => {
        if (data) {
            if (type === ___TYPE_MODAL.___materiales) {
                setIncident(<Materiales datos={data} setThisNull={setNullIncident} successSave={successSave} addWorkOrder={addWorkOrder} />)
            } else if (type === ___TYPE_MODAL.___orden_trabajo) {
                setModalWorkOrder(true);
            } else {
                setNullIncident();
            }
        }
    }, [data]);


    // Método para obtener la pestaña seleccionada
    const onChangeTab = (activeKey) => {
        setActiveTab(parseInt(activeKey));
    }
    const setNullIncident = () => {
        setIncident(null);
        setType(null);
    }

    const openModal = (id, type) => {
        setId(id);
        setType(type);
    }

    const getTable = () => {
        let table = '';
        switch (component) {
            case 'COMP_MR_xxxxCDV': table = 'right_way_inventory'; break;               // Cercado de derecho de vía
            case 'COMP_MR_xxxxLDV': table = 'right_way_cleaning_inventory'; break;      // Limpieza de DV
            case 'COMP_MR_xxxxODC': table = 'drainage_works_inventory'; break;          // Drenajes
            case 'COMP_MR_xxxxTER': table = 'embankments_inventory'; break;             // Terraplenes
            case 'COMP_MR_xxxxxDB': table = 'defenses_barriers_inventory'; break;       // Defensas y barreras
            case 'COMP_MR_xxxxxDS': table = 'surface_deterioration_inventory'; break;   // Deterioros    
            case 'COMP_MR_xxxxxIV': table = 'road_lighting_inventory'; break;           // Iluminación vial
            case 'COMP_MR_xxxxxRE': table = 'emergency_braking_ramps_inventory'; break; // Rampas
            case 'COMP_MR_xxxxxSH': table = 'horizontal_marking_inventory'; break;      // Señalamiento horizontal
            case 'COMP_MR_xxxxxSV': table = 'vertical_marking_inventory'; break;        // Señalamiento vertical
            case 'COMP_MR_xxxxxxE': table = 'structures_inventory'; break;              // Estructuras
            case 'COMP_MR_xxxxxxT': table = 'slopes_inventory'; break;                  // Taludes
            default: break;
        }
        return table;
    }
    const addWorkOrder = () => setModalWorkOrder(true);

    const getComponent = (type) => {
        switch (type) {
            case ___DASHBOARD___: return <Dashboard project_id={project_id} component={component} />;
            case ___INCIDENTES__: return <Incidents openModal={openModal} project_id={project_id} package_id={package_id} maintenance_standard_id={standard_id} component={component} table={getTable()} />;
            case ___DEDUCTIVAS__: return <Deductivas openModal={openModal} project_id={project_id} package_id={package_id} standard_id={standard_id} />;
            case ___INVENTARIO__: return <Inventario component={component} />;
            case ___ACTIVIDADES_: return <Activities project_id={project_id} package_id={package_id} maintenance_standard_id={standard_id} code={component} />;
            case ___PROGRAMA____: return <MaintenanceProgram project_id={project_id} package_id={package_id} maintenance_standard_id={standard_id} />;
            case ___CALENDAR____: return <CalendarComp project_id={project_id} standard_id={standard_id} />;
            case ___DIAGRAMA____: return <GanttChart />;
            case ___RH__________: return <HumanResources package_id={package_id} project_id={project_id} standard_id={standard_id} />;
            case ___MATERIALES__: return <Materials package_id={package_id} project_id={project_id} standard_id={standard_id} />;
            case ___MAQEQ_______: return <MachineryEquipment package_id={package_id} project_id={project_id} standard_id={standard_id} />;
            case ___SUBCONT_____: return <Subcontracts />;
            default: return null;
        }
    }

    const successSave = () => {
        Swal.fire('Success!', 'La orden de trabajo se generó correctamente!', 'success')
        setIncident(<Materiales datos={data} setThisNull={setNullIncident} addWorkOrder={addWorkOrder} />)
    }

    const onCloseWorkOrder = () => {
        setModalWorkOrder(false);
        setType(null);
    }
    const onCloseIncidentDetails = () => {
        setModalIncidentDetails(false);
        setType(null);
    }
    const newOrder = () => {
        setModalWorkOrders(false);
        setModalWorkOrder(true);
    }
    return (
        <div className="body_" style={{ width: '100%' }}
        // style={{ position: 'fixed' }}
        >
            <Heading className="tituloSub">{code + " " + name}</Heading>

            {/* Mostrar filtros de búsqueda sólo para los componentes de deductivas e incidentes */}
            {
                (componentActive === ___INCIDENTES__ || componentActive === ___DEDUCTIVAS__) &&
                <FiltrosBusqueda standard_id={standard_id} type={componentActive} />
            }
            <div style={{ width: '100%' }}>
                <TabsComp
                    onChange={onChangeTab}
                    activeKey={activeTab}
                    tablist={[
                        ...filteredOptions
                            .map((opt) => opt.component)
                    ]}
                    tabpanels={[
                        ...filteredOptions
                            .map((opt) => getComponent(opt.component))
                    ]}
                />
            </div>
            {/* Modal en el que se mostrarán los incidentes, orden de trabajo o materiales */}
            {
                incident
            }
            {/* Modal para mostrar los detalles del incidente */}
            {
                modalIncidentDetails && <IncidentDetails
                    onClose={onCloseIncidentDetails}
                    id={id}
                    project_id={project_id}
                    title={code + " " + name}
                />
            }
            {/* Modal para crear una orden de trabajo */}
            {
                modalWorkOrder && <WorkOrderModal
                    onClose={onCloseWorkOrder}
                    segment_id={data[0]?.segment?.id || null}
                    standard_id={standard_id}
                    package_id={package_id}
                    eventSel={data[0]}
                    // Abrir modal de materiales al finalizar una orden de trabajo
                    viewOrders={successSave}
                    is_incident={is_incident}
                />
            }
            {/* Modal para ver las órdenes de compra disponibles */}
            {/* {
                modalWorkOrders && <WorkOrdersModal
                    onClose={() => setModalWorkOrders(false)}
                    dataDetails={data[0]}
                    loading={loading}
                    package_id={package_id}
                    segment_id={data[0]?.segment?.id}
                    standard_id={standard_id}
                    project_id={project_id}
                    newOrder={newOrder}
                    updateStatusCalendar={() => { }}
                />
            } */}
        </div>
    )
}

export default MaintenaceMainComp