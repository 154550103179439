import React, { useState, useEffect } from 'react';
import { faBackward, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from 'components/layouts/ButtonIcon'
import ModalComp from 'components/layouts/Modal'
import PaginationComp from 'components/layouts/Pagination'
import Swal from 'sweetalert2';
import TableComp from 'components/layouts/Table'
import { indexSuppliesPumServices } from 'api/maintenance_standards/supplies_pum_services';
import { getSegments } from 'api/general/getSegments';
import Filters from './Filters';
import AssignSupply from './AssignSupply';
import { storeCostWorkOrder } from 'api/maintenance_standards/cost_work_order';

const AddCostWorkOrder = ({ onClose, package_id, type_description_id, standard_id, syncServer }) => {

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const [filters, setFilters] = useState({});

    const [segments, setSegments] = useState([]);
    const [supplySel, setSupplySel] = useState(null);
    const [form, setForm] = useState({});

    useEffect(() => {
        if (filters.str === '') delete filters.str;
    }, [filters]);

    useEffect(() => {
        const indexSegments = async () => {
            const response = await getSegments();
            setSegments(response);
            if (response.length > 0) {
                setForm(prevState => ({ ...prevState, segment_id: response[0].id }));
            }
        }
        indexSegments();
    }, []);

    useEffect(() => {
        getSuppliesPumServices();
    }, [page, pageSize]);

    const getSuppliesPumServices = async () => {
        setLoading(true);
        const { response } = await indexSuppliesPumServices({ package_id, type_description_id, page, pageSize, ...filters });
        if (response) {
            setData(response.data);
            setTotalItems(response.totalItems);
        }
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page); setPageSize(pageSize);
    }

    // Agregar elemento de supplies_pum_services a cost_work_orders
    const addItem = (item) => setSupplySel(item);
    // Seleccionar de nuevo un elemento
    const goBack = () => setSupplySel(null);
    // Validar formulario
    const validate = () => {
        if (!form.assign) {
            Swal.fire('Verifique los datos', 'Debe asignar una cantidad', 'warning');
            return false;
        }
        if (form?.assign > supplySel.count) {
            Swal.fire('Verifique los datos', 'La cantidad debe ser menor a la disponible', 'warning');
            return false;
        }
        return true;
    }
    // Asignar recurso
    const save = async () => {
        if (validate()) {
            setLoadingSave(true);
            const payload = {
                package_id,
                standard_id,
                segment_id: form.segment_id,
                count: form.assign,
                code_supplie: supplySel.code
            };
            const { response } = await storeCostWorkOrder({ data: payload });
            if (response) {
                Swal.fire('¡Operación exitosa!', 'El recurso ha sido agregado de manera satisfactoria', 'success');
                syncServer();
                onClose();
            } else {
                Swal.fire('¡Operación fallida!', 'Error al realizar la asignación de recursos. Verifique los datos', 'error');
            }
            setLoadingSave(false);
        }
    }

    return (
        <ModalComp
            title='Agregar recurso'
            size='xl'
            onClose={onClose}
            body={<>
                {
                    supplySel ? <>
                        {/* Cantidad disponible que se puede asignar */}
                        <AssignSupply
                            supplySel={supplySel}
                            segments={segments}
                            form={form}
                            setForm={setForm}
                            loadingSave={loadingSave}
                        />
                    </> : <>
                        {/* Filtros de búsqueda */}
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            searchStr={getSuppliesPumServices}
                        />
                        {/* Tabla de elementos */}
                        <div className='supplies_pum_services_table'>
                            <TableComp
                                headers={['ID', 'Código', 'Descripción', 'Unidad', 'Disponible', 'PU', 'Acciones']}
                                keys={['id', 'code', 'description', 'unit', 'count', 'cost']}
                                body={data}
                                loading={loading}
                                loadingItems={pageSize}
                                page={page}
                                actions={[
                                    { icon: faPlus, tooltip: 'Agregar', variant: 'outline-success', handleClick: addItem }
                                ]}
                            />
                        </div>
                        {/* Paginación */}
                        <PaginationComp
                            totalItems={totalItems}
                            defaultPageSize={pageSize}
                            current={page}
                            onChange={changePage}
                        />
                    </>
                }
            </>}
            footer={<>
                {
                    supplySel ? <>
                        <ButtonIcon icon={faBackward} name='Regresar' variant='outline-secondary' onClick={goBack} />
                        <ButtonIcon icon={faSave} name='Asignar recurso' variant='outline-success' onClick={save} disabled={loadingSave}/>
                    </> :
                        <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                }
            </>}
        />
    )
}

export default AddCostWorkOrder