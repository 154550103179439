import React, { useEffect, useState } from 'react';
import { downloadInventoryFiles } from 'api/maintenance_standards/inventory_files';
import TableComp from 'components/layouts/Table';
import { faFileDownload, faFilePdf, faPlus, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PDFViewerComp from 'components/layouts/PDFViewer';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { Col, Collapse, Row } from 'antd';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const { Panel } = Collapse;

const InventoryFiles = (props) => {
    const { project_id, table, data, loading, deleteFile, storeFile } = props;

    const [preview, setPreview] = useState(false);
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);                 // Archivo para cargar

    useEffect(() => {
        getFiles();
    }, []);

    // Manejador de carga de archivos
    const handleLoadFile = (event) => {
        if (event.target.files) {
            setFileUpload(event.target.files[0]);
        }
    }
    // Método para subir el archivo
    const handleUploadClick = () => {
        if (!fileUpload) {
            Swal.fire('¡Sin archivos!', 'Necesita elegir un archivo.', 'warning');
            return;
        }
        const formData = new FormData();
        formData.append('file', fileUpload);
        storeFile({ data: formData, file_type: 'document' });
    };

    const getFiles = () => {
        setFiles(data.filter((item) => item.file_type === 'document'));
    }
    const getFile = async (item) => {
        const { file_path, inventory_data_id } = item;
        return downloadInventoryFiles({ project_id, table: `${table}_files`, id: inventory_data_id, file_path });
    }
    const handleClick = async (item) => {
        const { file_path } = item;
        const { response } = await getFile(item);
        if (response) {
            const fileURL = URL.createObjectURL(response);
            const link = document.createElement('a')
            link.href = fileURL
            link.setAttribute('download', file_path)
            document.body.appendChild(link)
            link.click();
        }
    }

    const viewPDF = async (item) => {
        setPreview(true);
        const { response } = await getFile(item);
        if (response) setFile(response);
    }

    return (
        <>
            {
                preview ? <>
                    <ButtonIcon name='Cerrar vista previa' icon={faTimes} onClick={() => setPreview(false)} variant='link' />
                    <PDFViewerComp file={file} />
                </> : <div className="body_">
                    <TableComp
                        headers={['#', 'Archivo', 'Tipo', 'Acciones']}
                        keys={['#', 'file_path', 'file_type']}
                        body={files}
                        loading={loading}
                        actions={[
                            { icon: faFileDownload, tooltip: 'Descargar archivo', variant: 'outline-primary', handleClick },
                            { icon: faFilePdf, tooltip: 'Vista previa', variant: 'outline-success', handleClick: viewPDF },
                            { icon: faTrashAlt, tooltip: 'Eliminar archivo', variant: 'outline-danger', handleClick: deleteFile },
                        ]}
                    />
                </div>
            }
            {/* Agregar pdf */}
            <Collapse>
                <Panel header="Agregar PDF" key="1">
                    <Row align='middle'>
                        <Col span={20}>
                            <Form.Group className="mb-3">
                                <Form.Label>Cargar PDF</Form.Label>
                                <Form.Control type="file" size="sm" accept='application/pdf' onChange={handleLoadFile} />
                            </Form.Group>
                        </Col>
                        <Col span={4}>
                            <ButtonIcon name='Agregar' icon={faPlus} variant='success' tooltip='Agregar' onClick={handleUploadClick} />
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </>
    )
}

export default InventoryFiles;