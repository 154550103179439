import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import "./AbonoIngresosCuotaPeaje.css"
import { __SERVER__ } from '../../../../server/info';
import Pagination from '../Pagination';
import FAB from '../../../generales/FAB.jsx';
import { Variables } from '../../../../variables/Variables';
import ModalUpload from '../Modal.jsx';
import './Operacion.css';
import './Resultados.css';
import Deductiva from '../Deductiva.jsx';
import Axios from 'axios';
import { Heading } from '@chakra-ui/react';

const MySwal = withReactContent(Swal);
const _ = require('underscore');


const FiltroBusqueda = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				autocontrol_dateS: "2019-11-01",
				autocontrol_dateE: "2019-11-02"
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: ""
		};
	}

	generateQuery = () => {
		if (this.state.query.autocontrol_dateE === "" || this.state.query.autocontrol_dateS === "") {
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "0") {
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
		this.props.setProps(this.state.query_, headersTable)
	}

	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="autocontrol_dateS" value={this.state.query.autocontrol_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="autocontrol_dateE" value={this.state.query.autocontrol_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const Grid = class extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.data_per_page}</label>
				</section>
				<table>
					<tr>
						<th>Código</th>
						<th>Fecha de autocontrol</th>
						<th>Fecha de depósito</th>
						<th>Monto</th>
						<th>Fecha de faltante</th>
						<th>Faltante</th>
					</tr>
					{
						this.props.data.data.map((item, i) =>
							<tr key={i}>
								<td>{item.code}</td>
								<td>{item.autocontrol_date.split('T')[0]}</td>
								<td>{item.deposit_date.split('T')[0]}</td>
								<td>{item.amount}</td>
								<td>{item.missing_date.split('T')[0]}</td>
								<td>{item.missing_amount}</td>
							</tr>)
					}
				</table>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
			</div>
		);
	}
}

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalUploadData: false,
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			refresh: false,
			page: 1,
			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false,
			buttonVisible: false
		}
	}

	setProps = (query, headersTable) => {
		this.setState({ page: 1, query: query, result: [], headersTable: headersTable }, this.getDeposits)
	}

	updatePage = (page) => {
		this.setState({ page }, this.getDeposits);
	}

	generateTable = () => {
		let data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];
		this.setState({
			grid: null
		}, () => {
			if (data !== undefined) {
				this.setState({ grid: <Grid updatePage={this.updatePage} data={data} /> });
			}
		});
	}

	getDeposits = () => {
		let index = _.findIndex(this.state.result, { page: this.state.page });
		if (index !== -1) {
			this.generateTable();
			return;
		}
		let query = this.state.query + (this.state.query === '' ? `?page=${this.state.page}` : `&page=${this.state.page}`);
		fetch(__SERVER__ + "/projects/" + this.props.project_id + "/deposits" + query, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					this.state.result.push(result);
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			Swal.fire('Error', e, 'error');
			console.log('Error', e)
		})
	}

	componentDidMount = async () => {
		// GET operating_indicator_id
		let res = await Axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')
		
		if (res.data.length!==0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	openOrCloseModal = () => {
		this.setState({ modalUploadData: !this.state.modalUploadData });
	}

	openOrCloseModalDeductivas = () => {
		this.setState({ modalDeductivas: !this.state.modalDeductivas });
	}

	render() {
		return (
			<div className="body_">
				<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
				<FiltroBusqueda setProps={this.setProps} />
				{this.state.grid}
				{
					this.state.buttonVisible ?
						<FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faCarCrash'} onClick={this.openOrCloseModalDeductivas} /> : null
				}
				<FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />

				{
					this.state.modalUploadData ?
						<ModalUpload
							server={`${__SERVER__}/projects/${Variables.project_id}/deposits`}
							items={[
								{ label: 'CÓDIGO', description: 'Código', value: 'code', type: 'Number' },
								{ label: 'FECHA AUTOCONTROL', description: 'Fecha de autocontrol', value: 'autocontrol_date', type: 'String', toFormat: 'Date' },
								{ label: 'FECHA ABONO PEAJE', description: 'Fecha de abono del peaje', value: 'deposit_date', type: 'String', toFormat: 'Date' },
								{ label: 'IMPORTE ABONO PEAJE', description: 'Importe del abono de peaje', value: 'amount', type: 'Number' },
								{ label: 'FECHA ABONO FALTANTES', description: 'Fecha de los abonos de faltantes', value: 'missing_date', type: 'String', toFormat: 'Date' },
								{ label: 'IMPORTE ABONO FALTANTES', description: 'Importe de abonos de faltantes', value: 'missing_amount', type: 'Number' }
							]}
							title={`${this.props.code} ${this.props.name}`}
							onClose={this.openOrCloseModal} />
						: null
				}
				{
					this.state.modalDeductivas ?
						<Deductiva
							title={this.props.code}
							formula={this.state.formula}
							pum={this.state.PUMObject}
							cump={this.state.CumplObject}
							formulaCump={this.state.formulaCump}
							formulaPUM={this.state.formulaPUM}
							hayFormula={this.state.hayFormula}
						/> : null
				}
			</div>
		);
	}
}


