import React from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { __SERVER__ } from 'server/info';
import Swal from 'sweetalert2';
import Axios from 'axios';

class Assets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            uploading: false,
            folio: this.props.asset.folio,
            description: this.props.asset.description,
            brand: this.props.asset.brand,
            model: this.props.asset.model,
            serial: this.props.asset.serial,
            status: this.props.asset.status,
            produced: this.props.asset.produced,
            verified: this.props.asset.verified,
            observations: this.props.asset.observations
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    saveAsset = async () => {
        await Axios.put(__SERVER__ + `/projects/toll_management_assets/${this.props.asset.id}`, {
            folio: this.state.folio,
            description: this.state.description,
            brand: this.state.brand,
            model: this.state.model,
            serial: this.state.serial,
            status: this.state.status,
            produced: this.state.produced,
            verified: this.state.verified,
            observations: this.state.observations,
            maintenance_sections_id: this.props.asset.maintenance_sections_id
        }).then((response) => {
            console.log(response)
        });
        this.handleClose();
    }

    deleteAsset = () => {
        this.handleClose();
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    render = () => {
        return (
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.asset.folio}-{this.props.asset.description}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <center>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Folio</Form.Label>
                                    <input type="number" name="folio" defaultValue={this.state.folio} className="form-control" onChange={e => {
                                        this.setState({ folio: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Descripción</Form.Label>
                                    <input type="text" name="description" defaultValue={this.state.description} className="form-control" onChange={e => {
                                        this.setState({ description: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Marca</Form.Label>
                                    <input type="text" name="brand" defaultValue={this.state.brand} className="form-control" onChange={e => {
                                        this.setState({ brand: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Modelo</Form.Label>
                                    <input type="text" name="model" defaultValue={this.state.model} className="form-control" onChange={e => {
                                        this.setState({ model: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Serial</Form.Label>
                                    <input type="text" name="serial" defaultValue={this.state.serial} className="form-control" onChange={e => {
                                        this.setState({ serial: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Estado</Form.Label>
                                    <input type="text" name="status" defaultValue={this.state.status} className="form-control" onChange={e => {
                                        this.setState({ status: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Producido</Form.Label>
                                    <input type="text" name="produced" defaultValue={this.state.produced} className="form-control" onChange={e => {
                                        this.setState({ produced: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Verificado</Form.Label>
                                    <input type="text" name="verified" defaultValue={this.state.verified} className="form-control" onChange={e => {
                                        this.setState({ verified: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Label>Observaciones</Form.Label>
                                <textarea
                                    className="form-control" rows={3}
                                    defaultValue={this.state.observations}
                                    onChange={e => {
                                        this.setState({ observations: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true : false} />
                            </Form.Row>
                        </Form>
                    </center>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.action === 'edit' ?
                            <Button variant="primary" onClick={this.saveAsset}>Guardar cambios</Button> : null
                    }

                    {
                        this.props.action === 'view' ?
                            <Button variant="secondary" onClick={this.handleClose}>Cerrar</Button> : null
                    }

                    {
                        this.props.action === 'delete' ?
                            <Button variant="danger" onClick={this.deleteAsset}>Eliminar bien</Button> : null
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}

export default Assets;