import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const showMaintenanceStandardDashboard = async ({ project_id, component }) => {
    let response = false;
    try {
        let fetch = await fetcher({
            method: 'GET',
            url: `/projects/${project_id}/maintenance_dashboards/${component}`,
        });
        // console.log("🚀 ~ file: maintenance_standards.js:15 ~ showMaintenanceStandardDashboard ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}