import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { Form } from 'react-bootstrap';
import { google_maps_key } from '../../../../../server/info';

const mapStyles = {
    width: '100%',
    height: '100%'
};

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            initialCenter: {
                lat: 18.459202,
                lng: -97.398165
            },
            center: {}
        }
    }

    componentDidMount = () => {
        // Si initial center viene en las propiedades
        if (this.props.initialCenter !== undefined) {
            this.setState({
                initialCenter: this.props.initialCenter,
            });
        }
        if (this.props.center !== undefined) {
            this.setState({
                center: this.props.center,
            });
        }
    }

    onMarkerClick = (props, marker, e) => {
        // console.log("marker click: \nprops: ", props, "\nmarker: ", marker, "\ne: ", e);
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };


    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    render = () => {
        // console.log("MAP [state]: ", this.state)
        return (
            <Map google={this.props.google}
                style={mapStyles}
                className={'map'}
                zoom={18}
                initialCenter={this.state.initialCenter}
                center={this.state.center}
                onClick={this.onMapClicked}
            >
                {
                    this.props.ubications.map((item, i) =>
                        <Marker
                            key={`marker-${i}`}
                            title={item.title}
                            name={item.name}
                            onClick={this.onMarkerClick}
                            position={{ lat: item.position.lat, lng: item.position.lng }}
                        />
                    )
                }
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}>
                    <Form>
                        <h1 style={{ color: 'black' }}>{this.state.selectedPlace.title}</h1>
                        <p id={'name_id'}>{this.state.selectedPlace.name}</p>
                        <Form.Text id="name_id" muted>
                        </Form.Text>
                    </Form>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: google_maps_key
})(MapContainer);