import moment from 'moment';
import './BitacoraOnline.css';
import { Tabs, Switch } from 'antd';
import Modal from '../layouts/Modal';
import Table from 'react-bootstrap/Table';
import { __SERVER__ } from '../../server/info';
import FlexButton from '../layouts/FlexButton';
import PDFViewerComp from '../layouts/PDFViewer';
import React, { useEffect, useState } from 'react';
import { sendEmailReleases } from 'api/releases/releases';
import { DownloadIcon, ViewIcon, WarningIcon } from '@chakra-ui/icons';
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import PaginationComp from 'components/layouts/Pagination';

const FiltroBusqueda = React.memo(({ endSearch, setReleases, setDisableButton, disableButton, releases, topics, loadTopics, setReleasesSearched, setSearch }) => {

	const [dataSearch, setDataSearch] = useState({
		topic: " ",
		status: " ",
		first_date: " ",
		last_date: " "
	})

	const handleInputChange = e => {
		const { name, value } = e.target;
		setDataSearch({
			...dataSearch,
			[name]: value
		})
	}

	const handleSearch = () => {
		if (dataSearch.first_date === ' ' || dataSearch.last_date === ' ') {
			alert('Debe de ingresar un rango de fechas');
			/* setToast("error", "Debe de ingresar un rando de fechas"); */

		} else {
			let releasesSearched = [];
			setDisableButton(true)
			setReleasesSearched(releases);
			setSearch(true);
			for (const element of releases) {
				const result = () => {
					let resul = false;
					if (moment(dataSearch.first_date).isSameOrBefore(element.created_at) && moment(dataSearch.last_date).isSameOrAfter(element.created_at)) {
						resul = true;
					}
					return resul;
				}

				if (dataSearch.topic === ' ' && dataSearch.status !== ' ') {
					((element.status === dataSearch.status) && result()) && releasesSearched.push(element);
				}

				if (dataSearch.status === ' ' && dataSearch.topic !== ' ') {
					((element.title === dataSearch.topic) && result()) && releasesSearched.push(element);
				}

				if (dataSearch.status === ' ' && dataSearch.topic === ' ') {
					(result()) && releasesSearched.push(element);
				}

				if (dataSearch.status !== ' ' && dataSearch.topic !== ' ') {
					(((element.status === dataSearch.status) && (element.title === dataSearch.topic)) && result()) && releasesSearched.push(element);

				}
			}
			setReleases(releasesSearched);
		}
	}

	return (
		<section className="sectionSearch">
			<article>
				<label>Fecha inicial</label>
				<input onChange={handleInputChange} type='date' name="first_date" />
			</article>
			<article>
				<label>Fecha final</label>
				<input onChange={handleInputChange} type='date' name="last_date" />
			</article>
			<article>
				<label>Tópico</label>
				<select onChange={handleInputChange} name="topic" >
					<option value=" ">--Elige un tópico--</option>
					{
						(!loadTopics) && (
							topics.map(({ id, name }) => {
								return (
									<option key={id} value={name}>
										{name}
									</option>
								)
							})
						)
					}
				</select>
			</article>
			<article>
				<label>Estatus</label>
				<select onChange={handleInputChange} name="status">
					<option value=" ">--Elige un estatus--</option>
					<option value="open">Abierto</option>
					<option value="expired">Vencido</option>
					<option value="closed">Cerrado</option>
				</select>
			</article>
			{
				(!disableButton) ? (
					<article>
						<button className="botonBusqueda" onClick={() => handleSearch()}>
							<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
								</path>
							</svg>
						</button>
					</article>
				) : (<Button onClick={endSearch} variant="success">Finalizar Busqueda</Button>)
			}
		</section>
	);
})

const FormModal = React.memo(({ setFile, usersPackage, arrayIds, loading, loadTopics, topics, project_id, setreload, reload, setShow }) => {

	const [data, setData] = useState({
		title: "",
		responsible_id: 0,
		supervisor_id: 0,
		reviewer_id: 0,
		body: "",
		duration: 1,
		duration_type: ""
	})
	const [fileLoaded, setfileLoaded] = useState(null)

	const subirArchivo = e => {
		if (e.target.files.length !== 0) {
			setFile(URL.createObjectURL(e.target.files[0]));
			setfileLoaded(e.target.files[0])
		}
	}

	const handleInputChange = e => {
		const { name, value } = e.target;
		setData({
			...data,
			[name]: value
		})
	}

	const validateRelease = () => {
		let valueNull = Object.keys(data).find(key => data[key] === null || Number.isNaN(data[key]));
		return valueNull ? false : true
	}

	const handleSubmit = async e => {
		e.preventDefault();
		if (validateRelease()) {
			let _idRelease = 0;
			await fetch(`${__SERVER__}/projects/${project_id}/releases`, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				method: "POST",
				body: JSON.stringify(data)
			})
				.then(async (res) => {
					setreload(reload + 1);
					_idRelease = await res.json()
				})
				.catch(function (res) { console.log(res) })

			setShow(false)

			const formData = new FormData();
			formData.append("file", fileLoaded);
			await fetch(`${__SERVER__}/projects/${project_id}/releases/${_idRelease[0].id}/upload`, {
				method: "POST",
				body: formData
			})
				.then((res) => {
					console.log("File Upload success");
				})
				.catch((err) => alert("File Upload Error"));

			for (const item of arrayIds) {
				const user_id = { user_id: item }
				await fetch(`${__SERVER__}/releases/${_idRelease[0].id}/users`, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					method: "POST",
					body: JSON.stringify(user_id)
				})
					.then((res) => { console.log(res); })
					.catch(function (res) { console.log(res) })
			}
			// End point para mandar los correos a los destinatarios
			sendEmailReleases({ project_id, release_id: _idRelease[0].id });
		} else {
			alert('Debe completar los datos correctamente')
		}
	}

	return (
		<Container>
			<Form onSubmit={handleSubmit} >
				<Form.Row>
					<Form.Group as={Col} controlId="formname">
						<Form.Label className="label">Tipo de tópico</Form.Label>
						{
							(!loadTopics && topics) ? (
								<Form.Control as="select"
									name="title"
									onChange={handleInputChange}
								>
									<option value="">-- Elige un tópico --</option>
									{
										topics.map(({ id, name }) => {
											return (
												<option key={id} value={name}>
													{name}
												</option>
											)
										})
									}
								</Form.Control>
							) : (<h5>Cargando tópicos...</h5>)
						}
					</Form.Group>

					<Form.Group as={Col} controlId="responsable">
						<Form.Label className="label">Responsable</Form.Label>
						{
							(!loading) ? (
								<Form.Control as="select"
									name="responsible_id"
									onChange={handleInputChange}
								>
									<option value="0">-- Elige un responsable --</option>
									{
										usersPackage.map(({ id, first_name, last_name }) => {
											return (
												<option key={id} value={id}>
													{`${first_name} ${last_name}`}
												</option>
											)
										})
									}
								</Form.Control>
							) : (<h5>Cargando responsables...</h5>)
						}
					</Form.Group>
				</Form.Row>

				<Form.Row>
					<Form.Group as={Col} controlId="supervisor_id">
						<Form.Label className="label">Supervisor</Form.Label>
						{
							(!loading) ? (
								<Form.Control as="select"
									name="supervisor_id"
									onChange={handleInputChange}
								>
									<option value="0">-- Elige un supervisor --</option>
									{
										usersPackage.map(({ id, first_name, last_name }) =>
											<option key={id} value={id}>
												{`${first_name} ${last_name}`}
											</option>
										)
									}
								</Form.Control>
							) : (<h5>Cargando supervisores...</h5>)
						}
					</Form.Group>
					<Form.Group as={Col} controlId="reviewer_id">
						<Form.Label className="label">Revisor</Form.Label>
						{
							(!loading) ? (
								<Form.Control as="select"
									name="reviewer_id"
									onChange={handleInputChange}
								>
									<option value="0">-- Elige un revisor --</option>
									{
										usersPackage.map(({ id, first_name, last_name }) =>
											<option key={id} value={id}>
												{`${first_name} ${last_name}`}
											</option>
										)
									}
								</Form.Control>
							) : (<h5>Cargando revisores...</h5>)
						}
					</Form.Group>
				</Form.Row>

				<Form.Row>
					<Form.Row>
						<Form.Group as={Col} controlId="duracion">
							<Form.Label className="label">Duración</Form.Label>
							<Form.Control
								type="number"
								name="duration"
								required
								autoComplete='off'
								min="1"
								onChange={handleInputChange}
								value={data.duration}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="days">
							<Form.Label className="label">Tipo de duración</Form.Label>
							<Form.Control as="select"
								name="duration_type"
								onChange={handleInputChange}
							>
								<option value="">-- Elige una duración --</option>
								<option value="minutes">Minutos</option>
								<option value="hours">Horas</option>
								<option value="days">Días</option>
								<option value="weeks">Semanas</option>
								<option value="months">Meses</option>
								<option value="years">Años</option>
							</Form.Control>
						</Form.Group>

					</Form.Row>

					<Form.Group as={Col} controlId="file">
						<Form.Label className="label">Subir documento</Form.Label>
						<Form.Control
							type="file"
							name="file"
							accept="application/pdf"
							multiple
							required
							onChange={subirArchivo}
						/>
					</Form.Group>
				</Form.Row>

				<Form.Row>
					<Form.Group as={Col} controlId="body">
						<Form.Label className="label">Descripción</Form.Label>
						<Form.Control
							as="textarea"
							type="text"
							name="body"
							required
							autoComplete='off'
							onChange={handleInputChange}
							value={data.body}
						/>
					</Form.Group>
				</Form.Row>

				<Button type="submit" variant="primary">Guardar</Button>
			</Form>
		</Container>
	);
})

const Recipients = React.memo(({ loading, usersPackage, arrayIds }) => {

	return (
		(!loading) ? (
			<Table striped bordered hover size="sm">
				<thead>
					<tr>
						<th>#</th>
						<th>Nombre</th>
						<th>Rol</th>
						<th>Enviar</th>
					</tr>
				</thead>
				<tbody>
					{
						usersPackage.map(({ id, first_name, last_name, position }, i) => {
							return (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{`${first_name} ${last_name}`}</td>
									<td>{position}</td>
									<td><Switch onChange={(checked) => {
										(checked) ? (
											arrayIds.push(id)
										) : (arrayIds = arrayIds.filter(item => item !== id))
									}} unCheckedChildren="Enviar" /></td>
								</tr>
							)
						})
					}
				</tbody>
			</Table>
		) : (<h1>Cargando destinatarios...</h1>)
	)
})

const ButtonAction = React.memo(({ actionData, handleDownloadFile, handleShowPDF, setviews, closeButton }) => {
	const handleView = () => {
		closeButton();
		setviews(true)
	}
	return (
		<Row>
			<Col className='d-flex flex-column justify-content-center align-items-center' >
				<p style={{ color: 'black' }}>Ver Documento</p>
				<Button size={"lg"} className='m-1' variant="outline-primary" onClick={() => handleShowPDF(actionData.id)}>
					<ViewIcon />
				</Button>
			</Col>
			<Col className='d-flex flex-column justify-content-center align-items-center' >
				<p style={{ color: 'black', textAlign: "center" }}>Descargar Documento</p>
				<Button size={"lg"} variant="outline-success" onClick={() => handleDownloadFile(actionData.id, actionData.title, actionData.responsible_id)}>
					<DownloadIcon />
				</Button>
			</Col>
			<Col className='d-flex flex-column justify-content-center align-items-center' >
				<p style={{ color: 'black' }}>Ver receptores</p>
				<Button size={"lg"} className='m-1' variant="outline-primary" onClick={handleView}>
					<WarningIcon />
				</Button>
			</Col>
		</Row>
	)
})

const Views = React.memo(({ actionData, usersPackage }) => {

	let names = [];
	let viewed = [];
	let noViewed = [];
	let noviewe = []
	const [load, setload] = useState(false)
	const [dataUserName, setdataUserName] = useState(null)
	const [datadate, setdatadate] = useState(null)
	const [noViewedByUser, setnoViewedByUser] = useState(null)

	const data = async () => {
		const resUserRelease = await fetch(`${__SERVER__}/releases/${actionData.id}/users`);
		const UserRelease = await resUserRelease.json();
		noviewe = UserRelease.filter(user => user.seen_at === null);
		viewed = UserRelease.filter(user => user.seen_at !== null);
		setdatadate(viewed);

		for (let item of viewed) {
			usersPackage.map(user => {
				(user.id === item.user_id)
					&& names.push(user)
			})
		}

		for (let item of noviewe) {
			usersPackage.map(user => {
				(user.id === item.user_id)
					&& noViewed.push(user)
			})
		}
		setdataUserName(names)
		setnoViewedByUser(noViewed)
		setload(true)
	}

	useEffect(() => {
		data()
	}, [])


	return (
		load ? (
			<>
				<h4 className='text-center text-dark'>Usuarios que han visto el comunicado</h4>
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<th>#</th>
							<th>Comunicado</th>
							<th>Visto por</th>
							<th>Visto el</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{actionData.i + 1}</td>
							<td>{actionData.title}</td>
							<td>{dataUserName.map(user => <p className="m-0" key={user.id}>{user.first_name} {user.last_name}</p>)}</td>
							<td>{datadate.map(date => <p className="m-0" key={date.id}>{moment(date.seen_at).format('LLL')}</p>)}</td>
						</tr>
					</tbody>
				</Table>
				<hr />
				{(noViewedByUser.length > 0) && (<h4 className='text-center mt-1 text-dark'>Usuarios que no han visto el comunicado</h4>)}
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<th>#</th>
							<th>Nombre</th>
						</tr>
					</thead>
					<tbody>
						{
							(noViewedByUser.length > 0) ? noViewedByUser.map((notVi, i) =>
								<tr key={i}>
									<td> {i + 1} </td>
									<td className="m-0" key={notVi.id}>{notVi.first_name} {notVi.last_name}</td>
								</tr>
							) : <tr><td></td><td>Todos los usuarios han visto el comunicado</td></tr>
						}
					</tbody>
				</Table>
			</>) : (<div className='loader-cente'><span className="loader"></span></div>)

	)
})

const BitacoraOnline = (props) => {
	const [file, setFile] = useState(null)
	const [reload, setreload] = useState(0)
	const [show, setShow] = useState(false)
	const [views, setviews] = useState(false)
	const [topics, settopics] = useState(null)
	const [action, setAction] = useState(false)
	const [search, setSearch] = useState(false)
	const [loading, setLoading] = useState(true)
	const [showPDF, setshowPDF] = useState(false)
	const [releases, setReleases] = useState(null)
	const [loadTopics, setloadTopics] = useState(true)
	const [usersPackage, setUsersPackage] = useState({})
	const [disableButton, setDisableButton] = useState(false)
	const [loadingRelease, setloadingRelease] = useState(true)
	const [releasesSearched, setReleasesSearched] = useState(null)
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [actionData, setActionData] = useState({
		i: 0,
		id: 0,
		title: "",
		responsible_id: ""
	})
	const handleShowModal = () => setShow(true);
	const closeButton = () => {
		setShow(false);
		setAction(false);
		setviews(false)
	}
	const closeButton2 = () => {
		setshowPDF(false);
		setFile(null);
	}
	const ShowModalPDF = () => setshowPDF(true);
	let arrayIds = [];

	useEffect(() => {
		(async () => {
			const response = await fetch(`${__SERVER__}/packages/${props.package_id}/users`);
			const data = await response.json();
			setUsersPackage(data)
			setLoading(false)

			const res = await fetch(`${__SERVER__}/package/${props.package_id}/releases/topics`);
			const topics = await res.json();
			settopics(topics);
			setloadTopics(false)
		})()
	}, [])

	useEffect(() => {
		(async () => {
			const resReleases = await fetch(`${__SERVER__}/projects/${props.project_id}/releases`);
			const dataRelease = await resReleases.json();
			setReleases(dataRelease.data)
			setTotalItems(dataRelease.totalItems);
			setloadingRelease(false);
		})()
	}, [reload, page, pageSize])

	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	}
	const updateViews = async arr_UserRelease => {
		for (let array of arr_UserRelease) {
			if (array.length > 0) {
				for (let item of array) {
					if ((item.user_id === props.data.id) && (item.seen_at === null)) {
						await fetch(`${__SERVER__}/releases/${item.release_id}/users/${item.id}`, {
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							method: "PUT"
						})
							.then((res) => { console.log(res) })
							.catch((res) => { console.log(res) })
					}
				}
			}
		}
	}

	const getReleasesUser = async relases_id => {
		let arr_UserRelease = []
		for (let id of relases_id) {
			const resUserRelease = await fetch(`${__SERVER__}/releases/${id}/users`);
			const UserRelease = await resUserRelease.json();
			arr_UserRelease.push(UserRelease);
		}
		updateViews(arr_UserRelease)
	}

	let relases_id = [];
	!loadingRelease && releases.map(release => relases_id.push(release.id))
	!loadingRelease && getReleasesUser(relases_id)

	const items = [
		{ label: 'Registro', key: 'item-1', children: <FormModal arrayIds={arrayIds} topics={topics} loadTopics={loadTopics} reload={reload} setreload={setreload} project_id={props.project_id} setFile={setFile} package_id={props.package_id} loading={loading} usersPackage={usersPackage} setShow={setShow} /> },
		{ label: 'Vista previa', key: 'item-2', children: <PDFViewerComp file={file} /> },
		{ label: 'Destinatarios', key: 'item-3', children: <Recipients loading={loading} arrayIds={arrayIds} usersPackage={usersPackage} /> },
	];

	const endSearch = () => {
		setReleases(releasesSearched);
		setDisableButton(false)
		setSearch(false);
	}

	const loadPDF = async id => {
		const resfile = await fetch(`${__SERVER__}/projects/${props.project_id}/releases/${id}/download`);
		const filepdf = await resfile.blob();
		return filepdf;
	}

	const handleDownloadFile = async (id, titulo, responsable) => {
		const filepdf = await loadPDF(id);
		const fileURL = await URL.createObjectURL(filepdf);
		let alink = document.createElement('a');
		alink.href = fileURL;
		alink.download = titulo + ' por ' + responsable + '.pdf';
		alink.click();
	}

	const handleShowPDF = async id => {
		const filepdf = await loadPDF(id);
		const fileURL = await URL.createObjectURL(filepdf);
		setFile(fileURL)
		closeButton()
		ShowModalPDF();
	}

	const changeStatus = async (id, status) => {
		const newStatus = { status: status }
		await fetch(`${__SERVER__}/projects/${props.project_id}/releases/${id}`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			method: "PUT",
			body: JSON.stringify(newStatus)
		})
			.then((res) => { console.log("Se cambió de estatus") })
			.catch((res) => { console.log("Error al cambiar el estatus") })
		setreload(reload + 1)
	}

	const handleShowModalAction = (i, id, title, responsible_id) => {
		setActionData({
			i,
			id,
			title,
			responsible_id
		})
		setAction(true)
	}

	return (
		<>

			<div className="bitacoraOnline">
				<FiltroBusqueda setReleasesSearched={setReleasesSearched} endSearch={endSearch} search={search} setSearch={setSearch} disableButton={disableButton} setDisableButton={setDisableButton} project_id={props.project_id} loadTopics={loadTopics} topics={topics} setReleases={setReleases} releases={releases} />
				{
					(!loading && !loadingRelease) ? (
						(releases && releases.length > 0) ? (
							<Table className='tableReleases' striped bordered hover variant="light" size="sm" id="cssTable">
								<thead>
									<tr>
										<th>#</th>
										<th>Nombre</th>
										<th>Responsable</th>
										<th>Supervisor</th>
										<th>Revisor</th>
										<th>Descripción</th>
										<th>Estatus</th>
										<th>Fecha de creación</th>
										<th>Duración</th>
										<th>Acción</th>
									</tr>
								</thead>
								<tbody>
									{
										releases.map((releases, i) => {

											const statusNow = () => {
												let ress = false;
												let date = moment().subtract(releases.duration, releases.duration_type)
												let date2 = moment(releases.created_at);

												(moment(date).isSameOrAfter(date2)) && (ress = true);

												return ress;
											}

											let tipo_duracion = "";
											switch (releases.duration_type) {
												case "minutes": tipo_duracion = "minutos"; break;
												case "hours": tipo_duracion = "horas"; break;
												case "days": tipo_duracion = "días"; break;
												case "weeks": tipo_duracion = "semanas"; break;
												case "months": tipo_duracion = "meses"; break;
												case "years": tipo_duracion = "años"; break;
												default: break;
											}

											let responsible = usersPackage.filter(element => element.id === releases.responsible_id)
											let supervisor = usersPackage.filter(element => element.id === releases.supervisor_id)
											let reviewer = usersPackage.filter(element => element.id === releases.reviewer_id)

											for (const element of responsible) releases.responsible_id = element.first_name + " " + element.last_name
											for (const element of supervisor) releases.supervisor_id = element.first_name + " " + element.last_name
											for (const element of reviewer) releases.reviewer_id = element.first_name + " " + element.last_name

											if (releases.status === "open") {
												if (statusNow()) {
													let status = "expired"
													changeStatus(releases.id, status)
												}
											}

											return (
												<tr key={i}>
													<td>{i + 1}</td>
													<td>{releases.title}</td>
													<td>{releases.responsible_id}</td>
													<td>{releases.supervisor_id}</td>
													<td>{releases.reviewer_id}</td>
													<td>{releases.body}</td>
													<td>
														{
															(statusNow()) ? (
																<Button variant='danger'>Vencido</Button>
															) : (
																(releases.status === "closed") ? (
																	<Button variant='primary'>Cerrado</Button>
																) : (
																	<Dropdown>
																		<Dropdown.Toggle variant="success" id="dropdown-basic">
																			Abierto
																		</Dropdown.Toggle>

																		<Dropdown.Menu>
																			<Dropdown.Item onClick={() => changeStatus(releases.id, "closed")}>Cerrar</Dropdown.Item>
																		</Dropdown.Menu>
																	</Dropdown>
																)
															)
														}
													</td>
													<td>{moment(releases.created_at).format("D MMM YYYY, h:mm:ss a")}</td>
													<td>{`${releases.duration} ${tipo_duracion}`}</td>
													<td>
														<Button variant="outline-primary" onClick={() => handleShowModalAction(i, releases.id, releases.title, releases.responsible_id)}>
															<WarningIcon />
														</Button>
													</td>
												</tr>
											)
										})
									}
								</tbody>
							</Table>
						) : (<h1 className='w-100 text-center noDatabita'>No hay datos en la bitácora para mostrar.</h1>)
					) : (<div className='loader-center'><span className="loader"></span></div>)
				}{
					showPDF && <Modal
						onClose={closeButton2}
						title="Visualizar documento"
						body={<PDFViewerComp file={file} />}
						size='lg'
					/>
				}{
					action && <Modal
						onClose={closeButton}
						title="Acción"
						body={<ButtonAction closeButton={closeButton} setviews={setviews} handleDownloadFile={handleDownloadFile} handleShowPDF={handleShowPDF} actionData={actionData} />}
					/>
				}{
					views && <Modal
						onClose={closeButton}
						size="lg"
						title="Receptores"
						body={<Views actionData={actionData} usersPackage={usersPackage} />}
					/>
				}{
					show && <Modal
						onClose={closeButton}
						title="Registrar Tópico"
						body={<Tabs items={items} />}
						size='lg'
					/>
				}
				<FlexButton bgColor='#F3F6F3' color="#000" style={{ bottom: 20, right: 20 }} onClick={() => handleShowModal()} />
			</div>
			<PaginationComp
				current={page}
				totalItems={totalItems}
				onChange={changePage}
				defaultPageSize={pageSize}
			/>
		</>
	);
}

export default BitacoraOnline