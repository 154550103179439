import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexFactorTypes = async ({package_id}) => {
    const url = `/packages/${package_id}/factor_incident_type`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: factor_incident_type.js:14 ~ indexFactorTypes ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}