import { Text } from '@chakra-ui/react'
import React from 'react'
import StatisticCard from './StatisticCard';
import ProgressBar from './ProgressBar';

const Deductive = ({data, title}) => {

    const getColorBar = percent => {
        if (percent <= 65) return 'red';
        if (percent > 65 && percent <= 89) return 'orange';
        if (percent >= 90) return 'green';
    }

    return (
        <>
            <Text className='text-center bg-white' fontSize='3xl'>{title}</Text>
            <div className='w-100 bg-white p-2 d-flex justify-content-around align-item-center'>
                <StatisticCard data={data} getColorBar={getColorBar} />
                <ProgressBar data={data} getColorBar={getColorBar} />
            </div>
        </>
    )
}

export default Deductive