import moment from "moment";

export const validateNumber = (value) => {
    if (typeof value === 'number') {
        return value;
    } else {
        try {
            value = eval(value);
            if (value !== undefined && value !== null) {
                return 0;
            }
        } catch (e) {

        }
    }
    return 0;
}

export const validateString = (value) => {
    if (typeof value === 'string') {
        return value;
    }
    try {
        return value.toString();
    } catch (e) { }
    return false;
}

export const validateBoolean = (value) => {
    if (typeof value === 'boolean') {
        return value;
    } else {
        try {
            return parseInt(value, 10) !== 0;
        } catch (e) {
            return false;
        }
    }
}

/**
 * En JavaScript las fechas se construyen usando milisegundos que han transcurrido desde el 1 de enero de 1970 
 * (lo que se conoce como Tiempo Unix, o Unix Epoch).
 */
export const validateDate = (value) => {
    try {
        if (typeof value === 'number') {

            const UnixEpochDaysBetween1990 = 25569;
            const SecondsADay = 86400;
            const toMiliseconds = 1000;
            return moment(
                (value - UnixEpochDaysBetween1990)
                * SecondsADay
                * toMiliseconds
            ).utc().format('YYYY-MM-DD HH:mm:ss');
        } else {
            return moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
        }
    } catch (e) {
        return false;
    }
}