import React, { useEffect, useState } from 'react';
import Room from './Room';
import '../Styles.css';

const _ = require('underscore');

const Rooms = (props) => {
	const { rooms, handleChatRoom, countMessages, users, user_id, room_id } = props;
	
	const [salas, setSalas] = useState([]);

	useEffect(() => {
		setSalas(rooms);
	}, [rooms]);

	const getCount = (room_id) => {
		let count = 0;
		let pos = _.findIndex(countMessages, { id: room_id });
		if (pos !== -1) {
			count = countMessages[pos].count;
		}
		return count;
	}

	return (
		<div className='scroll-rooms scroll-body'>
			{
				salas.map((room, index) =>
					<div key={index} onClick={() => handleChatRoom(room.id)}>
						<Room
							room={room}
							count={getCount(room.id)}
							users={users}
							user_id={user_id}
							room_id={room_id}
						/>
					</div>
				)
			}
		</div>
	)
}

export default Rooms;
