export const ___DASHBOARD___ = 'Dashboard';
export const ___INCIDENTES__ = 'Incidentes';
export const ___DEDUCTIVAS__ = 'Deductivas';
export const ___INVENTARIO__ = 'Inventario';
export const ___ACTIVIDADES_ = 'Actividades'
export const ___PROGRAMA____ = 'Programa de Mantenimiento';
export const ___CALENDAR____ = 'Calendario';
export const ___DIAGRAMA____ = 'Diagrama de Gantt';
export const ___RH__________ = 'Mano de Obra';
export const ___MATERIALES__ = 'Materiales';
export const ___MAQEQ_______ = 'Herramienta y Equipo';
export const ___SUBCONT_____ = 'Subcontratos';

export const ___TYPE_MODAL = {
    ___incidente: 'edfwaME2P76WHFDSAI6DF7QW',
    ___materiales: 'lcln3q748sgew74r6wuer7wq',
    ___orden_trabajo: 'aslknas8df72837r8wefaisd'
}