import { gql } from "@apollo/client";

export const NEW_ROOM = gql`
  subscription NewRoom($user_id: Int) {
    messenger_room_users(
      order_by: { id: desc }
      limit: 1
      where: { user_id: { _eq: $user_id } }
    ) {
      id
    }
  }
`;
