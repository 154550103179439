import Cap from '../img/cap.jpg'
import MRO from '../img/mro.png'
export class Variables {
    static user;
    static project_id;
    static project_code;
    static package_id;
    static permissions;
    static indicator_id;
    static maintenance_indicator_id;
    static room_id;
}

export const httpStatus = {
    ok: 200,
    created: 201,
    badRequest: 400,
    unauthorized: 401,
    forbidden: 403
}

export const getInfoPackage = () => {
    const info = {
        12 : {
            'img': Cap,
            'title': 'Construcciones Amozoc Perote S.A de C.V.',
            'highway': 'Amazoc-Perote',
            'code': 'CAPSA'
        },
        13 : {
            'img': MRO,
            'title': 'MRO. México-Michoacán, S.A.P.I. DE C.V.',
            'highway': 'Atlacomulco-Maravatio',
            'code': 'MRO AM'
        }
    }
    return info[Variables.package_id];
}