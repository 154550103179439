import { faFolderPlus, faListAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import React, { useRef, useState } from 'react'
import { Button, Overlay, Popover } from 'react-bootstrap';

const EventCalendar = ({ event }) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        if (!show) {
            setTimeout(() => {
                setShow(false);
            }, 5000);
        }
        setShow(!show);
        setTarget(event.target);
    };

    let variant = "dark";
    switch (event.status) {
        case 'PENDIENTE': variant = "secondary"; break;
        case 'ASIGNADO': variant = "outline-primary"; break;
        case 'INICIADO': variant = "primary"; break;
        case 'NO REALIZADO': variant = "danger"; break;
        case 'CON RETRASO': variant = "warning"; break;
        case 'COMPLETADO': variant = "success"; break;
        default: break;
    }

    const onClick = () => {
        setShow(false)
        event.details(event)
    }

    const viewWorkOrders = () => {
        setShow(false)
        event.detailsOrder(event)
    }

    const addWworkOrders = () => {
        setShow(false)
        event.generateOrder(event)
    }

    const deleteItem = () => {
        setShow(false)
        event.deleteOrder(event)
    }

    return (
        <div>
            <Button title={event.status} onClick={handleClick} size="sm" variant={variant}>
                {event.title}
            </Button>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}>
                <Popover id="popover-contained">
                    <Popover.Title as="h3">{event.status}</Popover.Title>
                    <Popover.Content>
                        <ButtonIcon name='Ver detalles' variant='link' onClick={onClick} />
                        {
                            event.work_order_id ?
                                <ButtonIcon icon={faListAlt} name='Ver órden de trabajo' variant='link' onClick={viewWorkOrders} /> :
                                <ButtonIcon icon={faFolderPlus} name='Agregar orden de trabajo' variant='link' onClick={addWworkOrders} />

                        }
                        <ButtonIcon icon={faTrashAlt} name='Eliminar' variant='link' onClick={deleteItem} />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
}

export default EventCalendar