import { BREADCRUMB_BEFORE_AJUSTES, BREADCRUMB_ADD_AJUSTES, UPDATE_OPTION_AJUSTES, UPDATE_COMPONENT_AJUSTES } from '../../actions/actionTypes';
import { stateBreadCrumbAjustes as initialState } from '../../initialState/InitialState';
const _ = require('underscore');

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BREADCRUMB_BEFORE_AJUSTES:
            return {
                ...state,
                pathBC: state.pathBC.splice(0, action.index + 1)
            };
        case BREADCRUMB_ADD_AJUSTES:
            let level = _.findIndex(state.pathBC, { level: action.level });
            if (level === -1) {
                return {
                    ...state,
                    pathBC: state.pathBC.concat({ path: action.path, key: action.key, level: action.level })
                }
            } else {
                let key = _.findIndex(state.pathBC, { key: action.key });
                if (key === -1) {
                    state.pathBC[level] = { path: action.path, key: action.key, level: action.level };
                    state.pathBC.splice(level + 1);
                    return {
                        ...state,
                        pathBC: state.pathBC.map(item => item)
                    }
                } else {
                    return {
                        ...state
                    }
                }
            }
        case UPDATE_OPTION_AJUSTES:
            return {
                ...state,
                option: action.index
            }
        case UPDATE_COMPONENT_AJUSTES:
            return {
                ...state,
                componentActive: action.componentActive
            }
        default:
            return state;
    }
};

export default reducer;