import FormIcon from 'components/layouts/FormIcon';
import React, { Fragment } from 'react';
import { Row } from 'react-bootstrap';

const ClientTab = (props) => {
    const {customer} = props;
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title='Razón social' name='legal_name' value={customer?.legal_name}/>
                <FormIcon title='RFC' name='tax_id' value={customer?.tax_id}/>
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Identificador' name='id' value={customer?.id}/>
                <FormIcon title='Régimen fiscal' name='tax_system' value={customer?.tax_system}/>
            </Row>
        </Fragment>
    )
}

export default ClientTab