import React, { useState } from 'react';
import TableComp from 'components/layouts/Table';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const ProductividadHoraria = () => {
    const [data, setData] = useState([]);

    const edit = () => {

    }

    return (
        <>
            <TableComp
                headers={['ID', 'Recurso', 'Actividad', 'Estándar', 'Costo/Hr', 'Rendimiento (Hrs)', 'Creado en', 'Acciones']}
                keys={['#']}
                body={data}
                actions={[
                    { icon: faEdit, variant: 'outline-primary', handleClick: edit }
                ]}
            />
        </>
    )
}

export default ProductividadHoraria;