import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import "./Mantenimiento.css";
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import { __SERVER__, google_maps_key } from '../../../../server/info';
import play_icon from '../../../imagenes-app/play_icon.png';
import stop_icon from "../../../imagenes-app/stop-icon.png";
import ReactPlayer from 'react-player';
import { Variables } from '../../../../variables/Variables';
const _ = require('underscore');

const Formula = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operadores: [{ value: '*' }, { value: '/' }, { value: '+' }, { value: '-' }],
            deductive: 0,
            refresh: false,
            inputs: []
        }
    }

    calculateDeductive = async (value, pos) => {
        if (value === "") {
            value = 0;
        }
        this.state.inputs[pos] = value;
        console.log(this.state.inputs);
        let v = this.state.inputs;
        let formula = "";
        for (let i = 0; i < v.length; i++) {
            formula += v[i] + '';
        }
        let deductive = eval(formula);
        deductive = this.decimalAdjust('round', deductive, -2);
        this.setState({ deductive });

    }

    decimalAdjust = (type, value, exp) => {
        // Si el exp no está definido o es cero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    componentDidMount = () => {
        let v = this.props.data.formula.trim().split(' ');
        for (const element of v) {
            let value = null;
            switch (element) {
                case '+': value = element; break;
                case '-': value = element; break;
                case '*': value = element; break;
                case '/': value = element; break;
                default: break;
            }
            if (value === null) {
                if (!isNaN(element)) {
                    value = element;
                } else {
                    if (element === 'PD') {
                        value = this.props.PD + "";
                    } else if (element === 'PUM') {
                        value = this.props.PUM.price + "";
                    } else {
                        value = "0";
                    }
                }
            }
            this.state.inputs.push(value);
        }
    }

    applyDeductive = () => {
        let v = this.props.datag;
        let inp = this.state.inputs;
        let formula = "";
        for (const element of inp) {
            formula += element + ' ';
        }
        formula = formula.trim();
        fetch(__SERVER__ + '/packages/' + v.package_id + '/segments/' + v.segment_id + '/incidents/' + v.incident_id + '/deductive', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify({
                standard_id: v.standard_id,
                application_by: v.application_by,
                formula_id: v.formula_id,
                formula_values: formula,
                deductive: this.state.deductive
            })
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }

            return null;
        }).then(res => {
            if (res !== null) {
                if (res > 0) {
                    this.props.setDeductive();
                }
            } else {
                console.log('NULL');
            }
        }).catch(e => {
            console.log(e);
        })
    }

    render = () => {
        return (
            <div className="itemFormula">
                <article style={{ padding: 20 }}>
                    <section>
                        <input value={this.state.deductive} readOnly={true} className={'inputFormulaD'} />
                        {
                            this.props.data.formula.trim().split(' ').map((item, i) =>
                                <input type={'number'} key={'IN' + i} placeholder={item === 'PD' ? this.props.PD : item === 'PUM' ? this.props.PUM.price : item}

                                    readOnly={this.state.operadores[_.findIndex(this.state.operadores, { value: item })] !== undefined ? true : this.props.data.variables[_.findIndex(this.props.data.variables, { variable: item })] === undefined ? true : item === 'PD' ? true : item === 'PUM' ? true : false}
                                    className={'inputFormula'}
                                    style={{
                                        width: this.state.operadores[_.findIndex(this.state.operadores, { value: item })] !== undefined ? 25 : this.props.data.variables[_.findIndex(this.props.data.variables, { variable: item })] === undefined ? 35 : 100
                                    }}
                                    onChange={(e) => {
                                        this.calculateDeductive(e.target.value, i);
                                    }}
                                />
                            )
                        }
                        <button className={'buttonApply'} disabled={this.state.deductive > 0 ? false : true} onClick={this.applyDeductive}>Aplicar</button>
                    </section>
                    <br />
                </article>
            </div>

        );
    }
}

const Marker = class extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="pin bounce"
                style={{ backgroundColor: this.props.color, cursor: 'pointer' }}
            >
                <div className="pulse" ></div>
            </div>
        );
    }
}

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datos: this.props.datos[0],
            getMapOptions: {
                disableDefaultUI: true,
                mapTypeControl: true,
                streetViewControl: true,
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'on' }]
                    }
                ]
            },
            active: [true, false, false],
            comentarios: false,
            videos: false,
            imagenes: false,
            audios: false,
            commentsO: [],
            videosO: [],
            photosO: [],
            audiosO: [],
            moment: 'before',
            refresh: false,
            viewTable: false,
            readOnly: false,
            AudioPlay: [],
            AudioTime: [],
            photoView: null,
            videoView: null,
            videoTime: { progress: 0, duration: 0, progressS: 0 },
            formulas: [],
            visibleFormulas: false,
            price: null,
            deductive: 0,
            viewFormula: null,
            informationDeductive: [],

        };
    }

    componentDidMount() {
        this.getMedia();
        this.getFormulas();
        this.getPUM();
        this.getInformationDeductive();
    }

    getPUM = async () => {
        await fetch(__SERVER__ + '/packages/' + Variables.package_id + '/pum?segment_id=' + this.state.datos?.segment?.id + '&standard_id=' + this.state.datos?.standard?.id, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                this.state.price = res;
            }
        }).catch(e => {
            console.log(e);
        })
    }

    getMedia = () => {
        this.state.commentsO = [];
        this.state.videosO = [];
        this.state.photosO = [];
        this.state.audiosO = [];
        this.setState({ refresh: !this.state.refresh });
        if (this.state.datos.media.length === 0) {
            return;
        }
        let media = this.state.datos.media;
        for (let i = 0; i < media.length; i++) {
            if (media[i].file_path.indexOf(this.state.moment) !== -1) {
                let url = __SERVER__ + '/projects/' + this.state.datos.projectId + '/inspections/' + this.state.datos.inspectionId + '/incidents/' + this.state.datos.id + '/download?path=' + media[i].file_path;
                if (media[i].file_path.indexOf('images') !== -1) {
                    ///Media Imagenes
                    axios(url, {
                        method: "GET",
                        responseType: "blob"
                    }).then(response => {
                        let file = new Blob([response.data], {
                            type: response.data.type
                        });
                        let fileURL = URL.createObjectURL(file);
                        this.state.photosO.push({ info: media[i], file: fileURL });
                        this.setState({ refresh: !this.state.refresh });
                    }).catch(error => {
                        console.log(error);
                    });
                } else if (media[i].file_path.indexOf('video') !== -1) {
                    ///Media Videos
                    axios(url, {
                        method: "GET",
                        responseType: "blob"
                    }).then(response => {
                        let file = new Blob([response.data], {
                            type: response.data.type
                        });
                        let fileURL = URL.createObjectURL(file);
                        this.state.videosO.push({ info: media[i], file: fileURL });

                        this.setState({ refresh: !this.state.refresh });
                    }).catch(error => {
                        console.log(error);
                    });
                } else if (media[i].file_path.indexOf('audio') !== -1) {
                    ///Media Audios                    
                    axios(url, {
                        method: "GET",
                        responseType: "blob"
                    }).then(response => {
                        let file = new Blob([response.data], {
                            type: response.data.type
                        });
                        let fileURL = URL.createObjectURL(file);
                        this.state.AudioPlay.push(false);
                        this.state.AudioTime.push({ progress: 0, duration: 0, progressS: 0 });
                        this.state.audiosO.push({ info: media[i], file: fileURL });
                        this.setState({ refresh: !this.state.refresh });
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    ///Media Comentarions
                    fetch(url, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json'
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            return res.text();
                        }
                        return null;
                    }).then(res => {
                        if (res !== null) {
                            /*this.state.commentsO.push(
                                <div className="comments">
                                    <div>{res}</div>
                                </div>);*/
                            this.state.commentsO.push(
                                <tr key={'COO' + i}>
                                    <td>{media[i].creation_date.split('.')[0].replace('T', ' ')}</td>
                                    <td>{media[i].creation_by.first_name + " " + media[i].creation_by.last_name}</td>
                                    <td>{res}</td>
                                </tr>
                            );
                            this.setState({ refresh: !this.state.refresh });
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
            }
        }
    }

    setMoment = (index, moment) => {
        this.state.photoView = null;
        this.state.videoView = null;
        this.state.active = new Array(3).fill(false);
        this.state.active[index] = true;
        this.setState({ moment }, () => {
            this.getMedia();
        })
    }

    getFormulas = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/standards/' + this.state.datos.standard.id + '/formulas', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                for (let i = 0; i < res.length; i++) {
                    let array_formula = res[i].formula.trim().split(' ');
                    let formula_letras = "";
                    for (let j = 0; j < array_formula.length; j++) {
                        for (let k = 0; k < res[i].variables.length; k++) {
                            if (res[i].variables[k].variable === array_formula[j]) {
                                array_formula[j] = res[i].variables[k].description;
                                break;
                            }
                        }
                        formula_letras += array_formula[j] + " ";
                    }
                    res[i].formula_letras = formula_letras;
                }

                this.setState({ formulas: res });
            }
        }).catch(e => {
            console.log(e);
        });
    }

    decimalAdjust = (type, value, exp) => {
        // Si el exp no está definido o es cero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    deductiveApply = (deductive) => {
        this.setStates({ deductive });
    }

    getInformationDeductive = () => {
        if (this.state.datos.deductive === 1) {
            fetch(__SERVER__ + '/packages/' + Variables.package_id + '/segments/' + this.state.datos.segment?.id + '/deductive?incident_id=' + this.state.datos?.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            }).then(res => {
                if (res.status === 200) {
                    return res.json();
                }
                return null;
            }).then(res => {
                if (res !== null) {
                    res = res[0];
                    this.state.informationDeductive.push(<label><strong>Deductiva aplicada</strong></label>);
                    this.state.informationDeductive.push(<label>{res.application_date.split('.')[0]}</label>);
                    this.state.informationDeductive.push(<label>{res.application_by.first_name} {res.application_by.last_name}</label>);
                    this.state.informationDeductive.push(<label><strong>Método {res.formula.method}</strong></label>);
                    let formula = res.formula.formula.trim().split(' ');
                    let formula_s = "Deductiva = ";
                    for (let i = 0; i < formula.length; i++) {
                        let found = false;
                        for (let j = 0; j < res.formula.variables.length && !found; j++) {
                            if (res.formula.variables[j].variable === formula[i]) {
                                found = true;
                                formula_s += res.formula.variables[j].description + " ";
                            }
                        }
                        if (!found) {
                            formula_s += formula[i] + " ";
                        }
                    }
                    formula_s = formula_s.trim();
                    this.state.informationDeductive.push(<label>{formula_s}</label>);
                    /*let formula_values = res.formula_values.trim().split(' ');
                    for(let i = 0; i < formula.length; i++) {
                        let found = false;
                        for(let j = 0; j < res.formula.variables.length && !found; j++) {
                            if(res.formula.variables[j].variable === formula[i] && formula[i] !== 'PD'){
                                found = true;
                                this.state.informationDeductive.push(<label><strong>{formula_values[i]}: </strong>{res.formula.variables[j].description}</label>);
                            }
                        }
                    }*/
                    this.state.informationDeductive.push(<label><strong>{res.deductive + ' = ' + res.formula_values.trim()}</strong></label>);
                }
            })
        }
    }

    setDeductive = () => {
        this.state.datos.deductive = 1;
        this.state.visibleFormulas = false;
        this.getInformationDeductive();
        this.setState({ refresh: !this.state.refresh });
    }

    render() {
        return (
            <div className="incident_modal">
                <div className="modal_contenido">
                    <header className={"modal_header status" + this.state.datos.status.id}>
                        <h1>{this.state.datos.standard.code + ": " + this.state.datos.standard.name}</h1>
                        <button onClick={this.props.setThisNull} className={"closeModal"}>X</button>
                    </header>

                    {!this.state.visibleFormulas ? <section className="modal_body">

                        <section className="modal_body_" style={{ width: '100%' }}>
                            <article className="modal_users">
                                <article className="modal_createBy">
                                    <label>Creado por</label>
                                    <label>Nombre: {this.state.datos.created_by.first_name + " " + this.state.datos.created_by.last_name}</label>
                                    <label>Puesto: {this.state.datos.created_by.position}</label>
                                    <label>Fecha: {this.state.datos.creation_date.split('.')[0]}</label>
                                </article>
                                {this.state.datos.completed_by !== null ?
                                    <article className="modal_completedBy">
                                        <label>Completado por</label>
                                        <label>Nombre: {this.state.datos.completed_by.first_name + ' ' + this.state.datos.completed_by.last_name}</label>
                                        <label>Puesto: {this.state.datos.created_by.position}</label>
                                        <label>Fecha: {this.state.datos.completion_date.split('.')[0]}</label>
                                    </article> : null}
                            </article>
                            <article className="modal_users">
                                <article className="modal_createBy">
                                    <label>Evaluación</label>
                                    <label>{this.state.datos.data.evaluation.name}</label>
                                    <label>{this.state.datos.data.evaluation.description !== null ? "Descripción: " + this.state.datos.data.evaluation.description : null}</label>
                                </article>

                                <article className="modal_completedBy">
                                    <label>Fecha estimada para el cumplimiento</label>
                                    <label>{this.state.datos.data.estimated_date.split('.')[0]}</label>
                                    <label>Deductiva por incumplimiento</label>
                                    <label>{this.state.datos.data.deductive?.factor ? this.state.datos.data.deductive.factor + "%" : "N/A"}</label>
                                </article>
                            </article>

                            <article className="modal_details">
                                <article className="modal_details_description">
                                    <h4>{!this.state.datos.kilometer_E ? 'Ubicación' : 'Punto inicial'}</h4>
                                    <label>Latitud: {this.state.datos?.latitude}</label>
                                    <label>Longitud: {this.state.datos?.longitude}</label>
                                    <label>Altitud: {this.state.datos?.altitude}</label>
                                    <label>Kilometro: {this.state.datos?.kilometer}+{this.state.datos?.meter}</label>
                                    <label>Segmento: {this.state.datos?.segment?.name + " - " + this.state.datos?.segment?.location}</label>
                                </article>
                                <article style={{ height: '200px', width: '50%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: google_maps_key }}
                                        defaultCenter={{
                                            lat: this.state.datos.latitude,
                                            lng: this.state.datos.longitude
                                        }}
                                        defaultZoom={20}
                                        options={this.state.getMapOptions}>
                                        <Marker
                                            lat={this.state.datos.latitude}
                                            lng={this.state.datos.longitude}
                                            color="#EF0909"
                                        />
                                    </GoogleMapReact>
                                </article>
                            </article>
                            <article className="modal_informationDeductive">
                                {this.state.informationDeductive.map(item => item)}
                            </article>
                            {
                                this.state.datos.kilometer_E ?
                                    <article className="modal_details">
                                        <article className="modal_details_description">
                                            <h4>Punto inicial</h4>
                                            <label>Latitud: {this.state.datos.latitude_E}</label>
                                            <label>Longitud: {this.state.datos.longitude_E}</label>
                                            <label>Altitud: {this.state.datos.altitude_E}</label>
                                            <label>Kilometro: {this.state.datos.kilometer_E}+{this.state.datos.meter_E}</label>
                                            <label>Segmento: {this.state.datos.segment_E.name + " - " + this.state.datos.segment_E.location}</label>
                                        </article>
                                        <article style={{ height: '200px', width: '50%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: 'AIzaSyDXA-RuX3PnwAivwM1IscSXYfwWd4ax4Po' }}
                                                defaultCenter={{
                                                    lat: this.state.datos.latitude_E,
                                                    lng: this.state.datos.longitude_E
                                                }}
                                                defaultZoom={20}
                                                options={this.state.getMapOptions}>
                                                <Marker
                                                    lat={this.state.datos.latitude_E}
                                                    lng={this.state.datos.longitude_E}
                                                    color="#EF0909"
                                                />
                                            </GoogleMapReact>
                                        </article>
                                    </article> : null
                            }
                            <article className="modal_media">
                                <section className="modal_media_header_moment">
                                    <button className={this.state.active[0] ? 'active_' : 'desactive_'} onClick={() => this.setMoment(0, 'before')}>Previo</button>
                                    <button className={this.state.active[1] ? 'active_' : 'desactive_'} onClick={() => this.setMoment(1, 'during')}>En proceso</button>
                                    <button className={this.state.active[2] ? 'active_' : 'desactive_'} onClick={() => this.setMoment(2, 'after')}>Finalizado</button>
                                </section>
                                {this.state.photosO.length !== 0 ?
                                    <div className="content_media">
                                        <h4>Fotografías</h4>
                                        <div className="mediaPhotos">
                                            {
                                                this.state.photoView === null ?
                                                    null
                                                    : <img src={this.state.photoView} className={"image"} />
                                            }
                                            <section className="scroll">
                                                {
                                                    this.state.photosO.map((item, i) =>
                                                        <article key={'PHOA' + i} onClick={(e) => this.setState({ photoView: item.file })}>
                                                            <li><strong>Subido por</strong></li>
                                                            <li>{item.info.creation_by.first_name + " " + item.info.creation_by.last_name}</li>
                                                            <li><strong>Nombre del archivo</strong></li>
                                                            <li>{item.info.file_path.split('/')[2]}</li>
                                                            <li><strong>Fecha</strong></li>
                                                            <li>{item.info.creation_date.split('.')[0].replace('T', ' ')}</li>
                                                        </article>
                                                    )
                                                }

                                            </section>
                                        </div>
                                    </div>
                                    : null}

                                {this.state.videosO.length !== 0 ? <div className="content_media">
                                    <h4>Videos</h4>
                                    <div className="mediaPhotos">
                                        {
                                            this.state.videoView === null ?
                                                null
                                                :
                                                <article style={{ width: 200, height: 250 }}>
                                                    <ReactPlayer
                                                        onEnded={() => {
                                                            setTimeout(() => {
                                                                this.state.videoTime.progress = 0;
                                                                this.state.videoTime.progressS = 0;
                                                                this.setState({ refresh: !this.state.refresh })
                                                            }, 1000)
                                                        }}
                                                        onProgress={(info) => {
                                                            let progress = info.played;
                                                            let duration = info.loadedSeconds;
                                                            let progressS = info.playedSeconds;
                                                            this.state.videoTime = { progress, duration, progressS };
                                                            this.setState({ refresh: !this.state.refresh })
                                                        }}
                                                        className="image"
                                                        width={200}
                                                        height={220}
                                                        playing={true}
                                                        url={this.state.videoView}
                                                        /*onPause={() => {this.state.AudioPlay[i] = !this.state.AudioPlay[i]; this.setState({refresh: !this.state.refresh});}}*/ />
                                                    {/*<img src={this.state.AudioPlay[i] ? stop_icon : play_icon} onClick={() => {this.state.AudioPlay[i] = !this.state.AudioPlay[i]; this.setState({refresh: !this.state.refresh});}} style={{width: 50, height: 50, cursor: 'pointer'}} /> */}
                                                    <div style={{ width: 200 }} className="controlsAudio">
                                                        {this.decimalAdjust('round', this.state.videoTime.progressS, -2)}s/{this.decimalAdjust('round', this.state.videoTime.duration, -2)}s
                                                        <div style={{ width: 200, backgroundColor: '#9C9C92', height: 4 }} className="controlsAudioTime">
                                                            <div style={{ width: (this.state.videoTime.progress * 190), backgroundColor: '#C01207', height: 4 }}>
                                                            </div>
                                                            <div style={{ width: 10, height: 4, backgroundColor: '#C01207' }} className="controlsAudioIndicatorTime">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                        }
                                        <section className="scroll">
                                            {
                                                this.state.videosO.map((item, i) =>
                                                    <article key={'PHOA' + i} onClick={(e) => this.setState({ videoView: null, videoTime: { progress: 0, duration: 0, progressS: 0 } }, () => this.setState({ videoView: item.file }))}>
                                                        <li><strong>Subido por</strong></li>
                                                        <li>{item.info.creation_by.first_name + " " + item.info.creation_by.last_name}</li>
                                                        <li><strong>Nombre del archivo</strong></li>
                                                        <li>{item.info.file_path.split('/')[2]}</li>
                                                        <li><strong>Fecha</strong></li>
                                                        <li>{item.info.creation_date.split('.')[0].replace('T', ' ')}</li>
                                                    </article>
                                                )
                                            }
                                        </section>
                                    </div>
                                </div>
                                    : null}


                                <div className="content_media">
                                    {this.state.audiosO.length !== 0 ? <h4>Audio</h4> : null}
                                    {this.state.audiosO.length !== 0 ?
                                        <table className="tableMedia">

                                            {
                                                this.state.audiosO.map((item, i) =>
                                                    <tr key={'AUO' + i}>
                                                        <td style={{ width: 150 }}>
                                                            <ReactPlayer
                                                                onEnded={() => {
                                                                    setTimeout(() => {
                                                                        this.state.AudioTime[i].progress = 0;
                                                                        this.state.AudioTime[i].progressS = 0;
                                                                        this.setState({ refresh: !this.state.refresh })
                                                                    }, 1000)
                                                                }}
                                                                onProgress={(info) => {
                                                                    let progress = info.played;
                                                                    let duration = info.loadedSeconds;
                                                                    let progressS = info.playedSeconds;
                                                                    this.state.AudioTime[i] = { progress, duration, progressS };
                                                                    this.setState({ refresh: !this.state.refresh })
                                                                }} width={1} height={1} playing={this.state.AudioPlay[i]} url={item.file} onPause={() => { this.state.AudioPlay[i] = !this.state.AudioPlay[i]; this.setState({ refresh: !this.state.refresh }); }} />
                                                            <img src={this.state.AudioPlay[i] ? stop_icon : play_icon} onClick={() => { this.state.AudioPlay[i] = !this.state.AudioPlay[i]; this.setState({ refresh: !this.state.refresh }); }} style={{ width: 50, height: 50, cursor: 'pointer' }} />
                                                            <div style={{ width: 150 }} className="controlsAudio">
                                                                {this.decimalAdjust('round', this.state.AudioTime[i].progressS, -2)}s/{this.decimalAdjust('round', this.state.AudioTime[i].duration, -2)}s
                                                                <div style={{ width: 150, backgroundColor: '#9C9C92', height: 4 }} className="controlsAudioTime">
                                                                    <div style={{ width: (this.state.AudioTime[i].progress * 140), backgroundColor: '#C01207', height: 4 }}>
                                                                    </div>
                                                                    <div style={{ width: 10, height: 4, backgroundColor: '#C01207' }} className="controlsAudioIndicatorTime">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="left">
                                                            <li><strong>Subido por</strong></li>
                                                            <li>{item.info.creation_by.first_name + " " + item.info.creation_by.last_name}</li>
                                                            <li><strong>Nombre del archivo</strong></li>
                                                            <li>{item.info.file_path.split('/')[2]}</li>
                                                        </td>
                                                        <td className="left up">
                                                            <li><strong>Fecha</strong></li>
                                                            <li>{item.info.creation_date.split('.')[0].replace('T', ' ')}</li>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </table>
                                        : null}
                                </div>


                                <div className="content_media">
                                    {this.state.commentsO.length !== 0 ? <h4>Comentarios</h4> : null}
                                    {this.state.commentsO.length !== 0 ?
                                        <table className="tableMedia">
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Subido por</th>
                                                <th>Contenido</th>
                                            </tr>
                                            {
                                                this.state.commentsO.map(item => item)
                                            }
                                        </table>
                                        : null}
                                </div>
                            </article>
                            {
                                this.state.datos.data.deductive?.factor &&
                                    this.state.datos.deductive === 0 ?
                                    <article className="apply_deductive">
                                        <div onClick={() => {
                                            if (this.state.price === null) {
                                                this.getPUM();
                                                if (this.state.price !== null) {
                                                    this.setState({ visibleFormulas: !this.state.visibleFormulas });
                                                }
                                            } else {
                                                this.setState({ visibleFormulas: !this.state.visibleFormulas });
                                            }
                                        }}>Aplicar deductivas</div>
                                    </article>
                                    : null
                            }
                        </section>
                    </section>
                        : <section className="formulas">
                            <h4>Método de medida para determinación de deducciones</h4>
                            <div className="metodos">
                                {
                                    this.state.formulas.map((item, i) =>
                                        <div className="itemMetodo" key={"M" + i} >
                                            <div>
                                                <input type="radio" name="formulas" id={"formula_" + i} onChange={(e => {
                                                    if (e.target.checked) {
                                                        this.setState({ viewFormula: null }, () => {
                                                            this.setState({ viewFormula: <Formula setDeductive={this.setDeductive} datag={{ package_id: Variables.package_id, segment_id: this.state.datos.segment.id, incident_id: this.state.datos.id, standard_id: this.state.datos.standard.id, application_by: Variables.user.id, formula_id: item.id }} data={item} PD={this.state.datos.data.deductive.factor} PUM={this.state.price[0]} /> });
                                                        })
                                                    }
                                                })} />
                                                <label for={"formula_" + i}>Método {item.method}</label>
                                            </div>
                                            {
                                                item.variables.map((item_, i) =>
                                                    <label key={"V" + i}>{item_.variable}: {item_.description}<br /></label>
                                                )
                                            }
                                            <label><br />Deducción = {item.formula.trim()}</label>
                                        </div>
                                    )
                                }
                            </div>

                            {
                                this.state.viewFormula
                            }

                            {/**/}
                            <article className="apply_deductive">
                                <div onClick={() => {
                                    this.setState({ visibleFormulas: !this.state.visibleFormulas, viewFormula: null });
                                }}>Cerrar</div>
                            </article>
                        </section>}
                </div>
            </div>
        );
    }
}