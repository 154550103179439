import React, { useEffect, useState } from 'react'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from 'components/layouts/ButtonIcon'
import ModalComp from 'components/layouts/Modal'
import FormIcon from 'components/layouts/FormIcon'
import UpdateComp from './UpdateComp'
import { Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { indexPumActivities, storePumActivities, updatePumActivities } from 'api/maintenance_standards/pum_activities'
import { storePumActivitySupplies } from 'api/maintenance_standards/pum_activity_supplies'
import { Alert, Skeleton } from 'antd'

const AssignSuppliesModal = ({ onClose, activity, package_id, standard_id }) => {
    const [pumActivities, setPumActivities] = useState([]);
    const [pumActivitySel, setPumActivitySel] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [data, setData] = useState({});
    const [amounts, setAmounts] = useState({ materiales: {}, manoObra: {}, herramienta: {}, equipo: {} });
    const [rows, setRows] = useState([]);                   // suministros asignados a la actividad
    const [rowsUpdated, setRowsUpdated] = useState([]);     // sumunistros modificados o agregados
    const [loading, setLoading] = useState(false);
    const [loadingStore, setLoadingStore] = useState(false);
    const [loadingPA, setLoadingPA] = useState(false);

    const [direct_costs, setDirectCost] = useState(0);
    const [dataPercentage, setDataPercentage] = useState({});
    const [total_cost, setTotalCost] = useState(0);

    // Obtener todas las configuraciones diponibles
    useEffect(() => {
        const getPumActivities = async () => {
            const { response } = await indexPumActivities({ activity_id: activity.id });
            if (response.data.length > 0) {
                setPumActivitySel(response.data[0]);
            }
            setPumActivities(response.data);
        }
        if (!isAdd) getPumActivities();
    }, [isAdd]);

    // Obtener los suministros de la configuración seleccionada
    useEffect(() => {
        const getPumActivitiesSupplies = async () => {
            setLoadingPA(true);
            setRows(pumActivitySel.supplies);   // Obtener suministros asignados
            fillAdditionalCharges();            // llenar costos adicionales
            setLoadingPA(false);
        }
        if (pumActivitySel) getPumActivitiesSupplies();
    }, [pumActivitySel]);

    // Capturar suministros agregados o actualizados
    useEffect(() => {
        setRowsUpdated([
            ...getSuppliesCounts(amounts.materiales),
            ...getSuppliesCounts(amounts.equipo),
            ...getSuppliesCounts(amounts.manoObra),
            ...getSuppliesCounts(amounts.herramienta),
        ]);
    }, [amounts]);

    const fillAdditionalCharges = () => {
        const { direct_costs, total_cost, indirect_office_costs, indirect_field_costs,
            financing_costs, utility_costs, additional_charges, other_percentages } = pumActivitySel;
        setDirectCost(direct_costs)
        setTotalCost(total_cost);
        const additional = {}
        if (indirect_office_costs) additional.indirect_office_costs = indirect_office_costs;
        if (indirect_field_costs) additional.indirect_field_costs = indirect_field_costs;
        if (financing_costs) additional.financing_costs = financing_costs;
        if (utility_costs) additional.utility_costs = utility_costs;
        if (additional_charges) additional.additional_charges = additional_charges;
        if (other_percentages) additional.other_percentages = other_percentages;
        setDataPercentage(additional)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData(prevState => ({ ...prevState, [name]: value }));
    }

    const addPumActivity = async () => {
        setLoadingStore(true);
        const { response } = await storePumActivities({ data: { ...data, activity_id: activity.id } });
        if (response) {
            setIsAdd(false);
            setPumActivitySel(response);
            Swal.fire('¡Operación exitosa!', 'Se ha agregado una nueva colección de suministros', 'success');
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error, verifique los datos', 'error');
        }
        setLoadingStore(false);
    }

    const getSuppliesCounts = (classification) => {
        const rows = [];
        for (const [key, value] of Object.entries(classification)) {
            const supply_id = parseInt(key, 10);
            const count = parseFloat(value)
            if (!isNaN(count))
                rows.push({ pum_activity_id: pumActivitySel.id, supply_id, count })
        }
        return rows;
    }

    const createOrUpdatePAS = async () => {
        if (pumActivitySel) {
            let updatedPA = false;
            let updatedPAS = false;
            if (Object.values(dataPercentage).length > 0) {
                setLoading(true);
                const fetch = await updatePumActivities({ id: pumActivitySel.id, data: { ...dataPercentage, direct_costs, total_cost } });
                if (fetch.response) {
                    updatedPA = true;
                }
                setLoading(false);
            }
            if (rowsUpdated.length > 0) {
                setLoading(true);
                const { response } = await storePumActivitySupplies({ data: rowsUpdated });
                setLoading(false);
                if (response) {
                    updatedPAS = true;
                    // Actualizar sumistros obtenidos del servidor
                    setRows(prevState => [
                        ...prevState.filter((item) =>
                            !rowsUpdated.find((ru) => ru.supply_id === item.supply_id)),
                        ...rowsUpdated
                    ]);
                    // Vaciar arreglo de modificaciones
                    setRowsUpdated([]);
                    setAmounts({ materiales: {}, manoObra: {}, herramienta: {}, equipo: {} });
                }
            }
            if (updatedPAS) Swal.fire('Operación exitosa', 'Suministros de la actividad actualizados', 'success');
            if (updatedPA) Swal.fire('Operación exitosa', 'Costos adicionales actualizados', 'success');
            if (updatedPA && updatedPAS) Swal.fire('Operación exitosa', 'Suministros de la actividad y costos adicionales actualizados', 'success');
        } else {
            Swal.fire('Sin clasificación', 'Seleccionar una clasificación', 'warning');
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            size={isAdd ? 'md' : 'xl'}
            title={`${activity.code} | ${activity.name}`}
            body={<>
                {
                    isAdd ? <>
                        {
                            loadingStore ? <Skeleton active /> : <>
                                <Alert
                                    message="Configuraciones"
                                    description="Se pueden agregar tantas configuraciones como se necesiten."
                                    type="info"
                                    showIcon
                                />
                                {/* Agregar matriz de recursos */}
                                <Row>
                                    <FormIcon title='Nombre' name='name' value={data?.name} handleChange={handleChange} />
                                </Row>
                            </>
                        }
                    </> : <>
                        {
                            loading ? <Skeleton active /> : <>
                                <Alert
                                    message="Asignación de recursos"
                                    description="Recursos que se necesitan para llevar a cabo dicha actividad."
                                    type="info"
                                    showIcon
                                />
                                {/* Ver listado de matrices */}
                                <UpdateComp
                                    pumActivities={pumActivities}
                                    setIsAdd={setIsAdd}
                                    pumActivitySel={pumActivitySel}
                                    setPumActivitySel={setPumActivitySel}
                                    package_id={package_id}
                                    standard_id={standard_id}
                                    amounts={amounts}
                                    setAmounts={setAmounts}
                                    rows={rows}
                                    rowsUpdated={rowsUpdated}
                                    setRowsUpdated={setRowsUpdated}
                                    loadingPA={loadingPA}
                                    dataPercentage={dataPercentage}
                                    setDataPercentage={setDataPercentage}
                                    total_cost={total_cost}
                                    setTotalCost={setTotalCost}
                                    direct_costs={direct_costs}
                                    setDirectCost={setDirectCost}
                                />
                            </>
                        }
                    </>
                }

            </>}
            footer={<>
                {
                    isAdd ? <>
                        <ButtonIcon icon={faTimes} name='Cancelar' variant='outline-secondary' onClick={() => setIsAdd(false)} />
                        <ButtonIcon icon={faSave} name='Agregar' variant='outline-success' onClick={addPumActivity} disabled={loadingStore} />
                    </> : <>
                        <ButtonIcon name='Cerrar' variant='outline-secondary' icon={faTimes} onClick={onClose} />
                        <ButtonIcon name='Actualizar' variant='outline-success' icon={faSave} onClick={createOrUpdatePAS} />
                    </>
                }
            </>}
        />
    )
}

export default AssignSuppliesModal