import React, { useState } from 'react';
import { faPlus, faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { storeInventory } from 'api/maintenance_standards/inventory';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon2 from 'components/layouts/FormIcon2';
import ModalComp from 'components/layouts/Modal';
import { InputGroup, ListGroup, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import TabsComp from 'components/layouts/Tabs';

const GeneralComp = ({ inventory, handleChange }) => {
    return (
        <Form>
            <FormIcon2 type='date' title='Fecha *' name='creation_date' placeholder='Fecha' handleChange={handleChange} value={inventory?.creation_date || ''} />
            <FormIcon2 title='Código *' name='code' placeholder='Código' handleChange={handleChange} value={inventory?.code || ''} />
            <FormIcon2 title='Cuerpo *' name='body' placeholder='Cuerpo' handleChange={handleChange} value={inventory?.body || ''} />
            <FormIcon2 title='Inventario *' name='inventory' placeholder='Inventario' handleChange={handleChange} value={inventory?.inventory || ''} />
            <FormIcon2 title='Versión *' name='version' placeholder='Versión' handleChange={handleChange} value={inventory?.version || ''} />
        </Form>
    );
}

const CustomComp = ({ items, inventory, deleteItemExtra, addItemExtra, newHeader, setNewHeader }) => {

    const handleChange = (e) => {
        setNewHeader(e.target.value);

    }

    const onClick = () => addItemExtra()

    return (
        <Form>
            <Form.Group>
                <Form.Label>Las cabeceras del inventario son</Form.Label>
                <ListGroup>
                    {
                        items.map((item, i) =>
                            <ListGroup.Item key={"ad" + i}>{item.label}</ListGroup.Item>
                        )
                    }
                    {
                        inventory.labelExtra.map((item, i) =>
                            <ListGroup.Item key={"a" + i}>
                                <FontAwesomeIcon style={{ cursor: 'pointer', marginRight: 10 }} icon={faTimesCircle} color={'rgba(8, 193, 231, 0.7)'} size={30} onClick={() => deleteItemExtra(i)} />
                                {item}
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </Form.Group>
            <Form.Group>
                <Form.Label>Extras</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control placeholder="Extra" value={newHeader} onChange={handleChange} />
                    <InputGroup.Append>
                        <ButtonIcon name='Añadir' icon={faPlus} value={newHeader} disabled={newHeader === ''} variant='outline-primary' onClick={onClick} />
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
        </Form>
    );
}

const NewStore = (props) => {
    const {
        onClose,
        items,
        table,
        getInventory
    } = props;


    const [newHeader, setNewHeader] = useState('');
    const [inventory, setInventory] = useState({
        creation_date: '',
        code: '',
        body: '',
        inventory: '',
        version: '',
        labelExtra: []
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInventory(prevInventory => ({ ...prevInventory, [name]: value }));
    }

    const deleteItemExtra = (index) => {
        setInventory({ ...inventory, labelExtra: inventory.labelExtra.filter((_, i) => i !== index) });
    }

    const addItemExtra = () => {
        setInventory(prevInventory => ({
            ...prevInventory,
            labelExtra: [...prevInventory.labelExtra, newHeader.toUpperCase()]
        }));
        setNewHeader('');
    }

    const saveInventory = async () => {
        let labelExtra = JSON.stringify(inventory?.labelExtra);
        if (inventory?.labelExtra.length === 0) { labelExtra = null; }
        if (inventory?.creation_date === '' || inventory?.code === '' || inventory?.body === '' || inventory?.inventory === '' || inventory?.version === '') {
            alert('Debe rellenar todos los campos necesarios');
            return;
        }
        let data = {
            creation_date: inventory?.creation_date,
            code: inventory?.code,
            body: inventory?.body,
            inventory: inventory?.inventory,
            version: inventory?.version,
            labelExtra
        }
        let response = await storeInventory({ table, data });
        if (response) {
            getInventory();
            onClose();
            Swal.fire('¡Operación exitosa!', 'Se ha agregado un nuevo almacen de inventario', 'success');
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de realllizar esta operación. Verifique los datos.', 'warning');
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title='Inventario'
            size='md'
            body={<>
                <TabsComp
                    tabColor='black'
                    tablist={['General', 'Personalizar']}
                    tabpanels={[
                        <GeneralComp
                            key={'general'}
                            handleChange={handleChange}
                            inventory={inventory}
                        />
                        ,
                        <CustomComp
                            key={'custom'}
                            items={items}
                            inventory={inventory}
                            deleteItemExtra={deleteItemExtra}
                            addItemExtra={addItemExtra}
                            newHeader={newHeader}
                            setNewHeader={setNewHeader}
                        />
                    ]}
                />

            </>}
            footer={<>
                <Form.Label>* (Obligatorio)</Form.Label>
                <ButtonIcon name='Guardar' icon={faSave} onClick={saveInventory} variant='outline-success' />
            </>}
        />
    )
}

export default NewStore