import React from 'react'
import { Document, Page, Text, View,Image, StyleSheet  } from '@react-pdf/renderer'
import moment from 'moment';
import Cap from '../../../img/cap.jpg'
import Capp from '../../../img/carreterass2.PNG'


const styles = StyleSheet.create({
    body:{ padding: 15 },
    Head:{
        display: "flex",
        flexDirection: "row"
    },
    Head_Image:{ width: 70 },
    table: { 
      display: "table",
      borderStyle: "solid",  
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      marginBottom: 15,
      borderColapse: "collapse",
      tableLayout: "auto",
      width: "auto", 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderTopWidth: 1,
      width: "100%"
    }, 
    tableCol: { 
      borderStyle: "solid",
      borderRightWidth: 1,
      width: "100%",
      height: "auto"
    }, 
    tableCell: { 
      margin: "auto", 
      marginVertical: 5,
      marginHorizontal: 3,
      fontSize: 10,
      textAlign: "center"
    },
    text_center: { textAlign: "center" , fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_title: { textAlign: "center", fontWeight: "bold", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_center50: { textAlign: "center" , fontSize: 12, width: "50%", borderStyle: "solid", borderBottomWidth: 1, borderRightWidth:1 },
    logo:{
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    },
    logo1:{
        width: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid", 
        borderWidth: 1,
    },
    d_flex:{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    }, txt_center: { textAlign: "center" } ,
    text_logo: {textAlign: "center" , fontSize: 19, fontWeight: "heavy", width: "100%", borderStyle: "solid", borderTopWidth: 1, color: "#009644"},
    mb_5:  { marginBottom: 14, borderStyle: "solid", 
    borderWidth: 1, },
    fz_12: {fontSize: 12, textAlign: "center",  width: "100%"},
    flex:{
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" },
    cuad: { display: "flex", flexDirection: "row" , flexWrap: "wrap", justifyContent: "center" }
});

const EjectuvoPdf = ({activity, Incidents, Project}) => {
    return (
        <Document>
            <Page size='A4' style={styles.body}>
                <View style={styles.mb_5} fixed>
                    <View style={styles.Head}>
                        <View style={styles.logo}>
                            <Image src={Cap} style={styles.Head_Image}/>
                        </View>

                        <View style={styles.d_flex}>
                            <Text style={{fontWeight: 900, textAlign: 'center'}}>Construcciones Amozoc Perote S.A de C.V.</Text>
                            <Text style={styles.text_center}>GESTIÓN DE ACTIVOS </Text>
                            <Text style={styles.text_center}>CM-CAPSA-MTTO SGA</Text>
                            <Text style={styles.fz_12}>Reporte Ejecutivo</Text>
                            
                        </View>

                        <View style={styles.logo1}>
                            <Text style={styles.text_center}>Autopista Amozoc - Perote</Text>
                            <Text style={styles.text_center}>CÓDIGO: -</Text>
                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                <Text style={styles.text_center50}>VERSIÓN: 1</Text>
                                <Text style={styles.text_center50}>FECHA DE EMISIÓN: { moment().format("D/MM/YY") }</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <Text style={{ fontSize: 14, textAlign: "center", marginBottom: 7 }}>Resumen ejecutivo</Text>
                <Text style={{ fontSize: 12, textAlign: "justify", marginBottom: 4, paddingHorizontal: 30 }}>La empresa Capsa presenta wrestlemania greatest momentsel reporte de gestión de la Autopista {Project.name} </Text>
                <Text style={{ fontSize: 12, textAlign: "justify", marginBottom: 4, paddingHorizontal: 30 }}>Este informe detalla las actividades de mantenimiento realizadas en la autopista para garantizar un servicio seguro y eficiente a los usuarios.La aplicación web desarrollada por Capsa permite una gestión eficaz de la autopista, proporcionando información actualizada sobre el estado de las carreteras y las actividades de mantenimiento programadas.</Text>
                <Text style={{ fontSize: 12, textAlign: "justify", marginBottom: 4, paddingHorizontal: 30 }}>La autopista Amozoc Perote se encuentra ubicado en 3 estados (Puebla, Tlaxcala y Veracruz), consta de tres tramos independientes y el Libramiento Perote se encuentra ubicado en el estado de Veracruz. La medición de los indicadores de desempeño deberá ser por cada uno de los tramos independientes mencionados a continuación: La Autopista Amozoc Perote se encuentra entre las siguientes autopistas: </Text>
                <Text style={{ fontSize: 12, textAlign: "justify", marginBottom: 4, paddingHorizontal: 30 }}>• Por el lado Sur con la Autopista Puebla Orizaba, interceptando en el kilómetro 137+455.</Text>
                <Text style={{ fontSize: 12, textAlign: "justify", marginBottom: 4, paddingHorizontal: 30 }}> • Por el lado Norte con la Autopista Perote Xalapa, interceptando en el km  00+000. El Libramiento Perote se encuentra entre las siguientes autopistas: </Text>
                <Text style={{ fontSize: 12, textAlign: "justify", marginBottom: 4, paddingHorizontal: 30 }}>• Por el lado Sur con la Autopista Libre Perote Xalapa, interceptando en el kilómetro 93+750.</Text>
                <Text style={{ fontSize: 12, textAlign: "justify", marginBottom: 4, paddingHorizontal: 30 }}> • Por el lado Norte con la Autopista Perote Xalapa, interceptado en el kilómetro 00+000.</Text>
                <View style={{ paddingHorizontal: 30 }}>
                    <Image src={Capp} style={{ width: 500 }}/>
                </View>
                
                <View style={styles.table} break> 
                    <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}>Actividades del programa de mantenimiento</Text> 
                    <View style={styles.tableRow}> 
                    {
                        ["#", "Código", "Nombre", "Cantidad Total","Unidad", "Fecha de creacion" ].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                       
                    {
                        (activity.length > 0) && activity.map((act, i) => 
                            <View style={styles.tableRow} key={act.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{i+1}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{act.code}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{act.name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{act.total_amount}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{act.unit}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{moment(act.created_at).format('LLL')}</Text></View>
                            </View> 
                        )
                    }
                </View>
                
                <View style={styles.table} break>
                    <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}>Incidencias</Text> 
                    <View style={styles.tableRow} fixed> 
                    {
                        ["#", "Estandar", "Estatus", "Creado por","Fecha creación", "Resolución estimada", "Punto inicial", "Punto final"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                       
                    {
                        Incidents && Incidents.map((inc, i) => 
                            <View style={styles.tableRow} key={inc.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{i+1}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.standard.name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.status.name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.created_by.first_name} {inc.created_by.last_name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{moment(inc.creation_date).format("DD [de] MMM YYYY HH:mm")}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.data?.estimated_date ? moment(inc.data?.estimated_date).format("DD [de] MMM YYYY HH:mm") : 'Sin fecha'}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.longitude && inc.longitude.toFixed(3)} {inc.latitude && inc.latitude.toFixed(3)}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.longitude_E && inc.longitude_E.toFixed(3)} {inc.latitude_E && inc.latitude_E.toFixed(3)}</Text></View>
                            </View> 
                        )
                    }
                </View>

                <View style={styles.table} break> 
                    <View style={styles.tableRow} fixed> 
                        <View style={styles.tableCol} > 
                            <Text style={styles.tableCell}>Evaluación</Text> 
                        </View> 
                    </View>
                       
                    {
                        Incidents && Incidents.map((inc, i) => 
                            <View style={styles.tableRow} key={inc.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}> {inc.data.evaluation.name}</Text></View>
                            </View> 
                        )
                    }
                </View>

                <View style={{display: "flex", flexDirection: "row", marginTop: 100, fontSize: 12,justifyContent: "space-around"}} >
                    <View>
                        <Text>FIRMA DEL MRO (OPERADOR)</Text>
                    </View>
                    <View>
                        <Text>FIRMA DE LA SUPERVISIÓN (AAS)</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
export default EjectuvoPdf