import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default class extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            dataSet: []
        }
    }

    componentDidMount = () => {
        /*columns: ["Unidades", "Descripción", "Precio", "Observaciones"],*/
        let dataSet = [{
            columns: ["Unidades", "Descripción", "Precio", "Observaciones"],
            data: []
        }];
        for(let i = 0; i < this.props.report.supplies.length; i++){
            let data = [];
            let item = this.props.report.supplies[i];
            data.push(item.count);
            data.push(item.description);
            data.push(`$ ${item.amount.toFixed(2)}`);
            data.push(item.observations);
            dataSet[0].data.push(data);
        }
        this.setState({dataSet});
    }


    render() {
        return (
            this.state.dataSet.length !== 0 ?
                <ExcelFile fileExtension={this.props.extension} element={<button className={'XLSXDownloadLink'}>{this.props.label}</button>} filename={`Orden_de_Trabajo_${this.props.report.headers.creation_date.split('T')[0].split('-').join('')}`}>
                    <ExcelSheet dataSet={this.state.dataSet} name="Orden de Trabajo" />
                </ExcelFile>
            : <button  style={{cursor: 'progress'}} className={'XLSXDownloadLink'}>{this.props.label}...</button>
        );
    }
}


/**
 * <ExcelSheet data={this.props.report.supplies} name="Orden de Trabajo">
                        <ExcelColumn label="Unidades" value="count" />
                        <ExcelColumn label="Descripción" value="description" />
                        <ExcelColumn label="Precio" value={(col) => col.amount.toFixed(2)}/>
                        <ExcelColumn label="Observaciones" value="observations"/>
                    </ExcelSheet>
 */