import React, { useEffect, useState } from 'react'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import { useMutation } from '@apollo/client';
import { INSERT_MESSAGE } from 'graphql/mutations/_messenger/MessagesMutations';

const Send = (props) => {
	const { room_id, user_id } = props;

	const [message, setMessage] = useState('');             // Mensaje que se envía
	const [storeMessage, { loading, error, data }] = useMutation(INSERT_MESSAGE);

	const handleMessage = (e) => {
		setMessage(e.target.value);
	}

	useEffect(() => {
		if (loading) console.log('Loading...');
		if (error) console.log(`Error! ${error.message}`);
		if (data) console.log("Data: ", data);

	}, [loading, error, data]);
	
	const sendMessage = async () => {
		if (room_id) {
			storeMessage({ variables: { user_id, message, room_id } });
			setMessage('');
		}
	}

	const enterEvent = (e) => {
		if (e.key === 'Enter') sendMessage();
	}

	return (
		<Row className="m-3" style={{ heigth: '10vh' }}>
			<Col sm={11}>
				<FormIcon
					placeholder="Escribe un mensaje"
					handleChange={handleMessage}
					value={message}
					onKeyDown={enterEvent}
				/>
			</Col>
			<Col sm={1}>
				<ButtonIcon
					icon={faPaperPlane}
					variant='outline-success' size="md"
					tooltip='Enviar mensaje'
					onClick={sendMessage}
					disabled={!room_id}
				/>
			</Col>
		</Row>
	)
}

export default Send;
