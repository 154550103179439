import React, { useEffect, useState } from 'react';
import { Box, Image, useDisclosure } from '@chakra-ui/react';
import { downloadLogoApps } from 'api/general/apps';
import { Tag } from 'antd';
import android_logo from '../imagenes-app/android.png';
import ios_logo from '../imagenes-app/ios.png';
import './Card.css';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faQrcode, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import ModalQr from './ModalQr';
import ModalAccess from './ModalAccess';

const moment = require('moment');

const Card = (props) => {
    const { item } = props;
    const [image, setImage] = useState(null);
    const disclosureQr = useDisclosure();
    const disclosureAccess = useDisclosure();

    const { id, package_id, code, name, logo, so, created_at } = item;

    useEffect(() => {
        const getLogo = async () => {
            const { response } = await downloadLogoApps({ package_id, id, file_name: logo });
            if (response) {
                let url = window.URL || window.webkitURL;
                let thumbnailURL = url.createObjectURL(response);
                const thumbnail = {
                    file: response,
                    image: thumbnailURL,
                    created_at: moment(created_at).format('LLLL'),
                    fileName: response.name,
                };
                // Actualiza el estado con las nuevas miniaturas
                setImage(thumbnail);
            }
        };
        if (logo) getLogo();

    }, []);

    const getQrCode = () => disclosureQr.onOpen();
    const getAccess = () => disclosureAccess.onOpen();

    return (
        <>
            <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                <div class="image-container">
                    <Image
                        className='custom-image'
                        src={image?.image || (so === 'android' ? android_logo : ios_logo)}
                        alt={'logo'}
                    />
                    <div class="overlay">
                        {
                            so === 'android' ?
                                <ButtonIcon icon={faQrcode} size='lg' tooltip='Escanear QR' variant='outline-success' onClick={getQrCode} /> :
                                <ButtonIcon icon={faSignInAlt} size='lg' tooltip='Solicitar Acceso' variant='outline-success' onClick={getAccess} />
                        }
                    </div>
                </div>
                <Box p='6'>
                    {/* Tags del estado y línea base */}
                    <Box display='flex' alignItems='baseline'>
                        <Box>
                            <Tag color={'teal'} >{name}</Tag>
                        </Box>
                        <Box>
                            <Tag color={'default'} >{code}</Tag>
                        </Box>
                        <Box>
                            <Tag
                                color={so === 'android' ? '#87d068' : '#108ee9'} >
                                {so}
                            </Tag>
                        </Box>
                    </Box>

                </Box>
            </Box>
            {/* Modal para el Qr - sólo para Android*/}
            {
                disclosureQr.isOpen && <ModalQr item={item} onClose={disclosureQr.onClose} />
            }
            {/* Modal para el Acceso de iOS */}
            {
                disclosureAccess.isOpen && <ModalAccess item={item} onClose={disclosureAccess.onClose} />
            }
        </>
    );
}

export default Card;