import axios from "axios";

const axiosAPIInstance = axios.create({
    baseURL: `https://www.facturapi.io/v2`
});


const fetcherFa = async (args) => {
    let response;
    try {
        response = await axiosAPIInstance(args);
        return response;
    } catch (error) {
        if (error.response) {
            // La petición fue realizada y el servidor responde con un status code
            // Si obtiene una respuesta Unauthenticate, redirigir al login
            console.log("Error response: ", error.response);
            if (error.response.status === 401) { // Si es una petición no autorizada
                // window.location.href = '/logout';
                // console.log("Cerrando sesión!", document, window);
            }
            response = error.response;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            response = error.request;
        } else {
            // Alguna configuración de la petición ha provocado un error
            response = error.message;
        }
    } finally {
        return response;
    }
}

export default fetcherFa;