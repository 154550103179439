import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { faDollarSign, faPercentage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const CustomForm = ({ label, name, noPercentage, value, valuePercentage, handleChangePercentage }) => {

    return (<Row>
        <Col></Col>
        <Col>
            <Form.Label>{label}</Form.Label>
        </Col>
        <Col>
            {
                !noPercentage &&
                <InputGroup>
                    <Input type={'number'} name={name} placeholder={''} value={valuePercentage} onChange={handleChangePercentage} />
                    <InputRightElement color='gray.300' children={<FontAwesomeIcon icon={faPercentage} />} />
                </InputGroup>
            }
        </Col>
        <Col>
            <InputGroup>
                <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em' children={<FontAwesomeIcon icon={faDollarSign} />} />
                <Input type={'number'} name={name} placeholder={''} defaultValue={0} value={value} isReadOnly />
            </InputGroup>
        </Col>
    </Row>)
}

const Charges = ({ direct_costs, dataPercentage, setDataPercentage, total_cost, setTotalCost }) => {
    const [data, setData] = useState({})
    const [indirectTotal, setIndirectTotal] = useState(0)

    useEffect(() => {
        const obj = {};
        for (const [key, value] of Object.entries(dataPercentage)) {
            obj[key] = (value / 100) * direct_costs;
        }
        setData(obj);
    }, [dataPercentage])

    useEffect(() => {
        const values = Object.values(data);
        const additionalCosts = values.reduce((total, value) => total + value, 0);
        setIndirectTotal(additionalCosts);
        setTotalCost(additionalCosts + direct_costs);
    }, [data, direct_costs])

    const handleChangePercentage = (event) => {
        const { name, value } = event.target;
        setDataPercentage(prevState => ({ ...prevState, [name]: value }));
    }

    return (
        <div>
            <CustomForm label='Indirectos de oficina' name='indirect_office_costs' value={data?.indirect_office_costs}
                valuePercentage={dataPercentage?.indirect_office_costs} handleChangePercentage={handleChangePercentage} />
            <CustomForm label='Indirectos de campo' name='indirect_field_costs' value={data?.indirect_field_costs}
                valuePercentage={dataPercentage?.indirect_field_costs} handleChangePercentage={handleChangePercentage} />
            <CustomForm label='Financiamiento' name='financing_costs' value={data?.financing_costs}
                valuePercentage={dataPercentage?.financing_costs} handleChangePercentage={handleChangePercentage} />
            <CustomForm label='Ulilidad' name='utility_costs' value={data?.utility_costs}
                valuePercentage={dataPercentage?.utility_costs} handleChangePercentage={handleChangePercentage} />
            <CustomForm label='Cargos adicionales' name='additional_charges' value={data?.additional_charges}
                valuePercentage={dataPercentage?.additional_charges} handleChangePercentage={handleChangePercentage} />
            <CustomForm label='Otros porcentajes' name='other_percentages' value={data?.other_percentages}
                valuePercentage={dataPercentage?.other_percentages} handleChangePercentage={handleChangePercentage} />
            <CustomForm label='Total sobrecostos' name='indirect_total' value={indirectTotal} noPercentage />
            <CustomForm label='Costo Total' name='total_cost' value={total_cost} noPercentage />
        </div>
    )
}

export default Charges