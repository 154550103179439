import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

const CREATED = 201;
const STATUS_OK = 200;

export const indexMaintenanceProgram = async (query) => {
    const { project_id, package_id } = query;
    const url = `/packages/${package_id}/projects/${project_id}/victum_maintenance_program`;
    let response = { data: [], totalItems: 0 };
    let props = query;
    delete props.project_id;
    delete props.package_id;
    let params = {};
    
    const queryValues = Object.values(props);
    const queryKeys = Object.keys(props);
    for (let index = 0; index < queryValues.length; index++) {
        const element = queryValues[index];
        if (element !== '0')
        params[queryKeys[index]] = element;
    }
    
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_program.js ~ line 14 ~ indexMaintenanceProgram ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}

export const storeMaintenanceProgram = async ({ project_id, package_id, data }) => {
    const url = `/packages/${package_id}/projects/${project_id}/victum_maintenance_program`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: maintenance_program.js ~ line 32 ~ storeMaintenanceProgram ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteMaintenanceProgram = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/victum_maintenance_program/${id}`;
    let response = false;
    console.log("🚀 ~ file: maintenance_program.js:61 ~ deleteMaintenanceProgram ~ url:", url)
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: maintenance_program.js:65 ~ deleteMaintenanceProgram ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data;
        }
        return { response };
    } catch (error) {
        return { response };
    }
}