import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import Map from '../../../Mantemiento/Inventarios/Map'
import Swal from "sweetalert2"

const ModalMap = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			uploading: false,
		}
	}

	componentDidMount = () => {
		setTimeout(() => this.setState({ show: true }), 100);
	}

	handleClose = () => {
		if (this.state.uploading) {
			Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
			return;
		}
		this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
	}

	render = () => {
		return (
			<Modal
				show={this.state.show}
				onHide={this.handleClose}
				backdrop="static"
				keyboard={false}
				size="lg"
				centered>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ height: '500px', width: '100%' }}>
					<center>
						<Map
							ubications={this.props.ubications}
							center={this.props.ubications[0].position}
							initialCenter={this.props.ubications[0].position}
						/>
					</center>
				</Modal.Body>
			</Modal >
		);
	}
}

export default ModalMap