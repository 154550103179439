import { Heading } from '@chakra-ui/react';
import { faEye, faEyeSlash, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { getProjects } from 'api/general/getProjects';
import { indexRoadSections } from 'api/general/road_sections';
import { storeUsers, updateUsers } from 'api/users/users';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon2 from 'components/layouts/FormIcon2';
import { allowModule } from 'components/libs/allowModule';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const EditUsers = (props) => {
    const { package_id, roles, userSel, setUserSel, addUser, setAddUser,
        setUserForm, userForm, setUsers, view, setView, btnUser } = props;

    const currentPackage = useSelector(state => state.login.dataUser.package);

    const [showPassword, setShowPassword] = useState(false);            // Ver y ocultar la contraseña
    const [projects, setProjects] = useState([]);
    const [roadSections, setRoadSections] = useState([]);

    // Projectos del paquete
    useEffect(() => {
        const getAllProjects = async () => {
            const response = await getProjects();
            setProjects(response);
        }
        getAllProjects();
    }, []);

    // Tramos carreteros del paquete
    useEffect(() => {
        const getRoadSections = async () => {
            const { response } = await indexRoadSections({ package_id });
            setRoadSections(response);
        }
        getRoadSections();
    }, []);

    const newUser = () => {
        setUserSel(null);
        setAddUser(true);
        setUserForm(prevState => ({
            ...prevState,
            package_id,
            project_id: null,
            road_section_id: null,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            status: 1
        }));
    }

    const createOrUpdateUser = async () => {
        if (addUser) {
            // Agregar usuario
            const { first_name, last_name, project_id, road_section_id,
                status, user_type_id, phone, email, username, password } = userForm;
            const data = {
                first_name, last_name, project_id, road_section_id,
                status, user_type_id, phone, email, username, password
            };
            data.package_id = userForm.package_id;
            const { response } = await storeUsers({ package_id, data });
            if (response) {
                Swal.fire('¡Registro exitoso!', 'Se ha añadido al usuario de forma satisfactoria.', 'success');
                const userAdded = { id: response, ...data };
                setUsers(prevState => [...prevState, userAdded])
                setUserSel(userAdded);
            } else {
                Swal.fire('¡Registro fallido!', 'Ha ocurrido un error al tratar de registrar al usuario. Verifique los datos.', 'warning');
            }
        } else {
            // Actualizar usuario
            const data = {
                last_name: userForm.last_name,
                package_id: userForm.package_id,
                project_id: userForm.project_id,
                road_section_id: userForm.road_section_id,
                ...getChanges(),
            }
            const { response } = await updateUsers({ package_id, user_id: userSel.id, data });
            if (response) {
                Swal.fire('Actualización exitosa!', 'Se han actualizado los datos del usuario de forma satisfactoria.', 'success');
                setUsers(prevState => prevState.map((item) => {
                    let updateItem = item;
                    if (userSel.id === item.id) {
                        updateItem = { ...item, ...data }
                    }
                    return updateItem;
                }));
            } else {
                Swal.fire('Actualización fallida!', 'Ha ocurrido un error al tratar de actualizar los datos del usuario. Verifique los datos.', 'warning');
            }
        }
    }

    // Obtener atributos que fueron modificados
    const getChanges = () => {
        const clavesAnteriores = Object.keys(userSel);
        const clavesActuales = Object.keys(userForm);
        const atributosModificados = {};

        clavesAnteriores.forEach((clave) => {
            if (clavesActuales.includes(clave)) {
                const valorAnterior = userSel[clave];
                const valorActual = userForm[clave];
                if (valorAnterior !== valorActual) {
                    atributosModificados[clave] = valorActual;
                }
            }
        });
        if (clavesActuales.includes('password')) {
            atributosModificados['password'] = userForm['password'];
        }

        return atributosModificados;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserForm(prevState => ({ ...prevState, [name]: value }));
    }

    return (
        <Col xs={24} sm={24} md={6} lg={6} className='users-edit'>
            <Row className='users-edit-title'>
                <Row className='users-edit-title-row'>
                    <Col className='users-edit-title-h' xs={16}>
                        <Heading size='md' color={'black'}>Datos generales</Heading>
                    </Col>
                    <Col className='users-edit-title-add' xs={8}>
                        {
                            allowModule({ permission: 'WAAAA' }) &&
                            <ButtonIcon name='Agregar' icon={faPlus} variant='success' onClick={newUser} tooltipDisabled={true} />
                        }
                    </Col>
                </Row>
            </Row>
            <div className='users-edit-form'>
                {
                    (userSel || addUser) && <>
                        <FormIcon2 title='Nombre(s)' type='first_name' name='first_name' value={userForm?.first_name} handleChange={handleChange} />
                        <FormIcon2 title='Apellido(s)' type='last_name' name='last_name' value={userForm?.last_name} handleChange={handleChange} />
                        <FormIcon2 title='Correo electrónico' type='email' name='email' value={userForm?.email} handleChange={handleChange} />
                        <FormIcon2 title='Teléfono' type='phone' name='phone' value={userForm?.phone} handleChange={handleChange} />
                        <Form.Group className='col'>
                            <Form.Label>Rol</Form.Label>
                            <select className='form-control' name='user_type_id'
                                value={userForm?.user_type_id} onChange={handleChange}>
                                {
                                    roles.map((role) =>
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    )
                                }
                            </select>
                        </Form.Group>
                        <Form.Group className='col'>
                            <Form.Label>Estado</Form.Label>
                            <select className='form-control' name='status'
                                value={userForm?.status} onChange={handleChange}>
                                <option value={1}>Activo</option>
                                <option value={0}>Inactivo</option>
                            </select>
                        </Form.Group>
                        <FormIcon2 title='Usuario' name='username' value={userForm?.username} handleChange={handleChange} readOnly={userSel ? true : false} autoComplete={'off'} />
                        <FormIcon2 type={showPassword ? 'text' : 'password'} title='Contraseña' name='password' handleChange={handleChange} autoComplete={'off'}
                            re_icon={<FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />} re_onClick={() => setShowPassword(!showPassword)}
                        />
                        <Form.Group>
                            <Form.Label>Acceso a</Form.Label>
                            <Form.Group as={Row}>
                                <div className={roadSections.length > 0 ? 'col-sm-4' : 'col-sm-6'}>
                                    <input checked={userForm.package_id !== null} onChange={(e) => {
                                        if (e.target.checked) {
                                            setUserForm(prevState => ({
                                                ...prevState,
                                                package_id,
                                                road_section_id: null,
                                                project_id: null
                                            }));
                                            setView('package');
                                        }
                                    }} type='radio' name="access" id="access1" /><label style={{ fontSize: 15, color: '#263545', fontWeight: 'normal' }} for="access1">Paquete completo</label>
                                </div>
                                {
                                    roadSections.length > 0 &&
                                    <div className='col-sm-4'>
                                        <input checked={userForm.road_section_id !== null} onChange={(e) => {
                                            if (e.target.checked) {
                                                setUserForm(prevState => {
                                                    let road_section_id = null;
                                                    if (roadSections.length > 0)
                                                        road_section_id = roadSections[0].id;
                                                    return {
                                                        ...prevState,
                                                        package_id: null,
                                                        road_section_id,
                                                        project_id: null
                                                    }
                                                });
                                                setView('road');
                                            }
                                        }} type='radio' name="access" id="access2" /><label style={{ fontSize: 15, color: '#263545', fontWeight: 'normal' }} for="access2">Tramo carretero</label>
                                    </div>
                                }
                                <div className={roadSections.length > 0 ? 'col-sm-4' : 'col-sm-6'}>
                                    <input checked={userForm.project_id !== null} onChange={(e) => {
                                        if (e.target.checked) {
                                            setUserForm(prevState => {
                                                let project_id = null;
                                                if (projects.length > 0)
                                                    project_id = projects[0].id;
                                                return {
                                                    ...prevState,
                                                    package_id: null,
                                                    road_section_id: null,
                                                    project_id
                                                }
                                            });
                                            setView('project');
                                        }
                                    }} type='radio' name="access" id="access3" /><label style={{ fontSize: 15, color: '#263545', fontWeight: 'normal' }} for="access3">Una autopista</label>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                {
                                    view === 'package' &&
                                    <Col>
                                        <input checked={currentPackage.id === userForm.package_id} type='radio' name="package" id={`package`} /><label style={{ fontSize: 15, color: '#263545', fontWeight: 'normal' }} for={`package`}>{currentPackage.code}: {currentPackage.name}</label>
                                    </Col>
                                }
                                {
                                    view === 'road' && roadSections.length > 0 &&
                                    roadSections.map((item, i) =>
                                        <Col key={item.id}>
                                            <input checked={item.id === userForm.road_section_id} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setUserForm(prevState => ({ ...prevState, road_section_id: item.id }));
                                                }
                                            }} type='radio' name="road" id={`road${i}`} /><label style={{ fontSize: 15, color: '#263545', fontWeight: 'normal' }} for={`road${i}`}>{item.name}</label>
                                        </Col>
                                    )
                                }
                                {
                                    view === 'project' &&
                                    projects.map((item, i) =>
                                        <Col key={item.id}>
                                            <input checked={item.id === userForm.project_id} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setUserForm(prevState => ({ ...prevState, project_id: item.id }));
                                                }
                                            }} type='radio' name="project" id={`project${i}`} /><label style={{ fontSize: 15, color: '#263545', fontWeight: 'normal' }} for={`project${i}`}>{item.name}</label>
                                        </Col>
                                    )
                                }
                            </Form.Group>
                        </Form.Group>
                    </>
                }
            </div>
            <Row className='users-edit-footer'>
                {/* Actualizar datos */}
                {
                    userSel && allowModule({ permission: 'WAAAB' }) &&
                    <ButtonIcon
                        name='Actualizar'
                        icon={faSave}
                        onClick={createOrUpdateUser}
                        variant='outline-success'
                        tooltipDisabled={true}
                        disabled={btnUser}
                    />
                }
                {/* Nuevo usuario */}
                {
                    addUser && allowModule({ permission: 'WAAAA' }) &&
                    <ButtonIcon
                        name='Agregar'
                        icon={faSave}
                        onClick={createOrUpdateUser}
                        variant='outline-success'
                        tooltipDisabled={true}
                        disabled={btnUser}
                    />
                }
            </Row>
        </Col>
    )
}

export default EditUsers