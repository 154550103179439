import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalComp from 'components/layouts/Modal'
import ButtonIcon from 'components/layouts/ButtonIcon'
import { Col, Form, FormGroup, Row } from 'react-bootstrap'
import FormIcon2 from 'components/layouts/FormIcon2'
import { Checkbox } from '@chakra-ui/react'
import { indexAssetsComponentTypes } from 'api/operation_standards/assets_component_types'
import { indexAssetsComponentStatuses } from 'api/operation_standards/assets_component_statuses'
import { storeAssetsComponents } from 'api/operation_standards/assets_componets'
import Swal from 'sweetalert2'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'

const AddComponentModal = ({ onClose, asset, table, package_id }) => {
    const [form, setForm] = useState({});
    const [componentTypes, setComponentTypes] = useState([]);
    const [componentStatuses, setComponentStatuses] = useState([]);

    useEffect(() => {
        const getComponentTypes = async () => {
            const { response } = await indexAssetsComponentTypes({ package_id, table });
            setComponentTypes(response.data);
        }
        const getComponentStatuses = async () => {
            const { response } = await indexAssetsComponentStatuses({ package_id, table });
            setComponentStatuses(response.data);
        }
        getComponentTypes();
        getComponentStatuses();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm(prevState => ({ ...prevState, [name]: value }));
    }

    const handleChangeCheck = (event) => {
        const { checked } = event.target;
        setForm(prevState => ({ ...prevState, has_warranty: checked }))
    }
    // Crear el componente
    const onSave = async () => {
        if (validar()) {
            const { id, assets_base_line_id } = asset;
            const data = { ...form, assets_base_line_id }
            const response = await storeAssetsComponents({ table, asset_id: id, data });
            if (response) {
                onClose();
                Swal.fire('¡Operación exitosa!', 'El componente ha sido agregado de forma satisfactoria.', 'success');
            } else {
                Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de almarcenar el componente. Intente más tarde.', 'error');
            }
        }
    }

    const validar = () => {
        const {
            code,
            component_status_id,
            component_type_id,
            brand,
            model,
            no_serie,
            description,
            manufacturer,
            has_warranty,
            warranty_expiration_date,
            purchase_date,
        } = form;

        const validateSchema = Yup.object().shape({
            code: Yup.string().required('Introducir el código'),
            component_status_id: Yup.string().notOneOf(['0']).required('Introducir el estado del componente'),
            component_type_id: Yup.string().notOneOf(['0']).required('Introducir el tipo de componente'),
            brand: Yup.string().required('Introducir la marca del componente'),
            model: Yup.string().required('Introducir el modelo del componente'),
            no_serie: Yup.string().required('Introducir el número de serie del componente'),
            description: Yup.string().required('Introducir la descripción del componente'),
            manufacturer: Yup.string().required('Introducir el fabricante del componente'),
            purchase_date: Yup.string().required('Introducir la fecha de compra del componente'),
            warranty_expiration_date: has_warranty
                ? Yup.string().required('Introducir la fecha de expiración del componente')
                : Yup.string(),
        });

        try {
            validateSchema.validateSync(
                {
                    code,
                    component_status_id,
                    component_type_id,
                    brand,
                    model,
                    no_serie,
                    description,
                    manufacturer,
                    purchase_date,
                    warranty_expiration_date,
                },
                { abortEarly: false }
            );
            return true;
        } catch (error) {
            const mensajesErrores = error.errors.map((err, index) => `${index + 1}. ${err}`);
            Swal.fire({
                title: '¡Formulario incompleto!',
                html: `<ul>${mensajesErrores.map((error) => `<li>${error}</li>`).join('')}</ul>`,
                icon: 'info',
            });
            return false;
        }
    };

    return (
        <ModalComp
            onClose={onClose}
            size='xl'
            title='Agregar componente'
            body={<>
                <Row>
                    <FormIcon2 title='Código' name='code' type='text' value={form?.code} handleChange={handleChange} maxLength={10} />
                    <FormIcon2 title='Identificador de monitoreo ITS' name='monitoring_id' type='number' value={form?.code} handleChange={handleChange} />
                    <FormGroup as={Col}>
                        <Form.Label>Estado</Form.Label>
                        <select className='form-control' value={form?.component_status_id} name="component_status_id" onChange={handleChange} placeholder="Seleccione un estado" style={{ width: '100%' }}>
                            <option value="0">-- Seleccione un estado</option>
                            {
                                componentStatuses.map((item, index) =>
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                        </select>
                    </FormGroup>
                    <FormGroup as={Col}>
                        <Form.Label>Tipo</Form.Label>
                        <select className='form-control' value={form?.component_type_id} name="component_type_id" onChange={handleChange} placeholder="Seleccione un tipo" style={{ width: '100%' }}>
                            <option value="0">-- Seleccione un tipo</option>
                            {
                                componentTypes.map((item, index) =>
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                        </select>
                    </FormGroup>
                </Row>
                <Row>
                    <FormIcon2 title='Marca' name='brand' type='text' value={form?.brand} handleChange={handleChange} maxLength={100} />
                    <FormIcon2 title='Modelo' name='model' type='text' value={form?.model} handleChange={handleChange} maxLength={50} />
                    <FormIcon2 title='Número de serie' name='no_serie' type='text' value={form?.no_serie} handleChange={handleChange} maxLength={100} />
                </Row>
                <Row>
                    <FormGroup as={Col}>
                        <Form.Label>Descripción</Form.Label>
                        <textarea className='form-control' name="description" value={form?.description} onChange={handleChange} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormIcon2 title='Fabricante' name='manufacturer' type='text' value={form?.manufacturer} handleChange={handleChange} maxLength={100} />
                    <FormGroup as={Col}>
                        <Form.Label>Tiene garantía?</Form.Label>
                        <Checkbox className='form-control' onChange={handleChangeCheck}></Checkbox>
                    </FormGroup>
                    <FormIcon2 title='Fecha de compra' name='purchase_date' type='date' value={form?.purchase_date} handleChange={handleChange} />
                    {
                        form?.has_warranty &&
                        <FormIcon2 title='Fecha de expiración de la garantía' name='warranty_expiration_date' type='date' value={form?.warranty_expiration_date} handleChange={handleChange} />
                    }
                </Row>
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                <ButtonIcon icon={faSave} name='Guardar' variant='outline-success' onClick={onSave} />
            </>}
        />
    )
}

AddComponentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    asset: PropTypes.object.isRequired,
    package_id: PropTypes.number.isRequired,
    table: PropTypes.oneOf([
        'assets_its',
        'assets_environment',
        'assets_edification',
        'assets_infrastructure'
    ]).isRequired,
}

export default AddComponentModal