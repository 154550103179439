import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxLDV = () => {
    const table = 'right_way_cleaning_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 70 },
        { label: 'SENTIDO', description: 'Sentido', value: 'direction', type: 'String', show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 80 },
        { label: 'KM_INICIAL', description: 'Kilometro incial', value: 'start_km', type: 'String', show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'KM_FINAL', description: 'Kilometro final', value: 'end_km', type: 'String', show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'PINTURA', description: 'Observaciones pintura', value: 'observations_paint', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'ALAMBRE', description: 'Observaciones alambre', value: 'observations_wire', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'LONGITUD', description: 'Longitud', value: 'longitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'LATITUD', description: 'Latitud', value: 'latitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'OBSERVACIONES', description: 'Observaciones', value: 'observations', type: 'String', allow: null, edit: true, add: true, width: 30, widthXLSX: 130 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30 },
    ];

    const km = [{ index: 2 }, { index: 3 }];    // Arreglo de indices que irán con formato 000+000
    const percentage = [];                      // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxLDV;