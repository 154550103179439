import React, { Component } from "react";
import logo from './../imagenes-app/logo.png';
import pdf_image from './../imagenes-app/pdf.png';
import './../css/Menu.css';
import './../css/Proyectos.css';
import Reports from "./Entregables/Reports";
import InventoryReports from "./Entregables/InventoryReports";
import Bienes from "./Entregables/Bienes";
import AuxVial from "./Entregables/AuxVial";
import ReportsMan from "./Entregables/ReportsMan";
import Ejecutivo from "./Entregables/Ejecutivo";
import MaintenanceProgram from './Entregables/MaintenanceProgram';

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            button_mantenimiento: false,
            headers_mantemimiento: null,
            body_mantemimiento: null,
            fecha_inicio: '',
            fecha_fin: '',
            sections: [
                {
                    id: 1,
                    name: 'Mantenimiento',
                    items: [
                        { name: 'REPORTE DE MANTENIMIENTO' },
                        { name: 'REPORTE DE INCIDENCIAS' },
                        { name: 'REPORTE DE INVENTARIOS' },
                        { name: 'REPORTE EJECUTIVO' },
                        { name: 'PROGRAMA DE CONSERVACIÓN RUTINARIA' },
                    ]
                },
                {
                    id: 2,
                    name: 'Peaje',
                    items: [
                        { name: 'BITÁCORA DE EVENTOS DIARIOS' },
                        { name: 'REPORTE DE EFICIENCIA' },
                        { name: 'REPORTE DE INGRESO DIARIO' },
                        { name: 'VOLUMENES DE TRANSITO HORARIO POR MOVIMIENTO' },
                        { name: 'PRE LIQUIDACION DE TURNO' },
                        { name: 'LIQUIDACION DE TURNO' },
                        { name: 'LIQUIDACION DEL DIA' },
                        { name: 'RESUMEN DE VEHICULOS EXENTOS POR DEPENDENCIA' },
                        { name: 'RESUMEN DE VEHICULOS EXENTOS POR CAJERO' },
                        { name: 'INFORME HISTÓRICO DE TARIFAS POR MOVIMIENTO' },
                        { name: 'CATALOGO DE CLIENTES' },
                        { name: 'IMPORTE DE CRUCES X CASETA' },
                        { name: 'REPORTE DE VENTAS X RANGO' },
                        { name: 'DEPOSITO Y CONSOLIDACIONES (CAPTURA DEPOSITO BANCARIO)' },
                        { name: 'REPORTE LISTA NEGRA' },
                        { name: 'CATALOGO DE ALARMAS' },
                    ]
                },
                {
                    id: 3,
                    name: 'Telepeaje',
                    items: [
                        { name: 'BITÁCORA DE TRANSACCIONES POR TARJETA' },
                        { name: 'BITÁCORA DE ALARMAS' },
                        { name: 'BITÁCORA DE ALARMAS X TURNO' },
                        { name: 'DISTRIBUCIÓN DE INGRESOS POR CASETA DE CONSOLIDADO POR CLIENTE' },
                    ]
                },
                {
                    id: 4,
                    name: 'Sistemas ITS',
                    items: [
                        { name: 'BITACORA DE EVENTOS DICTAMINADOS' },
                    ]
                },
                {
                    id: 5,
                    name: 'Derecho de vía',
                    items: [
                        { name: 'DATOS GENERALES DE LA OBRA VIAL CONCESIONADA(DATOS DEL SISTEMA DE PEAJE)' },
                        { name: 'Item 2_1_' },
                        { name: 'Item 3_1_' },
                        { name: 'Item 4_1_' },
                    ]
                },
                {
                    id: 6,
                    name: 'Atención a usuarios',
                    items: [
                        { name: 'DATOS GENERALES DE LA OBRA VIAL CONCESIONADA(DATOS DEL SISTEMA DE PEAJE)' },
                        { name: 'DATOS GENERALES DE LA OBRA VIAL CONCESIONADA(SERVICIOS AL USUARIO)' },
                    ]
                }, {
                    id: 7,
                    name: 'Mantenimiento de Bienes Afectos de la Concesión',
                    items: [
                        { name: 'Mantenimiento de Equipos y Bienes de Infraestructura' },
                        { name: 'Mantenimiento de Equipos y Bienes de ITS' },
                        { name: 'Mantenimiento de Equipos y Bienes de Telepeaje' },
                        { name: 'Mantenimiento de Equipos y Bienes de Edificación' },
                        { name: 'Mantenimiento de Equipos y Bienes de Medio Ambiente' },
                    ]
                }, {
                    id: 8,
                    name: 'Servicios a usuarios',
                    items: [{ name: 'Auxilio vial' }]
                }
            ],
            section_selected: 0,
            report_selected: 0
        }
    }

    componentDidMount = () => {
        this.getDates();
    }

    getDates = () => {
        let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        let current_date = new Date(); //Obtenemos la fecha actual
        this.setState({ fecha_inicio: `${current_date.getFullYear()}-${months[current_date.getMonth()]}-01` });
        current_date.setMonth(current_date.getMonth() + 1);
        this.setState({ fecha_fin: `${current_date.getFullYear()}-${months[current_date.getMonth() - 1]}-${new Date(current_date.getFullYear(), current_date.getMonth(), 0).getDate()}` });
    }

    handleChange = (name, value) => {
        this.setState({ [name]: value });
    }
    render = () => {
        const { section_selected, report_selected } = this.state;
        return (
            <div className="con_">
                <section className="contentPro">
                    {
                        this.state.sections.map((item, i) =>
                            <div key={i} className="contentP" onClick={e => this.handleChange('section_selected', i)}>
                                <img className="imagenP" src={logo} />
                                <h1 className="nombreP">{item.name}</h1>
                            </div>
                        )
                    }
                </section>
                <section className="contentPro">
                    {
                        this.state.sections[section_selected].items.map((item, i) =>
                            <div key={i} className="contentP" onClick={e => this.handleChange('report_selected', i)}>
                                <img className="imagenP" src={pdf_image} />
                                <h1 className="nombreP">{item.name}</h1>
                            </div>
                        )

                    }
                </section>

                {
                    `${section_selected}${report_selected}` === '00' && <ReportsMan package_id={this.props.project_id.package.id} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '01' && <Reports package_id={this.props.project_id.package} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '02' && <InventoryReports package_id={this.props.project_id.package} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '03' && <Ejecutivo package_id={this.props.project_id.package} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '04' && <MaintenanceProgram package_id={this.props.project_id.package.id} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '61' && <Bienes code='UOV-7.2' table='assets_its' package_id={this.props.project_id.package.id} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '63' && <Bienes code='UOV-7.4' table='assets_edification' package_id={this.props.project_id.package.id} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '64' && <Bienes code='UOV-7.5' table='assets_environment' package_id={this.props.project_id.package.id} project_id={this.props.projectId} />
                }
                {
                    `${section_selected}${report_selected}` === '70' && <AuxVial package_id={this.props.project_id.package.id} project_id={this.props.projectId} />
                }


            </div>
        )
    }
}