import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const downloadAssetsGlobalFiles = async ({ project_id, path_file, table, id }) => {
    const url = `/projects/${project_id}/${table}/global/${id}/files`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: { path_file }
        });
        // console.log("🚀 ~ file: assets_global_files.js:14 ~ downloadAssetsGlobalFiles ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const uploadAssetsGlobalFiles = async ({ project_id, table, id, data }) => {
    const url = `/projects/${project_id}/${table}/global/${id}/files`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            contentType: 'application/x-www-form-urlencoded',
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: assets_global_files.js:35 ~ uploadAssetsGlobalFiles ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok || fetch.status === httpStatus.created)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const destroyAssetsGlobalFiles = async ({ project_id, table, global_id, id }) => {
    const url = `/projects/${project_id}/${table}/global/${global_id}/files/${id}`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: assets_global_files.js:52 ~ destroyAssetsGlobalFiles ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data
        return { response, cancel };
    } catch (error) {
        return response;
    }
}