import { StyleSheet } from '@react-pdf/renderer'

const stylesHeader = StyleSheet.create({
    header: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20
    },
    headerColCenter: {
        width: "60%",
        alignItems: "center"
    },
    headerColRight: {
        width: "20%",
        alignContent: "flex-start",
        alignItems: "flex-start"
    },
    headerColLeft: {
        width: "20%",
        alignContent: "flex-end",
        alignItems: "flex-end"
    },
    imageRectangle: {
        height: 70,
        width: 100
    },
    imageSquare: {
        height: 70,
        width: 70
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#000"
    },
    subtitle: {
        fontSize: 16,
        color: "black",
    },
})

export default stylesHeader