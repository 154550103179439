import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import React from 'react'

const ViewOrders = (props) => {
    const { onClose } = props;
    return (
        <ModalComp
            title='Ordenes de trabajo'
            size='lg'
            onClose={onClose}
            body={<>
            </>}
            footer={<>
                <ButtonIcon onClick={onClose} name='Cerrar' variant='outline-secondary' icon={faTimes}/>
            </>}
        />
    )
}

export default ViewOrders