import moment from 'moment/moment';
import React, { useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocPDF from './DocPDF';

const ReporteModal = React.memo(({dataIncident, pum}) => {

    const [ data, setData ] = useState({
        evaluacion: " ",
        duracion: "2023-04-16T12:45:03.000Z",
        medida: 0,
        metodo_deductiva: "A"
	})

    const handleInputChange = e => {
		const {name, value} = e.target;
		setData({
			...data,
			[name]: value
		})
	}

    function renderSwitch(param) {
        switch(param) {
            case 0.5:
                return 'Inicial';
            case 1:
                return 'Correctivo';
            case 1.5:
                return 'Reiterativa';
            default:
                return 'Dato No valido';
        }
    }

    let pumPrice = 23403.6;
    (pum.length > 0) && (pumPrice = pum[0].price);
    let tipo_deductiva = renderSwitch(dataIncident?.data?.deductive?.factor);
    let deductiva = ((data.medida*100)/(dataIncident.segment.length_km*1000)).toFixed(5);

    let deductiva_percent_price = 0;

    deductiva_percent_price = (((dataIncident?.data?.deductive?.factor) * pumPrice)/100).toFixed(3) 
    
    let deductiva_final = deductiva * deductiva_percent_price;
    let pago_mro = pumPrice - deductiva_final;

    let tiempo = " ";
    if(dataIncident?.data?.estimated_date){
        tiempo = moment(dataIncident?.data?.estimated_date).diff(dataIncident.creation_date, 'days');
    } else {
        tiempo = moment(data.duracion).diff(dataIncident.creation_date, 'days');
    }

    return (
        <>
            <Table variant="light" className="my-5">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Estándar</th>
                        <th>Tipo de deductica</th>
                        <th>Fecha de levantamiento</th>
                        <th>Fecha de cierre aprox.</th>
                        <th>Tiempo de atención</th>         
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td>{dataIncident.id} </td>
                        <td>{dataIncident.standard.code}</td>
                        <td>{tipo_deductiva}</td>
                        <td>{ moment(dataIncident.creation_date).format("D MMM YYYY, h:mm:ss a") } </td>
                        <td>{   (dataIncident?.data?.estimated_date) ? (
                                    moment(dataIncident?.data?.estimated_date).format("D MMM YYYY, h:mm:ss a") 
                                ) : ( moment(data.duracion).format("D MMM YYYY, h:mm:ss a") )}</td>
                        <td>{tiempo} días</td>
                    </tr>
                </tbody>
            </Table>

            <Table  variant="light" className="my-3">
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Método de deductiva</th>
                        <th>Deductiva en %</th>
                        <th>IT-PUM</th>
                        <th>Deductiva</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{dataIncident.status.name}</td>
                        <td>{data.metodo_deductiva}</td>
                        <td>{dataIncident?.data?.deductive?.factor} %</td>
                        <td>$ {pumPrice}</td>
                        <td>$ {deductiva_percent_price}</td>
                    </tr>
                </tbody>
            </Table>

            <Table striped bordered hover variant="light">
                <thead>
                    <tr>
                        <th>Evaluación del incidente</th>
                        <th>Medida en METROS (m)</th>
                        <th>DEDUCTIVA FINAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{dataIncident?.data?.evaluation?.name}</td>
                        <td><Form.Control
                                type="number"
                                name="medida"
                                required
                                autoComplete='off'
                                min="1"
                                onChange={handleInputChange}
                        /></td>
                        <td>$ { (typeof deductiva_final === 'number') ?(
                                    deductiva_final.toFixed(3)
                                ): ('Elige una deductiva e ingresa la medida en metros')}</td>
                    </tr>
                </tbody>
            </Table>
            
            <section className='d-flex justify-content-around'>
                <PDFDownloadLink document={<DocPDF dataIncident={dataIncident} data={data} 
                                            tipo_deductiva={tipo_deductiva}
                                            pumPrice={pumPrice} pago_mro={pago_mro}
                                            deductiva_percent_price={deductiva_percent_price}
                                            deductiva_final={deductiva_final} tiempo={tiempo}  />} fileName="Reporte_deductiva.pdf">
                    <Button className="mr-3" variant="danger" ><FaFilePdf /> Generar reporte en PDF</Button>
                </PDFDownloadLink>
                {/* <Button variant="success"><FaFileExcel /> Generar reporte en Excel</Button> */}
            </section>
        </>
    )
})

export default ReporteModal