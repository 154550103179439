import fetcherCancelToken from "components/libs/fetcherCancelToken";

export const signon = async ({ username, password, token, app }) => {
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/signon/${app}`,
            method: 'POST',
            data: { username, password, token }
        });
        // console.log("🚀 ~ file: auth.js:10 ~ signon ~ fetch:", fetch)
        return { response: fetch };
    } catch (error) {
        return { response: false };
    }
}

export const verifySignon = async ({ username, code, token, app }) => {
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/verify_code`,
            method: 'POST',
            data: { username, code, app, token }
        });
        // console.log("🚀 ~ file: auth.js:24 ~ verifySignon ~ fetch:", fetch)
        return { response: fetch };
    } catch (error) {
        return { response: false };
    }
}

export const twoFactorAuth = async ({ user_id, has_two_factor }) => {
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/users/${user_id}/two_factor_auth`,
            method: 'PUT',
            data: { has_two_factor }
        });
        // console.log("🚀 ~ file: auth.js:38 ~ twoFactorAuth ~ fetch:", fetch)
        return { response: fetch };
    } catch (error) {
        return { response: false };
    }
}