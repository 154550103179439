import React, { Component } from "react";
import { __SERVER__ } from "server/info";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const FiltroBusqueda = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            change: false,
            query: {
                notice_dateS: "2019-11-01",
                notice_dateE: "2023-11-02",
                type_event_id: "0"
            },
            headers: [
                "",
                "",
                "Turno",
                "Plaza",
                "Carril",
                "Tipo de pago",
                "Tipo de vehículo"
            ],
            query_: "",
            type_event: []
        };
        this.generateQuery = this.generateQuery.bind(this)
        this.getTypeEvent = this.getTypeEvent.bind(this)
    }

    componentDidMount = () => {
        this.getTypeEvent();
    }

    getTypeEvent = () => {
        fetch(__SERVER__ + "/type_event", {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                return null
            }
        }).then(res => {
            let type_event = []
            if (res !== null) {
                type_event.push(<option value={0} key={'E'}></option>)
                for (let i = 0; i < res.length; i++) {
                    type_event.push(<option value={res[i].id} key={'E' + i}>{res[i].name}</option>)
                }
            }
            this.setState({ type_event: type_event })
        }).catch(e => {
            this.getTypeEvent()
        })
    }

    generateQuery = () => {
        if (this.state.query.notice_dateE === "" || this.state.query.notice_dateS === "") {
            MySwal.fire(<p>El rango de fechas es obligatorio</p>)
            return;
        }

        this.state.query_ = "?"

        let keys_query = Object.keys(this.state.query);
        let value_query = Object.values(this.state.query);
        let headersTable = []
        for (let i = 0; i < value_query.length; i++) {
            if (value_query[i].toString() !== "0") {
                this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
            } else {
                headersTable.push(this.state.headers[i])
            }
        }
        this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
        this.props.setProps(this.state.query_, headersTable)
    }

    changeInput = (e) => {
        this.state.query[e.target.name] = e.target.value;
        this.setState({ change: !this.state.change })
    }

    render() {
        return (
            <section className="sectionSearch">
                <article>
                    <label>Fecha</label>
                    <input type='date' name="notice_dateS" value={this.state.query.notice_dateS} onChange={this.changeInput} />
                </article>
                <article>
                    <label>Fecha</label>
                    <input type='date' name="notice_dateE" value={this.state.query.notice_dateE} onChange={this.changeInput} />
                </article>
                <article>
                    <label>Evento</label>
                    <select name="type_event_id" onChange={this.changeInput}>
                        {this.state.type_event.map(item => item)}
                    </select>
                </article>
                <article>
                    <button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
                </article>
            </section>
        );
    }
}

export default FiltroBusqueda;