import PaginationComp from 'components/layouts/Pagination';
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { getKmFormat } from '../../../../libs/functions';
import Ubication from './IncidentDetails/Ubication';
import { Alert, Skeleton } from 'antd';

const StepInventory = (props) => {
    const {
        data,
        handleChange,
        inventory,
        assetBaseLines,
        assets,
        page,
        pageSize,
        changePage,
        totalItems
    } = props;

    const [loadedUbication, setLoadedUbication] = useState(null);
    const [markerPos, setMarkerPos] = useState({ latitude: 0, longitude: 0 });

    useEffect(() => {
        const { latitude, longitude } = markerPos;
        setLoadedUbication(false);
        if (latitude && longitude) {
            const timer = setTimeout(() => {
                setLoadedUbication(true);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            setLoadedUbication(null);
        }
    }, [markerPos]);

    useEffect(() => {
        if (data.asset_data_id) getMarkerPos();
    }, [data.asset_data_id]);

    const getMarkerPos = () => {
        let lat = 0, lng = 0;
        const { asset_data_id } = data;
        const { latitude, longitude } = assets.find((asset) => asset.id == asset_data_id) || {};
        // Convertir cadena a número
        if (typeof latitude === 'number' && typeof longitude === 'number') {
            lat = latitude;
            lng = longitude;
        }
        setMarkerPos({ latitude: lat, longitude: lng });
    }
    const searchKm = ({ km, start_km, end_km, stringing }) => {
        let km_format = '';
        if (km) km_format = getKmFormat(km);
        if (start_km) km_format = getKmFormat(start_km)
        if (end_km) {
            if (start_km) km_format = km_format + ' al ' + getKmFormat(end_km)
            else km_format = 'Termina en ' + getKmFormat(end_km)
        }
        if (stringing) km_format = getKmFormat(stringing)
        return km_format;
    }

    return (
        <>
            {/* Almacénes disponibles */}
            <Row className='mb-3 mt-4'>
                <Col>
                    <Form.Label>Almacén</Form.Label>
                    <select name='inventory_id' className='form-control' defaultValue={data?.inventory_id} onChange={handleChange}>
                        <option value={0}>Seleccione una opción</option>
                        {
                            inventory.map((item) =>
                                <option key={item.id} value={item.id}>
                                    {item.code} - {item.inventory} | Cuerpo: {item.body} | Versión: {item.version}
                                </option>
                            )
                        }
                    </select>
                </Col>
            </Row>
            {/* Líneas base - deshabilitadas por default */}
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Línea base</Form.Label>
                    <select name='assets_base_line_id' className='form-control' defaultValue={data?.assets_base_line_id} onChange={handleChange} disabled>
                        <option value={0}>Seleccione una opción</option>
                        {

                            assetBaseLines.map((item) =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                        }
                    </select>
                </Col>
            </Row>
            {/* Lista de elementos del inventario con paginación */}
            {
                data.inventory_id && (
                    <>
                        <Row className='mb-3'>
                            <Col>
                                <Form.Label>Activo/Elemento</Form.Label>
                                <select name='asset_data_id' className='form-control' defaultValue={data?.asset_data_id} onChange={handleChange}>
                                    <option value={0}>Seleccione una opción</option>
                                    {
                                        assets.map((item) => {
                                            const { id, point, type_name, body, direction } = item;
                                            // Detectar el cadenamiento
                                            const km = searchKm(item);
                                            return (
                                                <option key={id} value={id}>
                                                    {type_name}#{point} | Cadenamiento: {km} {body && ` | Cuerpo: ${body}`} {direction && ` | Sentido: ${direction}`}
                                                </option>
                                            )
                                        }

                                        )
                                    }
                                </select>
                            </Col>
                        </Row>
                        {/* Indicar que el elemento no tiene datos de ubicación */}
                        {
                            loadedUbication === null && data.asset_data_id && <Alert
                                message="El elemento no cuenta con datos de ubicación geográfica"
                                type="info"
                                showIcon
                            />
                        }
                        <PaginationComp current={page} onChange={changePage} totalItems={totalItems} defaultPageSize={pageSize} />
                    </>
                )
            }
            {/* Mostrar elemento en el mapa si tiene ubicación registrada */}
            {
                loadedUbication === false && <Skeleton active />
            }
            {
                loadedUbication && <Ubication data={markerPos} />
            }
        </>
    )
}

export default StepInventory