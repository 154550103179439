import { downloadIncidentFiles } from 'api/maintenance_standards/incident_files';
import CarouselComponent from 'components/layouts/Carousel'
import React, { useEffect, useState } from 'react'

const moment = require('moment');

const Photos = (props) => {
    const { data, project_id } = props;
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];
        const getPhotos = async () => {
            const { inspection_id } = data;
            const files = data.media.filter((item) => item.type === 'images')
            setCount(files.length);
            for (const item of files) {
                const { creation_date, incident_id, file_path } = item;
                if (cancelLoop) break;
                const { response, cancel } = await downloadIncidentFiles({ project_id, inspection_id, incident_id, path: file_path });
                cancelTokens.push({ cancel });
                if (response) {
                    let url = window.URL || window.webkitURL;
                    let image = url.createObjectURL(response);
                    setPhotos(photos => [...photos, {
                        image,
                        created_at: `Evidencia capturada ${moment(creation_date).format('LLLL')}`
                    }]);
                    setCurrentCount(currentCount => currentCount + 1);
                    setLoading(false);
                }
            }
            setLoading(false);
        }
        getPhotos();
        return () => {
            cancelLoop = true;
            cancelTokens.forEach((token) => token.cancel());
        };
    }, []);

    return (
        <>
            {
                count > 0 &&
                <p>Obteniendo {currentCount}/{count} fotografía{count === 1 ? '' : 's'}...</p>
            }
            <CarouselComponent
                evidences={photos}
                loading={loading}
            />
        </>
    )
}

export default Photos