import React from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { __SERVER__ } from 'server/info';
import Swal from 'sweetalert2';
import Axios from 'axios';

class Instructions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            uploading: false,
            instructions: this.props.asset_activity.instructions,
            code: this.props.asset_activity.code,
            description: this.props.asset_activity.description
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    saveMaintenanceActivity = async () => {
        await Axios.put(__SERVER__ + `/projects/maintenance_activity/${this.props.asset_activity.id}`, {
            instructions: this.state.instructions,
            code: this.state.code,
            description: this.state.description
        }).then(response => {
            console.log(response);
        });
        this.handleClose();
    }

    render = () => {
        return (
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.asset_activity.description}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <center>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Código</Form.Label>
                                    <input type="text" name="code" defaultValue={this.state.code} className="form-control" onChange={e => {
                                        console.log("");
                                        this.setState({ code: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true: false}/>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Descripción</Form.Label>
                                    <input type="text" name="description" defaultValue={this.state.description} className="form-control" onChange={e => {
                                        console.log("");
                                        this.setState({ description: e.target.value });
                                    }} disabled={this.props.action !== 'edit' ? true: false}/>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <p>Serie de instrucciones para completar la actividad.</p>
                        <textarea
                            className="form-control"
                            rows={5}
                            defaultValue={this.state.instructions}
                            onChange={e => {
                                this.setState({ instructions: e.target.value });
                            }} disabled={this.props.action !== 'edit' ? true: false}/>
                    </center>

                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.action === 'edit' ?
                            <Button variant="primary" onClick={this.saveMaintenanceActivity}>Guardar cambios</Button> : null
                    }

                    {
                        this.props.action === 'view' ?
                            <Button variant="secondary" onClick={this.handleClose}>Cerrar</Button> : null
                    }

                    {
                        this.props.action === 'delete' ?
                            <Button variant="danger" onClick={this.deleteMaintenanceActivity}>Eliminar actividad</Button> : null
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}

export default Instructions;