import React, { useState, useEffect } from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Skeleton, Steps, Transfer, Upload } from 'antd';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { getSegments } from 'api/general/getSegments';
import { indexAssetsTypes } from 'api/operation_standards/assets_types';
import { indexAssetsBaseLines } from 'api/operation_standards/assets_base_lines';
import { indexAssets } from 'api/operation_standards/assets';
import { Col, Form, Row } from 'react-bootstrap';
import FormIcon from 'components/layouts/FormIcon';
import { indexObacFactorTypes } from 'api/operation_standards/factor_types';
import { indexObacTimes } from 'api/operation_standards/times';
import { indexObacEvaluations } from 'api/operation_standards/obac_evaluations';
import { Variables } from 'variables/Variables';
import { indexObacIncidents, storeObacIncidents } from 'api/operation_standards/incidents';
import { closeObacInspections, indexObacInspections, storeObacInspections } from 'api/operation_standards/inspections';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import { useGeolocated } from 'react-geolocated';
import ModalComp from 'components/layouts/Modal';
import { getKmNumbers } from 'components/libs/functions';
import { uploadIncidentFiles } from 'api/operation_standards/incidents_files';
import SkeletonForm from 'components/layouts/SkeletonForm';
import { stepClass } from './NewIncident/steps';
import FooterButtons from './NewIncident/FooterButtons';
import StepIncidents from './NewIncident/StepIncidents';
import StepInspection from './NewIncident/StepInspection';
import { indexUsersSimple } from 'api/users/users';
import { indexUserTypeResponsable } from 'api/users/user_type_responsable';

const { Step } = Steps;

const NewIncident = (props) => {
    const { onClose, project_id, table, package_id, syncServer, google, user_code } = props;

    const operating_indicator_id = Variables.indicator_id;
    const user_id = useSelector(state => state.login.dataUser.id);

    const { coords, isGeolocationAvailable } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });

    const [current, setCurrent] = useState(0);
    const [segments, setSegments] = useState([]);
    const [targetKeys, setTargetKeys] = useState(segments);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [assets, setAssets] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [assetBaseLines, setAssetBaseLines] = useState([]);
    const [data, setData] = useState({ altitude: 0 });
    const [factorTypes, setFactorTypes] = useState([]);
    const [times, setTimes] = useState([]);
    const [evaluations, setEvaluations] = useState([]);
    const [targetKeys2, setTargetKeys2] = useState([]);
    const [selectedKeys2, setSelectedKeys2] = useState([]);
    const [markerPos, setMarkerPos] = useState({});
    const [showInfoWindow, setShowInfoWindow] = useState(true);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [activeMarker, setActiveMarker] = useState({});
    const [fileList, setFileList] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [incidents, setIncidents] = useState([]);
    const [inspections, setInspections] = useState([]);
    const [checkedOldInspection, setCheckedOldInspection] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers();
        getSegs();
        getAssetTypes();
        getAssetBaseLines();
        getFactorTypes();
    }, []);

    useEffect(() => {
        if (data.inspection_id) getIncidents();
    }, [data.inspection_id]);

    useEffect(() => {
        if (checkedOldInspection
            && inspections.length === 0
            && data.created_at_start
            && data.created_at_end) getInspections();
    }, [checkedOldInspection, data.created_at_start, data.created_at_end]);

    useEffect(() => {
        if (data.type_id && data.assets_base_line_id) getAssets();
    }, [data.type_id, data.assets_base_line_id]);

    useEffect(() => {
        if (data.obac_factor_type_id && data.segment_id) getObacTimes();
    }, [data.obac_factor_type_id, data.segment_id]);

    useEffect(() => {
        if (data.type_id) getObacEvaluations();
    }, [data.type_id]);

    useEffect(() => {
        if (data.asset_data_id) getMarkerPos();
    }, [data.asset_data_id]);

    /** Obtener el gerente responsable de atender los incidentes */
    const getUsers = async () => {
        let response = await indexUsersSimple({ package_id });
        setUsers(response.data);
        // Se consulta al usuario que es responsable del estándar de mantenimiento
        const user_types = await indexUserTypeResponsable({ package_id });
        const items = user_types.data.filter((item) => item.code === user_code);
        if (items.length > 0) {
            setData({ ...data, responsable_id: items[0].user_id });
        }
    }
    /** Obtener posición actual o del elemento del inventario */
    const getMarkerPos = () => {
        let lat = 0, lng = 0;
        const { asset_data_id } = data;
        const { latitude, longitude, km } = assets.find((asset) => asset.id == asset_data_id) || {};
        const { meter, kilometer } = getKmNumbers(km);
        if (typeof latitude === 'number' && typeof longitude === 'number') {
            lat = latitude;
            lng = longitude;
        } else {
            if (isGeolocationAvailable) {
                lat = coords?.latitude;
                lng = coords?.longitude;
            }
        }
        setData({ ...data, meter, kilometer, latitude: lat, longitude: lng });
        setMarkerPos({ lat, lng });
    };
    /** Al dar click al pin en el mapa */
    const onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };
    /** Al clickear en el mapa */
    const onMapClicked = (props) => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };
    /** Al arrastrar el pin se cambian las coordenadas */
    const onDragend = (coord) => {
        const { latLng } = coord;
        setData({
            ...data,
            latitude: latLng.lat(),
            longitude: latLng.lng()
        });
        setMarkerPos({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
    };
    const next = () => setCurrent(current + 1);     /** Paso siguiente*/
    const prev = () => setCurrent(current - 1);     /** Paso anterior*/
    /** Confirmación de creación del incidente*/
    const save = async () => {
        try {
            Swal.fire({
                title: '¿Está seguro que desea realizar esta operación?',
                text: "Usted está creando un incidente",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, crearlo'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    saveAll();
                } else {
                    Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
                }
            })
        } catch (error) {
            Swal.fire('¡Operación incompleta!', 'Verifique el formulario.', 'warning')
        }
    };
    /** Llamar métodos para guardar inspección, incidente y evidencias */
    const saveAll = async () => {
        setLoadingSave(true);
        const { inspection_id } = data;
        if (inspection_id) {
            let response = await saveIncident(inspection_id);
            if (response) {
                let files_count = await saveFiles(response[0].id);
                if (fileList.length > 0) {
                    Swal.fire('¡Operación exitosa!', `El incidente ha sido registrado. Se agregaron ${files_count} archivos como evidencia.`, 'success');
                } else {
                    Swal.fire('¡Operación exitosa!', 'El incidente ha sido registrado.', 'success');
                }
                // Eliminar datos del incidente para agregar uno nuevo
                const { created_at_start, created_at_end } = data;
                setData({ altitude: 0, inspection_id, created_at_start, created_at_end });
                setFileList([]);
                setLoadingSave(false);
                setCurrent(stepClass.stepIncidents);
                getIncidents();
            } else {
                setLoadingSave(false);
                Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de registrar el incidente.', 'error')
            }
        } else {
            setLoadingSave(false);
            Swal.fire('¡Operación abortada!', 'La inspección no se pudo crear.', 'warning')
        }
    };
    /** Obtener las inspecciones */
    const getInspections = async () => {
        const { created_at_start, created_at_end } = data;
        let response = await indexObacInspections({ project_id, status: 'En progreso', operating_indicator_id, created_at_start, created_at_end });
        setInspections(response.data);
    }
    /** Cambiar de tipo de inspección */
    const changeInspection = (e) => {
        setCheckedOldInspection(e.target.checked);
    }
    /** Crear una inspeción */
    const saveInspection = async () => {
        const { responsable_id } = data;
        let response = await storeObacInspections({
            project_id, data: {
                segments_id: JSON.stringify(targetKeys),
                created_by: user_id,
                operating_indicator_id,
                responsable_id
            }
        });
        if (response) {
            setData({ ...data, inspection_id: response[0].id });
            next();
        }
    };
    /** Cerrar la inspección */
    const closeInspection = async () => {
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Usted está cerrando la inspección. Lo que significa que ya no la podrá abrir para agregar nuevos incidentes.",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, salir'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { inspection_id } = data;
                let response = await closeObacInspections({ project_id, inspection_id, user_id });
                if (response) {
                    Swal.fire('¡Inspección cerrada!', 'La inspección fue cerrada de manera correcta. Ya no es posible agregar más incidentes a esta inspección.', 'success');
                } else {
                    Swal.fire('¡Error al cerrar la inspección!', 'Ha ocurrido un problema al tratar de cerrar la inspección. Intente más tarde.', 'warning');
                }
                syncServer();
                onClose();
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'info')
            }
        })
    }
    /** Salir de la inspección, esto implica poder agregar más incidentes */
    const leaveInspection = () => {
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Usted está saliendo de la inspección. Lo que significa que la puede abrir más tarde para agregar nuevos incidentes.",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cerrar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                syncServer();
                onClose();
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'info')
            }
        })
    }
    /** Obtener incidentes de la inspección en curso */
    const getIncidents = async () => {
        const { inspection_id } = data;
        let response = await indexObacIncidents({ project_id, obac_inspection_id: inspection_id });
        setIncidents(response.data);
    }
    /** Guardar el incidente */
    const saveIncident = async (obac_inspection_id) => {
        const { asset_data_id, segment_id, latitude, longitude, altitude, kilometer, meter } = data;
        let evals = [];
        for (let element of targetKeys2) {
            evals.push({
                evaluation_id: element
            });
        }
        let formData = {
            obac_inspection_id,
            operating_indicator_id,
            asset_data_id,
            segment_id,
            latitude,
            longitude,
            altitude,
            kilometer,
            meter,
            created_by: user_id,
            data: {
                obac_time_id: times[0].id,
                evaluations: evals
            }
        };
        return await storeObacIncidents({ project_id, data: formData });
    };
    /** Agregar archivos multimedia como evidencia */
    const saveFiles = async (incident_id) => {
        let files_count = 0;
        for (const iterator of fileList) {
            const { type } = iterator;
            let formDataFile = new FormData();
            formDataFile.append('file', iterator);
            let file_type = 'document';
            if (/audio\//i.test(type)) file_type = 'audio';
            if (/image\//i.test(type)) file_type = 'picture';
            if (/video\//i.test(type)) file_type = 'video';
            let status_upload = await uploadIncidentFiles({
                project_id, incident_id,
                data: formDataFile, file_type, user_id
            });
            if (status_upload) files_count++;
        }
        return files_count;
    };
    /** Pasos para la creación del un nuevo incidente */
    const steps = [
        <Step key={0} title="Inspección" />,
        <Step key={1} title="Incidentes" />,
        <Step key={2} title="Inventario" />,
        <Step key={3} title="Datos" />,
        <Step key={4} title="Ubicación" />,
        <Step key={5} title="Evaluaciones" />,
        <Step key={6} title="Evidencias" />
    ];
    /** Obtener los tipos de factor | Inicial, Correctivo y Reiterativo */
    const getFactorTypes = async () => {
        let response = await indexObacFactorTypes({ package_id });
        setFactorTypes(response.data);
    };
    /** Obtener las evaluaciones que se realizan a los distintos tipos de elementos */
    const getObacEvaluations = async () => {
        const { type_id } = data;
        let response = await indexObacEvaluations({ package_id, asset_type_id: type_id, operating_indicator_id });
        setEvaluations(response.data.map((item) => {
            const { id, name, description } = item;
            return {
                key: id,
                title: name,
                description
            }
        }));
    };
    /** Obtener el tiempo de resolución en el que se tiene que solucionar el incidente */
    const getObacTimes = async () => {
        const { segment_id, obac_factor_type_id } = data;
        let response = await indexObacTimes({ project_id, segment_id, obac_factor_type_id });
        setTimes(response.data);
    };
    /** Obtener los segmentos de la autopista */
    const getSegs = async () => {
        let response = await getSegments();
        setSegments(response.map((segment) => {
            const { id, name, location, start_km, end_km } = segment;
            return {
                key: id,
                title: name + ' ' + location,
                description: start_km + ' ' + end_km
            };
        }));

    };
    /** Segmentos que se van a inspeccionar */
    const onChange = (nextTargetKeys, _direction, _moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    /** Mover los segmentos del componente Transfer */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    /** Evaluaciones que se detectaron */
    const onChange2 = (nextTargetKeys, _direction, _moveKeys) => {
        setTargetKeys2(nextTargetKeys);
    };
    /** Mover las evaluaciones del componente Transfer*/
    const onSelectChange2 = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys2([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    /** Obtener los tipos de elementos|activos */
    const getAssetTypes = async () => {
        let response = await indexAssetsTypes({ package_id, table });
        setAssetTypes(response.data);
    };
    /** Obtener las líneas base */
    const getAssetBaseLines = async () => {
        let response = await indexAssetsBaseLines({ package_id });
        setAssetBaseLines(response.data);
    };
    /** Obtener los elementos|activos de acuerdo al tipo y línea base */
    const getAssets = async () => {
        const { assets_base_line_id, type_id } = data;
        let response = await indexAssets({ project_id, table, query: { type_id, assets_base_line_id } });
        setAssets(response.data);
    };
    /** Guadar datos del formulario */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    /** Propiedades del componente Upload, eliminar y agregar archivos*/
    const propsUpload = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const { size, type } = file;
            let allow = false;
            if (/audio\//i.test(type)) allow = true;
            if (/image\//i.test(type)) allow = true;
            if (/video\//i.test(type)) allow = true;
            if (/application\/pdf/i.test(type)) allow = true;

            if (allow) {
                const maxSize = 20 * 1024 * 1024;   // Archivos menores a 20MB
                if (size < maxSize) {
                    setFileList([...fileList, file]);
                } else {
                    Swal.fire('¡Archivo muy grande!', 'El archivo no debe pasar de 20 MB.', 'info');
                }
            } else {
                Swal.fire('¡Archivo desconocido!', 'El archivo debe ser imagen, audio, video o pdf.', 'info');
            }
            return false;
        },
        fileList,
        listType: "picture-card"
    };
    /** Habilitar botón siguiente */
    const enableNext = () => {
        let response = false;
        switch (current) {
            case stepClass.stepInspection:
                if (targetKeys.length > 0) response = true;
                break;
            case stepClass.stepInventory:
                const { type_id, assets_base_line_id, asset_data_id } = data;
                if (type_id && assets_base_line_id && asset_data_id) response = true;
                break;
            case stepClass.stepData:
                const { segment_id, kilometer, meter, obac_factor_type_id } = data;
                if (segment_id && kilometer && meter && obac_factor_type_id) response = true;
                break;
            case stepClass.stepUbication:
                const { latitude, longitude } = data;
                if (latitude && longitude) response = true;
                break;
            case stepClass.stepEvaluation:
                if (targetKeys2.length > 0) response = true;
                break;
            default: break;
        }
        return response;
    }
    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={'Registrar Incidente'}
            body={<div style={{ height: '60vh' }}>
                {
                    loadingSave ? <SkeletonForm cols={3} rows={5} /> : <>
                        <Steps current={current} size='small'>
                            {steps}
                        </Steps>
                        {
                            current === stepClass.stepInspection && (<StepInspection
                                changeInspection={changeInspection}
                                checkedOldInspection={checkedOldInspection}
                                data={data}
                                handleChange={handleChange}
                                inspections={inspections}
                                segments={segments}
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onSelectChange={onSelectChange}
                                onChange={onChange}
                                users={users}
                                user_code={user_code}
                            />)
                        }
                        {
                            current === stepClass.stepIncidents && (<StepIncidents
                                incidents={incidents}
                            />)
                        }
                        {
                            current == stepClass.stepInventory && (<>
                                <Row className='mb-3'>
                                    <Col>
                                        <Form.Label>Tipo de elemento</Form.Label>
                                        <select name='type_id' className='form-control' defaultValue={data?.type_id} onChange={handleChange}>
                                            <option value={0}>Seleccione una opción</option>
                                            {
                                                assetTypes.map((item) =>
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )
                                            }
                                        </select>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col>
                                        <Form.Label>Línea base</Form.Label>
                                        <select name='assets_base_line_id' className='form-control' defaultValue={data?.assets_base_line_id} onChange={handleChange}>
                                            <option value={0}>Seleccione una opción</option>
                                            {

                                                assetBaseLines.map((item) =>
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )
                                            }
                                        </select>
                                    </Col>
                                </Row>
                                {
                                    data.assets_base_line_id && data.type_id && (
                                        <Row className='mb-3'>
                                            <Col>
                                                <Form.Label>Activo/Elemento</Form.Label>
                                                <select name='asset_data_id' className='form-control' defaultValue={data?.asset_data_id} onChange={handleChange}>
                                                    <option value={0}>Seleccione una opción</option>
                                                    {
                                                        assets.map((item) =>
                                                            <option key={item.id} value={item.id}>
                                                                {item.type_name}#{item.id} | Cadenamiento: {item.km} | Cuerpo: {item.body} | Sentido: {item.direction}
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </>)
                        }
                        {
                            current === stepClass.stepData && (<>
                                <Row className='mb-3 mt-4'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Segmento</Form.Label>
                                        <select name='segment_id' className='form-control' defaultValue={data?.segment_id} onChange={handleChange}>
                                            <option value={0}>Seleccione una opción</option>
                                            {
                                                segments.map((item) =>
                                                    <option key={item.key} value={item.key}>
                                                        {item.title}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </Form.Group>
                                    <FormIcon type='number' title='Kilometro' name='kilometer' value={data?.kilometer} handleChange={handleChange} />
                                    <FormIcon type='number' title='Metro' name='meter' value={data?.meter} handleChange={handleChange} />
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Tipo de Factor</Form.Label>
                                        <select name='obac_factor_type_id' className='form-control'
                                            defaultValue={data?.obac_factor_type_id} onChange={handleChange}>
                                            <option value={0}>Seleccione una opción</option>
                                            {
                                                factorTypes.map((item) =>
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </Form.Group>
                                </Row>
                            </>)
                        }
                        {
                            current === stepClass.stepUbication && (<>
                                <div className='mt-3 mb-4'>
                                    <Map
                                        google={google}
                                        zoom={14}
                                        center={markerPos}
                                        onClick={onMapClicked}
                                        initialCenter={markerPos}
                                    >
                                        <Marker
                                            onClick={onMarkerClick}
                                            name={'Ubicación del activo'}
                                            draggable={true}
                                            position={markerPos}
                                            onDragend={(_t, _map, coord) => onDragend(coord)}
                                        />
                                        <InfoWindow
                                            marker={activeMarker}
                                            visible={true}>
                                            <div>
                                                <h5 style={{ color: 'black' }}>{selectedPlace.name}</h5>
                                            </div>
                                        </InfoWindow>
                                    </Map>
                                </div>
                            </>)
                        }
                        {
                            current === stepClass.stepEvaluation && (<>
                                <h4>Evaluaciones</h4>
                                <Transfer
                                    listStyle={{
                                        width: 800,
                                        height: 400,
                                    }}
                                    dataSource={evaluations}
                                    titles={['Evaluaciones disponibles', 'Evaluaciones Seleccionadas']}
                                    targetKeys={targetKeys2}
                                    selectedKeys={selectedKeys2}
                                    onChange={onChange2}
                                    onSelectChange={onSelectChange2}
                                    render={item => item.title}
                                />
                            </>)
                        }
                        {
                            current === stepClass.stepFiles && (<>
                                <div className='mt-4 mb-4'>
                                    <h4>Seleccionar los archivos multimedia</h4>
                                    <Upload {...propsUpload}>
                                        <ButtonIcon icon={faUpload} tooltip='Seleccionar archivos como evidencia' />
                                    </Upload>
                                </div>
                            </>)
                        }
                    </>
                }
            </div>}
            footer={<FooterButtons
                current={current}
                stepClass={stepClass}
                onClose={onClose}
                loadingSave={loadingSave}
                enableNext={enableNext}
                checkedOldInspection={checkedOldInspection}
                data={data}
                next={next}
                prev={prev}
                targetKeys={targetKeys}
                incidents={incidents}
                save={save}
                saveInspection={saveInspection}
                leaveInspection={leaveInspection}
                closeInspection={closeInspection}
            />}
        />
    )
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
    LoadingContainer: Skeleton
})(NewIncident)