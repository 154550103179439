import React, { Component } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Col, Row, Card, Button, OverlayTrigger, Tooltip, Spinner, Form } from 'react-bootstrap';
import { FaArrowLeft, FaCreditCard, FaSave, FaCcVisa, FaCcMastercard, FaPlus } from 'react-icons/fa';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { __SERVER__ } from 'server/info'
import { Variables } from 'variables/Variables';
import './css/PaymentForm.css';

const PaymentForm = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            holder_name: '',
            card_number: '',
            expiration_month: '',
            expiration_year: '',
            cvc: '',
            focus: '',
            cards: [],
            loading_cards: true,
            addCard: false,
            openpay_id: null,
            option: 'view',
            card_selected: null
        }
    }

    componentDidMount = () => {
        this.getOpenpayID();
    }

    getCards = async () => {
        let response = await Axios.get(`${__SERVER__}/victum_openpay_customer/${this.state.openpay_id}/victum_openpay_cards`);
        if (response.status === 200) {
            this.setState({ cards: response.data });
        }
        this.setState({ loading_cards: false });
        // console.log("cards response: ", response);
    }

    getOpenpayID = async () => {
        let response = await Axios.get(`${__SERVER__}/victum_openpay/projects/${Variables.project_id}`);
        if (response.status === 200 && response.data.length > 0) {
            this.setState({ openpay_id: response.data[0].openpay_id });
        }
        this.getCards();
    }

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    saveCard = async () => {
        let { holder_name, card_number, expiration_month, expiration_year, cvc } = this.state;
        let data = JSON.stringify({ holder_name, card_number, expiration_month, expiration_year, cvv2: cvc });
        let encode_data = btoa(data);
        let response = await Axios.post(`${__SERVER__}/victum_openpay_customer/${this.state.openpay_id}/victum_openpay_card`, { data: encode_data });
        if (response.status === 200) {
            if (response.data.error_code === undefined) {
                Swal.fire('Operación exitosa', 'La tarjeta fue agregada satisfactoriamente', 'success');
                this.props.onClose();
            } else {
                Swal.fire('Operación fallida', 'La tarjeta no pudo ser agregada. Error: ' + response.data.description, 'warning');
            }
        }
        console.log("Save card: ", response);
    }

    setTransaction = (card) => {
        this.setState({ option: 'transaction', card_selected: card });
        console.log("Card: ", card)
    }

    setCharge = async () => {
        Swal.fire({
            title: '¿Está seguro?',
            text: 'Desea realizar esta transacción',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, realizar!',
            cancelButtonText: 'No, regresar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Operación exitosa!',
                    'Transacción realizada con éxito',
                    'success'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Operación cancelada',
                    'Esta transacción no se pudo realizar satisfactoriamente ',
                    'error'
                );
            }
        });
    }

    render() {
        // console.log("State payment: ", this.state);
        return (
            <div id="PaymentForm">
                {/* Agregar nueva tarjeta */}
                {
                    this.state.option === 'add' ?
                        <Row className={'align-items-start'}>
                            <Col><br />
                                <Cards
                                    cvc={this.state.cvc}
                                    expiry={this.state.expiration_month + '/' + this.state.expiration_year}
                                    focused={this.state.focus}
                                    name={this.state.holder_name}
                                    number={this.state.card_number}
                                />
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Número de tarjeta</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="card_number"
                                            placeholder="Número de tarjeta"
                                            maxLength={16}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Nombre del propietario</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="holder_name"
                                            placeholder="Nombre del propietario"
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Mes</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="expiration_month"
                                                maxLength={2}
                                                min={1}
                                                max={12}
                                                placeholder="Mes"
                                                onChange={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Año</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="expiration_year"
                                                maxLength={2}
                                                placeholder="Año"
                                                onChange={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>CVV2</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="cvc"
                                                placeholder="CVV2"
                                                maxLength={3}
                                                onChange={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Regresar</Tooltip>}>
                                                <Button block variant="outline-secondary" onClick={e => this.setState({ option: 'view' })}><FaArrowLeft /> Regresar</Button>
                                            </OverlayTrigger>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Guardar</Tooltip>}>
                                                <Button block variant="outline-success" onClick={this.saveCard}><FaSave /> Agregar tarjeta</Button>
                                            </OverlayTrigger>
                                        </Form.Group>
                                    </Form.Row>

                                </Form></Col>
                        </Row> : null
                }

                {/* Vista de las tarjetas guardadas */}
                {
                    this.state.option === 'view' ?
                        <Row xs={1} sm={1} md={2} lg={3} className="g-4">
                            <Col>
                                <OverlayTrigger placement={"top"} overlay={<Tooltip>Agregar tarjeta</Tooltip>}>
                                    <center id={"addCard"} onClick={e => this.setState({ option: 'add' })}>
                                        <FaPlus size={50} color={'#28a745'} />
                                    </center>
                                </OverlayTrigger>
                            </Col>
                            {
                                !this.state.loading_cards ?
                                    this.state.cards.map((card, i) =>
                                        <Col key={i}>
                                            <Card onClick={e => this.setTransaction(card)}>
                                                <Card.Body>
                                                    <Card.Title>{
                                                        card.brand === 'visa' ?
                                                            <FaCcVisa size={50} color={'blue'} /> :
                                                            <FaCcMastercard size={50} color={'#db0f46'} />
                                                    }<i> {card.type === 'debit' ? "Débito" : "Crédito"}</i> </Card.Title>

                                                    <small className="text-muted"> {card.bank_name}</small>
                                                    <Card.Text><strong>{card.card_number}</strong></Card.Text>
                                                    <small className="text-muted"> {card.holder_name}</small>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ) :
                                    <Row>

                                        <Col>
                                            <OverlayTrigger placement={"top"} overlay={<Tooltip>Obteniendo registros</Tooltip>}>
                                                <center onClick={e => this.setState({ addCard: true })}>
                                                    <Spinner animation="border" />
                                                </center>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                            }
                        </Row> : null
                }

                {/* Realizar la transacción */}
                {
                    this.state.option === 'transaction' ?
                        <Form>
                            <Row className={'align-items-start'}>
                                <Col>
                                    <center>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>{
                                                    this.state.card_selected.brand === 'visa' ?
                                                        <FaCcVisa size={50} color={'blue'} /> :
                                                        <FaCcMastercard size={50} color={'#db0f46'} />
                                                }<i> {this.state.card_selected.type === 'debit' ? "Débito" : "Crédito"}</i> </Card.Title>

                                                <small className="text-muted"> {this.state.card_selected.bank_name}</small>
                                                <Card.Text><strong>{this.state.card_selected.card_number}</strong></Card.Text>
                                                <small className="text-muted"> {this.state.card_selected.holder_name}</small>
                                            </Card.Body>
                                        </Card>
                                    </center>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Cantidad</Form.Label>
                                        <Form.Control
                                            type="number"
                                        />
                                    </Form.Group>
                                </Col>
                                <input type="hidden" id="modal_source_id" value={this.state.card_selected.id} />
                                <input type="hidden" id="modal_customer_id" value={this.state.card_selected.customer_id} />
                            </Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Button block variant="outline-secondary" onClick={e => this.setState({ option: 'view' })}>Regresar</Button>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Button block variant="outline-success" id="pay-button" onClick={this.setCharge}>Recargar</Button>
                                </Form.Group>
                            </Form.Row>

                        </Form> : null
                }

            </div >
        );
    }
}

export default PaymentForm;