import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { indexMaintenanceActivities } from 'api/operation_standards/maintenance_activities';
import FlexButton from 'components/layouts/FlexButton';
import TableComp from 'components/layouts/Table';
import React, { Fragment, useEffect, useState } from 'react';
import AddOrEditActivity from '../modals/AddOrEditActivity';

const ActivitiesTab = (props) => {
	const { project_id, code, package_id, maintenance_sections_id } = props;

	const [data, setData] = useState([]);
	const [activitySel, setActivitySel] = useState({});
	const [loading, setLoading] = useState(true);
	const [modalActivity, setModalActivity] = useState(false);
	const [modalActivityUpdate, setModalActivityUpdate] = useState(false);

	useEffect(() => {
		getActivities();
	}, []);

	const getActivities = async () => {
		let response = await indexMaintenanceActivities({ project_id, maintenance_sections_id });
		setData(response.data);
		setLoading(false);

	}

	const openOrCloseNewActivity = () => setModalActivity(!modalActivity);

	const edit = (item) => {
		setActivitySel(item);
		setModalActivityUpdate(!modalActivityUpdate);
	}

	return (
		<Fragment>
			{/* Tabla para mostrar las actividades de mantenimiento */}
			<TableComp
				headers={['#', 'Código', 'Descripción', 'Unidad', 'Volumen', 'Acciones']}
				keys={['#', 'code', 'description', 'unit', 'total_amount']}
				body={data}
				loading={loading}
				actions={[
					{ icon: faEdit, variant: 'outline-success', tooltip: 'Editar registro', handleClick: edit }
				]}
			/>
			{/* Agregar nueva actividad */}
			<FlexButton icon={faPlus} style={{ bottom: 20, right: 20 }} onClick={openOrCloseNewActivity} />
			{/* Modal para la nueva actividad */}
			{
				modalActivity && <AddOrEditActivity
					type='store'
					onClose={openOrCloseNewActivity}
					syncServer={getActivities}
					code={code}
					project_id={project_id}
					package_id={package_id}
				/>
			}
			{/* Modal para editar o agregar una actividad */}
			{
				modalActivityUpdate && <AddOrEditActivity
					type='update'
					onClose={() => setModalActivityUpdate(!modalActivityUpdate)}
					activity={activitySel}
					syncServer={getActivities}
					code={code}
					project_id={project_id}
					package_id={package_id}
				/>
			}
		</Fragment>
	)
}

export default ActivitiesTab