import React, { Component, useEffect, useState } from 'react';
import { __SERVER__ } from 'server/info';
import Swal from 'sweetalert2';
import './css/Logs.css';
import { Alert } from 'react-bootstrap';
import Axios from 'axios';
import moment from 'moment';
import Map from '../subComponents/Proyectos/Mantemiento/Inventarios/Map';
import TableComp from 'components/layouts/Table';
import PaginationComp from 'components/layouts/Pagination';
import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import ModalComp from 'components/layouts/Modal';
import { indexPackages } from 'api/general/packages';

const _ = require('underscore');

const statuses = [
    { id: 1, color: 'green', value: 'Completed' },
    { id: 2, color: 'orange', value: 'Invalid Password' },
    { id: 3, color: 'orange', value: 'Invalid User' },
    { id: 4, color: 'orange', value: 'Without Permissions' },
    { id: 5, color: 'red', value: 'Inactive User' },
    { id: 6, color: 'red', value: 'Invalid Code' }
];

const ModalUbication = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            initialCenter: undefined,
            center: undefined,
            ubications: [],
            height: '500px',
            width: '100%'
        }
    }

    componentDidMount = () => {
        let { initialCenter, center, ubications } = this.props;
        setTimeout(() => this.setState({ show: true }), 100);
        this.setState({
            initialCenter,
            center,
            ubications
        });
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    render = () => {
        let { width, height } = this.state;
        return (
            <ModalComp
                onClose={this.handleClose}
                backdrop="static"
                keyboard
                size="lg"
                title={this.props.title}
                body={<div style={{ height, width }}>
                    <center>
                        {
                            this.state.ubications.length === 0 ?
                                <Alert variant={"warning"}>
                                    ¡La ubicación no fue registrada!</Alert> : null
                        }

                        <Map
                            ubications={this.state.ubications}
                            initialCenter={this.state.initialCenter}
                            center={this.state.center}
                        />
                    </center>
                </div>}
            />
        )
    }
}

const Filters = (props) => {
    const { users, setFilters, filters, searchInfo } = props;

    const [packages, setPackages] = useState([]);


    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = async () => {
        let response = await indexPackages();
        if (response.data.length > 0) setPackages(response.data);
    }
    const handleChange = (event) => {
        const { value, name } = event.target;
        setFilters({ ...filters, [name]: value });
    }
    return (
        <section className="assets">
            < section className="sectionSearch" >
                <article>
                    <label>Paquete</label>
                    <select onChange={handleChange} name='package_id' value={filters.package_id}>
                        <option value={-1}>Todos</option>
                        {
                            packages.map((item) =>
                                <option value={item.id} key={item.id}>{item?.code} {item?.name}</option>
                            )
                        }
                    </select>
                </article>
                <article>
                    <label>Aplicación</label>
                    <select onChange={handleChange} name='application' value={filters.application}>
                        <option value={-1}>Todas</option>
                        <option value={1}>Aplicación Web</option>
                        <option value={2}>Puentes</option>
                        <option value={3}>Usuarios</option>
                        <option value={4}>Mantenimiento</option>
                    </select>
                </article>
                <article>
                    <label>Usuario</label>
                    <select onChange={handleChange} name='user_id' value={filters.user_id}>
                        <option value={-1}>Todos</option>
                        {
                            users.map((user) =>
                                <option key={user.id} value={user.id}>{user?.package} - {user?.first_name} {user?.last_name}</option>
                            )
                        }
                    </select>
                </article>
                <article>
                    <label>Estado</label>
                    <select onChange={handleChange} name='status' value={filters.status}>
                        <option value={-1}>Todos</option>
                        {
                            statuses.map((item) =>
                                <option key={item.id} value={item.value}>{item.value}</option>
                            )
                        }
                    </select>
                </article>
                <article>
                    <button className="botonBusqueda" onClick={searchInfo}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                    </button>
                </article>
            </ section >
        </section>
    );
}

const UsersLogs = () => {
    const [data, setData] = useState([]);                       // Información que se va a mostrar como tuplas
    const [users, setUsers] = useState([]);
    const [usersSel, setUsersSel] = useState([]);
    const [loading, setLoading] = useState(true);               // Indicador de carga de registros
    // Paginación - Optimizar para que los que ya se hayan recuperado se guarden con REDUX
    const [totalItems, setTotalItems] = useState(0);            // Cantidad de registros existentes
    const [page, setPage] = useState(1);                        // Índice de la página que se está mostrando
    const [pageSize, setPageSize] = useState(25);               // Tamaño de la página, es decir, cantidad de registros que se muestran
    const [modalMap, setModalMap] = useState(false);
    const [ubications, setUbications] = useState({});
    const [filters, setFilters] = useState({ user_id: '-1', application: '-1', package_id: '-1', status: '-1' });

    useEffect(() => {
        if (filters.package_id !== '-1') {
            setUsersSel(users.filter((user) => `${user.package_id}` === filters.package_id));
        } else {
            setUsersSel(users);
        }
    }, [filters]);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (users.length > 0)
            getData();
    }, [page, pageSize, users]);

    const getData = async () => {
        setLoading(true);
        let params = { page, pageSize };
        const { user_id, application, package_id, status } = filters;
        if (user_id !== '-1') params.user_id = user_id;
        if (application !== '-1') params.application = application;
        if (package_id !== '-1') params.package_id = package_id;
        if (status !== '-1') params.status = status;
        let logs = await Axios.get(`${__SERVER__}/users_logs`, { params });
        if (logs.status === 200) {
            const { data, totalItems } = logs.data;
            setData(data.map((item) => {
                let pos = _.findIndex(users, { id: item.user_id })
                let user = {};
                if (pos !== -1) user = users[pos]
                return { ...item, user }
            }));
            setTotalItems(totalItems);
        }
        setLoading(false);
    }

    const getUsers = async () => {
        let response = await Axios.get(`${__SERVER__}/users_logs/all_users`);
        if (response.status === 200) {
            setUsers(response.data);
            setUsersSel(response.data);
        }
    }
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const viewMap = (item) => {
        const { latitude, longitude, user_id } = item;
        setUbications({
            id: 1,
            title: users[_.findIndex(users, { id: user_id })].username,
            name: `Ubicación: ${latitude}, ${longitude}\nFecha: 
            ${moment(data[_.findIndex(data, { user_id: user_id })].login_time)
                    .locale('es-MX').format('LLLL')}`,
            position: {
                lat: latitude,
                lng: longitude
            }
        });
        setModalMap(!modalMap);
    }

    return (
        <div className="body_">
            <Filters
                users={usersSel}
                filters={filters}
                setFilters={setFilters}
                searchInfo={getData}
            />
            <TableComp
                headers={['#', 'Usuario', 'Paquete', 'Fecha de Acceso', ' Dirección IP', 'Navegador', 'Plataforma', 'Aplication', 'Estado', 'Ubicación']}
                keys={['#', 'user.first_name+user.last_name', 'user.package', 'login_time', 'ip_address_v4', 'browser_type', 'platform', 'application', 'status']}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faMapMarked, variant: 'outline-danger', tooltip: 'Ver ubicación', handleClick: viewMap }
                ]}
                badges={[{ index: 7 }, { index: 8 }]}
                badges_values={[
                    {
                        col: 'application',
                        values: [
                            { color: 'teal', value: 1, name: 'Victum Web' },
                            { color: 'purple', value: 2, name: 'Puentes' },
                            { color: 'green', value: 3, name: 'Usuarios' },
                            { color: 'yellow', value: 4, name: 'Mantenimiento' }
                        ]
                    }, {
                        col: 'status',
                        values: statuses
                    }
                ]}
                moment_dates={[{ index: 3, format: 'LLLL' }]}
            />
            <PaginationComp totalItems={totalItems} current={page} defaultPageSize={pageSize} onChange={changePage} />
            {
                modalMap &&
                <ModalUbication
                    title={`Ubicación de inicio de sesión`}
                    ubications={[ubications]}
                    initialCenter={{ lat: ubications.position.lat, lng: ubications.position.lng }}
                    center={{ lat: ubications.position.lat, lng: ubications.position.lng }}
                    onClose={() => setModalMap(!modalMap)}
                />
            }
        </div>
    );
}

export default UsersLogs;
