import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const getUrlHumanResources = async ({ package_id, user_id, start_date, end_date, type }) => {
    const url = `/reports/pdf/packages/${package_id}/users/${user_id}/human_resources_management`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { start_date, end_date, type }
        });
        // console.log("🚀 ~ file: reports.js:12 ~ getUrlHumanResources ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return response;
    }
}   