import FormIcon from 'components/layouts/FormIcon'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const StepData = (props) => {
    const {
        data,
        handleChange,
        segments,
        factorTypes,
        evaluations,
    } = props;
    return (
        <>
            <Row className='mb-3 mt-4'>
                <Form.Group as={Col}>
                    <Form.Label>Segmento</Form.Label>
                    <select name='segment_id' className='form-control' defaultValue={data?.segment_id} onChange={handleChange}>
                        <option value={0}>Seleccione una opción</option>
                        {
                            segments.map((item) =>
                                <option key={item.key} value={item.key}>
                                    {item.title}
                                </option>
                            )
                        }
                    </select>
                </Form.Group>
                <FormIcon type='number' title='Kilometro' name='kilometer' value={data?.kilometer} handleChange={handleChange} />
                <FormIcon type='number' title='Metro' name='meter' value={data?.meter} handleChange={handleChange} />
            </Row>
            <Row className='mb-3'>
                <Form.Group as={Col}>
                    <Form.Label>Tipo de Factor</Form.Label>
                    <select name='factor_type_id' className='form-control'
                        defaultValue={data?.factor_type_id} onChange={handleChange}>
                        <option value={0}>Seleccione una opción</option>
                        {
                            factorTypes.map((item) =>
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            )
                        }
                    </select>
                </Form.Group>
            </Row>
            <Row className='mb-3 mt-4'>
                <Form.Group as={Col}>
                    <Form.Label>Evaluaciones</Form.Label>
                    <select name='evaluation_id' className='form-control' defaultValue={data?.evaluation_id} onChange={handleChange}>
                        <option value={0}>Seleccione una opción</option>
                        {
                            evaluations.map((item) =>
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            )
                        }
                    </select>
                </Form.Group>
            </Row>
            <Row className='mb-3 mt-4'>
                <FormIcon title='Tiempo de atención' value={data?.estimated_date} readOnly />
            </Row>
        </>
    )
}

export default StepData