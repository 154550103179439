import { Card, List } from 'antd'
import moment from 'moment';
import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'

const StepIncidents = (props) => {
    const { incidents } = props;
    return (
        <div className='mt-4'>
            <List
                grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                dataSource={incidents}
                renderItem={incident => (
                    <List.Item>
                        <Card title={moment(incident?.creation_date).format('LLL')}>
                            <h6>Creado por: {incident?.created_by?.first_name} {incident?.created_by?.last_name}</h6>
                            <h8>Nivel: {incident?.created_by?.position}</h8>
                            <ListGroup>
                                <ListGroupItem>{(!incident.segment_E ? 'Segmento: ' : 'Segmento inicial: ') + incident?.segment?.name}</ListGroupItem>
                                {incident.segment_E ? <ListGroupItem>Segmento final: {incident?.segment_E?.name}</ListGroupItem> : null}
                                <ListGroupItem>Estado: {incident?.status?.name}</ListGroupItem>
                                <ListGroupItem>Evaluación: {incident?.data?.evaluation?.name}</ListGroupItem>
                            </ListGroup>
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    )
}

export default StepIncidents