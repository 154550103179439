import { faFileDownload, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Collapse, Row } from 'antd';
import { downloadInventoryFiles } from 'api/maintenance_standards/inventory_files';
import ButtonIcon from 'components/layouts/ButtonIcon';
import CarouselComponent from 'components/layouts/Carousel'
import TableComp from 'components/layouts/Table';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const { Panel } = Collapse;

const InventoryPhotos = (props) => {
    const { data, project_id, table, storeFile, deleteFile } = props;

    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);
    const [files, setFiles] = useState(null);               // Lista de archivos existentes
    const [file, setFile] = useState(null);                 // Archivo para cargar

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];

        const getPhotos = async () => {
            let files = data.filter((file) => file.file_type === 'picture');
            setFiles(files);
            setCount(files.length);
            for (const element of files) {
                if (cancelLoop) break;
                const { file_path, created_at, inventory_data_id } = element;
                const { response, cancel } = await downloadInventoryFiles({ project_id, table: `${table}_files`, id: inventory_data_id, file_path });
                cancelTokens.push({ cancel });
                if (response) {
                    let url = window.URL || window.webkitURL;
                    let image = url.createObjectURL(response);
                    setPhotos(photos => [...photos, {
                        image,
                        created_at: `Evidencia capturada ${moment(created_at).format('LLLL')}`
                    }]);
                    setCurrentCount(currentCount => currentCount + 1);  // Contador del archivo obtenido
                    setLoading(false);
                }
            }
            setLoading(false);
        };
        getPhotos();
        return () => {
            cancelLoop = true;  // cancelar la petición del resto de las fotografías
            cancelTokens.forEach((token) => token.cancel()); // cancelar cada uno de los tokens creados
        };
    }, []);

    // Manejador de carga de archivos
    const handleLoadFile = (event) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    }
    // Método para subir el archivo
    const handleUploadClick = () => {
        if (!file) {
            Swal.fire('¡Sin archivos!', 'Necesita elegir un archivo.', 'warning');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        storeFile({ data: formData, file_type: 'picture' });
    };

    const getFile = async (item) => {
        const { file_path, inventory_data_id } = item;
        let response = await downloadInventoryFiles({ project_id, table: `${table}_files`, id: inventory_data_id, file_path });
        return response;
    }
    const downloadPic = async (item) => {
        const { file_path } = item;
        let response = await getFile(item);
        if (response) {
            const fileURL = URL.createObjectURL(response);
            const link = document.createElement('a')
            link.href = fileURL
            link.setAttribute('download', file_path)
            document.body.appendChild(link)
            link.click();
        }
    }

    return (
        <>
            {
                count > 0 &&
                <p>Obteniendo {currentCount}/{count} fotografía{count === 1 ? '' : 's'}...</p>
            }
            <CarouselComponent
                evidences={photos}
                loading={loading}
            />
            <Collapse>
                {/* Agregar fotografía */}
                <Panel header="Agregar fotografía" key="1">
                    <Row align='middle'>
                        <Col span={20}>
                            <Form.Group className="mb-3">
                                <Form.Label>Cargar fotografía</Form.Label>
                                <Form.Control type="file" size="sm" accept='image/*' onChange={handleLoadFile} />
                            </Form.Group>
                        </Col>
                        <Col span={4}>
                            <ButtonIcon name='Agregar' icon={faPlus} variant='success' tooltip='Agregar' onClick={handleUploadClick} />
                        </Col>
                    </Row>
                </Panel>
                {/* Eliminar fotografías */}
                <Panel header="Eliminar fotografías" key="2">
                    <div className="body_">
                        <TableComp
                            headers={['#', 'Archivo', 'Tipo', 'Acciones']}
                            keys={['#', 'file_path', 'file_type']}
                            body={files}
                            actions={[
                                { icon: faFileDownload, tooltip: 'Descargar archivo', variant: 'outline-primary', handleClick: downloadPic },
                                { icon: faTrashAlt, tooltip: 'Eliminar archivo', variant: 'outline-danger', handleClick: deleteFile },
                            ]}
                        />
                    </div>
                </Panel>
            </Collapse>
        </>
    )
}

export default InventoryPhotos