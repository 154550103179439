import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import { indexAssets, showAssets } from 'api/operation_standards/assets';
import { indexAssetsBaseLines } from 'api/operation_standards/assets_base_lines';
import { indexAssetsTypes } from 'api/operation_standards/assets_types';
import { indexMaintenanceActivities } from 'api/operation_standards/maintenance_activities';
import { indexMaintenanceProgram } from 'api/operation_standards/maintenance_program';
import { indexMaintenanceSections } from 'api/operation_standards/maintenance_sections';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Jumbotron, Row } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import BienesPdf from './BienesPdf';
import LoadingDoc from './LoadingDoc';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Empty, notification } from 'antd';
import moment from 'moment';
import { Heading } from '@chakra-ui/react';

const getDate = moment().format('YYYY-MMM-DD-HH:mm:ss')

const Bienes = React.memo(({ package_id, project_id, code, table }) => {
    const [maintenance_sections_id, setMaintenanceSectionsId] = useState(0);
    const [MaintenanceProgram, setMaintenanceProgram] = useState(null)  //5
    const [assetActivities, setAssetActivities] = useState(null);       //4
    const [reportReady, setReportReady] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [assetsTypes, setAssetsTypes] = useState(null);               //2
    const [BaseLines, setBaseLines] = useState(null);                   //3
    const [assetsImg, setAssetsImg] = useState(null);
    const [assets, setAssets] = useState(null);
    const [TypeAssetID, setTypeAssetID] = useState(null)
    const [Emmpty, setEmmpty] = useState(false)
    const [FistLoad, setFistLoad] = useState(false)

    let assets_id = [];

    useEffect(() => {
        /* getMaintenanceSections() */
        getAssetsTypes()
    }, []);

    const getData = async () => {
        setFistLoad(true)
        setLoadingData(true);
        setEmmpty(false)
        setAssetsImg(null)
        await getAssets();
        /*await getAssetActivities();
         await getBaseLines();
        await getMaintenanceProgram(); */
        setLoadingData(false);
    }

    const getAssetDetails = async assets_id => {
        try {
            const array = await Promise.all(
                assets_id.map(async (id) => {
                    const response = await showAssets({ table, project_id, id });
                    return response.data?.files || [];
                })
            );

            const mergedArray = array.reduce((acc, files) => acc.concat(files), []);
            setAssetsImg(mergedArray);
            setFistLoad(false)
        } catch (error) {
            console.error('Error al obtener detalles de activos:', error);
        }
    }

    //obtenemos los bienes
    const getAssets = async () => {
        let query = { type_id: TypeAssetID, check_status: "Verificado" }
        let response = await indexAssets({ project_id, table, query });
        if (response.data.length > 0) {
            setAssets(response.data);
            for (let asset of response.data) {
                assets_id.push(asset.id)
            }
            getAssetDetails(assets_id)
            setEmmpty(false)

        } else {
            setEmmpty(true)
            setAssetsImg(null)
            setFistLoad(false)
        }
    }

    //obtenemos los tipos de bienes
    const getAssetsTypes = async () => {
        let response = await indexAssetsTypes({ package_id, table });
        setAssetsTypes(response.data);
    }

    const getBaseLines = async () => {
        let response = await indexAssetsBaseLines({ package_id });
        setBaseLines(response.data);
    }

    // Método para obtener las actividades de mantenimiento
    const getAssetActivities = async () => {
        let response = await indexMaintenanceActivities({ project_id });
        setAssetActivities(response.data);
    }

    const getMaintenanceProgram = async () => {
        let response = await indexMaintenanceProgram({ package_id, project_id, maintenance_sections_id });
        setMaintenanceProgram(response.data);
    }

    const getMaintenanceSections = async () => {
        let response = await indexMaintenanceSections({ package_id, operating_indicator_code: code });
        if (response.data.length > 0) {
            setMaintenanceSectionsId(response.data[0].id);
        }
    }

    const createReport = () => setReportReady(true);

    const getAllData = () => {
        if (TypeAssetID === "") {
            openNotification('warning')
        } else {
            getData()
        }
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotification = type => {
        api[type]({
            message: 'Información',
            description:
                'Debe seleccionar el tipo de Bien',
        });
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setTypeAssetID(value)
    }

    return (
        <Jumbotron style={{ marginTop: 10, marginLeft: 10 }} >
            {contextHolder}
            <Form>
                <Heading color={'black'}>
                    Reporte de Mantenimiento de Equipos y Bienes de
                    {(code === 'UOV-7.2') && ' ITS'}
                    {(code === 'UOV-7.4') && ' Edificación'}
                    {(code === 'UOV-7.5') && ' Medio Ambiente'}
                </Heading>
                <Row>
                    <Alert variant="info">
                        <Alert.Heading>Información</Alert.Heading>
                        <p> En esta sección se crean los reportes mensuales de Mantenimiento de Equipos y Bienes de
                            {(code === 'UOV-7.2') && ' ITS'}
                            {(code === 'UOV-7.4') && ' Edificación'}
                            {(code === 'UOV-7.5') && ' Medio Ambiente'}</p>
                    </Alert>
                </Row>
                <div className='d-flex justify-content-around align-items-center'>
                    {/*  <DatePicker onChange={onChange} picker="month" aria-required /> */}
                    {
                        assetsTypes ? (
                            <div>
                                <Form.Control
                                    as="select"
                                    name="title"
                                    onChange={handleInputChange}
                                >
                                    <option value="">-- Elige un Bien --</option>
                                    {
                                        assetsTypes.map(({ id, name }) => {
                                            return (
                                                <option key={id} value={id}>
                                                    {name}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Control></div>
                        ) : (<h5>Cargando Bienes...</h5>)
                    }
                    {assetsTypes && <Button onClick={getAllData}>Cargar Información</Button>}
                </div>

                {
                    assetsImg
                    && <Button
                        onClick={() => createReport()} variant="outline-danger" className="my-2">
                        <FaFilePdf className="mr-1" />
                        Generar Informe de Mantenimiento de Equipos y Bienes de
                        {(code === 'UOV-7.2') && ' ITS'}
                        {(code === 'UOV-7.4') && ' Edificación'}
                        {(code === 'UOV-7.5') && ' Medio Ambiente'}
                    </Button>
                }

                {
                    (!loadingData && reportReady && assetsImg) && (
                        <PDFDownloadLink
                            document={
                                <BienesPdf
                                    assets={assets}
                                    project_id={project_id}
                                    assetsImg={assetsImg}
                                    assetsTypes={assetsTypes}
                                    /* BaseLines={BaseLines} */
                                    table={table}
                                    code={code}
                                /* assetActivities={assetActivities}
                                MaintenanceProgram={MaintenanceProgram} */
                                />}
                            fileName={`${code}_Reporte_Mantenimiento_de_Equipos_Bienes_${getDate}.pdf`}
                        >
                            {
                                ({ blob, url, loading, error }) =>
                                    loading ? <LoadingDoc /> : (<Button className="mx-1" variant="success" ><FaFilePdf className="mr-1" />Reporte Listo para descargar</Button>)
                            }
                        </PDFDownloadLink>
                    )
                }
                {
                    (!loadingData && reportReady && assetsImg) && (
                        <BlobProvider
                            document={
                                <BienesPdf
                                    assets={assets}
                                    project_id={project_id}
                                    assetsImg={assetsImg}
                                    assetsTypes={assetsTypes}
                                    /* BaseLines={BaseLines} */
                                    table={table}
                                    code={code}
                                /* assetActivities={assetActivities}
                                MaintenanceProgram={MaintenanceProgram} */
                                />
                            }
                        >
                            {({ url, loading }) =>
                                loading ? <LoadingDoc /> : (<a className="text-white" href={url} target="_blank" rel="noopener noreferrer">
                                    <Button className="ml-2 text-white" variant="primary">
                                        <ExternalLinkIcon className="mr-1" />
                                        Abrir reporte en una nueva ventana
                                    </Button>
                                </a>
                                )}
                        </BlobProvider>)
                }

                {
                    Emmpty && <Empty className='mt-4' description='No hay datos' />
                }
                {
                    FistLoad && <p className='mt-4 text-center'>Cargando datos...</p>
                }
            </Form>
        </Jumbotron>
    )
}
)
export default Bienes