import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

const CREATED = 201;
const STATUS_OK = 200;

const url = `maintenance_standard/program_flow`

export const indexMaintenanceProgramFlow = async (props) => {
    const { project_id } = props;
    let response = { data: [], totalItems: 0 };
    let query = props;
    delete query.project_id;
    let params = {};
    if (query) {
        const queryValues = Object.values(query);
        const queryKeys = Object.keys(query);
        for (let index = 0; index < queryValues.length; index++) {
            const element = queryValues[index];
            if (element !== '0')
                params[queryKeys[index]] = element;
        }
    }
    try {
        let fetch = await fetcher({
            url: `/projects/${project_id}/${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_program.js:16 ~ indexMaintenanceProgramFlow ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const showMaintenanceProgramFlow = async (props) => {
    const { project_id, id } = props;
    let response = {};
    try {
        let fetch = await fetcher({
            url: `/projects/${project_id}/${url}/${id}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js:36 ~ showMaintenanceProgramFlow ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const updateMaintenanceProgramFlow = async ({ project_id, id, data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/projects/${project_id}/${url}/${id}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js:65 ~ updateMaintenanceProgramFlow ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteMaintenanceProgramFlow = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/maintenance_standard/program_flow/${id}`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js:82 ~ deleteMaintenanceProgramFlow ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data;
        }
        return { response };
    } catch (error) {
        return { response };
    }
}