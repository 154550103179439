import moment from 'moment';
import React, { useEffect, useState } from 'react'
import GanttChart from 'components/layouts/gantt/Gantt';
import { Skeleton } from 'antd';

const GanttChartTab = (props) => {
	const {
		maintenanceProgram,
		maintenanceProgramFlow,
		assetActivities
	} = props;

	const [form, setForm] = useState({});
	const [tasks, setTasks] = useState({ data: [], links: [] });
	const [currentZoom, setCurrenZoom] = useState('Days');
	const [fullscreen, setFullscreen] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getTasks = async () => {
			setLoading(true);
			const promises = maintenanceProgram.map(async (program) => {
				const { id, asset_activity } = program;
				const iterator = {
					id,
					text: getTaskName(asset_activity),
					start_date: '',
					duration: 0,
					progress: 0
				};

				const childrenTasks = maintenanceProgramFlow
					.filter(item => item.program_id === iterator.id)
					.map((item, index) => {
						return {
							id: `${iterator.id}${item.id}`,
							text: item.responsable_permanent,
							start_date: moment(item.start_date).utcOffset('-06:00').format('YYYY-MM-DD'),
							duration: 1,
							progress: 0,
							parent: iterator.id
						};
					});

				const links = childrenTasks.map((item) => {
					return { id: item.id, source: iterator.id, target: item.id, type: '1' };
				});
				// Si el programa no tiene días asignados
				if (childrenTasks.length === 0) return;
				return { iterator, childrenTasks, links };
			});

			let results = await Promise.all(promises);
			// Filtrar sólo los elementos que tengan childrenTask
			results = results.filter((item) => item);

			const mergedData = results.reduce(
				(merged, { iterator, childrenTasks, links }) => {
					merged.data.push(iterator, ...childrenTasks);
					merged.links.push(...links);
					return merged;
				},
				{ data: [], links: [] }
			);

			setTasks(prevState => ({
				...prevState,
				data: [...prevState.data, ...mergedData.data],
				links: [...prevState.links, ...mergedData.links]
			}));
			setLoading(false);
		};

		if (maintenanceProgramFlow.length > 0) getTasks();

	}, [maintenanceProgram, maintenanceProgramFlow]);

	const getTaskName = (asset_activity) => {
		const { maintenance_activity_id } = asset_activity;
		const foundActivity = assetActivities.find((item) => item.id === maintenance_activity_id);
		if (foundActivity) {
			const { code, description } = foundActivity;
			return `${code} - ${description}`;
		}
		return 'Unknown Task';
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
	}

	const handleChangeZoom = (event) => {
		const { value } = event.target;
		setCurrenZoom(value);
	}

	return (
		<>
			<section className="sectionSearch">
				<label>Fecha incial</label>
				<input type="date" name="start_date" value={form.start_date} onChange={handleChange} />
				<label>Fecha final</label>
				<input type="date" name="end_date" value={form.end_date} onChange={handleChange} />
				<label>Zoom</label>
				<select onChange={handleChangeZoom} value={currentZoom}>
					<option value='Hours'>Horas</option>
					<option value='Days'>Días</option>
					<option value='Months'>Meses</option>
				</select>
			</section>
			<div>
				{
					loading ? <Skeleton active /> :
						<GanttChart
							data={tasks.data}
							links={tasks.links}
							zoom={currentZoom}
							fullscreen={fullscreen}
							loading={loading}
						/>
				}
			</div>
		</>
	)
}

export default GanttChartTab