import React from 'react'
import PropTypes from 'prop-types'
import ModalComp from 'components/layouts/Modal'
import ButtonIcon from 'components/layouts/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import FormIcon from 'components/layouts/FormIcon'
import useForm from 'hooks/useForm'
import { storeMaintenanceBudgets } from 'api/maintenance_standards/budgets'
import Swal from 'sweetalert2'
import { Skeleton } from 'antd'

const moment = require('moment');

const AddBudget = ({ onClose, hookProjects, hookStandards, hookBudgets, isEdit }) => {
	const hookForm = useForm();

	const validate = () => {
		const { form } = hookForm;
		if (isEdit) {
			return form?.budget;
		} else {
			return form?.budget && form?.month_year;
		}
	}
	const onSave = async () => {
		if (validate()) {
			hookForm.setLoading(true);
			const data = {
				...hookForm.form,
				maintenance_standard_id: hookStandards.dataSel?.id,
				project_id: hookProjects.dataSel?.id
			}
			if (isEdit) {
				data.month_year = moment.utc(hookBudgets.dataSel?.month_year, 'MMMM YYYY', 'es').startOf('day').format('YYYY-MM-DD');
			}
			// Este método detecta en el backend si se actualiza o se crea el registro
			const fetch = await storeMaintenanceBudgets({ data });
			if (fetch.res) {
				const month_year = moment.utc(fetch.data.month_year, 'YYYY-MM-DD').startOf('day').format('MMMM YYYY');
				if (fetch.status === 201) {
					Swal.fire('¡Operación exitosa!', 'El presupuesto ha sido agregado de forma exitosa', 'success');
					hookBudgets.addElement({ ...fetch.data, month_year });
				} else if (fetch.status === 200) {
					Swal.fire('¡Operación exitosa!', 'El presupuesto ha sido actualizado de forma exitosa', 'success');
					hookBudgets.updateElement({ ...fetch.data, month_year }, ['project_id', 'maintenance_standard_id', 'month_year']);
				}
				onClose();
			} else {
				Swal.fire('¡Operación fallida!', fetch.message, 'info');
			}
			hookForm.setLoading(false);
		} else {
			Swal.fire('¡Formulario incompleto!', 'Verifique los datos del formulario', 'info');
		}
	}

	return (
		<ModalComp
			onClose={onClose}
			title='Agregar presupuesto'
			size='md'
			body={<>
				{
					hookForm.loading ? <Skeleton active /> : <>
						{!isEdit &&
							<FormIcon title='Mes-Año' type='month' name='month_year' value={hookForm.form?.month_year} handleChange={hookForm.handleChange} />
						}
						<FormIcon title='Presupuesto' type='number' name='budget' value={hookForm.form?.budget} handleChange={hookForm.handleChange} />
					</>
				}
			</>}
			footer={<>
				<ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' />
				<ButtonIcon name={isEdit ? 'Actualizar' : 'Guardar'} icon={faSave} onClick={onSave} variant='outline-success' disabled={hookForm.loading} />
			</>}
		/>
	)
}

AddBudget.propTypes = {
	onClose: PropTypes.func.isRequired,
	hookProjects: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				code: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			})
		).isRequired,
		dataSel: PropTypes.shape({
			id: PropTypes.number.isRequired
		}),
		setDataSel: PropTypes.func.isRequired
	}),
	hookStandards: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				code: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			})
		).isRequired,
		dataSel: PropTypes.shape({
			id: PropTypes.number.isRequired
		}),
		setDataSel: PropTypes.func.isRequired,
	}),
	hookBudgets: PropTypes.shape({
		dataSel: PropTypes.shape({
			id: PropTypes.number.isRequired,
			month_year: PropTypes.string
		}),
		addElement: PropTypes.func.isRequired,
		updateElement: PropTypes.func.isRequired
	}),
	isEdit: PropTypes.bool
}

export default AddBudget