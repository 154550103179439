import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxODC = () => {
    const table = 'drainage_works_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, edit: true, add: true, width: 10, pdf: true , widthXLSX: 100 },
        { label: 'KM_INICIAL', description: 'Cadenamiento o Kilometro inicial', value: 'start_km', type: 'String', show: true, edit: true, add: true, width: 15, pdf: true , widthXLSX: 100 },
        { label: 'KM_FINAL', description: 'Kilometro final', value: 'end_km', type: 'String', allow: null, show: true, edit: true, add: true, width: 15, pdf: true , widthXLSX: 100 },
        { label: 'CUERPO', description: 'Cuerpo', value: 'body', type: 'String', edit: true, add: true, width: 15, pdf: true , widthXLSX: 100 },
        { label: 'TIPO', description: 'Tipo', value: 'type', type: 'String', allow: null, show: true, edit: true, add: true, width: 20, pdf: true , widthXLSX: 100 },
        { label: 'LONGITUD', description: 'Longitud', value: 'longitude', type: 'Number', allow: null, edit: true, add: true, width: 15 , widthXLSX: 100 },
        { label: 'LATITUD', description: 'Latitude', value: 'latitude', type: 'Number', allow: null, edit: true, add: true, width: 15 , widthXLSX: 100 },
        { label: 'LARGO_M', description: 'Largo en metros', value: 'length_meters', type: 'Number', allow: null, show: true, edit: true, add: true, width: 15 , widthXLSX: 100 },
        { label: 'ANCHO_M', description: 'Ancho en metros', value: 'width_meters', type: 'Number', allow: null, show: true, edit: true, add: true, width: 15 , widthXLSX: 100 },
        { label: 'ALTO_M', description: 'Alto en metros', value: 'high_meters', type: 'Number', allow: null, show: true, edit: true, add: true, width: 15 , widthXLSX: 100 },
        { label: 'AZOLVE', description: 'Azolve', value: 'azolve', type: 'String', allow: null, show: true, edit: true, add: true, width: 20 , widthXLSX: 100 },
        { label: 'DAÑADO', description: 'Daño', value: 'damaged', type: 'String', allow: null, edit: true, add: true, width: 20 , widthXLSX: 100 },
        { label: 'FOTOGRAFIA', description: 'Fotografía', value: 'file_name', type: 'String', allow: null, width: 20 , widthXLSX: 100 },
        { label: 'OBSERVACIONES', description: 'Observaciones', value: 'observations', type: 'String', allow: null, edit: true, add: true, width: 20 , widthXLSX: 100 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true , widthXLSX: 100 },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30 , widthXLSX: 100 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30 , widthXLSX: 100 },
    ];

    const km = [{ index: 1 }, { index: 2 }];    // Arreglo de indices que irán con formato 000+000
    const percentage = [];                      // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxODC;