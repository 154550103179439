import Axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Button, Table, Spinner, Row, Col, Modal, Form } from 'react-bootstrap';
import { FaCreditCard, FaExchangeAlt, FaRegChartBar, FaUserFriends } from 'react-icons/fa';
import { __SERVER__ } from 'server/info';
import FAB from '../generales/FAB.jsx';
import Swal from 'sweetalert2';
import _ from 'underscore';
import './css/ElectronicPayments.css';

const ModalAdd = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            package_id: -1,
            project_id: -1,
            projects: [],
            email: ''
        }
    }

    componentDidMount = () => {
        this.setState({ projects: this.props.projects });
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
        this.props.update();
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleChangeProject = (event) => {
        let value = parseInt(event.target.value);
        this.setState({ project_id: value });
    }

    handleChangePackage = (event) => {
        let value = parseInt(event.target.value);
        let projects = this.props.projects;

        if (value !== -1) {
            projects = projects.filter(project => project.package_id === value);
        }
        this.setState({
            projects,
            package_id: value,
            project_id: -1
        });
    }

    addProject = async () => {
        let { projects, project_id, email } = this.state;
        let name = projects[_.findIndex(projects, { id: project_id })].code + " - " + projects[_.findIndex(projects, { id: project_id })].name;

        if (email === '') {
            Swal.fire(
                '¡Email inválido!',
                'La dirección de correo electrónico es inválida',
                'warning'
            );
            return;
        }
        Swal.fire({
            title: '¿Está seguro?',
            text: 'Esta acción habilitará el módulo "medios electrónicos de pago" para la autopista ' + name,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, habilitar!',
            cancelButtonText: 'No, regresar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.setState({ project_id: -1 });
                let response = await Axios.post(`${__SERVER__}/victum_openpay/projects`, { project_id, name, email });
                if (response.status === 200) {
                    Swal.fire(
                        'Operación exitosa!',
                        'El módulo de medios electrónicos de pago fue habilitado para la autopista' + name,
                        'success'
                    );
                    this.handleClose();
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Operación cancelada',
                    'El módulo de medios electrónicos de pago no fue habilitado para la autopista ' + name,
                    'error'
                );
            }
        });
    }

    render = () => {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Paquetes</Form.Label>
                            <Form.Control as="select" name={'package_id'} onChange={this.handleChangePackage} custom>
                                <option value={-1}>Todos</option>
                                {
                                    this.props.packages.map((pack, index) =>
                                        <option key={index} value={pack.id}>{pack.code} - {pack.name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Autopistas</Form.Label>
                            <Form.Control as="select" name={'project_id'} onChange={this.handleChangeProject} custom>
                                <option value={-1}>Elije una autopista</option>
                                {
                                    this.state.projects.map((project, index) =>
                                        <option key={index} value={project.id}>{project.code} - {project.name}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>
                        {this.state.project_id !== -1 ?
                            <Form.Group>
                                <Form.Label>Correo electrónico</Form.Label>
                                <Form.Control type="email" name="email" onChange={this.handleChange} placeholder="Introduzca su dirección de correo electrónico" />
                            </Form.Group> : null}

                        <Form.Group>
                            <Button variant="outline-success" size="lg" onClick={this.addProject} block
                                disabled={this.state.project_id !== -1 ? false : true}>Agregar medios electrónicos de pago</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal >
        );
    }
}

const LoadTable = class extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const html = [];
        for (let index = 0; index < this.props.rows; index++) {
            html.push(<td key={index}><Spinner animation="border" size="sm" /></td>);
        }
        return (
            < tr >{html}</tr >
        );
    }
}

const ElectronicPayments = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttons_active: [
                'primary',
                'secondary',
                'secondary',
                'secondary',
                'secondary'],
            projects_openpay: [],
            projects_balances: [],
            projects_balances_movements: [],
            projects_balances_selected: [],
            drissly_carriers: [],
            victum_users: [],
            selected: [],
            projects: [],
            packages: [],
            modal: false,
            getting: true
        }
    }

    componentDidMount = async () => {
        this.getProjectsOpenpay();
        this.getDrisslyCarriers();
        this.getVictumUsers();
    }

    getPackages = async () => {
        let response = await Axios.get(`${__SERVER__}/packages`);
        if (response.status === 200) {
            this.setState({
                packages: response.data
            });
        }
    }

    getProjects = async () => {
        let response = await Axios.get(`${__SERVER__}/projects`);
        if (response.status === 200) {
            // Eliminar las autopistas que ya tienen medios electrónicos de pago
            this.state.projects_openpay.map((item) => {
                let pos = _.findIndex(response.data, { id: item.project_id });
                // console.log("POS: ", pos, "-> ", response.data);
                if (pos !== -1) {
                    response.data.splice(pos, 1);
                }
            });
            this.setState({
                projects: response.data
            });
        }
    }
    getVictumUsers = async () => {
        let response = await Axios.get(`${__SERVER__}/victum_users`);
        if (response.status === 200) {
            this.setState({
                victum_users: response.data
            });
        }
    }
    getProjectsBalanceMovements = async () => {
        let response = await Axios.get(`${__SERVER__}/projects/balances/movements`);
        if (response.status === 200) {
            this.setState({
                projects_balances_movements: response.data
            });
        }
    }

    getDrisslyCarriers = async () => {
        let response = await Axios.get(`${__SERVER__}/drissly/carriers`);
        if (response.status === 200) {
            this.setState({ drissly_carriers: response.data });
        }
    }

    getProjectsOpenpay = async () => {
        this.setState({ getting: true });
        let response = await Axios.get(`${__SERVER__}/victum_openpay/projects`);
        // console.log("Projects openpay response: ", response);
        if (response.status === 200) {
            this.setState({
                selected: new Array(response.data.length).fill(""),
                projects_openpay: response.data
            });
        }
        this.getProjects();
        this.getPackages();
        this.getProjectsBalances();
        this.getProjectsBalanceMovements();
        this.setState({ getting: false });
    }

    getProjectsBalances = async () => {
        let response = await Axios.get(`${__SERVER__}/projects/balances`);
        if (response.status === 200) {
            this.setState({ projects_balances: response.data });
        }
    }


    handleOptionChange = (i) => {
        this.setState(prevState => {
            const buttons_active = prevState.buttons_active.map((item, index) => {
                return i === index ? 'primary' : 'secondary'
            })
            return { buttons_active, }
        })
    }

    changeClass = (i, project_openpay_id) => {
        this.setState(prevState => {
            const selected = prevState.selected.map((item, index) => {
                return i === index ? 'selected' : ''
            })
            return { selected }
        })
        let data = this.state.projects_balances.filter(item => item.projects_openpay_id == project_openpay_id);
        this.setState({ projects_balances_selected: data });
    }

    openOrCloseModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    render = () => {
        let {
            projects_openpay,
            projects_balances,
            drissly_carriers,
            projects_balances_selected,
            projects_balances_movements,
            victum_users } = this.state;

        return (
            <div>
                <h2>Medios electrónicos de pago</h2>
                <div style={{ background: '#fff', height: '85vh' }}>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Afiliaciones</Tooltip>}>
                        <Button name={0} onClick={e => this.handleOptionChange(0)} variant={`outline-${this.state.buttons_active[0]}`}>
                            <FaCreditCard /> Afiliaciones</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Saldos</Tooltip>}>
                        <Button name={1} onClick={e => this.handleOptionChange(1)} variant={`outline-${this.state.buttons_active[1]}`}>
                            <FaRegChartBar /> Saldos</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Movimientos</Tooltip>}>
                        <Button name={2} onClick={e => this.handleOptionChange(2)} variant={`outline-${this.state.buttons_active[2]}`}>
                            <FaExchangeAlt /> Movimientos</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Usuarios de autopista</Tooltip>}>
                        <Button name={3} onClick={e => this.handleOptionChange(3)} variant={`outline-${this.state.buttons_active[3]}`}>
                            <FaUserFriends /> Usuarios de Autopista</Button>
                    </OverlayTrigger>
                    {/* Afiliaciones */}
                    {
                        this.state.buttons_active[0] === 'primary' ? <div className={'scrollvertical'}>
                            <h3>Afiliaciones</h3>
                            <FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Autopista</th>
                                        <th>Paquete</th>
                                        <th>Openpay ID</th>
                                        <th>Fecha de afiliación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.getting ?
                                            <LoadTable rows={5} /> : null
                                    }
                                    {
                                        projects_openpay.length > 0 ?
                                            projects_openpay.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.project.code} - {item.project.name}</td>
                                                    <td>{item.package.code} - {item.package.name}</td>
                                                    <td>{item.openpay_id}</td>
                                                    <td>{moment(item.created_at).format('lll')}</td>
                                                </tr>
                                            ) : null
                                    }
                                </tbody>
                            </Table>
                        </div> : null
                    }

                    {/* Modal para agregar medios electrónicos de pago a autopistas  */}
                    {
                        this.state.modal ?
                            <ModalAdd
                                title="Agrear medios electrónicos de pago"
                                projects={this.state.projects}
                                update={this.getProjectsOpenpay}
                                packages={this.state.packages}
                                onClose={this.openOrCloseModal}
                            /> : null
                    }
                    {/* Saldos */}
                    {
                        this.state.buttons_active[1] === 'primary' ? <div>
                            <h3>Saldos</h3>
                            <Row className={'align-items-start'}>
                                <Col md={4} className={"scrollvertical"}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Autopista</th>
                                            </tr>
                                        </thead>
                                        <tbody className={'tbody'}>
                                            {
                                                this.state.getting ?
                                                    <LoadTable rows={2} /> : null
                                            }
                                            {
                                                projects_openpay.length > 0 ?
                                                    projects_openpay.map((item, index) =>
                                                        <tr onClick={e => this.changeClass(index, item.id)} className={this.state.selected[index]} key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{
                                                                item.project.code
                                                            } - {
                                                                    item.project.name
                                                                }</td>
                                                        </tr>
                                                    ) : null
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={8} className={"scrollvertical"}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Carrier</th>
                                                <th>Saldo asignado</th>
                                                <th>Saldo gastado</th>
                                                <th>Saldo restante</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.getting ?
                                                    <LoadTable rows={4} /> : null
                                            }
                                            {
                                                projects_balances_selected.length > 0 ?
                                                    projects_balances_selected.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{
                                                                drissly_carriers.length > 0 ?
                                                                    drissly_carriers[_.findIndex(drissly_carriers, {id: item.drissly_carrier_id})].name :
                                                                    <Spinner animation="border" size="sm" />
                                                            }</td>
                                                            <td>${item.assigned_balance.toFixed(2)}</td>
                                                            <td>${item.spended_balance.toFixed(2)}</td>
                                                            <td>${item.remaining_balance.toFixed(2)}</td>
                                                        </tr>
                                                    ) : null
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div> : null
                    }

                    {/* Movimientos */}
                    {
                        this.state.buttons_active[2] === 'primary' ?
                            <div className={"scrollvertical"}>
                                <h3>Movimientos</h3>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Usuario</th>
                                            <th>Descripción</th>
                                            <th>Cantidad</th>
                                            <th>Tipo</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.getting ?
                                                <LoadTable rows={6} /> : null
                                        }
                                        {
                                            projects_balances_movements.length > 0 ?
                                                projects_balances_movements.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td><strong>{item.user.username}</strong>: {item.user.first_name} {item.user.last_name}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.type}</td>
                                                        <td>{moment(item.created_at).format('lll')}</td>
                                                    </tr>
                                                ) : null
                                        }
                                    </tbody>
                                </Table>
                            </div> : null
                    }

                    {/* Usuarios de Autopista */}
                    {
                        this.state.buttons_active[3] === 'primary' ?
                            <div className={"scrollvertical"}>
                                <h3>Usuarios de autopista</h3>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Usuario</th>
                                            <th>Openpay ID</th>
                                            <th>Teléfono</th>
                                            <th>Saldo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.getting ?
                                                <LoadTable rows={6} /> : null
                                        }
                                        {
                                            victum_users.length > 0 ?
                                                victum_users.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.first_name} {item.last_name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.openpay_id}</td>
                                                        <td>{item.phone}</td>
                                                        <td>${item.balance}</td>
                                                    </tr>
                                                ) : null
                                        }
                                    </tbody>
                                </Table>
                            </div> : null
                    }

                </div>

            </div >
        );
    }
}

export default ElectronicPayments;