import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { Heading } from '@chakra-ui/react'
import ButtonIcon from 'components/layouts/ButtonIcon'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const Header = ({ hookProjects, hookStandards, addBudget }) => {
    const changeProject = (e) => {
        const { value: project_id } = e.target;
        const currentProject = hookProjects.data.find(project => project.id == project_id);
        if (currentProject) hookProjects.setDataSel(currentProject);
    }

    return (
        <Row style={{ justifyContent: 'space-between', height: '10vh' }}>
            <Col>
                <Heading>Presupuestos</Heading>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <ButtonIcon
                            icon={faPlus}
                            name='Agregar presupuesto'
                            variant='success'
                            onClick={addBudget}
                            disabled={!hookStandards.dataSel}
                        />
                    </Col>
                    <Col>
                        <select className='form-control sm' onChange={changeProject}>
                            {
                                hookProjects.data.map(project =>
                                    <option value={project.id} key={project.id}>
                                        {project.code} {project.name}
                                    </option>
                                )
                            }
                        </select>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

Header.propTypes = {
    hookProjects: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                code: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
        setDataSel: PropTypes.func.isRequired
    }),
    hookStandards: PropTypes.shape({
        dataSel: PropTypes.object
    }).isRequired,
    addBudget: PropTypes.func
}

export default Header