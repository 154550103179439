import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import { Row } from 'react-bootstrap';
import FormIcon from 'components/layouts/FormIcon';
import TabsComp from 'components/layouts/Tabs';
import moment from 'moment';

const ViewMaintenanceProgram = (props) => {
    const { onClose, programSel } = props;
    return (
        <ModalComp
            onClose={onClose}
            title='Detalles del Programa de Mantenimiento'
            size='lg'
            body={<>
                <TabsComp
                    tabColor='black'
                    tablist={['Información general', 'Responsable']}
                    tabpanels={[
                        <>
                            <Row>
                                <FormIcon type='text' title='Fecha inicial' value={moment(programSel?.start_date).format('LLLL')} />
                                <FormIcon type='text' title='Fecha final' value={moment(programSel?.end_date).format('LLLL')} />
                            </Row>
                            <Row>
                                <FormIcon type='time' title='Hora inicial' value={programSel?.start_time} />
                                <FormIcon type='time' title='Hora final' value={programSel?.end_time} />
                            </Row>
                        </>,
                        <>
                            <Row>
                                <FormIcon title='Nombre(s)' value={programSel?.asset_activity?.responsable?.first_name} />
                                <FormIcon title='Apellido(s)' value={programSel?.asset_activity?.responsable?.last_name} />
                            </Row>
                            <Row>
                                <FormIcon type='email' title='Correo electrónico' value={programSel?.asset_activity?.responsable?.email} />
                                <FormIcon type='phone' title='Teléfono' value={programSel?.asset_activity?.responsable?.phone} />
                            </Row>
                        </>
                    ]}
                />
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' onClick={onClose} variant='outline-secondary' icon={faTimes} />
            </>}
        />
    )
}

export default ViewMaintenanceProgram