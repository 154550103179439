import React from 'react'
import { Table } from 'react-bootstrap'

const WorkOrderTable = ({ report_supplies }) => {
    return (
        <Table striped bordered id="emp">
            <thead>
                <tr>
                    <th className="center">Unidades</th>
                    <th className="center">Descripción</th>
                    <th className="center">Precio</th>
                    <th className="center">Observaciones</th>
                </tr>
            </thead>
            <tbody className="th">
                {
                    report_supplies.map((item) =>
                        <tr key={item.id}>
                            <td className="center">{item.count}</td>
                            <td className="left">{item.description}</td>
                            <td className="right">{item.amount}</td>
                            <td className="left">{item.observations}</td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
    )
}

export default WorkOrderTable