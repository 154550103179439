import { faFileExcel, faFilePdf, faSpinner } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import React from 'react';

const FiltersAssets = (props) => {
    const { getData, assetTypes, baseLines, filters, setFilters,
        getXLSXData, getPDFData, loadingXLSX, loadingPDF } = props;

    const searchInfo = () => {
        getData();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }

    return (
        <section className="assets">
            <section className="sectionSearch" >
                <article>
                    <label>Tipo de elemento</label>
                    <select name='type_id' onChange={handleChange}>
                        <option value="-1">Todos</option>
                        {
                            assetTypes.map((item) =>
                                <option value={item.id} key={item.id}>{item.name}</option>
                            )
                        }
                    </select>
                </article>
                <article>
                    <label>Línea base</label>
                    <select name='assets_base_line_id' onChange={handleChange}>
                        <option value="-1">Todos</option>
                        {
                            baseLines.map((item) =>
                                <option value={item.id} key={item.id}>{item.name}</option>
                            )
                        }
                    </select>
                </article>
                <article>
                    <label>Estado de verificación</label>
                    <select name='check_status' onChange={handleChange}>
                        <option value={-1}>Todos</option>
                        <option value='Verificado'>Verificado</option>
                        <option value='Parcialmente Verificado'>Parcialmente Verificado</option>
                        <option value='Sin Verificar'>Sin Verificar</option>
                    </select>
                </article>
                <article>
                    <button className="botonBusqueda" onClick={searchInfo}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                    </button>
                </article>
                <article className='ml-4'>
                    <ButtonIcon size='lg' icon={loadingXLSX ? faSpinner : faFileExcel} variant='outline-success' onClick={getXLSXData}
                        tooltip={loadingXLSX ? 'Descargando XLSX...' : 'Descargar datos en formato XLSX'} disabled={loadingXLSX} />
                </article>
                <article className='ml-4'>
                    <ButtonIcon size='lg' icon={loadingPDF ? faSpinner : faFilePdf} variant='outline-danger' onClick={getPDFData}
                        tooltip={loadingPDF ? 'Descargando PDF...' : 'Descargar datos en formato PDF'} disabled={loadingPDF} />
                </article>
            </section >
        </section>
    )
}

export default FiltersAssets