import axios from "axios";

const axiosAPIInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}`,
});

const fetcher = async (args) => {
    try {
        const response = await axiosAPIInstance(args);
        return response;
    } catch (error) {
        const { response } = error;
        if (response && response.status === 401) {
            console.log("Cerrando sesión!");
            // window.location.href = '/logout';
        }
        return response || error.request || error.message;
    }
};

export default fetcher;
