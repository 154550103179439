import React from 'react'
import './css/Proyectos.css';
import './css/Menu.css';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbRedux from './generales/BreadCrumbRedux';
import { BREADCRUMB_ADD_AJUSTES, UPDATE_COMPONENT_AJUSTES, UPDATE_OPTION_AJUSTES } from 'store/actions/actionTypes';
import Users from './subComponentsSettings/Users';
import UsersType from './subComponentsSettings/UsersType';
import TwoFactorAuthComp from './subComponentsSettings/security/TwoFactorAuth';
import ChangePasswordComp from './subComponentsSettings/security/ChangePassword';
import SuppliesComp from './subComponentsSettings/supplies/Index';
import { allowModule } from './libs/allowModule';
import BudgetsComp from './subComponentsSettings/supplies/budgets/Index';

const md5 = require('md5');

const Ajustes = () => {
	const dispatch = useDispatch();
	const { componentActive, options, option } = useSelector(state => state.ajustes);

	const openView = (component) => {
		switch (component) {
			case 'users':
				dispatch({ type: UPDATE_COMPONENT_AJUSTES, componentActive: <Users /> });
				break;
			case 'roles':
				dispatch({ type: UPDATE_COMPONENT_AJUSTES, componentActive: <UsersType /> });
				break;
			case 'two_factor_auth':
				dispatch({ type: UPDATE_COMPONENT_AJUSTES, componentActive: <TwoFactorAuthComp /> });
				break;
			case 'change_password':
				dispatch({ type: UPDATE_COMPONENT_AJUSTES, componentActive: <ChangePasswordComp /> });
				break;
			case 'supplies':
				dispatch({ type: UPDATE_COMPONENT_AJUSTES, componentActive: <SuppliesComp /> });
				break;
			case 'budgets':
				dispatch({ type: UPDATE_COMPONENT_AJUSTES, componentActive: <BudgetsComp /> });
				break;
			default: break;
		}
	}

	const handleInteraction = (item, index, type) => {
		const key = md5(`${type}_${item.title}_${index}`);
		const level = md5(`${type}_213`);
		dispatch({ type: BREADCRUMB_ADD_AJUSTES, path: item.title, key, level });
		if (type === '_OPTION_') {
			dispatch({ type: UPDATE_OPTION_AJUSTES, index });
		}
		openView(item.component || item.title);
	};

	const renderOption = (item, index, type) => (
		<button
			key={index}
			className="contentP"
			onClick={() => handleInteraction(item, index, type)}
		>
			<h1 className="nombreP">{item.title}</h1>
		</button>
	);

	return (
		<div>
			{/* Crear un BreadCrum AQUI*/}
			<BreadCrumbRedux here="AJUSTES" />
			{
				componentActive || <div className="con_">
					<section className="contentPro">
						{
							options
								.filter((item) => allowModule(item))
								.map((item, i) => renderOption(item, i, '_OPTION_'))
						}
					</section>
					{
						option !== -1 && (
							<section className="contentPro">
								{
									options[option].sub
										.filter((item) => allowModule(item))
										.map((item, i) => renderOption(item, i, 'SubOptions'))
								}
							</section>
						)
					}
				</div>
			}
		</div>
	)
}

export default Ajustes;