import { faInfo, faSuitcaseRolling } from '@fortawesome/free-solid-svg-icons';
import { indexObacIncidents, updateObacIncidents } from 'api/operation_standards/incidents';
import FlexButton from 'components/layouts/FlexButton';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Variables } from 'variables/Variables';
import ActivityAssignment from '../modals/ActivityAssignment';
import IncidentDetails from '../modals/IncidentDetails';
import NewIncident from '../modals/NewIncident';

const IncidentsTab = (props) => {
    const {
        project_id,
        table,
        package_id,
        user_code,
        maintenance_types_id,
        maintenance_sections_id
    } = props;

    const operating_indicator_id = Variables.indicator_id;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [assetSel, setAssetSel] = useState(null);
    const [modalDetails, setModalDetails] = useState(false);
    const [incidentSel, setIncidentSel] = useState({});
    const [modalNewIncident, setModalNewIncident] = useState(false);
    const [modalAssignActivity, setModalAssignActivity] = useState(false);

    useEffect(() => {
        getData();
    }, [page, pageSize]);

    const getData = async () => {
        setLoading(true);
        const response = await indexObacIncidents({ project_id, page, pageSize, operating_indicator_id });
        const { data, totalItems } = response;
        // console.log("🚀 ~ file: IncidentsTab.jsx:41 ~ getData ~ data:", data)
        setData(data);
        setTotalItems(totalItems);
        setLoading(false);
    }

    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const viewDetails = (item) => {
        setIncidentSel(item);
        setModalDetails(!modalDetails);
    }

    const updateStatusIncident = async () => {
        const { id } = incidentSel;
        const ATENDIENDO = 3;
        updateObacIncidents({ project_id, id, data: { status_id: ATENDIENDO } });
    }
    const assignActivity = (item) => {
        const { asset_data_id } = item;
        setIncidentSel(item);
        setAssetSel({ id: asset_data_id });
        if (asset_data_id)
            setModalAssignActivity(true);
        else
            Swal.fire('¡Sin activo!', 'Para poder asignar este incidente a programa de mantenimiento, este tiene que estar vinculado a un elemento del inventario.', 'info');
    }

    return (
        <>
            <TableComp
                headers={['#', 'Cadenamiento', 'Segmento', 'Creado por', 'Puesto', 'Responsable', 'Fecha de resolución', 'Creado en', 'Estado', 'Acciones']}
                keys={['#', 'kilometer++meter', 'segment.name', 'user.first_name+user.last_name', 'user.position', 'responsable.first_name+responsable.last_name', 'completion_date', 'created_at', 'status_id']}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}

                actions={[
                    { icon: faInfo, variant: 'outline-info', tooltip: 'Ver detalles', handleClick: viewDetails },
                    {
                        icon: faSuitcaseRolling, handleClick: assignActivity, tooltip: 'Asignar Actividad', variant: 'outline-success',
                        dinamic: [{ key: 'status_id', value: 1 }]
                    },
                ]}
                km={[{ index: 1 }]}
                badges={[{ index: 8 }]}
                badges_values={[{
                    col: 'status_id',
                    values: [
                        { color: 'teal', value: 1, name: "Creado" },
                        { color: 'blue', value: 2, name: "Levantado" },
                        { color: 'orange', value: 3, name: "Atendiendo" },
                        { color: 'red', value: 4, name: "Cerrado" },
                        { color: 'yellow', value: 5, name: "Cerrado con retraso" },
                        { color: 'purple', value: 6, name: "No hecho" },
                        { color: 'green', value: 7, name: "Completado" }
                    ]
                }]}
            />
            {/* Paginación */}
            <PaginationComp onChange={changePage} current={page} defaultPageSize={pageSize} totalItems={totalItems} />

            {/* Botón paa agregar un incidente */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalNewIncident(!modalNewIncident)} />
            {/* Modall para ver los detalles del incidente */}
            {
                modalDetails && <IncidentDetails
                    onClose={() => setModalDetails(!modalDetails)}
                    incidentSel={incidentSel}
                    project_id={project_id}
                />
            }
            {/* Modal para agregar incidente */}
            {
                modalNewIncident && <NewIncident
                    onClose={() => setModalNewIncident(false)}
                    project_id={project_id}
                    table={table}
                    package_id={package_id}
                    syncServer={getData}
                    user_code={user_code}
                />
            }
            {/* Modal para la asignación de actividades */}
            {
                modalAssignActivity && <ActivityAssignment
                    onClose={() => setModalAssignActivity(false)}
                    table_name={table}
                    project_id={project_id}
                    asset={assetSel}
                    package_id={package_id}
                    syncServer={updateStatusIncident}
                    maintenance_types_id={maintenance_types_id}
                    maintenance_sections_id={maintenance_sections_id}
                    getData={getData}
                    type='CORRECTIVO'
                    title='Programa de Mantenimiento Correctivo'
                />
            }
        </>
    )
}

export default IncidentsTab