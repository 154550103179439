import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from 'antd';
import { indexMaintenanceActivities } from 'api/maintenance_standards/maintenance_activities';
import { storeMaintenanceProgram } from 'api/maintenance_standards/maintenance_program';
import { indexUsers } from 'api/users/users';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import ModalComp from 'components/layouts/Modal'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import _ from 'underscore';
import AssignSchedule from './AssignSchedule';
import CustomSchedule from './CustomSchedule';

const ModalAssignActivity = (props) => {
    const { onClose, project_id, package_id, table, itemSel, type, updateStatusIncident, title } = props;

    const maintenance_standard_id = useSelector(state => state.proyectos.componentActive.props.standard_id);
    const user_id = useSelector(state => state.login.dataUser.id);

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState([]);
    const [form, setForm] = useState({
        periodicity: "dontrepeat",
        repeat_every: "days",
        repeats_number: 1,
        weekdays: [0, 1, 2, 3, 4, 5, 6] // Todos los días por default
    });
    const [checks, setChecks] = useState({
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
    });

    useEffect(() => {
        getUsers();
        getActivities();
    }, []);
    // Efecto para guardar por default el primer usuario obtenido
    useEffect(() => {
        if (users.length > 0)
            setForm({ ...form, responsable_id: users[0].id });
    }, [users]);
    // Efecto para guardar la primera actividad obtenida por default
    useEffect(() => {
        if (activities.length > 0)
            setForm({ ...form, activity_id: activities[0].id });
    }, [activities]);
    // Efecto para detectar que días de la semana se asignará la actividad
    useEffect(() => {
        if (form.periodicity === 'dontrepeat') setForm({ ...form, repeat_every: 'days', weekdays: [0, 1, 2, 3, 4, 5, 6] });
        if (form.periodicity === 'lmxjv') setForm({ ...form, repeat_every: 'days', weekdays: [1, 2, 3, 4, 5] });
        if (form.periodicity === 'everyday') setForm({ ...form, repeat_every: 'days', weekdays: [0, 1, 2, 3, 4, 5, 6] });
    }, [form.periodicity]);
    // Efecto para detectar que días de la semana se asignará la actividad
    useEffect(() => {
        if (form.repeat_every === 'days') setForm({ ...form, weekdays: [0, 1, 2, 3, 4, 5, 6] });
    }, [form.repeat_every]);
    // Efecto para obtener los días de la semana de los checkbox
    useEffect(() => {
        if (form.periodicity === 'custom') {
            const keys = Object.keys(checks);
            let weekdays = [];
            for (let index = 0; index < keys.length; index++) {
                const day = keys[index];
                if (checks[day]) {
                    weekdays.push(index);
                }
            }
            setForm({ ...form, weekdays });
        }
    }, [checks]);

    // Método para obtener los usuarios del paquete
    const getUsers = async () => {
        let response = await indexUsers({ package_id, project_id });
        setUsers(response.data);
    }
    // Método para obtener las actividades de mantenimiento del proyecto
    const getActivities = async () => {
        let response = await indexMaintenanceActivities({ project_id, maintenance_standard_id });
        setActivities(response.data);

    }
    const handleChange = (e) => {
        const { value, name } = e.target;
        setForm({ ...form, [name]: value });
    }

    // Controlador para capturar los datos de los checks marcados (días de la semana)
    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;
        setChecks({ ...checks, [name]: checked });
    };
    // Metodo para asignar formato a las fechas
    const formatDate = (date, format_string) => {
        return moment(date).format(format_string);
    };
    // Método que comprueba si se necesita una fecha de finalización
    const endDate = () => {
        let response = null;
        switch (form.periodicity) {
            case 'dontrepeat': response = false; break;
            case 'everyday': response = true; break;
            case 'lmxjv': response = true; break;
            case 'custom': response = false; break;
            default: response = false; break;
        }
        return response;
    };
    // Método para guardar el programa de mantenimiento
    const save = async () => {
        setLoading(true);
        if (validating()) {
            const { start_date, start_time, end_time, responsable_id, activity_id, weekdays,
                repeats_number, repeat_every } = form;
            let end_date = form.end_date;
            if (!end_date) end_date = start_date;
            let data = {
                maintenance_standard_id,
                user_id,
                start_date,
                end_date,
                start_time: start_time + ':00',
                end_time: end_time + ':00',
                type,
                data: {
                    responsable_id,
                    responsable_name: getResponsableName(responsable_id),
                    activity_id,
                    asset_name: getAssetName(),
                    asset_table_id: itemSel.id,
                    asset_table_name: table + '_data'
                },
                flow: {
                    weekdays,
                    repeats_number,
                    repeat_every
                }
            }
            let response = await storeMaintenanceProgram({ project_id, data });
            if (response) {
                Swal.fire('¡Operación exitosa!', 'Se ha programado el programa de mantenimiento', 'success');
                if (updateStatusIncident) await updateStatusIncident();
                onClose();
            } else {
                Swal.fire('¡Operación fallida!', 'Hubo un error al tratar de programar el mantenimiento.', 'error');
                setLoading(false);
            }
        }
        setLoading(false);
    }
    // Método para obtenener nombre de usuario
    const getResponsableName = (responsable_id) => {
        let name = '';
        let pos = _.findIndex(users, { id: parseInt(responsable_id) });
        if (pos !== -1) name = users[pos].first_name + ' ' + users[pos].last_name;
        return name;
    }
    // Obtener Nombre de la actividad
    const getAssetName = () => {
        let name = 'Unknow Asset';
        try {
            name = `Activo #${itemSel.id}`;
            return name;
        } catch (error) {
            return name;
        }
    }
    // Método para validar las fechas seleccionadas
    const validating = () => {
        let response = false;
        switch (form.periodicity) {
            case 'dontrepeat':
                response = true;
                break;
            case 'everymonth': break;
            case 'everyyear': break;
            case 'custom':
                response = validateCustom();
                break;
            default:
                if (form.end_date) response = true;
                break;
        }
        if (!response && !form.end_date)
            Swal.fire('¡Formulario incompleto!', 'Elija la fecha de finalización.', 'warning');

        return response;
    }
    // Método para validar las fechas del formulario personalizado
    const validateCustom = () => {
        let responseCustom = false;
        switch (form.repeat_every) {
            case 'days':
                if (form.ends !== '') {
                    responseCustom = true;
                } else {
                    Swal.fire('¡Formulario incompleto!', 'Elija la fecha de finalización o número de repeticiones.', 'warning');
                }
                break;
            case 'weeks':
                if (form.ends !== '') {
                    if (getWeekDaysFromChecks().length > 0) {
                        responseCustom = true;
                    } else {
                        Swal.fire('¡Formulario incompleto!', 'Elija al menos un día de la semana.', 'warning');
                    }
                }
                break;
            default: break;
        }
        return responseCustom;
    }
    // Obtiene array de los días de la semana según los check box
    const getWeekDaysFromChecks = () =>
        Object.entries(checks)
            .filter(([, value]) => value)
            .map(([, value], i) => i);

    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={title}
            body={<>
                <center>
                    {
                        loading ? <Skeleton /> :
                            <Form>
                                <Row>
                                    {/* Componente en el que se asigna el responsable, la actividad y el tipo de horario */}
                                    <AssignSchedule
                                        users={users}
                                        activities={activities}
                                        handleChange={handleChange}
                                        form={form}
                                        formatDate={formatDate}
                                    />
                                </Row>
                                {
                                    form?.periodicity !== "custom" ?
                                        <Form.Row>
                                            <FormIcon title={`Fecha ${endDate() ? "inicial" : ""}`} type='date' name='start_date' value={form?.start_date} handleChange={handleChange} />
                                            {
                                                endDate() &&
                                                <FormIcon title='Fecha final' type='date' name='end_date' value={form?.end_date} handleChange={handleChange} />
                                            }
                                            <FormIcon title='Hora inicial' type='time' name='start_time' value={form?.start_time} handleChange={handleChange} />
                                            <FormIcon title='Hora final' type='time' name='end_time' value={form?.end_time} handleChange={handleChange} />
                                        </Form.Row>
                                        :
                                        // Personalizar horario
                                        <CustomSchedule
                                            form={form}
                                            handleChange={handleChange}
                                            handleChangeCheckBox={handleChangeCheckBox}
                                            formatDate={formatDate}
                                            checks={checks}
                                        />
                                }
                            </Form>
                    }
                </center>
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} variant='outline-secondary' onClick={onClose} disabled={loading} />
                <ButtonIcon icon={faSave} name='Asignar' onClick={save} variant='outline-success' disabled={loading} />
            </>}
        />
    )
}

export default ModalAssignActivity