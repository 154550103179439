import React from 'react';

export default class extends React.Component {
  render = () => {
    return (
      <section className="body">
        <h1>Página de Colaboración en construcción</h1>
      </section>
    );
  }
}
