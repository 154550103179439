import fetcherCancelToken from "components/libs/fetcherCancelToken";

const CREATED = 201;
const STATUS_OK = 200;

export const indexMaintenanceBudgets = async ({ project_id, maintenance_standard_id, page, pageSize }) => {
    const url = `/maintenance_standard/monthly_unit_prices`;
    let response = { data: [], totalItems: 0, cancel: () => { } }
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url, method: 'GET',
            params: { project_id, maintenance_standard_id, page, pageSize }
        });
        // console.log("🚀 ~ file: budgets.js:15 ~ indexMaintenanceBudgets ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = { ...fetch.data, cancel }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeMaintenanceBudgets = async ({ data }) => {
    const response = { res: false, data: {}, message: 'Ocurrió un error inesperado al tratar de alamcenar la información', status: 500 };
    try {
        const { fetch } = await fetcherCancelToken({
            url: `/maintenance_standard/monthly_unit_prices`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: budgets.js:34 ~ storeMaintenanceBudgets ~ fetch:", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED) {
            response.data = fetch.data.data;
            response.res = true;
            response.status = fetch.status;
        }
        return response;
    } catch (error) {
        return response;
    }
}