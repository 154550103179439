import React from 'react'
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import stylesHeader from './styles_header';
import stylesTable from './styles_table';
import victum_logo from '../../../../imagenes-app/logo.png'
import moment from 'moment';

class PDFReport extends React.Component {
    constructor(props) {
        super(props)
    }

    render = () => {
        // console.log("PROPS PDF: ", this.props)
        let status = ['En espera', 'Atendiendo', 'Resuelto', 'No resuelto', 'FAKE'];
        return (
            <Document>
                <Page style={stylesTable.body} size={"LEGAL"} orientation={"landscape"}>
                    <View style={stylesHeader.header}>
                        <View style={stylesHeader.headerColLeft}>
                            <Image style={stylesHeader.imageSquare} source={victum_logo} />
                        </View>
                        <View style={stylesHeader.headerColCenter}>
                            <Text style={stylesHeader.title}>Reportes atendidos</Text>
                            <Text style={stylesHeader.subtitle}>{this.props.package.name}</Text>
                        </View>
                        <View style={stylesHeader.headerColRight}>
                            <Image style={stylesHeader.imageSquare} source={victum_logo} />
                        </View>
                    </View>

                    <View style={stylesTable.table}>
                        <View style={stylesTable.tableRowCenter}>
                            <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Reportes atendidos por fecha</Text>
                            </View>
                        </View>
                        <View style={stylesTable.tableRow}>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Folio</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Fecha de reporte</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Fecha de llegada</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Fecha de finalización</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Estatus</Text>
                            </View>
                        </View>
                        {
                            this.props.data.length > 0 ?
                                this.props.data.map((item, index) =>
                                    <View key={index} style={stylesTable.tableRow}>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{item.code}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{item.report_date !== null ? moment(item.report_date).format('lll'): 'No disponible'}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{item.arrival_date !== null ? moment(item.arrival_date).format('lll'): 'No disponible'}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{item.ended_date !== null ? moment(item.ended_date).format('lll'): 'No disponible'}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{status[item.status]}</Text>
                                        </View>
                                    </View>
                                ) : <Text style={stylesTable.tableCell}>No hay datos para mostrar</Text>
                        }
                    </View>
                </Page>
            </Document >
        )
    }
}

export default PDFReport