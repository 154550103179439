import moment from 'moment/moment';
import React from 'react';
import { Document, Page, Text, View, StyleSheet,  Image } from '@react-pdf/renderer';
import { getInfoPackage } from 'variables/Variables';
const { __SERVER__ } = require("../../../server/info");

const styles = StyleSheet.create({
    body:{ padding: 15 },
    Head:{
        display: "flex",
        flexDirection: "row"
    },
    Head_Image:{ width: 110 },
    table: { 
      display: "table",
      borderStyle: "solid",  
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      marginBottom: 15,
      borderColapse: "collapse",
      tableLayout: "auto",
      width: "auto", 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderTopWidth: 1,
      width: "100%" 
    }, 
    tableCol: { 
      borderStyle: "solid",
      borderRightWidth: 1,
      width: "100%",
      height: "auto"
    }, 
    tableCell: { 
      margin: "auto", 
      marginVertical: 5,
      marginHorizontal: 3,
      fontSize: 10,
      textAlign: "center"
    },
    text_center: { textAlign: "center" , fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_title: { textAlign: "center", fontWeight: "bold", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_center50: { textAlign: "center" , fontSize: 12, width: "50%", borderStyle: "solid", borderBottomWidth: 1, borderRightWidth:1 },
    logo:{
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    },
    logo1:{
        width: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid", 
        borderWidth: 1,
    },
    d_flex:{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    }, txt_center: { textAlign: "center" } ,
    text_logo: {textAlign: "center" , fontSize: 19, fontWeight: "heavy", width: "100%", borderStyle: "solid", borderTopWidth: 1, color: "#009644"},
    mb_5:  { marginBottom: 14, borderStyle: "solid", 
    borderWidth: 1, },
    fz_12: {fontSize: 12, textAlign: "center",  width: "100%"},
    flex:{
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" },
    cuad: { display: "flex", flexDirection: "row" , flexWrap: "wrap", justifyContent: "center" }
});

const DataTable = ({inventoryName, inventory, title}) => {
    return (
        <>  
        <Text style={{ fontSize: 12, textAlign: "center", marginBottom: 4 }}>{title}</Text>
        {
            (inventoryName==="vertical_marking_inventory") 
            ? 
            <View style={styles.table}> 
                    <View style={styles.tableRow} fixed> 
                    {
                        ["Id","Punto", "Descripción", "Longitud", "Latitud", "Cadenamiento","Leyenda" ,"Clasificación", "Dimensiones"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                    
                    {
                        inventory && inventory.map( inventor => {
                            return (
                                <View style={styles.tableRow} key={inventor.id} >
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.id}</Text> 
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.point}</Text> 
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.type}</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{parseFloat(inventor.longitude).toFixed(4)}</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{parseFloat(inventor.latitude).toFixed(4)}</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>
                                        {inventor.km}
                                    </Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>
                                        {inventor.legend_description}
                                    </Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.sct_classification}</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.cms_dimensions}</Text>
                                    </View>
                                </View> 
                            )
                        })
                    }      
                </View> : 
                <View style={styles.table}> 
                    <View style={styles.tableRow} fixed> 
                    {
                        ["Id","Punto", "Descripción", "Long. Inicial", "Lat. Inicial", "Long. Final", "Lat. Final", "Largo", "KM inicial", "KM final"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                    
                    {
                        inventory && inventory.map( inventor => {
                            return (
                                <View style={styles.tableRow} key={inventor.id} >
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.id}</Text> 
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.point}</Text> 
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.type}</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{parseFloat(inventor.longitude).toFixed(4)}</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{parseFloat(inventor.latitude).toFixed(4)}</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>
                                        {inventor?.final_longitude ?parseFloat(inventor?.final_longitude).toFixed(4): '-'}
                                    </Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>
                                        {inventor?.final_latitude ? parseFloat(inventor?.final_latitude).toFixed(4): '-'}
                                    </Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{inventor.length} m</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}> { 
                                    (inventor.start_km && (inventor.start_km !== "-")) && (
                                        `${(inventor.start_km).slice(0,-3)}+${(inventor.start_km).slice(-3)}`
                                    )
                                    }</Text>
                                    </View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{
                                    (inventor.end_km && (inventor.end_km !== '-')) && (`${(inventor.end_km).slice(0,-3)}+${(inventor.end_km).slice(-3)}`)
                                    }</Text>
                                    </View>
                                </View> 
                            )
                        })
                    }      
                </View>
        }
        </>
    )
}

const InventoryPdf = React.memo(({inventory, inventoryItem, project_id, inventoryName, files}) => {
    const verificado = inventory.filter(item => item.check_status === 'Verificado');
    const info = getInfoPackage()
    return (
        <Document>
            <Page size='A4' orientation="landscape" style={styles.body}>
                <View style={styles.mb_5} fixed>
                    <View style={styles.Head}>
                        <View style={styles.logo}>
                            <Image src={info.img} style={styles.Head_Image}/>
                        </View>

                        <View style={styles.d_flex}>
                            <Text style={{fontWeight: 900}}>{info.title}</Text>
                            <Text style={styles.text_center}>GESTIÓN DE ACTIVOS </Text>
                            <Text style={styles.text_center}>CM-{info.code}-MTTO SGA</Text>
                            <Text style={styles.fz_12}>{inventoryItem.inventory}</Text>
                        </View>

                        <View style={styles.logo1}>
                            <Text style={styles.text_center}>Autopista {info.highway}</Text>
                            <Text style={styles.text_center}>CÓDIGO:  {inventoryItem.code}</Text>
                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                <Text style={styles.text_center50}>VERSIÓN: {inventoryItem.version}</Text>
                                <Text style={styles.text_center50}>FECHA DE EMISIÓN: { moment().format("D/MM/YY") }</Text>
                            </View>
                        </View>
                    </View>
                </View>
                
                {
                    (verificado.length > 0) ? (<DataTable inventoryName={inventoryName} inventory={verificado} title="Puntos verificados" />) : (
                        <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}>No hay registros de puntos verificados</Text>
                    )
                }
                
                <View style={styles.cuad}>
                    {
                        (files && (files.length>0)) ? files.map((element, i) => 
                            <View key={i} style={{ margin: 5 }} >
                                <Image src={`${__SERVER__}/projects/${project_id}/${inventoryName}_files/${element.id}/download?file_path=${element.foto}`} style={styles.Head_Image}/>
                                <Text style={{ fontSize: 11, margin: 2 }}>Imagen {i+1} - Activo {element.id}</Text>
                            </View>
                        ) : <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}>No hay fotos</Text>
                    }
                </View>

                <View style={{display: "flex", flexDirection: "row", marginTop: 100, fontSize: 12,justifyContent: "space-around"}} >
                    <View>
                        <Text>FIRMA DEL MRO (OPERADOR)</Text>
                    </View>
                    <View>
                        <Text>FIRMA DE LA SUPERVISIÓN (AAS)</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
})

export default InventoryPdf