import { Heading } from '@chakra-ui/react'
import { Col, Row, Skeleton } from 'antd'
import React from 'react'

const UsersList = (props) => {
    const { loadingUsers, users, userSel, onClickUser } = props;
    return (
        <Col xs={24} sm={24} md={6} lg={6} className='users-panel'>
            <Row className='users-panel-title'>
                <Row className='users-panel-title-row'>
                    <Col className='users-panel-title-h' xs={24}>
                        <Heading size='md'>Usuarios</Heading>
                    </Col>
                </Row>
            </Row>
            <div className='users-list'>
                {
                    loadingUsers.isOpen ? <Skeleton active /> :
                        users.map((item) =>
                            <Row key={item.id} className={`users-list-row ${item.id === userSel?.id ? 'selected' : ''}`}>
                                <Col className='users-list-col' onClick={() => onClickUser(item)}>
                                    {item.first_name} {item.last_name}
                                </Col>
                            </Row>)
                }
            </div>
        </Col>
    )
}

export default UsersList