import fetcherCancelToken from "components/libs/fetcherCancelToken";

export const downloadRoadAidPdf = async ({ road_aid_id, package_id }) => {
    const url = `/reports/pdf/road_aid/${road_aid_id}`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: 'arraybuffer',
            params: { package_id }
        });
        // console.log("🚀 ~ file: reports.js:17 ~ downloadRoadAidPdf ~ fetch:", fetch)

        return { response: fetch, cancel };
    } catch (error) {
        return response;
    }
}