import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal';
import FormIcon from 'components/layouts/FormIcon';
import MaterialsTable from './ModalWorkOrders/MaterialsTable';
import HeaderWorkOrder from './ModalWorkOrders/HeaderWorkOrder';
import WorkOrderReports from './ModalWorkOrders/WorkOrderReports';
import ListWorkOrder from './ModalWorkOrders/ListWorkOrder';

import { Col, Form, Row } from 'react-bootstrap';
import { faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { updateMaintenanceProgramFlow } from 'api/maintenance_standards/maintenance_program_flow';
import {
    deleteWorkOrders,
    indexWorkOrders,
    indexWorkOrdersReport,
    storeWorkOrderSupplies,
    updatePumCostWorkOrders,
    updateWorkOrders
} from 'api/maintenance_standards/work_orders';
import { Alert, Skeleton } from 'antd';
import { indexCostWorkOrders } from 'api/maintenance_standards/cost_work_order';
import { indexPumActivities } from 'api/maintenance_standards/pum_activities';

const ModalWorkOrders = (props) => {
    const {
        onClose,
        loading,
        dataDetails,
        package_id,
        segment_id,
        standard_id,
        project_id,
        newOrder,
        updateStatusCalendar
    } = props;

    const typeFile = { HTML: 'HTML', PDF: 'PDF', XLSX: 'EXCEL', TXT: 'TEXTO' };

    const [workOrders, setWorkOrders] = useState([]);
    const [workOrderSel, setWorkOrderSel] = useState(-1);
    const [workOrderItem, setWorkOrderItem] = useState(null);
    const [fileType, setFileType] = useState('HTML');
    const [loadingTools, setLoadingTools] = useState(true);
    const [loadingWorkOrders, setLoadingWorkOrders] = useState(true);

    const [tools, setTools] = useState([]);
    const [toolsValues, setToolsValues] = useState([]);
    const [toolsObservations, setToolsObservations] = useState([]);

    const [materials, setMaterials] = useState([]);
    const [materialsValues, setMaterialsValues] = useState([]);
    const [materialsObservations, setMaterialsObservations] = useState([]);

    const [equipments, setEquipments] = useState([]);
    const [equipmentsValues, setEquipmentsValues] = useState([]);
    const [equipmentsObservations, setEquipmentsObservations] = useState([]);

    const [workforce, setWorkforce] = useState([]);
    const [workforceValues, setWorkforceValues] = useState([]);
    const [workforceObservations, setWorkforceObservations] = useState([]);

    const [toolsTotal, setToolsTotal] = useState(0);
    const [equipmentsTotal, setEquipmentsTotal] = useState(0);
    const [materialsTotal, setMaterialsTotal] = useState(0);
    const [workforceTotal, setWorkforceTotal] = useState(0);

    const [report, setReport] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [costWorkOrders, setCostWorkOrders] = useState([]);
    const [pumActivities, setPumActivities] = useState([]);
    const [pumActivitySel, setPumActivitySel] = useState('-1');
    const [pumActivityCount, setPumActivityCount] = useState(1);

    const [additionalCharges, setAdditionalCharges] = useState([]);

    // Llenar con los datos predeterminados, los costos y cantidades también cambian según el input numérico
    useEffect(() => {
        const fillTools = () => {
            const pumActivity = pumActivities.find((item) => item.id == pumActivitySel);
            const supplies = pumActivity.supplies
            getToolValues(supplies);
            getAdditionalCharges(pumActivity);
        }
        if (pumActivitySel !== '-1') fillTools()
    }, [pumActivitySel, costWorkOrders, pumActivityCount]);
    // Calcular el costo total de los materiales
    useEffect(() => {
        setMaterialsTotal(materialsValues
            .map((item, index) => {
                const cost = materials[index].supplie.cost;
                return { count: item, cost }
            })
            .reduce((total, item) => total + item.count * item.cost, 0));
    }, [materialsValues]);
    // Calcular el costo total de las herramientas
    useEffect(() => {
        setToolsTotal(toolsValues
            .map((item, index) => {
                const cost = tools[index].supplie.cost;
                return { count: item, cost }
            })
            .reduce((total, item) => total + item.count * item.cost, 0));
    }, [toolsValues]);
    // Calcular el costo total del equipo
    useEffect(() => {
        setEquipmentsTotal(equipmentsValues
            .map((item, index) => {
                const cost = equipments[index].supplie.cost;
                return { count: item, cost }
            })
            .reduce((total, item) => total + item.count * item.cost, 0));
    }, [equipmentsValues]);
    // Calcular el costo total de los materiales
    useEffect(() => {
        setWorkforceTotal(workforceValues
            .map((item, index) => {
                const cost = workforce[index].supplie.cost;
                return { count: item, cost }
            })
            .reduce((total, item) => total + item.count * item.cost, 0));
    }, [workforceValues]);
    // Obtener configuraciones de la actividad
    useEffect(() => {
        const getPumActivities = async () => {
            const { response } = await indexPumActivities({ activity_id: dataDetails.activity_id });
            setPumActivities(response.data);
        }
        getPumActivities();
    }, [workOrderSel]);
    // Obtener órdenes de trabajo asignadas
    useEffect(() => {
        const getWorkOrders = async () => {
            setLoadingWorkOrders(true);
            const { id } = dataDetails;
            const { response } = await indexWorkOrders({ package_id, segment_id, standard_id, incident_id: id, is_incident: 0 });
            setWorkOrders(response.data);
            setLoadingWorkOrders(false);
        }
        if (!loading) getWorkOrders();
    }, [loading]);
    // Mostrar vista en base al estado de la órden de trabajo
    useEffect(() => {
        if (workOrderSel !== -1 && workOrderItem) {
            if (!workOrderItem.status) {
                getTools();
            } else {
                setFileType('HTML');
                getReport();
            }
        }
    }, [workOrderItem, workOrderSel]);

    // Obtener los cargos adicionales
    const getAdditionalCharges = (pumActivity) => {
        const { indirect_office_costs, indirect_field_costs, financing_costs, utility_costs,
            additional_charges, other_percentages, total_cost } = pumActivity;
        const direct_costs = pumActivity.direct_costs * pumActivityCount;
        const v_indirect_office_costs = getPercentage(indirect_office_costs, direct_costs);
        const v_indirect_field_costs = getPercentage(indirect_field_costs, direct_costs);
        const v_financing_costs = getPercentage(financing_costs, direct_costs);
        const v_utility_costs = getPercentage(utility_costs, direct_costs);
        const v_additional_charges = getPercentage(additional_charges, direct_costs);
        const v_other_percentages = getPercentage(other_percentages, direct_costs);

        const v_total_indirect = v_indirect_office_costs + v_indirect_field_costs + v_financing_costs +
            v_utility_costs + v_additional_charges + v_other_percentages;

        setAdditionalCharges([
            { id: 'indirect_office_costs', name: 'Indirectos de oficina', value: v_indirect_office_costs },
            { id: 'indirect_field_costs', name: 'Indirectos de campo', value: v_indirect_field_costs },
            { id: 'financing_costs', name: 'Financiamiento', value: v_financing_costs },
            { id: 'utility_costs', name: 'Ulilidad', value: v_utility_costs },
            { id: 'additional_charges', name: 'Cargos adicionales', value: v_additional_charges },
            { id: 'other_percentages', name: 'Otros porcentajes', value: v_other_percentages },
            { id: 'indirect_total_cost', name: 'Total sobrecostos', value: v_total_indirect },
            { id: 'total_cost', name: 'Costo Total', value: total_cost * pumActivityCount },
        ]);
    }

    const getPercentage = (value, direct_costs) => {
        return (value / 100) * direct_costs
    }
    // Restaurar a 0 valores de las matrices
    const restoreValues = () => {
        setMaterialsValues(new Array(materials.length).fill(0));
        setToolsValues(new Array(tools.length).fill(0));
        setEquipmentsValues(new Array(equipments.length).fill(0));
        setWorkforceValues(new Array(workforce.length).fill(0));
    }
    // Agregar clasificación a los suministros
    const addSupplyTypes = (supplies) => {
        return supplies.map((element) => {
            const supply = costWorkOrders.find((cwo) => cwo.supplie.id === element.supply_id)
            const supply_type_id = supply.supplie.type.id;
            return { ...element, supply_type_id }
        })
    }
    // Mostrar valores predefinidos
    const showValues = (suppliesTools) => {
        for (const supply of suppliesTools) {
            switch (supply.supply_type_id) {
                case 1:
                    const index1 = materials.findIndex((item) => item.supplie.id === supply.supply_id)
                    setMaterialsValues(prevState => prevState.map((item, i) => {
                        if (i === index1) return supply.count * pumActivityCount;
                        return item;
                    }));
                    break;
                case 2:
                    const index2 = workforce.findIndex((item) => item.supplie.id === supply.supply_id)
                    setWorkforceValues(prevState => prevState.map((item, i) => {
                        if (i === index2) return supply.count * pumActivityCount;
                        return item;
                    }));
                    break;
                case 3:
                    const index3 = tools.findIndex((item) => item.supplie.id === supply.supply_id)
                    setToolsValues(prevState => prevState.map((item, i) => {
                        if (i === index3) return supply.count * pumActivityCount;
                        return item;
                    }));
                    break;
                case 4:
                    const index4 = equipments.findIndex((item) => item.supplie.id === supply.supply_id)
                    setEquipmentsValues(prevState => prevState.map((item, i) => {
                        if (i === index4) return supply.count * pumActivityCount;
                        return item;
                    }));
                    break;
                default: break;
            }
        }
    }
    // Obtener valores predetermiandos y llenar los campos
    const getToolValues = (supplies) => {
        // Obtener clasificación de los suministros
        const suppliesTools = addSupplyTypes(supplies);
        // Restaurar valores
        restoreValues();
        // Llenar con valores predefinidos
        showValues(suppliesTools);
        setLoadingTools(costWorkOrders.length === 0 ? null : false);
    }
    // Obtener Materiales, Mano de obra, Herramientas y Maquinaria
    const getTools = async () => {
        setLoadingTools(true);
        const { response } = await indexCostWorkOrders({ package_id, standard_id, segment_id });
        setCostWorkOrders(response.data);
        if (response) {
            const data = response.data.map(item => {
                return {
                    ...item,
                    remaining: {
                        id: item.supplie.id,
                        code: item.supplie.code,
                        count: item.count,
                    }
                }
            });
            const _materials = data.filter(item => item.supplie.type.id === 1);
            const _workforce = data.filter(item => item.supplie.type.id === 2);
            const _tools = data.filter(item => item.supplie.type.id === 3);
            const _equipments = data.filter(item => item.supplie.type.id === 4);

            setTools(_tools);
            setToolsObservations(new Array(_tools.length).fill(null));
            setToolsValues(new Array(_tools.length).fill(0));

            setEquipments(_equipments);
            setEquipmentsObservations(new Array(_equipments.length).fill(null));
            setEquipmentsValues(new Array(_equipments.length).fill(0));

            setMaterials(_materials);
            setMaterialsObservations(new Array(_materials.length).fill(null));
            setMaterialsValues(new Array(_materials.length).fill(0));

            setWorkforce(_workforce);
            setWorkforceObservations(new Array(_workforce.length).fill(null));
            setWorkforceValues(new Array(_workforce.length).fill(0));

            setLoadingTools(data.length === 0 ? null : false);
        } else {
            setLoadingTools(null);
        }
    }
    // Obtener datos para la creación del reporte
    const getReport = async () => {
        setReport(null);
        setLoadingTools(true);
        const work_order_id = workOrders[workOrderSel].id;
        const { response } = await indexWorkOrdersReport({ package_id, segment_id, standard_id, work_order_id, is_incident: 0 });
        if (response) {
            setReport(response);
            setLoadingTools(false);
        } else {
            setLoadingTools(false);
            Swal.fire('Error!', 'Error al obtener datos para el reporte', 'error');
        }
    }
    // Función para ajustar el decimal
    const decimalAdjust = (type, value, exp) => {
        // Si el exp no está definido o es cero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }
    // Calcular el costo de trabajo y costo directo
    const directCost_CostWork = (array, arrayValues, arrayObservations) => {
        let direct_cost = [];
        let cost_work = [];

        for (let i = 0; i < array.length; i++) {
            let item = array[i];
            let itemV = parseFloat(arrayValues[i]);
            itemV = Number.isNaN(itemV) ? 0 : itemV;
            if (itemV !== 0) {
                cost_work.push({
                    id: item.remaining.id,
                    count: item.remaining.count - itemV
                });
                direct_cost.push({
                    supplie_code: item.supplie.code,
                    count: itemV,
                    amount: decimalAdjust('round', item.supplie.cost * itemV, -2),
                    observations: arrayObservations[i]
                });
            }
        }

        return { direct_cost, cost_work };
    }
    // Guardar Órden de Trabajo
    const saveData = async ({ direct_cost, cost_work }) => {
        setDisabled(true);
        const work_order_id = workOrders[workOrderSel].id;

        const fetch = await storeWorkOrderSupplies({ package_id, segment_id, standard_id, work_order_id, data: direct_cost });

        if (fetch.response) {
            const fetchUPCWO = await updatePumCostWorkOrders({ package_id, data: { cost_work } });
            if (fetchUPCWO.response) {
                // Agregar costos adicionales y cambiar estado de la orden de trabajo
                const additional = getAdditionalData();
                const fetchUWO = await updateWorkOrders({ package_id, segment_id, standard_id, work_order_id, data: { status: 1, ...additional } });
                if (fetchUWO.response) {
                    setWorkOrders(prevState => prevState.map((item, index) => {
                        if (index === workOrderSel) item.status = 1;
                        return item;
                    }));
                    // Actualizar a ASIGNADO
                    updateMaintenanceProgramFlow({ project_id, id: dataDetails.id, data: { status: 'ASIGNADO' } });
                    updateStatusCalendar();
                    Swal.fire('Registro correcto', 'La orden se termino de generar correctamente', 'success');
                    setWorkOrderItem({ ...workOrderItem, status: 1 });  // Actualizar el status del seleccionado para obtener datos del reporte
                } else {
                    Swal.fire('Error', fetchUWO.error, 'error');
                }
                setDisabled(false);
            } else {
                Swal.fire('Error', fetchUPCWO.error, 'error');
                setDisabled(false);
            }
        } else {
            Swal.fire('Error', fetch.error, 'error');
            setDisabled(false);
        }
    }
    // Obtener datos adicionales para guardarlos en la orden de trabajo
    const getAdditionalData = () => {
        let additional = {};
        if (pumActivitySel !== '-1') {
            const pumActivity = pumActivities.find((item) => item.id == pumActivitySel);
            const { direct_costs, indirect_office_costs, indirect_field_costs, financing_costs, utility_costs,
                additional_charges, other_percentages, total_cost } = pumActivity;
            additional = {
                direct_costs, indirect_office_costs, indirect_field_costs, financing_costs, utility_costs,
                additional_charges, other_percentages, total_cost
            };
        }
        return additional
    }
    // Confirmación para guardar datos de Órden de Trabajo
    const saveWorkOrder = async () => {
        let direct_cost = [];
        let cost_work = [];

        const dc_tools = directCost_CostWork(tools, toolsValues, toolsObservations);
        direct_cost.push(...dc_tools.direct_cost);
        cost_work.push(...dc_tools.cost_work);
        const dc_materials = directCost_CostWork(materials, materialsValues, materialsObservations);
        direct_cost.push(...dc_materials.direct_cost);
        cost_work.push(...dc_materials.cost_work);
        const dc_equipments = directCost_CostWork(equipments, equipmentsValues, equipmentsObservations);
        direct_cost.push(...dc_equipments.direct_cost);
        cost_work.push(...dc_equipments.cost_work);
        const dc_workforce = directCost_CostWork(workforce, workforceValues, workforceObservations);
        direct_cost.push(...dc_workforce.direct_cost);
        cost_work.push(...dc_workforce.cost_work);

        if (direct_cost.length === 0) {
            Swal.fire('Advertencia', 'No a seleccionado ninguna unidad de Material, Equipo, Herramienta o Mano de Obra.', 'warning');
        } else {
            Swal.fire({
                title: '¿Finalizar Orden de Trabajo?',
                text: `El costo directo de la Orden asciende a $ ${decimalAdjust('round', (equipmentsTotal + toolsTotal + materialsTotal + workforceTotal), -2)}, una vez finalizada ya no se pueden hacer cambios`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, ¡Continuar!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.value) {
                    saveData({ direct_cost, cost_work });
                }
            })
        }
    }
    // Eliminar Órden de Trabajo
    const removeData = async () => {
        setDisabled(true);
        const work_order_id = workOrders[workOrderSel].id;
        const { response, error } = await deleteWorkOrders({ package_id, segment_id, standard_id, work_order_id });
        if (response) {
            workOrders.splice(workOrderSel, 1);
            if (workOrders.length > 0) {
                if (workOrders[0].status === false) {
                    getTools();
                }
            }
            Swal.fire('Información', 'Borrado correctamente', 'success');

            setDisabled(false);
        } else {
            setDisabled(false);
            Swal.fire('Error', error, 'danger');
        }

    }
    // Confirmación para eliminar Órden de Trabajo
    const deleteWorkOrder = async () => {
        Swal.fire({
            title: `¿Desea eliminar la orden de Trabajo: ${workOrders[workOrderSel].code}?`,
            text: 'Esta acción eliminará la orden y es irreversible.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, ¡Continuar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                removeData();
            }
        })
    }
    // Agregar nueva orden de trabajo
    const addWorkOrder = () => newOrder();
    // Evento al seleccionar una orden de trabajo
    const onClickWorkOrder = (i, item) => {
        setWorkOrderSel(i);
        setWorkOrderItem(item);
    }
    // Elegir alguna de las configuraciones con datos por default según la unidad de medida
    const handleChangePumActivity = (event) => setPumActivitySel(event.target.value)
    // Ajustar cantidades de las configuraciones seleccionadas "pum_activities_supplies"
    const changePumActivityCount = (event) => setPumActivityCount(event.target.value)

    return (
        <ModalComp
            onClose={onClose}
            title='Órdenes de trabajo'
            size='xl'
            body={<>
                <section className="containerWorkOrder">
                    <ListWorkOrder
                        addWorkOrder={addWorkOrder}
                        onClickWorkOrder={onClickWorkOrder}
                        workOrders={workOrders}
                        workOrderSel={workOrderSel}
                        loadingWorkOrders={loadingWorkOrders}
                    />
                    <section className="suppliesWorkOrder">
                        {
                            workOrderSel !== -1 && <HeaderWorkOrder
                                workOrders={workOrders}
                                workOrderSel={workOrderSel}
                            />
                        }
                        {
                            workOrderSel === -1 &&
                            <Alert
                                message="Ayuda!"
                                description="Debe seleccionar una Orden de trabajo"
                                type="info"
                                showIcon
                            />
                        }
                        {
                            workOrderSel !== -1 && loadingTools === null &&
                            <Alert
                                message="Sin información!"
                                description="La información no esta disponible"
                                type="error"
                                showIcon
                            />
                        }
                        {
                            workOrderSel !== -1 && loadingTools && <>
                                <Alert
                                    message="Obteniendo datos"
                                    type="info"
                                    showIcon
                                />
                                <Skeleton active />
                            </>
                        }
                        {
                            workOrderSel !== -1 && workOrders[workOrderSel].status === false && !loadingTools ?
                                <>
                                    {
                                        pumActivities.length > 0 ? <>
                                            <Row>
                                                <FormIcon
                                                    title='Cantidad'
                                                    type='number'
                                                    defaultValue={pumActivityCount}
                                                    value={pumActivityCount}
                                                    handleChange={changePumActivityCount}
                                                />
                                                <Form.Group as={Col} className='col-8'>
                                                    <Form.Label>Configuraciones</Form.Label>
                                                    <select className='form-control' onChange={handleChangePumActivity} value={pumActivitySel}>
                                                        <option value={-1}>--SELECCIONAR OPCIÓN</option>
                                                        {
                                                            pumActivities.map((item) =>
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </Form.Group>
                                            </Row>
                                        </> : <Alert
                                            message="Sin configurariones"
                                            description="Asigné la cantidad de suministros necesarios para realizar dicha actividad"
                                            type="info"
                                            showIcon
                                        />
                                    }
                                    <MaterialsTable
                                        columns={8}
                                        long_description={15}
                                        decimalAdjust={decimalAdjust}
                                        materials={materials}
                                        materialsValues={materialsValues}
                                        setMaterialsValues={setMaterialsValues}
                                        materialsTotal={materialsTotal}
                                        setMaterialsTotal={setMaterialsTotal}
                                        materialsObservations={materialsObservations}
                                        setMaterialsObservations={setMaterialsObservations}
                                        tools={tools}
                                        toolsValues={toolsValues}
                                        setToolsValues={setToolsValues}
                                        toolsTotal={toolsTotal}
                                        setToolsTotal={setToolsTotal}
                                        toolsObservations={toolsObservations}
                                        setToolsObservations={setToolsObservations}
                                        equipments={equipments}
                                        equipmentsValues={equipmentsValues}
                                        setEquipmentsValues={setEquipmentsValues}
                                        equipmentsTotal={equipmentsTotal}
                                        setEquipmentsTotal={setEquipmentsTotal}
                                        equipmentsObservations={equipmentsObservations}
                                        setEquipmentsObservations={setEquipmentsObservations}
                                        workforce={workforce}
                                        workforceValues={workforceValues}
                                        setWorkforceValues={setWorkforceValues}
                                        workforceTotal={workforceTotal}
                                        setWorkforceTotal={setWorkforceTotal}
                                        workforceObservations={workforceObservations}
                                        setWorkforceObservations={setWorkforceObservations}
                                        additionalCharges={additionalCharges}
                                    />
                                </> : report &&
                                <WorkOrderReports
                                    report={report}
                                    fileType={fileType}
                                    typeFile={typeFile}
                                />
                        }
                    </section>
                </section>
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' tooltipDisabled={true} />
                {
                    workOrderSel !== -1 && workOrders[workOrderSel].status === false &&
                    <div>
                        <ButtonIcon name='Eliminar Órden de Trabajo' icon={faTrashAlt} variant='danger'
                            onClick={deleteWorkOrder} tooltipDisabled={true} disabled={disabled} />
                        <ButtonIcon name='Finalizar Órden de Trabajo' icon={faSave} variant='success'
                            onClick={saveWorkOrder} tooltipDisabled={true} disabled={disabled} />
                    </div>
                }
            </>}
        />
    )
}

export default ModalWorkOrders