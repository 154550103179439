import {
    UPDATE_PROJECTS, UPDATE_PROJECT, UPDATE_MODULES, UPDATE_STM, STAGE_CHOOSED,
    STANDARD_O_CHOOSED, UPDATE_STO, MODULE_CHOOSED, UPDATE_COMPONENT, STANDARD_M_CHOOSED, UPDATE_STAGES
} from '../../actions/actionTypes';
import { stateProyectos as initialState } from '../../initialState/InitialState';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROJECTS:
            return {
                ...state,
                projects: action.projects
            };
        case UPDATE_PROJECT:
            return {
                ...state,
                project_id: action.project_id
            }
        case UPDATE_MODULES:
            return {
                ...state,
                modules: action.modules
            }
        case UPDATE_STAGES:
            return {
                ...state,
                stages: action.stages
            }
        case UPDATE_STO:
            return {
                ...state,
                standardsO: action.standardsO
            }
        case UPDATE_STM:
            return {
                ...state,
                standardsM: action.standardsM
            }
        case STAGE_CHOOSED:
            return {
                ...state,
                stageChoosed: action.stage_id
            }
        case STANDARD_O_CHOOSED:
            return {
                ...state,
                standardOChoosed: action.standardOChoosed
            }
        case STANDARD_M_CHOOSED:
            return {
                ...state,
                standardMChoosed: action.standardMChoosed
            }
        case MODULE_CHOOSED:
            return {
                ...state,
                moduleChoosed: action.moduleChoosed
            }
        case UPDATE_COMPONENT:
            return {
                ...state,
                componentActive: action.componentActive
            }
        default:
            return state;
    }
};

export default reducer;