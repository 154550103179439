import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import './UOV-0/Resultados.css';
import { Pagination } from 'react-bootstrap';


export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pagination: [],
			refresh: false,
		}
	}

	componentDidMount = () => {
		if (this.props.pages < 6) {
			for (let i = 1; i <= this.props.pages; i++) {
				this.state.pagination.push(
					<Pagination.Item key={i} active={i === this.props.page} onClick={() => {
						if (i !== this.props.page) {
							this.props.updatePage(i);
						}
					}}>
						{i}
					</Pagination.Item>);
			}
		} else {
			this.state.pagination.push(
				<Pagination.First onClick={() => {
					this.props.updatePage(1);
				}} disabled={this.props.page === 1} />
			);
			this.state.pagination.push(
				<Pagination.Prev onClick={() => {
					this.props.updatePage(this.props.page - 1);
				}} disabled={this.props.page === 1} />
			);
			this.state.pagination.push(
				<Pagination.Item onClick={() => {
					if (this.props.page !== 1) {
						this.props.updatePage(1);
					}
				}} active={this.props.page === 1} >
					1
				</Pagination.Item>
			);
			if (this.props.page === 1) {
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(2);
					}}>
						2
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(3);
					}}>
						3
					</Pagination.Item>
				);
			}
			if (this.props.page > 3) {
				this.state.pagination.push(
					<Pagination.Ellipsis />
				);
			}


			if (this.props.page === 2) {
				this.state.pagination.push(
					<Pagination.Item active >
						2
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(3);
					}}>
						3
					</Pagination.Item>
				);
			}

			if (this.props.page === 3) {
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(2);
					}}>
						2
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item active >
						3
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(4);
					}}>
						4
					</Pagination.Item>
				);
			}

			if (this.props.page > 3 && this.props.page < this.props.pages - 2) {
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(this.props.page - 1);
					}}>
						{this.props.page - 1}
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item active >
						{this.props.page}
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(this.props.page + 1);
					}}>
						{this.props.page + 1}
					</Pagination.Item>
				);
			}



			if (this.props.page === this.props.pages - 2) {
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(this.props.pages - 3);
					}}>
						{this.props.pages - 3}
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item active >
						{this.props.pages - 2}
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(this.props.pages - 1);
					}}>
						{this.props.pages - 1}
					</Pagination.Item>
				);
			}

			if (this.props.page === this.props.pages - 1) {
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(this.props.pages - 2);
					}}>
						{this.props.pages - 2}
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item active >
						{this.props.pages - 1}
					</Pagination.Item>
				);
			}

			if (this.props.page === this.props.pages) {
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(this.props.pages - 2);
					}}>
						{this.props.pages - 2}
					</Pagination.Item>
				);
				this.state.pagination.push(
					<Pagination.Item onClick={() => {
						this.props.updatePage(this.props.pages - 1);
					}}>
						{this.props.pages - 1}
					</Pagination.Item>
				);
			}


			if (this.props.page <= this.props.pages - 3) {
				this.state.pagination.push(
					<Pagination.Ellipsis />
				);
			}

			this.state.pagination.push(
				<Pagination.Item onClick={() => {
					if (this.props.page !== this.props.pages) {
						this.props.updatePage(this.props.pages);
					}
				}} active={this.props.page === this.props.pages} >
					{this.props.pages}
				</Pagination.Item>
			);
			this.state.pagination.push(
				<Pagination.Next onClick={() => {
					this.props.updatePage(this.props.page + 1);
				}} disabled={this.props.page === this.props.pages} />
			);
			this.state.pagination.push(
				<Pagination.Last onClick={() => {
					this.props.updatePage(this.props.pages);
				}} disabled={this.props.page === this.props.pages} />
			);

		}
		this.setState({ refresh: !this.state.refresh });
	}

	render() {
		return (
            <div>
                {this.props.pages !== 1 ?
                    <div className="pagination">
                        <Pagination>
                            {this.state.pagination}
                        </Pagination>
                    </div>
                : null}
            </div>
		);
	}
}