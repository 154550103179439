import React, { useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { indexCostWorkOrdersWithPag } from 'api/maintenance_standards/cost_work_order';
import TableComp from 'components/layouts/Table';
import PaginationComp from 'components/layouts/Pagination';
import FlexButton from 'components/layouts/FlexButton';
import AddCostWorkOrder from './AddCostWorkOrder';

const CostWorkOrdersComp = (props) => {
    const {
        package_id,
        standard_id,
        type_description_id
    } = props;

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);

    const [modalAdd, setModalAdd] = useState(false);

    useEffect(() => {
        getData();
    }, [page, pageSize]);

    const getData = async () => {
        setLoading(true);
        const { response } = await indexCostWorkOrdersWithPag({
            package_id,
            standard_id,
            type_description_id,
            page,
            pageSize
        });
        if (response) {
            setData(response.data);
            setTotalItems(response.totalItems);
        }
        setLoading(false);
    }

    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    return (
        <>
            <TableComp
                headers={['#', 'Código', 'Descripción', 'Segmento', 'Unidad', 'Disponible', 'PU', 'Creado en', 'Acciones']}
                keys={[
                    '#',
                    'supplie.code',
                    'supplie.description',
                    'segment.name',
                    'supplie.unit',
                    'count',
                    'supplie.cost',
                    'created_at'
                ]}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faEdit, variant: 'outline-primary', handleClick: () => { }, tooltip: 'Editar registro', disabled: true }
                ]}
                currency={[{ index: 6 }]}
            />
            {/* Paginación */}
            <PaginationComp
                totalItems={totalItems}
                defaultPageSize={pageSize}
                current={page}
                onChange={changePage}
            />
            {/* Agregar registro */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalAdd(true)} />

            {/* Modal para nuevo registro */}
            {
                modalAdd && <AddCostWorkOrder
                    onClose={() => setModalAdd(false)}
                    package_id={package_id}
                    type_description_id={type_description_id}
                    standard_id={standard_id}
                    syncServer={getData}
                />
            }
        </>
    )
}

export default CostWorkOrdersComp