import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { gantt } from 'dhtmlx-gantt/codebase/dhtmlxgantt.js';
import './Gantt.css';

const moment = require('moment');

const GanttChart = ({ data, links, zoom, fullscreen }) => {
    const ganttContainerRef = useRef(null);

    useEffect(() => {
        gantt.config.grid_resize = true;
        gantt.config.resize_rows = true;
        gantt.config.resize_cols = true;

        gantt.config.reorder_grid_columns = true;
        gantt.config.columns = [
            { name: "id", align: "center", label: "ID", min_width: 60, width: 60, resize: true },
            { name: "text", tree: true, min_width: 250, width: 300, resize: true },
            { name: "start_date", align: "center", width: 100, resize: true },
            { name: "duration", align: "center", width: 80, resize: true }
        ];

        gantt.config.layout = {
            css: "gantt_container",
            cols: [
                {
                    width: 400,
                    min_width: 300,
                    rows: [
                        { view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer" },
                        { view: "scrollbar", id: "gridScroll", group: "horizontal" }
                    ]
                },
                { resizer: true, width: 1 },
                {
                    rows: [
                        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
                        { view: "scrollbar", id: "scrollHor", group: "horizontal" }
                    ]
                },
                { view: "scrollbar", id: "scrollVer" }
            ]
        };
        gantt.config.date_format = "%Y-%m-%d %H:%i";
        gantt.i18n.setLocale("es");
        gantt.plugins({ fullscreen: true, marker: true });
        const today = new Date();
        gantt.addMarker({
            start_date: today,
            css: "today",
            text: "Día actual",
            title: "Hoy: " + moment().format('LLLL')
        });
        gantt.init(ganttContainerRef.current);
        gantt.parse({ data, links });

        const initZoom = () => {
            gantt.ext.zoom.init({
                levels: [
                    {
                        name: 'Hours',
                        scale_height: 60,
                        min_column_width: 30,
                        scales: [
                            { unit: 'day', step: 1, format: '%d %M %Y' },
                            { unit: 'hour', step: 1, format: '%H' }
                        ]
                    },
                    {
                        name: 'Days',
                        scale_height: 60,
                        min_column_width: 70,
                        scales: [
                            { unit: 'month', step: 1, format: '%F %Y' },
                            { unit: 'day', step: 1, format: '%j, %D' }
                        ]
                    },
                    {
                        name: 'Months',
                        scale_height: 60,
                        min_column_width: 70,
                        scales: [
                            { unit: 'month', step: 1, format: '%F %Y' }
                        ]
                    }
                ]
            });
        };

        const setZoom = (value) => {
            if (!gantt.$initialized) {
                initZoom();
            }
            gantt.ext.zoom.setLevel(value);
        };

        if (zoom) {
            setZoom(zoom);
        }
        if (fullscreen) {
            gantt.expand();
        }

        return () => {
            gantt.clearAll();
        };
    }, [data, links, zoom, fullscreen]);

    return (<div id='custom-gantt' ref={ganttContainerRef}></div>);
};

GanttChart.propTypes = {
    zoom: PropTypes.oneOf(['Hours', 'Days', 'Months']).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            text: PropTypes.string.isRequired,
            start_date: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            parent: PropTypes.number,
            progress: PropTypes.number,
        }).isRequired
    ).isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            source: PropTypes.number.isRequired,
            target: PropTypes.number.isRequired,
            type: PropTypes.oneOf(['0', '1']).isRequired
        }).isRequired
    )
}

export default GanttChart;