import React, { Component } from "react"
import { FaEye, FaCheckDouble, FaMapMarker } from 'react-icons/fa'
import { Button, Card, Col, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap"

import MediaOnline from "./MediaOnline"
import ModalTracing from '../modals/ModalTracing'
import ModalMap from '../modals/ModalMap'

const Grid_ = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            item: null,
            modal_tracing: false,
            item_modal_tracing: [],
            modal_map: false,
            ubications: []
        };
        console.log(this.props.data)
    }

    openOrCloseModalTracing = () => {
        this.setState({ modal_tracing: !this.state.modal_tracing });
    }

    openOrCloseModalMap = () => {
        this.setState({ modal_map: !this.state.modal_map });
    }

    render() {
        console.log("props: ", this.props);
        const statusMessage = ['Creado', 'Atendiendo', 'Resuelta', 'No resuelta', 'Catalogada como falsa'];
        return (
            <div>
                <div className="cards">
                    {
                        this.props.data.map((item) =>
                            <Card style={{ width: '20rem' }} key={item.id} >
                                <Card.Header as="h8"><strong>{item.type.name}</strong></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <strong>Código: </strong>{item.code}<br />
                                        <strong>Fecha de aviso: </strong> {item.report_date.replace('T', ' ').split('.')[0]}<br />
                                        {item.data_id !== null ? <><strong>Nombre: </strong> {item.data.name}<br /></> : null}
                                        {item.data_id !== null ? <><strong>Email: </strong> {item.data.email}<br /></> : null}
                                        {item.data_id !== null ? <><strong>Teléfono: </strong> {item.data.phone}<br /></> : null}
                                        <strong>Altitud: </strong>{item.altitude}<br />
                                        <strong>Longitud: </strong>{item.longitude}<br />
                                        <strong>Latitud: </strong>{item.latitude}<br />
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col><OverlayTrigger placement={"bottom"} overlay={<Tooltip>Más detalles</Tooltip>}>
                                            <Button variant="outline-info" id={item.id} onClick={() => this.setState({ modal: true, item })}>
                                                <FaEye />
                                            </Button>
                                        </OverlayTrigger></Col>
                                        <Col><OverlayTrigger placement={"bottom"} overlay={<Tooltip>Seguimiento</Tooltip>}>
                                            <Button variant="outline-success" id={item.id} onClick={e => {
                                                this.setState({ item_modal_tracing: item });
                                                this.openOrCloseModalTracing();
                                            }}>
                                                <FaCheckDouble />
                                            </Button>
                                        </OverlayTrigger></Col>
                                        <Col><OverlayTrigger placement={"bottom"} overlay={<Tooltip>Seguimiento</Tooltip>}>
                                            <Button variant="outline-danger" id={item.id} onClick={e => {
                                                this.setState({
                                                    ubications: [{
                                                        id: 1,
                                                        title: `${item.type.name}`,
                                                        name: `Código: ${item.code} - Fecha de aviso ${item.report_date.replace('T', ' ').split('.')[0]}`,
                                                        position: {
                                                            lat: item.latitude,
                                                            lng: item.longitude
                                                        }
                                                    }]
                                                });
                                                this.openOrCloseModalMap();
                                            }}>
                                                <FaMapMarker />
                                            </Button>
                                        </OverlayTrigger></Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        )
                    }
                </div>
                <Modal show={this.state.modal} backdrop="static" keyboard={false} onHide={() => this.setState({ modal: false })}>
                    <Modal.Header closeButton>
                        {this.state.item !== null ? <Modal.Title>{this.state.item.type.name}</Modal.Title> : null}
                    </Modal.Header>
                    {this.state.item !== null ?
                        <Modal.Body>
                            <div><strong>Reportado el: </strong> {this.state.item.report_date.replace('T', ' ').split('.')[0]} </div>
                            <div><strong>Código: </strong> {this.state.item.code} </div>
                            <div><strong>Siniestro: </strong> {this.state.item.accident_rate} </div>
                            <div><strong>Número de siniestro: </strong> {this.state.item.claim_number} </div>
                            <div><strong>Status: </strong> {statusMessage[this.state.item.status]} </div>{'\n'}
                            <div><strong>Ubicación</strong></div>
                            <div><strong>Cuerpo: </strong> {this.state.item.body} </div>
                            <div><strong>Localización: </strong> {this.state.item.location} </div>
                            <div><strong>Unidad: </strong> {this.state.item.unit} </div>
                            <div><strong>Latitud: </strong> {this.state.item.latitude} </div>
                            <div><strong>Longitud: </strong> {this.state.item.longitude} </div>
                            <div><strong>Altitud: </strong> {this.state.item.altitude} </div>{'\n'}
                            <div><strong>Notificaciones</strong></div>
                            <div><strong>Hora de intervención: </strong> {this.state.item.intervention_time} </div>
                            <div><strong>Hora de señalización: </strong> {this.state.item.signaling_time} </div>
                            {
                                this.state.item.notification.map((item) =>
                                    <div key={item.id}><strong>{item.entity.name}: </strong> {item.notification_time} </div>
                                )
                            }
                            {'\n'}
                            <div><strong>Tipo de vía: </strong> {this.state.item.type_road} </div>
                            <div><strong>Factores atmosféricos: </strong> {this.state.item.atmospheric_factors} </div>{'\n'}

                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Categoria</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Vehículos</td>
                                        <td>{this.state.item.number_vehicles}</td>
                                    </tr>
                                    <tr>
                                        <td>Ocupantes</td>
                                        <td>{this.state.item.number_occupants}</td>
                                    </tr>
                                    <tr>
                                        <td>Muertos</td>
                                        <td>{this.state.item.body_count}</td>
                                    </tr>
                                    <tr>
                                        <td>Heridos</td>
                                        <td>{this.state.item.number_injured}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <MediaOnline media={this.state.item.media} />
                        </Modal.Body> : null}
                    <Modal.Footer onClick={() => this.setState({ modal: false })}>
                        <Button variant="danger">Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                {
                    this.state.modal_tracing ?
                        <ModalTracing
                            title={"Seguimiento a Incidentes"}
                            item={this.state.item_modal_tracing}
                            onClose={this.openOrCloseModalTracing}
                        /> : null
                }

                {
                    this.state.modal_map ?
                        <ModalMap
                            title={"Ubicación del incidente"}
                            ubications={this.state.ubications}
                            onClose={this.openOrCloseModalMap}
                        /> : null
                }
            </div>
        );
    }
}

export default Grid_;