import { Map, Marker, GoogleApiWrapper, Polygon } from 'google-maps-react';
import React, { Component } from 'react';
import './InformacionGeoreferenciada.css';
import { __SERVER__, google_maps_key } from '../../server/info';
import { Variables } from '../../variables/Variables';
import circulo from '../imagenes-app/circulo.png';
import { indexValuesInventoryData, valuesInventoryData } from 'api/maintenance_standards/inventory_data';
import { indexAssets, valuesAssets } from 'api/operation_standards/assets';
const _ = require('underscore');

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        console.log('id', Variables.project_id)
        this.state = {
            refresh: false,
            information: [
                {
                    value: 'security_elements',
                    label: 'Elementos de Seguridad',
                    query: [
                        { body: '', typeInput: 'select', label: 'Cuerpo' },
                        { name: '', typeInput: 'select', label: 'Nombre' },
                        { type: '', typeInput: 'select', label: 'Tipo' },
                        { location: '', typeInput: 'select', label: 'Ubicación' },
                        { status: '', typeInput: 'select', label: 'Estado' }
                    ]
                },
                {
                    value: 'horizontal_pointing',
                    label: 'Señalamientos horizontales',
                    query: [
                        { body: '', typeInput: 'select', label: 'Cuerpo' },
                        { name: '', typeInput: 'select', label: 'Nombre' },
                        { type: '', typeInput: 'select', label: 'Tipo' },
                        { location: '', typeInput: 'select', label: 'Ubicación' },
                        { status: '', typeInput: 'select', label: 'Estado' }
                    ]
                }, {
                    value: 'defenses_barriers_inventory_data',
                    label: 'Defensas y Barreras',
                    query: [
                        { body: '', typeInput: 'defenses', label: 'Cuerpo' },
                        { side: '', typeInput: 'defenses', label: 'Lado' },
                        { type: '', typeInput: 'defenses', label: 'Tipo' },
                        { damaged: '', typeInput: 'defenses', label: 'Dañado' },
                        { check_status: '', typeInput: 'defenses', label: 'Estado de Verificación' }
                    ]
                }, {
                    value: 'assets_its',
                    label: 'Elementos ITS',
                    query: [
                        { name: '', typeInput: 'its', label: 'Tipo de activo' },
                        { direction: '', typeInput: 'its', label: 'Sentido' },
                        // { instructive: '', typeInput: 'its', label: 'Cuenta con instructivo' },
                        // { vertical_marking: '', typeInput: 'its', label: 'Señalamiento vertical' },
                        // { luminaries: '', typeInput: 'its', label: 'Cuenta con luminarias' },
                        // { paint: '', typeInput: 'its', label: 'Estado de la pintura' },
                        { check_status: '', typeInput: 'its', label: 'Estado de Verificación' },
                    ]
                }, {
                    value: 'assets_edification',
                    label: 'Elementos de Edificación',
                    query: [
                        { name: '', typeInput: 'edification', label: 'Tipo de activo' },
                        { body: '', typeInput: 'edification', label: 'Cuerpo' },
                        { direction: '', typeInput: 'edification', label: 'Sentido' },
                        { damage: '', typeInput: 'edification', label: 'Dañado' },
                        { status: '', typeInput: 'edification', label: 'Estado' },
                        { check_status: '', typeInput: 'edification', label: 'Estado de Verificación' },
                    ]
                }, {
                    value: 'assets_environment',
                    label: 'Elementos de Medio Ambiente',
                    query: [
                        { name: '', typeInput: 'environment', label: 'Tipo de activo' },
                        { direction: '', typeInput: 'environment', label: 'Sentido' },
                        { vertical_marking: '', typeInput: 'environment', label: 'Señalamiento vertical' },
                        { capacity: '', typeInput: 'environment', label: 'Capacidad' },
                        { cleaning: '', typeInput: 'environment', label: 'Estado de limpieza' },
                        { paint: '', typeInput: 'environment', label: 'Estado de la pintura' },
                        { check_status: '', typeInput: 'environment', label: 'Estado de verificación' },
                    ]
                }
            ],
            informationValue: '',
            selects: [], /** {name, label, values[], method()}   */
            polylines: [],
            markers: [],
            informationMap: null,
            zoom: 7,
            center: {
                lat: 25.5987952,
                lng: -99.9626067
            },
            loading: false,
            viewInfo: null
        }
    }

    generateQuery = async () => {

        if (this.state.informationValue === '') {
            return;
        }
        this.setState({ loading: true });
        /**Inicializar los inputs */
        this.state.selects = [];
        let index = _.findIndex(this.state.information, { value: this.state.informationValue });
        let query = this.state.information[index].query;

        for (let i = 0; i < query.length; i++) {
            let keys = Object.keys(query[i]);
            this.state.information[index].query[i][keys[0]] = '';
            let response = [];
            switch (query[i].typeInput) {
                case 'select':
                    await fetch(__SERVER__ + '/projects/' + Variables.project_id + '/' + this.state.informationValue + '/' + keys[0], {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json'
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            return res.json();
                        }
                        return null;
                    }).then((res) => {
                        if (res !== null) {
                            this.state.selects.push({ name: keys[0], label: this.state.information[index].query[i].label, values: res, method: (e) => { this.state.information[index].query[i][keys[0]] = e.target.value; this.setState({ refresh: !this.state.refresh }); } })
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                    break;
                case 'defenses':
                    response = await valuesInventoryData({ table: 'defenses_barriers_inventory', project_id: Variables.project_id, column: keys[0] });
                    this.state.selects.push({ name: keys[0], label: this.state.information[index].query[i].label, values: response, method: (e) => { this.state.information[index].query[i][keys[0]] = e.target.value; this.setState({ refresh: !this.state.refresh }); } });
                    break;

                case 'its':
                    response = await valuesAssets({ project_id: Variables.project_id, column: keys[0], table: 'assets_its' });
                    this.state.selects.push({ name: keys[0], label: this.state.information[index].query[i].label, values: response, method: (e) => { this.state.information[index].query[i][keys[0]] = e.target.value; this.setState({ refresh: !this.state.refresh }); } })
                    break;

                case 'edification':
                    response = await valuesAssets({ project_id: Variables.project_id, column: keys[0], table: 'assets_edification' });
                    this.state.selects.push({ name: keys[0], label: this.state.information[index].query[i].label, values: response, method: (e) => { this.state.information[index].query[i][keys[0]] = e.target.value; this.setState({ refresh: !this.state.refresh }); } })
                    break;
                case 'environment':
                    response = await valuesAssets({ project_id: Variables.project_id, column: keys[0], table: 'assets_environment' });
                    this.state.selects.push({ name: keys[0], label: this.state.information[index].query[i].label, values: response, method: (e) => { this.state.information[index].query[i][keys[0]] = e.target.value; this.setState({ refresh: !this.state.refresh }); } })
                    break;
                default: return;
            }
        }
        this.setState({ loading: false });
        this.setState({ refresh: !this.state.refresh });
    }

    getInformation = async () => {
        this.setState({ loading: true });
        const { project_id } = Variables;
        let index = _.findIndex(this.state.information, { value: this.state.informationValue });
        let query = this.state.information[index].query;
        let temp = [];
        for (const element of query) {
            let key = Object.keys(element)[0];
            let value = Object.values(element)[0];
            temp.push({ key, value });
        }
        query = "";
        let _query = {};
        for (const element of temp) {
            if (element.value !== '') {
                if (query.length === 0) query += '?';
                else query += '&';

                _query[element.key] = element.value;
                query += element.key + '=' + element.value;
            }
        }
        let response = [];
        switch (this.state.informationValue) {
            case 'defenses_barriers_inventory_data':
                response = await indexValuesInventoryData({ project_id, table: 'defenses_barriers_inventory', query: _query });
                // console.log("🚀 ~ file: InformacionGeoreferenciada.js:191 ~ MapContainer ~ getInformation= ~ response", response)
                this.generateMap(response);
                this.setState({ loading: false });
                break;
            case 'assets_its':
                response = await indexAssets({ project_id, table: 'assets_its', query: _query });
                // console.log("🚀 ~ file: InformacionGeoreferenciada.js:196 ~ MapContainer ~ getInformation= ~ response", response)
                this.generateMap(response.data);
                this.setState({ loading: false });
                break;
            case 'assets_edification':
                response = await indexAssets({ project_id, table: 'assets_edification', query: _query });
                // console.log("🚀 ~ file: InformacionGeoreferenciada.js:201 ~ MapContainer ~ getInformation= ~ response", response)
                this.generateMap(response.data);
                this.setState({ loading: false });
                break;
            case 'assets_environment':
                response = await indexAssets({ project_id, table: 'assets_environment', query: _query });
                // console.log("🚀 ~ file: InformacionGeoreferenciada.js:218 ~ MapContainer ~ getInformation= ~ response", response)
                this.generateMap(response.data);
                this.setState({ loading: false });
                break;
            default:
                console.log('Case default');
                fetch(__SERVER__ + '/projects/' + Variables.project_id + '/' + this.state.informationValue + query, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(res => {
                    if (res.status === 200) {
                        return res.json();
                    }
                    return null;
                }).then(res => {
                    if (res !== null) {
                        this.generateMap(res);
                    } else {
                        this.setState({ loading: false });
                    }
                }).catch(e => {
                    console.log(e);
                    this.setState({ loading: false });
                })
                break;
        }

    }

    generateMap = (info) => {
        this.setState({ loading: true });
        this.state.markers = [];
        this.state.polylines = [];
        this.state.zoom = 13;
        let center = false;
        for (const element of info) {
            let item = element;
            let keys = Object.keys(item);
            let found = false;
            let latitude = [];
            let longitude = [];

            for (let j = 0; j < keys.length && !found; j++) {
                switch (keys[j]) {
                    case 'latitude_start': found = true; latitude.push('latitude_start'); latitude.push('latitude_end'); longitude.push('longitude_start'); longitude.push('longitude_end'); break;
                    case 'latitude_s': found = true; latitude.push('latitude_s'); latitude.push('latitude_e'); longitude.push('longitude_s'); longitude.push('longitude_e'); break;
                    case 'latitudeStart': found = true; latitude.push('latitudeStart'); latitude.push('latitudeEnd'); longitude.push('longitudeStart'); longitude.push('longitudeEnd'); break;
                    case 'latitude':
                        if (item[keys[j]]) {
                            found = true; latitude.push('latitude'); longitude.push('longitude');
                        }
                        break;
                    case 'initial_latitude':
                        if (item[keys[j]]) {
                            latitude.push('initial_latitude'); longitude.push('initial_longitude');
                        }
                        break;
                    case 'final_latitude':
                        if (item[keys[j]]) {
                            latitude.push('final_latitude'); longitude.push('final_longitude');
                        }
                        break;
                    default: break;
                }
            }
            if (latitude.length === 1) {
                //Añadir Market
                this.state.markers.push({
                    data: item,
                    lat: item[latitude],
                    lng: item[longitude]
                });

                if (!center) {
                    this.state.center = {
                        lat: item[latitude],
                        lng: item[longitude]
                    }
                    center = true;
                }
                this.setState({ refresh: !this.state.refresh });
            }

            if (latitude.length === 2) {
                //Añadir linea
                if (item[latitude[1]] !== null) {
                    let line = [];
                    for (let k = 0; k < latitude.length; k++) {
                        line.push({
                            lat: item[latitude[k]],
                            lng: item[longitude[k]]
                        });
                    }
                    if (!center) {
                        this.state.center = {
                            lat: item[latitude[0]],
                            lng: item[longitude[0]]
                        }
                        center = true;
                    }
                    this.state.polylines.push({
                        data: item,
                        line: line
                    });
                } else {
                    this.state.markers.push({
                        data: item,
                        lat: item[latitude[0]],
                        lng: item[longitude[0]]
                    });
                    if (!center) {
                        this.state.center = {
                            lat: item[latitude[0]],
                            lng: item[longitude[0]]
                        }
                        center = true;
                    }
                }
                this.setState({ refresh: !this.state.refresh });
            }
        }

        this.setState({ loading: false });
    }

    viewInfoData = (data) => {
        let component = null;
        let keys = Object.keys(data);
        let values = Object.values(data);
        let info = [];
        for (let i = 0; i < values.length; i++) {
            if (values[i] !== null) {
                info.push(<div key={'INFO_' + i} className={'info'}>
                    <label><strong>{keys[i]}: </strong>{values[i]}</label>
                </div>);
            }
        }

        if (info.length !== 0) {
            component = <article className={'viewInfo'}>
                <section>
                    <button onClick={() => this.setState({ viewInfo: null })}>x</button>
                </section>
                {
                    info.map(item => item)
                }
            </article>
        }

        this.state.viewInfo = null;
        this.setState({ viewInfo: component });
    }

    render() {
        return (
            <section className={'mapStyle'}>
                {
                    this.state.viewInfo
                }
                {
                    this.state.loading ?
                        <section className={'loading'}>
                            <label>Cargando...</label>
                        </section>
                        : null
                }
                <article className={'optionInfo'}>
                    <select onChange={(e) => this.setState({ informationValue: e.target.value, viewInfo: null, markers: [], polylines: [] }, this.generateQuery)}>
                        <option key={'OP'} value={""}></option>
                        {
                            this.state.information.map((item, i) => <option key={'OP' + i} value={item.value}>{item.label}</option>)
                        }
                    </select>
                </article>
                <article className={'inputsInfoMap'}>
                    {
                        this.state.informationValue !== '' &&
                            this.state.selects
                              .filter(item => !item.values.errno && !item.values.sql) 
                              .map((item, i) =>
                                    <div key={'SELEC' + i} className={'inputCreated'}>
                                        <label>{item.label}</label>
                                        <select onChange={item.method}>
                                            <option value="">Todos</option>
                                            {
                                                item?.values?.map((it, j) =>
                                                    <option key={'VAL' + j} value={it[item?.name]}>{it[item?.name]}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                            )
                    }
                    {
                        this.state.informationValue !== '' ?
                            <button className={'buttonCreated'} onClick={this.getInformation}>Consultar</button>
                            : null
                    }
                </article>
                <Map google={this.props.google} zoom={this.state.zoom}
                    className={'styleMap'}
                    style={{ width: '90%', height: '90%' }}
                    initialCenter={this.state.center}
                    center={this.state.center}>
                    {
                        this.state.markers.map((item, i) =>
                            <Marker key={'MARKER_' + i}
                                {...this.props}
                                position={{
                                    lat: item.lat,
                                    lng: item.lng
                                }}
                                icon={{
                                    url: circulo,
                                    size: new this.props.google.maps.Size(32, 32),
                                    origin: new this.props.google.maps.Point(0, 0),
                                    anchor: new this.props.google.maps.Point(8, 8),
                                    scaledSize: new this.props.google.maps.Size(16, 16)
                                }}
                                onClick={() => { this.viewInfoData(item.data) }}
                            />
                        )
                    }

                    { 
                        this.state.polylines.map((item, i) =>
                            <Polygon
                                key={'POLYGON_' + i}
                                onMouseover={() => { this.viewInfoData(item.data) }}
                                paths={item.line}
                                strokeColor="#0000FF"
                                strokeOpacity={0.8}
                                strokeWeight={5}
                                fillColor="#0000FF"
                                fillOpacity={0.35}
                            />
                        )
                    }
                </Map>
            </section>
        );
    }
}

const moduleTemp = GoogleApiWrapper({
    apiKey: (google_maps_key)
})(MapContainer);

export default moduleTemp;