import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Jumbotron, Row } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import LoadingDoc from './LoadingDoc';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import AuxVialPdf from './AuxVialPdf';
import Axios from 'axios';
import { __SERVER__ } from 'server/info';
import { Heading } from '@chakra-ui/react';

const AuxVial = React.memo(({ package_id, project_id }) => {
    const [Road, setRoad] = useState(null);
    const [Users, setUsers] = useState(null);
    const [NoticeForm, setNoticeForm] = useState(null);
    const [loadingData, setLoadingData] = useState(true);
    const [reportReady, setReportReady] = useState(false);
    const [ServiceDescription, setServiceDescription] = useState(null);

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setLoadingData(true);
        await getNoticeForm();
        await getServiceDescription();
        await getUsers();
        await road_aid();
        setLoadingData(false);
    }

    const getNoticeForm = async () => {
        let notice_form = await Axios.get(`${__SERVER__}/victum_users/road_aid/notice_form`);
        (notice_form.status === 200) && setNoticeForm(notice_form.data)
    }

    const getServiceDescription = async () => {
        let service_description = await Axios.get(`${__SERVER__}/victum_users/road_aid/service_description`);
        (service_description.sttus === 200) && setServiceDescription(service_description.data)
    }

    const getUsers = async () => {
        let users = await Axios.get(`${__SERVER__}/packages/${package_id}/users`);
        (users.status === 200) && setUsers(users.data)
    }

    const road_aid = async () => {
        let road_aid = await Axios.get(`${__SERVER__}/victum_users/project/${project_id}/road_aid`);
        (road_aid.status === 200) && setRoad(road_aid.data.data)
    }

    const createReport = () => setReportReady(true);

    return (
        <Jumbotron style={{ marginTop: 10, marginLeft: 10 }} >
            <Form>
                <Heading color={'black'}>Reporte Servicio a usuarios - Auxilio Vial</Heading>
                <Row>
                    <Alert variant="info">
                        <Alert.Heading>Información</Alert.Heading>
                        <p> En esta sección se crean los reportes mensuales Servicio a usuarios - Auxilio Vial </p>
                    </Alert>
                </Row>
                <Row>
                    {
                        !loadingData ? <Button onClick={createReport} variant="outline-danger" className="my-2"><FaFilePdf className="mr-1 d-inline-block" /> Generar Informe Servicio a usuarios - Auxilio Vial </Button> : <p className='d-block text-center m-2' >Cargando datos...</p>
                    }
                    {
                        (!loadingData && reportReady) && (
                            <PDFDownloadLink
                                document={
                                    <AuxVialPdf
                                        Road={Road}
                                        Users={Users}
                                        NoticeForm={NoticeForm}
                                        ServiceDescription={ServiceDescription} />}
                                fileName="Informe_Auxilio_Vial.pdf">
                                {
                                    ({ blob, url, loading, error }) =>
                                        loading ? <LoadingDoc /> : (<Button className="mx-1" variant="success" ><FaFilePdf className="mr-1 d-inline-block" />Reporte Listo para descargar</Button>)
                                }
                            </PDFDownloadLink>
                        )
                    }
                    {
                        (!loadingData && reportReady) && (
                            <BlobProvider
                                document={<AuxVialPdf
                                    Road={Road}
                                    Users={Users}
                                    NoticeForm={NoticeForm}
                                    ServiceDescription={ServiceDescription} />}
                            >
                                {({ url }) => (
                                    <Button className="ml-2 text-white" variant="primary">
                                        <ExternalLinkIcon className="mr-1" />
                                        <a className="text-white" href={url} target="_blank" rel="noopener noreferrer">
                                            Abrir reporte en una nueva ventana
                                        </a>
                                    </Button>
                                )}
                            </BlobProvider>)
                    }
                </Row>
            </Form>
        </Jumbotron>
    )
}
)
export default AuxVial