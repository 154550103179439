import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const showWorkOrderOP = async ({ package_id }) => {
    const url = `/packages/${package_id}/work_order_op/last_code`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: work_order_op.js ~ line 14 ~ showWorkOrderOP ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            if (fetch.data.length > 0)
                response = fetch.data[0]
        return response;
    } catch (error) {
        return response;
    }
}

export const storeWorkOrderOP = async ({ project_id, package_id, indicator_id, data }) => {
    const url = `/packages/${package_id}/project/${project_id}/standard/${indicator_id}/work_order_op`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: work_order_op.js ~ line 31 ~ storeWorkOrderOP ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}