import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import React , { useState } from "react";
import { Col, Row, Container } from 'react-bootstrap';
import ButtonIcon from './ButtonIcon';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import SkeletonForm from './SkeletonForm';

const PDFViewerComp = (props) => {
    const { file } = props;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
    
    const changePage = (number) => ((number <= numPages && number >= 1) && setPageNumber(number));

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<SkeletonForm cols={1} rows={3}/>}
                        noData={(`No se ha cargado el documento...`)}
                        error={(`error`)}
                        className="d-flex justify-content-center"
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </Col>
            </Row>

            <Row className="d-flex justify-content-around"> 
                <ButtonIcon name="Anterior" variant='outline-primary' icon={faAngleDoubleLeft} onClick={() => changePage(pageNumber - 1)}/>
                <p>Página {pageNumber} de {numPages}</p>   
                <ButtonIcon name="Siguiente" variant='outline-primary' icon={faAngleDoubleRight} onClick={() => changePage(pageNumber + 1)}/>      
            </Row>
        </Container>
    );
}

export default PDFViewerComp;