import React, { useEffect, useState } from 'react'
import { Box, Center, Heading } from '@chakra-ui/react';
import { Divider } from 'antd';

const md5 = require('md5');

const PanelComp = ({ assets, handleBoxClick, getFillColor }) => {
    const [bodies, setBodies] = useState([]);
    const [otherAssets, setOtherAssets] = useState([]);

    useEffect(() => {
        const distintictBodies = [...new Set(assets.map(item => item.body))];
        const notNullBodies = distintictBodies.filter(item => item != null);
        setBodies(notNullBodies);
        setOtherAssets(assets.filter(asset => !notNullBodies.includes(asset.body)));
    }, [assets]);

    return (
        <div style={{ backgroundColor: '#fff' }}>
            {
                bodies.map((body, index) => {
                    const key = md5(`body_${index}`);
                    return (
                        <div key={key} >
                            <Divider orientation='left'>
                                <Heading color={'gray'} size='sm'>Cuerpo {body}</Heading>
                            </Divider>
                            <Box display='flex' alignItems='baseline' width={'100%'} flexWrap='wrap' bgColor={'#fff'}>
                                {
                                    assets
                                        .filter((asset) => asset.body === body)
                                        .map((asset, index) => (
                                            <Box
                                                style={{ cursor: 'pointer' }}
                                                key={asset.id}
                                                onClick={() => { handleBoxClick(index, asset) }}
                                                bgColor={getFillColor(asset.status)}
                                                color={'#fff'}
                                                ml={2} mt={2} mb={2}
                                                pl={2} pr={2}
                                                flex='0 0 auto' // Evitar que los elementos se estiren
                                            >
                                                <Center>{asset?.code}</Center>
                                            </Box>
                                        ))
                                }
                            </Box>
                        </div>
                    )
                })
            }
            {/* Activos que no pertenencen a ningún cuerpo */}
            {
                otherAssets.length > 0 &&
                <div>
                    <Divider orientation='left'>
                        <Heading color={'gray'} size='sm'>Sin Cuerpo</Heading>
                    </Divider>
                    <Box display='flex' alignItems='baseline' width={'100%'} flexWrap='wrap' bgColor={'#fff'}>
                        {
                            otherAssets
                                .map((asset, index) => (
                                    <Box
                                        style={{ cursor: 'pointer' }}
                                        key={asset.id}
                                        onClick={() => { handleBoxClick(index, asset) }}
                                        bgColor={getFillColor(asset.status)}
                                        color={'#fff'}
                                        ml={2} mt={2} mb={2}
                                        pl={2} pr={2}
                                        flex='0 0 auto' // Evitar que los elementos se estiren
                                    >
                                        <Center>{asset?.code}</Center>
                                    </Box>
                                ))
                        }
                    </Box>
                </div>
            }
        </div>
    )
}

export default PanelComp