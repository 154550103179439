import React, {Component} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import '../UOV-0/Operacion.css';
import '../UOV-0/Resultados.css';

import {__SERVER__} from '../../../../server/info';
import { Heading } from '@chakra-ui/react';

const MySwal = withReactContent(Swal)


const FiltroBusqueda = class extends Component{
	constructor(props){
		super(props);
		this.state={
			change:false,
			query:{
				creation_dateS:"2019-11-01",
				creation_dateE:"2019-11-02"
			},
			headers:[
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_:""
		};
		this.generateQuery = this.generateQuery.bind(this)
	}

	generateQuery = () => {
		if (this.state.query.creation_dateE === "" || this.state.query.creation_dateS === ""){
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for(let i = 0; i < value_query.length; i++){
			if(value_query[i].toString() !== "0"){
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
			}else{
				headersTable.push(this.state.headers[i])
			}
		}
		this.state.query_ = (this.state.query_).substring(0,this.state.query_.length - 1)
		this.props.setProps(this.state.query_, headersTable)
	}

	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({change:!this.state.change})
	}

	render(){
		return(
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="creation_dateS" value={this.state.query.creation_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="creation_dateE" value={this.state.query.creation_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{width:30}} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const Grid = class extends Component{
	constructor(props){
		super(props)
		console.log(this.props.headersTable)
		this.state = {
			limit:this.props.limit,
			offset:this.props.offset
		}
	}

	render(){
		return(
			<table>
			<tr className="queryConsulta">
				<td colSpan={4}  className="queryConsulta">
					<caption className="buttonsPreviusNext">
						<label>Número de elementos</label>
						<input className='formInput' value={this.state.limit} type='number' onChange={(e) => {
							this.setState({limit:parseInt(e.target.value,10)})
							this.props.limitChange(parseInt(e.target.value,10))
						}}></input>
						{this.state.offset !== 0?
							<button onClick={() => {this.props.updateOffset(this.state.offset - this.state.limit)}}>Anterior</button>:null
						}
						<button onClick={() => {
							let offset = this.props.offset + this.props.limit;
							console.log(offset)
							this.props.updateOffset(offset)}
						}>Siguiente</button>
					</caption>
				</td>
			</tr>
				<tr>
					<th>Fecha</th>
          <th>Transacción</th>
					<th>Monto</th>
					<th>Descripción</th>
				</tr>
				{
					this.props.result.map(item => <tr>
						<td>{item.creation_date.replace('T',' ').split('.')[0]}</td>
						<td>{item.openpay_id}</td>
						<td>{item.amount}</td>
						<td>{item.description}</td>
					</tr>)
				}

			</table>
		);
	}
}

export default class extends Component{
	constructor(props){
		super(props);
		this.state = {
			grid: null,
			query:"",
			headersTable:[],
			result:[],
			refresh:false,
			limit:100,
			offset:0
		}
		this.generateTable = this.generateTable.bind(this)
		this.getMovementsQR = this.getMovementsQR.bind(this)
		this.setProps = this.setProps.bind(this)
		this.updateOffset = this.updateOffset.bind(this)
		this.limitChange = this.limitChange.bind(this)
	}

	generateTable = () => {
		this.setState({
			grid:null
		}, () => {
		this.setState({grid:<Grid result={this.state.result} limitChange={this.limitChange} updateOffset={this.updateOffset} limit={this.state.limit} offset={this.state.offset} headersTable={this.state.headersTable} />})
		})
	}

	limitChange = (limit) => {
		this.setState({limit:limit,offset:0},this.getMovementsQR)
	}

	getMovementsQR = () => {
		let query = this.state.query + '&limit=' + this.state.limit + '&offset=' + this.state.offset;
		console.log(query)
		fetch(__SERVER__ + "/victum_users/victum_movements/project/" + this.props.project_id + query,{
			method:'GET',
			headers:{
				Accept: 'application/json'
			}
		}).then(res => {
			if(res.status === 200){
				return res.json()
			}else{
				return null
			}
		}).then(res => {
			if(res !== null){
				console.log("Result",res)
				this.setState({result:res, refresh: !this.state.refresh}, this.generateTable)

			}else{
				this.setState({result:[], refresh: !this.state.refresh},this.generateTable)
				console.log('Error','Null')
			}
		}).catch(e => {
			console.log('Error',e)
		})
	}

	setProps = (query,headersTable) => {
		this.setState({limit:100,
			offset:0,query:query,headersTable:headersTable},this.getMovementsQR)
	}

	updateOffset = (offset) => {
		this.setState({offset:offset},this.getMovementsQR);
	}

	render(){
		return (
				<div  className="body_">
					<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
					<FiltroBusqueda setProps={this.setProps} />
					{this.state.grid}
				</div>
		);
	}
}
