import React, { useEffect, useState } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal';
import TabsComp from 'components/layouts/Tabs';
import IncidentInfo from '../fragments/Incidents/IncidentInfo';
import IncidentMap from '../fragments/Incidents/IncidentMap';
import IncidentVideos from '../fragments/Incidents/IncidentVideos';
import IncidentPhotos from '../fragments/Incidents/IncidentPhotos';
import IncidentAudios from '../fragments/Incidents/IncidentAudios';
import IncidentFiles from '../fragments/Incidents/IncidentFiles';
import { showObacIncidents } from 'api/operation_standards/incidents';
import { Skeleton } from 'antd';
import { destroyIncidentFiles } from 'api/operation_standards/incidents_files';
import Swal from 'sweetalert2';

const IncidentDetails = (props) => {
    const { onClose, incidentSel, project_id } = props;
    const [incident, setIncident] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        let response = await showObacIncidents({ project_id, id: incidentSel.id });
        if (response) setIncident(response);
        setLoading(false);
    }

    const deleteFile = async (item) => {
        const { file_path, obac_incident_id, id } = item;
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Al aceptar esta operación se eliminará el archivo de la base de datos. ¡Esta operación es irreversible!.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await destroyIncidentFiles({ project_id, obac_incident_id, file_path, id });
                if (response) {
                    getData();
                    Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
                } else
                    Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
            }
        })
    }
    return (
        <ModalComp
            onClose={onClose}
            title={'Detalles del Incidente'}
            size='lg'
            body={<>
                {
                    loading ? <Skeleton active /> :
                        <TabsComp
                            tabColor='black'
                            tablist={['Mapa',
                                // 'Información general', 
                                'Videos', 'Fotografías', 'Audios', 'Documentos']}
                            tabpanels={[
                                <IncidentMap
                                    key='incident_map'
                                    incidentSel={incidentSel}
                                />,
                                // <IncidentInfo
                                //     key='incident_info'
                                // />,
                                <IncidentVideos
                                    key='incident_videos'
                                    data={incident.media}
                                    project_id={project_id}
                                    loading={loading}
                                    deleteFile={deleteFile}
                                />,
                                <IncidentPhotos
                                    key='incident_photos'
                                    data={incident.media}
                                    project_id={project_id}
                                    loading={loading}
                                    deleteFile={deleteFile}
                                />,
                                <IncidentAudios
                                    key='incident_audios'
                                    data={incident.media}
                                    project_id={project_id}
                                    loading={loading}
                                    deleteFile={deleteFile}
                                />,
                                <IncidentFiles
                                    key='incident_files'
                                    data={incident.media}
                                    project_id={project_id}
                                    loading={loading}
                                    deleteFile={deleteFile}
                                />
                            ]}
                        />
                }
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' />
            </>}
        />
    )
}

export default IncidentDetails