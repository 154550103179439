import FormIcon from 'components/layouts/FormIcon';
import React from 'react'
import { Col, Form } from 'react-bootstrap';

const CustomSchedule = (props) => {
    const {
        form,
        handleChange,
        handleChangeCheckBox,
        checks
    } = props;
    return (
        <>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Repetir cada</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                    <input type={"number"} name={"repeats_number"} className={"form-control"}
                        defaultValue={form?.repeats_number} min={1} onChange={handleChange} />
                </Form.Group>
                <Form.Group as={Col}>
                    <select name={"repeat_every"} className={"form-control"}
                        onChange={handleChange} defaultValue={form.repeat_every}>
                        <option value={"days"}>Día</option>
                        <option value={"weeks"}>Semana</option>
                    </select>
                </Form.Group>
            </Form.Row>
            {
                form?.repeat_every === "weeks" &&
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Se repite el</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} lg="8">
                        <Form.Check inline label="dom" name={"sunday"} checked={checks?.sunday} type={"checkbox"} id={`inline-chechbox-1`} onChange={handleChangeCheckBox} />
                        <Form.Check inline label="lun" name={"monday"} checked={checks?.monday} type={"checkbox"} id={`inline-checkbox-2`} onChange={handleChangeCheckBox} />
                        <Form.Check inline label="mar" name={"tuesday"} checked={checks?.tuesday} type={"checkbox"} id={`inline-checkbox-3`} onChange={handleChangeCheckBox} />
                        <Form.Check inline label="mié" name={"wednesday"} checked={checks?.wednesday} type={"checkbox"} id={`inline-checkbox-4`} onChange={handleChangeCheckBox} />
                        <Form.Check inline label="jue" name={"thursday"} checked={checks?.thursday} type={"checkbox"} id={`inline-checkbox-5`} onChange={handleChangeCheckBox} />
                        <Form.Check inline label="vie" name={"friday"} checked={checks?.friday} type={"checkbox"} id={`inline-checkbox-6`} onChange={handleChangeCheckBox} />
                        <Form.Check inline label="sáb" name={"saturday"} checked={checks?.saturday} type={"checkbox"} id={`inline-checkbox-7`} onChange={handleChangeCheckBox} />
                    </Form.Group>
                </Form.Row>
            }
            {/* Rango de fechas */}
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Fechas</Form.Label>
                </Form.Group>
                <FormIcon title='Fecha inicial' name='start_date' type='date'
                    defaultValue={form?.start_date} value={form?.start_date} handleChange={handleChange} />
                <FormIcon title='Fecha final' name='end_date' type='date'
                    defaultValue={form?.end_date} value={form?.end_date} handleChange={handleChange} />
            </Form.Row>
            {/* Rango de horas */}
            <Form.Row>
                <Form.Group as={Col} lg={4}>
                    <Form.Label>Horario</Form.Label>
                </Form.Group>
                <FormIcon title='Hora inicial' name='start_time' type='time'
                    defaultValue={form?.start_time} value={form?.start_time} handleChange={handleChange} />
                <FormIcon title='Hora final' name='end_time' type='time'
                    defaultValue={form?.end_time} value={form?.end_time} handleChange={handleChange} />
            </Form.Row>
        </>
    )
}

export default CustomSchedule