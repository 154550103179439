import { Skeleton } from 'antd'
import ModalComp from 'components/layouts/Modal';
import { GoogleApiWrapper, Map, Marker, Polyline } from 'google-maps-react'
import React, { useEffect, useState } from 'react'

const ModalMap = (props) => {
    const { onClose, google, title, inventory_data } = props;

    const [polylines, setPolylines] = useState([]);
    const [mapReady, setMapReady] = useState(false); // Estado para verificar si el mapa está listo para renderizarse

    useEffect(() => {
        const getCoords = () => {
            const { data } = inventory_data;
            if (data) {
                let res = data.filter(item => {
                    const { latitude, longitude, initial_latitude, initial_longitude } = item;
                    return !!((latitude && longitude) || (initial_latitude && initial_longitude));
                }).map((item) => {
                    const { latitude, longitude, initial_latitude, initial_longitude,
                        latitude_E, longitude_E, final_latitude, final_longitude } = item;

                    let arrCoords = [];
                    if (initial_latitude && initial_longitude) {
                        arrCoords.push({ lat: initial_latitude, lng: initial_longitude });
                    } else {
                        arrCoords.push({ lat: latitude, lng: longitude });
                    }
                    if (latitude_E && longitude_E) {
                        arrCoords.push({ lat: latitude_E, lng: longitude_E });
                    }
                    if (final_latitude && final_longitude) {
                        arrCoords.push({ lat: final_latitude, lng: final_longitude });
                    }
                    return arrCoords;
                })
                setPolylines(res);
                setMapReady(true); // Las posiciones están disponibles, el mapa está listo para renderizarse
            }
        }
        getCoords();
    }, [inventory_data]);

    const onReady = (_, map) => {
        const bounds = new props.google.maps.LatLngBounds();
        polylines.forEach((polyline) => {
            polyline.forEach((point) => {
                return bounds.extend(point)
            })
        });
        map.fitBounds(bounds);
    };
    return (
        <ModalComp
            onClose={onClose}
            title={title}
            size='xl'
            body={<>
                {
                    !mapReady && <Skeleton active />
                }
                {
                    mapReady && ( // Verificar si el mapa está listo para renderizarse
                        <div style={{ height: '70vh', width: '100%' }}>
                            <Map
                                google={google}
                                style={{ width: '100%', height: '100%', position: 'relative' }}
                                initialCenter={{ lat: 0, lng: 0 }}
                                className={'map'}
                                zoom={14}
                                onReady={onReady}
                            >
                                {
                                    polylines.map((polyline, index) => (
                                        <Polyline
                                            key={index}
                                            path={polyline}
                                            strokeColor="#0000FF"
                                            strokeOpacity={0.8}
                                            strokeWeight={3}
                                        />
                                    ))
                                }

                                {
                                    polylines.map((polyline, index) => {
                                        return polyline.map((coord, coordIndex) => (
                                            <Marker
                                                key={coordIndex}
                                                name={'Current location'}
                                                position={coord}
                                            />
                                        ))
                                    })
                                }
                            </Map>
                        </div>
                    )}
            </>}
        />

    )
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
    LoadingContainer: Skeleton
})(ModalMap)