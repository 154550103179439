import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { Box, Collapse, Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { downloadAssetsComponentsFiles, uploadAssetsComponentsFiles } from 'api/operation_standards/assets_component_files';
import Swal from "sweetalert2";
import CarouselComponent from "components/layouts/Carousel";
import ButtonIcon from "components/layouts/ButtonIcon";
import { faChevronDown, faChevronUp, faEdit, faPaperclip, faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Progress, Tag } from "antd";
import { Col, Row } from "react-bootstrap";
const moment = require('moment');

const CardComp = ({ item, table }) => {
    const { isOpen, onToggle } = useDisclosure();
    const disclosureFiles = useDisclosure();
    const clipRef = useRef();

    const { code, brand, model, no_serie, description, created_at, manufacturer,
        status_name, status_color, base_line_color, base_line_name, has_warranty,
        purchase_date, warranty_expiration_date, updated_at } = item;

    const addFiles = () => clipRef.current.click();

    const [thumbnailsLocal, setThumbnailsLocal] = useState([]);
    const [thumbnailsServer, setThumbnailsServer] = useState([]);
    const [disabledUpdate, setDisabledUpdate] = useState(false);
    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState({
        download: false,
        upload: false,
        files: false,
        count: 0,
        countServer: 0
    });

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];
        const getFilesFromServer = async () => {
            setLoading(prevState => ({ ...prevState, download: true }));
            for (const element of item.files) {
                const { created_at, path_file } = element;
                if (cancelLoop) break;
                const { response, cancel } = await downloadAssetsComponentsFiles({ table, asset_component_id: item.id, path_file });
                cancelTokens.push({ cancel });
                if (response) {
                    let url = window.URL || window.webkitURL;
                    let thumbnailURL = url.createObjectURL(response);
                    const thumbnail = {
                        file: response,
                        thumbnailURL: thumbnailURL,
                        fileName: response.name,
                    };
                    // Actualiza el estado con las nuevas miniaturas
                    setThumbnailsServer((prevState) => [...prevState, { thumbnail, created_at: moment(created_at).format('lll') }]);
                    setLoading(prevState => ({ ...prevState, download: false, countServer: prevState.countServer + 1 }));
                }
            }
        };
        if (item.files)
            if (item.files.length > 0) getFilesFromServer();
        return () => {
            cancelLoop = true;
            cancelTokens.forEach((token) => token.cancel());
        }
    }, []);

    useEffect(() => {
        try {
            const p = (loading.count / thumbnailsLocal.length) * 100;
            setPercent(p);
        } catch (error) {
            setPercent(0);
        }
    }, [loading.count]);

    const onChangeFiles = event => {
        setLoading(prevState => ({ ...prevState, files: true }));
        const files = event.target.files;
        // Recorre los archivos seleccionados
        for (const element of files) {
            const file = element;
            // Crea una instancia de FileReader
            const reader = new FileReader();
            // Asigna una función de callback para cuando se complete la lectura del archivo
            reader.onload = (e) => {
                // Obtiene la URL de la vista previa
                const thumbnailURL = e.target.result;
                // Crea un objeto con la información del archivo y la URL de la vista previa
                const thumbnail = { file, thumbnailURL };
                // Actualiza el estado con las nuevas vistas previas
                setThumbnailsLocal(prevState => [...prevState, { thumbnail, created_at: moment().format('lll') }]);
            };
            // Lee el contenido del archivo como una URL
            reader.readAsDataURL(file);
        }
        setLoading(prevState => ({ ...prevState, files: false }));
    }

    const saveFiles = async () => {
        setDisabledUpdate(true);
        setLoading(prevState => ({ ...prevState, upload: true }));
        let count = 0;
        for (const iterator of thumbnailsLocal) {
            const data = new FormData();
            data.append('file', iterator.thumbnail.file);
            const { response } = await uploadAssetsComponentsFiles({
                asset_component_id: item.id, table, data
            });
            if (response) count++;
            setLoading(prevState => ({ ...prevState, count: prevState.count + 1 }));
        }
        switch (count) {
            case 0:
                Swal.fire('¡Operación fallida!', 'Hubo un error a tratar de agregar los archivos', 'error');
                break;
            case count < thumbnailsLocal.length:
                Swal.fire('¡Operación incompleta!', `Sólo ${count} archivos pudieron ser cargados.`, 'warning');
                break;
            default:
                Swal.fire('¡Operación exitosa!', `Los ${count} archivos fueron agregados al servidor`, 'success');
                break;
        }
        setThumbnailsServer(prevState => [...prevState, ...thumbnailsLocal]);
        setThumbnailsLocal([]);
        setLoading(prevState => ({ ...prevState, upload: false, count: 0 }));
        setDisabledUpdate(false);
    }
    const handleDeleteThumbnail = (index) => {
        // Crea una copia del array de vistas previas
        const updatedThumbnails = [...thumbnailsLocal];
        // Elimina la vista previa en la posición `index`
        updatedThumbnails.splice(index, 1);
        // Actualiza el estado con las vistas previas actualizadas
        setThumbnailsLocal(updatedThumbnails);
    };

    return (
        <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <CarouselComponent
                evidences={[
                    ...thumbnailsLocal.map(item => ({ image: item.thumbnail.thumbnailURL, created_at: item.created_at })),
                    ...thumbnailsServer.map(item => ({ image: item.thumbnail.thumbnailURL, created_at: item.created_at }))
                ]
                }
                loading={loading.download}
            />
            <Box p='6'>
                <Box mt='2' fontWeight='semibold' as='h4' lineHeight='tight' isTruncated>
                    {code}
                </Box>
                {/* Card headers */}
                <Grid
                    width={'100%'}
                    templateRows={'repeat(1, 1fr)'}
                    templateColumns={'repeat(7, 1fr)'}
                    gap={4}
                >
                    <GridItem colSpan={5}>
                        <Box>
                            {description && <Box>Descripción: {description}</Box>}
                        </Box>
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Box display='flex' alignItems='baseline'>
                            <Box>
                                <ButtonIcon
                                    size='sm'
                                    tooltip={'Agregar evidencias'}
                                    icon={faPaperclip}
                                    variant='outline-secondary'
                                    onClick={addFiles}
                                />
                            </Box>
                            <Box>
                                <ButtonIcon
                                    size='sm'
                                    tooltip={isOpen ? 'Ver menos' : 'Ver más'}
                                    icon={isOpen ? faChevronUp : faChevronDown}
                                    variant='outline-primary'
                                    onClick={onToggle}
                                />
                            </Box>
                        </Box>
                    </GridItem>
                </Grid>
                {/* Opción para cargar multiples archivos */}
                <input type='file' hidden ref={clipRef} multiple onChange={onChangeFiles} accept='image/*' />
                {/* Detalles ocultos */}
                <Collapse in={isOpen} animateOpacity>
                    <Box>
                        <Box>Marca: {brand}</Box>
                        <Box>Modelo: {model}</Box>
                        <Box>No. serie: {no_serie}</Box>
                        <Box>Fabricante: {manufacturer}</Box>
                        <Box>Fecha de compra: {moment(purchase_date).format('LLLL')}</Box>
                        <Box>Garantía activa:   {has_warranty ? 'Sí' : 'No'}</Box>
                        {
                            has_warranty ?
                                <Box>Expiración de la garantía: {moment(warranty_expiration_date).format('LLLL')}</Box> : null

                        }
                        <Box>Fecha de registro: {moment(created_at).format('LLLL')}</Box>
                        <Box>Última actualización: {moment(updated_at).format('LLLL')}</Box>
                    </Box>
                </Collapse>
                {/* Tags del estado y línea base */}
                <Box display='flex' alignItems='baseline'>
                    <Box>
                        <Tag color={status_color} >{status_name}</Tag>
                    </Box>
                    <Box>
                        <Tag color={base_line_color} >{base_line_name}</Tag>
                    </Box>
                </Box>
                {
                    thumbnailsLocal.length > 0 && <>
                        <Box mt={4}>Hay {thumbnailsLocal.length} archivos por actualizar</Box>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" mt={4}>
                            <Box>
                                <Progress type="circle" percent={percent} width={70} />
                            </Box>
                            <Box ml={2}>
                                <ButtonIcon size='sm' name='Editar' icon={faEdit} variant='outline-secondary' onClick={disclosureFiles.onToggle} />
                            </Box>
                            <Box ml={2}>
                                <ButtonIcon size='sm' name='Actualizar' icon={faSave} variant='outline-success' onClick={saveFiles} disabled={disabledUpdate} />
                            </Box>
                        </Box>
                    </>
                }
                <Collapse in={disclosureFiles.isOpen} animateOpacity>
                    <Row>
                        {
                            thumbnailsLocal.map((thumbnail, index) => (
                                <Col sm={6} key={index}>
                                    <img src={thumbnail.thumbnail.thumbnailURL} alt="Thumbnail" />
                                    <ButtonIcon size='sm' tooltip='Eliminar' icon={faTrashAlt} variant='outline-danger' onClick={() => handleDeleteThumbnail(index)} />
                                </Col>
                            ))
                        }
                    </Row>

                </Collapse>
            </Box>
        </Box>
    );
}

CardComp.propTypes = {
    item: PropTypes.object,
    table: PropTypes.oneOf([
        'assets_its',
        'assets_environment',
        'assets_edification',
        'assets_infrastructure'
    ]).isRequired,
}

export default CardComp;