import { gql } from "@apollo/client";

export const INSERT_MESSAGES = gql`
    mutation InsertMessages($objects: [messenger_messages_insert_input!]!) {
        insert_messenger_messages(objects: $objects) {
        returning {
            id
            created_at
        }
    }
}
`;

export const INSERT_MESSAGE = gql`
    mutation InsertMessage($message: String, $room_id: Int, $user_id: Int) {
        insert_messenger_messages(
            objects: {
                message: $message, 
                room_id: $room_id, 
                user_id: $user_id}
        ) {
            returning {
                id
                created_at
            }
        }
    }
`;