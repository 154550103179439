import { StyleSheet } from '@react-pdf/renderer'

const BORDER_COLOR = '#000';
const BORDER_STYLE = 'solid';
const COLN_WIDTH = 100;

const stylesTable = StyleSheet.create({
    body: {
        padding: 10
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginTop: "4%"
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        margin: "0% 2%"
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableRowCenter: {
        margin: "auto",
        flexDirection: "row",
        alignContent: "center",
        textAlign: "center"
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#117DC3"
    },
    tableColHeader2: {
        width: 2 * COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#117DC3"
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol2: {
        width: 2 * COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColT: {
        width: 2 * COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: 5,
        backgroundColor: "#117DC3",
        color: "#fff",
        fontStyle: "bold",
        fontSize: 10,
        fontWeight: 500
    },
    tableCell: {
        margin: 4,
        fontSize: 9
    }
})

export default stylesTable