import { faEye } from '@fortawesome/free-solid-svg-icons';
import { indexCustomers } from 'api/facturapi/Customers';
import TableComp from 'components/layouts/Table';
import React, { Fragment, useEffect, useState } from 'react';
import Details from './Details';

const ClientsComp = () => {
	const [customers, setCustomers] = useState([]);
	const [customerSel, setCustomerSel] = useState({});
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(true);
	const [modalDet, setModalDet] = useState(false);

	useEffect(() => {
		getCustomers();
	}, []);

	const getCustomers = async () => {
		setLoading(true);
		let response = await indexCustomers();
		setTotalItems(response.totalItems);
		setCustomers(response.data);
		setLoading(false);
	}

	const viewDetails = (item) => {
		setCustomerSel(item);
		openOrCloseDet();
	}

	const openOrCloseDet = () => setModalDet(!modalDet);
	return (
		<Fragment>
			<TableComp
				headers={['#', 'Razón social', 'RFC', 'Correo electrónico', 'Teléfono', 'Creado en', 'Acciones']}
				keys={['#', 'legal_name', 'tax_id', 'email', 'phone', 'created_at']}
				body={customers}
				loading={loading}
				loadingItems={pageSize}
				page={page}
				actions={[
					{ icon: faEye, handleClick: viewDetails, tooltip: 'Ver detalles de la factura' },
				]}
			/>
			{/* Modal para ver los detalles del cliente */}
			{
				modalDet && <Details
					onClose={openOrCloseDet}
					customer={customerSel}
				/>
			}
		</Fragment>
	)
}

export default ClientsComp