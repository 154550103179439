import React from 'react'
import { Document, Page, Text, View,Image, StyleSheet  } from '@react-pdf/renderer'
import moment from 'moment';
import { __SERVER__ } from 'server/info';
import { getInfoPackage } from 'variables/Variables';

const styles = StyleSheet.create({
    body:{ padding: 15 },
    Head:{
        display: "flex",
        flexDirection: "row"
    },
    Head_Image:{ width: 110 },
    table: { 
      display: "table",
      borderStyle: "solid",  
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      marginBottom: 15,
      borderColapse: "collapse",
      tableLayout: "auto",
      width: "auto", 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderTopWidth: 1,
      width: "100%"
    }, 
    tableCol: { 
      borderStyle: "solid",
      borderRightWidth: 1,
      width: "100%",
      height: "auto"
    }, 
    tableCell: { 
      margin: "auto", 
      marginVertical: 5,
      marginHorizontal: 3,
      fontSize: 10,
      textAlign: "center"
    },
    text_center: { textAlign: "center" , fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_title: { textAlign: "center", fontWeight: "bold", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_center50: { textAlign: "center" , fontSize: 12, width: "50%", borderStyle: "solid", borderBottomWidth: 1, borderRightWidth:1 },
    logo:{
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    },
    logo1:{
        width: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid", 
        borderWidth: 1,
    },
    d_flex:{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    }, txt_center: { textAlign: "center" } ,
    text_logo: {textAlign: "center" , fontSize: 19, fontWeight: "heavy", width: "100%", borderStyle: "solid", borderTopWidth: 1, color: "#009644"},
    mb_5:  { marginBottom: 14, borderStyle: "solid", 
    borderWidth: 1, },
    fz_12: {fontSize: 12, textAlign: "center",  width: "100%"},
    flex:{
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" },
    cuad: { display: "flex", flexDirection: "row" , flexWrap: "wrap", justifyContent: "center" }
});

const BienesPdf = ({table,code,assets,assetsImg, project_id}) => {
    const info = getInfoPackage()
    return (
        <Document>
            <Page size='A4' orientation="landscape" style={styles.body}>
                <View style={styles.mb_5} fixed>
                    <View style={styles.Head}>
                        <View style={styles.logo}>
                            <Image src={info.img} style={styles.Head_Image}/>
                        </View>

                        <View style={styles.d_flex}>
                            <Text style={{fontWeight: 900}}>{info.title}</Text>
                            <Text style={styles.text_center}>GESTIÓN DE ACTIVOS </Text>
                            <Text style={styles.text_center}>CM-{info.code}-MTTO SGA</Text>
                            <Text style={styles.fz_12}>Mantenimiento de Equipos y Bienes de 
                            { (code === 'UOV-7.2') && ' ITS'}
                            { (code === 'UOV-7.4') && ' Edificación'}
                            { (code === 'UOV-7.5') && ' Medio Ambiente'}
                            </Text>
                            
                        </View>

                        <View style={styles.logo1}>
                            <Text style={styles.text_center}>Autopista {info.highway}</Text>
                            <Text style={styles.text_center}>CÓDIGO: -</Text>
                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                <Text style={styles.text_center50}>VERSIÓN: 1</Text>
                                <Text style={styles.text_center50}>FECHA DE EMISIÓN: { moment().format("D/MM/YY") }</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <Text style={{ fontSize: 14, textAlign: "center", marginBottom: 4 }} >Bienes Verificados</Text>
                {(table === 'assets_its') && (<View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    {
                        ["#","Id activo", "Tipo de elemento", "Sentido", "Cadenamiento", "Latitud", "Longitud", "Otro"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                       
                    {
                        (assets && assets.length>0) && assets.map((asset, i) => {
                            /* let elementType = assetsTypes.filter(item => item.id === asset.assets_base_line_id); */
                            return (
                                <View style={styles.tableRow} key={asset.id}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{i+1}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{asset.id}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{asset.type_name}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{asset.direction}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{asset.km} km</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>
                                            { asset.latitude && parseFloat(asset.latitude).toFixed(2) }
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{asset.longitude && parseFloat(asset.longitude).toFixed(2) }</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{asset.others}</Text>
                                    </View> 
                                </View> 
                            )
                        })
                    }
                </View>)}
                {(table === 'assets_edification') && (<View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    {
                        ["#", "Id activo","Nombre","KM", "Dirección", "Lat. Inicial", "Long. Inicial", "Lat. Inicial", "Lat. Final", "Daño"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                       
                    {
                        assets && assets.map((asset, i) => {
                            return (
                                <View style={styles.tableRow} key={asset.id}>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{i+1}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.id}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.name}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.km} km</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.direction}</Text></View>

                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.initial_latitude && ((asset.initial_latitude.toString()).slice(0, 7))}</Text></View>

                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.final_latitude && ((asset.final_latitude.toString()).slice(0, 7))}</Text></View>

                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.initial_longitude && ((asset.initial_longitude.toString()).slice(0, 7))}</Text></View>

                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.final_longitude && ((asset.final_longitude.toString()).slice(0, 7))}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.damage}</Text></View>
                                    
                                </View> 
                            )
                        })
                    }
                </View>)}
                {(table === 'assets_environment') && (<View style={styles.table}> 
                    <View style={styles.tableRow}> 
                    {
                        ["#","Id activo", "Tipo de elemento", "Sentido", "Cadenamiento", "Latitud", "Longitud", "Señalamiento vertical", "Capacidad", "Limpieza", "Pintura"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                       
                    {
                        assets && assets.map((asset, i) => {
                            return (
                                <View style={styles.tableRow} key={asset.id}>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{i+1}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.id}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.type_name}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.direction}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.km} km</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.latitude && (parseFloat(asset.latitude || 0).toFixed(4))}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.longitude && (parseFloat(asset.longitude || 0).toFixed(4))}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.vertical_marking}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.capacity}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.cleaning}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{asset.paint}</Text></View>
                                   
                                </View> 
                            )
                        })
                    }
                </View>)}

               
                <View style={styles.cuad}>
                    {
                        (assetsImg && assetsImg.length>0) ? assetsImg.map((element, i) => 
                            <View key={i} style={{ margin: 5 }} >
                                {(table === 'assets_its') && <Image src={`${__SERVER__}/projects/${project_id}/${table}_files/${element.assets_its_id}/download?path=${element.path}`} style={styles.Head_Image}/>}

                                {(table === 'assets_edification') && <Image src={`${__SERVER__}/projects/${project_id}/${table}_files/${element.assets_edification_id}/download?path=${element.path}`} style={styles.Head_Image}/>}

                                {(table === 'assets_environment') && <Image src={`${__SERVER__}/projects/${project_id}/${table}_files/${element.assets_environment_id}/download?path=${element.path}`} style={styles.Head_Image}/>}

                                <Text style={{ fontSize: 11, margin: 2 }}>
                                    Imagen {i+1} - Activo {element?.assets_environment_id || element?.assets_edification_id || element?.assets_its_id}
                                </Text>
                            </View>
                        ) : <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}>No hay fotos</Text>
                    }
                </View>
                

                <View style={{display: "flex", flexDirection: "row", marginTop: 100, fontSize: 12,justifyContent: "space-around"}} >
                    <View>
                        <Text>FIRMA DEL MRO (OPERADOR)</Text>
                    </View>
                    <View>
                        <Text>FIRMA DE LA SUPERVISIÓN (AAS)</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default BienesPdf