import React, { useEffect, useState } from 'react';
import { faFilePdf, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import { Skeleton } from 'antd';
import TabsComp from 'components/layouts/Tabs';
import CarouselComponent from 'components/layouts/Carousel';
import {
    destroyMaintenanceProgramFlowFiles,
    downloadMaintenanceProgramFlowFiles,
    uploadMaintenanceProgramFlowFiles
} from 'api/operation_standards/maintenance_program_flow_files';
import Swal from 'sweetalert2';
import { getPDFMaintenanceProgramFlow } from 'api/operation_standards/maintenance_program_flow';

const moment = require('moment');

const EventDetails = (props) => {
    const { onClose, data, project_id, package_id, loading } = props;
    const [images, setImages] = useState({ after: [], before: [] });
    const [localImages, setLocalImages] = useState({ after: [], before: [] });
    const [loadingPhotos, setLoadingPhotos] = useState({ after: true, before: true });
    const [loadingUpl, setLoadingUpl] = useState({ after: false, before: false });
    const [loadingDel, setLoadingDel] = useState({ after: false, before: false });
    const [count, setCount] = useState({ after: 0, before: 0 });
    const [currentCount, setCurrentCount] = useState({ after: 0, before: 0 });
    const [files, setFiles] = useState({ after: [], before: [] });
    const [loadingDownload, setLoadingDownload] = useState(false);

    useEffect(() => {
        const getLocalImages = async () => {
            // Actualizar el contador de las fotografías
            setCount(prevState => ({
                before: prevState.before + localImages.before.length
            }));
            // Recorrer el arreglo de las fotografías que se cargaron correctamente en el servidor
            for (let index = 0; index < localImages.before.length; index++) {
                const element = localImages.before[index];
                const { id, file_name, creation_date } = element;
                const file = files.before[index];  // Obtener el objeto file
                const reader = new FileReader();
                // Asigna una función de callback para cuando se complete la lectura del archivo
                reader.onload = (e) => {
                    // Obtiene la URL de la vista previa
                    const image = e.target.result;
                    // Actualiza las fotografías del carousel con las fotografías obtenidas localmente
                    setImages((prevState) => ({
                        ...prevState,
                        before: [
                            ...prevState.before,
                            { id, image, file_name, created_at: moment(creation_date).format('LLLL') }
                        ]
                    }));
                    setCurrentCount(prevState => ({ ...prevState, before: prevState.before + 1 }));
                };
                // Lee el contenido del archivo como una URL
                reader.readAsDataURL(file);
            }
            setLocalImages([]);
        }
        if (localImages?.before?.length > 0) getLocalImages();
    }, [localImages.before]);

    useEffect(() => {
        const getLocalImages = async () => {
            setCount(prevState => ({
                after: prevState.after + localImages.after.length
            }));
            for (let index = 0; index < localImages.after.length; index++) {
                const element = localImages.after[index];
                const { id, file_name, creation_date } = element;
                const file = files.after[index];
                const reader = new FileReader();
                reader.onload = (e) => {
                    const image = e.target.result;
                    setImages((prevState) => ({
                        ...prevState,
                        after: [
                            ...prevState.after,
                            { id, image, file_name, created_at: moment(creation_date).format('LLLL') }
                        ]
                    }));
                    setCurrentCount(prevState => ({ ...prevState, after: prevState.after + 1 }));
                };
                reader.readAsDataURL(file);
            }
            setLocalImages([]);
        }
        if (localImages?.after?.length > 0) getLocalImages();
    }, [localImages.after]);

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];
        const createPhothos = async () => {
            setImages({ after: [], before: [] });
            setCount({ after: 0, before: 0 });
            setCurrentCount({ after: 0, before: 0 });
            setLoadingPhotos(prevState => ({ ...prevState, after: true, before: true }));
            if (data?.evidences?.length > 0) {
                const afterPhotos = data.evidences.filter((item) => item.file_name[0] === 'a');
                const afterPhotosCount = afterPhotos.length;
                setCount({ after: afterPhotosCount, before: data.evidences.length - afterPhotosCount });
                for (const element of data.evidences) {
                    if (cancelLoop) break;
                    const { id, file_name, creation_date } = element;
                    const { response, cancel } = await downloadMaintenanceProgramFlowFiles({ project_id, program_flow_id: data.id, path: file_name });
                    if (response) {
                        cancelTokens.push({ cancel });
                        let blob_file = URL.createObjectURL(response);
                        if (file_name[0] === 'a') {
                            setImages((prevState) => ({
                                ...prevState,
                                after: [
                                    ...prevState.after,
                                    { id, image: blob_file, file_name, created_at: moment(creation_date).format('LLLL') }
                                ]
                            }));
                            setCurrentCount(prevState => ({ ...prevState, after: prevState.after + 1 }));
                            setLoadingPhotos(prevState => ({ ...prevState, after: false }));
                        } else {
                            setImages((prevState) => ({
                                ...prevState,
                                before: [
                                    ...prevState.before,
                                    { id, image: blob_file, file_name, created_at: moment(creation_date).format('LLLL') }
                                ]
                            }));
                            setCurrentCount(prevState => ({ ...prevState, before: prevState.before + 1 }));
                            setLoadingPhotos(prevState => ({ ...prevState, before: false }));
                        }
                    }
                }
            }
            setLoadingPhotos(prevState => ({ ...prevState, after: false, before: false }));
        }

        if (!loading) createPhothos();

        return () => {
            cancelLoop = true;  // cancelar la petición del resto de las fotografías
            cancelTokens.forEach((token) => token.cancel()); // cancelar cada uno de los tokens creados
        }
    }, [loading]);

    const removeFromImages = (item) => {
        setImages(prevState => ({
            ...prevState,
            before: prevState.before.filter(image => image.id !== item.id)
        }));
        setCurrentCount(prevState => ({ ...prevState, before: prevState.before - 1 }));
        setCount(prevState => ({ before: prevState.before - 1 }));
    }

    const removeFromImagesAfter = (item) => {
        setImages(prevState => ({
            ...prevState,
            after: prevState.after.filter(image => image.id !== item.id)
        }));
        setCurrentCount(prevState => ({ ...prevState, after: prevState.after - 1 }));
        setCount(prevState => ({ after: prevState.after - 1 }));
    }

    const handleDeleteThumbnail = async (item, index) => {
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Al aceptar esta operación se eliminará el archivo de la base de datos.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoadingDel(prevState => ({ ...prevState, before: true }));
                const { id, file_name } = item;
                const { response } = await destroyMaintenanceProgramFlowFiles({ project_id, flow_id: data.id, id, path: file_name });
                if (response) {
                    Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
                    removeFromImages(item);
                } else
                    Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
            }
            setLoadingDel(prevState => ({ ...prevState, before: false }));
        })
    }

    const handleDeleteThumbnailAfter = async (item, index) => {
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Al aceptar esta operación se eliminará el archivo de la base de datos.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoadingDel(prevState => ({ ...prevState, after: true }));
                const { id, file_name } = item;
                const { response } = await destroyMaintenanceProgramFlowFiles({ project_id, flow_id: data.id, id, path: file_name });
                if (response) {
                    Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
                    removeFromImagesAfter(item);
                } else
                    Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
            }
            setLoadingDel(prevState => ({ ...prevState, after: false }));
        })
    }

    const handleLoadFile = (event) => {
        const files = event.target.files;
        setFiles(prevState => ({ ...prevState, before: files }));
    }
    const handleLoadFileAfter = (event) => {
        const files = event.target.files;
        setFiles(prevState => ({ ...prevState, after: files }));
    }

    const handleUploadClick = async () => {
        setLoadingUpl(prevState => ({ ...prevState, before: true }));
        let count = 0;
        let filesUploaded = { data: [], files: [] };
        for (const file of files.before) {
            const dataFile = new FormData();
            dataFile.append('file', file);
            const { response } = await uploadMaintenanceProgramFlowFiles({ project_id, program_flow_id: data.id, moment: 'before', data: dataFile });
            if (response) {
                count++;
                filesUploaded.data.push(response[0]);
                filesUploaded.files.push(file);
            }
        }
        setLocalImages(prevState => ({ ...prevState, before: filesUploaded.data }));
        setFiles(prevState => ({ ...prevState, before: filesUploaded.files }));
        switch (count) {
            case 0:
                Swal.fire('¡Operación fallida!', 'Hubo un error a tratar de agregar los archivos', 'error');
                break;
            case count < files.length:
                Swal.fire('¡Operación incompleta!', `Sólo ${count} archivos pudieron ser cargados.`, 'warning');
                break;
            default:
                Swal.fire('¡Operación exitosa!', `Los ${count} archivos fueron agregados al servidor`, 'success');
                break;
        }
        setLoadingUpl(prevState => ({ ...prevState, before: false }));
    }
    const handleUploadClickAfter = async () => {
        setLoadingUpl(prevState => ({ ...prevState, after: true }));
        let count = 0;
        let filesUploaded = { data: [], files: [] };
        for (const file of files.after) {
            const dataFile = new FormData();
            dataFile.append('file', file);
            const { response } = await uploadMaintenanceProgramFlowFiles({ project_id, program_flow_id: data.id, moment: 'after', data: dataFile });
            if (response) {
                count++;
                filesUploaded.data.push(response[0]);
                filesUploaded.files.push(file);
            }
        }
        setLocalImages(prevState => ({ ...prevState, after: filesUploaded.data }));
        setFiles(prevState => ({ ...prevState, after: filesUploaded.files }));
        switch (count) {
            case 0:
                Swal.fire('¡Operación fallida!', 'Hubo un error a tratar de agregar los archivos', 'error');
                break;
            case count < files.length:
                Swal.fire('¡Operación incompleta!', `Sólo ${count} archivos pudieron ser cargados.`, 'warning');
                break;
            default:
                Swal.fire('¡Operación exitosa!', `Los ${count} archivos fueron agregados al servidor`, 'success');
                break;
        }
        setLoadingUpl(prevState => ({ ...prevState, after: false }));
    }

    const downloadPDF = async () => {
        setLoadingDownload(true);
        const { response } = await getPDFMaintenanceProgramFlow({ package_id, project_id, id: data.id });
        if (response) {
            const link = document.createElement('a')
            link.href = response.signedUrl;
            document.body.appendChild(link)
            link.click();
        }
        setLoadingDownload(false);
    }

    return (
        <ModalComp
            title={loading ? 'Cargando...' :
                `Detalles del evento ${data?.asset_activity?.asset?.code} - ${data?.asset_activity?.asset?.km}`}
            size='lg'
            onClose={onClose}
            body={<>
                {
                    loading && <Skeleton active />
                }
                {
                    !loading &&
                    <div>
                        <TabsComp
                            tabColor='black'
                            tablist={['Evento', 'Fotografías iniciales', 'Fotografías finales']}
                            tabpanels={[
                                <>
                                    <h4>{data.activity_permanent}</h4>
                                    <label><strong>Responsable: </strong>{data.responsable_permanent}</label><br />
                                    <label><strong>Bien: </strong>{data.asset_permanent}</label><br />
                                    <label><strong>Estado: </strong>{data.status}</label><br />
                                    <label><strong>Fecha: </strong>{moment(data.start_date).utcOffset('+06:00').format('DD-MM-YYYY')}</label><br />
                                    <label><strong>Horario: </strong>{data.start_time} - {data.end_time}</label><br />
                                    <label><strong>Responsable: </strong>{data.responsable_permanent}</label><br />
                                    {data.comment_S !== null ? <label><strong>Comentarios iniciales: </strong>{data.comment_S}</label> : null}
                                    {data.comment_E !== null ? <label><strong>Comentarios finales: </strong>{data.comment_E}</label> : null}
                                </>, <>
                                    {
                                        count.before > 0 &&
                                        <p>Obteniendo {currentCount.before}/{count.before} fotografía{count.before === 1 ? '' : 's'}...</p>
                                    }
                                    <CarouselComponent
                                        key={'before-key'}
                                        loading={loadingPhotos.before}
                                        evidences={images.before}
                                        enableDel={true}
                                        enableUpl={true}
                                        loadingDel={loadingDel.before}
                                        loadingUpl={loadingUpl.before}
                                        handleDeleteThumbnail={handleDeleteThumbnail}
                                        handleLoadFile={handleLoadFile}
                                        handleUploadClick={handleUploadClick}
                                    />
                                </>, <>
                                    {
                                        count.after > 0 &&
                                        <p>Obteniendo {currentCount.after}/{count.after} fotografía{count.after === 1 ? '' : 's'}...</p>
                                    }
                                    <CarouselComponent
                                        key={'after-key'}
                                        loading={loadingPhotos.after}
                                        evidences={images.after}
                                        enableDel={true}
                                        enableUpl={true}
                                        loadingDel={loadingDel.after}
                                        loadingUpl={loadingUpl.after}
                                        handleDeleteThumbnail={handleDeleteThumbnailAfter}
                                        handleLoadFile={handleLoadFileAfter}
                                        handleUploadClick={handleUploadClickAfter}
                                    />,
                                </>
                            ]}
                        />
                    </div>
                }
            </>}
            footer={<>
                <ButtonIcon onClick={onClose} name='Cerrar' variant='outline-secondary' icon={faTimes} />
                {
                    !loading &&
                    <ButtonIcon
                        onClick={downloadPDF}
                        name={loadingDownload ? 'Obteniendo PDF' : 'Descargar PDF'}
                        variant='outline-danger'
                        icon={loadingDownload ? faSpinner : faFilePdf}
                        disabled={loadingDownload}
                        tooltipDisabled={true}
                    />
                }
            </>}
        />
    )
}

export default EventDetails