import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";

const CREATED = 201;
const STATUS_OK = 200;

export const downloadIncidentFiles = async ({ project_id, obac_incident_id, file_path }) => {
    const url = `/projects/${project_id}/obac_incidents_media/download`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: {
                obac_incident_id,
                file_path
            }
        });
        if (fetch.status === STATUS_OK)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const uploadIncidentFiles = async ({ project_id, incident_id, file_type, user_id, data }) => {
    const url = `/projects/${project_id}/obac_incidents_media/${incident_id}/user/${user_id}/upload/${file_type}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            contentType: 'application/x-www-form-urlencoded',
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: incidents_files.js:39 ~ uploadInventoryFiles ~ fetch", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const destroyIncidentFiles = async ({ project_id, obac_incident_id, id, file_path }) => {
    const url = `/projects/${project_id}/obac_incidents_media/${obac_incident_id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'DELETE',
            params: {
                id,
                file_path
            }
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}