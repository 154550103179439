import { gql } from "@apollo/client";

export const ROOMS_QUERY = gql`
  query RoomsQuery($user_id: Int) {
    messenger_rooms(
      order_by: { id: asc }
      where: { room_users: { user_id: { _eq: $user_id } } }
    ) {
      id
      name
      room_users{
        user_id
      }
    }
  }
`;
