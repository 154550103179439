import React, { Fragment, useState } from 'react';
// import FlexButton from 'components/layouts/FlexButton';
import Main from './fragments/Main';
import NewRoom from './fragments/NewRoom';

const Messenger = () => {
    const [modal, setModal] = useState(false);
    const [updateRooms, setUpdateRooms] = useState(false);

    const openOrClose = () => setModal(!modal);
    const update = () => setUpdateRooms(!updateRooms);

    return (
        <Fragment>
            {/* Salas y mensajes: Sección para chatear */}
            <Main updateRooms={updateRooms} newRoom={openOrClose} />
            {/* Botón para agregar un nuevo chat */}
            {/* El administrador es libre de crear salas y  chatear con clientes, proveedores y demás administradores */}
            {/* <FlexButton style={{ bottom: 20, right: 20 }} onClick={openOrClose} /> */}
            {/* Modal para agregar más salas */}
            {
                modal && <NewRoom
                    onClose={openOrClose}
                    update={update}
                />
            }
        </Fragment>
    )
}

export default Messenger;