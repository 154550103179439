import { faArrowLeft, faBan } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { deleteInvoice } from 'api/facturapi/Invoices';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal';
import SkeletonStack from 'components/layouts/SkeletonStack';

const CancelInvoice = (props) => {
    const { onClose, invoice, update } = props;
    const [motive, setMotive] = useState('01');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => setMotive(e.target.value);

    const confirmCancelInvoice = async () => {
        setLoading(true);
        let response = await deleteInvoice(invoice.id, motive);
        if (response) {
            // setToast({
            //     toast, title: '¡Operación exitosa!', status: 'success',
            //     description: 'Su CFDi ha sido cancelado satisfactoriamente.'
            // });
            update();
            onClose();
        }else{
            // setToast({
            //     toast, title: '¡Operación fallida!', status: 'warning',
            //     description: 'Hubo un problema al tratar de cancelar su CFDi.'
            // });
        }
        setLoading(false);
    }

    return (
        <ModalComp
            title='Cancelar CFDi'
            size='md'
            onClose={onClose}
            body={<Fragment>
                {
                    loading ? <SkeletonStack rows={5} /> :
                        <Fragment>
                            <p className='mb-3'>
                                Usted está a punto de cancelar el CFDi con el folio <strong>{invoice?.folio_number}</strong> y
                                UUID <strong>{invoice?.uuid}</strong> emitida a <strong>{invoice?.customer?.legal_name}</strong> por un
                                total de <strong>${invoice?.total} {invoice?.currency}</strong>.
                            </p>
                            <Row className='mb-3'>
                                <Col>
                                    <Form.Label>Motivo de cancelación</Form.Label>
                                    <select value={motive} onChange={handleChange}>
                                        <option value='01'>01 - Comprobante emitido con errores con relación.</option>
                                        <option value='02'>02 - Comprobante emitido con errores sin relación.</option>
                                        <option value='03'>03 - No se llevó a cabo la operación.</option>
                                        <option value='04'>04 - Operación nominativa relacionada en la factura global.</option>
                                    </select>
                                </Col>
                            </Row>
                        </Fragment>
                }

            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                <ButtonIcon icon={faBan} name='Cancelar CFDi' variant='outline-danger' onClick={confirmCancelInvoice} disabled={loading} />
            </Fragment>}
        />
    )
}

export default CancelInvoice