import { __BUSCAR_INICIDENTES__ } from "../../../../utilidades/events_name";
import { Variables } from "../../../../variables/Variables";
import { __SERVER__ } from "../../../../server/info";
import React from 'react';
import PubSub from 'pubsub-js';
import { Card, ListGroup, ListGroupItem, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { dateToString } from "../../../../utilidades/dates";
import { ___TYPE_MODAL } from "./constantes/Interfaces";
import FlexButton from "components/layouts/FlexButton";
import NewIncident from "./Incidents/NewIncident";
import ModalAssignActivity from './Inventarios/fragments/ModalAssignActivity';
import Swal from "sweetalert2";
import { indexMaintenanceIncidents, updateIncidents } from "api/maintenance_standards/incidents";

export default class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            incidentes: [],
            id: 0,
            disabled: false,
            modalNewIncident: false,
            modalSchedule: false,
            itemSel: null,
            assetSel: {}                // Datos sobre el elemento del inventario sobre el que se levantó el incidente
        }
    }
    componentDidMount = () => {
        this.token = PubSub.subscribe(__BUSCAR_INICIDENTES__, this.queryIncidents);
    }

    componentWillUnmount = () => {
        PubSub.unsubscribe(this.token);
    }

    queryIncidents = async (msg, query) => {
        const { project_id } = Variables;
        let params = {};
        const { creation_dateE, creation_dateS, segment_id, standard_id, status_id } = query;
        if (creation_dateE !== "") params.creation_date_end = creation_dateE;
        if (creation_dateS !== "") params.creation_date_start = creation_dateS;
        if (segment_id !== "") params.segment_id = segment_id;
        if (status_id !== "") params.status_id = status_id;
        if (standard_id !== "") params.standard_id = standard_id;
        const { data } = await indexMaintenanceIncidents({ project_id, ...params });
        this.setState({ incidentes: data });
    }

    generarOrdenTrabajo = (type, item) => {
        this.setState({ id: item.id, disabled: true });
        setTimeout(() => {
            this.setState({ id: 0, disabled: false });
        }, 5000)
    }

    getTools = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/cost_work_order?standard_id=' + this.state.datos.standard.id + "&segment_id=" + this.state.datos.segment.id, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(async (res) => {
            let tools = [];
            let equipments = [];
            let materials = [];
            let workforce = [];

            for (let i = 0; i < res.length; i++) {
                switch (res[i].supplie.type.id) {
                    case 1: ///Materiales
                        materials.push(res[i]);
                        break;
                    case 2: ///Mano de obra
                        workforce.push(res[i]);
                        break;
                    case 3: ///Herramientas
                        tools.push(res[i]);
                        break;
                    case 4: ///Equipos
                        equipments.push(res[i]);
                        break;
                    default: break;
                }
            }

            console.log("FIASNDASF", tools[0])
            for (let i = 0; i < tools.length; i++) {
                let fecha = this.state.datos.creation_date.split('T')[0];
                fecha = fecha.split('-');
                fecha = fecha[0] + "-" + fecha[1];
                let code = tools[i].supplie.code;

                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order?supplie_code=" + code + "&segment_id=" + this.state.datos.segment.id + "&standard_id=" + this.state.datos.standard.id + "&month=" + fecha;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        tools[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }

            for (let i = 0; i < equipments.length; i++) {
                let fecha = this.state.datos.creation_date.split('T')[0];
                fecha = fecha.split('-');
                fecha = fecha[0] + "-" + fecha[1];
                let code = equipments[i].supplie.code;
                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order?supplie_code=" + code + "&segment_id=" + this.state.datos.segment.id + "&standard_id=" + this.state.datos.standard.id + "&month=" + fecha;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        equipments[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }

            for (let i = 0; i < materials.length; i++) {
                let fecha = this.state.datos.creation_date.split('T')[0];
                fecha = fecha.split('-');
                fecha = fecha[0] + "-" + fecha[1];
                let code = materials[i].supplie.code;
                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order?supplie_code=" + code + "&segment_id=" + this.state.datos.segment.id + "&standard_id=" + this.state.datos.standard.id + "&month=" + fecha;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        materials[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }

            for (let i = 0; i < workforce.length; i++) {
                let fecha = this.state.datos.creation_date.split('T')[0];
                fecha = fecha.split('-');
                fecha = fecha[0] + "-" + fecha[1];
                let code = workforce[i].supplie.code;
                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order?supplie_code=" + code + "&segment_id=" + this.state.datos.segment.id + "&standard_id=" + this.state.datos.standard.id + "&month=" + fecha;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        workforce[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }
            this.state.viewTable = res.length === 0 ? false : true;
            this.state.toolsValues = new Array(tools.length).fill(0);
            this.state.equipmentsValues = new Array(equipments.length).fill(0);
            this.state.materialsValues = new Array(materials.length).fill(0);
            this.state.workforceValues = new Array(workforce.length).fill(0);

            await fetch(__SERVER__ + '/packages/' + Variables.package_id + '/direct_cost_incidence?segment_id=' + this.state.datos.segment.id + '&standard_id=' + this.state.datos.standard.id + '&incident_id=' + this.state.datos.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                return [];
            }).then(response => {
                for (let i = 0; i < response.length; i++) {
                    let found = false;
                    for (let j = 0; j < tools.length && !found; j++) {
                        if (tools[j].supplie.code === response[i].supplie_code) {
                            found = true;
                            this.state.toolsValues[j] = response[i].count;
                            this.state.toolsTotal += response[i].amount;
                        }
                    }

                    for (let j = 0; j < workforce.length && !found; j++) {
                        if (workforce[j].supplie.code === response[i].supplie_code) {
                            found = true;
                            this.state.workforceValues[j] = response[i].count;
                            this.state.workforceTotal += response[i].amount;
                        }
                    }

                    for (let j = 0; j < materials.length && !found; j++) {
                        if (materials[j].supplie.code === response[i].supplie_code) {
                            found = true;
                            this.state.materialsValues[j] = response[i].count;
                            this.state.materialsTotal += response[i].amount;
                        }
                    }

                    for (let j = 0; j < equipments.length && !found; j++) {
                        if (equipments[j].supplie.code === response[i].supplie_code) {
                            found = true;
                            this.state.equipmentsValues[j] = response[i].count;
                            this.state.equipmentsTotal += response[i].amount;
                        }
                    }
                }
                if (response.length !== 0) {
                    this.setState({ readOnly: true });
                }
            }).catch(e => {
                console.log(e);
            })
            this.setState({
                tools,
                equipments,
                materials,
                workforce
            });
        }).catch(e => {
            this.setState({
                tools: [],
                equipments: [],
                materials: [],
                workforce: []
            });
            console.log(e);
        })
    }

    decimalAdjust = (type, value, exp) => {
        // Si el exp no está definido o es cero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    updateStatusIncident = async () => {
        const { id, inspection_id } = this.state.itemSel;
        const ATENDIENDO = 3;
        let response = await updateIncidents({
            project_id: Variables.project_id,
            inspection_id,
            id,
            data: { status_id: ATENDIENDO }
        });
        if (response) {
            this.setState({
                incidentes: this.state.incidentes.map((item) => {
                    if (item.id === id)
                        return {
                            ...item,
                            status_id: ATENDIENDO,
                            status_name: 'Atendiendo'
                        }
                    return item;
                })
            });
        }
    }
    assignMaintenanceProgram = (item) => {
        this.setState({ itemSel: item });
        const { inventory_data_id } = item;
        if (inventory_data_id) {
            this.setState({ modalSchedule: true, assetSel: { id: inventory_data_id } });
        } else {
            Swal.fire('¡Operación no disponible!', 'El incidente al que está tratando de asignar un programa de mantenimiento no está vinculado a un elemento del inventario. Comuníquese con el administrador.', 'info');
        }
    }
    render() {
        return (<>
            <ul className="content_ds">
                {
                    this.state.incidentes.map((item, i) =>
                        <Card key={item.id} style={{ width: '18rem' }}>
                            <Card.Header className={"status" + item.status_id}>{dateToString(item.creation_date)}</Card.Header>
                            <Card.Body>
                                <Card.Subtitle className="mb-2 text-muted">Creado por: {item.created_by_full_name}</Card.Subtitle>
                                {
                                    item.responsable_by_full_name &&
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Responsable: {item?.responsable_by_full_name}
                                    </Card.Subtitle>
                                }
                            </Card.Body>
                            <ListGroup>
                                <ListGroupItem>{(!item.segment_end ? 'Segmento: ' : 'Segmento inicial: ') + item?.segment_start}</ListGroupItem>
                                {item.segment_end ? <ListGroupItem>Segmento final: {item?.segment_end}</ListGroupItem> : null}
                                <ListGroupItem>{item?.status_name}</ListGroupItem>
                            </ListGroup>
                            <Card.Body>
                                <DropdownButton as={ButtonGroup} variant={'link'} title={"Abrir"} id="bg-nested-dropdown">
                                    <Dropdown.Item onClick={() => this.props.openModal(item.id, ___TYPE_MODAL.___incidente)} eventKey="1">Incidente</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.props.openModal(item.id, ___TYPE_MODAL.___orden_trabajo)} eventKey="2">Generar Orden de Trabajo</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.props.openModal(item.id, ___TYPE_MODAL.___materiales)} eventKey="3">Ver Ordenes de Trabajo</Dropdown.Item>
                                    {
                                        item.status_id === 1 && (item.responsable_id === Variables.user.id || Variables.user.superadmin) &&
                                        <Dropdown.Item onClick={() => this.assignMaintenanceProgram(item)} eventKey="4">Asignar programa de mantenimiento</Dropdown.Item>
                                    }
                                </DropdownButton>
                                {/*<DropdownButton disabled={this.state.disabled} as={ButtonGroup} variant={'link'} title={item.id === this.state.id ? "Generando..." : "Costo de trabajo"} id="bg-nested-dropdown">
                                    <Dropdown.Item onClick={() => this.generarOrdenTrabajo('PDF', item)} eventKey="1">PDF</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.generarOrdenTrabajo('TXT', item)} eventKey="2">Texto Plano</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.generarOrdenTrabajo('XLS', item)} eventKey="3">Excel</Dropdown.Item>
                                </DropdownButton>*/}
                            </Card.Body>
                        </Card>
                    )
                }
            </ul>
            {/* Botón paa agregar un incidente */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => this.setState({ modalNewIncident: true })} />
            {/* Modal para agregar incidente */}
            {
                this.state.modalNewIncident && <NewIncident
                    onClose={() => this.setState({ modalNewIncident: false })}
                    project_id={this.props.project_id}
                    maintenance_standard_id={this.props.maintenance_standard_id}
                    package_id={this.props.package_id}
                    component={this.props.component}
                    table={this.props.table}
                />
            }
            {
                this.state.modalSchedule && <ModalAssignActivity
                    onClose={() => this.setState({ modalSchedule: false })}
                    project_id={this.props.project_id}
                    package_id={this.props.package_id}
                    table={this.props.table}
                    itemSel={this.state.assetSel}
                    type='CORRECTIVO'
                    updateStatusIncident={this.updateStatusIncident}
                    title='Programa de Mantenimiento Correctivo'
                />
            }
        </>
        );
    }
}
