import { getPercentaje } from 'components/libs/functions';
import React from 'react'
import { Badge } from 'react-bootstrap';

const moment = require('moment');

const Info = ({ data }) => {
    const {
        created_by_full_name,
        created_by_type_name,
        responsable_by_full_name,
        responsable_by_type_name,
        creation_date,
        estimated_date,
        incident_evaluation_name,
        deductive,
        segment_start,
        segment_end
    } = data;

    const getVariant = (status_id) => {
        let variant = 'secondary';
        switch (status_id) {
            case 1: variant = 'info'; break;
            case 2: variant = 'info'; break;
            case 3: variant = 'primary'; break;
            case 4: variant = 'dark'; break;
            case 5: variant = 'warning'; break;
            case 6: variant = 'danger'; break;
            case 7: variant = 'success'; break;
            default: break;
        }
        return variant;
    }
    
    return (
        <>
            <p><strong>Creado por: </strong>{created_by_full_name} ({created_by_type_name})</p>
            {
                responsable_by_full_name &&
                <p><strong>Responsable: </strong>{responsable_by_full_name} ({responsable_by_type_name})</p>
            }
            <p><strong>Estado: </strong><Badge variant={getVariant(data.status_id)}>{data.status_name}</Badge>{' '}</p>
            <p><strong>Fecha del reporte: </strong>{moment(creation_date).format('LLLL')}</p>
            <p><strong>Fecha estimada de resolución: </strong>{moment(estimated_date).format('LLLL')}</p>
            <p><strong>Evaluación: </strong>{incident_evaluation_name}</p>
            <p><strong>Deductiva por incumplimiento: </strong>{getPercentaje(deductive / 100, 1)}</p>
            <p><strong>{segment_end ? 'Segmento inicial: ' : 'Segmento: '}</strong>{segment_start}</p>
            {
                segment_end &&
                <p><strong>Segmento final: </strong>{segment_end}</p>
            }
        </>
    )
}

export default Info