import { faComment } from '@fortawesome/free-solid-svg-icons'
import { Avatar, Col, Image, Row } from 'antd'
import ButtonIcon from 'components/layouts/ButtonIcon'
import React from 'react'

const Profile = (props) => {
    const { newRoom } = props;

    return (
        <Row align='middle' style={{ height: '64px', backgroundColor: '#001529' }}>
            <Col span={2}>
                <Avatar
                    size={{ xs: 24, sm: 32, md: 35, lg: 40, xl: 45, xxl: 50 }}
                    src={<Image src="https://joeschmoe.io/api/v1/random" />}
                />
            </Col>
            <Col offset={18} span={2}>
                <ButtonIcon icon={faComment} variant='success' tooltip='Agregar sala' onClick={newRoom} />
            </Col>
        </Row>
    )
}

export default Profile