import React, { Component } from 'react';
import './Puentes.css';
import { __SERVER__ } from '../../server/info';
import { Variables } from '../../variables/Variables';
import { dateToString } from '../../utilidades/dates';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import banobras from '../imagenes-app/banobras.png';
import { Card, Tooltip, OverlayTrigger, Button, Col, Row, Badge } from 'react-bootstrap';
import { FaUpload, FaFileExport, FaEye, FaMapMarker } from 'react-icons/fa';
import ModalMap from './Bridges/ModalMap';
import ModalDetails from './Bridges/ModalDetails';

// Create styles
const styles = StyleSheet.create({
    contentPoint: {
        width: '100%',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 40,
        paddingBottom: 40,
        maxWidth: '100%',
        backgroundColor: 'white'
    },
    TextPoint: {
        color: 'black',
        fontSize: 12,
    },
    valuePoint: {
        color: 'black',
        fontSize: 12,
        textAlign: 'left',
        borderBottom: 1,
        borderBottomColor: 'black'
    },
    h1: {
        color: 'black',
        fontSize: 12,
        marginTop: 10,
        marginBottom: 3
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    column: {
        width: '100%'
    },
    column2: {
        width: '50%'
    },
    column3: {
        width: '33.33%'
    },
    column4: {
        width: '25%'
    },
    column5: {
        width: '20%'
    },
    column6: {
        width: '16.6%'
    }
});

const InspectionBridge = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view1_1: null,
            view1_2: null,
            view1_3: null,
            view1_4: null,
            view1_5: null,
            view1_6: null,
            view1_7: null,
            view1_8: null,
            view1_9: null,
            view1_10: null,
            view1_11: null,
            view1_12: null,
        }
    }

    componentDidMount = () => {
        this.getPoint1();
    }

    getPoint1 = () => {
        this.getPoint1_1();
        this.getPoint1_2();
        this.getPoint1_3();
        this.getPoint1_4();
        this.getPoint1_5();
        this.getPoint1_6();
        this.getPoint1_7();
        this.getPoint1_8();
        this.getPoint1_9();
        this.getPoint1_10();
        this.getPoint1_11();
        this.getPoint1_12();
    }

    getPoint1_1 = () => {
        ///Datos generales del puente
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/general_data', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_1 =
                <View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Superintendente de puentes</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.superintendent}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Nombre del puente</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.name}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Número del puente</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.number}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Estado geográfico</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.geographic_status}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Carretera</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.highway_name}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Ramal</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.branch_name}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tramo</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.road_section_name}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Año de construcción</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.year_construction !== null && res.year_construction !== undefined ? res.year_construction.split('T')[0] : ''}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Año de última reconstrucción</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.year_last_reconstruction !== null && res.year_last_reconstruction !== undefined ? res.year_last_reconstruction.split('T')[0] : ''}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Kilometro</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.kilometer}</Text>
                    </View>
                </View>
            this.setState({ view1_1 });

        }).catch(e => {
            console.log(e)
            this.setState({ view1_1: null });
        })
    }

    getPoint1_2 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/geographical_position', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_2 =
                <View>
                    <Text style={styles.h1}>POSICIÓN GEOGRÁFICA</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}>Latitud</Text>
                        <Text style={[styles.valuePoint, styles.column4]}>{res.latitude}</Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Longitud</Text>
                        <Text style={[styles.valuePoint, styles.column4]}>{res.longitude}</Text>

                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}>Altitud</Text>
                        <Text style={[styles.valuePoint, styles.column4]}>{res.altitude}</Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Elevacion</Text>
                        <Text style={[styles.valuePoint, styles.column4]}>{res.elevation}</Text>
                    </View>

                </View>
            this.setState({ view1_2 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_2: null });
        })
    }

    getPoint1_3 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/geometry', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_3 =
                <View>
                    <Text style={styles.h1}>GEOMETRÍA</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Número de claros</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.number_gaps}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Longitud de claros mín (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.gap_length_min}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Longitud de claros max (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.gap_length_max}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Longitud total (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.length}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Ancho del camellón (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.width}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Ancho de la banq. izq. (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.width_left_bench}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Ancho de la banq. der. (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.width_right_bench}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Ancho de la calzada</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.width_road}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Ancho entre bordillos (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.width_between_curbs}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Ancho del acceso (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.access_width}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Área (m2)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.area}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Puente en curva (s/n)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.curved_bridge}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Esviajamiento (gra)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.displacement}</Text>
                    </View>
                </View>
            this.setState({ view1_3 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_3: null });
        })
    }

    getPoint1_4 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/superestructure?type=0', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_4 =
                <View>
                    <Text style={styles.h1}>SUPERESTRUCTURA, TIPO PRINCIPAL</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Diseño tipo</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.design}</Text>

                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Diseño de la sección transversal</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.cross_design}</Text>

                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Diseño de la elevación</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.elevation_design}</Text>

                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Material</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.material}</Text>

                    </View>
                </View>
            this.setState({ view1_4 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_4: null });
        })
    }

    getPoint1_5 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/superestructure?type=1', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_5 =
                <View>
                    <Text style={styles.h1}>SUPERESTRUCTURA, TIPO SECUNDARIO</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Diseño tipo</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.design}</Text>

                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Diseño de la sección transversal</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.cross_design}</Text>

                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Diseño de la elevación</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.elevation_design}</Text>

                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Material</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.material}</Text>

                    </View>
                </View>
            this.setState({ view1_5 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_5: null });
        })
    }

    getPoint1_6 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/substructure', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_6 =
                <View>
                    <Text style={styles.h1}>SUBESTRUCTURA</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}>Estribos</Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Tipo</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.abutments_type}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}></Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Material</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.abutments_material}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}></Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Tipo de cimentación</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.abutments_foundation}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}>Pilas</Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Tipo</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.piles_type}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}></Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Material</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.piles_material}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column4]}></Text>
                        <Text style={[styles.TextPoint, styles.column4]}>Tipo de cimentación</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.piles_foundation}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de parapeto</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.parapet_type}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Parapeto inclinado</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.sloping_parapet}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de superficie de desgaste</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.wear_surface_type}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de juntas de expansión</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.type_expansion_joints}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de apoyos fijos sobre soportes</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.type_fixed_support_on_supports}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de apoyos móviles sobre soportes</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.type_mobile_support_on_supports}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de apoyos fijos en trabes</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.type_fixed_support_in_beams}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de apoyos móviles en trabes</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.type_mobile_support_in_beams}</Text>
                    </View>
                </View>
            this.setState({ view1_6 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_6: null });
        })
    }

    getPoint1_7 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/design_charge', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_7 =
                <View>
                    <Text style={styles.h1}>CARGA DE DISEÑO</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Cl. de distribución de carga</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.load_distribution}</Text>
                    </View>
                </View>
            this.setState({ view1_7 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_7: null });
        })
    }

    getPoint1_8 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/obstacles', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_8 =
                <View>
                    <Text style={styles.h1}>OBSTÁCULOS QUE CRUZA</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Tipo de paso</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.step_type}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Iden. de la carretera</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.highway_ident}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Kilometro</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.kilometer}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Nombre de la carretera</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.highway_name}</Text>
                    </View>
                </View>
            this.setState({ view1_8 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_8: null });
        })
    }

    getPoint1_9 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/free_space', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_9 =
                <View>
                    <Text style={styles.h1}>Espacio libre</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Sobre el puente (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.over}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Bajo el puente (m)</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.under}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Propietario</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.owner}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Cooperador</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.cooperator}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Responsable de la inspección</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.responsable}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Proyectista</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.designer}</Text>
                    </View>
                </View>
            this.setState({ view1_9 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_9: null });
        })
    }

    getPoint1_10 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/observations', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            res = res[0];
            let view1_10 =
                <View>
                    <Text style={styles.h1}>Observaciones</Text>
                    <View style={styles.row}>
                        <Text style={[styles.valuePoint, styles.column]}>{res.description}</Text>
                    </View>
                </View>
            this.setState({ view1_10 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_10: null });
        })
    }

    getPoint1_11 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/chronological_summary', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({ activity_date: '-T.', exercise: '-' });
            }
            let view1_11 =
                <View>
                    <Text style={styles.h1}>Resumen cronológico</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Fecha</Text>
                        <Text style={[styles.TextPoint, styles.column2]}>Actividad</Text>
                    </View>
                    {
                        res.map((item, i) =>
                            <View style={styles.row} key={'DA' + i}>
                                <Text style={[styles.valuePoint, styles.column2]}>{item.activity_date.split('.')[0].replace('T', ' ')}</Text>
                                <Text style={[styles.valuePoint, styles.column2]}>{item.exercise}</Text>
                            </View>
                        )
                    }
                </View>
            this.setState({ view1_11 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_11: null });
        })
    }

    getPoint1_12 = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/' + this.props.bridge_id + '/inspection/' + this.props.inspection_id + '/1/transit', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return []
        }).then(res => {
            console.log(res);
            if (res.length === 0) {
                res.push({});
            }
            let view1_12 =
                <View>
                    <Text style={styles.h1}>Transito</Text>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>TDPA</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.TDPA}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Carros %</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.cars}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Autobuses %</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.buses}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.TextPoint, styles.column2]}>Camiones %</Text>
                        <Text style={[styles.valuePoint, styles.column2]}>{res.trucks}</Text>
                    </View>
                </View>
            this.setState({ view1_12 });
        }).catch(e => {
            console.log(e)
            this.setState({ view1_12: null });
        })
    }

    render = () => {
        return (
            <section className="viewPDF">
                <button className="closePDF" onClick={this.props.close}>Cerrar</button>
                <PDFViewer className={'contentPoint'}>
                    <Document>
                        <Page size="A4" style={styles.contentPoint}>
                            <View>
                                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Image src={banobras} style={{ width: 110, height: 50 }} />
                                    <Text>APÉNDICE 8</Text>
                                    <Text style={{ fontSize: 13 }}>PAQUETE SURESTE</Text>
                                </View>
                                <View>
                                    <Text style={{ textAlign: 'center', fontSize: 16 }}>SISTEMA DE ADMNISTRACIÓN DE PUENTES</Text>
                                    <Text style={{ textAlign: 'center', fontSize: 13, fontWeight: 'light' }}>FORMATO DE INSPECCIÓN PRINCIPAL</Text>
                                </View>
                                <View style={{ backgroundColor: '#CBC7BB', borderWidth: 2, borderColor: 'black' }}>
                                    <Text style={{ textAlign: 'center', color: 'red', fontSize: 13, padding: 3 }}>FORMATO DE INSPECCIÓN PRINCIPAL DE PUENTES PARA LOS SETVICIOS "MR"</Text>
                                </View>
                                <View style={{ justifyContent: 'flex-end', alignItems: "flex-end", flexDirection: 'row' }}>
                                    <Text style={{ fontSize: 13 }}>FECHA: </Text>
                                    <Text style={{ fontSize: 13 }}>{this.props.date.split('T')[0]}</Text>
                                </View>
                            </View>
                            <View style={{ borderWidth: 1, borderColor: 'black', padding: 2 }}>
                                <Text style={{ fontSize: 15 }}>1. DATOS GENERALES DEL PUENTE</Text>
                                {this.state.view1_1}
                                {this.state.view1_2}
                                {this.state.view1_3}
                                {this.state.view1_4}
                                {this.state.view1_5}
                                {this.state.view1_6}
                                {this.state.view1_7}
                                {this.state.view1_8}
                                {this.state.view1_9}
                                {this.state.view1_10}
                                {this.state.view1_11}
                                {this.state.view1_12}
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </section>
        );
    }
}

const FiltrosBusqueda = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: {
                status: '',
                start_date: '',
                end_date: '',
            },
            refresh: false,
        }
    }

    sendQuery = () => {
        let query = '?bridge_id=' + this.props.bridge_id;
        let keys = Object.keys(this.state.query);
        let values = Object.values(this.state.query);
        for (let i = 0; i < keys.length; i++) {
            if (values[i] !== "") {
                if (keys[i] === 'start_date') {
                    values[i] += 'T00:00:00';
                }
                if (keys[i] === 'end_date') {
                    values[i] += 'T23:59:59';
                }
                query += '&' + keys[i] + "=" + values[i];
            }
        }
        this.props.query(query);
    }

    render = () => {
        return (
            <section className="sectionSearch">
                <article>
                    <label>Estado</label>
                    <select onChange={(e) => {
                        this.state.query.status = e.target.value
                    }}>
                        <option value=''></option>
                        <option value='0'>Iniciado/Progreso</option>
                        <option value='1'>Terminado</option>
                        <option value='2'>Cancelado</option>
                    </select>
                </article>
                <article>
                    <label>Fecha inicio</label>
                    <input onChange={(e) => {
                        this.state.query.start_date = e.target.value
                    }} type="date" />
                </article>
                <article>
                    <label>Fecha fin</label>
                    <input onChange={(e) => {
                        this.state.query.end_date = e.target.value
                    }} type="date" />
                </article>
                <article>
                    <button className="botonBusqueda" onClick={this.sendQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
                </article>
            </section>
        );
    }
}

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bridges: [],
            active: [],
            inspections: [],
            view: 1,
            bridge_id: -1,
            loading: false,
            inspection_id: -1,
            date: '',
            //Bandera para modal de mapa
            modalMap: false,
            //Bandera para modal de detalles
            modalDetails: false,
        }
    }

    componentDidMount = () => {
        this.getBridges();
    }

    getBridges = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(bridges => {
            let active = new Array(bridges.length + 1).fill('cardPuentes');
            active[0] = 'card-active';
            this.setState({ bridges, active });
        }).catch(e => {
            console.log(e);
            let active = new Array(1).fill('card-active');
            this.setState({ bridges: [], active });
        })
    }

    getInspections = (query) => {
        this.setState({ loading: true });
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + Variables.project_id + '/bridges/inspection' + query, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(inspections => {
            this.setState({ inspections });
            this.setState({ loading: false });
        }).catch(e => {
            console.log(e);
            this.setState({ inspections: [] });
            this.setState({ loading: false });
        })
    }

    openOrCloseModalMap = () => {
        this.setState({
            modalMap: !this.state.modalMap
        });
    }

    openOrCloseModalDetails = (event) => {
        this.setState({
            modalDetails: !this.state.modalDetails
        });
    }

    render = () => {
        const statusLabel = ['En progreso', 'Terminado', 'Cancelado'];
        const statusBadge = ['primary', 'success', 'danger'];
        const statusLabelB = ['Enprogreso', 'Terminado', 'Cancelado'];
        // console.log("inspections: ", this.state.inspections);
        return (
            <section className='view-bridges'>
                <article className='bridges'>
                    <div key={'BRID_'} className={'card ' + this.state.active[0]} id='homeB' onClick={() => { this.state.active.fill('cardPuentes'); this.state.active[0] = 'card-active'; this.setState({ view: 1 }); }}>
                        <h3 for='homeB'>Puentes</h3>
                        <label for='homeB'>{this.props.p}</label>
                    </div>
                    {
                        this.state.bridges.map((item, i) =>
                            <div key={'BRID_' + i} className={'card ' + this.state.active[i + 1]} id={'card_id_' + i} onClick={() => { this.state.active.fill('cardPuentes'); this.state.active[i + 1] = 'card-active'; this.setState({ view: 2, bridge_id: item.id, inspections: [] }); }}>
                                <h3 for={'card_id_' + i}>{item.name}</h3>
                                <label for={'card_id_' + i}>{item.clasification}</label>
                            </div>
                        )
                    }
                </article>

                {/* Modal Map */}
                {
                    this.state.modalMap ?
                        <ModalMap
                            title={`Ubicación de la incidencia`}
                            inspection_id={this.state.inspection_id}
                            bridge_id={this.state.bridge_id}
                            onClose={this.openOrCloseModalMap}
                        /> : null
                }

                {/* Detalles de la incidencia */}
                {
                    this.state.modalDetails ?
                        <ModalDetails
                            title={`Detalles de la incidencia`}
                            inspection_id={this.state.inspection_id}
                            bridge_id={this.state.bridge_id}
                            onClose={this.openOrCloseModalDetails}
                        /> : null
                }
                {
                    this.state.view === 1 ?
                        <article className='content-bridges-home'>
                            <h1 className={'content-bridges-title'}>Te damos la bienvenida {Variables.user.first_name}, al módulo de la gestión de Puentes por VICTUM</h1>
                        </article>
                        : this.state.view === 2 ?
                            <article className='content-bridges'>
                                {
                                    this.state.loading ?
                                        <section className="sectionLoading">
                                            <h1>Cargando...</h1>
                                        </section>
                                        : null
                                }
                                <FiltrosBusqueda bridge_id={this.state.bridge_id} query={this.getInspections} />
                                <article className="inspections-bridge">
                                    <article>
                                        {
                                            this.state.inspections.map((item, i) =>
                                                <Card style={{ width: '20rem' }} key={'card' + i}
                                                    border={statusBadge[item.status]}>
                                                    <Card.Body>
                                                        <Card.Subtitle>{dateToString(item.start_date)}</Card.Subtitle>
                                                        <Card.Title>Creado por: <strong>{item.made}</strong></Card.Title>
                                                        <Card.Text>
                                                            <strong>Última actualización: </strong>{dateToString(item.last_update)}<br />
                                                            <strong>Estado: <Badge variant={statusBadge[item.status]}>{statusLabel[item.status]}</Badge></strong><br />
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <Row>
                                                            <Col>
                                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Ver incidencia</Tooltip>}>
                                                                    <Button variant="outline-info" id={item.id} onClick={(e) => {
                                                                        this.setState({ inspection_id: item.id, bridge_id: item.bridge_id });
                                                                        this.openOrCloseModalDetails();
                                                                    }}>
                                                                        <FaEye />
                                                                    </Button>
                                                                </OverlayTrigger></Col>
                                                            <Col>
                                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Ver incidencia en mapa</Tooltip>}>
                                                                    <Button variant="outline-danger" onClick={(e) => {
                                                                        this.setState({ inspection_id: item.id, bridge_id: item.bridge_id });
                                                                        this.openOrCloseModalMap();
                                                                    }}>
                                                                        <FaMapMarker />
                                                                    </Button>
                                                                </OverlayTrigger></Col>
                                                            <Col>
                                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Descargar reporte</Tooltip>}>
                                                                    <Button variant="outline-secondary" onClick={() => this.setState({ inspection_id: item.id, view: 3, date: item.start_date })}>
                                                                        <FaFileExport />
                                                                    </Button>
                                                                </OverlayTrigger></Col>
                                                        </Row>
                                                    </Card.Footer>
                                                </Card>
                                            )
                                        }
                                    </article>
                                </article>
                            </article>
                            : this.state.view === 3 ?
                                <InspectionBridge close={() => this.setState({ view: 2 })} inspection_id={this.state.inspection_id} bridge_id={this.state.bridge_id} date={this.state.date} />
                                : null
                }

            </section>
        );
    }
}