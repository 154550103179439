import React, { useState } from "react";
import { Modal, Button, Form, Col, InputGroup, Container, Row, Alert } from "react-bootstrap";
import { __SERVER__ } from "server/info";
import Swal from "sweetalert2";
import Axios from "axios";
import { dateToString } from "utilidades/dates";
import { before } from "underscore";
import moment from "moment";
import { Variables } from "variables/Variables";

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type_msg: 'warning',
            title: '',
            content: ''
        }
    }

    componentDidMount = () => {
        this.setState({
            title: this.props.title,
            content: this.props.content,
            type_msg: this.props.type_msg
        });
    }

    render = () => {
        return (
            <>
                <Alert variant={this.state.type_msg}>
                    <Alert.Heading>{this.state.title}</Alert.Heading>
                    <p>
                        {this.state.content}
                    </p>
                </Alert>
            </>
        );
    }
}

class AssignedActivities extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            uploading: false,
            start_date: null,
            end_date: null,
            responsable: null,
            maintenance_activity: null,
            periodicity: "dontrepeat",
            repeat_every: "week",
            start_time: null,
            end_time: null,
            checks: {
                sunday: false,
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: false,
            },
            ends: '',
            weekdays: [],
            repeats_number: 1, //Diario by default
            // DAtos para mostrar mensaje de retroalimentación
            title: '',
            content: '',
            type_msg: '',
            show_message: false
        };
    }

    componentDidMount = () => {
        let now = moment();
        now.subtract(now.minutes(), "minutes");
        this.setState({
            start_date: now.format("YYYY-MM-DD"),
            start_time: now.add(moment.duration("01:00:00")).format("HH:mm"),
            end_time: now.add(moment.duration("02:00:00")).format("HH:mm"),
            responsable: this.props.users[0].id,
            maintenance_activity: this.props.maintenance_activities[0].id,
        });
        setTimeout(() => this.setState({ show: true }), 100);
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckBox = (event) => {
        this.setState({
            checks: {
                ...this.state.checks,
                [event.target.name]: event.target.checked
            }
        });
    };

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire(
                "Advertencia",
                "La información sigue en proceso de carga, se le recomienda esperar un poco más.",
                "info"
            );
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    };

    formatDate = (date, format_string) => {
        return moment(date).format(format_string);
    };

    endDate = () => {
        let response = null;
        switch (this.state.periodicity) {
            case 'dontrepeat': response = false; break;
            case 'everyday': response = true; break;
            case 'everyweek': response = true; break;
            case 'everymonth': response = true; break;
            case 'everyyear': response = true; break;
            case 'lmxjv': response = true; break;
            case 'custom': response = false; break;
            default: response = false; break;
        }
        return response;
    }

    // Obtiene array de los días de la semana según los check box
    getWeekDaysFromChecks = () => {
        let values = Object.entries(this.state.checks).map((item, i) => {
            let r = -1;
            if (item[1] === true) r = i;
            return r;
        }).filter(item => item !== -1);

        if (values.length === 0) values = false;
        console.log("values: ", values)
        return values;
    }

    sendMessage = (msg) => {
        this.setState({
            title: "Verifique los siguiente",
            content: msg,
            type_msg: "danger",
            show_message: true
        });

        console.log("Error al asignar la actividad: ", msg);
    }
    // Método para validar que los datos estén correctos antes de hacer la asignación
    validating = () => {
        let response = false;
        switch (this.state.periodicity) {
            case 'dontrepeat':
                this.setState({
                    end_date: this.state.start_date,
                    weekdays: [0, 1, 2, 3, 4, 5, 6]
                });
                response = true;
                break;
            case 'everyday':
                if (this.state.end_date === null) {
                    this.sendMessage("Elija una fecha final!")
                } else {
                    this.setState({
                        weekdays: [0, 1, 2, 3, 4, 5, 6]
                    });
                    response = true;
                }
                break;
            case 'everyweek':
                if (this.state.end_date === null) {
                    this.sendMessage("Elija una fecha final!")
                } else {
                    this.setState({
                        weekdays: [moment(this.state.start_date, "YYYY-mm-dd").weekday()]
                    });
                    response = true;
                }
                break;
            case 'everymonth':
                response = false;
                break;
            case 'everyyear':
                response = false;
                break;
            case 'lmxjv':
                if (this.state.end_date === null) {
                    this.sendMessage("Elija una fecha final!")
                } else {
                    this.setState({
                        weekdays: [1, 2, 3, 4, 5]
                    });
                    response = true;
                }
                break;
            case 'custom':
                switch (this.state.repeat_every) {
                    case 'day':
                        if (this.state.ends !== '') {
                            this.setState({
                                weekdays: [0, 1, 2, 3, 4, 5, 6]
                            });
                            response = true;
                        } else {
                            this.sendMessage("Elija una fecha final o el número de repeticiones!")
                        }
                        break;
                    case 'week':
                        if (this.state.ends !== '') {
                            if (this.getWeekDaysFromChecks() !== false) {
                                this.setState({ weekdays: this.getWeekDaysFromChecks() });
                                response = true;
                            } else {
                                this.sendMessage("Elija por lo menos un día de la semana!")
                            }
                        } else {

                        }
                        break;
                    case 'month':
                        response = false;
                        break;
                    case 'year':
                        response = false;
                        break;
                    default: response = false; break;
                }
                break;
            default:
                response = false;
                break;
        }
        return response;
    }

    saveAssignedActivities = async () => {

        if (this.validating()) {
            // Guardar Asset Activity
            let data_asset_activity = {
                maintenance_activity_id: this.state.maintenance_activity,
                responsable_id: this.state.responsable,
                table_id: this.props.asset.id,
                table_name: "toll_management_assets"
            };
            let url = `/projects/${Variables.project_id}/asset_activity`;
            let asset_activity_id = -1;
            await Axios.post(__SERVER__ + url, data_asset_activity).then((response) => {
                asset_activity_id = response.data;
                // console.log(response)
            });
            // Guardar programa de mantenimiento
            /*
                * weekdays [0,1,2,3,4,5,6]
                * repeat number day[1] week[7] year[365]
            */
            let data_program = {
                asset_activity_id: asset_activity_id,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                start_time: this.state.start_time + ':00',
                end_time: this.state.end_time + ':00',
                maintenance_types_id: this.props.maintenance_types_id,
                maintenance_sections_id: this.props.maintenance_sections_id,
                weekdays: this.state.weekdays,
                repeats_number: this.state.repeats_number,
                repeat_every: this.state.repeat_every
            }

            url = `/packages/${Variables.package_id}/projects/${Variables.project_id}/victum_maintenance_program`;
            await Axios.post(__SERVER__ + url, data_program).then(response => { console.log(response) });
            // console.log("data program: ", data_program, "\ndata asset activity: ", data_asset_activity)
            //Actualizar datos de la vista

            //Cerrar modal si todo ha sido guardado
            this.handleClose();
        } else {
            // Mostrar mensaje de retroalimentación en modal
            console.log("Verificar los campos del formulario")
        }
    };

    render = () => {
        console.log("state: ", this.state);
        this.getWeekDaysFromChecks();
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.asset.description}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <center>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Responsable</Form.Label>
                                    <select className="form-control" name={"responsable"} disabled={false} onChange={this.handleChange}>
                                        {this.props.users.map((item, i) => (
                                            <option key={i} value={item.id}>
                                                {item.first_name} {item.last_name}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Actividad</Form.Label>
                                    <select className="form-control" disabled={false} name={"maintenance_activity"} onChange={this.handleChange}>
                                        {this.props.maintenance_activities.map((item, i) => (
                                            <option key={i} value={item.id}>
                                                {item.description}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Periodicidad</Form.Label>
                                    <select name={"periodicity"} className={"form-control"}
                                        defaultValue={"dontrepeat"} onChange={this.handleChange}
                                    >
                                        <option value={"dontrepeat"}>No se repite</option>
                                        <option value={"everyday"}>Cada día</option>
                                        <option value={"everyweek"}>
                                            Cada semana el{" "}
                                            {this.formatDate(this.state.start_date, "dddd")}
                                        </option>
                                        <option value={"everymonth"}>
                                            Cada mes el último{" "}
                                            {this.formatDate(this.state.start_date, "dddd")}
                                        </option>
                                        <option value={"everyyear"}>
                                            Anualmente el{" "}
                                            {this.formatDate(this.state.start_date, "DD")} de{" "}
                                            {this.formatDate(this.state.start_date, "MMMM")}
                                        </option>
                                        <option value={"lmxjv"}>De lunes a viernes</option>
                                        <option value={"custom"}>Personalizar...</option>
                                    </select>
                                </Form.Group>
                            </Form.Row>

                            {
                                this.state.periodicity !== "custom" ?
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Fecha {this.endDate() ? "inicial" : null}</Form.Label>
                                            <input name={"start_date"} type={"date"} className={"form-control"}
                                                defaultValue={this.state.start_date} onChange={this.handleChange}
                                            />
                                        </Form.Group>
                                        {
                                            this.endDate() ?
                                                <Form.Group as={Col}>
                                                    <Form.Label>Fecha final</Form.Label>
                                                    <input name={"end_date"} type={"date"} className={"form-control"}
                                                        defaultValue={this.state.end_date} onChange={this.handleChange}
                                                    />
                                                </Form.Group> : null
                                        }
                                        <Form.Group as={Col}>
                                            <Form.Label>Hora inicial</Form.Label>
                                            <input type={"time"} name={"start_time"} defaultValue={this.state.start_time}
                                                className={"form-control"} onChange={this.handleChange} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Hora final</Form.Label>
                                            <input type={"time"} name={"end_time"} defaultValue={this.state.end_time}
                                                className={"form-control"} onChange={this.handleChange} />
                                        </Form.Group>
                                    </Form.Row>
                                    :
                                    <>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Repetir cada</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <input type={"number"} name={"repeats_number"} className={"form-control"}
                                                    defaultValue={this.state.repeats_number} min={1} onChange={this.handleChange} />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <select name={"repeat_every"} className={"form-control"}
                                                    onChange={this.handleChange} defaultValue={this.state.repeat_every}>
                                                    <option value={"day"}>Día</option>
                                                    <option value={"week"}>Semana</option>
                                                    <option value={"month"}>Mes</option>
                                                    <option value={"year"}>Año</option>
                                                </select>
                                            </Form.Group>
                                        </Form.Row>

                                        {
                                            this.state.repeat_every === "week" ?
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Se repite el</Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg="8">
                                                        <Form.Check inline label="dom" name={"sunday"} checked={this.state.checks.sunday} type={"checkbox"} id={`inline-chechbox-1`} onChange={this.handleChangeCheckBox} />
                                                        <Form.Check inline label="lun" name={"monday"} checked={this.state.checks.monday} type={"checkbox"} id={`inline-checkbox-2`} onChange={this.handleChangeCheckBox} />
                                                        <Form.Check inline label="mar" name={"tuesday"} checked={this.state.checks.tuesday} type={"checkbox"} id={`inline-checkbox-3`} onChange={this.handleChangeCheckBox} />
                                                        <Form.Check inline label="mié" name={"wednesday"} checked={this.state.checks.wednesday} type={"checkbox"} id={`inline-checkbox-4`} onChange={this.handleChangeCheckBox} />
                                                        <Form.Check inline label="jue" name={"thursday"} checked={this.state.checks.thursday} type={"checkbox"} id={`inline-checkbox-5`} onChange={this.handleChangeCheckBox} />
                                                        <Form.Check inline label="vie" name={"friday"} checked={this.state.checks.friday} type={"checkbox"} id={`inline-checkbox-6`} onChange={this.handleChangeCheckBox} />
                                                        <Form.Check inline label="sáb" name={"saturday"} checked={this.state.checks.saturday} type={"checkbox"} id={`inline-checkbox-7`} onChange={this.handleChangeCheckBox} />
                                                    </Form.Group>
                                                </Form.Row> : null
                                        }

                                        <Form.Row>
                                            <Form.Group as={Col} lg={4}>
                                                <Form.Label>Horario</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Hora inicial</Form.Label>
                                                <input type={"time"} name={"start_time"} defaultValue={this.state.start_time}
                                                    className={"form-control"} onChange={this.handleChange} />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Hora final</Form.Label>
                                                <input type={"time"} name={"end_time"} defaultValue={this.state.end_time}
                                                    className={"form-control"} onChange={this.handleChange} />
                                            </Form.Group>
                                        </Form.Row>
                                        {
                                            this.state.repeat_every === "month" ?
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={4}>

                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={8}>
                                                        <select disabled={true} className={"form-control"}>
                                                            <option>Cada mes el dia {this.formatDate(this.state.start_date, "DD")}</option>
                                                        </select>
                                                    </Form.Group>
                                                </Form.Row> : null
                                        }

                                        {
                                            this.state.repeat_every === "year" ?
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={4}>

                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={8}>
                                                        <select disabled={true} className={"form-control"}>
                                                            <option>Cada año el dia {this.formatDate(this.state.start_date, "DD")}</option>
                                                        </select>
                                                    </Form.Group>
                                                </Form.Row> : null
                                        }


                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Termina</Form.Label>
                                            </Form.Group>

                                            <Form.Group as={Col} lg="8">
                                                <div>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Form.Check type={"radio"} name={"ends"} value={"until"} onChange={this.handleChange} />
                                                        </InputGroup.Prepend>
                                                        <input type="text" name="" id="" className={"form-control"} value={"Hasta la fecha"} readOnly={true} />
                                                        <input type="date" name="end_date" id="" className={"form-control"} onChange={this.handleChange}
                                                            defaultValue={this.state.end_date} disabled={this.state.ends === "until" ? false : true} />
                                                    </InputGroup>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <Form.Check type={"radio"} name={"ends"} value={"repeats"} onChange={this.handleChange} />
                                                        </InputGroup.Prepend>
                                                        <input type="text" name="" id="" className={"form-control"} value={"Después de"} readOnly={true} />
                                                        <input type="number" name="" id="" className={"form-control"} min={1}
                                                            defaultValue={1} disabled={this.state.ends === "repeats" ? false : true} />
                                                        <input type="text" name="" id="" className={"form-control"} value={"Repeticiones"} readOnly={true} />
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>

                                        </Form.Row>
                                    </>
                            }
                            {
                                this.state.show_message ?
                                    <Container fluid>
                                        <Row>
                                            <Col>
                                                <Message
                                                    title={this.state.title}
                                                    content={this.state.content}
                                                    type_msg={this.state.type_msg}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                    : null
                            }

                        </Form>
                    </center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Cerrar
          </Button>
                    <Button variant="success" onClick={this.saveAssignedActivities}>
                        Asignar actividad
          </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default AssignedActivities;
