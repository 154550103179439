import React, { Fragment, useEffect, useRef, useState } from 'react';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal';

import TabsComp from 'components/layouts/Tabs';
import InventoryDetails from './InventoryDetails';
import InventoryFiles from './InventoryFiles';
import { destroyInventoryFiles, indexInventoryFiles, uploadInventoryFiles } from 'api/maintenance_standards/inventory_files';
import InventoryPhotos from './InventoryPhotos';
import InventoryVideos from './InventoryVideos';
import InventoryAudios from './InventoryAudios';
import Swal from 'sweetalert2';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import InventoryMap from './InventoryMap';

const ModalDetailsInventory = (props) => {
    const {
        onClose,
        itemSel,
        items,
        labelExtra,
        project_id,
        table,
        getInventoryData
    } = props;

    const childRef = useRef(null);
    const user_id = useSelector(state => state.login.dataUser.id);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [buttonUpdate, setButtonUpdate] = useState(false);

    useEffect(() => {
        getFiles();
    }, []);

    const getFiles = async () => {
        setLoading(true);
        let response = await indexInventoryFiles({ project_id, table: `${table}_files`, inventory_data_id: itemSel.id });
        setData(response.data);
        setLoading(false);
    }

    const deleteFile = async (item) => {
        const { file_path, inventory_data_id } = item;
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Al aceptar esta operación se eliminará el archivo de la base de datos. ¡Esta operación es irreversible!.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await destroyInventoryFiles({
                    project_id, table: `${table}_files`,
                    id: inventory_data_id, file_path
                });
                if (response) {
                    getFiles();
                    getInventoryData();
                    Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
                } else
                    Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
            }
        })
    }

    // Método para almacenar archivos en los elementos del almacén
    const storeFile = async ({ file_type, data }) => {
        let response = await uploadInventoryFiles({
            project_id, table: `${table}_files`, data,
            id: itemSel.id, file_type, user_id
        });
        if (response) {
            getFiles();
            getInventoryData();
            Swal.fire('¡Operación exitosa!', 'El archivo ha sido agregado.', 'success')
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de agregar el archivo.', 'error')
        }
    }

    const onUpdate = () => {
        childRef.current.updateData();
    }

    return (
        <ModalComp
            title='Detalles del registro'
            size='lg'
            onClose={onClose}
            body={<Fragment>
                {
                    loading ? <Skeleton active /> :
                        <TabsComp
                            tabColor='black'
                            tablist={['Información general', 'Ubicación', 'Fotografías', 'Documentos (PDF)', 'Videos', 'Audios']}
                            tabpanels={[
                                <InventoryDetails
                                    ref={childRef}
                                    key='tab_details'
                                    data={itemSel}
                                    items={items}
                                    labelExtra={labelExtra}
                                    setButtonUpdate={setButtonUpdate}
                                    project_id={project_id}
                                    table={table}
                                    getInventoryData={getInventoryData}
                                    onClose={onClose}
                                />,
                                <InventoryMap
                                    key='tab_map'
                                    data={itemSel}
                                    items={items}
                                    labelExtra={labelExtra}
                                />,
                                <InventoryPhotos
                                    key='tab_photos'
                                    data={data}
                                    project_id={project_id}
                                    table={table}
                                    deleteFile={deleteFile}
                                    storeFile={storeFile}
                                />,
                                <InventoryFiles
                                    key='tab_files'
                                    project_id={project_id}
                                    table={table}
                                    data={data}
                                    loading={loading}
                                    deleteFile={deleteFile}
                                    storeFile={storeFile}
                                />,
                                <InventoryVideos
                                    key='tab_videos'
                                    project_id={project_id}
                                    table={table}
                                    data={data}
                                    loading={loading}
                                    deleteFile={deleteFile}
                                    storeFile={storeFile}
                                />,
                                <InventoryAudios
                                    key='tab_audios'
                                    project_id={project_id}
                                    table={table}
                                    data={data}
                                    deleteFile={deleteFile}
                                    storeFile={storeFile}
                                />
                            ]}
                        />
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faTimes} name='Cerrar' onClick={onClose} variant='outline-secondary' />
                {
                    buttonUpdate &&
                    <ButtonIcon icon={faSave} name='Actualizar datos' onClick={onUpdate} variant='outline-success' />
                }
            </Fragment>}
        />
    )
}

export default ModalDetailsInventory