import { gql } from "@apollo/client";

export const NEW_MESSAGE = gql`
  subscription NewMessage($user_id: Int) {
    messenger_messages(
      order_by: { id: desc }
      limit: 1
      where: { user_id: { _eq: $user_id } }
    ) {
      id
      message
    }
  }
`;

export const NEW_MESSAGE_IN_ROOMS = gql`
  subscription NewMessageInRooms($_in: [Int!]) {
    messenger_messages(
      where: { room_id: { _in: $_in } }
      limit: 1
      order_by: { id: desc }
    ) {
      id
      message
      user_id
      room_id
      created_at
    }
  }
`;

export const NEW_MESSAGE_IN_ROOMS_TIMETZ = gql`
  subscription NewMessageInRooms($_in: [Int!], $_gt: timestamptz) {
    messenger_messages(
      where: { room_id: { _in: $_in }, created_at:{_gt: $_gt} }
      limit: 1
      order_by: { id: desc }
    ) {
      id
      message
      user_id
      room_id
      created_at
    }
  }
`;
