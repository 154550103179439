import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import TabsComp from './layouts/Tabs';

const Ayuda = () => {
	const package_id = useSelector(state => state.login.package_id);
	return (
		<section className="body">
			<div style={{ width: '100%', height: '100vh' }} >
				<TabsComp
					tabColor=''
					tablist={['Manual', 'Instalar Apps Moviles', 'Levantar incidentes (móvil)', 'Levantar incidentes (web)', 'Matriz de Precios Unitarios']}
					tabpanels={[
						<>
							<div style={{ width: '100%' }}>
								{
									// Refactorizar para que se obtenga el enlace de la base de datos
									package_id === 12 && <Fragment>
										<div style={{ position: 'relative', paddingBottom: '56.25%', paddingTop: 0, height: 0 }}>
											<iframe title="INSTRUCTIVO PORTAL DE SUPERVISIÓN Y APLICACIONES MÓVILES"
												frameborder="0"
												width="1200px"
												height="675px"
												style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
												src="https://view.genial.ly/63716381a3ff9a001114b449"
												type="text/html"
												allowscriptaccess="always"
												allowfullscreen="true"
												scrolling="yes"
												allownetworking="all">
											</iframe>
										</div>
									</Fragment>
								}
								{
									// Refactorizar para que se obtenga el enlace de la base de datos
									package_id === 14 &&
									<div style={{ position: 'relative', paddingBottom: '56.25%', paddingTop: 0, height: 0 }}>
										<iframe title="INSTRUCTIVO PORTAL DE SUPERVISIÓN Y APLICACIONES MÓVILES"
											frameborder="0"
											width="1200px"
											height="675px"
											style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
											src="https://view.genial.ly/64fb23b81bf760001283f170"
											type="text/html"
											allowscriptaccess="always"
											allowfullscreen="true"
											scrolling="yes"
											allownetworking="all">
										</iframe>
									</div>
								}
							</div>
						</>,
						<div style={{ position: 'relative', width: '100%', height: '95vh' }}>
							<iframe allowFullScreen frameborder="0" style={{ border: 'none', width: '100%', height: '100%' }} src="https://mega.nz/embed/kXxhESCY#l-CFBw1swh2Jz4maYknuZ0H4VPnAwFSdxN-4JbpUb64" ></iframe>
						</div>,
						<div style={{ position: 'relative', width: '100%', height: '95vh' }}>
							<iframe allowFullScreen frameborder="0" style={{ border: 'none', width: '100%', height: '100%' }} src="https://mega.nz/embed/cLZE1CrJ#2W0EO5UT5bQymA-PFUoQ8L0c0b3XWllvwcT5TZqDtng" ></iframe>
						</div>,
						<div style={{ position: 'relative', width: '100%', height: '95vh' }}>
							<iframe allowFullScreen frameborder="0" style={{ border: 'none', width: '100%', height: '100%' }} src="https://mega.nz/embed/ZTJRhCha#BevvyLJWzH1tUfMhFn_dusCg9FZy1M0CkAacQAj2ehg" ></iframe>
						</div>,
						<>
							{
								package_id === 12 &&
								<div style={{ position: 'relative', width: '100%', height: '95vh' }}>
									<iframe allowFullScreen frameborder="0" style={{ border: 'none', width: '100%', height: '100%' }} src="https://mega.nz/embed/8Oom3R7L#nQle5zT20b_v-b5NxDvJMx3FsKjkwnnOOnyJOzT9DPU" ></iframe>
								</div>
							}
						</>
					]}
				/>
			</div>
		</section>
	)
}

export default Ayuda;