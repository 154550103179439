import React, { Component, useEffect, useState } from 'react';
import ReactExport from "react-export-excel";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import './Operacion.css';
import './Resultados.css';
import Pagination from '../Pagination';
import { __SERVER__ } from '../../../../server/info';
import { Variables } from '../../../../variables/Variables';
import { Dropdown, Spinner } from 'react-bootstrap';
import { dateUTC } from '../../../../utilidades/dates';
import ModalUpload from '../Modal.jsx';
import Deductiva from '../Deductiva.jsx';
import Axios from 'axios';
// import Reports from './UOV-01/Reports.jsx';
import { Heading } from '@chakra-ui/react';
import FlexButton from 'components/layouts/FlexButton';
import { faCarCrash, faChartLine, faFileExcel, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import DownloadXlsxModal from './UOV-01/modals/DownloadXlsxModal';
import { getCurrency } from 'components/libs/functions';
import TableComp from 'components/layouts/Table';
import PaginationComp from 'components/layouts/Pagination';
import { indexMovementCapacity } from 'api/operation_standards/movement_capacity';
// import ReportsComp from './UOV-01/fragments/ReportsComp.jsx';

const _ = require('underscore');

const MySwal = withReactContent(Swal);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const FiltroBusqueda = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				capacity_dateS: "2019-11-01",
				capacity_dateE: "2023-11-02",
				shift: 0,
				tollbooth_id: 0,
				lane_id: 0,
				payment_type_id: 0,
				vehicle_type_id: 0
			},
			headers: {},
			query_: "",
			tollbooths: [],
			lanes: [],
			payment_types: [],
			vehicle_types: [],
			click: false,
			refresh: false,
			headersTable: {}
		};
	}

	generateQuery = () => {
		if (this.state.query.capacity_dateE === "" || this.state.query.capacity_dateS === "") {
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = {};
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "0") {
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
				headersTable[keys_query[i]] = value_query[i]
			}
		}
		this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
		this.state.headersTable = headersTable;
		this.setState({ refresh: !this.state.refresh });
		return headersTable;
	}

	componentDidMount = () => {
		this.getTollbooths()
		this.getPaymentTypes()
		this.getVehicleTypes()
	}

	getLanes = async () => {
		if (this.state.query.tollbooth_id === "") {
			let lanes = []
			this.setState({ lanes: lanes })
			return;
		}
		fetch(__SERVER__ + "/projects/tollbooths/" + this.state.query.tollbooth_id + "/lanes", {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(res => {
			let lanes = []
			if (res !== null) {
				lanes.push(<option value={0} key={'L'}></option>)
				for (let i = 0; i < res.length; i++) {
					lanes.push(<option value={res[i].id} key={'L' + i}>{res[i].name}</option>)
				}
			}
			this.setState({ lanes: lanes })
		}).catch(e => {
			this.getLanes()
		})
	}

	getPaymentTypes = async () => {
		fetch(__SERVER__ + "/packages/" + Variables.package_id + "/payment_types", {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(res => {
			let payment_types = []
			if (res !== null) {
				payment_types.push(<option value={0} key={'P'}></option>)
				for (let i = 0; i < res.length; i++) {
					payment_types.push(<option value={res[i].id} key={'P' + i}>{res[i].common_name}</option>)
				}
			}
			this.setState({ payment_types: payment_types })
		}).catch(e => {
			this.getLanes()
		})
	}

	getVehicleTypes = async () => {
		fetch(__SERVER__ + "/packages/" + Variables.package_id + "/vehicle_types", {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(res => {
			let vehicle_types = []
			if (res !== null) {
				vehicle_types.push(<option value={0} key={'V'}></option>)
				for (let i = 0; i < res.length; i++) {
					vehicle_types.push(<option value={res[i].id} key={'V' + i}>{res[i].code + " - " + res[i].name}</option>)
				}
			}
			this.setState({ vehicle_types: vehicle_types })
		}).catch(e => {
			this.getLanes()
		})
	}

	getTollbooths = async () => {
		fetch(__SERVER__ + "/projects/" + this.props.project_id + "/tollbooths", {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(res => {
			let tollbooths = []
			if (res !== null) {
				tollbooths.push(<option value={0} key={'T'}></option>)
				for (let i = 0; i < res.length; i++) {
					tollbooths.push(<option value={res[i].id} key={'T' + i}>{res[i].code}</option>)
				}
			}
			this.getLanes()
			this.setState({ tollbooths: tollbooths })
		}).catch(e => {
			this.getTollbooths()
		})
	}

	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="capacity_dateS" value={this.state.query.capacity_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="capacity_dateE" value={this.state.query.capacity_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<label>Turno</label>
					<select name="shift" onChange={this.changeInput}>
						<option value="0"></option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
					</select>
				</article>
				<article>
					<label>Plaza</label>
					<select name="tollbooth_id" onChange={(e) => { this.changeInput(e); this.getLanes(); }}>
						{this.state.tollbooths.map(item => item)}
					</select>
				</article>
				<article>
					<label>Carril</label>
					<select name="lane_id" onChange={this.changeInput}>
						{this.state.lanes.map(item => item)}
					</select>
				</article>
				<article>
					<label>Tipo de pago</label>
					<select name="payment_type_id" onChange={this.changeInput}>
						{this.state.payment_types.map(item => item)}
					</select>
				</article>
				<article>
					<label>Tipo de vehículo</label>
					<select name="vehicle_type_id" onChange={this.changeInput}>
						{this.state.vehicle_types.map(item => item)}
					</select>
				</article>
				<article>
					<button className="botonBusqueda" onClick={() => {

						let headersTable = this.generateQuery();
						this.state.headersTable = headersTable;
						if (this.state.click) {
							this.setState({ click: false }, () => this.setState({ click: true }));
						} else {
							this.setState({ click: true });
						}
						this.props.setProps(this.state.query_, headersTable)
					}}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
				<article style={{ padding: 10 }}>
					{
						this.state.click ?
							<Dropdown>
								<Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ backgroundColor: '#2d4050', border: 'none' }}>Descargar</Dropdown.Toggle>
								<Dropdown.Menu>
									<DownloadExcel query={this.state.query_} headersTable={this.state.headersTable} />
								</Dropdown.Menu>
							</Dropdown>
							: null
					}
				</article>
			</section>
		);
	}
}

const Grid = class extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.data_per_page}</label>
				</section>
				<table>
					<thead>
						<tr>
							<th>Fecha</th>
							<th>Plaza</th>
							<th>Turno</th>
							<th>Carril</th>
							<th>Tipo de pago</th>
							<th>Tipo de vehículo</th>
							<th>Aforo</th>
							<th>Ingreso</th>
						</tr>
					</thead>
					<tbody>

						{
							this.props.data.data.length > 0 ?
								this.props.data.data.map((item, i) => <tr key={i}>
									<td>{item.capacity_date.split('T')[0]}</td>
									<td>{item.tollbooth === undefined ? null : item.tollbooth.code}</td>
									<td>{item.shift}</td>
									<td>{item.lane === undefined ? null : item.lane.name}</td>
									<td>{item.payment_type === undefined ? null : item.payment_type.common_name}</td>
									<td>{item.vehicle === undefined ? null : item.vehicle.code}</td>
									<td style={{ textAlign: 'end', paddingRight: 10 }}>{item.count.toLocaleString()}</td>
									<td style={{ textAlign: 'end', paddingRight: 10 }}>{getCurrency(item.income)}</td>
								</tr>)
								: <h3>No hay datos para mostrar</h3>
						}
					</tbody>
				</table>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
			</div>
		);
	}
}

// Reemplazar el antiguo grid con este
const GridTable = ({ params }) => {
	const [pagination, setPagination] = useState({
		data: [],
		loading: true,
		totalItems: 0,
		page: 1,
		pageSize: 20
	});

	useEffect(() => {
		const getData = async () => {
			const { page, pageSize } = pagination;
			const { project_id, package_id } = Variables;
			setPagination(prevState => ({ ...prevState, loading: true }));
			const { data, totalItems } = await indexMovementCapacity({ package_id, project_id, page, pageSize, ...params });
			setPagination(prevState => ({ ...prevState, data, totalItems, loading: false }));
		}
		getData();
	}, [pagination.page, pagination.pageSize]);


	const changePage = (page, pageSize) => setPagination(prevState => ({ ...prevState, page, pageSize }));

	return (<>
		<TableComp
			headers={['Fecha', 'Plaza', 'Turno', 'Carril', 'Tipo de Pago', 'Tipo de vehículo', 'Aforo', 'Ingreso', '']}
			keys={['capacity_date', 'tollbooth.code', 'shift', 'lane.name', 'payment_type.name', 'vehicle_type.code', 'count', 'income']}
			body={pagination.data}
			page={pagination.page}
			currency={[{ index: 7 }]}
			moment_dates={[{ index: 0, format: 'L' }]}
			loadingItems={pagination.pageSize}
			loading={pagination.loading}
			actions={[]}
		/>
		<PaginationComp
			totalItems={pagination.totalItems}
			current={pagination.page}
			defaultPageSize={pagination.pageSize}
			onChange={changePage}
		/>
	</>);
}
export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalUploadData: false,
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			refresh: false,
			element: null,
			page: 1,
			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false,
			buttonVisible: false,
			//Variables de estado para modal reportes
			modalReports: false,
			spinner: false,
			modalXlsx: false
		}
	}

	generateTable = () => {
		let data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];
		this.setState({
			grid: null
		}, () => {
			if (data !== undefined) {
				this.setState({
					grid:
						<Grid
							updatePage={this.updatePage}
							project_id={this.props.project_id}
							data={data}
							headersTable={this.state.headersTable}
						/>
				});
			}
		})
	}

	updatePage = (page) => {
		this.setState({ page }, this.getMovementCapacity);
	}

	getMovementCapacity = async () => {
		this.setState({ spinner: true });
		let index = _.findIndex(this.state.result, { page: this.state.page });
		if (index !== -1) {
			this.generateTable();
			this.setState({ spinner: false });
			return;
		}
		let query = this.state.query + (this.state.query === '' ? `?page=${this.state.page}` : `&page=${this.state.page}`);
		await fetch(__SERVER__ + "/packages/" + Variables.package_id + "/projects/" + this.props.project_id + "/movement_capacity" + query, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					this.state.result.push(result);
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			Swal.fire('Error', e, 'error');
		})
		this.setState({ spinner: false });

	}

	setProps = (query, headersTable) => {
		this.setState({ page: 1, query: query, result: [], headersTable: headersTable }, this.getMovementCapacity)
	}

	setPropsReport = (query, headersTable) => {
		this.setState({ query: query, headersTable: headersTable }, this.getReport);
	}



	componentDidMount = async () => {
		CODE = this.props.code;
		// GET operating_indicator_id
		let res = await Axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')

		if (res.data.length !== 0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	openOrCloseModal = () => {
		this.setState(prevState => ({ modalUploadData: !prevState.modalUploadData }));
	}

	openOrCloseModalDeductivas = () => {
		this.setState(prevState => ({ modalDeductivas: !prevState.modalDeductivas }));
	}

	// openOrCloseModalReports = (state) => {
	// 	this.setState({ modalReports: state });
	// }

	render() {
		return (
			<div className="body_">
				<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
				<FiltroBusqueda setProps={this.setProps} project_id={this.props.project_id} setPropsReport={this.setPropsReport} />
				{this.state.element}
				{
					this.state.spinner ? <Spinner animation="border" /> : this.state.grid
				}
				{/* <FlexButton style={{ right: 10, bottom: 170 }} icon={faChartLine} onClick={() => this.openOrCloseModalReports(true)} /> */}
				{
					this.state.buttonVisible &&
					<FlexButton style={{ right: 10, bottom: 65 }} icon={faCarCrash} onClick={this.openOrCloseModalDeductivas} />
				}
				{/* <FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} /> */}
				{/* Cargar datos desde XLSX */}
				<FlexButton style={{ right: 10, bottom: 10 }} icon={faFileUpload} onClick={this.openOrCloseModal} />
				{/* Descargar XLSX */}
				<FlexButton style={{ right: 10, bottom: 120 }} icon={faFileExcel} onClick={() => this.setState({ modalXlsx: true })} />
				{
					this.state.modalUploadData ?
						<ModalUpload
							server={`${__SERVER__}/packages/${Variables.package_id}/projects/${Variables.project_id}/movement_capacity`}

							items={[
								{ label: 'DIA AUTOCONTROL', description: 'Fecha', value: 'capacity_date', type: 'String', toFormat: 'Date' },
								{ label: 'PLAZA DE COBRO', description: 'Código de la Plaza de cobro', value: 'tollbooth', type: 'String' },
								{ label: 'CLASE', description: 'Nombre del tipo de pago', value: 'payment_type', type: 'String' },
								{ label: 'TURNO', description: 'Turno', value: 'shift', type: 'Number', allow: null },
								{ label: 'CARRIL', description: 'Nombre del Carril', value: 'lane', type: 'String' },
								{ label: 'CODE', description: 'Código del vehiculo', value: 'vehicle_type', type: 'String' },
								{ label: 'AFORO', description: 'Total de Aforo', value: 'count', type: 'Number' },
								{ label: 'INGRESO', description: 'Total de Ingreso', value: 'income', type: 'Number' }
							]}
							title={`${this.props.code} ${this.props.name}`}
							onClose={this.openOrCloseModal} />
						: null
				}
				{/* Modal Deductivas */}
				{
					this.state.modalDeductivas ?
						<Deductiva
							title={this.props.code}
							pum={this.state.PUMObject}
							cump={this.state.CumplObject}
							formulaCump={this.state.formulaCump}
							formulaPUM={this.state.formulaPUM}
							hayFormula={this.state.hayFormula}
							onClose={this.openOrCloseModalDeductivas}
						/> : null
				}
				{/* Modal para generar reportes -- temporalmente deshabilitado hasta corregir los errores de librería*/}
				{
					// this.state.modalReports && <ReportsComp
					// 	onClose={() => this.openOrCloseModalReports(false)}
					// />
					// <Reports
					// 	title={this.props.code}
					// 	project_id={this.props.project_id}
					// 	movementCapacity={this.state.result}
					// 	onClose={() => this.openOrCloseModalReports(false)}
					// /> : null
				}
				{/* Reporte de Auditoria Operativa en XLSX */}
				{
					this.state.modalXlsx && <DownloadXlsxModal
						onClose={() => this.setState({ modalXlsx: false })}
						project_id={this.props.project_id}
					/>
				}
			</div>
		);
	}
}

let CODE = "";
let EXCEL_HEADER_COLUMNS_NAME = [];
let EXCEL_HEADER = [];
let EXCEL_BODY_COLUMNS_NAME = [];
let EXCEL_BODY = [];
let EXCEL = [];


class DownloadExcel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			dataSet: []
		}
	}

	componentDidMount = () => {
		EXCEL = [];
		EXCEL_BODY = [];
		EXCEL_HEADER = [];
		EXCEL_BODY_COLUMNS_NAME = [];
		EXCEL_HEADER_COLUMNS_NAME = [];
		fetch(__SERVER__ + "/packages/" + Variables.package_id + "/projects/" + Variables.project_id + "/movement_capacity_report" + this.props.query, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(async (res) => {
			if (res !== null) {
				//let column = []
				if (res[1][0].count !== null) {
					/*
					Cabecera
					*/
					EXCEL_HEADER_COLUMNS_NAME = ['Autopista', 'Guadalara - Colima'];
					EXCEL_HEADER.push(['Fecha', this.props.headersTable.capacity_dateS + ' - ' + this.props.headersTable.capacity_dateE]);
					if (res[0].tollbooth !== undefined) {
						EXCEL_HEADER.push(['Caseta', res[0].tollbooth.code])
					}

					if (res[0].lane !== undefined) {
						EXCEL_HEADER.push(['Carril', res[0].lane.name])
					}

					if (res[0].shift !== undefined) {
						EXCEL_HEADER.push(['Turno', res[0].shift])
					}

					if (res[0].vehicle !== undefined) {
						EXCEL_HEADER.push(['Tipo de vehiculo', res[0].vehicle.name])
					}

					if (res[0].payment_type !== undefined) {
						EXCEL_HEADER.push(['Forma de pago', res[0].payment_type.name])
					}

					EXCEL.push({
						columns: EXCEL_HEADER_COLUMNS_NAME,
						data: EXCEL_HEADER
					})

					/**
					COLUMNS_NAME
					*/
					if (res[1][0].tollbooth !== undefined) {
						EXCEL_BODY_COLUMNS_NAME.push('Plaza')
					}

					if (res[1][0].lane !== undefined) {
						EXCEL_BODY_COLUMNS_NAME.push('Carril')
					}

					if (res[1][0].shift !== undefined) {
						EXCEL_BODY_COLUMNS_NAME.push('Turno')
					}

					if (res[1][0].vehicle !== undefined) {
						EXCEL_BODY_COLUMNS_NAME.push('Vehiculo')
					}

					if (res[1][0].payment_type !== undefined) {
						EXCEL_BODY_COLUMNS_NAME.push('Pago')
					}

					EXCEL_BODY_COLUMNS_NAME.push('Aforo')
					EXCEL_BODY_COLUMNS_NAME.push('Ingreso')

					let total_capacity = 0;
					let total_income = 0;

					for (let i = 0; i < res[1].length; i++) {
						let row = [];
						if (res[1][i].tollbooth !== undefined) {
							row.push(res[1][i].tollbooth.code)
						}

						if (res[1][i].lane !== undefined) {
							row.push(res[1][i].lane.name)
						}

						if (res[1][i].shift !== undefined) {
							row.push(res[1][i].shift)
						}

						if (res[1][0].vehicle !== undefined) {
							row.push(res[1][i].vehicle.code)
						}

						if (res[1][0].payment_type !== undefined) {
							row.push(res[1][i].payment_type.name)
						}
						row.push(res[1][i].count)
						row.push(res[1][i].income)

						total_capacity += res[1][i].count;
						total_income += res[1][i].income;

						EXCEL_BODY.push(row);
					}


					if (EXCEL_BODY_COLUMNS_NAME.length !== 2) {
						let row = new Array(EXCEL_BODY_COLUMNS_NAME.length);
						row[row.length - 1] = total_income;
						row[row.length - 2] = total_capacity;
						row[row.length - 3] = 'Total';
						EXCEL_BODY.push(row);
					}


					EXCEL.push({
						xSteps: 0,
						ySteps: 3,
						columns: EXCEL_BODY_COLUMNS_NAME,
						data: EXCEL_BODY
					});
					this.setState({ dataSet: EXCEL, name: EXCEL_HEADER_COLUMNS_NAME[1] });
				} else {
					console.log('No hay datos con respecto a esa consulta')
				}
			} else {
				console.log('Error', 'Null')
			}
		}).catch(e => {
			console.log('Error', e)
		})

	}

	render() {
		let date = dateUTC(new Date()).toISOString();
		date = date.split('-').join('');
		date = date.split(':').join('');
		date = date.split('.').join('');
		date = date.split('Z').join('');
		date = date.split('T').join('');
		return (
			this.state.dataSet.length !== 0 ?
				<ExcelFile filename={`${CODE}_${date}`} element={<Dropdown.Item eventKey="1">Excel</Dropdown.Item>}>
					<ExcelSheet dataSet={this.state.dataSet} name={this.state.name} />
				</ExcelFile>
				: <Dropdown.Item style={{ cursor: 'progress' }} eventKey="1">Excel...</Dropdown.Item>
		);
	}
}