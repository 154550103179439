import React from 'react';
import { Variables } from '../../../../variables/Variables';
import { __SERVER__ } from '../../../../server/info';
import PubSub from 'pubsub-js';
import { __BUSCAR_INICIDENTES__, __BUSCAR_DEDUCTIVAS__ } from '../../../../utilidades/events_name';
import { ___INCIDENTES__, ___DEDUCTIVAS__, ___INVENTARIO__ } from './constantes/Interfaces';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: [],
            segments: [],
            standards: [],
            query: {
                status_id: "",
                standard_id: this.props.standard_id,
                segment_id: "",
                creation_dateS: "",
                creation_dateE: ""
            },
            refresh: false,
        }
    }

    sendQuery = () => {
        if (this.props.type === ___INCIDENTES__) {
            let keys = Object.keys(this.state.query);
            let values = Object.values(this.state.query);
            let query = "";
            for (let i = 0; i < keys.length; i++) {
                if (values[i] !== "") {
                    if (query === "") {
                        query = "?";
                    } else {
                        query += "&";
                    }
                    if (keys[i] === 'creation_dateS') {
                        values[i] += 'T00:00:00';
                    }
                    if (keys[i] === 'creation_dateE') {
                        values[i] += 'T23:59:59';
                    }
                    query += keys[i] + "=" + values[i];
                }
            } 
            PubSub.publish(__BUSCAR_INICIDENTES__, this.state.query);
        } else if(this.props.type === ___DEDUCTIVAS__) {
            if(this.state.query.segment_id === ""){
                if(this.state.segments.length !== 0){
                    this.state.query.segment_id = this.state.segments[0].id;
                }else{
                    alert("Debe seleccionar un segmento");
                    return;
                }
            }
            let segment_id = this.state.query.segment_id;
            let query = "?standard_id=" + this.props.standard_id;
            query += this.state.query.creation_dateS !== "" ? '&application_dateS=' + this.state.query.creation_dateS + 'T00:00:00' : '';
            query += this.state.query.creation_dateE !== "" ? '&application_dateE=' + this.state.query.creation_dateE + 'T23:59:59' : '';
            PubSub.publish(__BUSCAR_DEDUCTIVAS__, {segment_id, query: query});
        }
    }



    componentDidMount = () => {
        this.getStatus();
        this.getSegments();
    }

    getSegments = () => {
        fetch(__SERVER__ + '/projects/' + Variables.project_id + '/segments', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                this.setState({ segments: res });
            }
        });
    }

    getStatus = () => {
        fetch(__SERVER__ + '/status', {
            method: 'GET',
            headers: {
                'Accept': ' application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                return null;
            }
        }).then(res => {
            if (res !== null) {
                let selectElement = []
                selectElement.push(<option key={'C'} value={""}></option>);
                let data = res;
                for (let i = 0; i < data.length; i++) {
                    selectElement.push(<option key={'C' + i} value={data[i].id}>{data[i].name[0].toUpperCase() + data[i].name.slice(1).toLowerCase()}</option>);
                }
                this.setState({ status: selectElement });
            }
        }).catch(e => {
            console.log(e);
        });
    }



    render = () => {
        return (
            <section className="sectionSearch">
                {
                    this.props.type === ___INCIDENTES__?
                        <article>
                            <label>Estado</label>
                            <select onChange={(e) => {
                                this.state.query.status_id = e.target.value
                            }}>
                                {this.state.status.map(item => item)}
                            </select>
                        </article>
                        : null
                }
                <article>
                    <label>Segmento</label>
                    <select onChange={(e) => {
                        this.state.query.segment_id = e.target.value
                    }}>
                        {this.props.type === ___INCIDENTES__?
                            <option key={'SEG'} value={""}></option>
                            : null
                        }
                        {this.state.segments.map((item, i) =>
                            <option key={'SEG' + i} value={item.id}>{item.name + ": " + item.start_km + " - " + item.end_km}</option>
                        )}
                    </select>
                </article>
                <article>
                    <label>Fecha inicio</label>
                    <input onChange={(e) => {
                        this.state.query.creation_dateS = e.target.value
                    }} type="date" />
                </article>
                <article>
                    <label>Fecha fin</label>
                    <input onChange={(e) => {
                        this.state.query.creation_dateE = e.target.value
                    }} type="date" />
                </article>
                <article>
                    <button className="botonBusqueda" onClick={this.sendQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
                </article>
            </section>
        );
    }
}