import React from 'react';

const Filters = ({ searchStr, setFilters, filters }) => {

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevState => ({ ...prevState, [name]: value }));
    }
    return (
        <section className="sectionSearch">
            <article>
                <label className='form-label'>Tipo</label>
                <select name='type_description_id' onChange={handleChange} value={filters?.type_description_id}>
                    <option value={-1}>Todos</option>
                    <option value={1}>Materiales</option>
                    <option value={2}>Mano de Obra</option>
                    <option value={3}>Herramienta</option>
                    <option value={4}>Equipo</option>
                </select>
            </article>
            <article>
                <label>Buscar descripción</label>
                <input
                    className='form-control'
                    name='str'
                    placeholder={'Buscar Descripción'}
                    value={filters?.str}
                    onChange={handleChange}
                />
            </article>
            <article>
                <button className="botonBusqueda" onClick={searchStr}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
            </article>
        </section >
    )
}

export default Filters