import moment from 'moment';
import React from 'react'

const Details = (props) => {
    const { dataDetails, loading } = props;
    const {
        activity, responsable_name, asset_name, status, start_at,
        end_at, started_at, ended_at, start_comment, end_comment
    } = dataDetails;
    // console.log("🚀 ~ file: Details.jsx:5 ~ Details ~ dataDetails", dataDetails)
    return (
        <>
            {
                !loading &&
                <div>
                    <h4>{activity?.code} - {activity?.name}</h4>
                    <label><strong>Responsable: </strong>{responsable_name}</label><br />
                    <label><strong>Bien: </strong>{asset_name}</label><br />
                    <label><strong>Estado: </strong>{status}</label><br />
                    <label><strong>Fecha compromiso: </strong>{moment(start_at).utc().format('LLLL')} - {moment(end_at).utc().format('LLLL')}</label><br />
                    {started_at && <label><strong>Fecha de inicio real: </strong>{moment(started_at).utc().format('LLLL')}</label>}
                    {ended_at && <label><strong>Fecha de finalización real: </strong>{moment(ended_at).utc().format('LLLL')}</label>}
                    <label><strong>Responsable: </strong>{responsable_name}</label><br />
                    {start_comment && <label><strong>Comentarios iniciales: </strong>{start_comment}</label>}
                    {end_comment && <label><strong>Comentarios finales: </strong>{end_comment}</label>}
                </div>
            }
        </>
    )
}

export default Details