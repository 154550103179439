import React, { Component, useState, useRef } from 'react';
import '../../ProgramaMantenimiento.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { __SERVER__ } from '../../../../server/info';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Variables } from '../../../../variables/Variables';
import axios from 'axios';
import Chart from 'react-google-charts';
import { Alert, Button, Overlay, Popover, Modal, Form, Col, Row, InputGroup } from 'react-bootstrap';
import Instructions from './Instructions';
import EditarPrograma from './EditarPrograma';
import { FaEdit, FaTrashAlt, FaEye } from 'react-icons/fa';

import Deductiva from '../Deductiva.jsx';
import FAB from '../../../generales/FAB.jsx';
import Materiales from './Materiales.jsx';
import ExcelReader from './../Modal.jsx';
import Assets from './Assets.jsx';
import AssignedActivities from './AssignedActivities';
import { indexTollbooths } from 'api/operation_standards/tollbooths';
import { indexMaintenanceSections } from 'api/operation_standards/maintenance_sections';
import { Heading } from '@chakra-ui/react';

require('moment/locale/es.js');
const localizer = momentLocalizer(moment);
const _ = require('underscore');

const gantt_columns = [
    { type: 'string', label: 'ID Tarea' },
    { type: 'string', label: 'Nombre de la tarea' },
    { type: 'string', label: 'Recurso' },
    { type: 'date', label: 'Fecha inicial' },
    { type: 'date', label: 'Fecha final' },
    { type: 'number', label: 'Duración' },
    { type: 'number', label: 'Porcentaje cumplido' },
    { type: 'string', label: 'Dependencias' },
];

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            assets: null,
            asset: null,
            program: null,
            buttons_active: ['button_active', 'button_desactive', 'button_desactive', 'button_desactive', 'button_desactive', 'button_desactive', 'button_desactive'],
            buttons_maintenance_type_active: ['button_active', 'button_desactive', 'button_desactive'],
            refresh: false,
            tap: 0, //Índice de pestaña opciones generales
            tapMT: 0, // Índice de pestaña tipo de mantenimiento
            payload: {
                assets_id: [],
                responsable_id: 0,
                start_date: '',
                end_date: null,
                start_time: '',
                end_time: null,
                frequency_id: null
            },
            maintenanceProgram: null,
            modal: false,
            dataFlow: null,
            before: [],
            after: [],

            eventsList: [],


            tollbooths: [],
            tollbooth_id: '',
            toll_management_assets: [],
            toll_management_assets_activity: [],

            viewActivityRadio: false,
            viewProgramModal: false,
            viewUsersRadio: false,

            maintenanceActivity: [],
            data_maintenance_activity: {
                code: '',
                description: '',
                maintenance_sections_id: -1
            },
            data_asset_activity: {
                maintenance_activity_id: -1,
                table_name: '',
                table_id: -1,
                responsable_id: -1
            },
            select_asset_activity_id: -1,

            data_program: {
                asset_activity_id: -1,
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
                maintenance_types_id: -1,
                maintenance_sections_id: -1
            },

            maintenance_program: [],
            maintenance_program_flow: [],

            users: [],

            //Datos de formularios de modal Deductivas
            CumplObject: [],
            PUMObject: [],
            formulaCump: '',
            formulaPUM: '',
            modalDeductivas: false,
            hayFormula: false,
            buttonVisible: false,

            // Indica en que sección de mantenimiento se encuentra actualmente
            maintenance_section_id: null,
            // Indica el tipo de mantenimiento
            maintenance_type_id: null,
            maintenance_types: [],
            // Datos para diagrama de Gantt
            data_gantt: [],
            data_gantt_program_flow: [],
            data_gantt_program: [],
            fecha_inicial_consulta: '',
            fecha_final_consulta: '',
            data_gantt2: [],

            // Datos para el modal de instrucciones
            modalInstructions: false,
            maintenance_activity_instructions: null,
            modalInstructions_action: 'view', //admite view, delete and edit

            // Datos para el modal de editar programa
            modalEditarPrograma: false,
            programa_seleccionado: null,
            modalEditarPrograma_action: 'view',

            // Datos para el modal de carga desde Excel
            modalUploadFromExcel: false,

            // Datos para modal de bienes
            modalAssets: false,
            asset_edit: null,
            modalAssets_action: 'view',

            // Datos para modal de actividades asignadas
            modalAssignedActivities: false,
            activity_asset: null,

            generateOrderModal: false,
            generateOrderIdEvent: -1,
            last_code: '0000001',
            last_code_min: 1,
            autorization: '',
            vobo: '',
            date_target: '',
            description_wo: '',
            work_order_id: -1,
            componentMaterials: null
        }
    }

    componentDidMount = async () => {
        await this.getMaintenanceSections();
        await this.getTollbooths();
        await this.getMaintenanceTypes();
        await this.getMaintenanceActivity();
        await this.getUsers();
        await this.getMaintenanceProgram();
        await this.getMaintenanceProgramFlow();
        await this.getDates();
        await this.getFormulasOperation();
        // await this.getDataGantt();
        this.setState({ buttonVisible: true });
    }
    // Obtiene la sección en la que se está haciendo el mantenimiento
    getMaintenanceSections = async () => {
        let response = await indexMaintenanceSections({ package_id: Variables.package_id, operating_indicator_code: this.props.code });
        this.setState({ maintenance_section_id: response.data[0]?.id });
    }

    // Método para obtener las fórmula de operación
    getFormulasOperation = async () => {
        // GET operating_indicator_id
        let res = await axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')

        if (res.data.length !== 0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
            this.setState({
                CumplObject: res.data[0].variables,
                PUMObject: res.data[1].variables,
                formulaCump: res.data[0].formula,
                formulaPUM: res.data[1].formula,
                hayFormula: true
            });
        } else {
            this.setState({ hayFormula: false });
        }
    }

    // Método para obtener las casetas de cobro
    getTollbooths = async () => {
        const { project_id } = this.props;
        let response = await indexTollbooths({ project_id });
        this.setState({ tollbooths: response.data });
    }

    // Método para obtener los tipos de mantenimiento
    getMaintenanceTypes = async () => {
        let response = await axios.get(__SERVER__ + `/packages/${Variables.package_id}/victum_maintenance_types`);
        this.setState({ maintenance_types: response.data, maintenance_type_id: response.data[0]?.id });
    }

    // Método para las pestañas activas
    tapActive = (tap) => {
        this.setState(prevState => {
            const buttons_active = prevState.buttons_active.map((item, index) => {
                let value = tap === index ? 'button_active' : 'button_desactive'
                return value
            })
            return { buttons_active, }
        })
        this.setState({ refresh: !this.state.refresh, tap });
    }

    // Método para obtener fotografías | evidencia subida desde la app usuarios
    createPhothos = async () => {
        let before = [];
        let after = [];
        // console.log(this.state.dataFlow.evidences)
        for (let i = 0; i < this.state.dataFlow.evidences.length; i++) {
            let path = this.state.dataFlow.evidences[i].file_name;
            let url = __SERVER__ + '/projects/' + this.props.project_id + '/victum_maintenance_program_flow/' + this.state.dataFlow.id + '/evidences/download?path=' + path;
            await new Promise((resolve, reject) => {
                let xhr = new XMLHttpRequest();
                xhr.onerror = reject;
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        resolve(xhr.response);
                    }
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob'; // convert type
                xhr.send();
            }).then(async resu => {
                await new Promise((resol, eject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(resu);
                    reader.onloadend = function () {
                        let base64data = reader.result;
                        base64data = 'data:image/png;base64,' + base64data.split(',')[1];
                        resol(base64data);
                    }
                }).then(b64 => {
                    if (path[0] === 'a') {
                        after.push(b64);
                    } else {
                        before.push(b64);
                    }
                })
            }).catch(e => {
                console.log('Error al descargar las imagenes', e);
            });
        }
        this.setState({ before, after })
    }

    // Muestra en consola las fechas de inicio y fin 
    onSlotChange = (slotInfo) => {
        let startDate = moment(slotInfo.start.toLocaleString()).format("YYYY-MM-DDm:ss");
        let endDate = moment(slotInfo.end.toLocaleString()).format("YYYY-MM-DDm:ss");
        // console.log('startTimetartDate'); //shows the start time chosen
        // console.log('endTimendDate'); //shows the end time chosen
    }

    searchInfo = async () => {
        if (this.state.tollbooth_id === '') {
            alert('Debe seleccionar una plaza');
            return;
        }

        ///toll_management_assets
        let url = '/projects/' + this.props.project_id + '/tollbooths/' + this.state.tollbooth_id + '/toll_management_assets';
        await fetch(__SERVER__ + url + `?maintenance_sections_id=${this.state.maintenance_section_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(res => {
            console.log("🚀 ~ file: UOV-51.js ~ line 303 ~ extends ~ searchInfo= ~ res", res)
            // console.log("CODE: ", this.props.code)
            this.setState({ toll_management_assets: res });
            if (res.length !== 0) {
                this.getAssetActivity('toll_management_assets');
            }
        }).catch(e => {
            this.setState({ toll_management_assets: [] });
            console.log(e);
        })
        //Se deben agregar las consultas de los demas bienes
    }

    getAssetActivity = async (table) => {
        this.state[table + "_activity"] = "";
        await fetch(__SERVER__ + '/projects/' + this.props.project_id + '/asset_activity?table_name=' + table, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(res => {
            // console.log("getAssetActivity: ", res)
            this.state[table + "_activity"] = res;
            this.setState({ refresh: !this.state.refresh });
        }).catch(e => {
            this.state[table + "_activity"] = [];
            this.setState({ refresh: !this.state.refresh });
            console.log(e);
        });

        //Obtener datos para diagrama de Gantt
        this.getDataGantt();
    }

    getMaintenanceActivity = async () => {
        let url = `/projects/${this.props.project_id}/maintenance_activity`;
        await fetch(__SERVER__ + url + `?maintenance_sections_id=${this.state.maintenance_section_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(res => {
            this.setState({ maintenanceActivity: res });
        }).catch(e => {
            this.setState({ maintenanceActivity: [] });
            console.log(e);
        })

    }

    addMaintenanceActivity = () => {
        if (this.state.data_maintenance_activity.code === "" || this.state.data_maintenance_activity.description === "") {
            alert('No puede haber campos vacios');
            return;
        }

        this.state.data_maintenance_activity.maintenance_sections_id = this.state.maintenance_section_id;
        this.setState({ refresh: !this.state.refresh });

        fetch(__SERVER__ + '/projects/' + this.props.project_id + '/maintenance_activity', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(this.state.data_maintenance_activity)
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                let item = {
                    id: res,
                    project_id: this.props.project_id,
                    code: this.state.data_maintenance_activity.code,
                    description: this.state.data_maintenance_activity.description
                }
                this.state.data_maintenance_activity.code = '';
                this.state.data_maintenance_activity.description = '';
                this.state.maintenanceActivity.push(item);
                this.setState({ refresh: !this.state.refresh });
            }
        }).catch(e => {
            console.log(e);
        })
    }

    deleteMaintenanceActivity = (id, i) => {
        fetch(__SERVER__ + '/projects/maintenance_activity/' + id, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                this.state.maintenanceActivity.splice(i, 1);
                this.setState({ refresh: !this.state.refresh });
            }
        }).catch(e => {
            console.log(e);
        })
    }

    saveAssetActivity = () => {
        if (this.state.data_asset_activity.responsable_id === -1 || this.state.data_asset_activity.maintenance_activity_id == -1) {
            alert('Debe seleccionar una opción');
            return;
        }
        let method = this.state.select_asset_activity_id === -1 ? 'POST' : 'PUT';
        let url = __SERVER__ + '/projects/' + (this.state.select_asset_activity_id === -1 ? this.props.project_id + '/asset_activity' : 'asset_activity/' + this.state.select_asset_activity_id);
        fetch(url, {
            method,
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(this.state.data_asset_activity)
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                let temp = {};
                temp.project_id = this.props.project_id;
                temp.maintenance_activity_id = this.state.data_asset_activity.maintenance_activity_id;
                temp.table_name = this.state.data_asset_activity.table_name;
                temp.table_id = this.state.data_asset_activity.table_id;
                temp.responsable_id = this.state.data_asset_activity.responsable_id;
                temp.id = this.state.select_asset_activity_id === -1 ? res : this.state.select_asset_activity_id;
                if (this.state.select_asset_activity_id === -1) {
                    //PUSH
                    this.state[temp.table_name + "_activity"].push(temp);
                } else {
                    //UPDATE ARRAY
                    this.state[temp.table_name + "_activity"][_.findIndex(this.state[temp.table_name + "_activity"], { id: temp.id })] = temp;
                }
            }
            this.setState({ viewActivityRadio: false });
        }).catch(e => {
            console.log(e);
            this.setState({ viewActivityRadio: false });
        })
    }

    getUsers = () => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/users?project_id=' + this.props.project_id, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {

            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(res => {
            this.setState({ users: res });
        }).catch(e => {
            console.log(e);
        })
    }

    saveProgram = async () => {
        if (this.state.data_program.asset_activity_id === -1) {
            alert('Debe especificar la actividad y el responsable');
            return;
        }
        let values = Object.values(this.state.data_program);
        for (let i = 0; i < values.length; i++) {
            if (values[i] === '') {
                alert('Todos los campos son necesarios');
                return;
            }
        }

        let since = moment(this.state.data_program.start_date);
        let until = moment(this.state.data_program.end_date);


        if (since.isSameOrBefore(until)) {
            let startTime = moment(this.state.data_program.start_time, 'HH:mm:ss');
            let endTime = moment(this.state.data_program.end_time, 'HH:mm:ss');
            if (startTime.isSame(endTime)) {
                alert('Debe colocar un rango de fechas más amplio');
                return;
            } else {
                if (since.isSame(until)) {
                    if (startTime.isAfter(endTime)) {
                        alert('El horario no tiene sentido');
                        return;
                    }

                    let dateNow = moment().format('YYYY-MM-DD');
                    dateNow = moment(dateNow);
                    if (since.isSame(dateNow)) {
                        let timeNow = moment().format('HH:mm:ss');
                        timeNow = moment(timeNow, 'HH:mm:ss');
                        if (startTime.isBefore(timeNow)) {
                            alert('El horario especificado no es correcto, debe seleccionar un horario que este por suceder');
                            return;
                        }
                    }
                }
            }

        } else {
            alert('El rango de fechas no es válida');
            return;
        }

        if (this.state.data_program.start_time.length === 5) {
            this.state.data_program.start_time += ":00";
            this.state.data_program.end_time += ":00";
        }
        this.state.data_program.maintenance_types_id = this.state.maintenance_type_id;
        this.state.data_program.maintenance_sections_id = this.state.maintenance_section_id;
        this.setState({ refresh: !this.state.refresh });

        await fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + this.props.project_id + '/victum_maintenance_program', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(this.state.data_program)
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                res = res[0];
                this.state.data_program.asset_activity_id = -1;
                this.state.data_program.start_time = '';
                this.state.data_program.end_date = '';
                this.state.data_program.end_time = '';
                this.state.data_program.start_date = '';
                this.state.maintenance_program.push(res.program);
                for (let i = 0; i < res.flow.length; i++) {
                    this.state.maintenance_program_flow.push(res.flow[i]);
                }
                this.setState({ viewProgramModal: false }, this.generateEventList);
                //console.log('PROGRAM', res);
            }
        }).catch(e => {
            console.log(e);
        });

        this.getDataGantt();
    }

    getMaintenanceProgram = async () => {

        let url = `/packages/${Variables.package_id}/projects/${Variables.project_id}/victum_maintenance_program`;
        url += `?maintenance_sections_id=${this.state.maintenance_section_id}`;
        url += `&maintenance_types_id=${this.state.maintenance_type_id}`;
        let response = await axios.get(__SERVER__ + url);
        this.setState({ maintenance_program: response.data }, this.generateEventList);
    }

    getDates = () => {
        let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        let current_date = new Date(); //Obtenemos la fecha actual
        this.setState({ fecha_inicial_consulta: `${current_date.getFullYear()}-${months[current_date.getMonth()]}-01` });
        current_date.setMonth(current_date.getMonth() + 1);
        this.setState({ fecha_final_consulta: `${current_date.getFullYear()}-${months[current_date.getMonth() - 1]}-${new Date(current_date.getFullYear(), current_date.getMonth(), 0).getDate()}` });
    }

    getDataGantt = () => {

        let state_fecha_inicial = new Date(this.state.fecha_inicial_consulta);
        state_fecha_inicial.setDate(state_fecha_inicial.getDate() + 1);
        state_fecha_inicial.setHours(0, 0, 0);
        let state_fecha_final = new Date(this.state.fecha_final_consulta);
        state_fecha_final.setDate(state_fecha_final.getDate() + 1);
        state_fecha_final.setHours(23, 59, 59);


        let items_program = this.state.maintenance_program.filter((item) => {
            let fecha_inicial = new Date(item.start_date);
            fecha_inicial.setHours(parseInt(item.start_time));
            let fecha_final = new Date(item.end_date);
            fecha_final.setHours(parseInt(item.end_time));

            return (state_fecha_inicial <= fecha_inicial && state_fecha_final >= fecha_final) ? true : false;

        });

        let items_program_flow = this.state.maintenance_program_flow.filter((item) => {
            let exist = ((_.findIndex(items_program, { id: item.program_id })) !== -1) ? true : false;
            return exist;
        }).map((item, i) => {
            let fecha_inicial = new Date(item.start_date);
            fecha_inicial.setHours(parseInt(item.start_time));
            let fecha_final = new Date(item.end_date);
            fecha_final.setHours(parseInt(item.end_time));

            // console.log("CODE ITEM::: ", item.asset_activity.maintenance.code)
            return [
                item.id,
                moment(item.start_date).format('dddd'),
                item.activity_permanent + i,
                fecha_inicial,
                fecha_final,
                null,
                75,
                item.id + '-' + item.activity_permanent + '-' + item.responsable_permanent,
            ];
        });

        this.setState({ data_gantt_program_flow: items_program_flow });

        items_program = this.state.maintenance_program.filter((item) => {
            let fecha_inicial = new Date(item.start_date);
            fecha_inicial.setHours(parseInt(item.start_time));
            let fecha_final = new Date(item.end_date);
            fecha_final.setHours(parseInt(item.end_time));

            return (state_fecha_inicial <= fecha_inicial && state_fecha_final >= fecha_final) ? true : false;

        }).map((item, i) => {
            let fecha_inicial = new Date(item.start_date);
            fecha_inicial.setHours(parseInt(item.start_time));
            let fecha_final = new Date(item.end_date);
            fecha_final.setHours(parseInt(item.end_time));

            let maintenance_activity_code = this.state.maintenanceActivity[
                _.findIndex(this.state.maintenanceActivity, {
                    id:
                        item.asset_activity.maintenance_activity_id
                })
            ].code;

            let asset = this.state.toll_management_assets[
                _.findIndex(this.state.toll_management_assets, {
                    id:
                        this.state.toll_management_assets_activity[
                            _.findIndex(this.state.toll_management_assets_activity, {
                                id:
                                    item.asset_activity_id
                            })
                        ].table_id
                })
            ].code;
            return [
                //Maintenance activity ID
                maintenance_activity_code + '-' + asset + '-' + item.asset_activity.responsable.first_name + ' - i:' + i,
                //Maintenance activity name
                this.state.maintenanceActivity[_.findIndex(this.state.maintenanceActivity, { id: item.asset_activity.maintenance_activity_id })].description,
                //Resource: maintenance_activity-toll_managemnet_asset
                maintenance_activity_code + '-' + asset,
                //Fecha de inicio   
                fecha_inicial,
                //Fecha final
                fecha_final,
                //Duración en caso de no contar con fecha de inicio o final (default null)
                null,
                //Porcentaje cumplido (modificar tabla "victum_maintenance_program"),
                100,
                //Dependencias (null para actividades padre),
                null
            ];
        });

        this.setState({ data_gantt_program: items_program });
        // console.log("items::: ", items_program)
        // console.log("items_program_flow::: ", items_program_flow)

        items_program = items_program.concat(items_program_flow);

        // console.log("-> items::: ", items_program)

        this.setState({ data_gantt2: items_program });
    }

    updateGantt = (index) => {
        // console.log("... ", index)
    }

    getMaintenanceProgramFlow = async () => {
        // console.log("Incio: time -> ", moment().format());
        let response = await axios.get(`${__SERVER__}/packages/${Variables.package_id}/projects/${Variables.project_id}/victum_maintenance_program_flow_2`);
        if (response.status === 200) { this.setState({ maintenance_program_flow: response.data }, this.generateEventList); }
        // console.log("Fin: time -> ", moment().format());
    }

    generateEventList = async () => {
        if (this.state.maintenance_program.length !== 0 && this.state.maintenance_program_flow.length !== 0) {
            let eventsList = [];
            for (let i = 0; i < this.state.maintenance_program.length; i++) {
                let program_flow = await _.where(this.state.maintenance_program_flow, { program_id: this.state.maintenance_program[i].id });
                // console.log('program_flow', program_flow);
                for (let j = 0; j < program_flow.length; j++) {
                    eventsList.push({
                        id: program_flow[j].id,
                        title: program_flow[j].responsable_permanent,
                        start: new Date(program_flow[j].start_date.split('T')[0] + " " + program_flow[j].start_time),
                        end: new Date(program_flow[j].end_date.split('T')[0] + " " + program_flow[j].end_time),
                        desc: program_flow[j].activity_permanent,
                        status: program_flow[j].status,
                        details: this.onEventClick,
                        detailsOrder: this.detailsOrder,
                        generateOrder: this.generateOrder
                    });
                }
            }
            // console.log('eventsList', eventsList);
            this.setState({ eventsList });
        }
    }

    // Evento Click: Get "victum_maintenance_program_flow"
    onEventClick = (event) => {
        let url = `/packages/${Variables.package_id}/projects/${this.props.project_id}/victum_maintenance_program_flow`;
        fetch(`${__SERVER__}${url}?id=${event.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                this.state.dataFlow = res[0];
                this.state.before = [];
                this.state.after = [];
                this.createPhothos();
                this.setState({ modal: true });
            } else {
                alert('Error en el servidor');
                this.state.dataFlow = null;
            }
        }).catch(e => {
            console.log(e);
            this.state.dataFlow = null;
            alert('Error en el servidor' + e);
        })
    }

    generateOrder = (event) => {
        this.setState({ generateOrderModal: true, generateOrderIdEvent: event.id });
        this.state.dataFlow = null;

        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + this.props.project_id + '/victum_maintenance_program_flow?id=' + event.id, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            // console.log("dataFlow", res);
            if (res !== null) {
                this.state.dataFlow = res[0];
                fetch(__SERVER__ + '/packages/' + Variables.package_id + '/work_order_op/last_code', {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(res => {
                    if (res.status === 200) {
                        res.json().then(data => {
                            if (data.length === 0) {
                                this.state.last_code = "000001";
                                this.state.last_code_min = 1;
                            } else {
                                this.state.last_code_min = parseInt(data[0].code.split('-')[2], 10);
                                this.state.last_code = this.state.last_code_min + 1;
                                this.state.last_code += "";
                                if (this.state.last_code.length < 6) {
                                    let t = new Array(6 - this.state.last_code.length).fill("0")
                                    this.state.last_code = t.join('') + this.state.last_code;
                                }
                            }
                            this.setState({ refresh: !this.state.refresh });
                        })
                    }
                })
                this.setState({ refresh: !this.state.refresh });
            }
        }).catch(e => {
            console.log(e);
        });
    }

    detailsOrder = (event) => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/projects/' + this.props.project_id + '/victum_maintenance_program_flow?id=' + event.id, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            throw res.statusText;
        }).then(res => {
            // console.log("dataFlow", res);
            this.setState({
                componentMaterials: <Materiales datos={res} setThisNull={() => {
                    this.setState({ componentMaterials: null });
                }} />
            });

            this.setState({ refresh: !this.state.refresh });
        }).catch(e => {
            console.log(e);
        });
    }

    openOrCloseModalDeductivas = () => {
        this.setState({ modalDeductivas: !this.state.modalDeductivas });
    }


    openOrCloseModalInstructions = () => {
        this.setState({ modalInstructions: !this.state.modalInstructions });
        this.getMaintenanceActivity();
    }

    openOrCloseModalEditarPrograma = () => {
        this.setState({ modalEditarPrograma: !this.state.modalEditarPrograma });
        this.getMaintenanceProgram();
        this.getMaintenanceProgramFlow();
    }

    openOrCloseModalUploadFromExcel = () => {
        this.setState({ modalUploadFromExcel: !this.state.modalUploadFromExcel });
        this.searchInfo();
    }

    openOrCloseModalAssets = () => {
        this.setState({ modalAssets: !this.state.modalAssets });
        this.searchInfo();
    }

    openOrCloseAssignedActivities = () => {
        this.setState({ modalAssignedActivities: !this.state.modalAssignedActivities });
        this.getMaintenanceProgram();
        this.getMaintenanceProgramFlow();
        this.getDataGantt();
        this.searchInfo();
    }

    onSubmit = (e) => {
        fetch(__SERVER__ + '/packages/' + Variables.package_id + '/project/' + Variables.project_id + '/standard/' + Variables.indicator_id + '/work_order_op', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify({
                code: `OT-${Variables.project_code}-${this.state.last_code}`,
                authorization: this.state.autorization,
                vobo: this.state.vobo,
                description: this.state.description_wo !== '' ? this.state.description_wo : null,
                target_date: this.state.dataFlow.end_date.split('T')[0] + 'T' + this.state.dataFlow.end_time,
                asset_activity_id: this.state.generateOrderIdEvent
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw response.statusText;
            }
        }).then(data => {
            this.state.last_code = ""
            this.state.autorization = ""
            this.state.vobo = ""
            this.state.description_wo = ''
            this.state.dataFlow = null
            this.state.generateOrderModal = false
            this.setState({ refresh: !this.state.refresh })
            alert("La orden de trabajo se genero exitosamente")
        }).catch(e => {
            console.log(e);
        });
        e.preventDefault();
    }



    render = () => {
        return (
            <section className="assets">
                <Heading className='tituloSub'>{this.props.name}</Heading>
                {
                    this.state.buttonVisible &&
                    <FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faCarCrash'} onClick={this.openOrCloseModalDeductivas} />
                }
                <FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModalUploadFromExcel} />
                {/** Seleccionador de plazas */}
                <section className="sectionSearch">
                    <article>
                        <label>Plaza de cobro</label>
                        <select onChange={e => this.setState({ tollbooth_id: e.target.value })}>
                            <option value={''}></option>
                            {
                                this.state.tollbooths.map(item =>
                                    <option key={"KEY" + item.id} value={item.id}>{item.code}: {item.description}</option>
                                )
                            }
                        </select>
                    </article><article>
                        <label>Tipo de mantenimiento</label>
                        <select>
                            {
                                this.state.maintenance_types.map((item, i) =>
                                    <option key={i} value={item.id}>{item.name}</option>
                                )
                            }
                        </select>
                    </article>
                    <article>
                        <button className="botonBusqueda" onClick={this.searchInfo}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
                    </article>
                </section>
                {/**Opciones generales */}
                {this.state.tollbooth_id !== '' ? <header className="assets_tap">
                    <button className={this.state.buttons_active[0]} onClick={() => this.tapActive(0)}>Bienes</button>
                    <button className={this.state.buttons_active[4]} onClick={() => this.tapActive(4)}>Actividades</button>
                    <button className={this.state.buttons_active[6]} onClick={() => this.tapActive(6)}>Asignación de actividades</button>
                    <button className={this.state.buttons_active[2]} onClick={() => this.tapActive(2)}>Programa de mantenimiento</button>
                    <button className={this.state.buttons_active[3]} onClick={() => this.tapActive(3)}>Calendario</button>
                    <button className={this.state.buttons_active[5]} onClick={() => this.tapActive(5)}>Diagrama de Gantt</button>
                </header> : null}

                {/* Nueva asignaión de actividades */}

                {
                    this.state.tap === 6 ?
                        <section>
                            <table className={"tableAssets"}>
                                <thead>
                                    <tr>
                                        <th>Folio</th>
                                        <th>Descripción</th>
                                        <th>Actividad</th>
                                        <th>Responsable</th>
                                        <th>Periodicidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.toll_management_assets.map((item, i) =>
                                            <tr key={"tr-" + i} className={"td_clickeable"} onClick={e => {
                                                this.setState({ activity_asset: item });
                                                this.openOrCloseAssignedActivities();
                                            }}>
                                                <td>{item.folio}</td>
                                                <td>{item.description}</td>
                                                <td>---</td>
                                                <td>---</td>
                                                <td>---</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </section> : null
                }

                {/* Inventario de gestión de peaje para Bienes y Asignación de actividades */}
                {
                    this.state.tap === 0 || this.state.tap === 1 ?
                        this.state.toll_management_assets.length !== 0 ?
                            <h2>Inventario de la gestión de Peaje</h2>
                            : null
                        : null
                }

                {
                    this.state.tap === 0 || this.state.tap === 1 ?
                        this.state.toll_management_assets.length !== 0 ?
                            <table className="tableAssets">
                                <thead>
                                    <tr>
                                        {this.state.tap === 1 ? <th></th> : null}
                                        <th>Folio</th>
                                        <th>Descripción</th>
                                        {this.state.tap === 0 ? <th>Marca</th> : null}
                                        {this.state.tap === 0 ? <th>Modelo</th> : null}
                                        {this.state.tap === 0 ? <th>Serial</th> : null}
                                        {this.state.tap === 0 ? <th>Status</th> : null}
                                        {this.state.tap === 0 ? <th>Acciones</th> : null}
                                        {this.state.tap === 1 ? <th>Actividad</th> : null}
                                        {this.state.tap === 1 ? <th>Responsable</th> : null}
                                        {this.state.tap === 1 ? <th>Fecha</th> : null}
                                        {this.state.tap === 1 ? <th>Horario</th> : null}

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.toll_management_assets.map((item, i) =>
                                            <tr key={'TMA' + i}>
                                                {this.state.tap === 1 ? <td></td> : null}
                                                <td>{item.folio}</td>
                                                <td>{item.description}</td>
                                                {this.state.tap === 0 ? <td>{item.brand}</td> : null}
                                                {this.state.tap === 0 ? <td>{item.model}</td> : null}
                                                {this.state.tap === 0 ? <td>{item.serial}</td> : null}
                                                {this.state.tap === 0 ? <td>{item.status}</td> : null}
                                                {this.state.tap === 0 ?
                                                    <td>
                                                        <Button variant="outline-success" onClick={(e) => {
                                                            this.setState({ modalAssets_action: 'edit' });
                                                            this.setState({ asset_edit: item });
                                                            this.openOrCloseModalAssets();
                                                        }}><FaEdit /></Button>
                                                        <Button variant="outline-info" onClick={(e) => {
                                                            this.setState({ modalAssets_action: 'view' });
                                                            this.setState({ asset_edit: item });
                                                            this.openOrCloseModalAssets();
                                                        }}><FaEye /></Button>
                                                        <Button variant="outline-danger" onClick={(e) => {
                                                            this.setState({ modalAssets_action: 'delete' });
                                                            this.setState({ asset_edit: item });
                                                            this.openOrCloseModalAssets();
                                                        }}><FaTrashAlt /></Button>
                                                    </td> : null}
                                                {this.state.tap === 1 ? <td className={'td_clickeable'} onClick={() => {
                                                    this.state.data_asset_activity.maintenance_activity_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                        this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].maintenance_activity_id : -1;
                                                    this.state.data_asset_activity.table_name = 'toll_management_assets';
                                                    this.state.data_asset_activity.table_id = item.id;
                                                    this.state.data_asset_activity.responsable_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                        this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].responsable_id : -1;
                                                    this.state.select_asset_activity_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                        this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id : -1;
                                                    this.setState({
                                                        viewActivityRadio: true
                                                    });
                                                }}>
                                                    {
                                                        _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                            this.state.maintenanceActivity[_.findIndex(this.state.maintenanceActivity, { id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].maintenance_activity_id })].code
                                                            : ''}
                                                </td>
                                                    : null}
                                                {this.state.tap === 1 ?
                                                    <td className={'td_clickeable'} onClick={() => {
                                                        this.state.data_asset_activity.maintenance_activity_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ? this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].maintenance_activity_id : -1;
                                                        this.state.data_asset_activity.table_name = 'toll_management_assets';
                                                        this.state.data_asset_activity.table_id = item.id;
                                                        this.state.data_asset_activity.responsable_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ? this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].responsable_id : -1;
                                                        this.state.select_asset_activity_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ? this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id : -1;
                                                        this.setState({
                                                            viewActivityRadio: true
                                                        });
                                                    }}>
                                                        {
                                                            _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                                this.state.users[_.findIndex(this.state.users, { id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].responsable_id })].first_name
                                                                : ''} {
                                                            _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                                this.state.users[_.findIndex(this.state.users, { id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].responsable_id })].last_name
                                                                : ''}
                                                    </td>
                                                    : null}
                                                {this.state.tap === 1 ? <td className={'td_clickeable'} onClick={() => {
                                                    this.state.data_program.asset_activity_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ? this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id : -1;
                                                    this.setState({ viewProgramModal: true });
                                                }}>
                                                    {
                                                        _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                            _.findIndex(this.state.maintenance_program, { asset_activity_id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id }) !== -1 ?
                                                                'Del ' + this.state.maintenance_program[_.findIndex(this.state.maintenance_program, { asset_activity_id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id })].start_date.split('T')[0] + ' al ' +
                                                                this.state.maintenance_program[_.findIndex(this.state.maintenance_program, { asset_activity_id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id })].end_date.split('T')[0]
                                                                : '' : ''
                                                    }
                                                </td> : null}
                                                {this.state.tap === 1 ? <td className={'td_clickeable'} onClick={() => {
                                                    this.state.data_program.asset_activity_id = _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ? this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id : -1;
                                                    this.setState({ viewProgramModal: true });
                                                }}>
                                                    {
                                                        _.findIndex(this.state.toll_management_assets_activity, { table_id: item.id }) !== -1 ?
                                                            _.findIndex(this.state.maintenance_program, { asset_activity_id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id }) !== -1 ?
                                                                this.state.maintenance_program[_.findIndex(this.state.maintenance_program, { asset_activity_id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id })].start_time + ' - ' +
                                                                this.state.maintenance_program[_.findIndex(this.state.maintenance_program, { asset_activity_id: this.state.toll_management_assets_activity[_.findIndex(this.state.toll_management_assets_activity, { table_id: item.id })].id })].end_time
                                                                : '' : ''
                                                    }
                                                </td> : null}
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            : null
                        : null
                }

                {/**Modal de Actividades */}
                {
                    this.state.viewActivityRadio ?
                        <section className={'modalBackground'}>
                            <section className={'modalActivity'}>
                                <section>
                                    <button onClick={() => this.setState({ viewActivityRadio: false })} className={'close_button'}>x</button>
                                </section>
                                <h3>Seleccione una actividad y un responsable</h3>
                                <section className={'section_columns'}>
                                    <section>
                                        <article>
                                            <h4>Actividades</h4>
                                        </article>
                                        {
                                            this.state.maintenanceActivity.map((item, i) =>
                                                <article key={'RA' + i}>
                                                    <input type='radio' name='radioActivityname' id={'radioActivity' + i} checked={this.state.data_asset_activity.maintenance_activity_id === item.id ? true : false}
                                                        onChange={e => { if (e.target.checked) { this.state.data_asset_activity.maintenance_activity_id = item.id; this.setState({ refresh: !this.state.refresh }); } }} />
                                                    <label for={'radioActivity' + i}>{item.code}: {item.description}</label>
                                                </article>
                                            )
                                        }
                                    </section>
                                    <section>
                                        <article>
                                            <h4>Responsables</h4>
                                        </article>
                                        {
                                            this.state.users.map((item, i) =>
                                                <article key={'RA' + i}>
                                                    <input type='radio' name='radioUsers' id={'radioUsers' + i} checked={this.state.data_asset_activity.responsable_id === item.id ? true : false}
                                                        onChange={e => { if (e.target.checked) { this.state.data_asset_activity.responsable_id = item.id; this.setState({ refresh: !this.state.refresh }); } }} />
                                                    <label for={'radioUsers' + i}><strong>{item.first_name} {item.last_name}:</strong> {item.name}</label>
                                                </article>
                                            )
                                        }
                                    </section>
                                </section>
                                <button onClick={this.saveAssetActivity} className={'botonBusqueda bottom'}>Guardar</button>

                            </section>
                        </section>
                        : null
                }

                {/**Modal de Fechas */}
                {
                    this.state.viewProgramModal ?
                        <section className={'modalBackground'}>
                            <section className={'modalActivity'}>
                                <section>
                                    <button onClick={() => this.setState({ viewProgramModal: false })} className={'close_button'}>x</button>
                                </section>
                                <h3>Fechas y horarios</h3>
                                <h4>Seleccione el rango de fechas</h4>
                                <input className={"classInputDateTime"} value={this.state.data_program.start_date} onChange={(e) => {
                                    this.state.data_program.start_date = e.target.value;
                                    this.setState({ refresh: !this.state.refresh });
                                }} min={moment().format('YYYY-MM-DD')} type={'date'} required />
                                <input className={"classInputDateTime"} value={this.state.data_program.end_date} onChange={(e) => {
                                    this.state.data_program.end_date = e.target.value;
                                    this.setState({ refresh: !this.state.refresh });
                                }} type={'date'} min={moment().format('YYYY-MM-DD')} required />
                                <h4>Seleccione el rango de tiempo</h4>
                                <input className={"classInputDateTime"} value={this.state.data_program.start_time} onChange={(e) => {
                                    this.state.data_program.start_time = e.target.value;
                                    this.setState({ refresh: !this.state.refresh });
                                }} type={'time'} required />
                                <input className={"classInputDateTime"} value={this.state.data_program.end_time} onChange={(e) => {
                                    this.state.data_program.end_time = e.target.value;
                                    this.setState({ refresh: !this.state.refresh });
                                }} type={'time'} required />
                                <button onClick={this.saveProgram} className={'botonBusqueda bottom'}>Guardar</button>
                            </section>
                        </section>
                        : null
                }

                {
                    this.state.tap === 2 ?
                        this.state.maintenance_program.length !== 0 ?
                            <table className="tableAssets">
                                <thead>
                                    <tr>
                                        <th>Fecha de creación</th>
                                        <th>Rango de fechas</th>
                                        <th>Horario</th>
                                        <th>Actividad</th>
                                        <th>Responsable</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.maintenance_program.reverse().map((item, i) =>
                                            <tr key={'MA' + i}>
                                                <td>{item.creation_date.replace('T', ' ').split('.')[0]}</td>
                                                <td>Del {item.start_date.split('T')[0]} al {item.end_date.split('T')[0]}</td>
                                                <td>{item.start_time + ' - ' + item.end_time}</td>
                                                <td>{this.state.maintenanceActivity[_.findIndex(this.state.maintenanceActivity, { id: item.asset_activity.maintenance_activity_id })].description}</td>
                                                <td>{item.asset_activity.responsable.first_name} {item.asset_activity.responsable.last_name}</td>
                                                <td>
                                                    <Button variant="outline-success" onClick={(e) => {
                                                        this.setState({ modalEditarPrograma_action: 'edit' });
                                                        this.setState({ programa_seleccionado: item });
                                                        this.openOrCloseModalEditarPrograma();
                                                    }}><FaEdit /></Button>
                                                    <Button variant="outline-info" onClick={e => {
                                                        this.setState({ modalEditarPrograma_action: 'view' });
                                                        this.setState({ programa_seleccionado: item });
                                                        this.openOrCloseModalEditarPrograma();
                                                    }}><FaEye /></Button>
                                                    <Button variant="outline-danger" onClick={e => {
                                                        this.setState({ modalEditarPrograma_action: 'delete' });
                                                        this.setState({ programa_seleccionado: item });
                                                        this.openOrCloseModalEditarPrograma();
                                                    }}><FaTrashAlt /></Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            : null
                        : null
                }

                {
                    this.state.tap === 4 ?
                        <section className="sectionSearch">
                            <article>
                                <label>Código</label>
                                <input value={this.state.data_maintenance_activity.code} type='text' onChange={e => {
                                    this.state.data_maintenance_activity.code = e.target.value;
                                    this.setState({ refresh: !this.state.refresh });
                                }
                                }
                                />
                            </article>
                            <article>
                                <label>Descripción</label>
                                <input value={this.state.data_maintenance_activity.description} type='text' onChange={e => {
                                    this.state.data_maintenance_activity.description = e.target.value;
                                    this.setState({ refresh: !this.state.refresh });
                                }
                                }
                                />
                            </article>
                            <article>
                                <button className="botonBusqueda" onClick={this.addMaintenanceActivity}>Añadir</button>
                            </article>
                        </section>
                        : null
                }

                {
                    this.state.tap === 4 ?
                        this.state.maintenanceActivity.length !== 0 ?
                            <table className="tableAssets">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Código</th>
                                        <th>Descripción</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.maintenanceActivity.map((item, i) =>
                                            <tr key={'MA' + i}>
                                                <td>{i + 1}</td>
                                                <td>{item.code}</td>
                                                <td>{item.description}</td>
                                                <td>
                                                    <Button variant="outline-success" onClick={(e) => {
                                                        this.setState({ modalInstructions_action: 'edit' });
                                                        this.setState({ maintenance_activity_instructions: item });
                                                        // console.log("maintenance_activity: ", this.state.maintenanceActivity)
                                                        this.openOrCloseModalInstructions();
                                                    }}><FaEdit /></Button>
                                                    <Button variant="outline-info" onClick={(e) => {
                                                        this.setState({ modalInstructions_action: 'view' });
                                                        this.setState({ maintenance_activity_instructions: item });
                                                        this.openOrCloseModalInstructions();
                                                    }}><FaEye /></Button>
                                                    <Button variant="outline-danger" onClick={(e) => {
                                                        this.setState({ modalInstructions_action: 'delete' });
                                                        this.setState({ maintenance_activity_instructions: item });
                                                        this.openOrCloseModalInstructions();
                                                    }}><FaTrashAlt /></Button>
                                                </td>
                                                {/*<td><button className="botonBusqueda" onClick={() => this.deleteMaintenanceActivity(item.id, i)}>Eliminar</button></td>*/}
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            : null
                        : null
                }


                {
                    this.state.tap === 3 ?
                        <div style={{ height: `${500}px` }} className="bigCalendar-container calendar">
                            <BigCalendar
                                selectable
                                /*onSelectEvent={this.onEventClick}*/
                                onSelectSlot={this.onSlotChange}
                                localizer={localizer}
                                views={['month', 'day', 'agenda']}
                                events={this.state.eventsList}
                                startAccessor="start"
                                endAccessor="end"
                                messages={{
                                    next: "sig",
                                    previous: "ant",
                                    today: "Hoy",
                                    month: "Mes",
                                    week: "Semana",
                                    day: "Día"
                                }}
                                components={{
                                    event: Event,
                                    agenda: { event: EventAgenda }
                                }} />
                        </div>
                        : null
                }

                <Modal size={'lg'} centered show={this.state.generateOrderModal} onHide={() => this.setState({ generateOrderModal: false, generateOrderIdEvent: -1 })} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Generar orden de trabajo</Modal.Title>
                    </Modal.Header>
                    {
                        this.state.generateOrderIdEvent !== -1 ?
                            <Modal.Body>
                                {this.state.dataFlow !== null ?
                                    <Form onSubmit={this.onSubmit}>
                                        <Form.Group>
                                            <Form.Label><strong>{this.state.dataFlow.asset_permanent}</strong></Form.Label><br />
                                            <Form.Label>{this.props.name}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={"2"}>Código OT</Form.Label>
                                            <Col sm={"10"}>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>OT-{Variables.project_code}-</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control value={this.state.last_code} type={'number'} min={this.state.last_code_min} maxLength={6} onChange={(e) => {
                                                        this.state.last_code = e.target.value;
                                                        this.setState({ refresh: !this.state.refresh });
                                                    }} required={true} />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={"2"}>Responsable</Form.Label>
                                            <Col sm={"10"}>
                                                <Form.Control type="text" readOnly={true} value={this.state.dataFlow.responsable_permanent} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={"2"}>Actividad</Form.Label>
                                            <Col sm={"10"}>
                                                <Form.Control readOnly={true} value={this.state.dataFlow.activity_permanent} multiple={true} />
                                            </Col>
                                        </Form.Group>
                                        {this.state.dataFlow.asset_activity.table_name === "toll_management_assets" ?
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Marca</Form.Label>
                                                        <Form.Control type="text" readOnly={true} value={this.state.dataFlow.asset_activity.asset[0].brand} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Modelo</Form.Label>
                                                        <Form.Control type="text" readOnly={true} value={this.state.dataFlow.asset_activity.asset[0].model} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Serial</Form.Label>
                                                        <Form.Control type="text" readOnly={true} value={this.state.dataFlow.asset_activity.asset[0].serial} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            : null}
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={"2"}>Fecha planeada</Form.Label>
                                            <Col sm={"10"}>
                                                <Form.Control readOnly={true} value={`Del ${this.state.dataFlow.start_date.split('T')[0]} al ${this.state.dataFlow.end_date.split('T')[0]}`} multiple={true} />
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Horario</Form.Label>
                                                    <Form.Control type="text" readOnly={true} value={this.state.dataFlow.start_time + " - " + this.state.dataFlow.end_time} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Estado</Form.Label>
                                                    <Form.Control type="text" readOnly={true} value={this.state.dataFlow.status} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Autorización</Form.Label>
                                                    <Form.Control type="text" required={true} value={this.state.autorization} onChange={e => {
                                                        this.state.autorization = e.target.value;
                                                        this.setState({ refresh: !this.state.refresh });
                                                    }} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Visto Bueno</Form.Label>
                                                    <Form.Control type="text" required={true} value={this.state.vobo} onChange={e => {
                                                        this.state.vobo = e.target.value;
                                                        this.setState({ refresh: !this.state.refresh });
                                                    }} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={"2"}>Descripción (opcional)</Form.Label>
                                            <Col sm={"10"}>
                                                <Form.Control as="textarea" value={this.state.description_wo} onChange={e => {
                                                    this.state.description_wo = e.target.value;
                                                    this.setState({ refresh: !this.state.refresh });
                                                }} />
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Button type='submit' variant="success" disabled={this.state.generateOrderIdEvent === -1}>
                                                    Guardar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    : null}
                            </Modal.Body>
                            : null
                    }
                </Modal>
                {this.state.componentMaterials}
                {/* Sección para el diagrama de gantt */}
                {/* {
                    this.state.tap === 5 ?
                        <h1>Diagrama de Gantt</h1>
                        : null
                } */}

                {
                    this.state.tap === 5 ?
                        <section className="sectionSearch">
                            <label>Fecha incial</label>
                            <input type="date" name="input_date_ini" defaultValue={this.state.fecha_inicial_consulta} onChange={async (e) => {
                                // console.log("fecha inicio cambio: ", e.target.value);
                                await this.setState({ fecha_inicial_consulta: e.target.value.toString() });
                                this.getDataGantt();
                                this.tapActive(4)
                                this.tapActive(5)
                            }} />
                            <label>Fecha final</label>
                            <input type="date" name="input_date_fin" defaultValue={this.state.fecha_final_consulta} onChange={async (e) => {
                                // console.log("fecha final cambio: ", e.target.value);
                                await this.setState({ fecha_final_consulta: e.target.value.toString() });
                                this.getDataGantt();
                                this.tapActive(4)
                                this.tapActive(5)
                            }} /><br />
                            {
                                this.state.data_gantt2.length !== 0 ?
                                    <>
                                        <Chart
                                            width={'100%'}
                                            height={'400px'}
                                            chartType="Gantt"
                                            loader={<div>Loading Chart</div>}
                                            data={[gantt_columns, ...this.state.data_gantt_program]}
                                            options={{
                                                height: 400,
                                                gantt: {
                                                    trackHeight: 30,
                                                },
                                            }}
                                            chartEvents={[
                                                {
                                                    eventName: 'ready',
                                                    callback: ({ chartWrapper, google }) => {

                                                        const chart = chartWrapper.getChart();
                                                        // console.log("ready chart!", chart.container)
                                                        // console.log("google: ", google, google.visualization.Gantt)
                                                    }
                                                }
                                            ]}
                                            rootProps={{ 'data-testid': '2' }}
                                        />
                                    </>
                                    : <Alert variant="info">No hay datos para mostrar. Intente elegir otro rango de fechas.</Alert>
                            }
                        </section> : null
                }

                {/**Modal de Fechas */}
                {
                    this.state.modal ?
                        <section className={'modalBackground'}>
                            <section className={'modalActivity'}>
                                <section>
                                    <button onClick={() => this.setState({ modal: false })} className={'close_button'}>x</button>
                                </section>
                                {this.state.dataFlow !== null ?
                                    <div>
                                        <h3>Evento</h3>
                                        <h4>{this.state.dataFlow.activity_permanent}</h4>
                                        <label><strong>Responsable: </strong>{this.state.dataFlow.responsable_permanent}</label><br />
                                        <label><strong>Bien: </strong>{this.state.dataFlow.asset_permanent}</label><br />
                                        <label><strong>Estado: </strong>{this.state.dataFlow.status}</label><br />
                                        <label><strong>Horario: </strong>{this.state.dataFlow.start_time} - {this.state.dataFlow.end_time}</label><br />
                                        <label><strong>Responsable: </strong>{this.state.dataFlow.responsable_permanent}</label><br />
                                        {this.state.dataFlow.comment_S !== null ? <label><strong>Comentarios iniciales: </strong>{this.state.dataFlow.comment_S}</label> : null}
                                        <br />
                                        {
                                            this.state.before.length !== 0 ?
                                                <label><strong>Fotografías iniciales</strong></label>
                                                : null
                                        }
                                        <br />
                                        <article className="contenedorimagenes">
                                            {
                                                this.state.before.map((item, i) =>
                                                    <img className={'imageMan'} key={'Before' + i} src={item} />
                                                )
                                            }
                                        </article>
                                        {this.state.dataFlow.comment_E !== null ? <label><strong>Comentarios finales: </strong>{this.state.dataFlow.comment_E}</label> : null}
                                        <br />
                                        {
                                            this.state.after.length !== 0 ?
                                                <label><strong>Fotografías finales</strong></label>
                                                : null
                                        }
                                        <br />
                                        <article className="contenedorimagenes">
                                            {
                                                this.state.after.map((item, i) =>
                                                    <img className={'imageMan'} key={'After' + i} src={item} />
                                                )
                                            }
                                        </article>
                                    </div>
                                    : null}
                            </section>
                        </section>
                        : null
                }

                {/* Deductiva modal */}
                {
                    this.state.modalDeductivas ?
                        <Deductiva
                            title={this.props.code}
                            formula={this.state.formula}
                            pum={this.state.PUMObject}
                            cump={this.state.CumplObject}
                            formulaCump={this.state.formulaCump}
                            formulaPUM={this.state.formulaPUM}
                            hayFormula={this.state.hayFormula}
                        /> : null
                }

                {/* Instructions Modal */}
                {
                    this.state.modalInstructions ?
                        <Instructions
                            asset_activity={this.state.maintenance_activity_instructions}
                            action={this.state.modalInstructions_action}
                            onClose={this.openOrCloseModalInstructions}
                        /> : null
                }

                {/* Modal para asignación de actividades */}
                {
                    this.state.modalAssignedActivities ?
                        <AssignedActivities
                            users={this.state.users}
                            maintenance_activities={this.state.maintenanceActivity}
                            maintenance_sections_id={this.state.maintenance_section_id}
                            maintenance_types_id={this.state.maintenance_type_id}
                            asset={this.state.activity_asset}
                            onClose={this.openOrCloseAssignedActivities}
                        /> : null
                }

                {/* Editar Programa Modal */}
                {
                    this.state.modalEditarPrograma ?
                        <EditarPrograma
                            program={this.state.programa_seleccionado}
                            program_flow={this.state.maintenance_program_flow[_.findIndex(this.state.maintenance_program_flow, { program_id: this.state.programa_seleccionado.id })]}
                            maintenance_activities={this.state.maintenanceActivity}
                            users={this.state.users}
                            action={this.state.modalEditarPrograma_action}
                            programs_flow={this.state.maintenance_program_flow}
                            onClose={this.openOrCloseModalEditarPrograma}
                        /> : null
                }

                {/* Modal de bienes */}
                {
                    this.state.modalAssets ?
                        <Assets
                            asset={this.state.asset_edit}
                            action={this.state.modalAssets_action}
                            onClose={this.openOrCloseModalAssets}
                        /> : null
                }

                {/* Subir datos desde Excel */}
                {
                    this.state.modalUploadFromExcel ?
                        <ExcelReader
                            server={`${__SERVER__}/projects/${Variables.project_id}/tollbooths/${this.state.tollbooth_id}/toll_management_assets/array`}
                            items={[
                                { label: 'FOLIO', description: 'Folio', value: 'folio', type: 'String' },
                                { label: 'ACTIVITY_CODE', description: 'Código de Actividad', value: 'activity_code', type: 'String' },
                                { label: 'CODE', description: 'Código de bien', value: 'code', type: 'String' },
                                { label: 'DESCRIPTION', description: 'Descripción', value: 'description', type: 'String' },
                                { label: 'BRAND', description: 'Marca', value: 'brand', type: 'String' },
                                { label: 'MODEL', description: 'Modelo', value: 'model', type: 'String' },
                                { label: 'SERIAL', description: 'Número de serie', value: 'serial', type: 'String' },
                                { label: 'PERIODICITY', description: 'Periodicidad', value: 'periodicity', type: 'String' },
                                { label: 'MAINTENANCE_SECTION', description: 'Sección de Mantenimiento', value: 'maintenance_sections_id', type: 'Number' }
                            ]}
                            title={`${this.props.code} ${this.props.name}`}
                            onClose={this.openOrCloseModalUploadFromExcel}
                        /> : null
                    // <UploadFromExcel
                    //     onClose={this.openOrCloseModalUploadFromExcel}
                    // /> : null
                }

            </section >
        );
    }
}

/*Agenda Rendering*/
//Outside the class
function Event({ event }) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        if (!show) {
            setTimeout(() => {
                setShow(false);
            }, 5000);
        }
        setShow(!show);
        setTarget(event.target);
    };



    let status = event.status;
    let variant = "primary";
    switch (status) {
        case 'PENDIENTE': variant = "secondary"; break;
        case 'INICIADO': variant = "warning"; break;
        case 'NO REALIZADO': variant = "danger"; break;
        case 'CON RETRASO': variant = "dark"; break;
        case 'REALIZADO': variant = "primary"; break;
        case 'COMPLEATADO': variant = "success"; break;
        default: break;
    }
    return (
        <div>
            <Button title={status} onClick={handleClick} size="sm" variant={variant}>
                {event.title}
            </Button>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                containerPadding={20}>
                <Popover id="popover-contained">
                    <Popover.Title as="h3">{status}</Popover.Title>
                    <Popover.Content>
                        <Button variant={'link'} onClick={() => {
                            setShow(false)
                            event.generateOrder(event)
                        }}>Generar orden de trabajo</Button><br />
                        <Button variant={'link'} onClick={() => {
                            setShow(false)
                            event.detailsOrder(event)
                        }}>Ver ordenes de trabajo</Button><br />
                        <Button variant={'link'} onClick={() => {
                            setShow(false)
                            event.details(event)
                        }}>Ver detalles</Button>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
}

function EventAgenda({ event }) {
    return (
        <span>
            <em>{event.title}</em>
            <p>{event.desc}</p>
        </span>
    );
}
