import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";

const CREATED = 201;
const STATUS_OK = 200;

export const indexInventoryFiles = async (props) => {
    const {
        project_id,
        table,
        inventory_data_id,
        page,
        pageSize
    } = props;
    let params = {};

    if (page) params.page = page;
    if (pageSize) params.pageSize = pageSize;

    const url = `/projects/${project_id}/${table}/${inventory_data_id}`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("index inventory data: ", fetch);
        if (fetch.status === STATUS_OK) {
            const { data, totalItems } = fetch.data;
            response = { data, totalItems };
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const downloadInventoryFiles = async ({ project_id, table, id, file_path }) => {
    const url = `/projects/${project_id}/${table}/${id}/download`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: {
                file_path
            }
        });
        if (fetch.status === STATUS_OK)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return response;
    }
}

export const uploadInventoryFiles = async ({ project_id, table, id, file_type, user_id, data }) => {
    const url = `/projects/${project_id}/${table}/${id}/upload/${file_type}/${user_id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            contentType: 'application/x-www-form-urlencoded',
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: inventory_files.js:70 ~ uploadInventoryFiles ~ fetch", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const destroyInventoryFiles = async ({ project_id, table, id, file_path }) => {
    const url = `/projects/${project_id}/${table}/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'DELETE',
            params: {
                file_path
            }
        });
        // console.log("🚀 ~ file: inventory_files.js ~ line 72 ~ destroyInventoryFiles ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}