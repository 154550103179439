import React from "react";
import PropTypes from 'prop-types';
import { Spinner } from "react-bootstrap";

const SkeletonTable = (props) => {
    const { tr, td } = props;
    return (
        <>
            {
                Array.from({ length: tr }).map((_tr, i_tr) =>
                    <tr key={i_tr}>
                        {
                            Array.from({ length: td }).map((_td, i_td) =>
                                <td key={i_td}><Spinner animation="border" /></td>)
                        }
                    </tr>
                )
            }
        </>
    );
}

SkeletonTable.propTypes = {
    tr: PropTypes.number,
    td: PropTypes.number
}

export default SkeletonTable;