import { Divider, Skeleton } from 'antd';
import FormIcon2 from 'components/layouts/FormIcon2';
import { getCurrency } from 'components/libs/functions';
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

const AssignSupply = (props) => {
	const { segments, supplySel, form, setForm, loadingSave } = props;

	useEffect(() => {
		if (form.assign) {
			const total = form.assign * supplySel.cost;
			setForm(prevState => ({ ...prevState, total: getCurrency(total) }));
		}
	}, [form.assign]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setForm(prevState => ({ ...prevState, [name]: value }));
	}

	return (
		<>
			{
				loadingSave ? <Skeleton active /> : <>
					<Divider orientation='left'>Recurso</Divider>
					<Row>
						<FormIcon2 title='Código' name='code' value={supplySel.code} readOnly />
						<FormIcon2 title='Descripción' name='description' value={supplySel.description} readOnly />
						<FormIcon2 title='Tipo' name='type' value={supplySel?.type?.name} readOnly />
					</Row><Row>
						<FormIcon2 title='Precio Unitario' name='cost' value={getCurrency(supplySel.cost)} readOnly />
						<FormIcon2 title='Unidad de medida' name='unit' value={supplySel.unit} readOnly />
						<FormIcon2 title='Cantidad disponible' type='number' name='count' value={supplySel.count} readOnly />
					</Row>
					<Divider orientation='left'>Asignación</Divider>
					<Row>
						<Col>
							<div className='form-group'>
								<label className='form-label'>Segmento</label>
								<select className='form-control' name="segment_id" onChange={handleChange}>
									{
										segments.map((segment) =>
											<option key={segment.id} value={segment.id}>
												{segment.name} {segment.location}
											</option>)
									}
								</select>
							</div>
						</Col>
						<FormIcon2 title='Cantidad a asignar' type='number' name='assign' value={form?.assign} handleChange={handleChange} />
						<FormIcon2 title='Total' name='total' value={form?.total} readOnly />
					</Row>
				</>
			}
		</>
	)
}

export default AssignSupply