import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Skeleton } from 'antd';
import { getSegments } from 'api/general/getSegments';
import { indexAssetsBaseLines } from 'api/operation_standards/assets_base_lines';
import { indexAssetsTypes } from 'api/operation_standards/assets_types';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { storeAssets } from 'api/operation_standards/assets';

const AddAsset = (props) => {
    const { onClose, items, package_id, table, project_id, syncServer } = props;

    const [segments, setSegments] = useState([]);
    const [baseLines, setBaseLines] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [itemsAdd, setItemsAdd] = useState([]);
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({});
    const [formExtra, setFormExtra] = useState({});

    useEffect(() => {
        setItemsAdd(items.filter((item) => item.add === true));
        const getData = async () => {
            let response = await getSegments();
            setSegments(response);
            response = await indexAssetsBaseLines({ package_id });
            setBaseLines(response.data);
            response = await indexAssetsTypes({ table, package_id });
            setAssetTypes(response.data);
            setLoading(false);
        }
        getData();
    }, []);

    useEffect(() => {
        if (segments.length > 0) {
            setForm({
                ...form,
                segment_id: segments[0].id,
                body: segments[0].name,
                direction: segments[0].location
            });
        }
    }, [segments]);

    useEffect(() => {
        if (form.segment_id) {
            const segment = segments.find((item) => item.id == form.segment_id);
            if (segment)
                setForm({
                    ...form,
                    body: segment.name,
                    direction: segment.location
                });
        }
    }, [form.segment_id]);

    useEffect(() => {
        if (assetTypes.length > 0)
            setForm({ ...form, type_id: assetTypes[0].id });
    }, [assetTypes]);

    useEffect(() => {
        if (baseLines.length > 0)
            setForm({ ...form, assets_base_line_id: baseLines[0].id });
    }, [baseLines]);

    const handleChange = (event) => {
        const { value, name } = event.target;
        setForm({ ...form, [name]: value });
    }

    const onSave = async () => {
        Swal.fire({
            title: '¿Desea realizar esta operación?',
            text: 'Usted está a punto de guardar un activo en la base de datos',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, realizar!',
            cancelButtonText: 'No, cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await storeAssets({ project_id, data: form, table });
                if (response){
                    syncServer();
                    Swal.fire(
                        'Operación exitosa!',
                        'Transacción realizada con éxito',
                        'success'
                    );
                    onClose();
                }else{
                    Swal.fire(
                        'Operación fallida!',
                        'Ha ocurrido un error al tratar de almacenar el activo, verifique el formulario.',
                        'error'
                    );
                }
                
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Operación cancelada',
                    'Esta transacción no se pudo realizar satisfactoriamente ',
                    'error'
                );
            }
        });
    }

    return (
        <ModalComp
            onClose={onClose}
            title='Agregar activo'
            size='lg'
            body={<>
                {
                    loading ? <Skeleton /> : <>
                        <Row gutter={16}>
                            <Col className='gutter-row' xs={24} lg={12} xl={8} >
                                <Form.Group>
                                    <Form.Label>Segmento</Form.Label>
                                    <Form.Control as="select" name="segment_id" onChange={handleChange}>
                                        {
                                            segments.map(segment =>
                                                <option key={segment.id} value={segment.id}>{segment.name} - {segment.location}</option>
                                            )
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className='gutter-row' xs={24} lg={12} xl={8} >
                                <Form.Group>
                                    <Form.Label>Tipo de activo</Form.Label>
                                    <Form.Control as="select" name="type_id" onChange={handleChange}>
                                        {
                                            assetTypes.map(assetType =>
                                                <option key={assetType.id} value={assetType.id}>{assetType.name}</option>
                                            )
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className='gutter-row' xs={24} lg={12} xl={8} >
                                <Form.Group>
                                    <Form.Label>Línea base</Form.Label>
                                    <Form.Control as="select" name="assets_base_line_id" onChange={handleChange}>
                                        {
                                            baseLines.map(baseLine =>
                                                <option key={baseLine.id} value={baseLine.id}>{baseLine.name}</option>
                                            )
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            {/* Atributos generales */}
                            {
                                itemsAdd.map((item) =>
                                    <Col className='gutter-row' key={item.key} xs={24} lg={12} xl={8}  >
                                        <Form.Group>
                                            <Form.Label>{item.label}</Form.Label>
                                            <Form.Control
                                                type={item.type || 'text'}
                                                name={item.key}
                                                value={form[item.key]}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                        </Row>
                    </>
                }
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' />
                {
                    !loading &&
                    <ButtonIcon name='Agregar' icon={faSave} onClick={onSave} variant='outline-success' />
                }
            </>}
        />
    )
}

export default AddAsset