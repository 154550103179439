import { faAngleLeft, faAngleRight, faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useEffect, useState } from 'react';
import { storeInvoice } from 'api/facturapi/Invoices';
import InvoiceAddress from './InvoiceAddress';
import InvoiceCustomer from './InvoiceCustomer';
import InvoiceDetails from './InvoiceDetails';
import AddProducts from './Products';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal';

const NewInvoice = (props) => {
    const { onClose, update } = props;
    const [step, setStep] = useState('customer');
    const [title, setTitle] = useState('Datos del cliente');
    const [size, setSize] = useState('lg');

    const [customer, setCustomer] = useState({
        tax_system: '601'
    });
    const [address, setAddress] = useState({
        country: 'MEX'
    });
    const [details, setDetails] = useState({
        currency: 'MXN',
        payment_form: '01',
        payment_method: 'PUE'
    });
    const [items, setItems] = useState([
        {
            quantity: 1,
            product: {
                description: 'Ukelele',
                product_key: '60131324',
                unit_name: 'Elemento',
                price: 345.60
            }
        },
    ]);

    useEffect(() => {
        switch (step) {
            case 'customer': setTitle('Datos del cliente'); setSize('lg'); break;
            case 'address': setTitle('Dirección del cliente'); setSize('lg'); break;
            case 'products': setTitle('Productos'); setSize('xl'); break;
            case 'details': setTitle('Últimos detalles'); setSize('lg'); break;
            default: setTitle(''); break;
        }
    }, [step]);

    const sendInvoice = async () => {
        let response = await storeInvoice({ ...customer, address }, items, details);
        if (response) {
            // setToast({
            //     toast, title: '¡Operación exitosa!', status: 'success',
            //     description: 'La factura ha sido emitida y timbrada en en SAT de forma satisfactoria.'
            // });
            update();       // Actualizar lista de facturas emitidas
            onClose();      //Cerrar el modal
        } else {
            // setToast({
            //     toast, title: '¡Operación fallida!', status: 'warning',
            //     description: 'Ha ocurrido un error al tratar de emitir su factura, ¡Verifique sus datos!'
            // });
        }
        // console.log("RESPONSE: ", response);
    }

    const nextStep = () => {
        switch (step) {
            case 'customer': setStep('address'); break;
            case 'address': setStep('products'); break;
            case 'products': setStep('details'); break;
            default: break;
        }
    }
    const prevStep = () => {
        switch (step) {
            case 'address': setStep('customer'); break;
            case 'products': setStep('address'); break;
            case 'details': setStep('products'); break;
            default: break;
        }
    }
    const handleChange = (e) => {
        let { name, value } = e.target;
        setCustomer({ ...customer, [name]: value });
    }
    const handleDetails = (e) => {
        let { name, value } = e.target;
        setDetails({ ...details, [name]: value });
    }
    const changeAddress = (e) => {
        let { name, value } = e.target;
        setAddress({ ...address, [name]: value });
    }

    const removeItem = (i) => setItems(items.filter((item, index) => index !== i));

    return (
        <ModalComp
            title={title}
            onClose={onClose}
            size={size}
            body={<Fragment>
                {
                    step === 'customer' && <InvoiceCustomer
                        customer={customer}
                        handleChange={handleChange}
                    />
                }
                {
                    step === 'address' && <InvoiceAddress
                        address={address}
                        changeAddress={changeAddress}
                    />
                }
                {
                    step === 'products' && <AddProducts
                        items={items}
                        setItems={setItems}
                        removeItem={removeItem}
                    />
                }
                {
                    step === 'details' && <InvoiceDetails
                        details={details}
                        handleDetails={handleDetails}
                    />
                }

            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                {
                    step !== 'customer' &&
                    <ButtonIcon name='Regresar' icon={faAngleLeft} onClick={prevStep} variant='outline-primary' />
                }
                {
                    step === 'details' ?
                        <ButtonIcon name='Guardar factura' icon={faSave} onClick={sendInvoice} variant='outline-success' /> :
                        <ButtonIcon name='Continuar' icon={faAngleRight} onClick={nextStep} variant='outline-primary' />
                }
            </Fragment>}
        />
    )
}

export default NewInvoice