import React, { Fragment, useEffect, useRef } from 'react';
import Message from './Message'
import '../Styles.css';
import { Variables } from 'variables/Variables';
import { useLazyQuery } from '@apollo/client';
import { MESSAGES_QUERY } from 'graphql/queries/_messenger/MessagesQueries';

const Chat = (props) => {
    const { room_id, messages, setMessages, users } = props;

    const messagesEndRef = useRef();
    const infiniteRef = useRef();

    const user_id = Variables.user.id;

    const [getMessages, { loading, data }] = useLazyQuery(MESSAGES_QUERY, { variables: { room_id }, pollInterval: 500 });

    useEffect(() => {
        if (data) {
            setMessages(data.messenger_messages);
        }
    }, [data]);

    useEffect(() => {
        if (room_id) {
            getMessages();
        }
    }, [room_id]);

    useEffect(() => {
        scrollToBottom();
        let { current: scrollContainer } = infiniteRef;
        function onScroll() {
            // Valor del scroll cuando está hasta abajo
            const availableScroll = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            // console.log("On scroll: [scrollcontainer]: ", scrollContainer.scrollTop, scrollContainer.scrollHeight, scrollContainer.clientHeight);
            // Si el escroll está en el mensaje más antiguo obtenido
            if (scrollContainer.scrollTop === 0) {
                // console.log("Scroll en el mensaje más antiguo");
                getMessages();
            }
            // Si el scroll está en el mensaje más reciente
            if (scrollContainer.scrollTop === availableScroll) {
                // console.log("Scroll en el mensaje más reciente");
            }
        }

        scrollContainer.addEventListener("scroll", onScroll);
        return () => {
            scrollContainer.removeEventListener("scroll", onScroll);
        };
    }, [messages]);

    // Posicionar scroll al final de los mensajes
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className='p-2 scroll-chat scroll-body' ref={infiniteRef}>
            {
                loading ? 'Obteniendo mensajes' :
                    messages && messages.map((msg, index) =>
                        <Fragment key={index}>
                            <Message
                                key={index}
                                alignMsg={user_id === msg.user_id ? 'end' : 'start'}
                                msg={msg}
                                user_id={user_id}
                                users={users}
                            />
                        </Fragment>
                    )
            }
            <div ref={messagesEndRef} />
        </div>
    )
}

export default Chat;