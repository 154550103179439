import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import '../UOV-0/Operacion.css';
import '../UOV-0/Resultados.css';
import { __SERVER__ } from '../../../../server/info';
import { Variables } from '../../../../variables/Variables';
import Pagination from '../Pagination';
import FAB from '../../../generales/FAB.jsx';
import ModalUpload from '../Modal.jsx';
import Deductiva from '../Deductiva.jsx';
import Axios from 'axios';
import { Heading } from '@chakra-ui/react';

const MySwal = withReactContent(Swal);
const _ = require('underscore');


const FiltroBusqueda = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				notice_dateS: "2019-11-01",
				notice_dateE: "2019-11-02",
				type_event_id: "0"
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: "",
			type_event: []
		};
		this.generateQuery = this.generateQuery.bind(this)
		this.getTypeEvent = this.getTypeEvent.bind(this)
	}

	componentDidMount = () => {
		this.getTypeEvent();
	}

	getTypeEvent = () => {
		fetch(__SERVER__ + "/type_event", {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				return null
			}
		}).then(res => {
			let type_event = []
			if (res !== null) {
				type_event.push(<option value={0} key={'E'}></option>)
				for (let i = 0; i < res.length; i++) {
					type_event.push(<option value={res[i].id} key={'E' + i}>{res[i].name}</option>)
				}
			}
			this.setState({ type_event: type_event })
		}).catch(e => {
			this.getTypeEvent()
		})
	}

	generateQuery = () => {
		if (this.state.query.notice_dateE === "" || this.state.query.notice_dateS === "") {
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "0") {
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
		this.props.setProps(this.state.query_, headersTable)
	}

	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="notice_dateS" value={this.state.query.notice_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="notice_dateE" value={this.state.query.notice_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<label>Evento</label>
					<select name="type_event_id" onChange={this.changeInput}>
						{this.state.type_event.map(item => item)}
					</select>
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const Grid = class extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.data_per_page}</label>
				</section>
				<div className="cards">
					{
						this.props.data.data.map(item =>
							<div className="card">
								<div className="card-header"><strong>Código: {item.code}</strong> {item.type_event.name}</div>
								<div><strong>Fecha de aviso: </strong> {item.notice_date.split('T')[0]} {item.notice_time} </div>
								<div><strong>KM ± m: </strong>{item.km} </div>
								<div><strong>Número de siniestro: </strong>{item.sinister}</div>
								<div><strong>Notificación: </strong>{item.means_notification}</div>
								<br />
								<div><strong>Notificaciones</strong></div>
								{(item.intervention_time !== null) ? <div><strong>Intervención: </strong> {item.intervention_time} <strong> Cumplimiento: </strong>{item.compliance_arrival}</div> : null}
								{(item.signaling_time !== null) ? <div><strong>Señalización: </strong> {item.signaling_time} <strong> Cumplimiento: </strong>{item.compliance_signaling}</div> : null}
								<br />
								{(item.procedures !== null) ? <div><strong>Procedimientos seguidos para la restitución vehicular</strong></div> : null}
								{(item.procedures !== null) ? <div>{item.procedures}</div> : null}
							</div>)
					}
				</div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
			</div>
		);
	}
}

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			refresh: false,
			page: 1,
			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false
		}
	}

	componentDidMount = async () => {
		// GET operating_indicator_id
		let res = await Axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')
		
		if (res.data.length!==0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	updatePage = (page) => {
		this.setState({ page }, this.getPatrolResponse);
	}

	generateTable = () => {
		let data = [];
		data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];

		this.setState({
			grid: null
		}, () => {
			if (data !== undefined) {
				this.setState({ grid: <Grid updatePage={this.updatePage} data={data} /> })
			} else {
				this.setState({ grid: null });
			}
		});
	}

	getPatrolResponse = () => {
		//let query = this.state.query + '&limit=' + this.state.limit + '&offset=' + this.state.offset;
		let query = this.state.query + (this.state.query === '' ? `?page=${this.state.page}` : `&page=${this.state.page}`);
		console.log(query)
		fetch(__SERVER__ + "/projects/" + this.props.project_id + "/patrol_response" + query, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					this.state.result.push(result);
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			Swal.fire('Error', e, 'error');
		})
	}

	setProps = (query, headersTable) => {
		this.setState({
			page: 1,
			result: [],
			query: query,
			headersTable: headersTable
		}, this.getPatrolResponse)
	}

	openOrCloseModal = () => {
		this.setState({modalUploadData: !this.state.modalUploadData});
	}

	openOrCloseModalDeductivas = () => {
		this.setState({ modalDeductivas: !this.state.modalDeductivas });
	}

	render() {
		return (
			<div className="body_">
				<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
				<FiltroBusqueda setProps={this.setProps} />
				{this.state.grid}
				<FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faCarCrash'} onClick={this.openOrCloseModalDeductivas} />
				<FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />
				{
					this.state.modalUploadData ?
						<ModalUpload
							server={`${__SERVER__}/projects/${Variables.project_id}/patrol_response`}
							items={[
								{ label: 'Código', description: 'Código', value: 'code', type: 'String' },
								{ label: 'Fecha de aviso', description: 'Fecha de aviso', value: 'notice_date', type: 'String', toFormat: 'Date' },
								{ label: 'Kilometro', description: 'Kilometro', value: 'km', type: 'String' },
								{ label: 'Hora de aviso', description: 'Hora de aviso', value: 'notice_time', type: 'String', toFormat: 'Time' },
								{ label: 'Hora de intervención', description: 'Hora de intervención', value: 'intervention_time', type: 'String', toFormat: 'Time' },
								{ label: 'Hora de señalización', description: 'Hora de señalización', value: 'signaling_time', type: 'String', toFormat: 'Time' },
								{ label: 'Cumplimiento de llegada', description: 'Cumplimiento de llegada', value: 'compliance_arrival', type: 'String' },
								{ label: 'Cumplimiento de señalización', description: 'Cumplimiento de señalización', value: 'compliance_signaling', type: 'String' },
								{ label: 'Tipo de evento', description: 'Tipo de evento', value: 'type_event', type: 'String' },
								{ label: 'Siniestro', description: 'Siniestro', value: 'sinister', type: 'String' },
								{ label: 'Medio de notificación', description: 'Medio de notificación', value: 'means_notification', type: 'String' },
								{ label: 'Procedimientos', description: 'Procedimientos', value: 'procedures', type: 'String' }
							]}
							title={`${this.props.code} ${this.props.name}`}
							onClose={this.openOrCloseModal} />
						: null
				}
				{
					this.state.modalDeductivas ?
						<Deductiva
							title={this.props.code}
							formula={this.state.formula}
							pum={this.state.PUMObject}
							cump={this.state.CumplObject}
							formulaCump={this.state.formulaCump}
							formulaPUM={this.state.formulaPUM}
							hayFormula={this.state.hayFormula}
						/> : null
				}
			</div>
		);
	}
}