import fetcher from "api/fetcher";
import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

const CREATED = 201;
const STATUS_OK = 200;

export const indexMaintenanceProgramFlow = async (query) => {
    const { project_id, package_id } = query;
    const url = `/packages/${package_id}/projects/${project_id}/victum_maintenance_program_flow_2`;
    let response = { data: [], totalItems: 0 };
    let props = query;
    delete props.project_id;
    delete props.package_id;
    let params = {};
    const queryValues = Object.values(props);
    const queryKeys = Object.keys(props);
    for (let index = 0; index < queryValues.length; index++) {
        const element = queryValues[index];
        if (element !== '0')
            params[queryKeys[index]] = element;
    }

    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js ~ line 14 ~ indexMaintenanceProgramFlow ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = { data: fetch.data };
        return response;
    } catch (error) {
        return response;
    }
}

export const showMaintenanceProgramFlow = async ({ project_id, package_id, id }) => {
    const url = `/packages/${package_id}/projects/${project_id}/victum_maintenance_program_flow`;
    let params = { id };
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js ~ line 33 ~ showMaintenanceProgramFlow ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data[0];
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteMaintenanceProgramFlow = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/victum_maintenance_program_flow/${id}`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js:66 ~ deleteMaintenanceProgramFlow ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data;
        }
        return { response };
    } catch (error) {
        return { response };
    }
}

export const getPDFMaintenanceProgramFlow = async ({ project_id, package_id, id }) => {
    const url = `/packages/${package_id}/projects/${project_id}/victum_maintenance_program_flow/${id}/pdf`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js:84 ~ getPDFMaintenanceProgramFlow ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}