import React from 'react';

const HeaderWorkOrder = (props) => {
    const {
        workOrders,
        workOrderSel
    } = props;

    return (
        <article className="headerSuppliesWorkOrder">
            {workOrders[workOrderSel].status === 0 ? <h2>Autorización: {workOrders[workOrderSel].authorization}</h2> : null}
            {workOrders[workOrderSel].status === 0 ? <h2>Visto bueno: {workOrders[workOrderSel].vobo}</h2> : null}
        </article>
    )
}

export default HeaderWorkOrder