import React from 'react'
import { Document, Page, Text, View,Image,StyleSheet  } from '@react-pdf/renderer'
import moment from 'moment';
import _ from 'underscore';
import { getInfoPackage } from 'variables/Variables';

const styles = StyleSheet.create({
    body:{ padding: 15 },
    Head:{
        display: "flex",
        flexDirection: "row"
    },
    Head_Image:{ width: 110 },
    table: { 
      display: "table",
      borderStyle: "solid",  
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      marginBottom: 15,
      borderColapse: "collapse",
      tableLayout: "auto",
      width: "auto", 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderTopWidth: 1,
      width: "100%" 
    }, 
    tableCol: { 
      borderStyle: "solid",
      borderRightWidth: 1,
      width: "100%",
      height: "auto"
    }, 
    tableCell: { 
      margin: "auto", 
      marginVertical: 5,
      marginHorizontal: 3,
      fontSize: 10,
      textAlign: "center"
    },
    text_center: { textAlign: "center" , fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_title: { textAlign: "center", fontWeight: "bold", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_center50: { textAlign: "center" , fontSize: 12, width: "50%", borderStyle: "solid", borderBottomWidth: 1, borderRightWidth:1 },
    logo:{
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    },
    logo1:{
        width: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid", 
        borderWidth: 1,
    },
    d_flex:{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    }, txt_center: { textAlign: "center" } ,
    text_logo: {textAlign: "center" , fontSize: 19, fontWeight: "heavy", width: "100%", borderStyle: "solid", borderTopWidth: 1, color: "#009644"},
    mb_5:  { marginBottom: 14, borderStyle: "solid", 
    borderWidth: 1, },
    fz_12: {fontSize: 12, textAlign: "center",  width: "100%"},
    flex:{
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" },
    cuad: { display: "flex", flexDirection: "row" , flexWrap: "wrap", justifyContent: "center" }
});

const AuxVialPdf = ({Users, NoticeForm, ServiceDescription, BaseLines, Road}) => {
    const info = getInfoPackage()

    const getOperator = operator_id => {
        let name = 'Sin asignar';
        let position = _.findIndex(Users, { id: operator_id });
        if (position !== -1) {
            name = `${Users[position].first_name} ${Users[position].last_name}`
        }
        return name;
    }

    const getNoticeForm = id => {
        let name = 'Indefinido'
        let position = _.findIndex(NoticeForm, { id });
        if (position !== -1) name = NoticeForm[position].name
        return name;
    }

    const getServiceDescription = id => {
        let name = 'Indefinido'
        let position = _.findIndex(ServiceDescription, { id });
        if (position !== -1) name = ServiceDescription[position].name
        return name;
    }

    const status_class = ['En espera', 'Atendiendo', 'Resuelto', 'No resuelto', 'FAKE'];

    return (
        <Document>
            <Page size='A4' orientation="landscape" style={styles.body}>
                <View style={styles.mb_5} fixed>
                    <View style={styles.Head}>
                        <View style={styles.logo}>
                            <Image src={info.img} style={styles.Head_Image}/>
                        </View>

                        <View style={styles.d_flex}>
                            <Text style={{fontWeight: 900}}>{info.title}</Text>
                            <Text style={styles.text_center}>GESTIÓN DE ACTIVOS </Text>
                            <Text style={styles.text_center}>CM-{info.code}-MTTO SGA</Text>
                            <Text style={styles.fz_12}>Reporte de Auxilio Vial</Text>
                        </View>

                        <View style={styles.logo1}>
                            <Text style={styles.text_center}>Autopista {info.highway}</Text>
                            <Text style={styles.text_center}>CÓDIGO: -</Text>
                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                <Text style={styles.text_center50}>VERSIÓN: 1</Text>
                                <Text style={styles.text_center50}>FECHA DE EMISIÓN: { moment().format("D/MM/YY") }</Text>
                            </View>
                            
                        </View>
                    </View>
                </View>

                <Text style={{ fontSize: 14, textAlign: "center", marginBottom: 4 }}>Auxilio Vial</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow} fixed> 
                    {
                        ["#", "Código", "Fecha de reporte", "Operador asignado","Forma de aviso", "Descripción del servicio","Estado"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                       
                    {
                        Road && Road.map((Road, i) => 
                            <View style={styles.tableRow} key={Road.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{i+1}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{Road.code}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{moment(Road.report_date).format('lll')}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{getOperator(Road.road_aid_operator_id)}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{getNoticeForm(Road.notice_form_id)}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{getServiceDescription(Road.service_description_id)}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{status_class[Road.status]}</Text></View>
                            </View> 
                        )
                    }
                </View>
                

                <View style={{display: "flex", flexDirection: "row", marginTop: 100, fontSize: 12,justifyContent: "space-around"}} >
                    <View>
                        <Text>FIRMA DEL MRO (OPERADOR)</Text>
                    </View>
                    <View>
                        <Text>FIRMA DE LA SUPERVISIÓN (AAS)</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default AuxVialPdf