import fetcherCancelToken from "components/libs/fetcherCancelToken";

const STATUS_OK = 200;

export const indexRoadAidByProjectID = async ({ project_id, query }) => {
    const url = `/victum_users/project/${project_id}/road_aid`;
    let response = { data: [], totalItems: 0 };
    let params = {};
    if (query) {
        const queryValues = Object.values(query);
        const queryKeys = Object.keys(query);
        for (let index = 0; index < queryValues.length; index++) {
            const element = queryValues[index];
            if (element !== '')
                params[queryKeys[index]] = element;
        }
    }
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        if (fetch.status === STATUS_OK) {
            response = { ...fetch.data };
        }
        return response;
    } catch (error) {
        return response;
    }
}