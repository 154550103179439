import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table'
import React, { useEffect, useState } from 'react'

const DeductivesTab = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    });

    const getData = async () => {
        setLoading(false);
    }
    return (
        <>
            <TableComp
                headers={['#', '']}
                keys={[]}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
            />
            <PaginationComp />
        </>
    )
}

export default DeductivesTab