import React, { useEffect, useRef, useState } from 'react'
import ModalComp from 'components/layouts/Modal'
import ButtonIcon from 'components/layouts/ButtonIcon'
import { faFileDownload, faFileUpload, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { downloadXLSXByArray } from 'components/libs/exportData'
import { HotTable } from '@handsontable/react'
import Swal from 'sweetalert2'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { storeSuppliesPumServicesArray } from 'api/maintenance_standards/supplies_pum_services'

const xlsx = require('xlsx');


const LoadFromExcel = ({ onClose, syncServer, package_id }) => {
	const inputFileRef = useRef();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [excel, setExcel] = useState(null);
	const [sheets, setSheets] = useState([]);
	const [sheetName, setSheetName] = useState(null);

	const obj = [
		{ key: 'type_description_id', name: 'Tipo', widthCell: 20, type: 'Number' },
		{ key: 'code', name: 'Código', widthCell: 20, type: 'String' },
		{ key: 'description', name: 'Descripción', widthCell: 20, type: 'String' },
		{ key: 'unit', name: 'Unidad de Medida', widthCell: 20, type: 'String' },
		{ key: 'count', name: 'Cantidad', widthCell: 20, type: 'Number' },
		{ key: 'cost', name: 'Precio unitario', widthCell: 20, type: 'Number' }
	];

	useEffect(() => {
		if (excel && sheetName) {
			readExcel();
		}
	}, [excel, sheetName]);

	// Descargar plantilla para rellenar
	const downloadTemplate = () => {
		const widthCells = obj.map((item) => ({ width: item.widthCell }));
		const headers = obj.map((item) => item.name);
		downloadXLSXByArray({ fileName: 'Template - Supply PUM Services', data: [headers], widthCells });
	}

	const clickUpload = () => inputFileRef.current.click();

	// Leer las hojas del libro de excel
	const readSheets = (e) => {
		setData([]);
		//Si no hay libro
		if (e.target.files.length === 0) {
			return;
		}
		let type = e.target.files[0].name.split('.');
		type = type[type.length - 1];
		const excel = e.target.files[0];
		setExcel(excel);
		if (type === 'xlsx' || type === 'xls') {
			let reader = new FileReader();
			reader.onload = (evt) => {
				let data = evt.target.result;
				try {
					const workbook = xlsx.read(data, { type: 'binary', sheetRows: true });
					setSheets(workbook.SheetNames);
				} catch (e) {
					Swal.fire('Error', e, 'error');
				}
			}
			reader.readAsBinaryString(excel);
		} else {
			Swal.fire('Advertencia', 'El archivo no es de extensión XLSX o XLS', 'warning');
		}

	}

	// Leer datos de la hoja
	const readExcel = () => {
		setLoading(true);
		const reader = new FileReader();

		reader.onload = (event) => {
			const data = event.target.result;
			const workbook = xlsx.read(data, { type: 'binary' });
			// Obtener los datos de la primera hoja como un objeto JSON
			const worksheet = workbook.Sheets[sheetName];
			let jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
			jsonData.shift();		// Eliminar encabezados
			jsonData = jsonData.map((row) => {
				let item = {};
				for (let index = 0; index < row.length; index++) {
					const element = row[index];
					item[obj[index].key] = element;
				}
				return item
			})
			setData(jsonData);
			setLoading(false);
		};

		reader.readAsBinaryString(excel);
	};

	const afterChange = (changes, source) => {
		if (source === 'edit') {
			changes.forEach(([row, col, oldValue, newValue]) => {
				setData(prevState => prevState.map((item, index) => {
					if (index === row - 1) item[obj[col].key] = newValue
					return item;
				}));
			});
		}
	}

	const getBodyData = () => {
		return data.map((row) => obj.map((item) => row[item.key]));
	}

	const uploadToServer = async () => {
		setUploading(true);
		const { response } = await storeSuppliesPumServicesArray({
			data: data.map((item) => ({
				...item,
				package_id,
				amount: item.cost * item.count
			}))
		});
		if (response) {
			Swal.fire('¡Operación exitosa!', 'Los datos han sido cargados de forma satisfactoria', 'success');
			syncServer();
			onClose();
		} else {
			Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de subir la información', 'warning');
		}
		setUploading(false);
	}
	return (
		<ModalComp
			size='xl'
			onClose={onClose}
			title='Cargar desde Excel'
			body={<>
				<HotTable
					className='hottable-comp'
					data={[
						obj.map((item) => item.name),
						...getBodyData()
					]}
					width={'100%'}
					rowHeaders={true}
					colHeaders={true}
					colWidths={obj.map(item => item.widthCell * 8)}
					manualColumnResize={true}
					licenseKey="non-commercial-and-evaluation"
					afterChange={afterChange}
				/>
				{/* Seleccionar archivos */}
				<input type="file" ref={inputFileRef} style={{ display: 'none' }} onChange={readSheets} />
			</>}
			footer={<>
				<ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' tooltipDisabled={true} />
				<ButtonIcon name='Descargar platilla' icon={faFileDownload} onClick={downloadTemplate} variant='outline-success' tooltipDisabled={true} />
				<ButtonIcon name='Elegir archivo' icon={faFileUpload} onClick={clickUpload} variant='outline-primary' tooltipDisabled={true} />
				{
					sheets.length > 0 &&
					<ButtonGroup>
						<DropdownButton as={ButtonGroup} title='Hojas' id="bg-nested-dropdown" variant={'outline-primary'} disabled={uploading} size='sm'>
							{
								sheets.map((item, i) =>
									<Dropdown.Item key={i} onClick={() => setSheetName(item)} disabled={loading}>{item}</Dropdown.Item>
								)
							}
						</DropdownButton>
					</ButtonGroup>
				}
				{
					excel && sheetName && data.length > 0 &&
					<ButtonIcon name='Cargar datos' icon={faSave} onClick={uploadToServer} variant='success' tooltipDisabled={true} />
				}
			</>}
		/>
	)
}

export default LoadFromExcel