import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import "../Mantemiento/Mantenimiento.css";
import { __SERVER__ } from '../../../../server/info';
import { Variables } from '../../../../variables/Variables';
import { Button, OverlayTrigger, Tooltip, Modal, Alert, ButtonGroup, Table, Dropdown, Form, Row, Col } from 'react-bootstrap';
import { dateUTC, dateToString } from '../../../../utilidades/dates';
import Swal from 'sweetalert2';
import { dateToString2 } from '../../../../utilidades/dates';
import PDF from './Files/PDF.jsx';
import XLSX from '../Mantemiento/Files/XLSX.jsx';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
/*import TXT from './Files/TXT.jsx';*/

import { PDFDownloadLink } from '@react-pdf/renderer';

export default class extends Component {
    constructor(props) {
        super(props);
        this.typeFile = {HTML: 'HTML', PDF: 'PDF', XLSX: 'EXCEL', TXT: 'TEXTO'};
        this.state = {
            typeFile: 'HTML',
            select: -1,
            disabled: false,
            work_orders: [],
            report: null,
            show: true,
            datos: this.props.datos[0],
            refresh: false,
            tools: [],
            equipments: [],
            materials: [],
            workforce: [],
            toolsValues: [],
            equipmentsValues: [],
            materialsValues: [],
            workforceValues: [],
            toolsObservations: [],
            equipmentsObservations: [],
            materialsObservations: [],
            workforceObservations: [],
            toolsTotal: 0,
            equipmentsTotal: 0,
            materialsTotal: 0,
            workforceTotal: 0,
            loading: true,
            price: null,
            month: this.props.datos[0].end_date.substring(0, 7),
            payload_wo: {
                incident_id: this.props.datos[0].id,
                code: "",
                authorization: "",
                vobo: "",
                description: ""
            }
        };
    }

    componentDidMount() {
        this.getWorkOrders();
    }

    getWorkOrders = () => {
        fetch(__SERVER__ + `/packages/${Variables.package_id}/project/${Variables.project_id}/standard/${Variables.indicator_id}/work_order_op?asset_activity_id=${this.state.datos.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            throw res.statusText;
        }).then(work_orders => {
            console.log("work_orders", work_orders);
            this.setState({ work_orders });
        }).catch(e => {
            console.log(e);
            this.setState({ work_orders: [] });
        })
    }

    getReport = () => {
        this.setState({ loading: true, report: null });
        fetch(__SERVER__ + `/packages/${Variables.package_id}/project/${Variables.project_id}/standard/${Variables.indicator_id}/work_order_op/${this.state.work_orders[this.state.select].id}/report`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            throw res.statusText;
        }).then(report => {
            this.setState({ report, loading: false });
        }).catch(e => {
            this.setState({ loading: false });
            Swal.fire('Error', e, 'error');
        })
    }

    getTools = () => {
        this.setState({ loading: true });
        fetch(__SERVER__ + `/packages/${Variables.package_id}/cost_work_order_op?standard_id=${Variables.indicator_id}&project_id=${this.state.datos.project_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(async (res) => {
            console.log(res)
            let tools = [];
            let equipments = [];
            let materials = [];
            let workforce = [];

            for (let i = 0; i < res.length; i++) {
                switch (res[i].supplie.type.id) {
                    case 1: ///Materiales
                        materials.push(res[i]);
                        break;
                    case 2: ///Mano de obra
                        workforce.push(res[i]);
                        break;
                    case 3: ///Herramientas
                        tools.push(res[i]);
                        break;
                    case 4: ///Equipos
                        equipments.push(res[i]);
                        break;
                    default: break;
                }
            }

            for (let i = 0; i < tools.length; i++) {
                let code = tools[i].supplie.code;
                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order_op?supplie_code=" + code + "&project_id=" + this.state.datos.project_id + "&standard_id=" + Variables.indicator_id + "&month=" + this.state.month;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        tools[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }

            for (let i = 0; i < equipments.length; i++) {
                let code = equipments[i].supplie.code;
                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order_op?supplie_code=" + code + "&project_id=" + this.state.datos.project_id + "&standard_id=" + Variables.indicator_id + "&month=" + this.state.month;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        equipments[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }

            for (let i = 0; i < materials.length; i++) {
                let code = materials[i].supplie.code;
                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order_op?supplie_code=" + code + "&project_id=" + this.state.datos.project_id + "&standard_id=" + Variables.indicator_id + "&month=" + this.state.month;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        materials[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }

            for (let i = 0; i < workforce.length; i++) {
                let code = workforce[i].supplie.code;
                let url = __SERVER__ + "/packages/" + Variables.package_id + "/pum_cost_work_order_op?supplie_code=" + code + "&project_id=" + this.state.datos.project_id + "&standard_id=" + Variables.indicator_id + "&month=" + this.state.month;
                await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(ress => {
                    if (ress.status === 200) {
                        return ress.json();
                    }
                    return null;
                }).then(ress => {
                    if (ress !== null) {
                        workforce[i].remaining = ress[0];
                    }
                }).catch(e => {
                    console.log(e);
                });
            }

            this.state.loading = res.length === 0 ? null : false;
            this.state.toolsValues = new Array(tools.length).fill(0);
            this.state.equipmentsValues = new Array(equipments.length).fill(0);
            this.state.materialsValues = new Array(materials.length).fill(0);
            this.state.workforceValues = new Array(workforce.length).fill(0);

            this.state.toolsObservations = new Array(tools.length).fill(null);
            this.state.equipmentsObservations = new Array(equipments.length).fill(null);
            this.state.materialsObservations = new Array(materials.length).fill(null);
            this.state.workforceObservations = new Array(workforce.length).fill(null);
            this.setState({
                tools,
                equipments,
                materials,
                workforce
            });
        }).catch(e => {
            this.setState({
                tools: [],
                equipments: [],
                materials: [],
                workforce: [],
                loading: null
            });
            console.log(e);
        })
    }

    decimalAdjust = (type, value, exp) => {
        // Si el exp no está definido o es cero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    onClose = () => {
        this.setState({ show: false }, this.props.setThisNull)
    }

    deleteWorkOrder = () => {
        Swal.fire({
            title: `¿Desea eliminar la orden de Trabajo: ${this.state.work_orders[this.state.select].code}?`,
            text: 'Esta acción eliminará la orden y es irreversible.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, ¡Continuar!',
            cancelButtonText: 'Cancelar'
        }).then(result => {
            if (result.value) {
                this.setState({ disabled: true });
                fetch(__SERVER__ + `/packages/${Variables.package_id}/project/${Variables.project_id}/standard/${Variables.indicator_id}/work_order_op/${this.state.work_orders[this.state.select].id}`, {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json'
                    }
                }).then(res => {
                    if (res.status === 200) {
                        return res.json();
                    }
                    throw res.statusText;
                }).then(rows => {
                    if (rows === 1) {
                        this.state.work_orders.splice(this.state.select, 1);
                        this.state.select = this.state.work_orders.length === 0 ? -1 : 0;
                        if (this.state.select !== -1) {
                            if (this.state.work_orders[0].status === 0) {
                                this.getTools();
                            }
                        }
                        Swal.fire('Información', 'Borrado correctamente', 'success');
                    } else {
                        throw "Ocurrio un error";
                    }
                    this.setState({ disabled: false });
                }).catch(e => {
                    this.setState({ disabled: false });
                    Swal.fire('Error', e, 'danger');
                });
            }
        })
    }

    saveWorkOrder = () => {
        let direct_cost = [];
        let cost_work = [];

        for (let i = 0; i < this.state.tools.length; i++) {
            let item = this.state.tools[i];
            let itemV = parseFloat(this.state.toolsValues[i]);
            itemV = Number.isNaN(itemV) ? 0 : itemV;
            if (itemV !== 0) {
                cost_work.push({
                    id: item.remaining.id,
                    count: item.remaining.count - itemV
                });
                direct_cost.push({
                    supplie_code: item.supplie.code,
                    count: itemV,
                    amount: this.decimalAdjust('round', item.supplie.cost * itemV, -2),
                    observations: this.state.toolsObservations[i]
                });
            }
        }

        for (let i = 0; i < this.state.materials.length; i++) {
            let item = this.state.materials[i];
            let itemV = parseFloat(this.state.materialsValues[i]);
            itemV = Number.isNaN(itemV) ? 0 : itemV;
            if (itemV !== 0) {
                cost_work.push({
                    id: item.remaining.id,
                    count: item.remaining.count - itemV
                });
                direct_cost.push({
                    supplie_code: item.supplie.code,
                    count: itemV,
                    amount: this.decimalAdjust('round', item.supplie.cost * itemV, -2),
                    observations: this.state.materialsObservations[i]
                });
            }
        }

        for (let i = 0; i < this.state.equipments.length; i++) {
            let item = this.state.equipments[i];
            let itemV = parseFloat(this.state.equipmentsValues[i]);
            itemV = Number.isNaN(itemV) ? 0 : itemV;
            if (itemV !== 0) {
                cost_work.push({
                    id: item.remaining.id,
                    count: item.remaining.count - itemV
                });
                direct_cost.push({
                    supplie_code: item.supplie.code,
                    count: itemV,
                    amount: this.decimalAdjust('round', item.supplie.cost * itemV, -2),
                    observations: this.state.equipmentsObservations[i]
                });
            }
        }

        for (let i = 0; i < this.state.workforce.length; i++) {
            let item = this.state.workforce[i];
            let itemV = parseFloat(this.state.workforceValues[i]);
            itemV = Number.isNaN(itemV) ? 0 : itemV;
            if (itemV !== 0) {
                cost_work.push({
                    id: item.remaining.id,
                    count: item.remaining.count - itemV
                });
                direct_cost.push({
                    supplie_code: item.supplie.code,
                    count: itemV,
                    amount: this.decimalAdjust('round', item.supplie.cost * itemV, -2),
                    observations: this.state.workforceObservations[i]
                });
            }
        }

        if (direct_cost.length === 0) {
            Swal.fire('Advertencia', 'No a seleccionado ninguna unidad de Material, Equipo, Herramienta o Mano de Obra.', 'warning');
        } else {
            Swal.fire({
                title: '¿Completar Orden de Trabajo?',
                text: `El costo directo de la Orden asciende a $ ${this.decimalAdjust('round', (this.state.equipmentsTotal + this.state.toolsTotal + this.state.materialsTotal + this.state.workforceTotal), -2)}, una vez finalizada ya no se pueden hacer cambios`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, ¡Continuar!',
                cancelButtonText: 'Cancelar'
            }).then(result => {
                if (result.value) {
                    this.setState({ disabled: true });
                    fetch(__SERVER__ + `/packages/${Variables.package_id}/project/${Variables.project_id}/standard/${Variables.indicator_id}/work_order_op/${this.state.work_orders[this.state.select].id}/supplies`, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json'
                        },
                        body: JSON.stringify(direct_cost)
                    }).then(res => {
                        if (res.status === 200) {
                            return res.json();
                        }
                        throw res.statusText;
                    }).then(res => {
                        fetch(__SERVER__ + `/packages/${Variables.package_id}/pum_cost_work_order_op`, {
                            method: 'PUT',
                            headers: {
                                Accept: 'application/json'
                            },
                            body: JSON.stringify({
                                cost_work
                            })
                        }).then(res => {
                            if (res.status === 200) {
                                fetch(__SERVER__ + `/packages/${Variables.package_id}/project/${Variables.project_id}/standard/${Variables.indicator_id}/work_order_op/${this.state.work_orders[this.state.select].id}`, {
                                    method: 'PUT',
                                    headers: {
                                        Accept: 'application/json'
                                    },
                                    body: JSON.stringify({ status: 1 })
                                }).then(res => {
                                    if (res.status === 200) {
                                        this.state.work_orders[this.state.select].status = 1;
                                        Swal.fire('Registro correcto', 'La orden se termino de generar correctamente', 'success');
                                        this.setState({ refresh: !this.state.refresh });
                                    } else {
                                        throw res.statusText;
                                    }
                                }).catch(e => {
                                    Swal.fire('Error', e, 'error');
                                })
                            } else {
                                throw res.statusText;
                            }
                            this.setState({ disabled: false });
                        }).catch(e => {
                            console.log('Error', e);
                            Swal.fire('Error', e, 'error');
                            this.setState({ disabled: false });
                        });
                    }).catch(e => {
                        console.log(e);
                        Swal.fire('Error', e, 'error');
                        this.setState({ disabled: false });
                    })
                }
            })
        }
        console.log('DIRECT_COST', direct_cost);
        console.log('WORK_ORDER', cost_work);
    }

    getDocument = (type) => {
        this.setState({typeFile: type});
    }

    render() {
        const today = (dateUTC(new Date()).toISOString()).substring(0, 7);
        const columns = 8;
        const long_description = 15;
        return (
            <Modal show={this.state.show} onHide={this.onClose} backdrop="static" keyboard={false} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Orden de Trabajo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className="containerWorkOrder">
                        <section className="listWorkOrder">
                            <article>
                                {/*<Button variant={'success'} onClick={this.props.addWorkOrder}>Añadir orden nueva</Button>*/}
                            </article>
                            {
                                this.state.work_orders.map((item, i) =>
                                    <article id={"card_workOrder" + i} onClick={() => this.setState({ select: i }, () => { if (item.status === 0) { this.getTools() } else { this.setState({typeFile: 'HTML'}, this.getReport); } })} className={this.state.select === i ? "active_card" : ""}>
                                        <h2 for={"card_workOrder" + i}>{item.code}</h2>
                                        <label for={"card_workOrder" + i}>{dateToString(item.creation_date)}</label>
                                    </article>
                                )
                            }
                        </section>
                        <section className="suppliesWorkOrder">
                            {
                                this.state.select !== -1 ?
                                    <article className="headerSuppliesWorkOrder">
                                        {
                                            this.state.work_orders[this.state.select].status === 0 ? 
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label><strong>Autorización</strong></Form.Label><br />
                                                        <Form.Label>{this.state.work_orders[this.state.select].authorization}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label><strong>Visto Bueno</strong></Form.Label><br />
                                                        <Form.Label>{this.state.work_orders[this.state.select].vobo}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            : null
                                        }
                                        {
                                            this.state.work_orders[this.state.select].status === 0 ?
                                                <div className="centerDiv">
                                                    <ButtonGroup>
                                                        <Button disabled={this.state.disabled} variant='danger' onClick={this.deleteWorkOrder}>Eliminar Orden de Trabajo</Button>
                                                        <Button disabled={this.state.disabled} variant='primary' onClick={this.saveWorkOrder}>Completar Orden de Trabajo</Button>
                                                    </ButtonGroup>
                                                </div>
                                                : null
                                        }
                                    </article>
                                    : null
                            }
                            {
                                this.state.select === -1 ?
                                    <Alert variant={'primary'}>Debe seleccionar una Orden de trabajo</Alert>
                                    : this.state.loading === null ?
                                        <Alert variant='danger'>La información no esta disponible</Alert>
                                        : this.state.loading ?
                                            <Alert variant='primary'>Cargando...</Alert>
                                            : this.state.work_orders[this.state.select].status === 0 ?
                                                <table className="tableTools">
                                                    {/** Materiales */}
                                                    <tr>
                                                        <th colSpan={columns}>Materiales</th>
                                                    </tr>
                                                    <tr>
                                                        <th className={'center'}>Código</th>
                                                        <th className={'center'}>Descripción</th>
                                                        <th className={'center'}>Unidad</th>
                                                        <th className={'center'}>Disponible</th>
                                                        <th className={'center'}>PU</th>
                                                        <th className={'center'}>Asignación</th>
                                                        <th className={'center'}>Costo</th>
                                                        <th className={'center'}>Observaciones</th>
                                                    </tr>
                                                    {
                                                        this.state.materials.map((item, i) =>
                                                            <tr key={"MAT" + i}>
                                                                <td className={"center"}>{item.supplie.code}</td>
                                                                <td className={"left"}>
                                                                    {item.supplie.description.length > long_description ?
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={
                                                                                <Tooltip id={'tooltip-right'}>
                                                                                    {item.supplie.description}
                                                                                </Tooltip>}>
                                                                            <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description.substring(0, long_description - 3)}...</Button>
                                                                        </OverlayTrigger>
                                                                        : <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description}</Button>}
                                                                </td>
                                                                <td className={"center"}>{item.supplie.unit}</td>
                                                                <td className={"right"}>{item.remaining.count}</td>
                                                                <td className={"right"}>$ {item.supplie.cost}</td>
                                                                <td className={"center"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.materialsValues[i]} min={0}
                                                                    onChange={e => {
                                                                        if (e.target.value <= item.remaining.count) {
                                                                            this.state.materialsTotal -= this.decimalAdjust('round', (this.state.materialsValues[i] * item.supplie.cost), - 2);
                                                                            this.state.materialsTotal += this.decimalAdjust('round', (e.target.value * item.supplie.cost), -2);
                                                                            this.state.materialsValues[i] = e.target.value;
                                                                            this.setState({ refresh: !this.state.refresh });
                                                                        } else {
                                                                            Swal.fire('Cantidad incorrecta', `La cantidad elegida debe ser menor o igual a lo disponible\nDisponible: ${item.remaining.count}\nValor especificado: ${e.target.value}`, 'warning');
                                                                        }

                                                                    }} type="number" max={item.remaining.count} /></form></td>
                                                                <td className={"right"}>$ {this.decimalAdjust('round', item.supplie.cost * this.state.materialsValues[i], -2)}</td>
                                                                <td className={"left"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.materialsObservations[i]}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') {
                                                                            this.state.materialsObservations[i] = null;
                                                                        } else {
                                                                            this.state.materialsObservations[i] = e.target.value;
                                                                        }
                                                                        this.setState({ refresh: !this.state.refresh });
                                                                    }} /></form></td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        this.state.materials.length !== 0 ?
                                                            <tr>
                                                                <td colSpan={columns - 1} className={"right"}>
                                                                    <strong>Suma de Materiales</strong>
                                                                </td>
                                                                <td className={"right"}>
                                                                    <strong>$ {this.decimalAdjust('round', this.state.materialsTotal, -2)}</strong>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    }
                                                    {/** Mano de obra */}
                                                    <tr>
                                                        <th colSpan={columns}>Mano de obra</th>
                                                    </tr>
                                                    <tr>
                                                        <th className={'center'}>Código</th>
                                                        <th className={'center'}>Descripción</th>
                                                        <th className={'center'}>Unidad</th>
                                                        <th className={'center'}>Disponible</th>
                                                        <th className={'center'}>PU</th>
                                                        <th className={'center'}>Asignación</th>
                                                        <th className={'center'}>Costo</th>
                                                        <th className={'center'}>Observaciones</th>
                                                    </tr>
                                                    {
                                                        this.state.workforce.map((item, i) =>
                                                            <tr key={"WORK" + i}>
                                                                <td className={"center"}>{item.supplie.code}</td>
                                                                <td className={"left"}>
                                                                    {item.supplie.description.length > long_description ?
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={
                                                                                <Tooltip id={'tooltip-right'}>
                                                                                    {item.supplie.description}
                                                                                </Tooltip>}>
                                                                            <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description.substring(0, long_description - 3)}...</Button>
                                                                        </OverlayTrigger>
                                                                        : <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description}</Button>}
                                                                </td>
                                                                <td className={"center"}>{item.supplie.unit}</td>
                                                                <td className={"right"}>{item.remaining.count}</td>
                                                                <td className={"right"}>$ {item.supplie.cost}</td>
                                                                <td className={"center"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.workforceValues[i]} min={0}
                                                                    onChange={e => {
                                                                        if (e.target.value <= item.remaining.count) {
                                                                            this.state.workforceTotal -= this.decimalAdjust('round', (this.state.workforceValues[i] * item.supplie.cost), - 2);
                                                                            this.state.workforceTotal += this.decimalAdjust('round', (e.target.value * item.supplie.cost), -2);
                                                                            this.state.workforceValues[i] = e.target.value;
                                                                            this.setState({ refresh: !this.state.refresh });
                                                                        } else {
                                                                            Swal.fire('Cantidad incorrecta', `La cantidad elegida debe ser menor o igual a lo disponible\nDisponible: ${item.remaining.count}\nValor especificado: ${e.target.value}`, 'warning');
                                                                        }

                                                                    }} type="number" max={item.remaining.count} /></form></td>
                                                                <td className={"right"}>$ {this.decimalAdjust('round', item.supplie.cost * this.state.workforceValues[i], -2)}</td>
                                                                <td className={"left"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.workforceObservations[i]}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') {
                                                                            this.state.workforceObservations[i] = null;
                                                                        } else {
                                                                            this.state.workforceObservations[i] = e.target.value;
                                                                        }
                                                                        this.setState({ refresh: !this.state.refresh });
                                                                    }} /></form></td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        this.state.workforce.length !== 0 ?
                                                            <tr>
                                                                <td colSpan={columns - 1} className={"right"}>
                                                                    <strong>Suma de Mano de obra</strong>
                                                                </td>
                                                                <td className={"right"}>
                                                                    <strong>$ {this.decimalAdjust('round', this.state.workforceTotal, -2)}</strong>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    }
                                                    {/** Herramientas */}
                                                    <tr>
                                                        <th colSpan={columns}>Herramienta</th>
                                                    </tr>
                                                    <tr>
                                                        <th className={'center'}>Código</th>
                                                        <th className="center">Descripción</th>
                                                        <th className={'center'}>Unidad</th>
                                                        <th className={'center'}>Disponible</th>
                                                        <th className={'center'}>PU</th>
                                                        <th className={'center'}>Asignación</th>
                                                        <th className={'center'}>Costo</th>
                                                        <th className={'center'}>Observaciones</th>
                                                    </tr>
                                                    {
                                                        this.state.tools.map((item, i) =>
                                                            <tr key={"TOOL" + i}>
                                                                <td className={"center"}>{item.supplie.code}</td>
                                                                <td className={"left"}>
                                                                    {item.supplie.description.length > long_description ?
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={
                                                                                <Tooltip id={'tooltip-right'}>
                                                                                    {item.supplie.description}
                                                                                </Tooltip>}>
                                                                            <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description.substring(0, long_description - 3)}...</Button>
                                                                        </OverlayTrigger>
                                                                        : <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description}</Button>}
                                                                </td>
                                                                <td className={"center"}>{item.supplie.unit}</td>
                                                                <td className={"right"}>{item.remaining.count}</td>
                                                                <td className={"right"}>$ {item.supplie.cost}</td>
                                                                <td className={"center"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.toolsValues[i]} min={0}
                                                                    onChange={e => {
                                                                        if (e.target.value <= item.remaining.count) {
                                                                            this.state.toolsTotal -= this.decimalAdjust('round', (this.state.toolsValues[i] * item.supplie.cost), - 2);
                                                                            this.state.toolsTotal += this.decimalAdjust('round', (e.target.value * item.supplie.cost), -2);
                                                                            this.state.toolsValues[i] = e.target.value;
                                                                            this.setState({ refresh: !this.state.refresh });
                                                                        } else {
                                                                            Swal.fire('Cantidad incorrecta', `La cantidad elegida debe ser menor o igual a lo disponible\nDisponible: ${item.remaining.count}\nValor especificado: ${e.target.value}`, 'warning');
                                                                        }

                                                                    }} type="number" max={item.remaining.count} /></form></td>
                                                                <td className={"right"}>$ {this.decimalAdjust('round', item.supplie.cost * this.state.toolsValues[i], -2)}</td>
                                                                <td className={"left"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.toolsObservations[i]}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') {
                                                                            this.state.toolsObservations[i] = null;
                                                                        } else {
                                                                            this.state.toolsObservations[i] = e.target.value;
                                                                        }
                                                                        this.setState({ refresh: !this.state.refresh });
                                                                    }} /></form></td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        this.state.workforce.length !== 0 ?
                                                            <tr>
                                                                <td colSpan={columns - 1} className={"right"}>
                                                                    <strong>Suma de Herramienta</strong>
                                                                </td>
                                                                <td className={"right"}>
                                                                    <strong>$ {this.decimalAdjust('round', this.state.toolsTotal, -2)}</strong>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    }
                                                    {/** Equipos */}
                                                    <tr>
                                                        <th colSpan={columns}>Equipo</th>
                                                    </tr>
                                                    <tr>
                                                        <th className={'center'}>Código</th>
                                                        <th className="center">Descripción</th>
                                                        <th className={'center'}>Unidad</th>
                                                        <th className={'center'}>Disponible</th>
                                                        <th className={'center'}>PU</th>
                                                        <th className={'center'}>Asignación</th>
                                                        <th className={'center'}>Costo</th>
                                                        <th className={'center'}>Observaciones</th>
                                                    </tr>
                                                    {
                                                        this.state.equipments.map((item, i) =>
                                                            <tr key={"EQUI" + i}>
                                                                <td className={"center"}>{item.supplie.code}</td>
                                                                <td className={"left"}>
                                                                    {item.supplie.description.length > long_description ?
                                                                        <OverlayTrigger
                                                                            placement="right"
                                                                            overlay={
                                                                                <Tooltip id={'tooltip-right'}>
                                                                                    {item.supplie.description}
                                                                                </Tooltip>}>
                                                                            <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description.substring(0, long_description - 3)}...</Button>
                                                                        </OverlayTrigger>
                                                                        : <Button style={{ background: 'transparent', borderWidth: 0 }} size="sm" variant="light">{item.supplie.description}</Button>}
                                                                </td>
                                                                <td className={"center"}>{item.supplie.unit}</td>
                                                                <td className={"right"}>{item.remaining.count}</td>
                                                                <td className={"right"}>$ {item.supplie.cost}</td>
                                                                <td className={"center"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.equipmentsValues[i]} min={0}
                                                                    onChange={e => {
                                                                        if (e.target.value <= item.remaining.count) {
                                                                            this.state.equipmentsTotal -= this.decimalAdjust('round', (this.state.equipmentsValues[i] * item.supplie.cost), - 2);
                                                                            this.state.equipmentsTotal += this.decimalAdjust('round', (e.target.value * item.supplie.cost), -2);
                                                                            this.state.equipmentsValues[i] = e.target.value;
                                                                            this.setState({ refresh: !this.state.refresh });
                                                                        } else {
                                                                            Swal.fire('Cantidad incorrecta', `La cantidad elegida debe ser menor o igual a lo disponible\nDisponible: ${item.remaining.count}\nValor especificado: ${e.target.value}`, 'warning');
                                                                        }

                                                                    }} type="number" max={item.remaining.count} /></form></td>
                                                                <td className={"right"}>$ {this.decimalAdjust('round', item.supplie.cost * this.state.equipmentsValues[i], -2)}</td>
                                                                <td className={"left"}><form onSubmit={(e) => e.preventDefault()}><input value={this.state.equipmentsObservations[i]}
                                                                    onChange={e => {
                                                                        if (e.target.value === '') {
                                                                            this.state.equipmentsObservations[i] = null;
                                                                        } else {
                                                                            this.state.equipmentsObservations[i] = e.target.value;
                                                                        }
                                                                        this.setState({ refresh: !this.state.refresh });
                                                                    }} /></form></td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        this.state.equipments.length !== 0 ?
                                                            <tr>
                                                                <td colSpan={columns - 1} className={"right"}>
                                                                    <strong>Suma de Equipo</strong>
                                                                </td>
                                                                <td className={"right"}>
                                                                    <strong>$ {this.decimalAdjust('round', this.state.equipmentsTotal, -2)}</strong>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    }
                                                    <tr>
                                                        <td colSpan={columns - 1} className={"right"}>
                                                            <strong>Costo directo</strong>
                                                        </td>
                                                        <td className={"right"}>
                                                            <strong>{"$ " + this.decimalAdjust('round', (this.state.equipmentsTotal + this.state.toolsTotal + this.state.materialsTotal + this.state.workforceTotal), -2)}</strong>
                                                        </td>
                                                    </tr>
                                                </table>
                                                : this.state.report !== null ?
                                                    <section>
                                                        <div className="right-button">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                                    Descargar
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <XLSX report={this.state.report} extension={'xlsx'} label={'EXCEL'} />
                                                                    <XLSX report={this.state.report} extension={'csv'} label={'CSV'} /><br />
                                                                    <PDFDownloadLink document={<PDF report={this.state.report} flow={this.state.datos} />} fileName={`Orden_de_Trabajo_${this.state.report.headers.creation_date.split('T')[0].split('-').join('')}.pdf`} className={'PDFDownloadLink'}>
                                                                        {({ blob, url, loading, error }) => (loading ? 'PDF...' : 'PDF')}
                                                                    </PDFDownloadLink>
                                                                    
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            
                                                        </div>
                                                        {
                                                            this.state.typeFile === this.typeFile.PDF ?
                                                                <PDF report={this.state.report} />
                                                            :null
                                                        }
                                                        {
                                                            this.state.typeFile === this.typeFile.HTML ?
                                                                <article className="workOrderHeader">
                                                                    <label><strong>Orden de Trabajo: </strong>{this.state.report.headers.code}</label><br />
                                                                    <label><strong>Autorización: </strong>{this.state.report.headers.authorization}</label><br />
                                                                    {this.state.report.headers.description !== null ? <label><strong>Descripción: </strong>{this.state.report.headers.description}</label> : null}
                                                                    {this.state.report.headers.description !== null ? <br /> : null}
                                                                    <label><strong>Fecha: </strong>{dateToString2(this.state.report.headers.creation_date.split('T')[0])}</label><br />
                                                                </article>
                                                            : null
                                                        }
                                                        {
                                                            /*this.state.typeFile === this.typeFile.HTML ?*/
                                                                <Table striped bordered className="workOrderTable" id="emp">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="center">Unidades</th>
                                                                            <th className="center">Descripción</th>
                                                                            <th className="center">Precio</th>
                                                                            <th className="center">Observaciones</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="th">
                                                                        {
                                                                            this.state.report.supplies.map((item, i) =>
                                                                                <tr key={i}>
                                                                                    <td className="center">{item.count}</td>
                                                                                    <td className="left">{item.description}</td>
                                                                                    <td className="right">{item.amount}</td>
                                                                                    <td className="left">{item.observations}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            /*: null*/
                                                        }
                                                    </section>
                                                    : null
                            }
                        </section>
                    </section>
                </Modal.Body>
            </Modal>
        );
    }
}