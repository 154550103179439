import React, { useEffect, useState } from 'react'
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import { Alert, Button, Form, Jumbotron, Row } from 'react-bootstrap'
import { indexMaintenanceProgram } from 'api/operation_standards/maintenance_program';
import { indexMaintenanceActivities } from 'api/operation_standards/maintenance_activities';
import { FaFilePdf } from 'react-icons/fa';
import ReportsManPdf from './ReportsManPdf';
import { Heading } from '@chakra-ui/react';

const ReportsMan = ({ package_id, project_id }) => {

    const [MaintenanceProgram, setMaintenanceProgram] = useState(null)  //5
    const [assetActivities, setAssetActivities] = useState(null);       //4
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoadingData(true);
        await getAssetActivities();
        await getMaintenanceProgram();
        setLoadingData(false);
    }

    const getAssetActivities = async () => {
        let response = await indexMaintenanceActivities({ project_id });
        setAssetActivities(response.data);
    }

    const getMaintenanceProgram = async () => {
        let response = await indexMaintenanceProgram({ package_id, project_id });
        setMaintenanceProgram(response.data);
    }

    return (
        <Jumbotron style={{ marginTop: 10, marginLeft: 10 }} >
            <Form>
                <Heading color={'black'}>Reporte de Mantenimiento</Heading>
                <Row>
                    <Alert variant="info">
                        <Alert.Heading>Información</Alert.Heading>
                        <p>En esta sección se crean los reportes mensuales de Mantenimiento.</p>
                    </Alert>
                </Row>
                <Row className='mt-2'>
                    {
                        !loadingData ? <PDFDownloadLink document={<ReportsManPdf assetActivities={assetActivities} MaintenanceProgram={MaintenanceProgram} />} fileName="Reporte_Mantenimiento.pdf">
                            <Button className="mx-2" variant="success" ><FaFilePdf className="mr-1" />Reporte Listo para descargar</Button>
                        </PDFDownloadLink> : <h3>Cargando...</h3>
                    }
                    {/* {
                    (data) ? data.data.map((item, i) => 
                        <Button key={item.id} variant="danger" className="m-2" onClick={()=>hanldeReport(item.id)}>{i+1}.{item.code} {item.name}</Button>
                    ) : (<h3>Cargando...</h3>)
                }  */}
                </Row>
            </Form>

        </Jumbotron>
    )
}

export default ReportsMan