import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const SkeletonStack = (props) => {
    let { rows } = props;
    return (
        <>
            {
                Array.from({ length: rows }).map((_, index) =>
                    <Spinner key={index} animation="border" />
                )
            }
        </>
    );
}

SkeletonStack.propTypes = {
    rows: PropTypes.number
}

export default SkeletonStack;