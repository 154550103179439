import { Text } from '@chakra-ui/react'
import { faFileDownload, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { getUrlHumanResources } from 'api/users/reports'
import ButtonIcon from 'components/layouts/ButtonIcon'
import FormIcon from 'components/layouts/FormIcon'
import ModalComp from 'components/layouts/Modal'
import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import Swal from 'sweetalert2';

const DownloadFileModal = ({ onClose, userSel, package_id }) => {
	const [params, setParams] = useState({ type: 'obac' });
	const [loading, setLoading] = useState(false);

	const validateParams = () => {
		let response = true;
		if (!params.start_date) response = false;
		if (!params.end_date) response = false;
		return response;
	}

	const downloadPDF = async () => {
		if (!validateParams()) {
			Swal.fire('Datos incompletos', 'Verifique los datos antes de continuar.', 'warning');
			return;
		}
		setLoading(true);
		const { id } = userSel;
		const { response } = await getUrlHumanResources({ package_id, user_id: id, ...params });
		if (response) {
			const link = document.createElement('a')
			link.href = response.signedUrl;
			document.body.appendChild(link)
			link.click();
			onClose();
		}
		setLoading(false);
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setParams({ ...params, [name]: value })
	}

	return (
		<ModalComp
			onClose={onClose}
			size='md'
			title='Descargar Reporte'
			body={<>
				<Text>
					Introduzca la fecha de inicio y final para establecer los parámetros de búsqueda de las actividades asignadas al empleado {userSel.first_name} {userSel.last_name} con cargo de {userSel.position}.
				</Text>
				<Row className='mt-4'>
					<FormIcon title='Fecha inicial' type='date' name='start_date' handleChange={handleChange} />
				</Row>
				<Row>
					<FormIcon title='Fecha final' type='date' name='end_date' handleChange={handleChange} />
				</Row>
			</>}
			footer={<>
				<ButtonIcon
					icon={loading ? faSpinner : faFileDownload}
					name={loading ? 'Descargando PDF...' : 'Descargar PDF'}
					variant='outline-success'
					onClick={downloadPDF}
					disabled={loading}
					tooltipDisabled={true}
				/>
			</>}

		/>
	)
}

export default DownloadFileModal