import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TableComp from 'components/layouts/Table'
import FlexButton from 'components/layouts/FlexButton'
import { indexSuppliesPumServices } from 'api/maintenance_standards/supplies_pum_services'
import PaginationComp from 'components/layouts/Pagination'
import { faEdit, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import Filters from './fragments/Filters'
import ModalAddSupply from './fragments/ModalAddSupply'
import ModalEditSupply from './fragments/ModalEditSupply'
import LoadFromExcel from './fragments/LoadFromExcel'

const SuppliesComp = () => {
    const package_id = useSelector(state => state.login.dataUser.package_id);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);

    const [form, setForm] = useState({});
    const [filters, setFilters] = useState({});
    const [modalAdd, setModalAdd] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalUpload, setModalUpload] = useState(false);
    const [supplySel, setSupplySel] = useState(null);

    useEffect(() => {
        if (filters.str === '') delete filters.str;
        if (filters.type_description_id == -1) delete filters.type_description_id;
    }, [filters]);

    useEffect(() => {
        if (supplySel) {
            setForm(prevState => ({ ...prevState, type_description_id: supplySel?.type?.id }));
        } else {
            setForm({});
        }
    }, [supplySel]);

    useEffect(() => {
        if (!modalEdit) setSupplySel(null)
    }, [modalEdit]);

    useEffect(() => {
        getSuppliesPumServices();
    }, [page, pageSize]);

    const getSuppliesPumServices = async () => {
        setLoading(true);
        const { response } = await indexSuppliesPumServices({ package_id, page, pageSize, ...filters });
        if (response) {
            setData(response.data);
            setTotalItems(response.totalItems);
        }
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page); setPageSize(pageSize);
    }

    const edit = (item) => {
        setSupplySel(item);
        setModalEdit(true);
    }

    return (
        <>
            <Filters
                filters={filters}
                setFilters={setFilters}
                searchStr={getSuppliesPumServices}
            />
            <TableComp
                headers={['ID', 'Tipo', 'Código', 'Descripción', 'Unidad', 'Disponible', 'PU', 'Acciones']}
                keys={['id', 'type.name', 'code', 'description', 'unit', 'count', 'cost']}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faEdit, tooltip: 'Editar registro', variant: 'outline-primary', handleClick: edit }
                ]}
                badges={[{ index: 1 }]}
                currency={[{ index: 6 }]}
                badges_values={[{
                    col: 'type.name',
                    values: [
                        { color: '#008080', value: 'Materiales' },
                        { color: '#800080', value: 'Mano de Obra' },
                        { color: '#008000', value: 'Herramienta' },
                        { color: '#808000', value: 'Equipo' }
                    ]
                }]}
            />
            {/* Paginación */}
            <PaginationComp
                totalItems={totalItems}
                defaultPageSize={pageSize}
                current={page}
                onChange={changePage}
            />
            {/* Agregar suministro */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalAdd(true)} />
            {/* Cargar desde Excel */}
            <FlexButton style={{ bottom: 80, right: 20 }} icon={faFileExcel} onClick={() => setModalUpload(true)} />

            {/* Modal para agregar suministros */}
            {
                modalAdd && <ModalAddSupply
                    onClose={() => setModalAdd(false)}
                    form={form}
                    setForm={setForm}
                    syncServer={getSuppliesPumServices}
                    package_id={package_id}
                />
            }

            {/* Modal para editar suministros */}
            {
                modalEdit && <ModalEditSupply
                    onClose={() => setModalEdit(false)}
                    supplySel={supplySel}
                    form={form}
                    setForm={setForm}
                    syncServer={getSuppliesPumServices}
                    package_id={package_id}
                />
            }

            {/* Cargar desde excel */}
            {
                modalUpload && <LoadFromExcel
                    onClose={() => setModalUpload(false)}
                    syncServer={getSuppliesPumServices}
                    package_id={package_id}
                />
            }
        </>
    )
}

export default SuppliesComp