import React, { useEffect, useState } from 'react';
import './css/UsersType.css';
import { useSelector } from 'react-redux';
import { Col, Row, Skeleton, Tree } from 'antd';
import { indexPermissions, indexRolePermissions, indexRoles, storeRolePermissions } from 'api/general/permissions';
import { Heading, useDisclosure } from '@chakra-ui/react';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { allowModule } from 'components/libs/allowModule';

const UsersType = () => {
    const [initialCheckedKeys, setInitialCheckedKeys] = useState([]);   // Lista de permisos del rol seleccionado (no editable)
    const [checkedKeys, setCheckedKeys] = useState([]);                 // Permisos seleccionados (editable)
    const [halfCheckedKeys, setHalfCheckedKeys] = useState([]);         // Permisos parcialmente seleccionados
    const [treeData, setTreeData] = useState([]);                       // Lista de permisos del paquete
    const [roles, setRoles] = useState([]);                             // Lista de roles de usuario existentes
    const [roleSel, setRoleSel] = useState(null);                       // Rol seleccionado
    const [expandedKeys, setExpandedKeys] = useState([]);               // Permisos con hijos que están expandidos
    const [btnDsabled, setBtnDisabled] = useState(true);

    const loadingRoles = useDisclosure();
    const loadingPermissions = useDisclosure();
    const loadingRolePermissions = useDisclosure();
    const loadingSave = useDisclosure();

    const package_id = useSelector(state => state.login.dataUser.package_id);

    // Expandir por default W y WP
    useEffect(() => {
        if (treeData.length > 0) setExpandedKeys([1075, 1079]);
    }, [treeData]);

    // Todos los permisos que contiene el paquete
    useEffect(() => {
        const getPermission = async () => {
            loadingPermissions.onOpen();
            const { response } = await indexPermissions({ package_id });
            setTreeData(recursive(response))
            loadingPermissions.onClose();
        }
        getPermission();
    }, []);

    // Todos los roles que existen
    useEffect(() => {
        const getRoles = async () => {
            loadingRoles.onOpen();
            const { response } = await indexRoles({ package_id });
            setRoles(response);
            loadingRoles.onClose();
        }
        getRoles();
    }, []);

    // Si se selecciona un rol se obtienen los permisos
    useEffect(() => {
        const getRolePermissions = async () => {
            loadingRolePermissions.onOpen();
            const { response } = await indexRolePermissions({ user_type_id: roleSel.id, package_id });
            const nodes = getLastNodes(response);
            setInitialCheckedKeys(nodes);
            setCheckedKeys(nodes);
            loadingRolePermissions.onClose();
        }
        if (roleSel) getRolePermissions();
    }, [roleSel]);

    useEffect(() => {
        if (initialCheckedKeys.length !== checkedKeys.length)
            setBtnDisabled(false);
        else
            setBtnDisabled(true);
    }, [initialCheckedKeys, checkedKeys]);

    const recursive = (array) => {
        let response = [];
        for (const element of array) {
            const { id, description, sub } = element;
            let children = [];
            if (sub) children = recursive(sub);
            response.push({ title: `${description}`, key: id, children });
        }
        return response;
    }

    const getLastNodes = (array) => {
        let response = [];
        for (const element of array) {
            const { permission_id, sub } = element;
            if (sub) {
                response = response.concat(getLastNodes(sub));
            } else {
                response.push(permission_id)
            }
        }
        return response;
    }

    const findKeyInArray = (array, key) => {
        for (const element of array) {
            if (element.key === key) {
                return element; // Código encontrado, se devuelve el elemento completo
            }
            if (element.children) {
                const found = findKeyInArray(element.children, key); // Búsqueda recursiva en los elementos hijos
                if (found) {
                    return found; // Se encontró el código en los hijos, se devuelve el elemento completo
                }
            }
        }
        return null; // No se encontró el código en el arreglo
    };


    const onClickRole = (item) => setRoleSel(item);
    const onExpand = (expandedKeys) => setExpandedKeys(expandedKeys);

    const onCheck = (checkedKeysValue, e) => {
        setHalfCheckedKeys(e.halfCheckedKeys);
        setCheckedKeys(checkedKeysValue);
    };

    const savePermissions = async () => {
        if (!roleSel) {
            Swal.fire('¡Seleccione un rol!', 'Debe seleccionar un rol de usuario para editar los permisos.', 'info');
            return;
        }
        setBtnDisabled(true);
        loadingSave.onOpen();
        const data = [...checkedKeys, ...halfCheckedKeys].map((item) => {
            const permission = findKeyInArray(treeData, item);
            return {
                package_id,
                user_type_id: roleSel.id,
                permission_id: permission.key
            }
        })
        const { response } = await storeRolePermissions({ data });
        if (response) {
            Swal.fire('¡Permisos actualizados!', `Los permisos del rol "${roleSel.name}" han sido actualizados.`, 'success');
        } else {
            Swal.fire('¡Operación fallida!', `Los permisos del rol "${roleSel.name}" no se han podido actualizar.`, 'error');
        }
        loadingSave.onClose();
    }

    return (
        <Row className='roles-main'>
            <Col xs={24} sm={24} md={8} lg={6} className='roles-panel'>
                <Row className='roles-panel-title'>
                    <Heading size='md'>Roles de Usuario</Heading>
                </Row>
                <div className='roles-list'>
                    {
                        loadingRoles.isOpen ? <Skeleton active /> :
                            roles.map((item) =>
                                <Row key={item.id} className={`roles-list-row ${item === roleSel ? 'selected' : ''}`}>
                                    <Col className='roles-list-col' onClick={() => onClickRole(item)}>
                                        {item.name}
                                    </Col>
                                </Row>)
                    }
                </div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={18} className='roles-permissions'>
                <Row className='roles-permissions-title'>
                    <Heading size='md' color={'black'}>Permisos</Heading>
                </Row>
                {
                    loadingPermissions.isOpen && <Skeleton active />
                }
                {
                    loadingRolePermissions.isOpen && <Skeleton active />
                }
                <div className='roles-permissions-tree'>
                    {
                        !loadingPermissions.isOpen && !loadingRolePermissions.isOpen && roleSel &&
                        <Tree
                            checkable
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            treeData={treeData}
                            expandedKeys={expandedKeys}
                            onExpand={onExpand}
                        />
                    }
                </div>
                <Row className='roles-permissions-footer'>
                    {
                        allowModule({ permission: 'WAABA' }) &&
                        <ButtonIcon
                            name='Guardar'
                            icon={faSave}
                            onClick={savePermissions}
                            variant='outline-success'
                            tooltipDisabled={true}
                            disabled={btnDsabled}
                        />
                    }
                </Row>
            </Col>
        </Row>
    )
}

export default UsersType