import { createStore, combineReducers } from 'redux';
import reducerLogin from './reducers/Login';
import reducerBreadCrumb from './reducers/BreadCrumb';
import reducerProyectos from './reducers/App/Proyectos';
import reducerAjustes from './reducers/App/Ajustes';
import reducerAssets from './reducers/Assets';


import { CLOSE_SESSION } from './actions/actionTypes';

const appReducer = combineReducers({
    login: reducerLogin,
    proyectos: reducerProyectos,
    breadCrumb: reducerBreadCrumb,
    ajustes: reducerAjustes,
    assets: reducerAssets
});

const rootReducer = (state, action) => {
    if (action.type === CLOSE_SESSION) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default createStore(rootReducer);