import React from 'react';
import TabsComp from 'components/layouts/Tabs';
import CostWorkOrdersComp from '../fragments/CostWorkOrders';

const Materials = (props) => {
	const { package_id, standard_id } = props;
	return (
		<>
			<TabsComp
				tabPosition='left'
				tablist={['Materiales']}
				tabpanels={[
					<CostWorkOrdersComp
						key='1'
						package_id={package_id}
						standard_id={standard_id}
						type_description_id={1} // Materiales
					/>,
				]}
			/>
		</>
	)
}

export default Materials