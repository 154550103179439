import React, { useEffect, useState } from 'react';
import { getProjects } from 'api/general/getProjects';
import { Chart } from "react-google-charts"
import Search from './Search';
import moment from 'moment';
import { getChartIncidents } from 'api/dashboards/getChartIncidents';
import { indexObacIncidents } from 'api/operation_standards/incidents';
import { indexMaintenanceIncidents } from 'api/maintenance_standards/incidents';
import { ChakraProvider } from '@chakra-ui/react';
import Jumb from './Jumb';
import Deductive from './Deductive';
import { Bar } from 'react-chartjs-2';
import { indexAssets } from 'api/operation_standards/assets';
import _ from 'lodash'
import ReactApexChart from 'react-apexcharts'
import { Variables } from 'variables/Variables';
import Axios from 'axios';
import { __SERVER__ } from 'server/info';
import { Button } from 'react-bootstrap';
import { Skeleton } from 'antd';

const getOptionsIncidents = text =>{
    const options = {
        title: text,
        vAxis: { title: "Número de Incidentes" },
        hAxis: { title: "Estándar" },
        seriesType: "bars",
    };
    return options;
}

const getNameProject = id => {
    if (id) {
        switch (id) {
            case '38': return 'Tramo II - Carretera Federal Huamantla - Cuapiaxtla - Libres';
            case '39': return 'Tramo III - Entronque Libres-Oriental - Cantona-Entronque Perote';
            case '40': return 'Libramiento Perote - Guadalupe Victoria - Martínez de la torre - Entronque Perote - Xalapa';
            default: return 'Tramo I - Autopista Puebla-Orizaba Amozoc - Ixtenco - Cuapiaxtla';
        }
    } else {
        return 'Tramo I - Autopista Puebla-Orizaba Amozoc - Ixtenco - Cuapiaxtla';
    }
}

const getOptions = text => {
    const option = {
        scales: { yAxes: [{ ticks: { beginAtZero: true} } ] },
        title: {
          display: true,
          text,
          fontSize: 20,
        },
        legend: {
          display: true,
          position: 'top',
        },
    };
    return option 
}

const getRandomRgb = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
}

const getDataChat = (labels,verified,notVerified,ParVerified ) => {
    const dataGraf = {
        labels,
        datasets: [
            {
                label: 'Verificado',
                backgroundColor: getRandomRgb(),
                data: verified
            },
            {
                label: 'Sin Verificar',
                backgroundColor: getRandomRgb(),
                data: notVerified
            },
            {
                label: 'Parcialmente Verificado',
                backgroundColor: getRandomRgb(),
                data: ParVerified
            },
        ]
    };

    return dataGraf;
}

const optionsAPX= {
    xaxis : { categories: ['Recorrido','CCO/JI','Pers. Caseta','Usuarios','Correo electrónico','Página Web','Poste SOS']},
    colors: '#9D1482'
};

const optionsAPXS= {
    xaxis : { categories: ['En espera', 'Atendiendo', 'Resuelto', 'No resuelto', 'No definido']},
    colors: '#3275FC'
};

const DashboardEjecutivo = () => {
    const [Dat, setDat] = useState(null)
    const [Obac, setObac] = useState(null)
    const [project, setproject] = useState(null)
    const [ObacITS, setObacITS] = useState(null)
    const [filters, setfilters] = useState(null)
    const [NoData, setNoData] = useState(false)
    const [DedEmpy, setDedEmpy] = useState(false)
    const [ObacEmpy, setObacEmpy] = useState(false)
    const [projectID, setprojectID] = useState(null)
    const [DedNameEmpy, setDedNameEmpy] = useState(false)
    const [chartDedective, setchartDedective] = useState(null)

    const [ObacEdification, setObacEdification] = useState(null)
    const [DeductiveByName, setDeductiveByName] = useState(null)
    const [ObacEnvironment, setObacEnvironment] = useState(null)
    const [ObacInfrastructure, setObacInfrastructure] = useState(null)

    const [ObacOneEmpy, setObacOneEmpy] = useState(false)
    const [ObacTwoEmpy, setObacTwoEmpy] = useState(false)
    const [ObacFourEmpy, setObacFourEmpy] = useState(false)
    const [ObacThreeEmpy, setObacThreeEmpy] = useState(false)

    const [Change, setChange] = useState(1)

    const [form, setForm] = useState({
        creation_dateS: '',
        creation_dateE: '',
        project_id: ''
    })

    useEffect(() => {getProject()}, [])
    useEffect(() => {getData()}, [projectID, filters])
    useEffect(() => {getDataAUX()}, [projectID,Change])

    let totalsByMonth = {};
    let invertedTotalsByMonth = {};

    const getDataDeductive = datos => {
        datos.forEach(item => {
            const month = moment(item?.creation_date).format('MMMM');
            totalsByMonth[month] = (totalsByMonth[month] || 0) + (item?.deductive || 0);
        });

        for(const mont in totalsByMonth) {
            invertedTotalsByMonth[mont] = 100 - totalsByMonth[mont];
        }

        const resultArray = Object.entries(invertedTotalsByMonth).map(([name, deductive], i) => {
            return { key: i, name, deductive }
        });

        setchartDedective(resultArray);
    }

    const getProject = async () => {
        const res = await getProjects()
        const projectIds = res.map(item => item.id)
        setproject(res)
        setprojectID(projectIds)
    }

    const dataGraf = e => {
        let result = [];
        for (const element of e) {
            let containsNonZero = false;
            for (let j = 1; j <= 7; j++) {
                if (element[j] !== 0) {
                    containsNonZero = true;
                    break;
                }
            }
            (containsNonZero) && (result.push(element));
        }
        return result;
    }

    const getObacData = async projectIds => {
        let objectParams = filters ? { project_id: filters?.project_id || projectIds[0], created_at_start: filters?.creation_dateS, created_at_end: filters?.creation_dateE } : { project_id: projectIds[0] }

        const { data } = await indexObacIncidents(objectParams);

        getCountDataObac(data)
        
    }

    const getDeductiveByName = datos => {
        const sumByStandardName = {};
        for (const obj of datos) {
            if (obj.deductive && obj.maintenance_standard_name) {
                const standardName = obj.maintenance_standard_name;
                if (!sumByStandardName[standardName]) sumByStandardName[standardName] = 0;
                sumByStandardName[standardName] += obj.deductive;
            }
        }        
        const resultArray = Object.keys(sumByStandardName).map(key => {
            return { name: key, deductive: (100 - parseInt(sumByStandardName[key])) };
        });
        setDeductiveByName(resultArray)
    }

    const getIncidents = async projectIds => {
        let objectParams = filters ? { project_id: filters?.project_id || projectIds[0], creation_date_start: filters?.creation_dateS, creation_date_end: filters?.creation_dateE } : { project_id: projectIds[0] }

        const { data } = await indexMaintenanceIncidents(objectParams);
        if (data?.length > 0) {
            getDataDeductive(data)
            getDeductiveByName(data)
        } else {
            setDedEmpy(true)
            setDedNameEmpy(true)
        }
    }

    const getData = async () => {
        setNoData(false);
        setObacEmpy(false)
        setDedEmpy(false)
        setDedNameEmpy(false)

        setObacOneEmpy(false)
        setObacTwoEmpy(false)
        setObacFourEmpy(false)
        setObacThreeEmpy(false)

        setDat(null);
        setObac(null)
        setchartDedective(null)
        setDeductiveByName(null)

        setObacITS(null)
        setObacEdification(null)
        setObacInfrastructure(null)
        setObacEnvironment(null)

        const data = await getChartIncidents(filters)
        const dataNonZero = dataGraf(data)
        if(dataNonZero.length > 1){
            setDat(dataNonZero)
        } else {
            setNoData(true)
        }

        if (projectID) {
            getObacData(projectID)
            getIncidents(projectID)
            getObacEvaluations(projectID)
        }
    }

    const dataGrafObac = (data,setState) => {

        const labels = _.uniq(_.map(_.filter(data, (o) => o.type_name), "type_name"));

        const verified = _.map(labels, (label) => 
            _.filter(data, { type_name: label, check_status: "Verificado" }).length
        );

        const notVerified = _.map(labels, (label) => 
            _.filter(data, { type_name: label, check_status: "Sin Verificar" }).length
        );

        const ParVerified = _.map(labels, (label) => 
            _.filter(data, { type_name: label, check_status: "Parcialmente Verificado" }).length
        );

        const dataToGraf = getDataChat(labels, verified, notVerified, ParVerified);
        
        setState(dataToGraf)
    }

    const getObacEvaluations = async projectIds => {
        const getParams = i => {
            const tables = ['assets_its', 'assets_edification','assets_infrastructure','assets_environment']

            let objectParams = filters 
                                ? { 
                                    project_id: filters?.project_id || projectIds[0],
                                    creation_date_start: filters?.creation_dateS, 
                                    creation_date_end: filters?.creation_dateE,
                                    table: tables[i]
                                  } 
                                : { project_id: projectIds[0], table: tables[i] }
            return objectParams;
        }

        /* let objectParams = { project_id: projectIds[0], table: tables[0]} */
        const dataITS = await indexAssets(getParams(0));
        const dataEdification = await indexAssets(getParams(1));
        const dataInfrastructure = await indexAssets(getParams(2));
        const dataEnvironment = await indexAssets(getParams(3));

        if (dataITS?.data?.length > 0) {
            dataGrafObac(dataITS.data,setObacITS)
        } else {
            setObacITS(null)
            setObacOneEmpy(true)
        }

        if (dataEdification?.data?.length > 0) {
            dataGrafObac(dataEdification.data,setObacEdification)
        } else{
            setObacEdification(null)
            setObacTwoEmpy(true)
        }

        if (dataInfrastructure?.data?.length > 0) {
            dataGrafObac(dataInfrastructure.data,setObacInfrastructure)
        } else {
            setObacInfrastructure(null)
            setObacThreeEmpy(true)
        }

        if (dataEnvironment?.data?.length > 0) {
            dataGrafObac(dataEnvironment.data,setObacEnvironment)
        } else {
            setObacEnvironment(null)
            setObacFourEmpy(true)
        }
    }

    let count = {};
    const getCountDataObac = data => {
        if (data.length > 0 ) {
            dataGrafp(data)
            let result = [["Standard", "Creado", "Levantado", "Atendiendo", "Cerrado", "Cerrado con retraso", "No hecho", "Completado"]];
            Object.keys(count).forEach(standardCode => {
                const row = [standardCode];
                Object.keys(count[standardCode]).forEach(statusName => {
                    row.push(count[standardCode][statusName]);
                });
                result.push(row);
            });

            for (const element of result) {
                (element.length === 9 && isNaN(element[8])) && (element.pop())
            }
            setObac(result)
        } else {
            setObac(null)
            setObacEmpy(true)
        }
    }

    const dataGrafp = data => {
        data.forEach(item => {
            const standardCode = item.segment.location;
            const statusName = item.status.name;

            if (!count[standardCode]) {
                count[standardCode] = {
                    "Creado": 0,
                    "Levantado": 0,
                    "Atendiendo": 0,
                    "Cerrado": 0,
                    "Cerrado con retraso": 0,
                    "No hecho": 0,
                    "Completado": 0
                };
            }

            count[standardCode][statusName]++;
        });
    }

    const [filterAux, setFilterAux] = useState({
        filter: '',
        initialDateAUX: '',
        finalDateAUX: ''
    })

    const changeAUX = ({target}) => {
        const { name, value} = target;
        setFilterAux({
            ...filterAux,
            [name] : value
        })
    }

    const getTypeFirstInput = (type="date") => {
        const types = {
            "Dia": "date",
            "Semana": "week",
            "Mes": "Month",
            "Periodo": "date" 
        }

        return types[type];
    }

    const [series, setSeries] = useState([{
        name: 'Reportes Recibidos',
        data: []
    }]);

    const [seriesStatus, setSeriesStatus] = useState([{
        name: 'Reportes por estatus',
        data: []
    }]);

    const [ShowApex, setShowApex] = useState(false)
    

    const getDataAUX = async () => {
        if(projectID){
            try {
                setShowApex(false)
                let { data } = await Axios.get(`${__SERVER__}/victum_users/project/${projectID[0]}/road_aid`);
                if(data?.data?.length > 0){
                    let DataFilter = null;
                    if(filterAux.filter === 'Dia'){
                        function filterByReportDate(data, initialDate) {
                            return _.filter(data, function(item) {
                              return _.isEqual(_.get(item, 'report_date').substring(0, 10), initialDate);
                            });
                        }

                        DataFilter = filterByReportDate(data.data, filterAux.initialDateAUX)
                    }

                    if(filterAux.filter === 'Semana' || filterAux.filter === 'Mes' || filterAux.filter === 'Periodo'){
                        let startOf = filterAux.initialDateAUX;
                        let endOf = filterAux.finalDateAUX;
                        if(filterAux.filter === 'Semana'){
                            const week = moment(filterAux.initialDateAUX )
                            startOf = week.startOf('week').format('YYYY-MM-DD')
                            endOf = week.endOf('week').format('YYYY-MM-DD');
                        }

                        if(filterAux.filter === 'Mes'){
                            const month = moment(`${filterAux.initialDateAUX}-01`);
                            startOf = `${filterAux.initialDateAUX}-01`;
                            endOf = month.endOf('month').format('YYYY-MM-DD');
                        }

                        const filteredData = _.filter(data.data, function(item) {
                            const reportDate = new Date(item.report_date);
                            const startDate = new Date(startOf);
                            const endDate = new Date(endOf);
                            return reportDate >= startDate && reportDate <= endDate;
                        });

                        DataFilter = filteredData;
                    }

                    const counts = _(DataFilter || data.data)
                        .groupBy('notice_form_id')
                        .map((value, key) => [Number(key), value.length])
                        .sortBy(0)
                        .value();
    
                    const result = Array.from({ length: 7 }, (_, i) => {
                        const item = counts.find(([key, _]) => key === i + 1);
                        return item ? item[1] : 0;
                    });
                    const nuevoSeries = [...series];
                    nuevoSeries[0].data = result;
                    setSeries(nuevoSeries)
    
                    const countStatus = _(DataFilter || data.data)
                        .groupBy('status')
                        .map((value, key) => [Number(key), value.length])
                        .sortBy(0)
                        .value();
    
                    const resultStatus = Array.from({ length: 5 }, (_, i) => {
                        const item = countStatus.find(([key, _]) => key === i );
                        return item ? item[1] : 0;
                    });
                    const nuevoSerie = [...seriesStatus];
                    nuevoSerie[0].data = resultStatus;
                    setSeriesStatus(nuevoSerie)
                    setShowApex(true)
                } 
            } catch (error) {
                console.log(error)
            }
        }
    }

    const searchAUX = () => {
        const {filter, initialDateAUX, finalDateAUX} = filterAux;
        if(filter === '' || initialDateAUX === '' ) return;
        if(filter === 'Periodo' && finalDateAUX === '' ) return; 

        setChange(Change+1)
    }

    return (
        <>
            <Search project={project} setForm={setForm} form={form} setfilters={setfilters} />
            <div className={`d-flex ${(Variables.package_id===13)?'flex-column-reverse':'flex-column'} `}>
                {
                    Dat && <h3 className='text-center bg-white pt-2 m-0 text-dark'>
                        {form?.creation_dateS && moment(form?.creation_dateS).format('D [de] MMMM [de] YYYY')} - {form?.creation_dateE && moment(form?.creation_dateE).format('D [de] MMMM [de] YYYY')} - {getNameProject(form?.project_id)}
                    </h3>
                }
                {
                    Dat ? <Chart
                        chartType="ComboChart"
                        width="100%"
                        height="600px"
                        data={Dat}
                        options={getOptionsIncidents("Incidentes por Estándar")}
                    /> : <Jumb empy={NoData} text="Incidentes por Estándar" />
                }
                {
                    Obac && <h3 className='text-center bg-white pt-2 m-0 text-dark'>
                        {form?.creation_dateS && moment(form?.creation_dateS).format('D [de] MMMM [de] YYYY')} - {form?.creation_dateE && moment(form?.creation_dateE).format('D [de] MMMM [de] YYYY')} - {getNameProject(form?.project_id)}
                    </h3>
                }
                {
                    Obac ? <Chart
                        chartType="ComboChart"
                        width="100%"
                        height="600px"
                        data={Obac}
                        options={getOptionsIncidents("Incidentes por Estándar de OBAC")}
                    /> : <Jumb empy={ObacEmpy} text="Incidentes por Estándar de OBAC" /> 
                    
                }

                <ChakraProvider>
                    {
                        chartDedective 
                            ? <Deductive title='CALIFICACIÓN DE DEDUCTIVAS (O DEDUCTIVA TOTAL DEL MES)' data={chartDedective} />
                            : <Jumb empy={DedEmpy} text='CALIFICACIÓN DE DEDUCTIVAS (O DEDUCTIVA TOTAL DEL MES)' /> 
                    }
                    {
                        DeductiveByName 
                            ? <Deductive title='CALIFICACIÓN DE DEDUCTIVA POR ESTÁNDAR' data={DeductiveByName} />
                            : <Jumb empy={DedNameEmpy} text='CALIFICACIÓN DE DEDUCTIVA POR ESTÁNDAR' /> 
                    }
                    {/* {
                        dataEvaluations && <Evaluations title='EVALUACIÓN DE BIENES AFECTOS A LA CONSECIÓN' data={dataEvaluations} project_id={projectID[0] || form?.project_id } />
                    } */}
                </ChakraProvider>
                {
                    ObacInfrastructure ? <div className='bg-white p-3' >
                                            <Bar 
                                            options={getOptions('OBAC EQUIPO Y BIENES DE INFRAESTRUCTURA')} 
                                            data={ObacInfrastructure} />
                                        </div> 
                                        : <Jumb empy={ObacThreeEmpy} text='OBAC EQUIPO Y BIENES DE INFRAESTRUCTURA' />
                }

                {
                    ObacEdification ? <div className='bg-white p-3' >
                                        <Bar 
                                            options={getOptions('OBAC MANTENIMIENTO DE EQUIPOS Y BIENES DE EDIFICACIÓN')} 
                                            data={ObacEdification} />
                                        </div>
                                    : <Jumb empy={ObacTwoEmpy} text='OBAC MANTENIMIENTO DE EQUIPOS Y BIENES DE EDIFICACIÓN' /> 
                }

                {
                    ObacEnvironment ? <div className='bg-white p-3' >
                                        <Bar 
                                            options={getOptions('OBAC MANTENIMIENTO DE EQUIPOS DE MEDIO AMBIENTE')} 
                                            data={ObacEnvironment} /> 
                                        </div>
                                    : <Jumb empy={ObacFourEmpy} text='OBAC MANTENIMIENTO DE EQUIPOS DE MEDIO AMBIENTE' />
                }
                {
                    ObacITS ? <div className='bg-white p-3' >
                                <Bar 
                                    options={getOptions('OBAC ITS')} 
                                    data={ObacITS} /> 
                                </div>
                            : <Jumb empy={ObacOneEmpy} text='OBAC ITS' /> 
                }

                <div className='bg-white p-3'>
                    <div className='d-flex justify-content-center border m-1 p-2'>
                            <div className='d-flex flex-column align-items-center' >
                                <label>Filtro por:</label>
                                <select name="filter" onChange={changeAUX}>
                                    <option value=''>Elige un filtro</option>
                                    {
                                        ["Dia", "Semana", "Mes", "Periodo"].map(item=><option key={item} value={item}>{item}</option>)
                                    }     
                                </select>
                            </div>
                            <div className='d-flex flex-column align-items-center mx-5'>    
                                <label>Fecha Inicial</label>
                                <input type={getTypeFirstInput(filterAux.filter)} name="initialDateAUX" onChange={changeAUX} disabled={ (filterAux.filter === "")?true:false } />
                            </div>
                            {
                                (filterAux.filter === 'Periodo') && <div className='d-flex flex-column align-items-center mr-5'>    
                                    <label>Fecha Final</label>
                                    <input type='date' name="finalDateAUX" onChange={changeAUX} />
                                </div>
                            }
                            <Button onClick={searchAUX} >Buscar</Button>
                    </div>
                    {
                        ShowApex 
                          ? <div>
                                <div className='w-50 d-inline-block'>
                                    <p className='h4 m-0 text-center'>Reportes Recibidos</p>
                                    <ReactApexChart 
                                        options={optionsAPX} 
                                        series={series} 
                                        type="bar" 
                                        height={500} 
                                    />
                                </div>

                                <div className='w-50 d-inline-block'>
                                    <p className='h4 m-0 text-center'>Reportes por estatus</p>
                                    <ReactApexChart 
                                        options={optionsAPXS} 
                                        series={seriesStatus} 
                                        type="bar" 
                                        height={500} 
                                    />
                                </div>
                            </div>
                          : <Skeleton active />
                    }
                </div>

            </div>
        </>
    )
}

export default DashboardEjecutivo