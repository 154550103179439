import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import '../UOV-0/Operacion.css';
import '../UOV-0/Resultados.css';
import Inventory from './Inventario';
import { Variables } from '../../../../variables/Variables';
import { __SERVER__ } from '../../../../server/info';
import {
	Modal,
	Media,
	InputGroup,
	FormControl,
	Spinner,
	Alert,
	Button,
	Card,
	Container,
	Form,
	Badge,
	Row,
	Col,
	Tooltip,
	OverlayTrigger
} from 'react-bootstrap';
import { FaPaperPlane, FaFileContract, FaExchangeAlt, FaFileDownload, FaMapMarker, FaUpload, FaEye } from 'react-icons/fa';
import Pagination from '../Pagination';
import FAB from '../../../generales/FAB.jsx';
import ModalUpload from '../Modal.jsx';
import Deductiva from '../Deductiva.jsx';
import Axios from 'axios';
import moment from 'moment';
import victum_users_logo from '../../../imagenes-app/victum_users.jpg';
import user_operation from '../../../imagenes-app/user_operation.png';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import Map from '../Mantemiento/Inventarios/Map';
import { Heading } from '@chakra-ui/react';

const MySwal = withReactContent(Swal);
const axios = require('axios');
const _ = require('underscore');

const MyDoc = () => (
	<Document>
		<Page>
		</Page>
	</Document>
);

const FiltroBusqueda_ = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				application_dateS: "",
				application_dateE: "",
				status: "",
				project_id: Variables.project_id
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: "",
		};
		this.generateQuery = this.generateQuery.bind(this)
	}

	generateQuery = () => {
		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "") {
				this.state.query_ += (this.state.quety_ === '' ? '?' : '&') + keys_query[i] + "=" + value_query[i];
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.props.setProps(this.state.query_, headersTable)
	}

	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="application_dateS" value={this.state.query.application_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="application_dateE" value={this.state.query.application_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<label>Status</label>
					<select name="status" value={this.state.query.status} onChange={this.changeInput}>
						<option value=""></option>
						<option value="0">En proceso</option>
						<option value="1">Aprobado</option>
						<option value="2">Cancelado</option>
						<option value="3">No aprobado</option>
					</select>
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const FiltroBusqueda = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			query: {
				OAS_contact_dateS: "2015-11-01",
				OAS_contact_dateE: "2019-11-02"
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: ""
		};
		this.generateQuery = this.generateQuery.bind(this)
	}

	generateQuery = () => {
		if (this.state.query.OAS_contact_dateE === "" || this.state.query.OAS_contact_dateS === "") {
			MySwal.fire(<p>El rango de fechas es obligatorio</p>)
			return;
		}

		this.state.query_ = "?"

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "0") {
				this.state.query_ += keys_query[i] + "=" + value_query[i] + "&";
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.state.query_ = (this.state.query_).substring(0, this.state.query_.length - 1)
		this.props.setProps(this.state.query_, headersTable)
	}


	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="OAS_contact_dateS" value={this.state.query.OAS_contact_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="OAS_contact_dateE" value={this.state.query.OAS_contact_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const Grid = class extends Component {
	constructor(props) {
		super(props)
		this.state = {
			item: {},
			modal: false
		}
	}

	render() {
		return (
			<div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.data_per_page}</label>
				</section>
				<div className="cards">
					{
						this.props.data.data.map(item =>
							<div className="card">
								<div className="card-header"><strong>Expediente: {item.file_number}</strong></div>
								<div><strong>Fecha de contaco OAS:</strong> {item.OAS_contact_date.split('.')[0]} </div>
								<div><strong>Solicitante:</strong> {item.applicant} </div>
								<div><strong>Estado:</strong> {item.state} </div>
								<div><strong>Medio:</strong> {item.means_reception} </div>
								{(item.request_response_date !== null) ? <div><strong>Fecha de respuesta:</strong> {item.request_response_date.split('.')[0]}</div> : null}
								<Button onClick={() => this.setState({ modal: true, item })} variant={'link'}>Más detalles</Button>
							</div>)
					}
				</div>
				<Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} />
				<Modal show={this.state.modal} backdrop="static" keyboard={false} onHide={() => this.setState({ modal: false, item: null })}>
					<Modal.Header closeButton>
						{this.state.item !== null ? <Modal.Title>{this.state.item.file_number}</Modal.Title> : null}
					</Modal.Header>
					{this.state.item !== null ?
						<Modal.Body>
							<div><strong>Descripción de la solicitud: </strong> {this.state.item.description_request} </div>
							<div><strong>Descripción de la respuesta: </strong> {this.state.item.response_description} </div>
						</Modal.Body> : null}
					<Modal.Footer onClick={() => this.setState({ modal: false, item: null })}>
						<Button variant="danger">Cerrar</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

const ModalCard = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			uploading: false,
			// Initially, no file is selected
			contract_name: '',
			completed_date: '',
			selectedFile: '',
			description: '',
			periodicity: 30,
			amount: 0,
			victum_users: []
		}
	}

	componentDidMount = async () => {
		let victum_users = await axios.get(`${__SERVER__}/victum_users`);
		this.setState({ victum_users: victum_users.data });
		setTimeout(() => this.setState({ show: true }), 100);
	}

	handleClose = () => {
		if (this.state.uploading) {
			Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
			return;
		}
		this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
	}

	// On file select (from the pop up)
	onFileChange = event => {
		// Update the state
		this.setState({ selectedFile: event.target.files[0] });
	}

	validateFileds = () => {
		let { contract_name, completed_date, description, periodicity, amount } = this.state;
		let validate = true;
		try {
			if (contract_name === '' || contract_name === null) return false;
			if (completed_date === '' || completed_date === null) return false;
			if (description === '' || description === null) return false;
			if (periodicity === 0 || periodicity === null) return false;
			if (amount === 0 || amount === null) return false;
		} catch (error) {
			validate = false;
		}
		return validate;
	}
	// On file upload (click the upload button)
	onFileUpload = async (e) => {
		e.preventDefault();
		// crear un objeto de formData
		const formData = new FormData();
		if (this.state.selectedFile === null) {
			Swal.fire('Error', '¡No hay un archivo seleccionado!', 'warning');
		} else if (this.validateFileds() !== true) {
			Swal.fire('Error de validación', '¡Verifique que el valor de los campos sea correcto!', 'warning');
		} else {

			// Actualizar el objeto formData
			formData.append(
				"file",
				this.state.selectedFile
			);
			// Payload
			let data = {
				user_id: Variables.user.id,
				victum_user_id: this.state.victum_users[_.findIndex(this.state.victum_users, { email: this.props.item.email })].id,
				name: this.state.contract_name,
				description: this.state.description,
				requested_date: moment(this.props.item.application_date).format('YYYY-MM-DD'),
				assigned_date: moment().format('YYYY-MM-DD'),
				completed_date: moment(this.state.completed_date).format('YYYY-MM-DD'),
				periodicity: this.state.periodicity,
				amount: this.state.amount
			};
			//Crear contrato
			let contract = await axios.post(`${__SERVER__}/projects/${Variables.project_id}/contracts`, data);
			if (contract.status === 200) {
				//Cargar archivo de contrato
				// Petición al backend, enviar el objeto formData
				let response = await Axios.post(`${__SERVER__}/projects/${Variables.project_id}/contracts/${contract.data}/user/${this.props.item.id}/upload`, formData);
				if (response.status === 200) {
					Swal.fire('Contrato asignado', '¡El contracto ha sido asignado satisfactoriamente!', 'success');
					//Actualizar el seguimiento a contrato asignado
					response = await axios.put(`${__SERVER__}/victum_users/victum_right_way/${this.props.item.id}`, { status: 1 });
					this.handleClose();
				}
			}
		}
	}

	fileData = () => {
		if (this.state.selectedFile) {
			return (
				<div>
					<h2>Detalles del archivo:</h2>
					<p><strong>Nombre:</strong> {this.state.selectedFile.name}</p>
					<p><strong>Tipo:</strong> {this.state.selectedFile.type}</p>
					<p><strong>
						Última modificación:</strong>{" "}
						{this.state.selectedFile.lastModifiedDate.toDateString()}
					</p>
				</div>
			);
		} else {
			return (
				<div>
					<br />
					<h5>Elija su archivo antes de cargarlo</h5>
				</div>
			);
		}
	};

	handleChange = (event) => {
		let { name, value } = event.target;
		this.setState({ [name]: value });
	}

	render = () => {
		return (
			<Modal //Propiedades del modal
				animation={true}
				show={this.state.show}
				onHide={this.handleClose}
				size="md"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.title}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						<Form.Row>
							<Form.Group as={Col}>
								<Form.Label>Nombre del contrato</Form.Label>
								<Form.Control type="text" name="contract_name" placeholder="Escriba el nombre..." onChange={this.handleChange} />
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Finalización del contrato</Form.Label>
								<Form.Control type="date" name="completed_date" onChange={this.handleChange} />
							</Form.Group>
						</Form.Row>

						<Form.Row>
							<Form.Group as={Col}>
								<Form.Label>Adjuntar el contrato</Form.Label>
								<input type="file" className={"form-control"} onChange={this.onFileChange} />
							</Form.Group>
						</Form.Row>

						<Form.Group>
							<Form.Label>Descripción</Form.Label>
							<textarea placeholder="Escriba una breve descripción (máximo 200 caracteres)" className={"form-control"} maxLength={"200"}
								name="description" onChange={this.handleChange} />
						</Form.Group>

						<Form.Row>
							<Form.Group as={Col}>
								<Form.Label>Periodicidad</Form.Label>
								<Form.Control type="number" placeholder="Expresada en días" name="periodicity" onChange={this.handleChange} defaultValue={this.state.periodicity} min={0} />
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Cantidad</Form.Label>
								<Form.Control type="number" placeholder="Escriba la cantidad" name="amount" onChange={this.handleChange} min={0} />
							</Form.Group>
						</Form.Row>
					</Form>
					{/* {this.fileData()} */}
				</Modal.Body>
				<Modal.Footer>
					<Button variant={'outline-secondary'} onClick={this.handleClose}>Cancelar</Button>
					<Button variant={'outline-success'} onClick={this.onFileUpload}>Asingar contrato</Button>
				</Modal.Footer>
			</Modal >
		)
	}
}

const ModalTracing = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			uploading: false,
			selectedFile: null,
			message: '',
			tracing: []
		}
	}

	componentDidMount = () => {
		setTimeout(() => this.setState({ show: true }), 100);
		this.setState({ tracing: this.props.tracing });
	}

	handleClose = () => {
		if (this.state.uploading) {
			Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
			return;
		}
		this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
	}

	downloadFile = (id, path) => {
		let url = __SERVER__ + '/projects/' + Variables.project_id + '/victum_users/right_way/' + this.props.item.id + '/tracing/' + id + '/download?path=' + path;

		axios(url, {
			method: "GET",
			responseType: "blob"
		}).then(response => {
			const file = new Blob([response.data], {
				type: response.data.type
			});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}).catch(error => {
			console.log(error);
		});
	}

	onFileChange = (event) => {
		this.setState({ selectedFile: event.target.files[0] });
	};

	fileData = () => {
		if (this.state.selectedFile) {
			return (
				<div>
					<p><strong>Archivo:</strong> {this.state.selectedFile.name}</p>
				</div>
			);
		}
	};

	sendTracing = async () => {
		if (this.state.message === '') {
			Swal.fire('Advertencia', 'El mensaje no puede estar vacio', 'info');
			return;
		}
		let url = `${__SERVER__}/projects/${Variables.project_id}/victum_users/right_way/${this.props.item.id}/tracing`;
		let trace = await axios.post(url, {
			description: this.state.message,
			type_id: 1
		});
		console.log("TRACE: ", trace)
		if (trace.status === 200) {
			if (this.state.selectedFile !== null) {
				url += `/${trace.data}/upload`;
				const formData = new FormData();
				// Actualizar el objeto formData
				formData.append(
					"file",
					this.state.selectedFile
				);
				let file_upload = await axios.post(url, formData);
				if (file_upload.status === 200) {
					this.setState({ selectedFile: null });
				}
			}
			this.setState(() => setTimeout(this.props.getTracing, 100));
			Swal.fire('Mensaje enviado', 'Mensaje enviado con éxito', 'success');
			this.setState({ message: '' });
		}
	}

	render = () => {
		return (
			<Modal
				show={this.state.show}
				onHide={this.handleClose}
				backdrop="static"
				scrollable={true}
				keyboard={false}
				size="lg"
				centered>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.item.class} - {this.props.item.code}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row>
							<Col md={{ span: 10, offset: 1 }}>
								{
									this.props.tracing !== null ?
										this.props.tracing.length !== 0 ?
											<div>
												{
													this.props.tracing.map((item, i) =>
														<Media key={i}>
															<img
																width={64}
																height={64}
																className="align-self-start mr-3"
																src={item.type_id === 0 ? victum_users_logo : user_operation}
																alt="Generic placeholder"
															/>
															<Media.Body>
																<h5>{item.type_id === 0 ? this.props.item.name : 'Usuario de operación'} <small className="text-muted">{moment(item.creation_date).format('LLLL')}</small></h5>
																<p>{item.description} {item.file_name !== null ? <Button variant="link" onClick={() => this.downloadFile(item.id, item.file_name)}>{item.file_name}</Button> : null}</p>
															</Media.Body>
														</Media>
													)
												}
											</div>
											: <Alert variant="warning">
												<Alert.Heading>¡No hay seguimiento!</Alert.Heading>
												<p>Aún no se ha registrado información para realizar el seguimiento.</p>
											</Alert> : null

								}
							</Col></Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Container>
						<Row>
							<Col md={{ span: 10, offset: 1 }}>
								<InputGroup className="mb-3">
									<InputGroup.Prepend>
										<OverlayTrigger placement={"bottom"} overlay={<Tooltip>Adjuntar archivo</Tooltip>}>
											<Button variant="outline-info" onClick={e => this.inputElement.click()}>
												<FaUpload />
											</Button>
										</OverlayTrigger>
									</InputGroup.Prepend>
									<FormControl type="text" placeholder={"Escriba un mensaje..."} value={this.state.message} onChange={e => this.setState({ message: e.target.value })} />
									<InputGroup.Append>
										<OverlayTrigger placement={"bottom"} overlay={<Tooltip>Enviar mensaje</Tooltip>}>
											<Button variant="outline-info" onClick={this.sendTracing}>
												<FaPaperPlane />
											</Button>
										</OverlayTrigger>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Row>
						<Row>
							<Col md={{ span: 10, offset: 1 }}>
								{this.fileData()}
								<Form.File.Input style={{ display: 'none' }} ref={input => this.inputElement = input} onChange={this.onFileChange} />
							</Col>
						</Row>
					</Container>

				</Modal.Footer>
			</Modal >
		);
	}
}

const GetReport = class extends Component {
	render = () => {
		return (
			<PDFDownloadLink document={
				<MyDoc />} fileName={"reporte seguimiento.pdf"} >
				{
					({ blob, url, loading, error }) => (loading ?

						<div>
							<Spinner animation="border" variant="success" />
							<h3>Finalizando reporte</h3>
						</div> :
						<Button variant="outline-danger">
							<FaFileDownload />
						</Button>
					)}
			</PDFDownloadLink >
		);
	}
}

const ModalMap = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			uploading: false,
		}
	}

	componentDidMount = () => {
		setTimeout(() => this.setState({ show: true }), 100);
	}

	handleClose = () => {
		if (this.state.uploading) {
			Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
			return;
		}
		this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
	}

	render = () => {
		return (
			<Modal
				show={this.state.show}
				onHide={this.handleClose}
				backdrop="static"
				keyboard={false}
				size="lg"
				centered>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ height: '500px', width: '100%' }}>
					<center>
						<Map
							ubications={this.props.ubications}
							center={this.props.ubications[0].position}
							initialCenter={this.props.ubications[0].position}
						/>
					</center>
				</Modal.Body>
			</Modal >
		);
	}
}

const Grid_ = class extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modal: false,
			item: null,
			tracing: null,
			modalCard: false,
			modalTracing: false,
			button_pdf: false,
			modalMap: false
		}
	}

	getTracing = () => {
		fetch(__SERVER__ + '/projects/' + Variables.project_id + '/victum_users/right_way/' + this.state.item.id + '/tracing', {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(tracing => {
					tracing = tracing.reverse();
					this.setState({ tracing });
				})
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			console.log(e);
		})
	}

	downloadFile = (id, path) => {
		let url = __SERVER__ + '/projects/' + Variables.project_id + '/victum_users/right_way/' + this.state.item.id + '/tracing/' + id + '/download?path=' + path;
		axios(url, {
			method: "GET",
			responseType: "blob"
		}).then(response => {
			const file = new Blob([response.data], {
				type: response.data.type
			});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}).catch(error => {
			console.log(error);
		});
	}

	openOrCloseModal = () => {
		this.setState({ modalCard: !this.state.modalCard });
	}

	openOrCloseModalTracing = () => {
		this.setState({ modalTracing: !this.state.modalTracing });
	}

	openOrCloseModalMap = () => {
		this.setState({ modalMap: !this.state.modalMap });
	}

	render() {
		const statusMessage = ['En proceso', 'Aprobado', 'Cancelado', 'No aprobado'];
		const statusType = ['primary', 'success', 'danger', 'secondary'];
		return (
			<div>
				<div className="cards">
					{
						this.props.data.map((item, i) =>
							<Card style={{ width: '20rem' }} key={'card' + i} >
								<Card.Body>
									<Card.Title>Expediente: <strong>{item.code}</strong></Card.Title>
									<Card.Text>
										<strong>Fecha de solicitud: </strong>{moment(item.application_date).format('llll')} <br />
										<strong>Solicitante: </strong>{item.name} <br />
										<strong>E-mail: </strong>{item.email} <br />
										<strong>Teléfono: </strong> {item.phone}<br />
										<strong>Estado: </strong> <Badge variant={statusType[item.status]}>{statusMessage[item.status]}</Badge><br />
										<strong>Contrato: </strong> Pendiente... <br />
									</Card.Text>
								</Card.Body>
								<Card.Footer>
									<Row>
										<Col>
											<OverlayTrigger placement={"bottom"} overlay={<Tooltip>Más detalles</Tooltip>}>
												<Button variant="outline-info" id={item.id} onClick={() => this.setState({ modal: true, item }, this.getTracing)}>
													<FaEye />
												</Button>
											</OverlayTrigger></Col>
										<Col>
											<OverlayTrigger placement={"bottom"} overlay={<Tooltip>Asignar contrato</Tooltip>}>
												<Button variant="outline-success" onClick={e => this.setState({ item: item }, this.openOrCloseModal)}>
													<FaFileContract />
												</Button>
											</OverlayTrigger></Col>
										<Col>
											<OverlayTrigger placement={"bottom"} overlay={<Tooltip>Dar seguimiento</Tooltip>}>
												<Button variant="outline-secondary" onClick={(e) => {
													this.setState({ item }, this.getTracing);
													this.openOrCloseModalTracing();
												}}>
													<FaExchangeAlt />
												</Button>
											</OverlayTrigger></Col>
										<Col>
											<OverlayTrigger placement={"bottom"} overlay={<Tooltip>Descargar reporte</Tooltip>}>
												<Button variant="outline-danger" onClick={e => this.setState({ item }, this.openOrCloseModalMap)}>
													<FaMapMarker />
												</Button>
											</OverlayTrigger></Col>
									</Row>
								</Card.Footer>
							</Card>
						)
					}
				</div>
				{/* Modal para seguimiento */}
				{
					this.state.modalTracing ?
						<ModalTracing
							title={`Seguimiento`}
							tracing={this.state.tracing}
							getTracing={this.getTracing}
							item={this.state.item}
							onClose={this.openOrCloseModalTracing}
						/> : null
				}
				{/* Modal para mostrar ubicación */}
				{
					this.state.modalMap ?
						<ModalMap
							title={'Ubicación de la solicitud de derecho de vía'}
							ubications={[{
								id: 1,
								title: `${this.state.item.code}`,
								name: `Fecha de solicitud ${moment(this.state.item.application_date).format('llll')}`,
								position: {
									lat: this.state.item.latitude,
									lng: this.state.item.longitude
								}
							}]}
							onClose={this.openOrCloseModalMap}
						/> : null
				}
				{/* Modal para subir contrato */}
				{
					this.state.modalCard ?
						<ModalCard
							title={'Asignación de contrato'}
							item={this.state.item}
							onClose={this.openOrCloseModal}
						/> : null
				}
				<Modal show={this.state.modal} backdrop="static" keyboard={false} onHide={() => this.setState({ modal: false, item: null, tracing: null })}>
					<Modal.Header closeButton>
						{this.state.item !== null ? <Modal.Title>{this.state.item.code}</Modal.Title> : null}
					</Modal.Header>
					{this.state.item !== null ?
						<Modal.Body>
							<div><strong>Clase: </strong>{this.state.item.class}</div>
							<div><strong>Localización: </strong>{this.state.item.location}</div>
							<div><strong>Altitud: </strong>{this.state.item.altitude}</div>
							<div><strong>Longitud: </strong>{this.state.item.longitude}</div>
							<div><strong>Latitud: </strong>{this.state.item.latitude}</div>
							<div><strong>Descripción: </strong>{this.state.item.description}</div>

						</Modal.Body> : null}
					<Modal.Footer onClick={() => this.setState({ modal: false, item: null, tracing: null })}>
						<Button variant="danger">Cerrar</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			result2: [],
			page: 1,
			page2: 1,
			refresh: false,
			statusOne: { backgroundColor: '#FF0000', cursor: 'pointer', margin: 5, color: '#FFF', borderWidth: 0, padding: 10, borderRadius: 5 },
			statusTwo: { backgroundColor: '#9C9D9C', cursor: 'pointer', margin: 5, color: '#FFF', borderWidth: 0, padding: 10, borderRadius: 5 },
			status: 1,
			inventory: false,
			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false,
			buttonVisible: false,
			modalRequest: false
		}
	}

	componentDidMount = async () => {
		// GET operating_indicator_id
		let res = await Axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')

		if (res.data.length !== 0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	updatePage = (page) => {
		if (this.state.status === 1) {
			this.setState({ page }, this.getEmergencyCoordination);
		} else {
			this.setState({ page2: page }, this.getEmergencyCoordination);
		}
	}

	generateTable = () => {
		let data = [];
		if (this.state.status === 1) {
			data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];
		} else {
			data = this.state.result2;
		}

		this.setState({
			grid: null
		}, () => {
			if (data !== undefined) {
				if (this.state.status === 1) {
					this.setState({ grid: <Grid updatePage={this.updatePage} data={data} /> })
				} else if (this.state.status === 2) {
					this.setState({ grid: <Grid_ updatePage={this.updatePage} data={data} /> })
				} else {
					this.setState({ grid: null });
				}
			} else {
				this.setState({ grid: null });
			}
		});
	}

	getRequestsAttention = () => {
		let table = "/projects/" + this.props.project_id + "/requests_attention";
		let query = this.state.query;

		if (this.state.status === 1) {
			query = this.state.query + (this.state.query === '' ? `?page=${this.state.page}` : `&page=${this.state.page}`);
		}

		if (this.state.status === 2) {
			table = "/victum_users/victum_right_way";
		}

		table += query;

		fetch(__SERVER__ + table, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					if (this.state.status === 1) {
						this.state.result.push(result);
					} else {
						this.state.result2 = result;
					}
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			console.log(e)
			Swal.fire('Error', e, 'error');
		})
	}

	setProps = (query, headersTable) => {
		if (this.state.status === 1) {
			this.setState({
				page: 1,
				result: [],
				query: query,
				headersTable: headersTable
			}, this.getRequestsAttention)
		} else {
			this.setState({
				page2: 1,
				result2: [],
				query: query,
				headersTable: headersTable
			}, this.getRequestsAttention)
		}
	}

	status = (status) => {
		this.setState({
			statusOne: this.state.statusTwo,
			statusTwo: this.state.statusOne,
			status: status,
			grid: null
		});
	}

	openOrCloseModal = () => {
		this.setState({ modalUploadData: !this.state.modalUploadData });
	}

	openOrCloseModalDeductivas = () => {
		this.setState({ modalDeductivas: !this.state.modalDeductivas });
	}

	openOrCloseModalRequest = () => {
		this.setState({ modalRequest: !this.state.modalRequest });
	}

	render() {
		return (
			<div className="body_">
				<button onClick={() => { this.state.inventory = !this.state.inventory; this.setState({ refresh: !this.state.refresh }); }} className="buttonOpenInventario">{this.state.inventory ? 'Indicador' : 'Inventarios'}</button>
				{!this.state.inventory ?
					<div>
						<div className="buttons">
							<button onClick={() => { this.status(1) }} style={this.state.statusOne}>{this.props.code}</button>
							<button onClick={() => { this.status(2) }} style={this.state.statusTwo}>VICTUM</button>
						</div>
						{this.state.status === 1 ?
							<div>
								<Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
								<FiltroBusqueda setProps={this.setProps} />
								{this.state.grid}
							</div>
							: null}

						{this.state.status === 2 ?
							<div>
								<Heading className='tituloSub'>{"VICTUM " + this.props.code + " " + this.props.name}</Heading>
								<FiltroBusqueda_ setProps={this.setProps} />
								{this.state.grid}
							</div>
							: null}
					</div>
					: <Inventory />
				}
				{
					this.state.buttonVisible ?
						<FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faCarCrash'} onClick={this.openOrCloseModalDeductivas} /> : null
				}
				<FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />
				
				{
					this.state.modalUploadData ?
						<ModalUpload
							server={`${__SERVER__}/projects/${Variables.project_id}/requests_attention`}
							items={[
								{ label: 'Fecha de contacto OAS', description: 'Fecha de contacto OAS', value: 'OAS_contact_date', type: 'String' },
								{ label: 'Solicitante', description: 'Solicitante', value: 'applicant', type: 'Number' },
								{ label: 'Estado', description: 'Estado', value: 'state', type: 'Number' },
								{ label: 'Medio de recepción', description: 'Medio de recepción', value: 'means_reception', type: 'String' },
								{ label: 'Descripción de solicitud', description: 'Descripción de solicitud', value: 'description_request', type: 'Number' },
								{ label: 'Descripción de respuesta', description: 'Descripción de respuesta', value: 'response_description', type: 'Number', toFormat: 'Time' },
								{ label: 'Fecha de solicitud-respuesta', description: 'Fecha de solicitud-respuesta', value: 'request_response_date', type: 'Number', toFormat: 'Time' },
								{ label: 'Número de archivo', description: 'Número de archivo', value: 'file_number', type: 'String' }
							]}
							title={`${this.props.code} ${this.props.name}`}
							onClose={this.openOrCloseModal} />
						: null
				}
				{
					this.state.modalDeductivas ?
						<Deductiva
							title={this.props.code}
							formula={this.state.formula}
							pum={this.state.PUMObject}
							cump={this.state.CumplObject}
							formulaCump={this.state.formulaCump}
							formulaPUM={this.state.formulaPUM}
							hayFormula={this.state.hayFormula}
						/> : null
				}
			</div>
		);
	}
}

//export default RequestsAttention;
