import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Col, Row } from 'antd';
import { Alert } from 'react-bootstrap';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { downloadIncidentFiles } from 'api/operation_standards/incidents_files';

const IncidentAudios = (props) => {
	const { data, project_id, deleteFile } = props;

	const [audios, setAudios] = useState([]);
	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(0);
	const [currentCount, setCurrentCount] = useState(0);

	useEffect(() => {
		let cancelLoop = false;
		let cancelTokens = [];
		const getAudios = async () => {
			// Obtener únicamente las evidencias de tipo picture
			let files = data.filter((file) => file.file_type === 'audio');
			setCount(files.length);
			for (const element of files) {
				if (cancelLoop) break;
				const { file_path, obac_incident_id, created_at, id } = element;
				const { response, cancel } = await downloadIncidentFiles({ project_id, obac_incident_id, file_path });
				cancelTokens.push({ cancel });
				if (response) {
					setAudios(audios => [...audios, {
						id,
						audio: URL.createObjectURL(response),
						created_at: `${moment(created_at).format('LLLL')}`,
						obac_incident_id,
						file_path
					}]);
					setCurrentCount(currentCount => currentCount + 1);
					setLoading(false);
				}
			}
			setLoading(false);
		}

		getAudios();

		return () => {
			cancelLoop = true;
			cancelTokens.forEach((token) => token.cancel());
		}
	}, []);

	return (
		<>
			{
				count > 0 &&
				<p>Obteniendo {currentCount}/{count} audio{count === 1 ? '' : 's'}...</p>
			}
			{
				!loading && audios.map((item, index) =>
					<Row align="middle" key={item.id}>
						<Col span={2}>{index + 1}</Col>
						<Col span={10}>{item.created_at}</Col>
						<Col span={10}>
							<audio key={item.id} src={item.audio} controls />
						</Col>
						<Col span={2}>
							<ButtonIcon icon={faTrashAlt} variant='outline-danger' onClick={() => deleteFile(item)} tooltip='Eliminar audio' />
						</Col>
					</Row>
				)
			}
			{
				!loading && audios.length === 0 &&
				<Alert variant='info'>
					No se encontraron registros de audios.
				</Alert>
			}
		</>
	)
}

export default IncidentAudios