import FormIcon from 'components/layouts/FormIcon';
import React, { Fragment } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const StampTab = (props) => {
    const { stamp } = props;
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title='Fecha de timbrado' name='date' value={stamp?.date} />
                <FormIcon title='Firma SAT' name='sat_signature' value={stamp?.sat_signature} />
                <FormIcon title='Número de certificado SAT' name='sat_cert_number' value={stamp?.sat_cert_number} />
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Firma</Form.Label>
                    <textarea
                        className='form-control'
                        rows={4}
                        value={stamp?.signature}
                    />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Cadena complementaria</Form.Label>
                    <textarea
                        className='form-control'
                        rows={4}
                        value={stamp?.complement_string}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

export default StampTab