import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const downloadMaintenanceProgramFlowFiles = async ({ project_id, program_flow_id, path }) => {
    const url = `/projects/${project_id}/victum_maintenance_program_flow/${program_flow_id}/evidences/download`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            responseType: "blob",
            params: { path }
        });
        // console.log("🚀 ~ file: maintenance_program_flow_files.js:14 ~ downloadMaintenanceProgramFlowFiles ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = new Blob([fetch.data], {
                type: fetch.data.type
            });
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}

export const uploadMaintenanceProgramFlowFiles = async ({ project_id, program_flow_id, moment, data }) => {
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `/projects/${project_id}/victum_maintenance_program_flow/${program_flow_id}/evidences/${moment}/upload`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: maintenance_program_flow.js:86 ~ uploadMaintenanceProgramFlowFiles ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok || fetch.status === httpStatus.created) {
            response = fetch.data
        }
        return { response, cancel }
    } catch (error) {
        return response;
    }
}

export const destroyMaintenanceProgramFlowFiles = async ({ project_id, flow_id, id, path }) => {
    const url = `/projects/${project_id}/victum_maintenance_program_flow/${flow_id}/evidences/${id}`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE',
            params: { path }
        });
        // console.log("🚀 ~ file: maintenance_program_flow_files.js:52 ~ destroyMaintenanceProgramFlowFiles ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok)
            response = fetch.data
        return { response, cancel };
    } catch (error) {
        return response;
    }
}