import FormIcon from 'components/layouts/FormIcon';
import React, { Fragment } from 'react';
import { Row } from 'react-bootstrap';

const InformationTab = (props) => {
    const {invoice} = props;
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title='ID' name='id' value={invoice?.id}/>
                <FormIcon title='Creado en' name='created_at' value={invoice?.created_at}/>
                <FormIcon title='Livemode' name='livemode' value={invoice?.livemode}/>
                <FormIcon title='Estado' name='status' value={invoice?.status}/>
                <FormIcon title='Estado de cancelación' name='cancellation_status' value={invoice?.cancellation_status}/>
            </Row>
            <Row className='mb-3'>
                <FormIcon title='URL de verificación' name='verification_url' value={invoice?.verification_url}/>
                <FormIcon title='Fecha' name='date' value={invoice?.date}/>
                <FormIcon title='Tipo' name='type' value={invoice?.type}/>
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Total' name='total' value={invoice?.total} icon='$'/>
                <FormIcon title='UUID' name='uuid' value={invoice?.uuid}/>
                <FormIcon title='Folio' name='folio_number' value={invoice?.folio_number}/>
                <FormIcon title='Serie' name='series' value={invoice?.series}/>
                <FormIcon title='Método de pago' name='payment_method' value={invoice?.payment_method}/>
                <FormIcon title='Tipo de moneda' name='currency' value={invoice?.currency}/>
            </Row>
        </Fragment>
    )
}

export default InformationTab