import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateBaseLines } from 'api/operation_standards/assets_base_lines';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import ModalComp from 'components/layouts/Modal'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

const UpdateBaseLine = (props) => {
    const { onClose, package_id, update, baseLineSel } = props;
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const { name, color, description } = baseLineSel;
        setData({ name, color, description });
    }, []);

    useEffect(() => {
        readChanges();
    }, [data]);

    const readChanges = () => {
        const fields = ['name', 'color', 'description'];
        let response = true;
        for (const field of fields) {
            if (data[field] !== baseLineSel[field]) response = false;
        }
        setDisabled(response);
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    const save = async () => {
        let response = await updateBaseLines({ package_id, data, id: baseLineSel.id });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'La línea base ha sido actualizado de manera satisfactoria.', 'success');
            update();
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de actualizar la línea base. Intente más tarde.', 'error');
        }
    }

    return (
        <ModalComp
            title='Actualizar'
            size='md'
            onClose={onClose}
            body={<>
                <Row>
                    <FormIcon title='Nombre' name='name' value={data?.name} handleChange={handleChange} />
                </Row>
                <Row>
                    <FormIcon title='Color' name='color' value={data?.color} handleChange={handleChange} type='color' />
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Description</Form.Label>
                        <textarea
                            className='form-control'
                            name='description'
                            value={data?.description}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name='Actualizar' icon={faSave} variant='outline-success' onClick={save} disabled={disabled} />
            </>}
        />
    )
}

export default UpdateBaseLine