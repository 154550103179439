import React from 'react';
import COMP_MR_xxxxxIV from "./Inventarios/COMP_MR_xxxxxIV.jsx";        //Iluminación Vial
import COMP_MR_xxxxCDV from "./Inventarios/COMP_MR_xxxxCDV.jsx";        //Cercado del Derecho de Vía
import COMP_MR_xxxxODC from "./Inventarios/COMP_MR_xxxxODC.jsx";        //Obras de drenaje y obras complementarias
import COMP_MR_xxxxxxE from "./Inventarios/COMP_MR_xxxxxxE.jsx";        //Estructuras
import COMP_MR_xxxxTER from "./Inventarios/COMP_MR_xxxxTER.jsx";        //Estado de Terraplenes
import COMP_MR_xxxxxSV from "./Inventarios/COMP_MR_xxxxxSV.jsx";        //Señalamiento vertical
import COMP_MR_xxxxxDB from "./Inventarios/COMP_MR_xxxxxDB.jsx";        //Defensas y barreras
import COMP_MR_xxxxxSH from "./Inventarios/COMP_MR_xxxxxSH.jsx";        //Señalamiento Horizontal
import COMP_MR_xxxxxDS from "./Inventarios/COMP_MR_xxxxxDS.jsx";        //Deterioros superficiales
import COMP_MR_xxxxxxT from "./Inventarios/COMP_MR_xxxxxxT.jsx";        //Taludes
import COMP_MR_xxxxLDV from "./Inventarios/COMP_MR_xxxxLDV.jsx";        //Limpieza del Derecho de Vía
import COMP_MR_xxxxxRE from "./Inventarios/COMP_MR_xxxxxRE.jsx";        //Rampas de Frenado de emergencia

/*
import COMP_MR_xxxxIRI from "./Inventarios/COMP_MR_xxxxLEV.jsx";        //Limpieza de toda la estructura de via
import COMP_MR_xxxxIRI from "./Inventarios/COMP_MR_xxxxIRI.jsx";        //Índice de rugosidad (IRI)
import COMP_MR_xxxxxPR from "./Inventarios/COMP_MR_xxxxxPR.jsx";        //Profundidad de Roderas
import COMP_MR_xxxxLCA from "./Inventarios/COMP_MR_xxxxLCA.jsx";        //Limpieza de calzada
import COMP_MR_xxxxERP from "./Inventarios/COMP_MR_xxxxERP.jsx";        //Estado de los pavimentos rígidos
import COMP_MR_xxxxxCP from "./Inventarios/COMP_MR_xxxxxCP.jsx";        //Coeficiente de fricción
import COMP_MR_xxxxVDV from "./Inventarios/COMP_MR_xxxxVDV.jsx";        //Control de Vegetación en Derecho de Vía
import COMP_MR_xxxxxRE from "./Inventarios/COMP_MR_xxxxxRE.jsx";        //Rampas para frenado de emergencia
import COMP_MR_xxxxxAHU from "./Inventarios/COMP_MR_xxxxAHU.jsx";       //Ahuellamientos
import COMP_MR_xxxxxMRT from "./Inventarios/COMP_MR_xxxxMRT.jsx";       //Mantenimiento rutinario
import COMP_MR_xxxxxMPR from "./Inventarios/COMP_MR_xxxxMPR.jsx";       //Mantenimiento periódico
*/
const _ = require('underscore');

const components = [
    {name: "COMP_MR_xxxxxIV", component: <COMP_MR_xxxxxIV />},
    {name: "COMP_MR_xxxxCDV", component: <COMP_MR_xxxxCDV />},
    {name: "COMP_MR_xxxxODC", component: <COMP_MR_xxxxODC />},
    {name: "COMP_MR_xxxxxxE", component: <COMP_MR_xxxxxxE />},
    {name: "COMP_MR_xxxxTER", component: <COMP_MR_xxxxTER />},
    {name: "COMP_MR_xxxxxSV", component: <COMP_MR_xxxxxSV />},
    {name: "COMP_MR_xxxxxDB", component: <COMP_MR_xxxxxDB />},
    {name: "COMP_MR_xxxxxSH", component: <COMP_MR_xxxxxSH />},
    {name: "COMP_MR_xxxxxDS", component: <COMP_MR_xxxxxDS />},
    {name: "COMP_MR_xxxxxxT", component: <COMP_MR_xxxxxxT />},
    {name: "COMP_MR_xxxxLDV", component: <COMP_MR_xxxxLDV />},
    {name: "COMP_MR_xxxxxRE", component: <COMP_MR_xxxxxRE />},
    /*
    {name: "COMP_MR_xxxxIRI", component: <COMP_MR_xxxxIRI />},
    {name: "COMP_MR_xxxxxPR", component: <COMP_MR_xxxxxPR />},
    {name: "COMP_MR_xxxxLCA", component: <COMP_MR_xxxxLCA />},
    {name: "COMP_MR_xxxxERP", component: <COMP_MR_xxxxERP />},
    {name: "COMP_MR_xxxxxCP", component: <COMP_MR_xxxxxCP />},
    {name: "COMP_MR_xxxxVDV", component: <COMP_MR_xxxxVDV />},
    {name: "COMP_MR_xxxxxRE", component: <COMP_MR_xxxxxRE />},
    {name: "COMP_MR_xxxxxAHU", component: <COMP_MR_xxxxxAHU />},
    {name: "COMP_MR_xxxxxMRT", component: <COMP_MR_xxxxxMRT />},
    {name: "COMP_MR_xxxxxMPR", component: <COMP_MR_xxxxxMPR />},
    */
];
export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: null
        }
    }

    componentDidMount = () => {
        let index = _.findIndex(components, {name: this.props.component});
        this.setState({component: index !== -1 ? components[index].component : null});
    }

    render = () => {
        return (
            this.state.component !== null ?
                this.state.component
            :null
        );
        
    }
}