import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Skeleton } from 'antd'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from 'components/layouts/ButtonIcon'
import FormIcon2 from 'components/layouts/FormIcon2'
import ModalComp from 'components/layouts/Modal'
import { storeWorkOrders } from 'api/maintenance_standards/work_orders'
import { indexUsersSimple } from 'api/users/users'
import Swal from 'sweetalert2'

const WorkOrderModal = ({ onClose, segment_id, standard_id, package_id, eventSel, viewOrders, is_incident, setEvents }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const response = await indexUsersSimple({ package_id });
            setUsers(response.data);
        }
        getUsers();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData(prevState => ({ ...prevState, [name]: value }));
    }

    const save = async () => {
        setLoading(true);
        const { response } = await storeWorkOrders({
            data: {
                ...data,
                incident_id: eventSel.id,
                is_incident
            },
            package_id,
            segment_id,
            standard_id
        });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'La orden de trabajo se generó correctamente!', 'success');
            // Actualizar id de la orden de trabajo del evento
            setEvents(prevState => prevState.map(event => {
                if (event.id === eventSel.id) event.work_order_id = response.id;
                return event;
            }));
            viewOrders(); // Abrir modal para ver todas las órdenes de trabajo
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'La orden de trabajo no se pudo crear!', 'error')
        }
        setLoading(false);
    }

    return (
        <ModalComp
            onClose={onClose}
            title='Nueva Orden de Trabajo'
            size='md'
            body={<>
                {
                    loading ? <Skeleton active /> : <>
                        <Row>
                            <FormIcon2 title='Orden de Trabajo/Código' name='code' value={data?.code} handleChange={handleChange} />
                        </Row>
                        <Row>
                            {/* TODO: Mostrar usuarios que tienen permitido autorizar */}
                            <Form.Group as={Col}>
                                <Form.Label>Autorización</Form.Label>
                                <select className='form-control' name='authorization' onChange={handleChange} value={data?.authorization}>
                                    <option value={-1}>--SELECCIONAR OPCIÓN</option>
                                    {
                                        users.map((item) =>
                                            <option key={item.id} value={`${item.first_name} ${item.last_name}`}>
                                                {item.first_name} {item.last_name}
                                            </option>
                                        )
                                    }
                                </select>
                            </Form.Group>
                        </Row>
                        <Row>
                            {/* TODO: Mostrar usuarios que pueden dar el visto bueno */}
                            <Form.Group as={Col}>
                                <Form.Label>Visto Bueno</Form.Label>
                                <select className='form-control' name='vobo' onChange={handleChange} value={data?.vobo}>
                                    <option value={-1}>--SELECCIONAR OPCIÓN</option>
                                    {
                                        users.map((item) =>
                                            <option key={item.id} value={`${item.first_name} ${item.last_name}`}>
                                                {item.first_name} {item.last_name}
                                            </option>
                                        )
                                    }
                                </select>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Descripción</Form.Label>
                                <textarea className={"form-control"}
                                    name="description" onChange={handleChange} />
                            </Form.Group>
                        </Row>
                    </>
                }
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} onClick={onClose} variant='outline-secondary' tooltipDisabled={true} />
                <ButtonIcon name='Generar' icon={faSave} variant='outline-success' onClick={save} tooltipDisabled={true} disabled={loading} />
            </>}
        />
    )
}

export default WorkOrderModal