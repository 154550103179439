import fetcher from "api/fetcher";
import { Variables } from "variables/Variables";

const CREATED = 201;
const STATUS_OK = 200;

export const indexInventory = async ({ project_id, table, creation_dateS, creation_dateE }) => {
    const url = `/projects/${project_id}/${table}`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params: {
                creation_dateS,
                creation_dateE
            }
        });
        // console.log("🚀 ~ file: inventory.js:19 ~ indexInventory ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeInventory = async ({ table, data }) => {
    const url = `/projects/${Variables.project_id}/${table}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: inventory.js ~ line 21 ~ storeInventory ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}