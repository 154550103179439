import React, { Fragment, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import TwoFactorModal from './TwoFactorModal';
import { twoFactorAuth } from 'api/users/auth';
import { httpStatus, Variables } from 'variables/Variables';
import ButtonIcon from 'components/layouts/ButtonIcon';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { CLOSE_SESSION } from 'store/actions/actionTypes';

const moment = require('moment');

const TwoFactorAuthComp = () => {
    const dispatch = useDispatch();
    const { user } = Variables;
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState('');

    const openOrClose = () => setShowModal(!showModal);

    const confirm = async () => {
        const { response } = await twoFactorAuth({ user_id: user.id, has_two_factor: type === 'enable' ? true : false });
        if (response.status === httpStatus.ok) {
            dispatch({ type: CLOSE_SESSION });
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de realizar esta operación. Contacte al soporte.', 'error');
        }
    }
    const enableTwoFactor = () => {
        setType('enable');
        openOrClose();
    }

    const disableTwoFactor = () => {
        setType('disable');
        openOrClose();
    }

    return (
        <Fragment>
            <Box p={5} shadow='md' borderWidth='1px' className='mt-3' bg={'white'}>
                <Row className=''>
                    <Col className='col-10'>
                        <Text>
                            {
                                user.has_two_factor ?
                                    `La verificación en dos pasos se encuentra activa desde ${moment(user.two_factor_since).format('LLLL')}`  // Habilitado desde
                                    : 'La verificación en dos pasos se encuentra DESACTIVADA'                           // Autentificación en dos pasos desactivada
                            }
                        </Text>
                    </Col>
                    <Col className='col-2 d-flex align-items-start'>
                        {
                            user.has_two_factor ? <ButtonIcon name={'DESACTIVAR'} variant='outline-danger' icon={faToggleOff} onClick={disableTwoFactor} /> :
                                <ButtonIcon name={'ACTIVAR'} variant='outline-success' icon={faToggleOn} onClick={enableTwoFactor} />
                        }
                    </Col>
                </Row>
            </Box>
            {/* Modal para confirmación */}
            {
                showModal && <TwoFactorModal
                    onClose={openOrClose}
                    type={type}
                    confirm={confirm}
                />
            }
        </Fragment>
    );
};

export default TwoFactorAuthComp;
