import React, { Component } from 'react';
//import './Puentes.css' ;
//import '../Mantenimiento.css'
import '../../Mantemiento/Mantenimiento.css'
import { dateToString2 } from '../../../../../utilidades/dates';
import { PDFViewer, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    contentPoint: {
        width: '100%',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 40,
        paddingBottom: 40,
        maxWidth: '100%',
        backgroundColor: 'white'
    },
    TextPoint: {
        color: 'black',
        fontSize: 12,
    },
    valuePoint: {
        color: 'black',
        fontSize: 12,
        textAlign: 'left',
        borderBottom: 1,
        borderBottomColor: 'black'
    },
    h1: {
        color: 'black',
        fontSize: 12,
        marginTop: 10,
        marginBottom: 3
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    column: {
        width: '100%'
    },
    column2: {
        width: '50%'
    },
    column3: {
        width: '33.33%'
    },
    column4: {
        width: '25%'
    },
    column5: {
        width: '20%'
    },
    column6: {
        width: '16.6%'
    }
});

export default class extends Component {
    constructor(props) {
        super(props);
        console.log("Properties", this.props);
    }

    normalizar = (value) => {
        console.log(value);
        value += value.toString(10);
        console.log(value);
        if(value.indexOf('.') === -1){
            value += '.00';
        }else{
            let d = value.split('.');
            value = d[0] + '.' + (d[1].length === 1 ? d[1] + '0' : d[1]);
        }
        return value;
    }

    decimalAdjust = (type, value, exp) => {
        // Si el exp no está definido o es cero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    /*<section className="viewPDF">*/
    render = () => {
        let total = 0;
        for(let x = 0; x < this.props.report.supplies.length; x++){
            total += parseFloat(this.props.report.supplies[x].amount.toFixed(2));
        }
        return (
                    <Document className={'document'}>
                        <Page size="A4" style={styles.contentPoint}>
                            <View>
                                <View style={{}}>
                                    <Text style={{ textAlign: 'center', color: 'black', fontSize: 16, padding: 3, fontWeight: 'bold' }}>Orden de trabajo</Text>
                                </View>
                                <View>
                                    <Text style={{ textAlign: 'left', fontSize: 12, padding: 3, color: 'black', fontWeight: 'bold'}}>{this.props.flow.asset_permanent}</Text>
                                    <Text style={{ textAlign: 'left', fontSize: 12, padding: 3, color: 'black' }}>Código: {this.props.report.headers.code}</Text>
                                    <Text style={{ textAlign: 'left', fontSize: 12, padding: 3, color: 'black' }}>Responsable: {this.props.flow.responsable_permanent}</Text>
                                    <Text style={{ textAlign: 'justify', fontSize: 12, padding: 3, color: 'black' }}>Actividad: {this.props.flow.activity_permanent}</Text>
                                    {this.props.report.headers.description !== null ?
                                        <Text style={{ textAlign: 'left', fontSize: 12, padding: 3, color: 'black' }}>Descripción: {this.props.report.headers.description}</Text>
                                        : null}
                                    <Text style={{ textAlign: 'left', fontSize: 12, padding: 3, color: 'black' }}>Fecha: {dateToString2(this.props.report.headers.creation_date.split('T')[0])}</Text>
                                </View>
                            </View>
                            <View style={{marginTop: 10}}>
                                <View style={[{ backgroundColor: '#1a2530' }, styles.row]}>
                                    <Text style={[styles.column6, { color: '#FFF', fontSize: 12, textAlign: 'center', paddingTop: 5, paddingBottom: 5 }]}>Unidades</Text>
                                    <Text style={[styles.column3, { color: '#FFF', fontSize: 12, textAlign: 'center', paddingTop: 5, paddingBottom: 5 }]}>Descripción</Text>
                                    <Text style={[styles.column6, { color: '#FFF', fontSize: 12, textAlign: 'center', paddingTop: 5, paddingBottom: 5 }]}>Precio</Text>
                                    <Text style={[styles.column3, { color: '#FFF', fontSize: 12, textAlign: 'center', paddingTop: 5, paddingBottom: 5 }]}>Observaciones</Text>
                                </View>
                                {
                                    this.props.report.supplies.map((item, i) =>
                                        <View key={i} style={[{ borderBottomColor: '#1a2530', borderBottomWidth: 1, backgroundColor: i % 2 === 0 ? '#EAEBEB' : '#FFF' }, styles.row]}>
                                            <Text style={[styles.column6, { textAlign: 'center', fontSize: 12, paddingTop: 5, paddingBottom: 5 }]}>{item.count}</Text>
                                            <Text style={[styles.column3, { textAlign: 'left', fontSize: 12, paddingTop: 5, paddingBottom: 5 }]}>{item.description}</Text>
                                            <Text style={[styles.column6, { textAlign: 'right', fontSize: 12, paddingTop: 5, paddingBottom: 5 }]}>$ {item.amount.toFixed(2)}</Text>
                                            <Text style={[styles.column3, { textAlign: 'left', fontSize: 12, paddingTop: 5, paddingBottom: 5 }]}>{item.observations}</Text>
                                        </View>
                                    )
                                }
                                <View style={styles.row}>
                                    <Text style={[styles.column6, { textAlign: 'center', fontSize: 12 }]}></Text>
                                    <Text style={[styles.column3, { textAlign: 'left', fontSize: 12 }]}></Text>
                                    <Text style={[styles.column6, { textAlign: 'right', fontSize: 12, backgroundColor: '#1a2530', color: '#FFF', fontWeight: 'bold', paddingTop: 5, paddingBottom: 5}]}>$ {total.toFixed(2)}</Text>
                                    <Text style={[styles.column3, { textAlign: 'left', fontSize: 12 }]}></Text>
                                </View>
                            </View>
                            <View style={[{marginTop: 20}, styles.row]}>
                                <View style={[styles.column2, {flex: 1, flexDirection: 'column', flexDirection: 'center', alignItems: 'center'}]}>
                                    <Text style={{fontSize: 12}}>Autoriza</Text>
                                    <Text style={{marginTop: 10}}></Text>
                                    <Text style={{fontSize: 12}}>_____________________________</Text>
                                    <Text style={{fontSize: 12}}>{this.props.report.headers.authorization}</Text>
                                </View>
                                <View style={[styles.column2, {flex: 1, flexDirection: 'column', flexDirection: 'center', alignItems: 'center'}]}>
                                    <Text style={{fontSize: 12}}>Visto Bueno</Text>
                                    <Text style={{marginTop: 10}}></Text>
                                    <Text style={{fontSize: 12}}>____________________________</Text>
                                    <Text style={{fontSize: 12}}>{this.props.report.headers.vobo}</Text>
                                </View>
                            </View>
                        </Page>
                    </Document>
                
        );
    }
}