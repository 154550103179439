import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Skeleton } from 'antd';
import { getKmFormat, getPercentaje } from 'components/libs/functions';
import FormIcon from 'components/layouts/FormIcon';
import { updateAssets } from 'api/operation_standards/assets';
import Swal from 'sweetalert2';

const AssetInfo = React.forwardRef((props, ref) => {
    const { asset, items, setButtonUpdate, setButtonDisabled, table,
        buttonDisabled, project_id, syncServer, onClose } = props;
    const [elements, setElements] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [dataExtra, setDataExtra] = useState({});
    const [elementsExtra, setElementsExtra] = useState([]);
    const [headersExtra, setHeadersExtra] = useState([]);
    const [form, setForm] = useState({});
    const [formExtra, setFormExtra] = useState({});

    useEffect(() => {
        setElements(Object.values(asset));
        setHeaders(Object.keys(asset));
        if (asset.extra) {
            const extra = JSON.parse(asset.extra);
            setDataExtra(extra);
            setElementsExtra(Object.values(extra));
            setHeadersExtra(Object.keys(extra));
        }
    }, [asset.extra]);

    useEffect(() => { validateChanges(); }, [form, formExtra]);
    // Método para determinar que form cambió
    const howChange = () => {
        let changeForm = false, changeFormExtra = false;
        // Validar cambios de form
        for (const iterator of Object.keys(form)) {
            if (form[iterator] !== asset[iterator]) {
                if (!(form[iterator] === '' && !asset[iterator])) changeForm = true;
            }
        }
        // Validar cambios de formExtra
        for (const iterator of Object.keys(formExtra)) {
            if (formExtra[iterator] !== dataExtra[iterator]) {
                if (!(formExtra[iterator] === '' && !dataExtra[iterator])) changeFormExtra = true;
            }
        }
        return { changeForm, changeFormExtra }
    }
    // Cambiar visibilidad del botón actualziar
    const validateChanges = () => {
        const { changeForm, changeFormExtra } = howChange();
        setButtonUpdate((changeForm || changeFormExtra) || (changeForm && changeFormExtra));
    }

    const handleChange = (event) => {
        const { value, name } = event.target;
        setForm({ ...form, [name]: value });
    }
    // Método que detecta los cambios en el formulario de información adicional
    const handleChangeExtra = (event) => {
        const { value, name } = event.target;
        setFormExtra({ ...formExtra, [name]: value });
    }

    const getLabel = (key) => {
        const { label } = items.find((item) => item.key === key) || {};
        return label || key;
    };

    const isHidden = (key) => {
        const { hidden } = items.find((item) => item.key === key) || {};
        return !!hidden;
    };

    const isEditable = (key) => {
        const { edit } = items.find((item) => item.key === key) || {};
        return !!edit;
    };

    const formatValue = (value, key) => {
        if (!value) {
            return '';
        }
        if (/km/i.test(key)) {
            return getKmFormat(value);
        }
        if (/percent/i.test(key)) {
            return getPercentaje(value, 5);
        }
        return value;
    };

    React.useImperativeHandle(ref, () => ({
        updateData
    }));

    const updateData = async () => {
        setButtonDisabled(true);
        const { changeForm, changeFormExtra } = howChange();
        const { id } = asset;
        let data = {};
        if (changeForm) data = { ...form };
        if (changeFormExtra) data = { ...data, extra: JSON.stringify(formExtra) }
        let response = await updateAssets({ table, project_id, data, id });
        if (response) {
            syncServer();
            Swal.fire('¡Operación satisfactoria!', 'La información ha sido actualizada de manera satisfactoria.', 'success');
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de actualizar la información.', 'error');
            setButtonDisabled(false);
        }
    }
    return (
        <>
            {
                buttonDisabled ? <Skeleton /> : <>
                    <Row gutter={16}>
                        {headers.map((key, index) => {
                            if (!isHidden(key)) {
                                const label = getLabel(key);
                                const value = formatValue(elements[index], key);
                                const isReadOnly = !isEditable(key);
                                return (
                                    <Col key={key} xs={2} sm={4} md={6} lg={6} xl={12}>
                                        <FormIcon title={label} name={key} defaultValue={value} value={form[key]}
                                            readOnly={isReadOnly} handleChange={handleChange} />
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                    {asset.extra && (
                        <>
                            <Divider orientation="left">Atributos adicionales</Divider>
                            <Row gutter={24}>
                                {headersExtra.map((key, index) => {
                                    const label = getLabel(key);
                                    const value = formatValue(elementsExtra[index], key);
                                    return (
                                        <Col key={key} xs={2} sm={4} md={6} lg={6} xl={12}>
                                            <FormIcon title={label} name={key} defaultValue={value}
                                                value={formExtra[key]} handleChange={handleChangeExtra} />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </>
                    )}
                </>
            }
        </>
    );
});

export default AssetInfo;