import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Tooltip } from 'antd'
import React from 'react'

const StatisticCard = ({data, getColorBar}) => 
    <div className='w-50 my-3'>
        <Row gutter={16}>
            {
                data.map(card =>
                    <Tooltip title={`${card.name} ${card.deductive}%`} key={card.name}>
                        <Col span={8} className="mb-3">
                            <Card bordered={true}>
                                <Statistic
                                    title={card.name}
                                    value={card.deductive}
                                    precision={2}
                                    valueStyle={{color: getColorBar(card.deductive)}}
                                    prefix={ 
                                        (card.deductive >= 90) 
                                            ? <ArrowUpOutlined /> 
                                            : <ArrowDownOutlined />
                                    }
                                    suffix="%"
                                />
                            </Card>
                        </Col>
                    </Tooltip>
                )
            }
        </Row>
    </div>

export default StatisticCard