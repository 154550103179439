import React, { useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd';
import { Form } from 'react-bootstrap';
import { getKmFormat, getPercentaje } from 'components/libs/functions';
import { updateInventoryData } from 'api/maintenance_standards/inventory_data';
import Swal from 'sweetalert2';

const InventoryDetails = React.forwardRef((props, ref) => {
    const {
        data,
        items,
        labelExtra,
        setButtonUpdate,
        project_id,
        table,
        getInventoryData,
        onClose } = props;

    const [dataExtra, setDataExtra] = useState({});
    const [elements, setElements] = useState([]);       // Valores extra
    const [headers, setHeaders] = useState([]);         // Keys extra

    const [form, setForm] = useState({});               // Atributos modificados
    const [formExtra, setFormExtra] = useState({});     // Atributos extra modificados

    useEffect(() => {
        getDataDetails();
    }, []);

    useEffect(() => { validateChanges(); }, [form, formExtra]);
    // Método para determinar que form cambió
    const howChange = () => {
        let changeForm = false, changeFormExtra = false;
        // Validar cambios de form
        for (const iterator of Object.keys(form)) {
            if (form[iterator] !== data[iterator]) {
                if (!(form[iterator] === '' && !data[iterator])) changeForm = true;
            }
        }
        // Validar cambios de formExtra
        for (const iterator of Object.keys(formExtra)) {
            if (formExtra[iterator] !== dataExtra[iterator]) {
                if (!(formExtra[iterator] === '' && !dataExtra[iterator])) changeFormExtra = true;
            }
        }
        return { changeForm, changeFormExtra }
    }
    // Cambiar visibilidad del botón actualziar
    const validateChanges = () => {
        const { changeForm, changeFormExtra } = howChange();
        setButtonUpdate((changeForm || changeFormExtra) || (changeForm && changeFormExtra));
    }
    const getDataDetails = () => {
        const array_labelExtra = []; // Items extra que se almacenan en inventory
        if (labelExtra)
            try {
                JSON.parse(labelExtra).forEach(element => {
                    array_labelExtra.push(element);
                });
            } catch (error) { }

        let array_extras = [];          // Información de los items extra
        if (data?.extras)
            try { array_extras = JSON.parse(data.extras); } catch (error) { }

        const obj = {};
        let dataE = {};
        if (array_labelExtra.length === array_extras.length)
            for (let index = 0; index < array_labelExtra.length; index++) {
                obj[array_labelExtra[index]] = array_extras[index];
                dataE[array_labelExtra[index]] = array_extras[index];
            }
        setDataExtra(dataE);
        setElements(obj);
        setHeaders(array_labelExtra);
    }
    const getIncludes = (arrstr, str) => {
        let response = false;
        for (const word of arrstr) {
            if (str.includes(word)) {
                response = true;
                break;
            }
        }
        return response;
    }
    const getValue = (key, value) => {
        let response = value;
        // Si el key del atributo incluye la cadena km, obtiene formato 000+000
        if (getIncludes(['km', 'KM'], key))
            response = getKmFormat(value);
        // Si el key del atributo incluye una cadena relacionada a porcentaje, asigna el formato de porcentaje con 5 dígitos
        if (getIncludes(['PERCENT', 'percent', 'porcent', 'PORCENT'], key))
            response = getPercentaje(value, 5);
        return response;
    }
    // Método que detecta los cambios en el formulario de información
    const handleChange = (event) => {
        const { value, name } = event.target;
        setForm({ ...form, [name]: value });
    }
    // Método que detecta los cambios en el formulario de información adicional
    const handleChangeExtra = (event) => {
        const { value, name } = event.target;
        setFormExtra({ ...formExtra, [name]: value });
    }
    // Método para actualizar la información
    const updateData = async () => {
        const { inventory_id, id } = data;
        const { changeForm, changeFormExtra } = howChange();
        let newData = {};
        if (changeForm) newData = { ...form };              // Datos generales que cambiaron
        if (changeFormExtra) {
            const extras = { ...dataExtra, ...formExtra };  // Actualizar datos extra que fueron modificados
            let arr_newData = [];
            for (const iterator of headers) {               // Ordenar array
                arr_newData.push(extras[iterator]);
            }
            newData = { ...newData, extras: JSON.stringify(arr_newData) };  // Convertir a cadena los datos extra, se guardan como JSON
        }
        let response = await updateInventoryData({ project_id, inventory_id, id, data: newData, table });
        if (response) {
            getInventoryData();
            Swal.fire('¡Operación satisfactoria!', 'La información ha sido actualizada de manera satisfactoria.', 'success');
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de actualizar la información.', 'error');
        }
    }
    React.useImperativeHandle(ref, () => ({
        updateData
    }));
    return (
        <>
            <Row gutter={16}>
                {/* Atributos generales - No todos los atributos son editables */}
                {
                    items.map((item) =>
                        <Col className='gutter-row' key={item.value} xs={2} sm={4} md={6} lg={6} xl={12} >
                            <Form.Group>
                                <Form.Label>{item.label}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    name={item.value}
                                    defaultValue={getValue(item.value, data[item.value]) || ''}
                                    value={form[item.value]}
                                    readOnly={!item.edit}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    )
                }
            </Row>
            {
                labelExtra &&
                <Divider orientation='left'>Información adicional</Divider>
            }
            <Row gutter={16}>
                {/* Atributos extra -- Todos son editables */}
                {
                    labelExtra && headers.map((item) =>
                        <Col className='gutter-row' key={item} xs={2} sm={4} md={6} lg={6} xl={12} >
                            <Form.Group>
                                <Form.Label>{item}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    name={item}
                                    defaultValue={getValue(item, elements[item]) || ''}
                                    value={formExtra[item]}
                                    readOnly={false}
                                    onChange={handleChangeExtra}
                                />
                            </Form.Group>
                        </Col>
                    )
                }
            </Row>
        </>
    )
});

export default InventoryDetails