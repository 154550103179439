import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const downloadPDF = ({ title, headers, data, file_name }) => {
    const download = () => {
        const widths = headers.map(_ => '*');
        const theaders = headers.map(item => ({ text: item.label, style: 'tableHeader' }))
        const tbody = data.map(obj => headers.map(item => obj[item.value]));
        
        const docDefinition = {
            pageOrientation: 'landscape',
            content: [
                { text: title, style: 'header' },
                { text: 'Fecha: ' + new Date().toLocaleDateString(), alignment: 'right' },
                {
                    table: {
                        headerRows: 1,
                        widths,
                        body: [theaders, ...tbody]
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            }
        };

        pdfMake.createPdf(docDefinition).download(file_name + '_' + new Date().getTime() + '.pdf');
    };
    download();
}