import Axios from 'axios';
import React, { Component } from 'react';
import { Alert, OverlayTrigger, Tooltip, Button, Modal, Table, Col, Row, Card, Form } from 'react-bootstrap';
import { FaCreditCard, FaExchangeAlt, FaPlus } from 'react-icons/fa';
import { __SERVER__ } from 'server/info';
import _ from 'underscore';
import { Variables } from 'variables/Variables';
import '../subComponents/ElectronicPayments.css';
import Swal from 'sweetalert2';
import PaymentForm from './modules/PaymentForm';

const ModalBalance = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    render = () => {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PaymentForm
                        changeTitle={this.changeTitle}
                        onClose={this.handleClose}
                    />
                </Modal.Body>
            </Modal >
        );
    }
}

const ModalBalanceAdd = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            quantity: 10 //Cantidad a abonar
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    handleChange = (e) => {
        let { value, name } = e.target;
        this.setState({ [name]: value });
    }


    updateBalance = async () => {
        let { quantity } = this.state;
        quantity = parseFloat(quantity);
        if (quantity > this.props.unassigned_balance) {
            Swal.fire('Operación fallida', 'La cantidad no puede ser mayor al saldo disponible', 'warning');
            return;
        }
        let response = await Axios.put(`${__SERVER__}/projects/balance/${this.props.balance.id}`, {
            assigned_balance: this.props.balance.assigned_balance + quantity,
            remaining_balance: this.props.balance.remaining_balance + quantity
        });
        if (response.status === 200) {
            /*Descontar saldo sin asignar */
            let update_project = await Axios.put(`${__SERVER__}/victum_openpay/projects/${this.props.openpay_project.id}`, {
                unassigned_balance: this.props.unassigned_balance - parseFloat(quantity)
            });
            console.log("update project: ", update_project, "response: ", response);
            Swal.fire('Operación exitosa', 'El saldo a sido actualizado con éxito', 'success');
            this.props.getBalances();
        } else {
            Swal.fire('Operación fallida', 'Hubo un error al realizar la operación', 'warning');
        }
        this.handleClose();
    }

    render = () => {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <label>Cantidad a agregar</label>
                            <Form.Control
                                type={"number"}
                                min={10}
                                name={"quantity"}
                                max={this.props.unassigned_balance}
                                defaultValue={this.state.quantity}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                variant="outline-success"
                                block
                                onClick={this.updateBalance}
                            >Abonar cantidad</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal >
        );
    }
}

const Movements = class extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <section className="content">
                <h1>Movimientos</h1>
            </section>
        );
    }
}

const Balance = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            balances: [], /* Saldo de los carriers */
            openpay_project: null, /* Registro de afiliación*/
            drissly_carriers: [], /* Lista de carriers */
            balance: null, /* Tupla de carrier seleccionada*/
            modal_balance: false, /* Modal para obtener saldo a través de openpay */
            modal_balance_add: false /*Modal para asignar saldo a los carriers*/
        }
    }

    componentDidMount = () => {
        this.getBalances();
        this.getDrisslyCarriers();
    }

    getDrisslyCarriers = async () => {
        let response = await Axios.get(`${__SERVER__}/drissly/carriers`);
        if (response.status === 200) {
            this.setState({ drissly_carriers: response.data });
        }
    }

    getBalances = async () => {
        let openpay_project = await Axios.get(`${__SERVER__}/victum_openpay/projects/${Variables.project_id}`);
        if (openpay_project.status === 200 && openpay_project.data.length > 0) {
            this.setState({ openpay_project: openpay_project.data[0] });
            let response = await Axios.get(`${__SERVER__}/projects/balance?field=projects_openpay_id&id=${openpay_project.data[0].id}`);
            if (response.status === 200 && response.data.length > 0) {
                this.setState({ balances: response.data });
            }
            // console.log("response: ", response);
        }
        // console.log("response openpay: ", openpay_project);
    }

    actionModalBalance = () => {
        this.setState({ modal_balance: !this.state.modal_balance });
    }

    actionModalBalanceAdd = () => {
        this.props.getPermission();
        this.setState({ modal_balance_add: !this.state.modal_balance_add });
    }

    render = () => {
        let { drissly_carriers, balances } = this.state;
        return (
            <section className="container_table">
                <h1>Saldo</h1>
                <Row className={'align-items-start'}>
                    <Col md={3} className={"scrollvertical"}>
                        <Card border="success" className="card_balance">
                            <Card.Header className="header">Saldo sin asignar</Card.Header>
                            <Card.Body>
                                <center>
                                    <Card.Title className="card_title">${this.props.unassigned_balance?.toFixed(2)}</Card.Title>
                                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Saldo</Tooltip>}>
                                        <Button variant="outline-success" onClick={this.actionModalBalance}><FaCreditCard /> Abonar saldo</Button>
                                    </OverlayTrigger>
                                    <Card.Text>
                                        Dispone de este saldo para asignar al Carrier que desee.
                                    </Card.Text>
                                </center>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={9} className={"scrollvertical"}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Carrier</th>
                                    <th>Saldo asignado</th>
                                    <th>Saldo gastado</th>
                                    <th>Saldo restante</th>
                                    <th>Agregar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    balances.map((balance, index) =>
                                        <tr key={index}>
                                            <td>{drissly_carriers[_.findIndex(drissly_carriers, { id: balance.drissly_carrier_id })].name}</td>
                                            <td>{balance.assigned_balance}</td>
                                            <td>{balance.spended_balance}</td>
                                            <td>{balance.remaining_balance}</td>
                                            <td>
                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Saldo</Tooltip>}>
                                                    <Button variant="outline-success" onClick={e => {
                                                        this.setState({ balance });
                                                        this.actionModalBalanceAdd();
                                                    }}><FaPlus /></Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                {/* Modal balance */}
                {
                    this.state.modal_balance ?
                        <ModalBalance
                            title={'Obtener saldo'}
                            onClose={this.actionModalBalance}
                        /> : null
                }

                {/* Modal Add balance */}
                {
                    this.state.modal_balance_add ?
                        <ModalBalanceAdd
                            title={'Administrar saldo'}
                            unassigned_balance={this.props.unassigned_balance}
                            openpay_project={this.state.openpay_project}
                            getBalances={this.getBalances}
                            balance={this.state.balance}
                            onClose={this.actionModalBalanceAdd}
                        /> : null
                }
            </section>
        );
    }
}

const ElectronicPayments = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasElectronicPayments: false, //Cuenta con medios electrónicos de pago
            unassigned_balance: 0,
            buttons_active: [
                'primary',
                'secondary',],
        }
    }

    componentDidMount = () => {
        this.getPermission();
    }

    getPermission = async () => {
        let response = await Axios.get(`${__SERVER__}/victum_openpay/projects/${this.props.project_id}`);
        console.log("response: ", response);
        if (response.status === 200 && response.data.length > 0) {
            this.setState({ hasElectronicPayments: true, unassigned_balance: response.data[0].unassigned_balance });
        }
    }

    handleOptionChange = (i) => {
        this.setState(prevState => {
            const buttons_active = prevState.buttons_active.map((item, index) => {
                return i === index ? 'primary' : 'secondary'
            })
            return { buttons_active, }
        })
    }

    render = () => {
        console.log("[props] Electronic Payments: ", this.props)
        return (
            <section>
                <h1>Medios electrónicos de pago</h1>

                {
                    !this.state.hasElectronicPayments ?
                        <Alert variant="warning">
                            <Alert.Heading>No cuenta con este módulo</Alert.Heading>
                            <p>
                                En este módulo usted puede gestionar el saldo de su bolsa para recargas teléfónicas,
                                pago de srvicios y peaje.
                            </p>
                            <hr />
                            <p className="mb-0">
                                Altualmente no cuenta con este módulo. Solicitar con el administrador.
                            </p>
                        </Alert> :
                        <section style={{ background: '#fff', height: '85vh' }}>
                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Saldo</Tooltip>}>
                                <Button name={0} onClick={e => this.handleOptionChange(0)} variant={`outline-${this.state.buttons_active[0]}`}>
                                    <FaCreditCard /> Saldo</Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Movimientos</Tooltip>}>
                                <Button name={1} onClick={e => this.handleOptionChange(1)} variant={`outline-${this.state.buttons_active[1]}`}>
                                    <FaExchangeAlt /> Movimientos</Button>
                            </OverlayTrigger>
                            {/*  Saldo */}
                            {
                                this.state.buttons_active[0] === 'primary' ?
                                    <Balance
                                        unassigned_balance={this.state.unassigned_balance}
                                        getPermission={this.getPermission}
                                    /> : null
                            }
                            {/* Movimientos */}
                            {
                                this.state.buttons_active[1] === 'primary' ?
                                    <Movements
                                    /> : null
                            }
                        </section>
                }
            </section>
        );
    }
}

export default ElectronicPayments;