import React, { useState } from 'react';
import './css/Login.css';
import { __SERVER__ } from '../server/info';
import { httpStatus, Variables } from '../variables/Variables';
import { Alert, Button, Form, Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import { useDispatch } from 'react-redux';
import victumBot from '../components/video/victumBot.mp4';
import Axios from 'axios';
import { signon } from 'api/users/auth';
import { OPEN_SESSION, UPDATE_STAGES } from 'store/actions/actionTypes';
import PinInput from './VerifyCode';
import { faEye, faEyeSlash, faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormIcon2 from './layouts/FormIcon2';
import { Center, Heading } from '@chakra-ui/react';

const publicIP = require('public-ip');

const Login = () => {
    const dispatch = useDispatch();

    const [payload, setPayload] = useState({});
    const [verifyCode, setVerifyCode] = useState(false);
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState({
        error: null, loading: false, alertType: 'danger'
    });

    const getGeolocation = () => {
        let geolocation = { lat: null, log: null };
        if ("geolocation" in navigator) {
            try {
                navigator.geolocation.getCurrentPosition(function (position) {
                    geolocation.lat = position.coords.latitude;
                    geolocation.log = position.coords.longitude;
                });
            } catch (error) {
                console.log(error)
            }
        }
        return geolocation;
    }

    // Obtiene el tipo de navegador
    const getBrowserType = () => {
        let userAgent = navigator.userAgent;
        let browserType;

        try {
            if (navigator.userAgentData) {
                const { brands } = navigator.userAgentData;
                browserType = brands.find(brand => brand.brand !== "Not a Brand").brand;
                const knownBrands = brands.filter(brand => !/not|brand/i.test(brand.brand.replace(/\s|[^a-zA-Z]/g, '')));

                if (knownBrands.length > 0) {
                    // Tomar la primera marca disponible.
                    let brand = knownBrands[0];
                    const realBrands = knownBrands.filter(brand => !/Chromium/i.test(brand.brand.replace(/\s|[^a-zA-Z]/g, '')));
                    if (realBrands.length > 0) brand = realBrands[0];
                    browserType = `${brand.brand} versión ${brand.version}`;
                }
            }
        } catch (error) {
            browserType = 'Navegador Desconocido';
        }

        return browserType || userAgent;
    };

    // Crear registro de usuario
    const setUserLog = async (status) => {
        let geolocation = getGeolocation();
        let ipv4 = 'unknow', ipv6 = 'unknow';
        try { ipv4 = await publicIP.v4(); } catch (error) { };
        try { ipv6 = await publicIP.v6(); } catch (error) { };

        const data = {
            user_id: Variables.user.id,
            ip_address_v4: ipv4,
            ip_address_v6: ipv6,
            browser_type: getBrowserType(),
            application: 1,
            latitude: geolocation.lat,
            longitude: geolocation.log,
            status,
            platform_name: navigator?.platform
        };
        Axios.post(`${__SERVER__}/users_logs`, data);
    }

    const login = async () => {
        const { username, password } = payload;
        const { loading } = info;
        if (loading) { return; }
        setInfo({ ...info, loading: true });
        if (username === "" || password === "") {
            setInfo({ ...info, error: 'Campos incompletos' });
            return;
        }
        const { response } = await signon({ username, password, app: 'W' });

        if (response && response.status !== 0) {
            const { status, data } = response;
            switch (status) {
                case httpStatus.forbidden:
                    setVerifyCode(true);
                    setInfo({ ...info, loading: false, error: data.message, alertType: 'info' });
                    break;
                case httpStatus.ok:
                    goHome(data);
                    break;
                default:
                    setInfo({ ...info, loading: false, error: data.message, alertType: 'warning' });
                    setUserLog(data.message);
                    break;
            }
        } else {
            setInfo({ ...info, loading: false, error: 'Error con el servidor', alertType: 'danger' });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayload({ ...payload, [name]: value });
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') login();
    }

    const goHome = (data) => {
        setInfo({ ...info, error: null });
        Variables.package_id = data.package.id;
        Variables.user = data;
        Variables.permissions = data.permissions;
        if (Variables.permissions.length === 0) {
            setUserLog('Without Permissions');
            setInfo({ ...info, error: 'No tiene permisos para usar esta plataforma', loading: false });
        } else {
            setUserLog('Completed');
            setInfo({ ...info, loading: false, error: null });
            dispatch({
                type: OPEN_SESSION,
                session: 'active',
                dataUser: data,
                package_id: data.package.id
            });
            dispatch({
                type: UPDATE_STAGES,
                stages: data.stages
            });
        }
    }

    const handleClick = () => setShow(!show);

    return (
        <div>
            <section className="twoColumns">
                <section className="form">
                    <section className="black">
                        <Form className="login">
                            <video autoPlay loop src={victumBot} />
                            {
                                verifyCode ? <PinInput
                                    username={payload.username}
                                    goHome={goHome}
                                    setUserLog={setUserLog}
                                /> : <>
                                    <Center className='mb-2'>
                                        <Heading size={'md'} color={'black'}>Iniciar sesión</Heading>
                                    </Center>
                                    <Row>
                                        <FormIcon2 title='Usuario' name='username' placeholder='Usuario'
                                            handleChange={handleChange} icon={<FontAwesomeIcon icon={faUser} />}
                                        />
                                    </Row>
                                    <Row>
                                        <FormIcon2 type={show ? 'text' : 'password'} title='Contraseña' name='password'
                                            placeholder='Contraseña' handleChange={handleChange} icon={<FontAwesomeIcon icon={faKey} />}
                                            re_icon={<FontAwesomeIcon icon={show ? faEye : faEyeSlash} />} re_onClick={handleClick} onKeyDown={onKeyDown}
                                        />
                                    </Row>
                                    <Button className="loginB mt-4" variant="danger" onClick={login} disabled={!!info.loading}>
                                        Acceder {info.loading && <div className="loadingCCC"></div>}
                                    </Button>


                                </>
                            }
                            {
                                info.error &&
                                <Alert dismissible={true} onClose={() => setInfo({ ...info, error: null })} variant={info.alertType}>{info.error}</Alert>
                            }

                        </Form>
                    </section>
                </section>
                <header className="info">
                    <h1 className="h1">Sistema de Gestión de Autopistas (SGA)</h1>
                    <h2>Bienvenido a VICTUM</h2>
                    <article className="info">
                        <ul>
                            <li className="li" style={{ color: "white" }}>Plataforma para Gestión de Infraestructura</li>
                            <li className="li" style={{ color: "white" }}>Fabricantes y desarrolladores RPA*</li>
                            <li className="li" style={{ color: "white" }}>Modular y adaptable</li>
                            <li className="li" style={{ color: "white" }}>Multiplataforma y multidispositivo</li>
                        </ul>
                    </article>
                </header>
            </section>
        </div>
    );
}

export default Login;
