import React, { useEffect, useState } from 'react'
import Incidente from '../Proyectos/Mantemiento/Incidente';
import { faFilePdf, faInfo } from '@fortawesome/free-solid-svg-icons';
import { indexIncidents, indexMaintenanceIncidents } from 'api/maintenance_standards/incidents';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table';
import Search from './Search';
import moment from 'moment';
import Report from './ReportPdf';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import LoadingDoc from '../Entregables/LoadingDoc'
import { Button } from 'react-bootstrap'
import { FaFilePdf } from 'react-icons/fa'
import ModalComp from 'components/layouts/Modal';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Variables } from 'variables/Variables';
import { Skeleton } from 'antd';
import IncidentDetails from '../Proyectos/Mantemiento/Incidents/IncidentDetails';
const { __SERVER__ } = require("../../../server/info");

const List = ({ project_id }) => {
    const [data, setData] = useState([]);					// incidentes hallados
    const [page, setPage] = useState(1);					// Página actual
    const [pageSize, setPageSize] = useState(10);			// Tamaño de la página
    const [totalItems, setTotalItems] = useState(0);		// Items hallados
    const [loading, setLoading] = useState(true);			// Indica el estado de carga
    const [modalDetails, setModalDetails] = useState(false);
    const [incidentSel, setIncidentSel] = useState({});
    const [Show, setShow] = useState(false);
    const [media, setMedia] = useState([])
    const [filters, setfilters] = useState(1)               // cambia por busqueda
    const [Datafilters, setDatafilters] = useState(null)    // será un objeto con los parametros
    const [dataTxt, setdataTxt] = useState(null)
    const [load, setload] = useState(true)

    useEffect(() => {
        getData();
    }, [page, pageSize, filters]);

    const [form, setForm] = useState({
        creation_dateS: '',
        creation_dateE: '',
        status_id: '',
        standard_id: '',
        segment_id: ''
    });

    // Obtener los incidentes registrados en el estándar de mantenimiento
    const getData = async () => {
        setLoading(true);
        let objectParams = Datafilters ? { ...Datafilters, project_id, page, pageSize } : { project_id, page, pageSize }

        const { data, totalItems } = await indexMaintenanceIncidents(objectParams)

        const newData = data.map(item => {
            const { creation_date, data } = item;
            return {
                ...item,
                deductive: item.deductive / 100,
                creation_date: moment(creation_date).format('LLL'),
                estimated_date: moment(data?.estimated_date).format('LLL')
            }
        });
        setData(newData);
        setTotalItems(totalItems);
        setLoading(false);
    }
    const getDeductive = (item_data) => {
        let response = 0;
        try {
            return item_data.deductive.factor / 100;    // El porcentaje viene expresado en razón de 100, no en razón de 1. Es decir 0.5% es 0.5
        } catch (error) {
            return response;
        }
    }
    // Método que se lanza al cambiar de pagina o tamaño de paginación
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const viewDetails = async (item) => {
        // let incidentAll = await indexIncidents({ project_id, mode: 'all', id: item.id });
        // console.log("🚀 ~ file: List.jsx:83 ~ viewDetails ~ incidentAll:", incidentAll)
        setIncidentSel(item);
        setModalDetails(!modalDetails);
    }
    const closeButton = () => setShow(false);

    const createReport = async item => {
        let incidentAll = await indexIncidents({ project_id, mode: 'all', id: item.id });
        setIncidentSel(incidentAll.data);
        let results = [];
        if (incidentAll.data[0].media.length > 0) {
            let dataTxts = incidentAll.data[0].media.filter(item => item.type === 'text');
            if (dataTxts.length > 0) {
                dataTxts = dataTxts.map(item => item.file_path)
                const promises = dataTxts.map(file_path => fetch(`${__SERVER__}/projects/${Variables.project_id}/inspections/${incidentAll.data[0].inspectionId}/incidents/${incidentAll.data[0].id}/download?path=${file_path}`).then(response => response.text()));

                Promise.all(promises)
                    .then(texts => {
                        results = texts;
                        setdataTxt(results)
                        setload(false)
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                setload(false);
            }
        } else {
            setload(false);
        }
        setMedia(incidentAll.data[0].media);
        setShow(true);
    }
    return (
        <>
            {/* Filtros de búsqueda */}
            <Search form={form} setForm={setForm} data={data} setfilters={setfilters} filters={filters} setDatafilters={setDatafilters} />
            {/* Tabla de las incidencias */}
            <TableComp
                headers={['#', 'Estándar', 'Evaluación', 'Segmento', 'Creado por', 'Responsable', 'Creado en', 'Resolución estimada', 'Deductiva', 'Estado', 'Semáforo', 'Acciones']}
                keys={[
                    '#',
                    'maintenance_standard_name',								// Nombre del estándar de mantenimiento
                    'incident_evaluation_name',							// Evaluación del estándar
                    'segment_start',									// Código del segmento en el que se levantó el incidente
                    'created_by_full_name',										// Nombre completo del usuario que levantó el incidente
                    'responsable_by_full_name',
                    'creation_date',								// Fecha de creación del incidente
                    'estimated_date', 							// Fecha estimada de resolución del incidente
                    'deductive',
                    'status_id',									// Estado en el que se encuentra el incidente
                    'completion_status',
                ]}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faInfo, variant: 'outline-primary', handleClick: viewDetails, tooltip: 'Ver detalles de la incidencia' },
                    { icon: faFilePdf, variant: 'outline-danger', handleClick: createReport, tooltip: 'Generar reporte' }
                ]}
                badges={[{ index: 9 }, { index: 10 }]}
                percentage={[{ index: 8, accuracy: 1 }]}
                badges_values={[{
                    col: 'status_id',
                    values: [
                        { color: '#17A2B8', value: 1, name: "Creado" },
                        { color: '#17A2B8', value: 2, name: "Levantado" },
                        { color: '#007BFF', value: 3, name: "Atendiendo" },
                        { color: '#000', value: 4, name: "Cerrado" },
                        { color: '#FFC107', value: 5, name: "Cerrado con retraso" },
                        { color: '#282C34', value: 6, name: "No hecho" },
                        { color: '#282C34', value: 7, name: "Completado" }
                    ]
                }, {
                    col: 'completion_status',
                    values: [
                        { color: '#1EC421', value: 'V' },
                        { color: '#F1BB29', value: 'N' },
                        { color: '#DA2721', value: 'R' },
                        { color: '#282C34', value: 'C' },
                    ]
                }]}
            />
            {/* Paginación */}
            <PaginationComp totalItems={totalItems} current={page} onChange={changePage} />
            {/* Modal para visualizar los detalles del incidente */}
            {
                // modalDetails && <Incidente
                //     datos={incidentSel}
                //     setThisNull={() => setModalDetails(!modalDetails)}
                // />
            }
            {
                modalDetails && <IncidentDetails
                    title={'Title'}
                    id={incidentSel.id}
                    onClose={() => setModalDetails(false)}
                    project_id={project_id}
                />
            }
            {
                Show && <ModalComp
                    onClose={closeButton}
                    size="sm"
                    title="Reporte"
                    body={
                        (!load) ?
                            <>
                                <PDFDownloadLink
                                    document={<Report dataTxt={dataTxt} media={media} incidentSel={incidentSel} />}
                                    fileName="Reporte_de_Incidencias.pdf">
                                    {
                                        ({ loading }) => loading ? <LoadingDoc /> : <Button className='w-100' variant="success" ><FaFilePdf className="mr-1" />Reporte Listo para descargar</Button>
                                    }
                                </PDFDownloadLink>

                                <BlobProvider document={<Report dataTxt={dataTxt} media={media} incidentSel={incidentSel} />}>
                                    {({ url, loading }) =>
                                        loading ? <LoadingDoc /> : (<a className="text-white btn btn-primary mt-1" href={url} target="_blank" rel="noopener noreferrer"><ExternalLinkIcon className="mr-1" />Abrir reporte en una nueva ventana
                                        </a>
                                        )}
                                </BlobProvider>
                            </> : <Skeleton active />
                    }
                />
            }
        </>
    )
}

export default List