import { faBan, faCheck, faCheckDouble, faEye, faFileArchive, faFileCode, faFileContract, faFilePdf, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react'
import { downloadInvoice, indexInvoices, sendInvoice } from 'api/facturapi/Invoices';
import NewInvoice from '../fragments/NewInvoice';
import CancelInvoice from './CancelInvoice';
import InvoiceDetails from './Details';
import FlexButton from 'components/layouts/FlexButton';
import TableComp from 'components/layouts/Table';

const InvoicesComp = () => {
    const [invoices, setInvoices] = useState([]);
    const [invoiceSel, setInvoiceSel] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [modalDet, setModalDet] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalCan, setModalCan] = useState(false);

    useEffect(() => {
        getInvoices();
    }, []);

    const getInvoices = async () => {
        setLoading(true);
        let response = await indexInvoices();
        setTotalItems(response.totalItems);
        setInvoices(response.data);
        console.log("Invoices: ", response.data);
        setLoading(false);
    }

    const viewDetails = (item) => {
        setInvoiceSel(item);
        openOrCloseDet();
    }

    const downloadPDF = (item) => downloadInvoice(item.id, 'pdf');
    const downloadXML = (item) => downloadInvoice(item.id, 'xml');
    const downloadZip = (item) => downloadInvoice(item.id, 'zip');
    const sendByEmail = (item) => {
        let response = sendInvoice(item.id);
        // if (response)
            // setToast({
            //     toast, title: '¡Operación exitosa!', status: 'success',
            //     description: 'Se ha enviado la factura en formato PDF y XML al correo del cliente'
            // });
    }

    const openOrCloseModalAdd = () => setModalAdd(!modalAdd);
    const openOrCloseDet = () => setModalDet(!modalDet);
    const openOrCloseCancel = () => setModalCan(!modalCan);

    const cancelInvoice = (item) => {
        setInvoiceSel(item);
        openOrCloseCancel();
    }

    const cancelVoucher = () => {

    }

    const redirectTo = (item) => {
        window.open(item.verification_url, '_blank');
    }

    return (
        <Fragment>
            <TableComp
                headers={['#', 'ID', 'Cliente', 'Tipo', 'Moneda', 'Total', 'Estado', 'Creado en', 'Acciones']}
                keys={['#', 'id', 'customer_name', 'type', 'currency', 'total', 'status', 'created_at']}
                body={invoices}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                currency={[{ index: 5 }]}
                actions={[
                    { icon: faEye, handleClick: viewDetails, tooltip: 'Ver detalles de la factura', variant: 'outline-secondary' },
                    { icon: faFilePdf, handleClick: downloadPDF, tooltip: 'Descargar PDF', variant: 'outline-danger' },
                    { icon: faFileCode, handleClick: downloadXML, tooltip: 'Descargar XML', variant: 'outline-primary' },
                    { icon: faFileArchive, handleClick: downloadZip, tooltip: 'Descargar ZIP', variant: 'outline-success' },
                    { icon: faPaperPlane, handleClick: sendByEmail, tooltip: 'Enviar por correo electrónico', variant: 'outline-dark' },
                    { icon: faCheckDouble, handleClick: redirectTo, tooltip: 'Verificar CFDi', variant: 'outline-success', dinamic: [{key: 'status', value: 'valid'}] },
                    { icon: faBan, handleClick: cancelInvoice, tooltip: 'Cancelar factura', variant: 'outline-warning', dinamic: [{key: 'status', value: 'valid'}] },
                    { icon: faFileContract, handleClick: cancelVoucher, tooltip: 'Acuse de cancelación', variant: 'outline-info', dinamic: [{key: 'status', value: 'canceled'}] },
                ]}
                icons={[
                    { value: 'valid', icon: <FontAwesomeIcon icon={faCheck} color='green' /> },
                    { value: 'canceled', icon: <FontAwesomeIcon icon={faBan} color='red' />},
                ]}
            />
            {/* Agregar factura */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={openOrCloseModalAdd} />

            {/* Modal para agregar nueva factura */}
            {
                modalAdd && <NewInvoice
                    onClose={openOrCloseModalAdd}
                    update={getInvoices}
                />
            }
            {/* Modal para ver los detalles de la factura */}
            {
                modalDet && <InvoiceDetails
                    onClose={openOrCloseDet}
                    invoice={invoiceSel}
                />
            }
            {/* Modal para cancelar una factura */}
            {
                modalCan && <CancelInvoice
                    onClose={openOrCloseCancel}
                    invoice={invoiceSel}
                    update={getInvoices}
                />
            }
        </Fragment>
    )
}

export default InvoicesComp