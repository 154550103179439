import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { indexAssetsTypes } from 'api/operation_standards/assets_types';
import FlexButton from 'components/layouts/FlexButton';
import PaginationComp from 'components/layouts/Pagination';
import TableComp from 'components/layouts/Table'
import React, { useEffect, useState } from 'react'
import AddAssetType from '../modals/AddAssetType';
import UpdateAssetType from '../modals/updateAssetType';

const AssetsTypesTab = (props) => {
    const { package_id, table } = props;

    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [assetTypeSel, setAssetTypeSel] = useState(null);

    useEffect(() => {
        getAssetsTypes();
    }, [page, pageSize]);

    const getAssetsTypes = async () => {
        setLoading(true);
        let response = await indexAssetsTypes({ package_id, table });
        setData(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const updateAsset = (item) => {
        setAssetTypeSel(item);
        setModalUpdate(!modalUpdate);
    }

    return (
        <>
            <TableComp
                headers={['#', 'Nombre', 'Creado en', 'Acciones']}
                keys={['#', 'name', 'created_at']}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faEdit, variant: 'outline-primary', handleClick: updateAsset, tooltip: 'Actualizar registro' }
                ]}
            />
            {/* Paginación */}
            <PaginationComp totalItems={totalItems} current={page} onChange={changePage} defaultPageSize={pageSize}/>
            {/* Agregar un registro */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModal(!modal)} />
            {/* Modal para agregar un registro */}
            {
                modal && <AddAssetType
                    onClose={() => setModal(!modal)}
                    package_id={package_id}
                    table={table}
                    update={getAssetsTypes}
                />
            }
            {/* Modal para actualizar los registros */}
            {
                modalUpdate && <UpdateAssetType
                    onClose={() => setModalUpdate(!modalUpdate)}
                    package_id={package_id}
                    table={table}
                    update={getAssetsTypes}
                    assetTypeSel={assetTypeSel}
                />
            }
        </>
    )
}

export default AssetsTypesTab