import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableComp from 'components/layouts/Table';
import { indexAssets, updateAssets } from 'api/operation_standards/assets';
import { faInfo, faListAlt, faPhotoVideo, faPlus, faSuitcaseRolling, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ModalPics from '../modals/ModalPics';
import ActivityAssignment from '../modals/ActivityAssignment';
import { indexAssetsBaseLines } from 'api/operation_standards/assets_base_lines';
import { indexAssetsTypes } from 'api/operation_standards/assets_types';
import FiltersAssets from '../fragments/FiltersAssets';
import PaginationComp from 'components/layouts/Pagination';
import Swal from 'sweetalert2';
import AssetDetails from '../modals/AssetDetails';
import ModalAssetsEvaluations from '../modals/AssetsEvaluations';
import FlexButton from 'components/layouts/FlexButton';
import AddAsset from '../modals/AddAsset';
import { downloadXLSX } from 'components/libs/exportData';
import { downloadPDF } from 'components/libs/exportDataPDF';
import AddComponentModal from '../modals/AddComponent';

const AssetsTab = (props) => {
	const {
		project_id,
		table,
		package_id,
		maintenance_types_id,
		maintenance_sections_id,
		getData,
		badges,
		items,
		code,
		name,
		km
	} = props;

	const [data, setData] = useState([]);
	const [assetSel, setAssetSel] = useState(null);
	const [modalPic, setModalPic] = useState(false);
	const [modalAssignActivity, setModalAssignActivity] = useState(false);
	const [modalDetails, setModalDetails] = useState(false);
	const [modalAdd, setModalAdd] = useState(false);
	const [modalEvaluations, setModalEvaluations] = useState(false);
	const [modalComponent, setModalComponent] = useState(false);
	// Valores que indican el color que será asignado al badge según el id relacionado
	const [values, setValues] = useState([]);
	const [valuesTypes, setValuesTypes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [baseLines, setBaseLines] = useState([]);
	const [assetTypes, setAssetTypes] = useState([]);
	const [filters, setFilters] = useState({});
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [totalItems, setTotalItems] = useState(0);
	const [tkeys, setTkeys] = useState([]);
	const [theaders, setTheaders] = useState([]);
	const [loadingXLSX, setLoadingXLSX] = useState(false);
	const [loadingPDF, setLoadingPDF] = useState(false);

	useEffect(() => { getAssets(); }, [page, pageSize]);

	useEffect(() => {
		getTableData();
		getAssetsBaseLines();
		getAssetsTypes();
	}, []);
	// Obtener headers y keys de la table del atributo items
	const getTableData = () => {
		setTheaders(items.filter((item) => item.table).map((item) => item.label));
		setTkeys(items.filter((item) => item.table).map((item) => item.key));
	}
	// Obtener los tipos de elementos de los inventarios
	const getAssetsTypes = async () => {
		let response = await indexAssetsTypes({ package_id, table });
		if (response.data.length > 0) {
			setAssetTypes(response.data);
			setValuesTypes(
				response.data.map((item) => {
					const { name, id, color } = item;
					return { name, value: id, color };
				})
			);
		}
	}
	// Obtener las líneas base de los activos de inventario
	const getAssetsBaseLines = async () => {
		let response = await indexAssetsBaseLines({ package_id });
		if (response.data.length > 0) {
			setBaseLines(response.data);
			setValues(
				response.data.map((item) => {
					const { color, name, id } = item;
					return { color, value: id, name }
				})
			);
		}
	}
	// Obtener los activos de inventario
	const getAssets = async () => {
		setLoading(true);
		let response = await indexAssets({ project_id, table, query: { ...filters, page, pageSize } });
		setData(response.data);
		setTotalItems(response.totalItems);
		setLoading(false);
	}

	const openOrCloseModalPic = () => setModalPic(!modalPic);
	const openOrCloseAssignActivity = () => setModalAssignActivity(!modalAssignActivity);
	const openOrCloseDetails = () => setModalDetails(!modalDetails);
	const openOrCloseAdd = () => setModalAdd(!modalAdd);
	const openOrCloseEvaluations = () => setModalEvaluations(!modalEvaluations);

	// Método para asignar actividades a los bienes
	const assignActivity = (item) => {
		setAssetSel(item);
		openOrCloseAssignActivity();
	}

	const viewPhotograph = (item) => {
		setAssetSel(item);
		openOrCloseModalPic();
	}

	const changePage = (page, pageSize) => {
		setPage(page);
		setPageSize(pageSize);
	}

	const changeVisibility = (item) => {
		const { id } = item;
		Swal.fire({
			title: '¿Está seguro que desea eliminar este elemento?',
			text: 'Al realizar esta operación el elemento ya no será visible en las estadísticas ni en los reportes.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si, eliminar!',
			cancelButtonText: 'No, cancelar'
		}).then(async (result) => {
			if (result.isConfirmed) {
				await updateAssets({ project_id, table, id, data: { removed: 'removed' } });
				getAssets();	// Actualizar inventario
				Swal.fire(
					'Operación exitosa!',
					'Transacción realizada con éxito',
					'success'
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Operación cancelada',
					'Esta transacción no se pudo realizar satisfactoriamente ',
					'error'
				);
			}
		});
	}

	const viewDetails = (item) => {
		setAssetSel(item);
		openOrCloseDetails();
	}

	const viewEvaluations = (item) => {
		setAssetSel(item);
		openOrCloseEvaluations();
	}

	const getXLSXData = async () => {
		setLoadingXLSX(true);
		let response = await indexAssets({ project_id, table, query: { ...filters } });
		const widthCells = items.map(item => ({ width: item.width }));
		const xlsxItems = items.filter(item => item.xlsx === true);
		const newData = response.data.map(obj => {
			const newObj = {};
			xlsxItems.forEach((item) => {
				const { key, label } = item;
				newObj[label] = obj[key];
			});
			return newObj;
		});
		downloadXLSX({
			file_name: code + ' | ' + name,
			data: newData,
			widthCells
		});
		setLoadingXLSX(false);
	}

	const getPDFData = async () => {
		setLoadingPDF(true);
		let response = await indexAssets({ project_id, table, query: { ...filters } });
		const headers = items.filter(item => item.pdf === true).map(item => {
			const { key } = item;
			return { ...item, value: key }
		});
		downloadPDF({
			title: code + ' | ' + name,
			file_name: code + ' | ' + name,
			headers,
			data: response.data
		});
		setLoadingPDF(false);
	}
	// Agregar componentes al activo
	const addComponent = (item) => {
		setAssetSel(item);
		setModalComponent(true);
	}

	return (
		<>
			{/* Filtros de búsqueda para el inventario */}
			<FiltersAssets
				getData={getAssets}
				baseLines={baseLines}
				assetTypes={assetTypes}
				filters={filters}
				setFilters={setFilters}
				getXLSXData={getXLSXData}
				getPDFData={getPDFData}
				loadingXLSX={loadingXLSX}
				loadingPDF={loadingPDF}
			/>
			{/* Tabla de los bienes */}
			<TableComp
				headers={[...theaders, 'Acciones']}
				keys={tkeys}
				body={data}
				loading={loading}
				loadingItems={pageSize}
				page={page}
				badges={badges}
				badges_values={[{
					values,
					col: 'assets_base_line_id'
				}, {
					values: valuesTypes,
					col: 'type_id'
				}, {
					values: [
						{ color: 'red', value: 'Sin Verificar' },
						{ color: 'orange', value: 'Parcialmente Verificado' },
						{ color: 'green', value: 'Verificado' }
					],
					col: 'check_status'
				}
				]}
				km={km}
				// Botones de acción de la tabla
				actions={[
					{ icon: faPhotoVideo, handleClick: viewPhotograph, tooltip: 'Ver fotografías' },
					{ icon: faPlus, handleClick: addComponent, tooltip: 'Agregar componente' },
					{ icon: faSuitcaseRolling, handleClick: assignActivity, tooltip: 'Asignar Actividad', variant: 'outline-success' },
					{ icon: faInfo, handleClick: viewDetails, tooltip: 'Ver detalles', variant: 'outline-info' },
					{ icon: faListAlt, handleClick: viewEvaluations, tooltip: 'Ver evaluaciones', variant: 'outline-info' },
					{ icon: faTrashAlt, handleClick: changeVisibility, tooltip: 'Eliminar elemento del inventario', variant: 'outline-danger' }
				]}
			/>
			{/* Agregar activo */}
			<FlexButton
				style={{ bottom: 30, right: 20 }}
				onClick={openOrCloseAdd}
				icon={faPlus}
			/>
			{/* Componente de Paginación */}
			<PaginationComp current={page} onChange={changePage} totalItems={totalItems} defaultPageSize={pageSize} />
			{/* Modal para ver la fotografía de cada bien */}
			{
				modalPic && <ModalPics
					onClose={openOrCloseModalPic}
					asset={assetSel}
					project_id={project_id}
					table={table}
					syncServer={getAssets}
				/>
			}
			{/* Modal para la asignación de actividades */}
			{
				modalAssignActivity && <ActivityAssignment
					onClose={openOrCloseAssignActivity}
					table_name={table}
					project_id={project_id}
					asset={assetSel}
					package_id={package_id}
					syncServer={getAssets}
					maintenance_types_id={maintenance_types_id}
					maintenance_sections_id={maintenance_sections_id}
					getData={getData}
					type='RUTINARIO'
					title='Programa de Mantenimiento Rutinario'
				/>
			}
			{/* Modal para ver los detalles el activo */}
			{
				modalDetails && <AssetDetails
					onClose={openOrCloseDetails}
					asset={assetSel}
					items={items}
					project_id={project_id}
					package_id={package_id}
					table={table}
					syncServer={getAssets}
				/>
			}
			{/* Modal para ver las evaluaciones */}
			{
				modalEvaluations && <ModalAssetsEvaluations
					onClose={openOrCloseEvaluations}
					asset={assetSel}
					project_id={project_id}
					table={table}
				/>
			}
			{/* Modal para agregar un activo */}
			{
				modalAdd && <AddAsset
					onClose={openOrCloseAdd}
					items={items}
					package_id={package_id}
					table={table}
					project_id={project_id}
					syncServer={getAssets}
				/>
			}
			{/* Modal para agregar un componente */}
			{
				modalComponent && <AddComponentModal
					onClose={() => setModalComponent(false)}
					asset={assetSel}
					package_id={package_id}
					table={table}
				/>
			}
		</>
	)
}

AssetsTab.propTypes = {
	project_id: PropTypes.number.isRequired,
	table: PropTypes.oneOf([
		'assets_its',
		'assets_environment',
		'assets_edification',
		'assets_infrastructure'
	]).isRequired,
	package_id: PropTypes.number.isRequired,
	maintenance_types_id: PropTypes.number.isRequired,
	maintenance_sections_id: PropTypes.number.isRequired,
	getData: PropTypes.func.isRequired,
	badges: PropTypes.arrayOf(
		PropTypes.shape({
			index: PropTypes.number.isRequired
		})
	),
	items: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			label: PropTypes.string,
			description: PropTypes.string,
			table: PropTypes.bool,
			hidden: PropTypes.bool,
			width: PropTypes.number,
			edit: PropTypes.bool,
			add: PropTypes.bool,
			type: PropTypes.oneOf([
				'text',
				'number',
				'textarea'
			]),
			xlsx: PropTypes.bool,
			pdf: PropTypes.bool,
		})
	),
	code: PropTypes,
	name: PropTypes,
	km: PropTypes,
}

export default AssetsTab