import React, { useState } from 'react'
import { Document, Page, Text, View,Image,StyleSheet  } from '@react-pdf/renderer'
import moment from 'moment';
import Cap from '../../../img/cap.jpg'
import { __SERVER__ } from 'server/info';

const styles = StyleSheet.create({
    body:{ padding: 15 },
    Head:{
        display: "flex",
        flexDirection: "row"
    },
    Head_Image:{ width: 110 },
    table: { 
      display: "table",
      borderStyle: "solid",  
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      marginBottom: 15,
      borderColapse: "collapse",
      tableLayout: "auto",
      width: "auto", 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      borderStyle: "solid", 
      borderTopWidth: 1,
      width: "100%" 
    }, 
    tableCol: { 
      borderStyle: "solid",
      borderRightWidth: 1,
      width: "100%",
      height: "auto"
    }, 
    tableCell: { 
      margin: "auto", 
      marginVertical: 5,
      marginHorizontal: 3,
      fontSize: 10,
      textAlign: "center"
    },
    text_center: { textAlign: "center" , fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_title: { textAlign: "center", fontWeight: "bold", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_center50: { textAlign: "center" , fontSize: 12, width: "50%", borderStyle: "solid", borderRightWidth:1 },
    logo:{
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    },
    logo1:{
        width: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid", 
        borderWidth: 1,
    },
    d_flex:{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",  borderStyle: "solid", 
        borderWidth: 1,
    }, txt_center: { textAlign: "center" } ,
    text_logo: {textAlign: "center" , fontSize: 19, fontWeight: "heavy", width: "100%", borderStyle: "solid", borderTopWidth: 1, color: "#009644"},
    mb_5:  { marginBottom: 14, borderStyle: "solid", 
    borderWidth: 1, },
    fz_12: {fontSize: 12, textAlign: "center",  width: "100%"},
    flex:{
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" },
    cuad: { display: "flex", flexDirection: "row" , flexWrap: "wrap", justifyContent: "center" }
});

const Report = ({incidentSel, media, dataTxt}) => {
    return (
        <Document>
            <Page size='A4' orientation="landscape" style={styles.body}>
                <View style={styles.mb_5} fixed>
                    <View style={styles.Head}>
                        <View style={styles.logo}>
                            <Image src={Cap} style={styles.Head_Image}/>
                        </View>

                        <View style={styles.d_flex}>
                            <Text style={{fontWeight: 900}}>Conservación Mayor</Text>
                            <Text style={styles.text_center}></Text>
                            <Text style={styles.text_center}>VERIFICACION FISICA-FOTOGRAFICA DE LA AUTOPISTA AMOZOC – PEROTE (104.9 KM) Y EL LIBRAMIENTO PEROTE (17.6 KM)</Text>
                            <Text style={{textAlign: "center" , fontSize: 12, width: "100%"}}>Reporte de Incidencias</Text>
                        </View>

                        <View style={styles.logo1}>
                            {/* <Text style={styles.text_center}>Autopista Amozoc-Perote</Text> */}
                            <Text style={styles.text_center}>CÓDIGO: -</Text>
                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                <Text style={styles.text_center50}>VERSIÓN: 1</Text>
                                <Text style={{ textAlign: "center" , fontSize: 12, width: "50%" }}>FECHA DE EMISIÓN: { moment().format("D/MM/YY") }</Text>
                            </View>
                            
                        </View>
                    </View>
                </View>

                <View style={styles.table}> 
                    <View style={styles.tableRow} fixed> 
                    {
                        ["#", "Estandar", "Estatus", "Creado por","Fecha creación", "Resolución estimada", "Punto inicial", "Punto final"].map((item, i) => 
                            <View style={styles.tableCol} key={i} > 
                                <Text style={styles.tableCell}>{item}</Text> 
                            </View> 
                        )
                    }
                    </View>
                       
                    {
                        incidentSel && incidentSel.map((inc, i) => 
                            <View style={styles.tableRow} key={inc.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{i+1}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.standard.name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.status.name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.created_by.first_name} {inc.created_by.last_name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{moment(inc.creation_date).format('LLL')}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.data.estimated_date ? moment(inc.data.estimated_date).format('LLL') : 'Sin fecha'}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.longitude} {inc.latitude}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.longitude_E} {inc.latitude_E}</Text></View>
                            </View> 
                        )
                    }
                </View>

                <View style={styles.table}> 
                    <View style={styles.tableRow} fixed> 
                        <View style={styles.tableCol} > 
                            <Text style={styles.tableCell}>Evaluación</Text> 
                        </View> 
                    </View>
                       
                    {
                        incidentSel && incidentSel.map((inc, i) => 
                            <View style={styles.tableRow} key={inc.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}> {inc.data.evaluation.name}</Text></View>
                            </View> 
                        )
                    }
                </View>

                {
                    (media.length > 0) && <View style={styles.cuad}>
                        {
                            media.map((element, i) => { 
                                if(element.type === 'images'){
                                    return (
                                        <View key={i} style={{ margin: 5 }} >
                                            <Image src={`${__SERVER__}/projects/${incidentSel[0].projectId}/inspections/${incidentSel[0].inspectionId}/incidents/${incidentSel[0].id}/download?path=${element.file_path}`} style={styles.Head_Image}/>
                                            <Text style={{ fontSize: 11, margin: 2 }}>FOTO {i+1} - Activo {element.id}</Text>
                                        </View>           
                                    )
                                }
                            })
                        }
                    </View>
                }

                {
                    dataTxt && dataTxt.map((item, i) => 
                    <View key={i} style={{ margin: 5 }} >
                        <Text style={{ fontSize: 11, margin: 2, textAlign: 'center' }}>{item}</Text>
                    </View>  )
                }

                <View style={{display: "flex", flexDirection: "row", marginTop: 100, fontSize: 12,justifyContent: "space-around"}} break>
                    <View style={{ width: '28%', textAlign: 'center' }}>
                        <Text>FORMULÓ:</Text>
                        <Text>Scala, Supervisión Consultoría Asesoría y Laboratorio S.A. de C.V.</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text>Eduardo González Serrano</Text>
                        <Text>Representante legal</Text>
                    </View>
                    <View style={{ width: '28%', textAlign: 'center' }}>
                        <Text>NO OBJECIÓN:</Text>
                        <Text>Construcciones Amozoc Perote SA de CV</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text>Eddy Cabrera Perez</Text>
                        <Text>Construcciones Amozoc Perote SA de CV</Text>
                    </View>
                    <View style={{ width: '28%', textAlign: 'center' }}>
                        <Text>Vo.Bo.</Text>
                        <Text>Construcciones Amozoc Perote SA de CV</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text style={{ color: 'white' }}>.</Text>
                        <Text>Hugo E. Rodríguez Torres</Text>
                        <Text>Gerente de Operación</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default Report