import { faDollarSign, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Alert, Skeleton } from 'antd';
import { indexCostWorkOrdersByStandard } from 'api/maintenance_standards/cost_work_order';
import ButtonIcon from 'components/layouts/ButtonIcon'
import FormIcon from 'components/layouts/FormIcon';
import TabsComp from 'components/layouts/Tabs';
import { getCurrency } from 'components/libs/functions';
import React, { useEffect, useState } from 'react'
import { Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Charges from './Charges';

const moment = require('moment');

const TablePanel = ({ data, totalCosts, type, setAmounts, amounts, rows }) => {

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAmounts(prevState => ({ ...prevState, [type]: { ...prevState[type], [name]: value } }))
    }

    const getDefaultValue = (supply_id) => {
        if (Array.isArray(rows)) {
            const row = rows.find((row) => row.supply_id === supply_id);
            if (row) return row.count;
        }
        return '';
    }

    return (
        <Table striped bordered className="workOrderTable">
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Descripción</th>
                    <th>Unidad de medida</th>
                    <th>Costo por unidad</th>
                    <th>Cantidad</th>
                    <th>Costo total</th>
                </tr>
            </thead>
            <tbody>
                {
                    Array.isArray(data) && data.map((row) =>
                        <tr key={row.id}>
                            <td>{row.code}</td>
                            <td>{row.description}</td>
                            <td>{row.unit}</td>
                            <td>{getCurrency(row.unit_cost)}</td>
                            <td>
                                <input
                                    type="number"
                                    name={row.supply_id}
                                    onChange={handleChange}
                                    value={amounts[type][row.supply_id] || getDefaultValue(row.supply_id)}
                                />
                            </td>
                            <td>{getCurrency(totalCosts[row.supply_id] || '0.00')}</td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
    )
}

const UpdateComp = (props) => {
    const { pumActivities, setIsAdd, setPumActivitySel, pumActivitySel, package_id, standard_id,
        amounts, setAmounts, rows, rowsUpdated, setRowsUpdated, loadingPA, dataPercentage, setDataPercentage,
        total_cost, setTotalCost, direct_costs, setDirectCost } = props;

    const [sSupplies, setSSupplies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCosts, setTotalCosts] = useState({
        materiales: {},
        manoObra: {},
        herramienta: {},
        equipo: {}
    });

    // Obtener el costo directo
    useEffect(() => {
        if (sSupplies.length > 0 && Array.isArray(rows)) {
            const directCost = rows.reduce((total, item) => {
                const { count, supply_id } = item;
                const supply = sSupplies.find((item) => item.supply_id === supply_id)
                return total + count * supply.unit_cost;
            }, 0)
            setDirectCost(directCost);
        }
    }, [rows, sSupplies]);

    useEffect(() => {
        const calculateTotalCosts = () => {
            const materiales = getTotalCosts(sSupplies.filter((item) => item.supply_type_id === 1), amounts.materiales);
            const manoObra = getTotalCosts(sSupplies.filter((item) => item.supply_type_id === 2), amounts.manoObra);
            const herramienta = getTotalCosts(sSupplies.filter((item) => item.supply_type_id === 3), amounts.herramienta);
            const equipo = getTotalCosts(sSupplies.filter((item) => item.supply_type_id === 4), amounts.equipo);
            setTotalCosts({ materiales, manoObra, herramienta, equipo });
        };
        calculateTotalCosts();
    }, [amounts]);

    // Obtener los suministros disponibles para realizar la actividad
    useEffect(() => {
        const getSSupplies = async () => {
            setLoading(true)
            const { response } = await indexCostWorkOrdersByStandard({ package_id, standard_id });
            setSSupplies(response.data);
            setLoading(false);
        }
        getSSupplies();
    }, []);

    // Calcular el costo total para llevar a cabo dicha actividad
    useEffect(() => {
        const totalMateriales = sumarValores(totalCosts.materiales);
        const totalManoObra = sumarValores(totalCosts.manoObra);
        const totalHerramienta = sumarValores(totalCosts.herramienta);
        const totalEquipo = sumarValores(totalCosts.equipo);

        const total = totalMateriales + totalManoObra + totalHerramienta + totalEquipo;
        setDirectCost(total);
    }, [totalCosts]);

    const getTotalCosts = (data, amounts) => {
        const newTotalCosts = {};
        data.forEach((row) => {
            // Se busca si hay registros existentes, para obtener la cantidad
            const exists = rows.find((item) => item.supply_id === row.supply_id);
            let initial_quantity = 0;
            if (exists) {
                initial_quantity = exists.count;
            }
            // Cantidad que se establece en el input, si no hay toma el inicial
            const quantity = parseFloat(amounts[row.supply_id]) || initial_quantity || 0;
            const unitCost = parseFloat(row.unit_cost) || 0;

            newTotalCosts[row.supply_id] = (quantity * unitCost).toFixed(2);
        });
        return newTotalCosts;
    }
    const sumarValores = (objeto) => {
        return Object.values(objeto).reduce((total, stringValue) => {
            // Convierte la cadena a entero
            const valorEntero = parseFloat(stringValue);
            // Maneja casos en los que no se pueda convertir a entero
            return total + (isNaN(valorEntero) ? 0 : valorEntero);
        }, 0);
    };

    const handleChangePumActivities = (item) => {
        if (rowsUpdated.length > 0) {
            Swal.fire({
                title: '¿Está seguro que desea salir sin guardar los cambios?',
                text: "Al aceptar esta operación, se perderá la asignación actual",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, salir'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setPumActivitySel(item);
                    setRowsUpdated([])
                    setAmounts({ materiales: {}, manoObra: {}, herramienta: {}, equipo: {} });
                }
            })
        } else {
            setPumActivitySel(item);
            setAmounts({ materiales: {}, manoObra: {}, herramienta: {}, equipo: {} });
        }
    }
    return (
        <section className="containerWorkOrder">
            <section className="listWorkOrder">
                <article>
                    <ButtonIcon icon={faPlus} name='Agregar' variant='success' onClick={() => setIsAdd(true)} />
                </article>
                {
                    pumActivities.map((item, i) =>
                        <article
                            key={item.id}
                            id={"card_workOrder" + i}
                            onClick={() => handleChangePumActivities(item)}
                            className={pumActivitySel?.id === item.id ? "active_card" : ""}
                        >
                            <h2 for={"card_workOrder" + i}>{item.name}</h2>
                            <label for={"card_workOrder" + i}>{moment(item.created_at).format('LLL')}</label>
                        </article>
                    )
                }
            </section>
            <section className="suppliesWorkOrder">
                {
                    !pumActivitySel ? <Alert
                        message="Seleccionar o crear un elemento"
                        type="warning"
                        showIcon
                    /> : <>
                        {
                            loadingPA ? <Skeleton active /> : <>
                                <Row>
                                    <FormIcon
                                        icon={faDollarSign}
                                        title='Costo total de la Actividad'
                                        value={getCurrency(direct_costs)}
                                    />
                                </Row>
                                {
                                    loading ? <Skeleton active /> :
                                        <TabsComp
                                            tabColor='black'
                                            tablist={['Materiales', 'Mano de obra', 'Herramienta', 'Equipo']}
                                            tabpanels={[
                                                <TablePanel key='' data={sSupplies.filter((item) => item.supply_type_id === 1)}
                                                    totalCosts={totalCosts.materiales} type='materiales'
                                                    setAmounts={setAmounts} amounts={amounts} rows={rows} />,
                                                <TablePanel key='' data={sSupplies.filter((item) => item.supply_type_id === 2)}
                                                    totalCosts={totalCosts.manoObra} type='manoObra'
                                                    setAmounts={setAmounts} amounts={amounts} rows={rows} />,
                                                <TablePanel key='' data={sSupplies.filter((item) => item.supply_type_id === 3)}
                                                    totalCosts={totalCosts.herramienta} type='herramienta'
                                                    setAmounts={setAmounts} amounts={amounts} rows={rows} />,
                                                <TablePanel key='' data={sSupplies.filter((item) => item.supply_type_id === 4)}
                                                    totalCosts={totalCosts.equipo} type='equipo'
                                                    setAmounts={setAmounts} amounts={amounts} rows={rows} />
                                            ]}
                                        />
                                }
                                <Charges
                                    direct_costs={direct_costs}
                                    dataPercentage={dataPercentage}
                                    setDataPercentage={setDataPercentage}
                                    total_cost={total_cost}
                                    setTotalCost={setTotalCost}
                                />
                            </>
                        }
                    </>
                }
            </section>
        </section>
    )
}

export default UpdateComp