import React, { Fragment, useEffect, useState } from 'react';
import { faArrowLeft, faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { indexUsers } from 'api/users/users';
import { Variables } from 'variables/Variables';
import FormIcon from 'components/layouts/FormIcon';
import ButtonIcon from 'components/layouts/ButtonIcon';
import TableComp from 'components/layouts/Table';
import ModalComp from 'components/layouts/Modal';
import { useMutation } from '@apollo/client';
import { INSERT_ROOM } from 'graphql/mutations/_messenger/RoomsMutations';
import Swal from 'sweetalert2';

const NewRoom = (props) => {
    const { onClose, update } = props;

    const [users, setUsers] = useState([]);             // Usuarios a los que se puede agregar a una sala
    const [loading, setLoading] = useState(true);       // Indicador de carga de los usuarios
    const [members, setMembers] = useState([]);         // integrantes de la nueva sala
    const [roomName, setRoomName] = useState('');       // Nombre de la sala
    // const [roomTypes, setRoomTypes] = useState([]);
    const [storeRoom, { data }] = useMutation(INSERT_ROOM);

    useEffect(() => {
        if (data) console.log("Data new room: ", data);
    }, [data]);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        let response = await indexUsers({ package_id: Variables.package_id });  // Obtener todos los usuarios
        setUsers(response.data.filter((usr) => usr.id !== Variables.user.id)); // Eliminar el usuario actual
        setLoading(false);
    }

    const handleChange = (checked, user_id) => {
        if (checked)
            setMembers([...members, { user_id }]);      // Agregar miembro a la lista
        else
            setMembers(members.filter((member) => member.user_id !== user_id));  // ELiminar miembro de la lista
    }
    // Método para marcar por default una tupla
    const getChecked = (user_id) => false;
    // Método para deshabilidar la columna Acciones de alguna tupla
    const getDisabled = (user_id) => false;

    const createRoom = async (data) => {
        await storeRoom({ variables: { name: data.name, data: data.users } });
    }
    const addRoom = async () => {
        let valid = true;
        if (members.length >= 2) {
            if (roomName === '') {
                valid = false;
                Swal.fire('¡Formulario incompleto!', 'Cuando son más de dos integrantes tiene que agregar un nombre para la sala', 'warning');
            }
        }
        let data = {
            name: roomName,
            users: [...members, { user_id: Variables.user.id }]
        }
        if (valid) {
            createRoom(data);
            update();
            onClose();
        }
    }

    const changeRoomName = (e) => {
        setRoomName(e.target.value);
    }
    return (
        <ModalComp
            onClose={onClose}
            title='Configurar nueva sala'
            size='lg'
            body={<Fragment>
                {/* Si la sala es grupal */}
                {
                    <Row className='mb-3'>
                        <Col md={{ span: 6 }}>
                            <FormIcon
                                title='Nombre de la sala'
                                placeholder='Escribe el nombre de la sala'
                                value={roomName}
                                handleChange={changeRoomName}
                                disabled={members.length < 2}
                            />
                        </Col>
                    </Row>
                }
                <div className="">
                    <TableComp
                        headers={['#', 'Nombre', 'Apellidos', 'Correo electrónico', 'Acciones']}
                        keys={['#', 'first_name', 'last_name', 'email']}
                        body={users}
                        loading={loading}
                        actions={{
                            checkedChildren: 'Agregar',
                            unCheckedChildren: 'No agregar',
                            onChange: handleChange,
                            getChecked: getChecked,
                            getDisabled: getDisabled
                        }}
                        type='switch'
                    />
                </div>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                {
                    members.length > 0 &&
                    <ButtonIcon name='Crear sala' variant='outline-success' icon={faCommentAlt} onClick={addRoom} />
                }
            </Fragment>}
        />
    )
}

export default NewRoom;
