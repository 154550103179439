import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import { useGeolocated } from 'react-geolocated';
import _ from 'underscore';

const InventoryMap = (props) => {
    const { data, items, google } = props;
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });

    const [markerPos, setMarkerPos] = useState({});
    const [showInfoWindow, setShowInfoWindow] = useState(true);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [activeMarker, setActiveMarker] = useState({});

    useEffect(() => {
        getMarkerPos();
    }, [coords]);

    const getMarkerPos = () => {
        let lat = 0, lng = 0;
        let pos = _.findIndex(items, { value: 'latitude' });
        // Si el data tiene el posicionamiento se usa
        if (pos !== -1) {
            if (data.latitude && data.longitude) {
                lat = data.latitude;
                lng = data.longitude;
            } else {
                // Se usa la ubicación obtenida de react-geolocated
                if (isGeolocationAvailable) {
                    lat = coords?.latitude;
                    lng = coords?.longitude;
                }
            }
        }
        setMarkerPos({ lat, lng });
    }

    const onMarkerClick = (props, marker, e) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };

    const onMapClicked = (props) => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };
    const onDragend = (coord) => {
        const { latLng } = coord;
        setMarkerPos({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
    }
    return (
        <div style={{ height: '40vh', width: '100%' }}>
            <Map
                google={google}
                zoom={14}
                center={markerPos}
                onClick={onMapClicked}
            >
                <Marker
                    onClick={onMarkerClick}
                    name={'Ubicación del activo'}
                    draggable={true}
                    position={markerPos}
                    onDragend={(_t, _map, coord) => onDragend(coord)}
                />
                <InfoWindow
                    marker={activeMarker}
                    visible={true}>
                    <div>
                        <h5 style={{ color: 'black' }}>{selectedPlace.name}</h5>
                    </div>
                </InfoWindow>
            </Map>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
    LoadingContainer: Skeleton
})(InventoryMap)