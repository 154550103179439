import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexObacEvaluations = async ({ package_id, asset_type_id, operating_indicator_id }) => {
    const url = `/packages/${package_id}/obac_evaluation`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params: {
                asset_type_id,
                operating_indicator_id
            }
        });
        console.log("🚀 ~ file: inspections.js:15 ~ indexObacEvaluations ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}