import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Col, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import '../styles/Room.scss';
import _ from 'underscore';

const Room = (props) => {
    const { room, count, users, user_id, room_id } = props;
    
    const [roomName, setRoomName] = useState('');
    
    // Actualizar el nombre de las salas, al detectar cambios en la colleción de usuarios o sala
    useEffect(() => {
        getRoomName();
    }, [users, room]);

    const getRoomName = () => {
        if (room.room_users.length === 2) {                                         // Es chat de dos personas
            let _users = room.room_users.filter((ru) => ru.user_id !== user_id);    // Se obtiene el identificador del destinatario
            if (_users.length === 1) {
                let pos = _.findIndex(users, { id: _users[0].user_id });            // Se obtiene la posición del destinatario en el arreglo de usuarios del paquete
                if (pos !== -1)
                    setRoomName(`${users[pos].first_name} ${users[pos].last_name}`);
            }
        } else {
            setRoomName(room.name);
        }
    }

    return (
        <Row
            // style={{ height: '8vh', border: 'solid 0.5px', borderColor: 'gray' }}
            justify={'center'}
            align={'middle'}
            className={room.id === room_id ? 'card-room-sel' : 'card-room'}
        >
            <Col span={5}>
                <Avatar
                    size={{ xs: 24, sm: 32, md: 35, lg: 40, xl: 45, xxl: 50 }}
                    icon={<UserOutlined />}
                />
            </Col>
            <Col span={16} >
                <h6>{roomName}</h6>
            </Col>
            <Col span={2} >
                <Badge count={count} className='mr-0' />
            </Col>
            {/* <div style={{ border: 'solid 1px', width: '100%', margin: '0', height: '8vh' }}>
                <div style={{ border: 'solid 1px' }}>

                </div>
                <div className='d-flex justify-content-end mt-0 pt-0'>
                </div>
            </div> */}
        </Row>
    )
}

export default Room;