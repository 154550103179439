import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import '../UOV-0/Operacion.css';
import '../UOV-0/Resultados.css';
import { __SERVER__ } from '../../../../server/info';
import { Variables } from '../../../../variables/Variables';
import { dateToString2 } from '../../../../utilidades/dates';
import readXlsxFile from 'read-excel-file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Card, Button, Modal, Alert, Form, ListGroup, InputGroup, } from 'react-bootstrap';
import axios from 'axios';

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: 'cercadoDDV',
            toggleOptions: ['cercadoDDV', 'establecimientosDDV'],
            loading: false,
            loadingSave: false,
            sheets: [],
            modalExcel: false,
            modalViewInvetoryData: false,
            modalEstablishments: false,
            excel_rows: [],
            excel_valid: null,
            excel: null,
            loadingSaveExcel: false,
            index: -1,
            refresh: false,
            buttonSave: false,
            inventory: [],
            inventory_payload: {
                creation_date: '',
                code: '',
                body: '',
                inventory: '',
                version: '',
                labelExtra: []
            },
            inventory_data: [],
            tableExcel: React.createRef(),
            imageInventory: React.createRef(),
            refExtra: React.createRef(),
            establishments_query: {
                registration_dateS: '',
                registration_dateE: '',
            },
            establishments_payload: {
                registration_date: '',
                number: '',
                body: '',
                stringing: '',
                description: '',
                file_name: ''
            },
            establishments_data: [],
            image: null,
            modalInventory: false
        }
    }

    componentDidMount = () => {
        this.getInventory();
    }

    getInventory = () => {
        fetch(__SERVER__ + '/projects/' + Variables.project_id + '/right_way_inventory', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(inventory => {
            this.setState({ inventory: inventory.reverse() });
        }).catch(e => {
            console.log(e);
            this.setState({ inventory: [] });
        })
    }

    saveInventory = () => {
        if (this.state.buttonSave) {
            return;
        }
        this.setState({ buttonSave: true });

        let labelExtra = JSON.stringify(this.state.inventory_payload.labelExtra);
        if (this.state.inventory_payload.labelExtra.length === 0) {
            labelExtra = null;
        }
        if (this.state.inventory_payload.creation_date === '' || this.state.inventory_payload.code === '' || this.state.inventory_payload.body === '' || this.state.inventory_payload.inventory === '' || this.state.inventory_payload.version === '') {
            alert('Debe rellenar todos los campos necesarios');
            return;
        }
        fetch(__SERVER__ + '/projects/' + Variables.project_id + '/right_way_inventory', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify({
                creation_date: this.state.inventory_payload.creation_date,
                code: this.state.inventory_payload.code,
                body: this.state.inventory_payload.body,
                inventory: this.state.inventory_payload.inventory,
                version: this.state.inventory_payload.version,
                labelExtra
            })
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                this.state.inventory_payload.id = res;
                this.state.inventory_payload.labelExtra = this.state.inventory_payload.labelExtra.length === 0 ? null : JSON.stringify(this.state.inventory_payload.labelExtra);
                this.state.inventory.unshift(this.state.inventory_payload);
                this.setState({
                    inventory_payload: {
                        creation_date: '',
                        code: '',
                        body: '',
                        inventory: '',
                        version: '',
                        labelExtra: []
                    }, buttonSave: false, modalInventory: false
                });
            } else {
                this.setState({ refresh: !this.state.refresh, buttonSave: false });
            }
        }).catch(e => {
            console.log(e);
            this.setState({ refresh: !this.state.refresh, buttonSave: false });
        })
    }

    getInformatioFromExcel = (index) => {
        if (this.state.loading) {
            return;
        }
        this.setState({ loading: true });
        if (this.state.excel !== null) {
            readXlsxFile(this.state.excel, { sheet: index })
                .then((rows) => {
                    if (rows.length > 1) {
                        let esperado = "PUNTO | UTM | UTM | LAT | LOG | CADENAMIENTO | FOTO | ELEMENTO | TIPO | UBICACIÓN | CONDICIÓN ";
                        if (this.state.inventory[this.state.index].labelExtra !== null) {
                            let extras = JSON.parse(this.state.inventory[this.state.index].labelExtra);
                            for (let i = 0; i < extras.length; i++) {
                                esperado += "| " + extras[i].toUpperCase() + " ";
                            }
                        }
                        esperado = esperado.trim();
                        let lo_que_hay = "";
                        for (let index = 0; index < rows[0].length; index++) {
                            lo_que_hay += rows[0][index].toUpperCase() + ((rows[0].length === (index + 1)) ? "" : " | ");
                        }
                        lo_que_hay = lo_que_hay.trim();
                        if (lo_que_hay === esperado) {
                            this.setState({ excel_valid: true, excel_rows: rows });
                        } else {
                            this.setState({ excel_valid: false, excel_rows: [] });
                        }
                    } else {
                        this.setState({ excel_valid: false, excel_rows: [] });
                    }
                    console.log(rows)
                    this.setState({ loading: false });
                }).catch(e => {
                    console.log("Error", e);
                    this.setState({ loading: false, excel_rows: [], excel_valid: false });
                })
        }
    }

    getSheet = () => {
        if (this.state.excel !== null) {
            readXlsxFile(this.state.excel, { getSheets: true })
                .then((sheets) => {
                    this.setState({ sheets });
                }).catch(e => {
                    console.log("Error", e);
                    this.setState({ sheets: [] });
                })
        }
    }

    moveXP = () => {
        if (this.state.tableExcel.current.scrollLeft < this.state.tableExcel.current.clientWidth) {
            this.state.tableExcel.current.scrollLeft += 100;
            if (this.state.tableExcel.current.scrollLeft > this.state.tableExcel.current.clientWidth) {
                this.state.tableExcel.current.scrollLeft = this.state.tableExcel.current.clientWidth;
            }
        }
        this.setState({ refresh: !this.state.refresh });
    }

    moveXN = () => {
        if (this.state.tableExcel.current.scrollLeft > 0) {
            this.state.tableExcel.current.scrollLeft -= 100;
            if (this.state.tableExcel.current.scrollLeft < 0) {
                this.state.tableExcel.current.scrollLeft = 0;
            }
        }
        this.setState({ refresh: !this.state.refresh });
    }

    moveYP = () => {
        if (this.state.tableExcel.current.scrollTop < this.state.tableExcel.current.clientHeight) {
            this.state.tableExcel.current.scrollTop += 100;
            if (this.state.tableExcel.current.scrollTop > this.state.tableExcel.current.clientHeight) {
                this.state.tableExcel.current.scrollTop = this.state.tableExcel.current.clientHeight;
            }
        }
        console.log(this.state.tableExcel)
        this.setState({ refresh: !this.state.refresh });
    }

    moveYN = () => {
        if (this.state.tableExcel.current.scrollTop > 0) {
            this.state.tableExcel.current.scrollTop -= 100;
            if (this.state.tableExcel.current.scrollTop < 0) {
                this.state.tableExcel.current.scrollTop = 0;
            }
        }
        this.setState({ refresh: !this.state.refresh });
    }

    saveOnlineExcel = () => {
        if (this.state.loadingSaveExcel) {
            alert("Existe un proceso en ejecución, espere a que termine para continuar");
            return;
        }
        //this.state.inventory[this.state.index].labelExtra !== null?
        let array = [];
        for (let i = 1; i < this.state.excel_rows.length; i++) {
            let extras = [];
            if (this.state.excel_rows[0].length > 11) {
                for (let index = 11; index < this.state.excel_rows[i].length; index++) {
                    extras.push(this.state.excel_rows[i][index]);
                }
                extras = JSON.stringify(extras);
            } else {
                extras = null;
            }

            array.push({
                point: this.state.excel_rows[i][0],
                utm_start: this.state.excel_rows[i][1],
                utm_end: this.state.excel_rows[i][2],
                latitude: this.state.excel_rows[i][3],
                longitude: this.state.excel_rows[i][4],
                stringing: this.state.excel_rows[i][5],
                file_name: this.state.excel_rows[i][6],
                element: this.state.excel_rows[i][7],
                type: this.state.excel_rows[i][8],
                location: this.state.excel_rows[i][9],
                conditions: this.state.excel_rows[i][10],
                extras
            });
        }
        this.setState({ loadingSaveExcel: true });
        fetch(__SERVER__ + '/projects/' + Variables.project_id + '/right_way_inventory/' + this.state.inventory[this.state.index].id + '/data_array', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(array)
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return null;
        }).then(res => {
            if (res !== null) {
                console.log(res);
                alert('Datos guardados correctamente');
            } else {
                alert('Ocurrio un error al guardar los datos');
            }
            this.setState({ loadingSaveExcel: false });
        }).catch(e => {
            console.log(e);
            alert('Ocurrio un error al guardar los datos');
            this.setState({ loadingSaveExcel: false });
        })
    }

    getInventoryData = () => {
        fetch(__SERVER__ + '/projects/' + Variables.project_id + '/right_way_inventory/' + this.state.inventory[this.state.index].id + '/data', {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(inventory_data => {
            this.setState({ inventory_data });
        }).catch(e => {
            console.log(e);
            this.setState({ inventory_data: [] });
        })
    }

    searchEstablishments = () => {
        let query = "";
        let keys = Object.keys(this.state.establishments_query);
        let values = Object.values(this.state.establishments_query);
        for (let index = 0; index < keys.length; index++) {
            if (values[index] !== '') {
                query = (query === "" ? '?' : '&') + keys[index] + '=' + values[index];
            }
        }

        fetch(__SERVER__ + '/projects/' + Variables.project_id + '/right_way_establishments_settlements' + query, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            return [];
        }).then(establishments_data => {
            this.setState({ establishments_data });
        }).catch(e => {
            this.setState({ establishments_data: [] });
            console.log(e);
        });
    }

    saveOneEstablishmentImage = async (id) => {
        let data = new FormData();
        data.append('file', this.state.image);

        return new Promise((resolve, eject) =>
            fetch(__SERVER__ + '/projects/' + Variables.project_id + '/right_way_establishments_settlements/' + id + '/upload', {
                method: 'POST',
                body: data
            }).then(res => {
                if (res.status === 200) {
                    res.json().then(result => {
                        console.log(result);
                        resolve(result);
                    });
                } else {
                    console.log(res.statusText)
                    eject(res.statusText);
                }
            }).catch(e => {
                console.log(e)
                eject(e);
            })
        );


    }


    saveOneEstablishment = () => {
        let values = Object.values(this.state.establishments_payload);
        let keys = Object.keys(this.state.establishments_payload);
        let data = {};
        for (let i = 0; i < keys.length; i++) {
            if (values[i] === '') {
                data[keys[i]] = null;
            } else {
                data[keys[i]] = values[i];
            }
        }
        let method = 'POST';
        let url = __SERVER__ + '/projects/' + Variables.project_id + '/right_way_establishments_settlements';
        let __ID__ = data.id;
        if (this.state.establishments_payload.id !== undefined) {
            method = 'PUT';
            url = __SERVER__ + '/projects/' + Variables.project_id + '/right_way_establishments_settlements/' + this.state.establishments_payload.id;
            delete data.id;
            delete data.project_id;
            delete data.registration_date;

        }
        fetch(url, {
            method,
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                res.json().then(async id => {
                    if (this.state.image !== null) {
                        if(this.state.image.name !== undefined){
                            //File
                            id = method === 'PUT' ? __ID__ : id;
                            await this.saveOneEstablishmentImage(id);
                        }
                        
                    }
                    this.searchEstablishments();
                    this.setState({
                        establishments_payload: {
                            registration_date: '',
                            number: '',
                            body: '',
                            stringing: '',
                            description: '',
                            file_name: ''
                        }, modalEstablishments: false, image: null
                    });
                });
            } else {
                throw res.statusText;
            }
        }).catch(e => {
            console.log(e);
        })
    }

    getPhoto = () => {
        if (this.state.establishments_payload.file_name !== null) {
            let url = __SERVER__ + '/projects/' + Variables.project_id + '/right_way_establishments_settlements/' + this.state.establishments_payload.id + '/download?path=' + this.state.establishments_payload.file_name;
            axios(url, {
                method: "GET",
                responseType: "blob"
            }).then(response => {
                let image = new Blob([response.data], {
                    type: response.data.type
                });
                this.setState({ image });
                this.setState({ refresh: !this.state.refresh });
            }).catch(error => {
                console.log(error);
            });
        }
    }

    render = () => {
        return (
            <section>
                <section className="sectionSerach toggles">
                    <article>
                        <input type="button" value="Cercado de DDV" className={this.state.toggle === this.state.toggleOptions[0] ? "active" : ""} onClick={() => this.setState({ toggle: this.state.toggleOptions[0] })} />
                    </article>
                    <article>
                        <input type="button" value="Establecimientos y asentamientos" className={this.state.toggle === this.state.toggleOptions[1] ? "active" : ""} onClick={() => this.setState({ toggle: this.state.toggleOptions[1] })} />
                    </article>
                </section>
                {this.state.toggle === this.state.toggleOptions[0] ?
                    <section className="cardsInventories">
                        {
                            this.state.inventory.map((item, i) =>
                                <Card style={{ width: '15rem' }} key={'asfas' + i}>
                                    <Card.Body>
                                        <Card.Subtitle>{dateToString2(item.creation_date)}</Card.Subtitle>
                                        <Card.Title>{item.inventory}</Card.Title>
                                        <Card.Text>
                                            <strong>Código: </strong>{item.code}<br />
                                            <strong>Cuerpo: </strong>{item.body}<br />
                                            <strong>Versión: </strong>{item.version}<br />
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant={'link'} onClick={() => this.setState({ modalExcel: true, index: i })}>Cargar un archivo</Button>
                                        <Button variant={'link'} onClick={() => this.setState({ modalViewInvetoryData: true, inventory_data: [], index: i }, this.getInventoryData)}>Ver inventario</Button>
                                    </Card.Footer>
                                </Card>
                            )
                        }
                    </section>
                    : null}

                {this.state.toggle === this.state.toggleOptions[1] ?
                    <section className="sectionSearch">
                        <article>
                            <label>Fecha</label>
                            <input type='date' value={this.state.establishments_query.registration_dateS} onChange={(e) => { this.state.establishments_query.registration_dateS = e.target.value; this.setState({ refresh: !this.state.refresh }) }} />
                        </article>
                        <article>
                            <label>Fecha</label>
                            <input type='date' value={this.state.establishments_query.registration_dateE} onChange={(e) => { this.state.establishments_query.registration_dateE = e.target.value; this.setState({ refresh: !this.state.refresh }) }} />
                        </article>

                        <article>
                            <button className="botonBusqueda" onClick={this.searchEstablishments}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                            </button>
                        </article>
                    </section>
                    : null}

                {this.state.toggle === this.state.toggleOptions[1] ?
                    <table>
                        {
                            this.state.establishments_data.length !== 0 ?
                                <tr>
                                    <th>No.</th>
                                    <th>Cuerpo</th>
                                    <th>Cadenamiento</th>
                                    <th>Descripción</th>
                                    <th></th>
                                </tr>
                                : null
                        }
                        {this.state.establishments_data.map((item, i) =>
                            <tr key={"establishments_" + i}>
                                <td>{item.number}</td>
                                <td>{item.body}</td>
                                <td>{item.stringing}</td>
                                <td>{item.description}</td>
                                <td><Button onClick={() => { item.registration_date = item.registration_date.split("T")[0]; this.setState({ modalEstablishments: true, establishments_payload: item }, this.getPhoto); }} variant="dark">Ver</Button></td>
                            </tr>
                        )}
                    </table>
                    : null
                }

                {this.state.toggle === this.state.toggleOptions[0] ?
                    <div className="floatAdd" onClick={() => this.setState({ modalInventory: true })}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                    : null}

                {this.state.toggle === this.state.toggleOptions[1] ?
                    <div className="floatAdd" onClick={() => this.setState({ modalEstablishments: true })}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                    : null}
                <Modal show={this.state.modalInventory} backdrop="static" keyboard={false} onHide={() => this.setState({
                    modalInventory: false, inventory_payload: {
                        creation_date: '',
                        code: '',
                        body: '',
                        inventory: '',
                        version: '',
                        labelExtra: []
                    }
                })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Inventario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Fecha *</Form.Label>
                                <Form.Control type="date" placeholder="Fecha" value={this.state.inventory_payload.creation_date} onChange={(e) => { this.state.inventory_payload.creation_date = e.target.value; this.setState({ refresh: !this.state.refresh }) }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Código *</Form.Label>
                                <Form.Control placeholder="Código" value={this.state.inventory_payload.code} onChange={(e) => { this.state.inventory_payload.code = e.target.value; this.setState({ refresh: !this.state.refresh }) }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Cuerpo *</Form.Label>
                                <Form.Control placeholder="Cuerpo" value={this.state.inventory_payload.body} onChange={(e) => { this.state.inventory_payload.body = e.target.value; this.setState({ refresh: !this.state.refresh }) }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Inventario *</Form.Label>
                                <Form.Control placeholder="Inventario" value={this.state.inventory_payload.inventory} onChange={(e) => { this.state.inventory_payload.inventory = e.target.value; this.setState({ refresh: !this.state.refresh }) }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Versión *</Form.Label>
                                <Form.Control placeholder="Versión" value={this.state.inventory_payload.version} onChange={(e) => { this.state.inventory_payload.version = e.target.value; this.setState({ refresh: !this.state.refresh }) }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Las cabeceras del inventario son</Form.Label>
                                <ListGroup>
                                    <ListGroup.Item>PUNTO</ListGroup.Item>
                                    <ListGroup.Item>UTM</ListGroup.Item>
                                    <ListGroup.Item>UTM</ListGroup.Item>
                                    <ListGroup.Item>LAT</ListGroup.Item>
                                    <ListGroup.Item>LOG</ListGroup.Item>
                                    <ListGroup.Item>CADENAMIENTO</ListGroup.Item>
                                    <ListGroup.Item>FOTO</ListGroup.Item>
                                    <ListGroup.Item>ELEMENTO</ListGroup.Item>
                                    <ListGroup.Item>TIPO</ListGroup.Item>
                                    <ListGroup.Item>UBICACIÓN</ListGroup.Item>
                                    <ListGroup.Item>CONDICIÓN</ListGroup.Item>
                                    {
                                        this.state.inventory_payload.labelExtra.map((item, i) =>
                                            <ListGroup.Item key={"a" + i}>
                                                <FontAwesomeIcon style={{cursor: 'pointer', marginRight: 10}} icon={faTimesCircle} color={'rgba(8, 193, 231, 0.7)'} size={30} onClick={() => {this.state.inventory_payload.labelExtra.splice(i, 1); this.setState({refresh: !this.state.refresh});}} />
                                                {item}
                                            </ListGroup.Item>
                                        )
                                    }
                                </ListGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Extras</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control placeholder="Extra" ref={this.state.refExtra} onKeyDown={(e) => {
                                            if(e.key === "Enter"){
                                                this.state.inventory_payload.labelExtra.push(this.state.refExtra.current.value.toUpperCase()); this.state.refExtra.current.value = ''; this.setState({ refresh: !this.state.refresh });
                                            }
                                        }}/>
                                    <InputGroup.Append>
                                        <Button  onClick={() => { this.state.inventory_payload.labelExtra.push(this.state.refExtra.current.value.toUpperCase()); this.state.refExtra.current.value = ''; this.setState({ refresh: !this.state.refresh }) }} variant={"primary"}>Añadir</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Label>* (Obligatorio)</Form.Label>
                            <Button disabled={this.state.buttonSave} onClick={this.saveInventory} variant="primary">Guardar</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalEstablishments} backdrop="static" keyboard={false} onHide={() => this.setState({
                    modalEstablishments: false, image: null, establishments_payload: {
                        registration_date: '',
                        number: '',
                        body: '',
                        stringing: '',
                        description: '',
                        file_name: ''
                    },
                })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Establecimiento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Fecha de registro</Form.Label>
                                <Form.Control value={this.state.establishments_payload.registration_date} type="date" onChange={e => { this.state.establishments_payload.registration_date = e.target.value; this.setState({ refresh: !this.state.refresh }); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Número</Form.Label>
                                <Form.Control value={this.state.establishments_payload.number} onChange={e => { this.state.establishments_payload.number = e.target.value; this.setState({ refresh: !this.state.refresh }); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Cuerpo</Form.Label>
                                <Form.Control value={this.state.establishments_payload.body} onChange={e => { this.state.establishments_payload.body = e.target.value; this.setState({ refresh: !this.state.refresh }); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Cadenamiento</Form.Label>
                                <Form.Control value={this.state.establishments_payload.stringing} onChange={e => { this.state.establishments_payload.stringing = e.target.value; this.setState({ refresh: !this.state.refresh }); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control value={this.state.establishments_payload.description} onChange={e => { this.state.establishments_payload.description = e.target.value; this.setState({ refresh: !this.state.refresh }); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Evidencia fotográfica</Form.Label>
                                <input className="buttonFileModal" id="buttonFileModal" type="file" accept="image/*" onChange={e => { if (e.target.files.length !== 0) { this.setState({ image: e.target.files[0] }) } }} />
                                <label className="buttonFileModal2" for="buttonFileModal">
                                    Upload
                                </label>
                                {
                                    this.state.image !== null ?
                                        <img ref={this.state.imageInventory} className="imageInventory" src={URL.createObjectURL(this.state.image)} />
                                        : null
                                }
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.saveOneEstablishment}>Guardar</Button>
                    </Modal.Footer>
                </Modal>

                

                <Modal size={'xl'} show={this.state.modalExcel} backdrop="static" keyboard={false} onHide={() => this.setState({ modalExcel: false, })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Carga de archivo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label><strong>NOTA: </strong> Los encabezados deben seguir el siguiente orden</label><br />
                        <div className="modalCabeceras">
                            <center>
                                <label>PUNTO | UTM | UTM | LAT | LOG | CADENAMIENTO | FOTO | ELEMENTO | TIPO | UBICACIÓN | CONDICIÓN </label>
                                {
                                    this.state.index !== -1 ?
                                        this.state.inventory[this.state.index].labelExtra !== null ?
                                            JSON.parse(this.state.inventory[this.state.index].labelExtra).map((item, i) =>
                                                <label key={"a" + i}>| {item.toUpperCase()} </label>
                                            )
                                            : null
                                        : null
                                }
                            </center>
                        </div>
                        <br />
                        <div className="contentFileModal">
                            <input className="buttonFileModal" id="buttonFileModal" type="file" accept=".xlsx, .xls" onChange={e => { if (e.target.files.length !== 0) { this.setState({ excel: e.target.files[0] }, this.getSheet) } }} />
                            <label className="buttonFileModal2" for="buttonFileModal">
                                Upload
                                <span for="buttonFileModal">{this.state.excel !== null ? this.state.excel.name : null}</span>
                            </label>
                            {
                                this.state.excel !== null ?
                                    <div className="sheets">
                                        {
                                            this.state.sheets.map((item, i) =>
                                                <div className="sheet" key={"ad" + i} >
                                                    <label>{item.name}</label>
                                                    <Button disabled={this.state.loading} onClick={() => this.getInformatioFromExcel(i + 1)}>{this.state.loading ? 'Generando...' : 'Obtener información'}</Button>                                                    
                                                </div>
                                            )
                                        }

                                    </div>
                                    : null
                            }
                        </div>
                        {this.state.excel_valid !== null ? this.state.excel_valid ? <div className="saveOnlineExcel"><button onClick={this.saveOnlineExcel}>Guardar</button></div> : null : null}
                        {
                            this.state.excel_valid ?
                                <div className="designTableExcel">
                                    {this.state.excel_rows.length !== 0 ? <button className="moveTable-left" onClick={this.moveXN}><FontAwesomeIcon icon={faAngleLeft} /></button> : null}
                                    {this.state.excel_rows.length !== 0 ? <button className="moveTable-right" onClick={this.moveXP}><FontAwesomeIcon icon={faAngleRight} /></button> : null}
                                    <div className="tableExcel" ref={this.state.tableExcel}>
                                        <table>
                                            {
                                                this.state.excel_rows.map((row, i) =>
                                                    <tr className="rowExcel" key={"ROW" + i}>
                                                        {
                                                            row.map((cell, j) =>
                                                                i === 0 ?
                                                                    <th key={"VALUE_" + i + "_" + j}>
                                                                        {cell !== null ? cell : '-'}
                                                                    </th>
                                                                    :
                                                                    <td key={"VALUE_" + i + "_" + j}>
                                                                        {cell !== null ? cell : '-'}
                                                                    </td>
                                                            )
                                                        }
                                                    </tr>
                                                )
                                            }
                                        </table>
                                    </div>
                                </div>
                                : <div>
                                    {this.state.excel_valid !== null ? <Alert dismissible variant="danger" onClose={() => this.setState({ excel_valid: null })}>Las cabeceras no son válidas</Alert> : null}
                                </div>
                        }
                    </Modal.Body>
                </Modal>



                <Modal size={'xl'} show={this.state.modalViewInvetoryData} backdrop="static" keyboard={false} onHide={() => this.setState({ modalViewInvetoryData: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Inventario</Modal.Title>
                    </Modal.Header>
                    {
                        this.state.inventory_data.length !== 0 ?
                            <Modal.Body>
                                <div className="designTableExcel">
                                    {this.state.inventory_data.length !== 0 ? <button className="moveTable-left" onClick={this.moveXN}><FontAwesomeIcon icon={faAngleLeft} /></button> : null}
                                    {this.state.inventory_data.length !== 0 ? <button className="moveTable-right" onClick={this.moveXP}><FontAwesomeIcon icon={faAngleRight} /></button> : null}
                                    <div className="tableExcel" ref={this.state.tableExcel}>
                                        <table>
                                            <tr>
                                                <th>PUNTO</th>
                                                <th>UTM</th>
                                                <th>UTM</th>
                                                <th>LAT</th>
                                                <th>LOG</th>
                                                <th>CADENAMIENTO</th>
                                                <th>FOTO</th>
                                                <th>ELEMENTO</th>
                                                <th>TIPO</th>
                                                <th>UBICACIÓN</th>
                                                <th>CONDICIÓN</th>
                                                {
                                                    this.state.inventory[this.state.index].labelExtra !== null ?
                                                        JSON.parse(this.state.inventory[this.state.index].labelExtra).map((item, i) =>
                                                            <th key={"a" + i}>{item.toUpperCase()} </th>
                                                        )
                                                        : null
                                                }
                                            </tr>
                                            {
                                                this.state.inventory_data.map((item, i) =>
                                                    <tr className="rowExcel" key={"ROW" + i}>
                                                        <td>{item.point}</td>
                                                        <td>{item.utm_start}</td>
                                                        <td>{item.utm_end}</td>
                                                        <td>{item.latitude}</td>
                                                        <td>{item.longitude}</td>
                                                        <td>{item.stringing}</td>
                                                        <td>{item.file_name}</td>
                                                        <td>{item.element}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.location}</td>
                                                        <td>{item.conditions}</td>
                                                        {
                                                            item.extras !== null ?
                                                                JSON.parse(item.extras).map((it, j) =>
                                                                    <td key={"das" + i + j}>{it}</td>
                                                                )
                                                                : null
                                                        }
                                                    </tr>
                                                )
                                            }
                                        </table>
                                    </div>
                                </div>
                            </Modal.Body>
                            : <Modal.Body>
                                <Alert show={true} variant={"primary"}>
                                    <Alert.Heading>Cargando...</Alert.Heading>
                                </Alert>
                            </Modal.Body>
                    }
                </Modal>
            </section>
        );
    }
}