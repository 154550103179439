import AID from './Proyectos/UOV-0/UOV-01';
import AICP from './Proyectos/UOV-0/UOV-02';
import NSPC from './Proyectos/UOV-0/UOV-03';
import PDRC from './Proyectos/UOV-0/UOV-04';
import PDRCDC from './Proyectos/UOV-0/UOV-05';
import VCCT from './Proyectos/UOV-1/UOV-11';
import TAG from './Proyectos/UOV-1/UOV-12';
import USERSUPPORT from './Proyectos/UOV-1/UOV-17';
import QR from './Proyectos/UOV-1/QR';
import EMERGENCY from './Proyectos/UOV-2/UOV-21';
import PATROL from './Proyectos/UOV-2/UOV-22';
import QUEJA from './Proyectos/UOV-3/UOV-31';
import SOS from './Proyectos/UOV-3/UOV-32';
import REQUESTSATTENTION from './Proyectos/UOV-4/UOV-41';       //[Derecho de vía] Atención de solicitudes
import PAYMENTS from './Proyectos/UOV-4/UOV-42';                //[Derecho de vía] Cobro y conciliación de ingresos
import MEBI from './Proyectos/UOV-5/UOV-51';                    //[OBAC] Infraestructura, Ofimática, Telepeaje y Edificación
import OBAC2 from './Proyectos/UOV-5/UOV-52';                   // OBAC ITS (Postes SOS)
import OBAC3 from './Proyectos/UOV-5/UOV-53';                   // OBAC Medio ambiente
import OBAC4 from './Proyectos/UOV-5/UOV-54';                   // OBAC Edificación
import OBAC1 from './Proyectos/UOV-5/UOV-55';                   // OBAC Edificación
import AUX from './Proyectos/UOV-3/UOV-34';                
import BILLING from './Proyectos/ElectronicBilling/Billing';
import UOV71 from './Proyectos/UOV-7/UOV-71';                   // Aforos, Ingresos y depósitos de ingresos por servicios prestados y/o multas en la gestión de pesos y dimensiones
import UOV72 from './Proyectos/UOV-7/UOV-72';                   // Nivel de servicio en las básculas
import UOV43 from './Proyectos/UOV-4/UOV-43';                   // Asentamientos y accesos irregulares - no permitir nuevos

import React from 'react';

export const OperacionProyectos = (id, code, name, permission) => {
    let op = [
        {
            name: "UOV-0.1",
            component: <AID project_id={id} code={code} name={name} />
        }, {
            name: "UOV-0.2",
            component: <AICP project_id={id} code={code} name={name} />
        }, {
            name: "UOV-0.3",
            component: <NSPC project_id={id} code={code} name={name} />
        }, {
            name: "UOV-0.4",
            component: <PDRC project_id={id} code={code} name={name} />
        }, {
            name: "UOV-0.5",
            component: <PDRCDC project_id={id} code={code} name={name} />
        }, {
            name: "UOV-1.1",
            component: <VCCT project_id={id} code={code} name={name} />
        }, {
            name: "UOV-1.2",
            component: <TAG project_id={id} code={code} name={name} />
        }, {
            name: "UOV-1.7",
            component: <USERSUPPORT project_id={id} code={code} name={name} />
        }, {
            name: "QR",
            component: <QR project_id={id} code={code} name={name} />
        }, {
            name: "UOV-2.1",
            component: <EMERGENCY project_id={id} code={code} name={name} />
        }, {
            name: "UOV-2.2",
            component: <PATROL project_id={id} code={code} name={name} />
        }, {
            name: "UOV-3.2",
            component: <SOS project_id={id} code={code} name={name} />
        }, {
            name: "UOV-3.1",
            component: <QUEJA project_id={id} code={code} name={name} />
        }, {
            name: "UOV-3.4",
            component: <AUX project_id={id} code={code} name={name} />
        }, {
            name: "UOV-4.1",
            component: <REQUESTSATTENTION project_id={id} code={code} name={name} />
        }, {
            name: "UOV-4.2",
            component: <PAYMENTS project_id={id} code={code} name={name} />
        }, {
            name: "UOV-4.3",
            component: <UOV43 project_id={id} code={code} name={name} />
        }, {
            name: "UOV-5.1",
            component: <MEBI project_id={id} code={code} name={name} />
        }, {
            name: "UOV-5.1",
            component: <MEBI project_id={id} code={code} name={name} />
        }, {
            name: "UOV-5.2",
            component: <OBAC2 project_id={id} code={code} name={name} permission={permission}/>
        }, {
            name: "UOV-5.3",
            component: <OBAC3 project_id={id} code={code} name={name} permission={permission}/>
        }, {
            name: "UOV-5.4",
            component: <OBAC4 project_id={id} code={code} name={name} permission={permission}/>
        }, {
            name: "UOV-5.5",
            component: <OBAC1 project_id={id} code={code} name={name} permission={permission}/>
        },{
            name: "UOV-6.1",
            component: <BILLING project_id={id} code={code} name={name} />
        },{
            name: "UOV-7.1",
            component: <UOV71 project_id={id} code={code} name={name} />
        },{
            name: "UOV-7.2",
            component: <UOV72 project_id={id} code={code} name={name} />
        }
    ];
    return op;
}