import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import store from './store/configureStore';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from "@apollo/client/link/ws";
import 'handsontable/dist/handsontable.full.min.css';

const {
    REACT_APP_PROTOCOL_HTTP: P_HTTP,
    REACT_APP_PROTOCOL_WS: P_WS,
    REACT_APP_URI: URI,
    REACT_APP_HASURA_SECRET: TOKEN_HASURA
} = process.env;

// Add an authorization header to every HTTP request by chaining together Apollo Links

const httpLink = createHttpLink({
    uri: P_HTTP + URI
});

const wsLink = (token) => {
    return new WebSocketLink({
        uri: P_WS + URI,
        options: {
            reconnect: true,
            connectionParams: () => {
                return { headers: { "x-hasura-admin-secret": token } };
            },

        },
        // webSocketImpl
    });
};

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = TOKEN_HASURA;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            "x-hasura-admin-secret": token
        }
    }
});

// The split function takes three parameters:
//
// -- A function that's called for each operation to execute
// -- The Link to use for an operation if the function returns a "truthy" value
// -- The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink(TOKEN_HASURA),
    authLink.concat(httpLink),
);

// Definicón de cliente APOLLO
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: splitLink,
});

function Index() {
    return (
        <ChakraProvider>
            <Provider store={store}>
                <ApolloProvider client={client}>
                    <App />
                </ApolloProvider>
            </Provider>
        </ChakraProvider>
    );
}

ReactDOM.render(
    <Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
