import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexAssetsBaseLines = async ({ package_id }) => {
    const url = `/packages/${package_id}/assets_base_lines`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: assets_base_lines.js:14 ~ indexAssetsBaseLines ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeBaseLines = async ({ package_id, data }) => {
    const url = `/packages/${package_id}/assets_base_lines`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: assets_base_lines.js:32 ~ storeBaseLines ~ fetch", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const updateBaseLines = async ({ package_id, data, id }) => {
    const url = `/packages/${package_id}/assets_base_lines/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: assets_base_lines.js:50 ~ updateBaseLines ~ fetch", fetch)
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}