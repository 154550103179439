import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexObacIncidents = async (props) => {
    const {
        project_id,             // Identificador de la autopista
    } = props;
    // Obtener información básica
    let params = props;
    delete params.project_id;

    const url = `/projects/${project_id}/obac_incidents`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: incidents.js ~ line 27 ~ indexIncidents ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            const { data, totalItems } = fetch.data;
            response = { data, totalItems };
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const showObacIncidents = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/obac_incidents/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: incidents.js:41 ~ showObacIncidents ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeObacIncidents = async ({ project_id, data }) => {
    const url = `/projects/${project_id}/obac_incidents`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: maintenance_program.js ~ line 32 ~ storeObacIncidents ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const updateObacIncidents = async ({ project_id, data, id }) => {
    const url = `/projects/${project_id}/obac_incidents/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: incidents.js:78 ~ updateObacIncidents ~ fetch:", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}