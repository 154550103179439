import React, { useEffect, useState } from 'react';
import TableComp from 'components/layouts/Table';
import { faFileDownload, faFilePdf, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PDFViewerComp from 'components/layouts/PDFViewer';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { downloadIncidentFiles } from 'api/operation_standards/incidents_files';

const IncidentFiles = (props) => {
    const { project_id, data, loading, deleteFile } = props;

    const [preview, setPreview] = useState(false);
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState(null);

    useEffect(() => {
        setFiles(data.filter((item) => item.file_type === 'document'));
    }, []);

    const getFile = async (item) => {
        const { file_path, obac_incident_id } = item;
        return await downloadIncidentFiles({ project_id, obac_incident_id, file_path });
    }
    const handleClick = async (item) => {
        const { file_path } = item;
        const { response } = await getFile(item);
        if (response) {
            const fileURL = URL.createObjectURL(response);
            const link = document.createElement('a')
            link.href = fileURL
            link.setAttribute('download', file_path)
            document.body.appendChild(link)
            link.click();
        }
    }

    const viewPDF = async (item) => {
        setPreview(true);
        const { response } = await getFile(item);
        if (response) setFile(response);
    }

    return (
        <>
            {
                preview ? <>
                    <ButtonIcon name='Cerrar vista previa' icon={faTimes} onClick={() => setPreview(false)} variant='link' />
                    <PDFViewerComp file={file} />
                </> : <div className="body_">
                    <TableComp
                        headers={['#', 'Archivo', 'Tipo', 'Acciones']}
                        keys={['#', 'file_path', 'file_type']}
                        body={files}
                        loading={loading}
                        actions={[
                            { icon: faFileDownload, tooltip: 'Descargar archivo', variant: 'outline-primary', handleClick },
                            { icon: faFilePdf, tooltip: 'Vista previa', variant: 'outline-success', handleClick: viewPDF },
                            { icon: faTrashAlt, tooltip: 'Eliminar archivo', variant: 'outline-danger', handleClick: deleteFile },
                        ]}
                    />
                </div>
            }
        </>
    )
}

export default IncidentFiles