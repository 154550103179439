import React from 'react'
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import stylesHeader from './styles_header';
import stylesTable from './styles_table';
import victum_logo from '../../../../imagenes-app/logo.png'
import moment from 'moment';
import _ from 'underscore';

class PDFReport extends React.Component {
    constructor(props) {
        super(props)
    }
    getOperator = (operator_id) => {
        let { operators } = this.props;
        let name = 'Sin asignar';
        let position = _.findIndex(operators, { id: operator_id });
        if (position !== -1) {
            name = `${operators[position].first_name} ${operators[position].last_name}`
        }
        return name;
    }

    getNoticeForm = (id) => {
        let name = 'Indefinido', { notice_form } = this.props;
        let position = _.findIndex(notice_form, { id });
        if (position !== -1) name = notice_form[position].name
        return name;
    }

    getServiceDescription = (id) => {
        let name = 'Indefinido', { service_description } = this.props;
        let position = _.findIndex(service_description, { id });
        if (position !== -1) name = service_description[position].name
        return name;
    }
    render = () => {
        let status = ['En espera', 'Atendiendo', 'Resuelto', 'No resuelto', 'FAKE'];
        return (
            <Document>
                <Page style={stylesTable.body} size={"LEGAL"} orientation={"landscape"}>
                    <View style={stylesHeader.header}>
                        <View style={stylesHeader.headerColLeft}>
                            <Image style={stylesHeader.imageSquare} source={victum_logo} />
                        </View>
                        <View style={stylesHeader.headerColCenter}>
                            <Text style={stylesHeader.title}>Bitácora de registro de quejas y/o sugerencias</Text>
                            <Text style={stylesHeader.subtitle}>{this.props.package.name}</Text>
                        </View>
                        <View style={stylesHeader.headerColRight}>
                            <Image style={stylesHeader.imageSquare} source={victum_logo} />
                        </View>
                    </View>

                    <View style={stylesTable.table}>
                        <View style={stylesTable.tableRowCenter}>
                            <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Bitácora</Text>
                            </View>
                        </View>
                        <View style={stylesTable.tableRow}>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Folio</Text>
                            </View>
                            <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Fecha</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Nombre</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Correo</Text>
                            </View>
                            <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Teléfono</Text>
                            </View>
                            <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Tipo</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Área involucrada</Text>
                            </View>
                            {/* <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Turno</Text>
                            </View> */}
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Descripción/hechos</Text>
                            </View>
                            <View style={stylesTable.tableColHeader2}>
                                <Text style={stylesTable.tableCellHeader}>Se notificó a:</Text>
                            </View>
                            <View style={stylesTable.tableColHeader}>
                                <Text style={stylesTable.tableCellHeader}>Estatus</Text>
                            </View>
                        </View>
                        {
                            this.props.data.length > 0 ?
                                this.props.data.map((item, index) =>
                                    <View key={index} style={stylesTable.tableRow}>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{item.code}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol}>
                                            <Text style={stylesTable.tableCell}>{moment(item.report_date).format('L')}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>---</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{item.highway_user_email!== null ? item.highway_user_email: '---'}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol}>
                                            <Text style={stylesTable.tableCell}>{item.highway_user_phone!== null ? item.highway_user_phone: '---'}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol}>
                                            <Text style={stylesTable.tableCell}>{this.getNoticeForm(item.notice_form_id)}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>Auxilio vial</Text>
                                        </View>
                                        {/* <View style={stylesTable.tableCol}>
                                            <Text style={stylesTable.tableCell}>---</Text>
                                        </View> */}
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{this.getServiceDescription(item.service_description_id)}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol2}>
                                            <Text style={stylesTable.tableCell}>{this.getOperator(item.road_aid_operator_id)}</Text>
                                        </View>
                                        <View style={stylesTable.tableCol}>
                                            <Text style={stylesTable.tableCell}>{status[item.status]}</Text>
                                        </View>
                                    </View>
                                ) : <Text style={stylesTable.tableCell}>No hay datos para mostrar</Text>
                        }
                    </View>
                </Page>
            </Document >
        )
    }
}

export default PDFReport