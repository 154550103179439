import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxCDV = () => {
    const table = 'right_way_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 70 },
        { label: 'UTM_START', description: 'UTM Inicial', value: 'utm_start', type: 'String', show: false, edit: true, add: true, width: 20, pdf: false, widthXLSX: 80 },
        { label: 'UTM_END', description: 'UTM final', value: 'utm_end', type: 'String', show: false, edit: true, add: true, width: 20, pdf: false, widthXLSX: 80 },
        { label: 'LONGITUD', description: 'Longitud', value: 'longitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'LATITUD', description: 'Latitud', value: 'latitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'CADENAMIENTO', description: 'Cadenamiento', value: 'stringing', type: 'String', show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'ELEMENTO', description: 'Elemento', value: 'element', type: 'String', show: true, edit: true, add: true, width: 20, pdf: false, widthXLSX: 100 },
        { label: 'TIPO', description: 'Tipo', value: 'type', type: 'String', show: true, edit: true, add: true, width: 20, pdf: false, widthXLSX: 100 },
        { label: 'UBICACIÓN', description: 'Ubicación', value: 'location', type: 'String', show: true, edit: true, add: true, width: 20, pdf: false, widthXLSX: 100 },
        { label: 'CONDICIONES', description: 'Condiciones', value: 'conditions', type: 'String', show: true, edit: true, add: true, width: 20, pdf: false, widthXLSX: 100 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30 },
    ];

    const km = [{ index: 5 }];    // Arreglo de indices que irán con formato 000+000
    const percentage = [];                      // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxCDV;
