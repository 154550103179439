import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const getUrlMonitoringXlsx = async ({ project_id, package_id, start_date, end_date }) => {
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `/assets_its/records/xlsx`,
            method: 'GET',
            params: { package_id, project_id, start_date, end_date }
        });
        // console.log("🚀 ~ file: assets_its_monitoring.js:12 ~ getUrlMonitoringXlsx ~ fetch:", fetch)

        if (fetch.status === httpStatus.ok)
            response = fetch.data;
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}