import React, { useEffect, useState } from 'react';
import { Heading, useDisclosure } from '@chakra-ui/react';
import { Col, Row, Skeleton, Tree } from 'antd';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { allowModule } from 'components/libs/allowModule';
import { faCogs, faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { updateUsers } from 'api/users/users';
import { indexPermissions, storeUserPermissions } from 'api/general/permissions';

const PermissionsComp = (props) => {
    const { userSel, disabledTree, package_id, userForm, setUsers, setUserSel, 
        initialCheckedKeys, checkedKeys, setCheckedKeys, setDisabledTree, loadingUserPermissions } = props;

    const [treeData, setTreeData] = useState([]);                       // Lista de permisos del paquete
    const [halfCheckedKeys, setHalfCheckedKeys] = useState([]);         // Permisos parcialmente seleccionados
    const [expandedKeys, setExpandedKeys] = useState([]);               // Permisos con hijos que están expandidos
    const [btnPermissions, setButtonPermissions] = useState(true);      // Botón para actualizar permisos

    const loadingPermissions = useDisclosure();
    const loadingSave = useDisclosure();

    // Todos los permisos que contiene el paquete
    useEffect(() => {
        const getPermission = async () => {
            loadingPermissions.onOpen();
            const { response } = await indexPermissions({ package_id });
            setTreeData(recursive(response))
            loadingPermissions.onClose();
        }
        getPermission();
    }, []);

    // Expandir por default W y WP
    useEffect(() => {
        if (treeData.length > 0) setExpandedKeys([1075, 1079]);
    }, [treeData]);

     // Efecto para habilitar o deshabilitar boton de permisos de usuario
     useEffect(() => {
        if (initialCheckedKeys.length === checkedKeys.length) {
            setButtonPermissions(true);
        } else {
            setButtonPermissions(false);
        }
    }, [initialCheckedKeys, checkedKeys]);

    const recursive = (array) => {
        let response = [];
        for (const element of array) {
            const { id, description, sub } = element;
            let children = [];
            if (sub) children = recursive(sub);
            response.push({ title: `${description}`, key: id, children });
        }
        return response;
    }

    const onExpand = (expandedKeys) => setExpandedKeys(expandedKeys);

    const onCheck = (checkedKeysValue, e) => {
        setHalfCheckedKeys(e.halfCheckedKeys);
        setCheckedKeys(checkedKeysValue);
    };

    const findKeyInArray = (array, key) => {
        for (const element of array) {
            if (element.key === key) {
                return element; // Código encontrado, se devuelve el elemento completo
            }
            if (element.children) {
                const found = findKeyInArray(element.children, key); // Búsqueda recursiva en los elementos hijos
                if (found) {
                    return found; // Se encontró el código en los hijos, se devuelve el elemento completo
                }
            }
        }
        return null; // No se encontró el código en el arreglo
    };

    const savePermissions = async () => {
        if (!userSel) {
            Swal.fire('¡Seleccione un usuario!', 'Debe seleccionar un usuario para editar los permisos.', 'info');
            return;
        }
        loadingSave.onOpen();
        const data = [...checkedKeys, ...halfCheckedKeys].map((item) => {
            const permission = findKeyInArray(treeData, item);
            return {
                user_id: userSel.id,
                permission_id: permission.key
            }
        })
        const { response } = await storeUserPermissions({ data });
        if (response) {
            Swal.fire('¡Permisos actualizados!', `Los permisos del usuario "${userSel.first_name} ${userSel.last_name}" han sido actualizados.`, 'success');
        } else {
            Swal.fire('¡Operación fallida!', `Los permisos del usuario "${userSel.first_name} ${userSel.last_name}" no se han podido actualizar.`, 'error');
        }
        loadingSave.onClose();
    }

    const customPermissions = async () => {
        const data = {
            last_name: userForm.last_name,
            package_id: userForm.package_id,
            project_id: userForm.project_id,
            road_section_id: userForm.road_section_id,
            custom_permissions: disabledTree ? 1 : 0
        };

        let confirmTitle = '';
        let confirmText = '';
        let confirmButtonText = '';

        if (disabledTree) {
            confirmTitle = '¿Está seguro que desea personalizar los permisos?';
            confirmText = `Actualmente tiene permisos del rol de usuario al que está asignado. 
            Los permisos modificados sólo se guardarán para el usuario actual.`;
            confirmButtonText = 'Si, personalizar!';
        } else {
            confirmTitle = '¿Está seguro que desea restaurar los permisos?';
            confirmText = 'Al realizar esta operación los permisos de usuario se restaurarán.';
            confirmButtonText = 'Si, restaurar!';
        }

        const result = await Swal.fire({
            title: confirmTitle,
            text: confirmText,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: 'No, cancelar'
        });

        if (result.isConfirmed) {
            const { response } = await updateUsers({ package_id, user_id: userSel.id, data });
            if (response) {
                const refreshUsers = prevState => prevState.map((item) => {
                    let custom_permissions = item.custom_permissions;
                    if (item.id === userSel.id) {
                        custom_permissions = data.custom_permissions;
                    }
                    return { ...item, custom_permissions };
                });

                setUserSel(prevState => ({ ...prevState, custom_permissions: data.custom_permissions }));
                setUsers(refreshUsers);
                setDisabledTree(!disabledTree);
            }
        }
    }

    return (
        <Col xs={24} sm={24} md={12} lg={12} className='users-permissions'>
            <Row className='users-permissions-title'>
                <Row className='users-permissions-title-row'>
                    <Col className='users-permissions-title-h' xs={16}>
                        <Heading size='md' color={'black'}>Permisos</Heading>
                    </Col>
                    <Col className='users-permissions-title-add' xs={8}>
                        {
                            allowModule({ permission: 'WAAAC' }) &&
                            <ButtonIcon
                                name={disabledTree ? 'Personalizar' : 'Restaurar'}
                                icon={disabledTree ? faCogs : faUndoAlt}
                                variant={disabledTree ? 'success' : 'secondary'}
                                onClick={customPermissions}
                                tooltipDisabled={true}
                                disabled={userSel ? false : true}
                            />
                        }
                    </Col>
                </Row>
            </Row>
            {
                loadingPermissions.isOpen && <Skeleton active />
            }
            {
                loadingUserPermissions.isOpen && <Skeleton active />
            }
            <div className='users-permissions-tree'>
                {
                    !loadingPermissions.isOpen && !loadingUserPermissions.isOpen && userSel &&
                    <Tree
                        checkable
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        treeData={treeData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        disabled={disabledTree}
                    />
                }
            </div>
            <Row className='users-permissions-footer'>
                {
                    allowModule({ permission: 'WAAAC' }) &&
                    <ButtonIcon
                        name='Guardar'
                        icon={faSave}
                        onClick={savePermissions}
                        variant='outline-success'
                        tooltipDisabled={true}
                        disabled={btnPermissions}
                    />
                }
            </Row>
        </Col>
    )
}

export default PermissionsComp