import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Chat from './Chat';
import Rooms from './Rooms';
import Send from './Send';

import audioNot from '../../../audio/iphone-notificacion.mp3';
import audioNew from '../../../audio/new_message.mp3';
import audioSend from '../../../audio/send_message_wa.mp3';

import { useLazyQuery, useSubscription } from '@apollo/client';
import { NEW_MESSAGE_IN_ROOMS } from 'graphql/subscriptions/_messenger/MessagesSubscriptions';
import { NEW_ROOM } from 'graphql/subscriptions/_messenger/RoomsSubscriptions';
import { Layout } from 'antd';
import { Variables } from 'variables/Variables';
import { ROOMS_QUERY } from 'graphql/queries/_messenger/RoomsQueries';
import { indexUsersSimple } from 'api/users/users';
import Profile from './Profile';
import HeaderRoom from './Header';
import moment from 'moment';

const _ = require('underscore');
const { Footer, Sider, Content } = Layout;

const Main = (props) => {
    const { updateRooms, newRoom } = props;
    const user_id = Variables.user.id;
    const room_id = Variables.room_id;

    const [chatRoomSel, setChatRoomSel] = useState(null);
    const [rooms, setRooms] = useState([]);
    const [idRooms, setIdRooms] = useState([]);
    const [messages, setMessages] = useState([]);
    const [countMessages, setCountMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [title, setTitle] = useState('');
    const [datetz, setDatetz] = useState(null);

    // Se obtiene la fecha y hora en UTC ya que ese formato se maneja
    // const datetz = moment().utc().format('YYYY-MM-DDTHH:mm:ss');

    // Subscription para nuevas salas
    const {
        data: new_room_data
    } = useSubscription(NEW_ROOM, { variables: { user_id } });
    // Subscription para mensajes entrantes
    const {
        data: new_message_data
    } = useSubscription(NEW_MESSAGE_IN_ROOMS, { variables: { _in: idRooms } });
    // Query para obtener las salas del usuario
    const [
        getRooms, { data: queryRooms }
    ] = useLazyQuery(ROOMS_QUERY, { variables: { user_id }, pollInterval: 500 });

    useEffect(() => {
        handleChatRoom(chatRoomSel);// Actualizar datos de lista actual, al actualizar el listado de salas
    }, [rooms]);
    // Efecto inicial en el que se obtienen los usuarios
    useEffect(() => {
        getUsers();
        // Se obtiene la fecha y hora en UTC ya que ese formato se maneja
        setDatetz(moment().utc().format('YYYY-MM-DDTHH:mm:ss'));
    }, []);

    useEffect(() => {
        getRooms();
    }, [new_room_data, updateRooms]);
    // Efecto que obtiene y actualiza las salas del usuario
    useEffect(() => {
        if (queryRooms) {
            if (queryRooms.messenger_rooms.length > 0) {
                setIdRooms(queryRooms.messenger_rooms.map((room) => room.id));
                setCountMessages(queryRooms.messenger_rooms.map((r) => { return { id: r.id, count: 0 } })); // Inicializar contador para notificaciones en 0 
            }
            setRooms(queryRooms.messenger_rooms);
        }
    }, [queryRooms]);

    useEffect(() => {
        if (new_message_data) {
            if (new_message_data.messenger_messages.length > 0) {    // Si hay mensajes entrantes
                if (new_message_data.messenger_messages[0].created_at > datetz) { // Se usa esta condicion para obtener los mensajes nuevos a partir de la hora de inicio de sesión
                    setNewMessage(new_message_data.messenger_messages[0]) // Se guarda mensaje entrante
                }
            }
        }
    }, [new_message_data]);


    const getUsers = async () => {
        let response = await indexUsersSimple({ package_id: Variables.package_id });
        setUsers(response.data);
    }

    const setNewMessage = (msg) => {
        if (room_id === msg?.room_id) {
            if (user_id !== msg?.user_id) new Audio(audioNew).play();
            else new Audio(audioSend).play();
            setMessages(current => {
                const exists = current.some(item => item.id == msg.id)
                if (exists) return current
                return [...current, msg]
            });
        } else {
            new Audio(audioNot).play();
            setCountMessages(
                countMessages.map((item) => {
                    if (msg.room_id === item.id) item.count++;
                    return item;
                })
            );
        }
    }
    // Evento para cambio de sala
    const handleChatRoom = (chat_room_id) => {
        setChatRoomSel(chat_room_id);
        setCountMessages(
            countMessages.map((item) => {
                if (chat_room_id === item.id) item.count = 0;
                return item;
            })
        );
        getTitle(chat_room_id);
        Variables.room_id = chat_room_id;
    }

    const getTitle = (id) => {
        let pos = _.findIndex(rooms, { id });
        if (pos !== -1) {
            if (rooms[pos].room_users.length === 2) {
                let _users = rooms[pos].room_users.filter((ru) => ru.user_id !== user_id);
                if (_users.length === 1) {
                    let pos = _.findIndex(users, { id: _users[0].user_id });            // Se obtiene la posición del destinatario en el arreglo de usuarios del paquete
                    if (pos !== -1)
                        setTitle(`${users[pos].first_name} ${users[pos].last_name}`);
                }
            } else {
                setTitle(rooms[pos].name);
            }
        }
    }

    return (
        <Container fluid style={{
            position: 'fixed',
            marginLeft: '3%',
            marginRight: '3%',
            maxWidth: '90%',
            width: '90%',
            marginTop: '2%',
            marginBottom: '4%',
            flexWrap: 'wrap'

        }}>
            <Layout>
                <Sider width={'22%'} style={{ backgroundColor: '#fff', height: '100%' }}>
                    {/* Datos de Perfil del usuario */}
                    <Profile newRoom={newRoom} />

                    {/* Componente para listar las salas disponibles */}
                    {
                        <Rooms
                            rooms={rooms}
                            handleChatRoom={handleChatRoom}
                            countMessages={countMessages}
                            users={users}
                            user_id={user_id}
                            room_id={chatRoomSel}
                        />
                    }
                </Sider>
                <Layout>
                    {/* Header de la sala seleccionada */}
                    <HeaderRoom
                        chatRoomSel={chatRoomSel}
                        title={title}
                        users={users}
                        rooms={rooms}
                        getRooms={getRooms}
                        user_id={user_id}
                    />
                    {/* <Header style={{ color: '#fff', fontWeight: 'bold' }}>
                        {
                            chatRoomSel &&
                            <Row>
                                <Col span={2}>
                                    <Avatar
                                        size={{ xs: 24, sm: 32, md: 35, lg: 40, xl: 45, xxl: 50 }}
                                        src={<Image src="https://joeschmoe.io/api/v1/random" />}
                                    />
                                </Col>
                                <Col span={20}>
                                    {title}
                                </Col>
                                <Col span={2}>

                                </Col>
                            </Row>
                        }
                    </Header> */}
                    <Content>
                        {/* Componente para listar los mensajes de la sala seleccionada */}
                        <Chat
                            room_id={chatRoomSel}
                            messages={messages}
                            setMessages={setMessages}
                            users={users}
                        />
                    </Content>
                    <Footer style={{ padding: '0' }}>
                        {/* Componente para enviar un nuevo mensaje */}
                        <Send
                            room_id={chatRoomSel}
                            user_id={user_id}
                        />
                    </Footer>
                </Layout>
            </Layout>
        </Container>
    )
}

export default Main;