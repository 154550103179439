import React, { useState, useEffect } from 'react';
import { indexMaintenanceTypes } from 'api/operation_standards/maintenance_types';
import { Heading } from '@chakra-ui/react';

const Filters = (props) => {
    const { activeTab, package_id, code, name, setMaintenanceTypesId, getData } = props;

    const [maintenanceTypes, setMaintenanceTypes] = useState([]);

    useEffect(() => {
        getMaintenanceTypes();
    }, []);

    // Método para obtener los tipos de mantenimiento
    const getMaintenanceTypes = async () => {
        let response = await indexMaintenanceTypes({ package_id });
        if (response.data.length > 0) {
            setMaintenanceTypes(response.data);
            setMaintenanceTypesId(response.data[0].id);
        }
    }

    const searchInfo = () => {
        getData();
    }

    const handleChange = (event) => {
        const { value } = event.target;
        setMaintenanceTypesId(value);
    }

    return (
        <section className="assets">
            {/* Título */}
            <Heading className='tituloSub'>{code} - {name}</Heading>
            <section className="sectionSearch" >
                {/* <article>
                    <label>Tipo de mantenimiento</label>
                    <select onChange={handleChange}>
                        {
                            maintenanceTypes.map((item, i) =>
                                <option key={i} value={item?.id}>{item?.name}</option>
                            )
                        }
                    </select>
                </article> */}
                {/* {
                    activeTab === 3 && <>
                        <article>
                            <label>Tipo de elemento</label>
                            <select>
                            </select>
                        </article>
                        <article>
                            <label>Línea base</label>
                            <select>
                            </select>
                        </article>
                        <article>
                            <label>Estado de verificación</label>
                            <select>
                                <option value={-1}>Todos</option>
                            </select>
                        </article>
                    </>
                }
                <article>
                    <button className="botonBusqueda" onClick={searchInfo}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                    </button>
                </article> */}
            </section >
        </section>
    )
}

export default Filters