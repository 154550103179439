import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexIncidentTimes = async ({ incident_evaluation_id, factor_type_id, segment_id }) => {
    const url = `/incident_times`;
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params: {
                incident_evaluation_id,
                factor_type_id,
                segment_id
            }
        });
        // console.log("🚀 ~ file: times.js:18 ~ indexIncidentTimes ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}