import { gql } from "@apollo/client";

export const GET_ASSETS_ITS_RECORDS = gql`
  query GetRecords($limit: Int!, $offset: Int!){
    assets_its_records(
        order_by: { id: desc }
        limit: $limit
        offset: $offset
    ) {
      id
      type
      active_user
      extras
      created_at
    }

    assets_its_records_aggregate {
      aggregate {
        count
      }
  }
  }
`;

export const GET_RECORDS_BY_MONITORING_ID = gql`
  query GetRecordsByMonitoringId($monitoring_id: String = ""){
    assets_its_records(
        order_by: { id: desc }
        where: {
          active_user: {_eq: $monitoring_id}
        }
        limit: 100
    ) {
      id
      type
      active_user
      extras
      created_at
    }

    assets_its_records_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_LAST_RECORD = gql`
  query MyQuery($monitoring_id: String = "", $events: [String!] = "") {
    assets_its_records(
      limit: 1,
      where: {
        type: {_in: $events}, 
        active_user: {_eq: $monitoring_id}
      }, 
      order_by: {
        id: desc
      }
    ) {
    id
    type
    created_at
  }
}
`;

export const GET_LASTEST_RECORDS = gql`
  query MyQuery($monitoring_id: String = "", $events: [String!] = "") {
    assets_its_records(
      distinct_on: [type], 
      where: {
        type: {_in: $events}, 
        active_user: {_eq: $monitoring_id}
      }, 
      order_by: {
        type: asc, 
        id: desc
      }
    ) {
    id
    type
    created_at
  }
}
`;