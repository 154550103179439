import React, { useState } from 'react'
import ModalComp from 'components/layouts/Modal'
import ButtonIcon from 'components/layouts/ButtonIcon'
import FormIcon from 'components/layouts/FormIcon'
import { faFileExcel, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { getUrlMonitoringXlsx } from 'api/operation_standards/assets_its_monitoring'
import { useSelector } from 'react-redux'

const MonitoringXlsx = ({ onClose, project_id }) => {
	const package_id = useSelector(state => state.login.package_id);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	const validate = () => {
		const { start_date, end_date } = data;
		return !!(start_date && end_date);
	}

	const onDownload = async () => {
		if (validate()) {
			setLoading(true);
			const { response } = await getUrlMonitoringXlsx({ project_id, package_id, ...data });
			if (response) {
				const link = document.createElement('a')
				link.href = response.signedUrl;
				document.body.appendChild(link)
				link.click();
			}
			setLoading(false);
			onClose();
		} else {
			Swal.fire('¡Formulario inválido!', 'Revise el formulario.', 'warning');
		}
	}
	const handleChange = (e) => {
		const { value, name } = e.target;
		setData(prevState => ({ ...prevState, [name]: value }))
	}
	return (
		<ModalComp
			onClose={onClose}
			size='sm'
			title='Descargar'
			body={<>
				<Row>
					<FormIcon
						type='date'
						title='Fecha de inicio'
						name='start_date'
						value={data?.start_date}
						handleChange={handleChange}
					/>
				</Row>
				<Row>
					<FormIcon
						type='date'
						title='Fecha de fin'
						name='end_date'
						value={data?.end_date}
						handleChange={handleChange}
					/>
				</Row>
			</>}
			footer={<>
				<ButtonIcon name='Cerrar' variant='outline-secondary' onClick={onClose} icon={faTimes} />
				<ButtonIcon
					name={loading ? 'Obteniendo...' : 'Descargar'}
					variant='outline-success'
					onClick={onDownload}
					icon={loading ? faSpinner : faFileExcel}
					disabled={loading}
				/>
			</>}
		/>
	)
}

export default MonitoringXlsx