import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import '../../UOV-0/Operacion.css';
import '../../UOV-0/Resultados.css';
import { __SERVER__ } from '../../../../../server/info';
import { Variables } from '../../../../../variables/Variables';
import { dateToString2 } from '../../../../../utilidades/dates';
import { faFileExcel, faFilePdf, faMapMarker, faPlus, faSpinner, faTable, faUpload } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row } from 'react-bootstrap';
import Inv from './ModalCarga';
import Axios from 'axios';
import ModalMap from './ModalMap';
import ModalReport from './ModalReportInventory';
import ShowInventory from './fragments/ShowInventory';
import NewStore from './fragments/NewStore';
import InventoryData from './fragments/InventoryData';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { downloadXLSX } from 'components/libs/exportData';
import { indexInventoryData } from 'api/maintenance_standards/inventory_data';
import { downloadPDF } from 'components/libs/exportDataPDF';
import FlexButton from 'components/layouts/FlexButton';

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingSave: false,
            sheets: [],
            modalExcel: false,
            modalViewInventoryData: false,
            modalEstablishments: false,
            excel_rows: [],
            excel_valid: null,
            excel: null,
            loadingSaveExcel: false,
            index: -1,
            refresh: false,
            buttonSave: false,
            inventory: [],
            inventory_payload: {
                creation_date: '',
                code: '',
                body: '',
                inventory: '',
                version: '',
                labelExtra: []
            },
            inventory_data: [],
            imageInventory: React.createRef(),
            refExtra: React.createRef(),
            image: null,
            modalInventory: false,
            //Bandera para abrir modal de carga
            modalUploadData: false,
            //items para carga de archivos
            items: this.props.items,
            extra_items: [],
            // bandera de modal mapa
            modalMap: false,
            // Bandera modal de reportes
            modalReports: false,
            modalReport_index: null,
            inventory_id: null,
            loadingDownloadXLSX: false,
            loadingDownloadPDF: false
        }
    }

    componentDidMount = () => {
        this.getInventory();
    }

    getInventory = async () => {
        this.setState({ loading: true });
        await Axios.get(`${__SERVER__}/projects/${Variables.project_id}/${this.props.table}`)
            .then(inventory => {
                this.setState({ inventory: inventory.data.reverse() });
            })
            .catch(error => {
                console.log(error);
                this.setState({ inventory: [] });
            });
        this.setState({ loading: false });
    }

    saveInventory = async () => {
        if (this.state.buttonSave) { return; }
        this.setState({ buttonSave: true });

        let labelExtra = JSON.stringify(this.state.inventory_payload.labelExtra);
        if (this.state.inventory_payload.labelExtra.length === 0) { labelExtra = null; }
        if (this.state.inventory_payload.creation_date === '' || this.state.inventory_payload.code === '' || this.state.inventory_payload.body === '' || this.state.inventory_payload.inventory === '' || this.state.inventory_payload.version === '') {
            alert('Debe rellenar todos los campos necesarios');
            return;
        }
        let data = {
            creation_date: this.state.inventory_payload.creation_date,
            code: this.state.inventory_payload.code,
            body: this.state.inventory_payload.body,
            inventory: this.state.inventory_payload.inventory,
            version: this.state.inventory_payload.version,
            labelExtra
        }
        await Axios.post(`${__SERVER__}/projects/${Variables.project_id}/${this.props.table}`, data)
            .then(res => {
                if (res !== null) {
                    this.state.inventory_payload.id = res;
                    this.state.inventory_payload.labelExtra = this.state.inventory_payload.labelExtra.length === 0 ? null : JSON.stringify(this.state.inventory_payload.labelExtra);
                    this.state.inventory.unshift(this.state.inventory_payload);
                    this.setState({
                        inventory_payload: {
                            creation_date: '',
                            code: '',
                            body: '',
                            inventory: '',
                            version: '',
                            labelExtra: []
                        }, buttonSave: false, modalInventory: false
                    });
                    this.getInventory();
                } else {
                    this.setState({ refresh: !this.state.refresh, buttonSave: false });
                }
            })
            .catch(e => {
                console.log(e);
                this.setState({ refresh: !this.state.refresh, buttonSave: false });
            });
    }

    getInventoryData = async () => {
        await Axios.get(`${__SERVER__}/projects/${Variables.project_id}/${this.props.table}/${this.state.inventory[this.state.index].id}/data`)
            .then(res => {
                this.setState({ inventory_data: res.data });
                // console.log("res inventory data: ", res);
            })
            .catch(error => {
                console.log(error);
                this.setState({ inventory_data: [] });
            })
    }

    openOrCloseModal = () => {
        this.setState({
            modalUploadData: !this.state.modalUploadData
        });
    }

    openOrCloseModalInventory = () => this.setState({ modalInventory: !this.state.modalInventory });

    openOrCloseModalView = () => {
        console.log("Returning");
        this.setState({ modalViewInventoryData: !this.state.modalViewInventoryData });
    }

    openOrCloseModalMap = () => {
        this.setState({
            modalMap: !this.state.modalMap
        });
    }

    openOrCloseModalReports = (i) => {
        this.setState({
            modalReport_index: i,
            modalReports: !this.state.modalReports
        });
    }

    getFields = (index) => {
        this.setState({ index });
        let extra = []
        // console.log("labels: ", this.state.inventory[index].labelExtra)
        if (this.state.inventory[index].labelExtra !== null) {
            JSON.parse(this.state.inventory[index].labelExtra).map(item => {
                extra.push({
                    label: item,
                    description: item,
                    value: item.toLowerCase(),
                    type: 'String',
                    allow: null
                })
            })

        }
        this.setState({ extra_items: extra });
    }

    clickViewInventory = (item, i) => {
        this.setState({
            modalViewInventoryData: true,
            inventory_data: [],
            index: i,
            inventory_id: item.id
        }, this.getInventoryData
        );
    }

    getXLSXData = async (item) => {
        this.setState({ loadingDownloadXLSX: true });
        const { code, inventory, id } = item;
        const { table, items } = this.props;
        const { data } = await indexInventoryData({ table, inventory_id: id });

        const widthCells = items.map(item => ({ width: item.width }));

        const newData = data.map(obj => {
            const newObj = {};
            items.forEach((item) => {
                const { value, label } = item;
                newObj[label] = obj[value];
            });
            return newObj;
        });

        downloadXLSX({
            file_name: code + ' | ' + inventory,
            data: newData,
            widthCells
        });
        this.setState({ loadingDownloadXLSX: false });
    }

    getPDFData = async (item) => {
        this.setState({ loadingDownloadPDF: true });
        const { code, inventory, id } = item;
        const { table, items } = this.props;
        const { data } = await indexInventoryData({ table, inventory_id: id });
        const headers = items.filter(item => item.pdf === true);
        downloadPDF({
            title: code + ' | ' + inventory,
            file_name: code + ' | ' + inventory,
            headers,
            data
        });
        this.setState({ loadingDownloadPDF: false });
    }

    render = () => {
        return (
            <section>
                {
                    this.state.modalViewInventoryData ?
                        <InventoryData
                            inventory={this.state.inventory[this.state.index]}
                            items={this.state.items}
                            table={this.props.table}
                            onClose={this.openOrCloseModalView}
                            project_id={Variables.project_id}
                            km={this.props.km}
                            percentage={this.props.percentage}
                        />
                        : <>
                            <section className="cardsInventories">
                                {
                                    this.state.inventory.map((item, i) => {
                                        const { id, creation_date, inventory, code, body, version } = item;
                                        const { downloadPDF, downloadXLSX } = this.state;
                                        return (
                                            <Card style={{ width: '15rem' }} key={id}>
                                                <Card.Body>
                                                    <Card.Subtitle>{dateToString2(creation_date)}</Card.Subtitle>
                                                    <Card.Title><strong>{inventory}</strong></Card.Title>
                                                    <Card.Text>
                                                        <strong>Código: </strong>{code}<br />
                                                        <strong>Cuerpo: </strong>{body}<br />
                                                        <strong>Versión: </strong>{version}<br />
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Row>
                                                        <Col>
                                                            <ButtonIcon icon={faTable} onClick={() => this.clickViewInventory(item, i)} variant='outline-info' tooltip='Ver inventario' /></Col>
                                                        <Col>
                                                            <ButtonIcon icon={faUpload} onClick={() => { this.getFields(i); this.openOrCloseModal(); }} variant='outline-success' tooltip='Cargar Inventario' /></Col>
                                                        <Col>
                                                            <ButtonIcon icon={faMapMarker} onClick={() => this.setState({ modalMap: true, inventory_data: [], index: i }, this.getInventoryData)} variant='outline-danger' tooltip='Ver inventario en mapa' /></Col>
                                                        <Col>
                                                            <ButtonIcon icon={downloadXLSX ? faSpinner : faFileExcel} onClick={() => this.getXLSXData(item)} variant='outline-success' tooltip='Descargar en formato XLSX' disabled={downloadXLSX} /></Col>
                                                        <Col>
                                                            <ButtonIcon icon={downloadPDF ? faSpinner : faFilePdf} onClick={() => this.getPDFData(item)} variant='outline-danger' tooltip='Descargar en formato PDF' disabled={downloadPDF} /></Col>
                                                    </Row>
                                                </Card.Footer>
                                            </Card>
                                        )
                                    }
                                    )
                                }
                            </section>
                            {/* Mapa de ubicaciones*/}
                            {
                                this.state.modalMap ?
                                    <ModalMap
                                        title={`${this.state.inventory[this.state.index].code} - ${this.state.inventory[this.state.index].inventory}`}
                                        inventory_data={this.state.inventory_data}
                                        onClose={this.openOrCloseModalMap}
                                    /> : null
                            }

                            {/*Modal reportes*/}
                            {
                                this.state.modalReports ?
                                    <ModalReport
                                        title={`Descargar reporte de inventarios`}
                                        inventory={this.state.inventory[this.state.modalReport_index]}
                                        onClose={this.openOrCloseModalReports}
                                    /> : null
                            }
                            {/* Agregar almacén */}
                            <FlexButton style={{ bottom: 20, right: 20 }} icon={faPlus} onClick={() => this.setState({ modalInventory: true })} />

                            {/* Modal para crear almacén */}
                            {
                                this.state.modalInventory &&
                                <NewStore
                                    items={this.props.items.filter(item => item.add === true)}
                                    onClose={this.openOrCloseModalInventory}
                                    table={this.props.table}
                                    getInventory={this.getInventory}
                                    saveInventory={this.saveInventory}
                                />
                            }
                            {/* Modal para cargar datos a través de XLSX */}
                            {
                                this.state.modalUploadData &&
                                <Inv
                                    server={`${__SERVER__}/projects/${Variables.project_id}/${this.props.table}/${this.state.inventory[this.state.index].id}/data_array`}
                                    items={this.state.items.filter(item => item.add === true).concat(this.state.extra_items.map(item => ({ ...item, widthXLSX: 100, width: 25 })))}
                                    items_base={this.props.items.concat(this.state.extra_items)}
                                    extra_items={this.state.extra_items}
                                    title={`Cargar datos`}
                                    onClose={this.openOrCloseModal}
                                    inventory={this.state.inventory[this.state.index]}
                                />
                            }
                            {/* Modal para visualizar inventario */}
                            {
                                this.state.modalViewInventoryData &&
                                <ShowInventory
                                    table={this.props.table}
                                    inventory_id={this.state.inventory_id}
                                    onClose={() => this.setState({ modalViewInventoryData: !this.state.modalViewInventoryData })}
                                    excel_headers={this.state.items}
                                    excel_headers_extra={this.state?.inventory[this.state.index]?.labelExtra}
                                />
                            }
                        </>
                }
            </section>
        );
    }
}
