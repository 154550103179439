import React from 'react'
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment';
import Cap from '../../../img/cap.jpg'

const styles = StyleSheet.create({
    body: { padding: 15 },
    Head: {
        display: "flex",
        flexDirection: "row"
    },
    Head_Image: { width: 70 },
    table: {
        display: "table",
        borderStyle: "solid",
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        marginBottom: 15,
        borderColapse: "collapse",
        tableLayout: "auto",
        width: "auto",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        borderStyle: "solid",
        borderTopWidth: 1,
        width: "100%"
    },
    tableCol: {
        borderStyle: "solid",
        borderRightWidth: 1,
        width: "100%",
        height: "auto"
    },
    tableCell: {
        margin: "auto",
        marginVertical: 5,
        marginHorizontal: 3,
        fontSize: 10,
        textAlign: "center"
    },
    text_center: { textAlign: "center", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_title: { textAlign: "center", fontWeight: "bold", fontSize: 12, width: "100%", borderStyle: "solid", borderBottomWidth: 1, marginVertical: 2 },
    text_center50: { textAlign: "center", fontSize: 12, width: "50%", borderStyle: "solid", borderBottomWidth: 1, borderRightWidth: 1 },
    logo: {
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid",
        borderWidth: 1,
    },
    logo1: {
        width: "35%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid",
        borderWidth: 1,
    },
    d_flex: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center", borderStyle: "solid",
        borderWidth: 1,
    }, txt_center: { textAlign: "center" },
    text_logo: { textAlign: "center", fontSize: 19, fontWeight: "heavy", width: "100%", borderStyle: "solid", borderTopWidth: 1, color: "#009644" },
    mb_5: {
        marginBottom: 14, borderStyle: "solid",
        borderWidth: 1,
    },
    fz_12: { fontSize: 12, textAlign: "center", width: "100%" },
    flex: {
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" },
    cuad: { display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }
});

const ReportGeneral = ({ data }) => {
    return (
        <Document>
            <Page size='A4' style={styles.body}>
                <View style={styles.mb_5} fixed>
                    <View style={styles.Head}>
                        <View style={styles.logo}>
                            <Image src={Cap} style={styles.Head_Image} />
                        </View>

                        <View style={styles.d_flex}>
                            <Text style={{ fontWeight: 900, textAlign: 'center' }}>Construcciones Amozoc Perote S.A de C.V.</Text>
                            <Text style={styles.text_center}>GESTIÓN DE ACTIVOS </Text>
                            <Text style={styles.text_center}>CM-CAPSA-MTTO SGA</Text>
                            <Text style={styles.fz_12}>Reporte General</Text>

                        </View>

                        <View style={styles.logo1}>
                            <Text style={styles.text_center}>Autopista Amozoc - Perote</Text>
                            <Text style={styles.text_center}>CÓDIGO: -</Text>
                            <View style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                <Text style={styles.text_center50}>VERSIÓN: 1</Text>
                                <Text style={styles.text_center50}>FECHA DE EMISIÓN: {moment().format("D/MM/YY")}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.table}>
                    <Text style={{ fontSize: 14, marginBottom: 10, textAlign: "center" }}>Incidencias</Text>
                    <View style={styles.tableRow} fixed>
                        {
                            ["#", "Estandar", "Estatus", "Creado por", "Fecha creación", "Resolución estimada", "Seg inicial", "Seg final"].map((item, i) =>
                                <View style={styles.tableCol} key={i} >
                                    <Text style={styles.tableCell}>{item}</Text>
                                </View>
                            )
                        }
                    </View>

                    {
                        data && data.map((inc, i) =>
                            <View style={styles.tableRow} key={inc.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{i + 1}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.maintenance_standard_name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.status_name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.created_by_full_name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.creation_date}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.estimated_date ? moment(inc.estimated_date).format("DD [de] MMM YYYY HH:mm") : 'Sin fecha'}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.segment_start}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{inc.segment_end}</Text></View>
                            </View>
                        )
                    }
                </View>

                <View style={styles.table} break>
                    <View style={styles.tableRow} fixed>
                        <View style={styles.tableCol} >
                            <Text style={styles.tableCell}>Evaluación</Text>
                        </View>
                    </View>

                    {
                        data && data.map((inc, i) =>
                            <View style={styles.tableRow} key={inc.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}> {inc.incident_evaluation_name}</Text></View>
                            </View>
                        )
                    }
                </View>

                <View style={{ display: "flex", flexDirection: "row", marginTop: 100, fontSize: 12, justifyContent: "space-around" }} >
                    <View>
                        <Text>FIRMA DEL MRO (OPERADOR)</Text>
                    </View>
                    <View>
                        <Text>FIRMA DE LA SUPERVISIÓN (AAS)</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
export default ReportGeneral