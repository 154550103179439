import React, { Component } from "react";
import ReactPlayer from 'react-player';
import axios from "axios";
import { __SERVER__ } from "server/info";

const mime = require('mime-types');

const MediaOnline = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            media: [],
            refresh: false,
        }
    }

    componentDidMount = () => {
        console.log('MEDIA', this.props.media);
        for (const element of this.props.media) {
            let url = __SERVER__ + '/victum_users/victum_incidents/' + element.victum_incident_id + '/media/download?path=' + element.file_name;
            axios(url, {
                method: "GET",
                responseType: "blob"
            }).then(response => {
                let file = new Blob([response.data], {
                    type: response.data.type
                });
                let type = "image";
                let fileURL = URL.createObjectURL(file);
                if ((mime.lookup(element.file_name)).split('/')[0] === 'video') {
                    type = "video";
                }
                this.state.media.push({ type, media: fileURL });
                this.setState({ refresh: !this.state.refresh });
            }).catch(error => {
                console.log("Error", error);
            });
        }
    }

    render = () => {
        return (
            <div>
                {
                    this.state.media.map((item) =>
                        item.type === 'image' ?
                            <img src={item.media} key={item.id} className="mediaInicidentes" />
                            : <ReactPlayer
                                key={item.id}
                                width={200}
                                height={220}
                                className="mediaInicidentes"
                                controls={true}
                                url={item.media} />
                    )
                }
            </div>
        );
    }
}

export default MediaOnline;