import FormIcon from 'components/layouts/FormIcon';
import React, { Fragment } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const payment_form = [
    { code: "01", name: 'Efectivo' },
    { code: "02", name: 'Cheque nominativo' },
    { code: "03", name: 'Transferencia electrónica de fondos' },
    { code: "04", name: 'Tarjeta de crédito' },
    { code: "05", name: 'Monedero electrónico' },
    { code: "06", name: 'Dinero electrónico' },
    { code: "08", name: 'Vales de despensa' },
    { code: "12", name: 'Dación en pago' },
    { code: "13", name: 'Pago por subrogación' },
    { code: "14", name: 'Pago por consignación' },
    { code: "15", name: 'Condonación' },
    { code: "17", name: 'Compensación' },
    { code: "23", name: 'Novación' },
    { code: "24", name: 'Confusión' },
    { code: "25", name: 'Remisión de deuda' },
    { code: "26", name: 'Prescripción o caducidad' },
    { code: "27", name: 'A satisfacción del acreedor' },
    { code: "28", name: 'Tarjeta de débito' },
    { code: "29", name: 'Tarjeta de servicios' },
    { code: "30", name: 'Aplicación de anticipos' },
    { code: "31", name: 'Intermediario pagos' },
    { code: "99", name: 'Por definir' },
];

const cfdi_use = [
    { code: 'G01', name: 'Adquisición de mercancías.' },
    { code: 'G02', name: 'Devoluciones, descuentos o bonificaciones.' },
    { code: 'G03', name: 'Gastos en general.' },
    { code: 'I01', name: 'Construcciones.' },
    { code: 'I02', name: 'Mobiliario y equipo de oficina por inversiones.' },
    { code: 'I03', name: 'Equipo de transporte.' },
    { code: 'I04', name: 'Equipo de computo y accesorios.' },
    { code: 'I05', name: 'Dados, troqueles, moldes, matrices y herramental.' },
    { code: 'I06', name: 'Comunicaciones telefónicas.' },
    { code: 'I07', name: 'Comunicaciones satelitales.' },
    { code: 'I08', name: 'Otra maquinaria y equipo.' },
    { code: 'D01', name: 'Honorarios médicos, dentales y gastos hospitalarios.' },
    { code: 'D02', name: 'Gastos médicos por incapacidad o discapacidad.' },
    { code: 'D03', name: 'Gastos funerales.' },
    { code: 'D04', name: 'Donativos.' },
    { code: 'D05', name: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
    { code: 'D06', name: 'Aportaciones voluntarias al SAR.' },
    { code: 'D07', name: 'Primas por seguros de gastos médicos.' },
    { code: 'D08', name: 'Gastos de transportación escolar obligatoria.' },
    { code: 'D09', name: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
    { code: 'D10', name: 'Pagos por servicios educativos (colegiaturas).' },
    { code: 'S01', name: 'Sin efectos fiscales.' },
    { code: 'CP01', name: 'Pagos' },
    { code: 'CN01', name: 'Nómina' },
];

const InvoiceDetails = (props) => {
    const { details, handleDetails } = props;
    return (
        <Fragment>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Forma de pago</Form.Label>
                    <select name='payment_form' onChange={handleDetails}>
                        {
                            payment_form.map((pf, index) =>
                                <option key={index} value={pf.code}>{pf.code} - {pf.name}</option>
                            )
                        }
                    </select>
                </Col>
                <Col>
                    <Form.Label>Método de pago</Form.Label>
                    <select name='payment_method' onChange={handleDetails}>
                        <option value="PUE">PUE - Pago en Una sola Exhibición</option>
                        <option value="PPD">PPD - Pago en Parcialidades o Diferido</option>
                    </select>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Uso de CFDI</Form.Label>
                    <select name='use' onChange={handleDetails}>
                        {
                            cfdi_use.map((cu, index) =>
                                <option key={index} value={cu.code}>{cu.code} - {cu.name}</option>
                            )
                        }
                    </select>
                </Col>
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Tipo de moneda' name='currency' value={details?.currency} handleChange={handleDetails} />
                <FormIcon title='ID externo [Opcional]' name='external_id' value={details?.external_id} handleChange={handleDetails} />
                <FormIcon title='Folio [Opcional]' name='folio_number' value={details?.folio_number} handleChange={handleDetails} />
                <FormIcon title='ID externo [Opcional]' name='' value={details?.series} handleChange={handleDetails} />
            </Row>
        </Fragment>
    )
}

export default InvoiceDetails