import React, { useEffect, useState } from 'react'
import { downloadMaintenanceProgramFlowFiles } from 'api/maintenance_standards/maintenance_program_flow_files';
import CarouselComponent from 'components/layouts/Carousel'
import moment from 'moment';

const Photos = (props) => {
    const { files, project_id } = props;
    const [loading, setLoading] = useState(true);
    const [pictures, setPictures] = useState([]);
    const [count, setCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];
        const getPictures = async () => {
            setLoading(true);
            if (files) {
                setCount(files.length);
                for (const file of files) {
                    if (cancelLoop) break;
                    const { id, path, program_flow_id, created_at } = file;
                    const { cancel, response } = await downloadMaintenanceProgramFlowFiles({ project_id, program_flow_id, path });
                    cancelTokens.push({ cancel });
                    if (response) {
                        const blob_file = URL.createObjectURL(response);
                        setPictures(pictures => [...pictures, {
                            id,
                            image: blob_file,
                            created_at: `Evidencia capturada ${moment(created_at).format('LLLL')}`
                        }]);
                        setCurrentCount(currentCount => currentCount + 1);  // Contador del archivo obtenido
                        setLoading(false);
                    }
                }
            }
            setLoading(false);
        }
        getPictures();
        return () => {
            cancelLoop = true;  // cancelar la petición del resto de las fotografías
            cancelTokens.forEach((token) => token.cancel()); // cancelar cada uno de los tokens creados
        }
    }, []);


    return (
        <>
            {
                count > 0 &&
                <p>Obteniendo {currentCount}/{count} fotografía{count === 1 ? '' : 's'}...</p>
            }
            <CarouselComponent
                evidences={pictures}
                loading={loading}
            />
        </>
    )
}

export default Photos