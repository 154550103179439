import React from "react";
import { Empty } from "antd";

const NoData = () => (
    <Empty
        description={
            <span style={{ color: '#fff' }}>
                No hay registros
            </span >
        }
    />
)

export default NoData;