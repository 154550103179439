import React, { useEffect, useRef, useState } from 'react';
import './Style.css';
import { Skeleton } from 'antd';
import { GoogleApiWrapper, InfoWindow, Map, Marker, LoadingContainer } from 'google-maps-react';
import { getKmFormat, getPercentaje } from 'components/libs/functions';
import { useQuery } from '@apollo/client';
import { GET_RECORDS_BY_MONITORING_ID } from 'graphql/queries/assets_its/RecordsQueries';
import PanelComp from './PanelComp';

const moment = require('moment');

const Ubication = ({ google, mapReady, assets, setAssets, getStatus, items, propsQuery, data }) => {
    const markerRefs = useRef([]);

    const [activeMarker, setActiveMarker] = useState(null);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [assetSel, setAssetSel] = useState({});
    const [elements, setElements] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [events, setEvents] = useState([]);

    // Query para obtener la lista de eventos según el elemento seleccionado
    const { data: dataEvents } = useQuery(GET_RECORDS_BY_MONITORING_ID, {
        ...propsQuery,
        variables: { monitoring_id: `${assetSel.monitoring_id}` }
    });

    useEffect(() => {
        if (dataEvents) {
            const { assets_its_records } = dataEvents;
            // Verificar que los eventos ya hayan sido escuchados, de lo contrario actualizar
            verifyRecords(assets_its_records);
            setEvents(assets_its_records);
        }
    }, [dataEvents]);

    useEffect(() => {
        if (assetSel) {
            setElements(Object.values(assetSel));
            setHeaders(Object.keys(assetSel));
        }
    }, [assetSel]);

    const verifyRecords = (records) => {
        if (records.length > 0) {
            // Verificar el último registro
            const latestRecord = records[0];
            // Buscar el evento en la lista obtenida
            const lastRecordExists = data.data.some(record => record.id === latestRecord.id);
            // Si no existe se actualiza el estado
            if (!lastRecordExists) {
                setAssets(assets.map((asset) => {
                    if (asset.monitoring_id == latestRecord.active_user) {
                        asset.status = getStatus(latestRecord.type);
                    }
                    return asset;
                }));
            }
        }
    }
    const onReady = (_, map) => {
        const bounds = new google.maps.LatLngBounds();
        assets.forEach((point) => {
            return bounds.extend({
                lat: point.latitude,
                lng: point.longitude
            })
        })
        map.fitBounds(bounds);
    };

    const getFillColor = (status) => {
        switch (status) {
            case 'ocupado': return '#17b5d9';
            case 'disponible': return '#00ff00';
            case 'falla': return '#878586';
            case 'vandalizado': return '#e5232e';
            default: return '#878586';
        }
    }

    const handleMarkerClick = (marker, item) => {
        if (item.id !== assetSel.id) setEvents([]);
        setAssetSel(item);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };

    const handleBoxClick = (index, item) => {
        if (markerRefs.current[index]) {
            if (item.id !== assetSel.id) setEvents([]);
            setAssetSel(item);
            const marker = markerRefs.current[index].marker
            if (marker) {
                setActiveMarker(marker);
                setShowInfoWindow(true);
            }
        }
    }

    const handleCloseInfoWindow = () => {
        setActiveMarker(null);
        setShowInfoWindow(false);
    };

    const getLabel = (key) => {
        const { label } = items.find((item) => item.key === key) || {};
        return label || key;
    };

    const isHidden = (key) => {
        const { hidden } = items.find((item) => item.key === key) || {};
        return !!hidden;
    };

    const formatValue = (value, key) => {
        if (!value) {
            return '';
        }
        if (/km/i.test(key)) {
            return getKmFormat(value);
        }
        if (/percent/i.test(key)) {
            return getPercentaje(value, 5);
        }
        return value;
    };
    return (
        <>
            <PanelComp
                assets={assets}
                handleBoxClick={handleBoxClick}
                getFillColor={getFillColor}
            />

            <div key={'ubication'} style={{ height: '70vh', width: '100%' }}>
                {
                    !mapReady ? <Skeleton active /> :
                        <Map
                            google={google}
                            style={{ width: '100%', height: '100%', position: 'relative' }}
                            className={'map'}
                            zoom={14}
                            onReady={onReady}
                        >
                            {
                                assets.map((item, index) =>
                                    <Marker
                                        key={item.id}
                                        ref={(ref) => (markerRefs.current[index] = ref)}
                                        position={{ lat: item.latitude, lng: item.longitude }}
                                        onClick={(props, marker) => handleMarkerClick(marker, item)}
                                        options={{
                                            icon: {
                                                path: google.maps.SymbolPath.CIRCLE,
                                                fillColor: getFillColor(item.status),
                                                fillOpacity: 1,
                                                strokeWeight: 0,
                                                scale: 10,
                                            },
                                        }}
                                    />
                                )
                            }
                            <InfoWindow
                                marker={activeMarker}
                                visible={showInfoWindow}
                                onClose={handleCloseInfoWindow}
                            >
                                <div className='infoWindowStyles'>
                                    <div className='list-view'>
                                        {/* Datos del elemento */}
                                        <div className="attribute-view">
                                            <h1 className="attribute-title">Información del elemento</h1>
                                            {
                                                headers.map((key, index) => {
                                                    if (!isHidden(key)) {
                                                        const label = getLabel(key);
                                                        const value = formatValue(elements[index], key);
                                                        return (
                                                            <div key={key} className="attribute-item">
                                                                <span className="attribute-label">{label}</span>
                                                                <span className="attribute-value">{value}</span>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                        {/* Historial de eventos */}
                                        <div className="attribute-view">
                                            <h1 className="attribute-title">Historial de eventos</h1>
                                            <ul className="event-list-items">
                                                {
                                                    events.map((event) => (
                                                        <li key={event.id} className="event-list-item">
                                                            <span className="event-name">{event.type}</span>
                                                            <span className="event-date">{moment(event.created_at).fromNow()}</span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </InfoWindow>
                        </Map>
                }
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
    LoadingContainer
})(Ubication)