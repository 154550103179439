import React, { useState } from 'react';
import FormIcon2 from 'components/layouts/FormIcon2';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getUrlMaintenanceProgram } from 'api/maintenance_standards/maintenance_program';
import './css/MaintenanceProgram.css';
import { Col, Row } from 'react-bootstrap';
import { Heading, Text } from '@chakra-ui/react';
import Swal from 'sweetalert2';

const MaintenanceProgram = ({ project_id, package_id }) => {
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);

    const getFile = async () => {
        if (validate()) {
            setLoading(true);
            const { response } = await getUrlMaintenanceProgram({ package_id, project_id, ...form });
            if (response) {
                const link = document.createElement('a')
                link.href = response.signedUrl;
                document.body.appendChild(link)
                link.click();
            }
            setLoading(false);
        } else {
            Swal.fire('¡Formulario incompleto!', 'Debe introducir un rango de fechas para crear el archivo.', 'info');
        }
    }

    const validate = () => {
        let response = true;
        const fields = ['start_date', 'end_date'];
        for (const field of fields) {
            if (!form[field]) {
                response = false;
                break;
            }
        }
        return response;
    }

    const handleChange = (event) => {
        const { value, name } = event.target;
        setForm(prevState => ({ ...prevState, [name]: value }));
    }

    return (
        <div className='entregables-maintenance-program'>
            <Row>
                <Heading color={'black'}>Programa de Mantenimiento</Heading>
            </Row>
            <Row className='mb-4 mt-2'>
                <Text>
                    Descargar Hoja de cálculo con el concentrado de programa de mantenimiento de un rango de fechas específico.
                </Text>
            </Row>
            <Row className='entregables-maintenance-program-r-form'>
                <FormIcon2 title='Fecha de incial' type='date' name='start_date' handleChange={handleChange} value={form?.start_date} />
                <FormIcon2 title='Fecha de final' type='date' name='end_date' handleChange={handleChange} value={form?.end_date} />
            </Row>
            <Row className='entregables-maintenance-program-r-footer'>
                <Col>
                    <ButtonIcon
                        name={loading ? 'Obteniendo...' : 'Descargar'}
                        icon={loading ? faSpinner : faFileExcel}
                        variant='outline-success'
                        onClick={getFile}
                        tooltipDisabled={true}
                        disabled={loading}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default MaintenanceProgram