import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;

export const indexObacInspections = async (props) => {
    const { project_id } = props;
    const url = `/projects/${project_id}/obac_inspections`;
    let response = { data: [], totalItems: 0 };
    delete props.project_id;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params: props
        });
        console.log("🚀 ~ file: inspections.js:15 ~ indexObacInspections ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const showObacInspections = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/obac_inspections/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET'
        });
        // console.log("🚀 ~ file: inspections.js:34 ~ showObacInspections ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storeObacInspections = async ({ project_id, data }) => {
    const url = `/projects/${project_id}/obac_inspections`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: maintenance_program.js ~ line 32 ~ storeObacInspections ~ fetch", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const closeObacInspections = async ({ project_id, inspection_id, user_id }) => {
    const url = `/projects/${project_id}/obac_inspections/${inspection_id}/close`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data: { completed_by: user_id }
        });
        console.log("🚀 ~ file: inspections.js:69 ~ closeObacInspections ~ fetch:", fetch)
        if (fetch.status === CREATED || fetch.status === STATUS_OK)
            response = fetch.data
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteObacInspections = async ({ project_id, id }) => {
    const url = `/projects/${project_id}/obac_inspections/${id}`;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: inspections.js:34 ~ deleteObacInspections ~ fetch", fetch)
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}