import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalComp from 'components/layouts/Modal';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import { faFileExcel, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'react-bootstrap';
import usePagination from 'hooks/usePagination';
import Swal from 'sweetalert2';
import { getUrlMaintenanceProgramPerformanceXlsx } from 'api/maintenance_standards/maintenance_program';

const ModalReport = ({ onClose, project_id, package_id, maintenance_standard_id }) => {
    const { data, setData, loading, setLoading } = usePagination({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData(prevState => ({ ...prevState, [name]: value }));
    }

    const validate = () => {
        const { start_date, end_date } = data;
        return !!(start_date && end_date);
    }

    const onDownload = async () => {
        if (validate()) {
            setLoading(true);
            const { response } = await getUrlMaintenanceProgramPerformanceXlsx({ project_id, package_id, ...data, maintenance_standard_id });
            if (response) {
                const link = document.createElement('a')
                link.href = response.signedUrl;
                document.body.appendChild(link)
                link.click();
            }
            setLoading(false);
            // onClose();
        } else {
            Swal.fire('¡Formulario inválido!', 'Revise el formulario.', 'warning');
        }
    }
    return (
        <ModalComp
            onClose={onClose}
            title='Reporte de Rendimientos'
            size='md'
            body={<>
                <Row>
                    <FormIcon
                        type='date'
                        title='Fecha de inicio'
                        name='start_date'
                        value={data?.start_date}
                        handleChange={handleChange}
                    />
                </Row>
                <Row>
                    <FormIcon
                        type='date'
                        title='Fecha de fin'
                        name='end_date'
                        value={data?.end_date}
                        handleChange={handleChange}
                    />
                </Row>
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                <ButtonIcon
                    name={loading ? 'Obteniendo...' : 'Descargar'}
                    variant='outline-success'
                    onClick={onDownload}
                    icon={loading ? faSpinner : faFileExcel}
                    disabled={loading}
                />
            </>}
        />
    )
}

ModalReport.propTypes = {
    onClose: PropTypes.func.isRequired,
    project_id: PropTypes.number.isRequired,
    package_id: PropTypes.number.isRequired,
    maintenance_standard_id: PropTypes.number.isRequired
}

export default ModalReport