import { indexInventoryData } from 'api/maintenance_standards/inventory_data';
import React, { useState, useEffect } from 'react';
import { Alert, Modal } from 'react-bootstrap';

const ShowInventory = (props) => {
    // console.log("🚀 ~ file: ShowInventory.jsx ~ line 6 ~ ShowInventory ~ props", props)
    const {
        table,
        inventory_id,
        onClose,
        excel_headers,
        excel_headers_extra
    } = props;

    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInventoryData();
    }, []);


    const handleClose = () => {
        onClose();
        setShow(false);
    }

    const getInventoryData = async () => {
        setLoading(true);
        let response = await indexInventoryData({ table, inventory_id });
        // console.log("🚀 ~ file: ShowInventory.jsx ~ line 30 ~ getInventoryData ~ response", response)
        setData(response.data);
        setLoading(false);
    }

    return (
        <Modal size={'xl'} show={show} backdrop="static" keyboard={false} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Inventario</Modal.Title>
            </Modal.Header>
            {
                loading &&
                <Modal.Body>
                    <Alert show={true} variant={"primary"}>
                        <Alert.Heading>Obteniendo datos...</Alert.Heading>
                    </Alert>
                </Modal.Body>
            }
            {
                !loading && data.length !== 0 &&
                <Modal.Body>
                    <div className="designTableExcel">
                        <table>
                            <thead>
                                <tr>
                                    {
                                        excel_headers.map((item, i) =>
                                            <th key={"header_" + i}>{item.label}</th>
                                        )
                                    }
                                    {
                                        excel_headers_extra !== null &&
                                        JSON.parse(excel_headers_extra).map((item, i) =>
                                            <th key={"header_extra_" + i}>{item.toUpperCase()} </th>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, i) =>
                                        <tr key={"row" + i}>
                                            {
                                                excel_headers.map((el, j) =>
                                                    <td key={`${i}-${j}`}>{item[el.value]}</td>
                                                )
                                            }
                                            {
                                                item.extras !== null &&
                                                JSON.parse(item.extras).map((it, j) =>
                                                    <td key={"extras" + i + j}>{it}</td>
                                                )
                                            }
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>

            }
            {
                !loading && data.length === 0 && <Modal.Body>
                    <Alert show={true} variant={"warning"}>
                        <Alert.Heading>No hay registros</Alert.Heading>
                    </Alert>
                </Modal.Body>
            }
        </Modal>
    )
}

export default ShowInventory