import React, { useState } from 'react'
import { Center, HStack, PinInput, PinInputField, useToast } from "@chakra-ui/react";
import ButtonIcon from './layouts/ButtonIcon';
import setToast from './layouts/setToast';
import { faSpellCheck } from '@fortawesome/free-solid-svg-icons';
import { verifySignon } from 'api/users/auth';
import { httpStatus } from 'variables/Variables';

const VerifyCodeComp = ({ username, goHome, setUserLog }) => {
    const stylePin = { backgroundColor: '#FFF' };
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);

    const toast = useToast();

    const isValidData = () => {
        return code.length === 6;
    }
    const verifyCode = async () => {
        if (isValidData()) {
            setLoading(true);
            const { response } = await verifySignon({ username, code, app: 'W' });
            if (response.status === httpStatus.ok) { // Código validado correctamente
                // Abrir sesión
                goHome(response.data);
                setToast({  // Mensaje de retroalimentación: success
                    title: '¡Código de validación correcto!', toast, status: 'success',
                    description: 'Su sesión ha sido aprobada'
                });
            } else {
                setUserLog('Invalid Code');
                setToast({  // Mensaje de retroalimentación: error
                    title: '¡Código de validación incorrecto!', toast, status: 'error',
                    description: 'El código proporcionado no coincide con el último código que fue enviado a su dirección de correo electrónico. Verifique por favor'
                });
            }
            setLoading(false);
        } else {
            setToast({  // Mensaje de retroalimentación: warning
                title: '¡Código de validación incompleto!', toast, status: 'warning',
                description: 'El código debe tener una longitud de 6 dígitos'
            });
        }
    }

    const handleChange = (value) => { setCode(value) }
    const isDisabled = loading || !isValidData();
    return (
        <>
            <h3>Verificar Código</h3>
            <HStack className="mt-4">
                <PinInput
                    size='lg'
                    type='alphanumeric'
                    onChange={handleChange}
                >
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                </PinInput>
            </HStack>
            <div className="d-grid gap-2 mt-3">
                <Center>
                    <ButtonIcon size='md' style={{ width: '100%' }} variant='primary' icon={faSpellCheck}
                        name={'Verificar Código'} onClick={verifyCode} disabled={isDisabled} />
                </Center>
            </div>
        </>
    )
}

export default VerifyCodeComp