import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import TabsComp from 'components/layouts/Tabs';
import Details from './ModalDetails/Details';
import Photos from './ModalDetails/Photos';

const ModalDetails = (props) => {
    const { onClose, dataDetails, project_id } = props;
    return (
        <ModalComp
            onClose={onClose}
            title='Detalles del evento'
            size='lg'
            body={<>
                <TabsComp
                    tabColor='black'
                    tablist={['Detalles', 'Fotografías']}
                    tabpanels={[
                        <Details
                            key='1'
                            dataDetails={dataDetails}
                        />,
                        <Photos
                            key='2'
                            files={dataDetails?.files}
                            project_id={project_id}
                        />
                    ]}
                />
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
            </>}
        />
    )
}

ModalDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    dataDetails: PropTypes.object.isRequired,
    project_id: PropTypes.number.isRequired
}

export default ModalDetails