import React, { useEffect, useState } from 'react';
import TableComp from 'components/layouts/Table';
import { faCameraRetro, faFileDownload, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { downloadIncidentFiles } from 'api/operation_standards/incidents_files';

const IncidentVideos = (props) => {
    const { project_id, data, loading, deleteFile } = props;

    const [preview, setPreview] = useState(false);
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState(null);
    const [loadVideo, setLoadVideo] = useState(true);

    useEffect(() => {
        setFiles(data.filter((item) => item.file_type === 'video'));
    }, []);

    const getFile = async (item) => {
        const { file_path, obac_incident_id } = item;
        let response = await downloadIncidentFiles({ project_id, obac_incident_id, file_path });
        setLoadVideo(false);
        return response;
    }
    const handleClick = async (item) => {
        const { file_path } = item;
        const { response } = await getFile(item);
        if (response) {
            const fileURL = URL.createObjectURL(response);
            const link = document.createElement('a')
            link.href = fileURL
            link.setAttribute('download', file_path)
            document.body.appendChild(link)
            link.click();
        }
    }

    const viewVideo = async (item) => {
        const { response } = await getFile(item);
        if (response) setFile(URL.createObjectURL(response));
        else setFile(null)
        setPreview(true);
    }

    return (
        <>
            {
                preview && !loadVideo ? <>
                    <ButtonIcon name='Cerrar vista previa' icon={faTimes} onClick={() => { setPreview(false); setLoadVideo(true); }} variant='link' />
                    {/* Reproducir video */}
                    <video width="100%" height="100%" src={file} controls autoPlay />
                </> : <div className="body_">
                    <TableComp
                        headers={['#', 'Archivo', 'Tipo', 'Acciones']}
                        keys={['#', 'file_path', 'file_type']}
                        body={files}
                        loading={loading}
                        actions={[
                            { icon: faFileDownload, tooltip: 'Descargar archivo', variant: 'outline-primary', handleClick },
                            { icon: faCameraRetro, tooltip: 'Reproducir video', variant: 'outline-success', handleClick: viewVideo },
                            { icon: faTrashAlt, tooltip: 'Eliminar archivo', variant: 'outline-danger', handleClick: deleteFile },
                        ]}
                    />
                </div>
            }
        </>
    )
}

export default IncidentVideos