import FAB from 'components/generales/FAB.jsx';
import moment from 'moment';
import React, { Component } from 'react';
import { OverlayTrigger, Col, Modal, ButtonGroup, ToggleButton, Tab, ListGroup, Spinner, Alert, Tooltip, Button, ModalFooter, Form, Carousel, Row, Container } from 'react-bootstrap';
import { FaCarAlt, FaDolly, FaEdit, FaFileImage, FaMapMarker, FaReadme, FaRocketchat, FaTrailer } from 'react-icons/fa';
import { Variables, httpStatus } from 'variables/Variables';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { __SERVER__ } from 'server/info';
import _ from 'underscore';
import Map from '../Mantemiento/Inventarios/Map';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Chart } from 'react-google-charts';

import BITACORA from './Reports/R_1';
import ATENDIDOS from './Reports/R_2';
import STATUS from './Reports/R_3';
import RECEPCION from './Reports/R_4';
import TOTAL from './Reports/R_5';
import TIEMPO from './Reports/R_6';
import HORA from './Reports/R_7';
import COSTO from './Reports/R_8';
import KILOMETRO from './Reports/R_9';
import CALIFICACION from './Reports/R_10';
import { indexRoadAidByProjectID } from 'api/road_aid/road_aid';
import PaginationComp from 'components/layouts/Pagination';
import ButtonIcon from 'components/layouts/ButtonIcon';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { downloadRoadAidPdf } from 'api/road_aid/reports';
import { Heading } from '@chakra-ui/react';

const notice_form_class = ['Recorrido', 'CCO/JI', 'Pers. Caseta', 'Usuario'];
const status_class = ['En espera', 'Atendiendo', 'Resuelto', 'No resuelto', 'FAKE'];

const FiltroBusquedaGraph = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: '',
            end_date: '',
            status: '',
            graph: 'pieChart',
            type: 'notice_form'
        };
    }

    generateQuery = () => {
        this.props.setProps(this.state)
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <section className="sectionSearch">
                <article>
                    <label>Fecha</label>
                    <input type='date' name="start_date" value={this.state.start_date} onChange={this.handleChange} />
                </article>
                <article>
                    <label>Fecha</label>
                    <input type='date' name="end_date" value={this.state.end_date} onChange={this.handleChange} />
                </article>
                <article>
                    <label>Clasificación</label>
                    <select name="type" defaultValue={this.state.type} onChange={this.handleChange}>
                        <option value={'notice_form'}>Forma de aviso</option>
                        <option value={'service_description'}>Descripción del servicio</option>
                        <option value={'status'}>Estado del reporte</option>
                    </select>
                </article>
                <article>
                    <label>Tipo de gráfica</label>
                    <select name="graph" defaultValue={this.state.graph} onChange={this.handleChange}>
                        <option value={'pieChart'}>Gráfica de pastel</option>
                        <option value={'barChart'}>Gráfica de barras</option>
                    </select>
                </article>
                <article>
                    <button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
                </article>
            </section>
        );
    }
}

const FiltroBusqueda = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: '',
            end_date: '',
            status: '',
            notice_form_id: '',
            service_description_id: '',
            modalReports: false,
        };
    }

    generateQuery = () => {
        this.props.setProps(this.state)
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    openOrCloseModalReports = () => {
        this.setState({ modalReports: !this.state.modalReports });
    }

    render() {
        // console.log("PROPS FILTER: ", this.props)
        let { notice_form, service_description } = this.props;
        return (
            <section className="sectionSearch">
                <article>
                    <label>Fecha</label>
                    <input type='date' name="start_date" value={this.state.start_date} onChange={this.handleChange} />
                </article>
                <article>
                    <label>Fecha</label>
                    <input type='date' name="end_date" value={this.state.end_date} onChange={this.handleChange} />
                </article>
                <article>
                    <label>Estado</label>
                    <select name="status" onChange={this.handleChange} defaultValue={this.state.status}>
                        <option value=''>TODOS</option>
                        <option value={0}>En espera</option>
                        <option value={1}>Atendiendo</option>
                        <option value={2}>Resuelto</option>
                        <option value={3}>No resuelto</option>
                        <option value={4}>FAKE</option>
                    </select>
                </article>
                <article>
                    <label>Tipo aviso</label>
                    <select name="notice_form_id" onChange={this.handleChange}>
                        <option value=''>TODOS</option>
                        {
                            notice_form.map((item) =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                        }
                    </select>
                </article>
                <article>
                    <label>Descripción del servicio</label>
                    <select name="service_description_id" onChange={this.handleChange}>
                        <option value=''>TODOS</option>
                        {
                            service_description.map((item) =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                        }
                    </select>
                </article>
                <article>
                    <button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
                </article>
                <FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faChartLine'} onClick={this.openOrCloseModalReports} />
                {/* <article style={{ padding: 10 }}>
                    <Button onClick={this.openOrCloseModalReports}>Reportes</Button>
                </article> */}

                {/* Modal para mostrar los reportes */}
                {
                    this.state.modalReports ?
                        <ModalReports
                            title={"Reportes: Auxilio Vial"}
                            data={this.props.data}
                            operators={this.props.operators}
                            notice_form={this.props.notice_form}
                            service_description={this.props.service_description}
                            onClose={this.openOrCloseModalReports}
                        /> : null
                }
            </section>
        );
    }
}

const Grid = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: null,
            longitude: null,
            loadingReport: []
        }
    }

    componentDidMount = () => {
        // console.log("Grid mounted...")
    }

    componentDidUpdate = () => {
        console.log("🚀 ~ file: UOV-34.js:194 ~ extends ~ constructor ~ loadingReport:", this.state.loadingReport)
    }
    getOperator = (operator_id) => {
        let { users } = this.props;
        let name = 'Sin asignar';
        let position = _.findIndex(users, { id: operator_id });
        if (position !== -1) {
            name = `${users[position].first_name} ${users[position].last_name}`
        }
        return name;
    }

    getNoticeForm = (id) => {
        let name = 'Indefinido', { notice_form } = this.props;
        let position = _.findIndex(notice_form, { id });
        if (position !== -1) name = notice_form[position].name
        return name;
    }

    getServiceDescription = (id) => {
        let name = 'Indefinido', { service_description } = this.props;
        let position = _.findIndex(service_description, { id });
        if (position !== -1) name = service_description[position].name
        return name;
    }

    downloadPDF = async (item) => {
        this.setState({ loadingReport: [...this.state.loadingReport, { id: item.id, status: true }] });
        const { package_id } = Variables;
        const { response } = await downloadRoadAidPdf({ road_aid_id: item.id, package_id });
        if (response.status === httpStatus.ok) {
            const blob = new Blob([response.data], {
                type: response.data.type
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = item.code + '.pdf';
            link.click();
        }
        this.setState({
            loadingReport: this.state.loadingReport.map((i) => {
                if (i.id === item.id) i.status = false;
                return i;
            })
        });
    }
    getButtonStatus = (item) => {
        let response = false;
        let button = this.state.loadingReport.find((loading) => loading.id === item.id);
        if (button) response = button.status;

        return response;
    }
    render = () => {
        return (
            <table className="tableAssets">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Fecha de reporte</th>
                        <th>Operador asignado</th>
                        <th>Forma de aviso</th>
                        <th>Descripción del servicio</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.data !== null ?
                            this.props.data.map((item, i) =>
                                <tr key={item.code}>
                                    <td>{item.code}</td>
                                    <td>{moment(item.report_date).format('lll')}</td>
                                    <td>{this.getOperator(item.road_aid_operator_id)}</td>
                                    <td>{this.getNoticeForm(item.notice_form_id)}</td>
                                    <td>{this.getServiceDescription(item.service_description_id)}</td>
                                    <td>{status_class[item.status]}</td>
                                    <td>
                                        <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Ver más</Tooltip>}>
                                            <Button variant="outline-success" onClick={e => this.props.setPropsView(i)}><FaEdit /></Button>
                                        </OverlayTrigger>
                                        <ButtonIcon icon={faFilePdf} variant='outline-danger' onClick={() => this.downloadPDF(item)} tooltip='Descargar reporte en PDF'
                                            disabled={this.getButtonStatus(item)} />
                                        {
                                            item.latitude === null ?
                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Ubicación no disponible</Tooltip>}>
                                                    <Button variant="outline-secondary"><FaMapMarker /></Button>
                                                </OverlayTrigger> :
                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Ver ubicación</Tooltip>}>
                                                    <Button variant="outline-danger" onClick={e => this.props.setPropsMap({
                                                        ubications: [{
                                                            title: `${item.code}`,
                                                            name: `${item.report_date} - ${item.body}`,
                                                            position: {
                                                                lat: item.latitude,
                                                                lng: item.longitude
                                                            }
                                                        }]
                                                    })}><FaMapMarker /></Button>
                                                </OverlayTrigger>
                                        }
                                    </td>
                                </tr>
                            ) : null
                    }
                </tbody>
            </table>
        );
    }
}


const ModalMap = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    render = () => {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '600px', width: '100%' }}>
                    <center>
                        <Map
                            ubications={this.props.ubications}
                            center={this.props.ubications[0].position}
                            initialCenter={this.props.ubications[0].position}
                        />
                    </center>
                </Modal.Body>
            </Modal >
        );
    }
}

const ModalReports = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            download_button: false,
            package: []
        }
    }

    componentDidMount = async () => {
        let response = await Axios.get(__SERVER__ + `/packages/${Variables.package_id}/getPackageById`)
        this.setState({ package: response.data.data[0] })
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    render = () => {
        // console.log("PROPS MODAL R: ", this.props)
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: '100%' }}>
                    <Tab.Container id="list-group-tabs-example" defaultActiveKey="#bitacora">
                        <Row>
                            <Col sm={4}>
                                <ListGroup>
                                    <ListGroup.Item action href="#bitacora">BITÁCORA</ListGroup.Item>
                                    <ListGroup.Item action href="#atendidos">REPORTES ATENDIDOS</ListGroup.Item>
                                    <ListGroup.Item action href="#estatus">REPORTES POR ESTATUS</ListGroup.Item>
                                    <ListGroup.Item action href="#recepcion">REPORTES POR FORMA DE RECEPCIÓN</ListGroup.Item>
                                    <ListGroup.Item action href="#turno">TOTAL DE REPORTES</ListGroup.Item>
                                    <ListGroup.Item action href="#tiempo">TIEMPO PROMEDIO DE ATENCIÓN</ListGroup.Item>
                                    <ListGroup.Item action href="#grafico">REPORTES POR HORA</ListGroup.Item>
                                    <ListGroup.Item action href="#costo">COSTO DE ATENCIÓN</ListGroup.Item>
                                    <ListGroup.Item action href="#kilometro">REPORTES POR KILOMETRO Y POR HORA</ListGroup.Item>
                                    <ListGroup.Item action href="#calificacion">CALIFICACIÓN DE CUESTIONARIOS</ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col sm={8}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="#bitacora">
                                        <h1 style={{ color: 'black' }}>Bitácora</h1>
                                        <PDFDownloadLink
                                            document={
                                                <BITACORA
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"BIT_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="sm">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#atendidos">
                                        <h1 style={{ color: 'black' }}>Total de reportes atendidos</h1>
                                        <PDFDownloadLink
                                            document={
                                                <ATENDIDOS
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"ATE_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#estatus">
                                        <h1 style={{ color: 'black' }}>Total de reportes por estatus</h1>
                                        <PDFDownloadLink
                                            document={
                                                <STATUS
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"STA_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#recepcion">
                                        <h1 style={{ color: 'black' }}>Reporte por forma de recepción</h1>
                                        <PDFDownloadLink
                                            document={
                                                <RECEPCION
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"REC_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#turno">
                                        <h1 style={{ color: 'black' }}>Total de reportes y estatus por turno</h1>
                                        <PDFDownloadLink
                                            document={
                                                <TOTAL
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"TUR_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#tiempo">
                                        <h1 style={{ color: 'black' }}>Tiempo promedio de atención</h1>
                                        <PDFDownloadLink
                                            document={
                                                <TIEMPO
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"TIE_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#grafico">
                                        <h1 style={{ color: 'black' }}>Reportes por hora y estatus</h1>
                                        <PDFDownloadLink
                                            document={
                                                <HORA
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"GRA_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#costo">
                                        <h1 style={{ color: 'black' }}>Costo de atención por tipo de reporte</h1>
                                        <PDFDownloadLink
                                            document={
                                                <COSTO
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"COS_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#kilometro">
                                        <h1 style={{ color: 'black' }}>Reportes por kilometro y por hora</h1>
                                        <PDFDownloadLink
                                            document={
                                                <KILOMETRO
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"KIL_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#calificacion">
                                        <h1 style={{ color: 'black' }}>Calificación de cuestionarios</h1>
                                        <PDFDownloadLink
                                            document={
                                                <CALIFICACION
                                                    package={this.state.package}
                                                    data={this.props.data}
                                                    operators={this.props.operators}
                                                    notice_form={this.props.notice_form}
                                                    service_description={this.props.service_description}
                                                />}
                                            fileName={"CAL_" + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime()) + ".pdf"} >
                                            {({ blob, url, loading, error }) => (loading ?
                                                <div><Spinner animation="border" variant="success" />
                                                    <h3>Finalizando reporte</h3>
                                                </div> : <Button variant="outline-success" size="lg">Descargar reporte</Button>
                                            )}</PDFDownloadLink >
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
            </Modal >
        );
    }
}

const ModalView = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            highway_user_email: '',
            highway_user_phone: '',
            observations: null,
            road_aid_operator_id: null,
            buttons_active: [
                'success',
                'secondary',
                'secondary',
                'secondary',
                'secondary',
                'secondary',
                'secondary'],
            vehicle_data: [],
            survey: [],
            survey_questions: [],
            crane_data: [],
            consumable: [],
            evidences: [],
            victum_users: [],
            images: [],
            emergency_services: null
        }
    }

    componentDidMount = () => {
        let { highway_user_email, highway_user_phone, observations, road_aid_operator_id, radio_operator_id } = this.props.data;
        this.setState({
            highway_user_email: highway_user_email !== null ? highway_user_email : '',
            highway_user_phone: highway_user_phone !== null ? highway_user_phone : '',
            observations: observations,
            road_aid_operator_id: road_aid_operator_id,
            radio_operator_id
        });
        this.getEvidences();
        this.getVehicleData();
        this.getSurvey();
        this.getCraneData();
        this.getConsumableData();
        this.getSurveyQuestions();
        this.getEmergencyServices();
        setTimeout(() => this.setState({ show: true }), 100);
    }

    getEmergencyServices = async () => {
        let { id } = this.props.data;
        let response = await Axios.get(`${__SERVER__}/victum_users/road_aid/${id}/emergency_services_called`);
        if (response.status === 200) {
            this.setState({ emergency_services: response.data });
        }
    }
    getVehicleData = async () => {
        let { road_aid_vehicle_data_id } = this.props.data;
        if (road_aid_vehicle_data_id === null) return;
        let vehicle_data = await Axios.get(`${__SERVER__}/victum_users/road_aid/vehicle_data?id=${road_aid_vehicle_data_id}&field=id`);
        if (vehicle_data.status === 200) this.setState({ vehicle_data: vehicle_data.data })
    }

    getConsumableData = async () => {
        let { road_aid_consumable_id } = this.props.data;
        if (road_aid_consumable_id === null) return;
        let consumable = await Axios.get(`${__SERVER__}/victum_users/road_aid/consumable?id=${road_aid_consumable_id}&field=id`);
        if (consumable.status === 200) this.setState({ consumable: consumable.data })
    }

    getSurvey = async () => {
        let { evaluation_status } = this.props.data;
        if (!evaluation_status) return;
        let survey = await Axios.get(`${__SERVER__}/victum_users/road_aid/answers?id=${this.props.data.id}&field=road_aid_id`);
        if (survey.status === 200) this.setState({ survey: survey.data });
    }

    getSurveyQuestions = async () => {
        let survey_questions = await Axios.get(`${__SERVER__}/victum_users/road_aid/questions`);
        if (survey_questions.status === 200) this.setState({ survey_questions: survey_questions.data })
    }

    getCraneData = async () => {
        let { road_aid_crane_id } = this.props.data;
        if (road_aid_crane_id === null) return;
        let crane_data = await Axios.get(`${__SERVER__}/victum_users/road_aid/crane?id=${road_aid_crane_id}&field=id`);
        if (crane_data.status === 200) this.setState({ crane_data: crane_data.data });
    }

    getEvidences = async () => {
        let evidences = await Axios.get(`${__SERVER__}/victum_users/road_aid/evidences?id=${this.props.data.id}&field=road_aid_id`);
        if (evidences.status === 200) this.setState({ evidences: evidences.data });
        evidences.data.map(async (evidence) => {
            await Axios.get(`${__SERVER__}/victum_users/road_aid/evidences/download?road_aid_evidence_id=${evidence.id}`, {
                method: "GET",
                responseType: "blob"
            }).then(response => {
                let image = new Blob([response.data], {
                    type: response.data.type
                });
                let url = window.URL || window.webkitURL;
                image = url.createObjectURL(image);
                this.setState(prevState => {
                    let images = prevState.images;
                    images.push(image);
                    return { images };
                });
            }).catch(error => {
                console.log(error);
            });
        });
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.props.getRoadAid();
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    handleChange = (event) => {
        let { value, name } = event.target;
        this.setState({ [name]: value });
    }

    handleOptionChange = (i) => {
        this.setState(prevState => {
            const buttons_active = prevState.buttons_active.map((item, index) => {
                return i === index ? 'success' : 'secondary'
            })
            return { buttons_active, }
        })
    }

    updateRoadAid = async () => {
        let { highway_user_email, highway_user_phone, observations, road_aid_operator_id, radio_operator_id } = this.state;
        let data = {};
        if (highway_user_email !== '') data.highway_user_email = highway_user_email;
        if (highway_user_phone !== '') data.highway_user_phone = highway_user_phone;
        let position_email = _.findIndex(this.props.victum_users, { email: highway_user_email });
        if (position_email !== -1) {
            data.victum_user_id = this.props.victum_users[position_email].id;
        }
        if (observations) data.observations = observations;
        if (road_aid_operator_id !== 'null') {
            data.road_aid_operator_id = road_aid_operator_id;
            data.status = 1; //Cambiar estado a atendiendo
        } else {
            data.road_aid_operator_id = null;
            data.status = 0; //Cambiar estado a espera
        }


        if (radio_operator_id === null)
            data.radio_operator_id = Variables.user.id;
        let response = await Axios.put(`${__SERVER__}/victum_users/road_aid/${this.props.data.id}`, data);
        if (response.status === 200) {
            Swal.fire('Auxilio vial actualizado', 'La llamada de auxilio ha sido actualizada', 'success');
        } else {
            Swal.fire('Error al actualizar', 'Ocurrió un error al actualizar la información', 'danger');
        }
        this.handleClose();
    }

    getVehicleName = (id) => {
        let { vehicle_types } = this.props;
        let name = 'Desconocido'
        let pos = _.findIndex(vehicle_types, { id });
        if (pos !== -1) name = `${vehicle_types[pos].code} - ${vehicle_types[pos].name}`;
        return name;
    }

    render = () => {
        let { code, report_date, ended_date, arrival_date, body, km,
            notice_form_id, service_description_id, observations, radio_operator_id,
            road_aid_operator_id } = this.props.data;
        let { road_aid_operators, users } = this.props;
        let position_radio = _.findIndex(users, { id: radio_operator_id });
        let name_radio = '';
        if (position_radio !== -1) {
            name_radio = users[position_radio].first_name;
            name_radio += ` ${users[position_radio].last_name}`
        }
        let { vehicle_data, consumable, evidences, survey, crane_data } = this.state;
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{code}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '600px', width: '100%' }}>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Datos generales</Tooltip>}>
                        <Button name={0} onClick={e => this.handleOptionChange(0)} variant={`outline-${this.state.buttons_active[0]}`}><FaReadme /> Principal</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Evidencias</Tooltip>}>
                        <Button name={1} onClick={e => this.handleOptionChange(1)} variant={`outline-${this.state.buttons_active[1]}`}><FaFileImage /> Evidencias</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Datos de vehículo</Tooltip>}>
                        <Button name={2} onClick={e => this.handleOptionChange(2)} variant={`outline-${this.state.buttons_active[2]}`}><FaCarAlt /> Datos de vehículo</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Consumibles</Tooltip>}>
                        <Button name={3} onClick={e => this.handleOptionChange(3)} variant={`outline-${this.state.buttons_active[3]}`}><FaDolly /> Consumibles</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Remolque</Tooltip>}>
                        <Button name={4} onClick={e => this.handleOptionChange(4)} variant={`outline-${this.state.buttons_active[4]}`}><FaTrailer /> Remolque</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Servicios de emergencia</Tooltip>}>
                        <Button name={6} onClick={e => this.handleOptionChange(6)} variant={`outline-${this.state.buttons_active[6]}`}><FaRocketchat /> Servicios de emergencia</Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>Encuesta de calidad</Tooltip>}>
                        <Button name={5} onClick={e => this.handleOptionChange(5)} variant={`outline-${this.state.buttons_active[5]}`}><FaRocketchat /> Encuesta</Button>
                    </OverlayTrigger><br /><hr />

                    {/* Sección 1: Datos generales */}
                    {
                        this.state.buttons_active[0] === "success" ?
                            <Form>
                                <h6>Sección 1. Datos llenados por el Operador de Auxilio Vial</h6>
                                <hr />
                                <Form.Row>
                                    <Col>
                                        <label>Fecha de reporte</label>
                                        <input type="text" className="form-control"
                                            defaultValue={moment(report_date).format('lll')} readOnly />
                                    </Col>
                                    <Col>
                                        <label>Fecha de llegada al lugar</label>
                                        <input type="text" className="form-control"
                                            defaultValue={arrival_date !== null ? moment(arrival_date).format('lll') : 'En espera...'} readOnly />
                                    </Col>
                                    <Col>
                                        <label>Hora de fin de la atención</label>
                                        <input type="text" className="form-control"
                                            defaultValue={ended_date !== null ? moment(ended_date).format('lll') : 'En curso...'} readOnly />
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <label>Kilómetro</label>
                                        <input type="text" className="form-control"
                                            defaultValue={km !== null ? km : 'Desconocido'} readOnly />
                                    </Col>
                                    <Col>
                                        <label>Cuerpo</label>
                                        <input type="text" className="form-control"
                                            defaultValue={body !== null ? body : 'Desconocido'} readOnly />
                                    </Col>
                                    <Col>
                                        <label>Forma de aviso</label>
                                        <select className="form-control" defaultValue={notice_form_id} readOnly>
                                            {
                                                this.props.notice_form.map((item) =>
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )
                                            }
                                        </select>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <label>Descripción del servicio</label>
                                        <select className="form-control" defaultValue={service_description_id} readOnly>
                                            {
                                                this.props.service_description.map((item) =>
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                )
                                            }
                                        </select>
                                    </Col>
                                    <Col>
                                        <label>Correo electrónico [Opcional]</label>
                                        <input type="email" className="form-control" name="highway_user_email" onChange={this.handleChange}
                                            defaultValue={this.state.highway_user_email} />
                                    </Col>
                                    <Col>
                                        <label>Teléfono [Opcional]</label>
                                        <input type="phone" className="form-control" name="highway_user_phone" onChange={this.handleChange}
                                            defaultValue={this.state.highway_user_phone} />
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    {
                                        radio_operator_id !== null ?
                                            <Col>
                                                <label>Radioperador</label>
                                                <input type="text" className="form-control" defaultValue={name_radio} readOnly />
                                            </Col> : null
                                    }

                                    <Col>
                                        <label>Agente de auxilio vial asignado</label>
                                        <select className="form-control" onChange={this.handleChange} name="road_aid_operator_id"
                                            defaultValue={road_aid_operator_id !== null ? road_aid_operator_id : null}>
                                            <option value="null">Sin asignar</option>
                                            {
                                                road_aid_operators !== null ?
                                                    road_aid_operators.map((user) =>
                                                        <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
                                                    ) : null
                                            }
                                        </select>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <label>Observaciones</label>
                                        <textarea className="form-control" name="observations" onChange={this.handleChange}
                                            defaultValue={observations !== null ? observations : ''} />
                                    </Col>
                                </Form.Row>
                            </Form> : null
                    }

                    {/* Sección 2: Evidencias */}
                    {
                        this.state.buttons_active[1] === "success" ?
                            evidences.length > 0 ?
                                <Carousel>

                                    {
                                        evidences.map((evidence, index) =>
                                            <Carousel.Item key={index} style={{ height: '450px', width: '100%' }}>
                                                <img
                                                    className="d-block w-100"
                                                    src={this.state.images.length > 0 ? this.state.images[index] : null}
                                                    alt="Obteniendo registros"
                                                />
                                                <Carousel.Caption>
                                                    <h3>Evidencia de auxilio vial {index + 1}</h3>
                                                    <p>{moment(evidence.created_at).format('llll')}</p>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        )
                                    }
                                </Carousel> :
                                <Alert variant="info">
                                    <Alert.Heading>No hay evidencias registradas</Alert.Heading>
                                    <p>
                                        La evidencias se podrán ver cuando el usuario de Auxilio Vial las cargue desde la aplicación
                                        "Victum Usuarios".
                                    </p>
                                </Alert> : null
                    }

                    {/* Sección 3: Datos de vehículo */}
                    {
                        this.state.buttons_active[2] === "success" ?
                            this.props.data.road_aid_vehicle_data_id === null ?
                                <Alert variant="info">
                                    <Alert.Heading>No hay datos de vehículos</Alert.Heading>
                                    <p>
                                        El usuario de Auxilio Vial debe capturar estos datos desde la
                                        aplicación "Victum Usuarios".
                                    </p>
                                </Alert> :
                                <Form>
                                    <Form.Row>
                                        <Col>
                                            <label>Tipo de vehículo</label>
                                            <input type="text" className="form-control" readOnly
                                                value={vehicle_data.length > 0 ? this.getVehicleName(vehicle_data[0].vehicle_type_id) : '...'} />
                                        </Col>
                                        <Col>
                                            <label>Placa</label>
                                            <input type="text" className="form-control" readOnly
                                                value={vehicle_data.length > 0 ? vehicle_data[0].plate : '...'} />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <label>Marca</label>
                                            <input type="text" className="form-control" readOnly
                                                value={vehicle_data.length > 0 ? vehicle_data[0].brand : '...'} />
                                        </Col>
                                        <Col>
                                            <label>Modelo</label>
                                            <input type="text" className="form-control" readOnly
                                                value={vehicle_data.length > 0 ? vehicle_data[0].model : '...'} />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <label>Origen</label>
                                            <input type="text" className="form-control" readOnly
                                                value={vehicle_data.length > 0 ? vehicle_data[0].origin : '...'} />
                                        </Col>
                                        <Col>
                                            <label>Destino</label>
                                            <input type="text" className="form-control" readOnly
                                                value={vehicle_data.length > 0 ? vehicle_data[0].destination : '...'} />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <label>Observaciones</label>
                                            <textarea className="form-control" readOnly
                                                value={vehicle_data.length > 0 ? vehicle_data[0].observations : ''} />
                                        </Col>
                                    </Form.Row>
                                </Form>
                            : null
                    }

                    {/* Sección 4: Consumibles */}
                    {
                        this.state.buttons_active[3] === "success" ?
                            this.props.data.road_aid_consumable_id === null ?
                                <Alert variant="info">
                                    <Alert.Heading>No hay consumibles registrados</Alert.Heading>
                                    <p>
                                        La razón es porque no se utilizaron consumibles o el usuario de Auxilio Vial
                                        aún no los captura en la aplicación "Victum Usuarios".
                                    </p>
                                </Alert> :
                                <Form>
                                    <Form.Row>
                                        <Col>
                                            <label>Tipo de consumible</label>
                                            <input type="text" className="form-control" readOnly
                                                value={consumable.length > 0 ? consumable[0].consumable_type : '...'} />
                                        </Col>
                                        <Col>
                                            <label>Vendido por pers. de caseta</label>
                                            <input type="text" className="form-control" readOnly
                                                value={consumable.length > 0 ? consumable[0].sold_by_tollbooth === 0 ? 'No' : 'Sí' : '...'} />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <label>Cantidad</label>
                                            <input type="text" className="form-control" readOnly
                                                value={consumable.length > 0 ? consumable[0].quantity : '...'} />
                                        </Col>
                                        <Col>
                                            <label>Importe total pagado</label>
                                            <input type="text" className="form-control" readOnly
                                                value={consumable.length > 0 ? consumable[0].total_amount_paid : '...'} />
                                        </Col>
                                    </Form.Row>
                                </Form>
                            : null
                    }

                    {/* Sección 5: Remolque */}
                    {
                        this.state.buttons_active[4] === "success" ?
                            this.props.data.road_aid_crane_id === null ?
                                <Alert variant="info">
                                    <Alert.Heading>No hay datos de grúas involucradas</Alert.Heading>
                                    <p>
                                        La razón es porque no se utilizaron grúas o el usuario de Auxilio Vial
                                        aún no los captura en la aplicación "Victum Usuarios".
                                    </p>
                                </Alert> :
                                <Form>
                                    <Form.Row>
                                        <Col>
                                            <label>La grúa fue cotizada por pers. de caseta</label>
                                            <input type="text" className="form-control" readOnly
                                                value={crane_data.length > 0 ? crane_data[0].crane_quoted_by_tollbooth === 0 ? 'No' : 'Sí' : '...'} />
                                        </Col>
                                        <Col>
                                            <label>Importe pagado</label>
                                            <input type="text" className="form-control" readOnly
                                                value={crane_data.length > 0 ? crane_data[0].total_amount_paid : '...'} />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <label>Destino de traslado</label>
                                            <input type="text" className="form-control" readOnly
                                                value={crane_data.length > 0 ? crane_data[0].transfer_destination : '...'} />
                                        </Col>
                                        <Col>
                                            <label>Proveedor</label>
                                            <input type="text" className="form-control" readOnly
                                                value={crane_data.length > 0 ? crane_data[0].provider : '...'} />
                                        </Col>
                                    </Form.Row>
                                </Form>
                            : null
                    }

                    {/* Sección 6: Encuesta de calidad */}
                    {
                        this.state.buttons_active[5] === "success" ?
                            !this.props.data.evaluation_status ?
                                <Alert variant="info">
                                    <Alert.Heading>Encuesta de calidad</Alert.Heading>
                                    <p>
                                        El usuario de autopista aún no ha respondido la encuesta de calidad.
                                    </p>
                                </Alert> :
                                <Form>
                                    {
                                        this.state.survey_questions.map((question, index) =>
                                            <Form.Row key={index}>
                                                <Col>
                                                    <label>{question.question}</label>
                                                    <input type="text" className="form-control" readOnly
                                                        value={survey.length > 0 ? survey[index].answer : '...'} />
                                                </Col>
                                            </Form.Row>
                                        )
                                    }
                                </Form>
                            : null
                    }

                    {/* Servicios de emergencia */}
                    {
                        this.state.buttons_active[6] === 'success' ?
                            this.state.emergency_services === null ?
                                <p>Cargando datos...</p> : //Implementar un spinner de carga
                                this.state.emergency_services.length > 0 ?
                                    <Container>
                                        <h1 style={{ color: 'black' }}>Servicios de emergencia llamados</h1>
                                        <ListGroup>
                                            {
                                                this.state.emergency_services.map((item, index) =>
                                                    <ListGroup.Item key={index}>{item.name}</ListGroup.Item>
                                                )
                                            }
                                        </ListGroup>
                                    </Container>

                                    : <Alert variant="info">
                                        <Alert.Heading>Servicios de emergencia</Alert.Heading>
                                        <p>
                                            No hay servicios de emergencia registrados.
                                        </p>
                                    </Alert>
                            : null
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={this.handleClose}>Cancelar</Button>
                    <Button variant="outline-success" onClick={this.updateRoadAid}>Actualizar información</Button>
                </Modal.Footer>
            </Modal >
        );
    }
}

const ModalAdd = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            //Form data
            notice_form_id: '',
            service_description_id: '',
            km: 0,
            metro: 0,
            body: 'A',
            road_aid_operator_id: null,
            observations: null,
            highway_user_email: null,
            highway_user_phone: null
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.props.getRoadAid();
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    validateFields = () => {
        let validate = true;
        let { notice_form_id, service_description_id, km, metro, body, road_aid_operator_id } = this.state;
        if (notice_form_id === '') validate = false;
        if (service_description_id === '') validate = false;
        if (km === '') validate = false;
        if (body === '') validate = false;
        if (road_aid_operator_id === null) validate = false;
        if (metro < 0) validate = false;
        if (metro > 999) validate = false;
        return validate;
    }

    getCode = (notice_form_id) => {
        let code = '';
        notice_form_id = parseInt(notice_form_id);
        switch (notice_form_id) {
            case 1: code = 'REC_'; break;
            case 2: code = 'CCO_'; break;
            case 3: code = 'CAS_'; break;
            case 4: code = 'USU_'; break;
            case 5: code = 'EMA_'; break;
            case 6: code = 'WEB_'; break;
            case 7: code = 'SOS_'; break;
            default: code = 'UNK_'; break;
        }
        return code + (((new Date()).getFullYear()) - 2000) + '_' + ((new Date()).getTime());
    }
    saveRoadAid = async () => {
        if (!this.validateFields()) {
            Swal.fire('Advertencia', 'Complete los campos del formulario', 'warning');
            return;
        }
        let { km, metro, body, notice_form_id, service_description_id,
            observations, road_aid_operator_id, highway_user_email,
            highway_user_phone } = this.state;
        let data = {
            code: this.getCode(notice_form_id),
            project_id: Variables.project_id,
            km: `${km}+${metro === 0 ? '000' : metro}`,
            body,
            notice_form_id,
            service_description_id,
            observations,
            highway_user_email,
            highway_user_phone,
            road_aid_operator_id,
            radio_operator_id: Variables.user.id,
            status: 1 /* Atendiendo */
        };
        let response = await Axios.post(`${__SERVER__}/victum_users/road_aid`, data);
        if (response.status === 200) {
            Swal.fire('Auxilio vial asignado', 'La llamada de auxilio ya está siendo atendida', 'success');
        } else {
            Swal.fire('Error al guardar', 'Ocurrió un error al guardar la información', 'danger');
        }
        this.handleClose();
    }

    render = () => {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Col>
                                <label>Forma de aviso</label>
                                <select className={"form-control"} name="notice_form_id" onChange={this.handleChange}>
                                    <option value=""></option>
                                    {
                                        this.props.notice_form.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    }
                                </select>
                            </Col>
                            <Col>
                                <label>Descripción del servicio</label>
                                <select className={"form-control"} name="service_description_id" onChange={this.handleChange}>
                                    <option value=""></option>
                                    {
                                        this.props.service_description.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    }
                                </select>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <label>Kilómetro</label>
                                <input type="number" className="form-control" name="km" onChange={this.handleChange} min={0} />
                            </Col>
                            <Col>
                                <label>Metro</label>
                                <input type="number" placeholder={'000'} min={0} max={999} className="form-control" name="metro" onChange={this.handleChange} />
                            </Col>
                            <Col>
                                <label>Cuerpo</label>
                                <select className="form-control" defaultValue="A" name="body" onChange={this.handleChange}>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                </select>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <label>Asignar llamada de auxilio a:</label>
                            <select className="form-control" name="road_aid_operator_id" defaultValue={null} onChange={this.handleChange}>
                                <option value={null}></option>
                                {
                                    this.props.road_aid_operators.map((operator) =>
                                        <option key={operator.id} value={operator.id}>{operator.first_name} {operator.last_name}</option>
                                    )
                                }
                            </select>
                        </Form.Row>
                        <Form.Row>
                            <label>Observaciones [Opcional]</label>
                            <textarea className={"form-control"} name="observations" onChange={this.handleChange} />
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <label>Correo electrónico [Opcional]</label>
                                <input type="email" className="form-control" name="highway_user_email" onChange={this.handleChange} min={0} />
                            </Col>
                            <Col>
                                <label>Teléfono [Opcional]</label>
                                <input type="phone" className="form-control" name="highway_user_phone" onChange={this.handleChange} />
                            </Col>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <ModalFooter>
                    <Button variant="outline-secondary" onClick={this.handleClose}>Cancelar</Button>
                    <Button variant="outline-success" onClick={this.saveRoadAid}>Registrar auxilio</Button>
                </ModalFooter>
            </Modal >
        );
    }
}

const ModalUpload = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            uploading: false,
            file: null,
            status: 0
        }
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ show: true }), 100);
    }

    handleClose = () => {
        if (this.state.uploading) {
            Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
            return;
        }
        this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
    }

    handleFileChange = (event) => {
        this.setState({ file: event.target.files[0] });
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    save = async (e) => {
        e.preventDefault();
        if (this.state.file === null) {
            Swal.fire('Error', '¡No hay un archivo seleccionado!');
        } else {
            // crear un objeto de formData
            const formData = new FormData();
            // Actualizar el objeto formData
            formData.append("file", this.state.file);
            formData.append("status", this.state.status);
            // Detalles del archivo cargado
            // console.log(this.state.file);
            // Petición al backend, enviar el objeto formData
            await Axios.post(`${__SERVER__}/victum_users/road_aid/1/evidences`, formData);
            this.handleClose();
        }
    }

    render = () => {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>En Construcción</p>
                </Modal.Body>
            </Modal >
        );
    }
}
const Loader = () => {
    return (
        <div>
            <Spinner animation="border" variant="light" />
            <p style={{ color: 'white' }}>Obteniendo datos...</p>
        </div>
    );
}
const Main = class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            graph: 0,
            width: '92vw',
            height: '100vh'
        }
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render = () => {
        return (
            <>
                <div style={{ display: 'flex' }}>
                    {
                        this.props.graph === 'pieChart' ?
                            this.props.uploading ?
                                <Loader /> :
                                <Chart
                                    width={this.state.width}
                                    height={this.state.height}
                                    chartType="PieChart"
                                    loader={<div>
                                        <Spinner animation="border" variant="light" />
                                        <p style={{ color: 'white' }}>Cargando datos</p>
                                    </div>}
                                    data={[
                                        [this.props.title, "Reportes"], ...this.props.data
                                    ]}
                                    options={{
                                        title: this.props.title
                                    }}
                                /> : null
                    }

                    {
                        this.props.graph === 'barChart' ?
                            this.props.uploading ?
                                <Loader /> :
                                <Chart
                                    width={this.state.width}
                                    height={this.state.height}
                                    chartType="BarChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        [this.props.title, "Reportes"], ...this.props.data
                                    ]}
                                    options={{
                                        title: this.props.title,
                                        chartArea: { width: '50%' },
                                        hAxis: {
                                            title: 'Cantidad de reportes',
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: this.props.title,
                                        },
                                    }}
                                /> : null
                    }

                </div>
            </>
        );
    }
}
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAdd: false,
            modalUpload: false,
            modalMap: false,
            modalView: false,

            option_select: 0,
            options: ['Principal', 'Datos de Auxilio Vial'],

            data: [], //Datos de auxilio vial
            totalItems: 0,
            page: 1,
            pageSize: 10,
            type: 'notice_form',
            title: {
                notice_form: 'Forma de aviso',
                service_description: 'Descripción del servicio',
                status: 'Estado del reporte'
            },
            dataGraph: [], //Datos para gráfica de pastel
            dataGraph_ulpoading: true, //Cargando datos de gráficos
            graph: 'pieChart', //Tipo de gráfica

            users: [], //Usuarios del paquete
            victum_users: [], //Usuarios de autopista
            road_aid_operators: [], //Operadores de auxilio vial
            vehicle_types: [], //Tipos de vehículo

            start_date: '', //Fecha de inicial de consulta
            end_date: '', //Fecha final de consulta

            status: '', //Estatus por default en creado
            notice_form: [], //Forma de aviso
            notice_form_id: '', //Identificador de forma de aviso. Default CCO/JI

            service_description: [], //Descripción del servicio
            service_description_id: '', //Identificador de descripción del servicio

            ubications: [], //Ubicaciones para mostrar en el modal map 
            item_selected: -1, //Item de auxilio vial seleccionado
        }
    }

    componentDidMount = async () => {
        await this.getNoticeForm();
        await this.getServiceDescription();
        await this.getVehicleTypes();
        await this.getRoadOperators();
        await this.getUsers();
        await this.getVictumUsers();
        await this.getRoadAid();
    }

    getVehicleTypes = async () => {
        let response = await Axios.get(`${__SERVER__}/packages/${Variables.package_id}/vehicle_types`);
        if (response.status === 200) this.setState({ vehicle_types: response.data });
    }

    getServiceDescription = async () => {
        let service_description = await Axios.get(`${__SERVER__}/victum_users/road_aid/service_description`);
        if (service_description.status === 200) {
            this.setState({ service_description: service_description.data });
        }
    }

    getNoticeForm = async () => {
        let notice_form = await Axios.get(`${__SERVER__}/victum_users/road_aid/notice_form`);
        if (notice_form.status === 200) {
            this.setState({ notice_form: notice_form.data });
        }
    }

    getVictumUsers = async () => {
        let victum_users = await Axios.get(`${__SERVER__}/victum_users`);
        if (victum_users.status === 200) this.setState({ victum_users: victum_users.data });
    }

    getUsers = async () => {

        let users = await Axios.get(`${__SERVER__}/packages/${Variables.package_id}/users`);
        if (users.status === 200) {
            this.setState({ users: users.data });
        } else {
            Swal.fire('Advertencia', 'No se puede obtener la información de los usuarios', 'warning');
        }
    }

    getRoadOperators = async () => {
        let road_operators = await Axios.get(`${__SERVER__}/project/${Variables.project_id}/road_aid_operators`);
        if (road_operators.status === 200) {
            this.setState({ road_aid_operators: road_operators.data });
        } else {
            Swal.fire('Advertencia', 'Ocurri+o un error al obtener la lista de los operadores de auxilio vial.', 'warning');
        }
    }

    getRoadAid = async () => {
        this.setState({ dataGraph_ulpoading: true });
        const { project_id } = Variables;
        let { start_date, end_date, status, notice_form_id, service_description_id } = this.state;
        let query = { start_date, end_date, status, notice_form_id, service_description_id };
        const { totalItems, data } = await indexRoadAidByProjectID({ project_id, query });
        this.setState({ data, totalItems });
        this.getdataGraph();
    }

    getdataGraph = () => {
        let nf = [], { data, type } = this.state;
        this.setState({ dataGraph: [] });
        if (type === 'status') {
            data.map((item) => {
                let id = _.findIndex(nf, { id: item.status });
                if (id === -1) {
                    nf.push({
                        id: item.status,
                        name: status_class[item.status],
                        count: 1
                    })
                } else {
                    nf[id].count += 1;
                }
            });
        } else {
            data.map((item) => {
                let id = _.findIndex(nf, { id: item[`${type}_id`] });
                if (id === -1) {
                    let pos = _.findIndex(this.state[type], { id: item[`${type}_id`] });
                    nf.push({
                        id: item[`${type}_id`],
                        name: pos !== -1 ? this.state[type][pos].name : 'unknow',
                        count: 1
                    })
                } else {
                    nf[id].count += 1;
                }
            });
        }
        nf.map((item) => {
            this.setState(prevState => {
                const dataGraph = prevState.dataGraph;
                dataGraph.push([item.name, item.count]);
                return { dataGraph };
            });
        });
        this.setState({ dataGraph_ulpoading: false });
    }

    setProps = (query) => {
        let {
            start_date,
            end_date,
            status,
            notice_form_id,
            service_description_id
        } = query;
        this.setState({
            data: [],
            start_date,
            end_date,
            notice_form_id,
            service_description_id,
            status
        }, this.getRoadAid)
    }

    setPropsGraph = (query) => {
        let {
            start_date,
            end_date,
            status,
            graph,
            type
        } = query;
        this.setState({
            data: [],
            start_date,
            end_date,
            status,
            graph,
            type
        }, this.getRoadAid)
    }

    setPropsMap = (query) => {
        let { ubications } = query;
        this.setState({
            ubications
        }, this.openOrCloseModalMap);
    }

    setPropsView = (item_selected) => {
        this.setState({
            item_selected
        }, this.openOrCloseModalView);
    }

    openOrCloseModalAdd = () => {
        this.setState({ modalAdd: !this.state.modalAdd });
    }

    openOrCloseModalUpload = () => {
        this.setState({ modalUpload: !this.state.modalUpload });
    }

    openOrCloseModalMap = () => {
        this.setState({ modalMap: !this.state.modalMap });
    }

    openOrCloseModalView = () => {
        this.setState({ modalView: !this.state.modalView });
    }

    changePage = (page, pageSize) => this.setState({ page, pageSize });

    render = () => {
        let { item_selected, users, data, road_aid_operators, notice_form, service_description,
            dataGraph, dataGraph_ulpoading, graph, type, title, vehicle_types } = this.state;
        return (
            <div className="body_">
                <Heading className='tituloSub'>{this.props.code + " " + this.props.name}</Heading>
                <div style={{ background: '#1a2530', width: '100%', paddingLeft: 10, paddingBottom: 10, marginTop: -10, paddingTop: 10 }}>
                    <ButtonGroup toggle>
                        {
                            this.state.options.map((item, i) =>
                                <ToggleButton
                                    key={i}
                                    type="radio"
                                    variant="secondary"
                                    name="radio"
                                    style={{ background: this.state.option_select === i ? "#FFF" : '#2d4050', color: this.state.option_select === i ? '#2d4050' : "#FFF" }}
                                    value={i}
                                    checked={this.state.option_select === i}
                                    onChange={(e) => this.setState({ option_select: i })}>
                                    {item}
                                </ToggleButton>
                            )
                        }
                    </ButtonGroup>
                </div>
                {
                    this.state.option_select === 0 ?
                        <><FiltroBusquedaGraph
                            setProps={this.setPropsGraph}
                            data={data}
                        />
                            {
                                notice_form.length > 0 && service_description.length > 0 ?
                                    <Main
                                        data={dataGraph}
                                        type={type}
                                        uploading={dataGraph_ulpoading}
                                        title={title[type]}
                                        graph={graph}
                                    />
                                    : null
                            }</> : null
                }

                {
                    this.state.option_select === 1 ?
                        <><FiltroBusqueda
                            setProps={this.setProps}
                            data={data}
                            operators={users}
                            notice_form={notice_form}
                            service_description={service_description}
                        />
                            <Grid
                                data={data}
                                users={users}
                                notice_form={notice_form}
                                service_description={service_description}
                                setPropsMap={this.setPropsMap}
                                setPropsView={this.setPropsView}
                            />
                            <PaginationComp
                                current={this.state.page}
                                totalItems={this.state.totalItems}
                                onChange={this.changePage}
                                defaultPageSize={this.state.pageSize}
                            />
                            <FAB style={{ position: 'fixed', bottom: 130, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModalAdd} />
                            <FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faUpload'} onClick={this.openOrCloseModalUpload} />

                            {/* Modal para levantar auxilio vial */}
                            {
                                this.state.modalAdd ?
                                    <ModalAdd
                                        title={"Atender llamada de auxilio vial"}
                                        getRoadAid={this.getRoadAid}
                                        road_aid_operators={road_aid_operators}
                                        service_description={service_description}
                                        notice_form={notice_form}
                                        onClose={this.openOrCloseModalAdd}
                                    /> : null
                            }
                            {/* Modal para cargar datos desde hoja de cálculo */}
                            {
                                this.state.modalUpload ?
                                    <ModalUpload
                                        title={"Cargar datos de auxilio vial"}
                                        onClose={this.openOrCloseModalUpload}
                                    /> : null
                            }

                            {/* Modal para mostrar ubicación del auxilio vial */}
                            {
                                this.state.modalMap ?
                                    <ModalMap
                                        title={"Ubicación del Auxilio Vial"}
                                        ubications={this.state.ubications}
                                        onClose={this.openOrCloseModalMap}
                                    /> : null
                            }

                            {/* Modal para visualizar la información */}
                            {
                                this.state.modalView && data.length > 0 ?
                                    <ModalView
                                        title={"Vista previa"}
                                        data={data[item_selected]}
                                        users={users}
                                        vehicle_types={vehicle_types}
                                        service_description={service_description}
                                        notice_form={notice_form}
                                        victum_users={this.state.victum_users}
                                        getRoadAid={this.getRoadAid}
                                        road_aid_operators={road_aid_operators}
                                        onClose={this.openOrCloseModalView}
                                    /> : null
                            }</> : null
                }
            </div>
        );
    }
}