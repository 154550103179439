import { Result, Skeleton } from 'antd';
import { showMaintenanceStandardDashboard } from 'api/dashboards/maintenance_standards';
import React, { useEffect, useState } from 'react'

const Dashboard = (props) => {
    const { project_id, component } = props;

    const [urlDashboard, setUrlDashboard] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDashboard();
    }, []);

    const getDashboard = async () => {
        setLoading(true);
        let response = await showMaintenanceStandardDashboard({ project_id, component });
        if (response) setUrlDashboard(response.url);
        setLoading(false);
    }

    return (
        <div className='body_' style={{ backgroundColor: '#fff', height: '100vh' }}>
            {
                loading && !urlDashboard && <Skeleton />
            }
            {
                !loading && !urlDashboard &&
                <Result
                    status="404"
                    title="Sin dashboards"
                    subTitle="Actualmente no tiene una dashboard para este estándar de mantenimiento. Contacte al administrador para obtener el dashboard."
                    extra={null}
                />
            }
            {
                !loading && urlDashboard &&
                <iframe
                    src={urlDashboard}
                    frameborder="0"
                    width="100%"
                    height='100%'
                    allowtransparency
                />
            }
        </div>
    )
}

export default Dashboard