import React, { useEffect, useState } from 'react'
import CarouselComponent from 'components/layouts/Carousel'
import moment from 'moment';
import { downloadIncidentFiles } from 'api/operation_standards/incidents_files';

const IncidentPhotos = (props) => {
    const { data, project_id } = props;

    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];

        const getPhotos = async () => {
            // Obtener únicamente las evidencias de tipo picture
            let files = data.filter((file) => file.file_type === 'picture');
            setCount(files.length);
            for (const element of files) {
                if (cancelLoop) break;
                const { file_path, created_at, obac_incident_id } = element;
                const { response, cancel } = await downloadIncidentFiles({ project_id, obac_incident_id, file_path });
                cancelTokens.push({ cancel });
                if (response) {
                    let url = window.URL || window.webkitURL;
                    let image = url.createObjectURL(response);
                    setCurrentCount(currentCount => currentCount + 1);
                    setPhotos(photos => [...photos, {
                        image,
                        created_at: `Evidencia capturada ${moment(created_at).format('LLLL')}`
                    }]);
                    setLoading(false);
                }
            }
            setLoading(false);
        }

        getPhotos();

        return () => {
            cancelLoop = true;  // cancelar la petición del resto de las fotografías
            cancelTokens.forEach((token) => token.cancel()); // cancelar cada uno de los tokens creados
        }
    }, []);



    return (
        <>
            {
                count > 0 &&
                <p>Obteniendo {currentCount}/{count} fotografía{count === 1 ? '' : 's'}...</p>
            }
            <CarouselComponent
                evidences={photos}
                loading={loading}
            />
        </>
    )
}

export default IncidentPhotos