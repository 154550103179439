import React from 'react'
import LoadingDoc from './LoadingDoc'
import EjectuvoPdf from './EjectuvoPdf'
import { FaFilePdf } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { notification, Skeleton } from 'antd'
import { Variables } from "variables/Variables"
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer'
import { indexIncidents } from 'api/maintenance_standards/incidents'
import { Alert, Button, Col, Form, Jumbotron, Row } from 'react-bootstrap'
import { getMaintenanceStandards } from 'api/maintenance_standards/getMaintenanceStandards'
import { indexMaintenanceActivities } from 'api/maintenance_standards/maintenance_activities'
import { getProjects } from 'api/general/getProjects'
import { Heading } from '@chakra-ui/react'

const Ejecutivo = () => {
    const project_id = Variables.project_id
    const [Filters, setFilters] = useState(1)
    const [activity, setActivity] = useState([])
    const [loading, setLoading] = useState(true)
    const [Project, setProject] = useState(null)
    const [Incidents, setIncidents] = useState(null)
    const [loadingIncidents, setLoadingIncidents] = useState(true)
    const [date, setDate] = useState({
        creation_dateS: '',
        creation_dateE: '',
    });

    useEffect(() => { getData() }, [])
    useEffect(() => { getDataIncidents() }, [Filters])

    const getDataIncidents = async () => {
        let objectParams = (date.creation_dateE !== '') ? { ...date, project_id } : { project_id }
        console.log(objectParams)
        const { data } = await indexIncidents(objectParams)
        setIncidents(data)
        setLoadingIncidents(false)
    }

    const getData = async () => {
        const standars = await getMaintenanceStandards();
        let standars_id = standars.map(item => item.id)
        const Projects = await getProjects()
        const filteredProject = Projects.filter(p => p.id === Variables.project_id);
        setProject(filteredProject[0])
        getAct(standars_id);
    }

    const getAct = async standars_id => {
        const promises = standars_id.map(maintenance_standard_id =>
            indexMaintenanceActivities({ project_id, maintenance_standard_id })
        );

        const responses = await Promise.all(promises);
        const data = responses
            .filter(response => response.data.length > 0)
            .map(response => response.data);

        setActivity([].concat(...data));
        setLoading(false);
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setDate({
            ...date,
            [name]: value
        });
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Importante',
            description: 'El rango de fechas es obligatorio'
        });
    };

    const handleDate = () => {
        if (date.creation_dateE === '' || date.creation_dateS === '') {
            openNotificationWithIcon('info')
        } else {
            setLoadingIncidents(true)
            setFilters(Filters + 1)
        }
    }

    return (
        <Jumbotron style={{ marginTop: 10, marginLeft: 10 }} >
            {contextHolder}
            <Form>
                <Heading color={'black'}>Reporte Ejecutivo</Heading>
                <Row>
                    <Col>
                        <Alert variant="info">
                            <Alert.Heading>Información</Alert.Heading>
                            <p>En esta sección se crean los reportes mensuales Ejecutivo. El rango de fechas se puede ajustar para los incidentes</p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Fecha inicial</label>
                        <input type="date" name="creation_dateS" onChange={handleChange} className="form-control" />
                    </Col>
                    <Col>
                        <label>Fecha final</label>
                        <input type="date" name="creation_dateE" onChange={handleChange} className="form-control" />
                    </Col>
                    <Button onClick={handleDate}>Ajustar fecha</Button>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        {(!loading && !loadingIncidents) ?
                            <>
                                <PDFDownloadLink document={<EjectuvoPdf Project={Project} Incidents={Incidents} activity={activity} />} fileName="Reporte_Ejecutivo.pdf">
                                    {({ loading }) =>
                                        loading ? <LoadingDoc /> : (<Button className="mx-2" variant="success" ><FaFilePdf className="mr-1" />Reporte Listo para descargar</Button>)}
                                </PDFDownloadLink>

                                <BlobProvider document={<EjectuvoPdf Project={Project} Incidents={Incidents} activity={activity} />}>
                                    {({ url, loading }) =>
                                        loading ? <LoadingDoc /> : (<a className="text-white btn btn-primary" href={url} target="_blank" rel="noopener noreferrer"> <ExternalLinkIcon className="mr-1" /> Abrir reporte en una nueva ventana
                                        </a>
                                        )}
                                </BlobProvider>
                            </>
                            : <Skeleton active className='mt-3' />}
                    </Col>
                </Row>
            </Form>
        </Jumbotron>
    )
}

export default Ejecutivo