import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexWorkOrders = async ({ package_id, segment_id, standard_id, ...params }) => {
    const url = `/packages/${package_id}/segment/${segment_id}/standard/${standard_id}/work_order`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: work_orders.js:13 ~ indexWorkOrders ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response.data = fetch.data;
            response.totalItems = fetch.data.length;
        }
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}

export const storeWorkOrders = async ({ package_id, segment_id, standard_id, data }) => {
    const url = `/packages/${package_id}/segment/${segment_id}/standard/${standard_id}/work_order`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:33 ~ storeWorkOrders ~ fetch:", fetch)
        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok)
            response = fetch.data
        return { response };
    } catch (error) {
        return { response };
    }
}
export const indexWorkOrdersReport = async ({ package_id, segment_id, standard_id, work_order_id, ...params }) => {
    const url = `/packages/${package_id}/segment/${segment_id}/standard/${standard_id}/work_order/${work_order_id}/report`;
    let response = false;
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: work_orders.js:32 ~ showWorkOrdersReport ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data;
        }
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}

export const indexPumCostWorkOrders = async ({ package_id, ...params }) => {
    const url = `/packages/${package_id}/pum_cost_work_order`;
    let response = {};
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: work_orders.js:70 ~ indexPumCostWorkOrders ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data[0];
        }
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}

export const storeWorkOrderSupplies = async ({ package_id, segment_id, standard_id, work_order_id, data }) => {
    const url = `/packages/${package_id}/segment/${segment_id}/standard/${standard_id}/work_order/${work_order_id}/supplies`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:108 ~ storeWorkOrderSupplies ~ fetch:", fetch)
        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok) {
            response = fetch.data
        } else {
            throw fetch.statusText;
        }
        return { response };
    } catch (error) {
        return { response, error };
    }
}

export const updatePumCostWorkOrders = async ({ package_id, data }) => {
    const url = `/packages/${package_id}/pum_cost_work_order`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:129 ~ updatePumCostWorkOrders ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data
        } else {
            throw fetch.statusText;
        }
        return { response };
    } catch (error) {
        return { response, error };
    }
}

export const updateWorkOrders = async ({ package_id, segment_id, standard_id, work_order_id, data }) => {
    const url = `/packages/${package_id}/segment/${segment_id}/standard/${standard_id}/work_order/${work_order_id}`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:150 ~ updatePumCostWorkOrders ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data
        } else {
            throw fetch.statusText;
        }
        return { response };
    } catch (error) {
        return { response, error };
    }
}

export const deleteWorkOrders = async ({ package_id, segment_id, standard_id, work_order_id }) => {
    const url = `/packages/${package_id}/segment/${segment_id}/standard/${standard_id}/work_order/${work_order_id}`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'DELETE'
        });
        // console.log("🚀 ~ file: work_orders.js:171 ~ deleteWorkOrders ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data
        } else {
            throw fetch.statusText;
        }
        return { response };
    } catch (error) {
        return { response, error };
    }
}