import React from 'react';
import TabsComp from 'components/layouts/Tabs';
import CostWorkOrdersComp from '../fragments/CostWorkOrders';

const MachineryEquipment = (props) => {
	const { package_id, standard_id } = props;
	return (
		<>
			<TabsComp
				tabPosition='left'
				tablist={['Herramienta', 'Equipo']}
				tabpanels={[
					<CostWorkOrdersComp
						key='1'
						package_id={package_id}
						standard_id={standard_id}
						type_description_id={3} // Herramienta
					/>,
					<CostWorkOrdersComp
						key='2'
						package_id={package_id}
						standard_id={standard_id}
						type_description_id={4} // Equipo
					/>,
				]}
			/>
		</>
	)
}

export default MachineryEquipment