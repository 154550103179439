import React, { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import PropTypes from 'prop-types';
import { Col, Collapse, Row, Skeleton } from "antd";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import './css/Carousel.scss';
import ButtonIcon from "./ButtonIcon";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const { Panel } = Collapse;

/**
 * 
 * @param {Array} evidences                     Arreglo de imagenes que se visualizarán
 * @param {Boolean} loading                     Indica si las fotografías están en proceso ed descarga
 * @param {Boolean} enableDel                   Habilitar Eliminación | Si es true se tiene que mandar {loadingDel, handleDeleteThumbnail}
 * @param {Boolean} enableUpl                   Habilitar carga de nuevos elementos | Si es tru se tiene que mandar {loadingUpl, handleLoadFile, handleUploadClick}
 * @param {Boolean} loadingUpl                  Indica si hay trabajos pendientes en la carga
 * @param {Boolean} loadingDel                  Indica si hay trabajos pendientes en la eliminación
 * @param {Function} handleLoadFile             Método para cargar nuevos elementos
 * @param {Function} handleUploadClick          Método que se dispara al presionar el botón de "Agregar", función que manda las fotografías al servidor
 * @param {Function} handleDeleteThumbnail      Método que se dispara al presionar el botón de eliminar de las miniaturas
 * @returns Carousel
 */
const CarouselComponent = ({
    evidences,
    loading,
    enableDel,
    enableUpl,
    loadingUpl,
    loadingDel,
    handleLoadFile,
    handleUploadClick,
    handleDeleteThumbnail
}) => {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const renderImageGallery = () => {
        const images = evidences.map((evidence) => ({
            original: evidence.image,
            thumbnail: evidence.image,
            originalAlt: 'Cargando imagen...',
            thumbnailAlt: 'Cargando imagen...',
            originalTitle: evidence.created_at,
        }));

        return (
            <ImageGallery
                items={images}
                showFullscreenButton={true}
                useBrowserFullscreen={false}
                onScreenChange={toggleFullScreen}
                showPlayButton={false}
                showIndex={true}
                showBullets={true}
                renderItem={renderItem}
                startIndex={currentIndex}
                onSlide={(currentIndex) => setCurrentIndex(currentIndex)}
            />
        );
    };

    const renderItem = (item) => {
        return (
            <div className="image-gallery-image">
                <img
                    src={item.original}
                    alt={item.originalAlt}
                    srcSet={item.srcSet}
                    sizes={item.sizes}
                    title={item.originalTitle}
                />
                <span className="image-gallery-description">{item.description}</span>
            </div>
        );
    };

    return (
        <>
            {
                !loading && evidences.length === 0 && (
                    <Alert variant="info">Actualmente no existen fotografías.</Alert>
                )
            }
            {
                loading ? (
                    <Skeleton active />
                ) : (
                    renderImageGallery()
                )
            }

            {/* Editar las fotografías del carousel */}
            <Collapse>
                {/* Agregar fotografía */}
                {
                    enableUpl &&
                    <Panel header="Agregar fotografía" key="1">
                        {
                            loadingUpl ? <Skeleton active /> :
                                <Row align='middle'>
                                    <Col span={20}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Cargar fotografía</Form.Label>
                                            <Form.Control type="file" size="sm" accept='image/*' onChange={handleLoadFile} multiple />
                                        </Form.Group>
                                    </Col>
                                    <Col span={4}>
                                        <ButtonIcon name='Agregar' icon={faPlus} variant='success' tooltip='Agregar' onClick={handleUploadClick} />
                                    </Col>
                                </Row>
                        }
                    </Panel>
                }
                {/* Eliminar fotografías */}
                {
                    enableDel &&
                    <Panel header="Eliminar fotografías" key="2">
                        {
                            loadingDel ? <Skeleton active /> :
                                <Row>
                                    {
                                        evidences.map((thumbnail, index) => (
                                            <Col sm={6} key={index}>
                                                <div className="thumbnail-container">
                                                    <img src={thumbnail.image} alt="Thumbnail" />
                                                    <div className="delete-button-container">
                                                        <ButtonIcon
                                                            size='sm'
                                                            tooltip='Eliminar'
                                                            icon={faTrashAlt}
                                                            variant='outline-danger'
                                                            onClick={() => handleDeleteThumbnail(thumbnail, index)}
                                                            className="delete-button"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                        }
                    </Panel>
                }
            </Collapse>
        </>
    );
};

CarouselComponent.propTypes = {
    evidences: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object.isRequired,
            created_at: PropTypes.string.isRequired
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    enableDel: PropTypes.bool,
    enableUpl: PropTypes.bool,
    loadingUpl: PropTypes.bool,
    loadingDel: PropTypes.bool,
    handleLoadFile: PropTypes.func,
    handleUploadClick: PropTypes.func,
    handleDeleteThumbnail: PropTypes.func
};

export default CarouselComponent;
