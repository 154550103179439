import axios from "axios";

const axiosAPIInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}`,
});

const fetcherCancelToken = async (args) => {
    const source = axios.CancelToken.source();
    try {
        const response = await axiosAPIInstance({
            ...args,
            cancelToken: source.token,
        });
        return {
            fetch: response,
            cancel: () => source.cancel("Solicitud cancelada")
        };
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log("La petición fue cancelada:", error.message);
        } else {
            const { response } = error;
            if (response && response.status === 401) {
                console.log("Cerrando sesión!");
            }
            return { fetch: response || error.request || error.message, cancel: () => { } };
        }
    }
};

export default fetcherCancelToken;