import React, { useEffect, useState } from 'react';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col, Divider, Row } from 'antd';
import ButtonIcon from 'components/layouts/ButtonIcon';
import ModalComp from 'components/layouts/Modal'
import { Form } from 'react-bootstrap';
import { storeInventoryData } from 'api/maintenance_standards/inventory_data';
import Swal from 'sweetalert2';

const ModalAddInventory = (props) => {
    const {
        onClose,
        items,
        labelExtra,
        project_id,
        table,
        inventory,
        getInventoryData
    } = props;

    const [itemsAdd, setItemsAdd] = useState([]);
    const [form, setForm] = useState({});               // Atributos modificados
    const [formExtra, setFormExtra] = useState({});     // Atributos extra modificados
    const [headers, setHeaders] = useState([]);         // Keys extra

    useEffect(() => {
        setItemsAdd(items.filter((item) => item.add === true));
        getDataDetails();
    }, []);

    const getDataDetails = () => {
        const array_labelExtra = []; // Items extra que se almacenan en inventory
        if (labelExtra)
            try {
                JSON.parse(labelExtra).forEach(element => {
                    array_labelExtra.push(element);
                });
            } catch (error) { }
        setHeaders(array_labelExtra);
    }
    // Método que detecta los cambios en el formulario de información
    const handleChange = (event) => {
        const { value, name } = event.target;
        setForm({ ...form, [name]: value });
    }
    // Método que detecta los cambios en el formulario de información adicional
    const handleChangeExtra = (event) => {
        const { value, name } = event.target;
        setFormExtra({ ...formExtra, [name]: value });
    }
    // Método para agregar el elemento
    const onCreate = async () => {
        let extras = [];
        if (labelExtra)
            for (const iterator of headers) {               // Ordenar array
                extras.push(formExtra[iterator]);
            }
        extras = JSON.stringify(extras);
        const data = { ...form, extras };
        let response = await storeInventoryData({ project_id, table, inventory_id: inventory.id, data });
        if (response) {
            getInventoryData();
            Swal.fire('¡Operación satisfactoria!', 'La información ha sido agregada al almacén de manera satisfactoria.', 'success');
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de agregar la información. Verifique los datos.', 'error');
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title='Agregar elemento'
            size='lg'
            body={<>
                <Row gutter={16}>
                    {/* Atributos generales */}
                    {
                        itemsAdd.map((item) =>
                            <Col className='gutter-row' key={item.value} xs={2} sm={4} md={6} lg={6} xl={12} >
                                <Form.Group>
                                    <Form.Label>{item.label}</Form.Label>
                                    <Form.Control
                                        type={item.type || 'text'}
                                        name={item.value}
                                        value={form[item.value]}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        )
                    }
                </Row>
                {
                    labelExtra &&
                    <Divider orientation='left'>Información adicional</Divider>
                }
                <Row gutter={16}>
                    {/* Atributos extra */}
                    {
                        labelExtra && headers.map((item) =>
                            <Col className='gutter-row' key={item} xs={2} sm={4} md={6} lg={6} xl={12} >
                                <Form.Group>
                                    <Form.Label>{item}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        name={item}
                                        value={formExtra[item]}
                                        readOnly={false}
                                        onChange={handleChangeExtra}
                                    />
                                </Form.Group>
                            </Col>
                        )
                    }
                </Row>
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                <ButtonIcon icon={faSave} name='Agregar' variant='outline-success' onClick={onCreate} />
            </>}
        />
    )
}

export default ModalAddInventory