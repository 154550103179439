import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-datepicker/dist/react-datepicker.css";
import '../UOV-0/Operacion.css';
import '../UOV-0/Resultados.css';
import { __SERVER__ } from '../../../../server/info';
import { Variables } from '../../../../variables/Variables';
import { Button, Modal, Accordion, Card } from 'react-bootstrap';
import FAB from '../../../generales/FAB.jsx';
import ModalUpload from '../Modal.jsx';
import Deductiva from '../Deductiva.jsx';
import Axios from 'axios';
import { Heading } from '@chakra-ui/react';

const MySwal = withReactContent(Swal);
const axios = require('axios');
const _ = require('underscore');

const FiltroBusqueda = class extends Component {
	constructor(props) {
		super(props);
		console.log('VARIABLES', Variables.project_id)
		this.state = {
			change: false,
			query: {
				request_dateS: "",
				request_dateE: "",
				project_id: Variables.project_id,
			},
			headers: [
				"",
				"",
				"Turno",
				"Plaza",
				"Carril",
				"Tipo de pago",
				"Tipo de vehículo"
			],
			query_: ""
		};
	}

	generateQuery = () => {
		this.state.query_ = "";

		let keys_query = Object.keys(this.state.query);
		let value_query = Object.values(this.state.query);
		let headersTable = []
		for (let i = 0; i < value_query.length; i++) {
			if (value_query[i].toString() !== "") {
				this.state.query_ += (this.state.query_ === "" ? "?" : "&") + keys_query[i] + "=" + value_query[i];
			} else {
				headersTable.push(this.state.headers[i])
			}
		}
		this.props.setProps(this.state.query_, headersTable)
	}
	changeInput = (e) => {
		this.state.query[e.target.name] = e.target.value;
		this.setState({ change: !this.state.change })
	}

	render() {
		return (
			<section className="sectionSearch">
				<article>
					<label>Fecha</label>
					<input type='date' name="request_dateS" value={this.state.query.request_dateS} onChange={this.changeInput} />
				</article>
				<article>
					<label>Fecha</label>
					<input type='date' name="request_dateE" value={this.state.query.request_dateE} onChange={this.changeInput} />
				</article>
				<article>
					<button className="botonBusqueda" onClick={this.generateQuery}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" style={{ width: 30 }} class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></button>
				</article>
			</section>
		);
	}
}

const Grid = class extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modal: false,
			item: null,
			tracing: null,
			images: []
		}
	}

	getTracing = () => {
		fetch(__SERVER__ + '/projects/' + Variables.project_id + '/victum_users/victum_complaints_suggestions/' + this.state.item.id + '/tracing', {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(tracing => {
					tracing = tracing.reverse();
					this.setState({ tracing, images: new Array(tracing.length).fill(null) }, this.getImages);
				})
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			console.log(e);
		})
	}

	getImages = () => {
		for (let i = 0; i < this.state.images.length; i++) {
			if (this.state.tracing[i].file !== null) {
				let url = __SERVER__ + '/projects/' + Variables.project_id + '/victum_users/victum_complaints_suggestions/' + this.state.item.id + '/tracing/' + this.state.tracing[i].id + '/download?path=' + this.state.tracing[i].file;
				axios(url, {
					method: "GET",
					responseType: "blob"
				}).then(response => {
					let file = new Blob([response.data], {
						type: response.data.type
					});
					let fileURL = URL.createObjectURL(file);
					this.state.images[i] = fileURL;
					this.setState({ refresh: !this.state.refresh });
				}).catch(error => {
					console.log("Error", error);
				});
			}
		}
	}

	render() {
		return (
			<div>
				{/* <Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} /> */}
				<section className="dataPage">
					<label>Número de elementos: {this.props.data.length}</label>
				</section>
				<table>
					<tr>
						<th>Fecha</th>
						<th>Nombre</th>
						<th>Correo</th>
						<th>Teléfono</th>
						<th></th>
					</tr>
					{
						this.props.data.map((item, i) =>
							<tr key={"sfdasdf" + i}>
								<td>{item.request_date.split('.')[0]}</td>
								<td>{item.name}</td>
								<td>{item.email}</td>
								<td>{item.phone}</td>
								<td><Button onClick={() => this.setState({ modal: true, item }, this.getTracing)} variant={'dark'}>Ver</Button></td>
							</tr>
						)
					}
				</table>
				{/* <Pagination updatePage={this.props.updatePage} page={this.props.data.page} pages={this.props.data.pages} /> */}

				<Modal show={this.state.modal} backdrop="static" keyboard={false} onHide={() => this.setState({ modal: false, item: null, tracing: null, images: [] })}>
					<Modal.Header closeButton>
						{this.state.item !== null ? <Modal.Title>{this.state.item.name}</Modal.Title> : null}
					</Modal.Header>
					{this.state.item !== null ?
						<Modal.Body>
							<div><strong>Reportado el: </strong> {this.state.item.request_date.replace('T', ' ').split('.')[0]} </div>
							<div><strong>Correo: </strong> {this.state.item.email} </div>
							<div><strong>Teléfono: </strong> {this.state.item.phone} </div>
							{/**Seguimiento */}
							{
								this.state.tracing !== null ?
									this.state.tracing.length !== 0 ?
										<div>
											<div><strong>{'\n'}Seguimiento</strong></div>
											<Accordion className="accordion">
												{
													this.state.tracing.map((item, i) =>
														<Card key={'wfsfa' + i}>
															<Card.Header className="card-headerBoot">
																<Accordion.Toggle as={Button} variant={'light'} eventKey={`${i}`}>
																	<small>{item.creation_date.split('.')[0] + ' - ' + (item.type === 0 ? this.state.item.name : 'Proyecto')}</small>
																</Accordion.Toggle>
															</Card.Header>
															<Accordion.Collapse eventKey={`${i}`}>
																<Card.Body>
																	{item.body}<br />
																	{this.state.images[i] !== null ?
																		<img className="imageCard" src={this.state.images[i]} />
																		: null}
																</Card.Body>
															</Accordion.Collapse>
														</Card>
													)
												}
											</Accordion>
										</div>
										: null : null
							}
						</Modal.Body> : null}
					<Modal.Footer onClick={() => this.setState({ modal: false, item: null, tracing: null, images: [] })}>
						<Button variant="danger">Cerrar</Button>
					</Modal.Footer>
				</Modal>
			</div>

		);
	}
}

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			grid: null,
			query: "",
			headersTable: [],
			result: [],
			refresh: false,
			page: 1,
			//Datos de formularios de modal Deductivas
			CumplObject: [],
			PUMObject: [],
			formulaCump: '',
			formulaPUM: '',
			modalDeductivas: false,
			hayFormula: false,
			buttonVisible: false
		}
	}

	componentDidMount = async () => {
		// GET operating_indicator_id
		let res = await Axios.get(__SERVER__ + '/packages/' + Variables.package_id + '/operating_indicator/' + Variables.indicator_id + '/formulas_operation')
		
		if (res.data.length!==0 && res.data[0].variables !== undefined && res.data[1].variables !== undefined) {
			this.setState({
				CumplObject: res.data[0].variables,
				PUMObject: res.data[1].variables,
				formulaCump: res.data[0].formula,
				formulaPUM: res.data[1].formula
			})

			this.setState({ hayFormula: true });
		} else {
			this.setState({ hayFormula: false });
		}
		this.setState({ buttonVisible: true });
	}

	updatePage = (page) => {
		this.setState({ page }, this.getComplaints);
	}

	generateTable = () => {
		// let data = [];
		// data = this.state.result[_.findIndex(this.state.result, { page: this.state.page })];

		this.setState({
			grid: null
		}, () => {
			if (this.state.result !== undefined) {
				this.setState({ grid: <Grid updatePage={this.updatePage} data={this.state.result} /> })
			} else {
				this.setState({ grid: null });
			}
		});
	}

	getComplaints = () => {
		//let query = this.state.query + (this.state.query === '' ? `?page=${this.state.page}` : `&page=${this.state.page}`);

		fetch(__SERVER__ + "/projects/victum_users/victum_complaints_suggestions" + this.state.query, {
			method: 'GET',
			headers: {
				Accept: 'application/json'
			}
		}).then(res => {
			if (res.status === 200) {
				res.json().then(result => {
					this.state.result = result;
					this.setState({ refresh: !this.state.refresh }, this.generateTable);
				});
			} else if (res.status === 401) {
				res.json().then(res => {
					this.setState({ grid: null });
					Swal.fire(res.statusCode, res.error + ": " + res.message, 'error');
				});
			} else {
				throw res.statusText;
			}
		}).catch(e => {
			this.setState({ grid: null });
			Swal.fire('Error', e, 'error');
		})
	}

	setProps = (query, headersTable) => {
		this.setState({
			page: 1,
			result: [],
			query: query,
			headersTable: headersTable
		}, this.getComplaints)
	}

	openOrCloseModal = () => {
		this.setState({modalUploadData: !this.state.modalUploadData});
	}

	openOrCloseModalDeductivas = () => {
		this.setState({ modalDeductivas: !this.state.modalDeductivas });
	}

	render() {
		return (
			<div className="body_">
				<Heading className='tituloSub'>{"VICTUM " + this.props.code + " " + this.props.name}</Heading>
				<FiltroBusqueda setProps={this.setProps} />
				{this.state.grid}

				{
					this.state.buttonVisible ?
						<FAB style={{ position: 'fixed', bottom: 70, right: 10 }} size={50} icon={'faCarCrash'} onClick={this.openOrCloseModalDeductivas} /> : null
				}
				<FAB style={{ position: 'fixed', bottom: 10, right: 10 }} size={50} icon={'faPlus'} onClick={this.openOrCloseModal} />
				{
					this.state.modalUploadData ?
						<ModalUpload
							server={`${__SERVER__}/projects/${Variables.project_id}/victum_complaints_suggestions`}
							items={[
								{ label: 'Identificador', description: 'Identificador', value: 'identifier', type: 'String' },
								{ label: 'Nombre', description: 'Nombre', value: 'name', type: 'String' },
								{ label: 'Teléfono', description: 'Teléfono', value: 'phone', type: 'String' },
								{ label: 'Correo electrónico', description: 'Correo electrónico', value: 'email', type: 'String' }
							]}
							title={`${this.props.code} ${this.props.name}`}
							onClose={this.openOrCloseModal} />
						: null
				}
				{
					this.state.modalDeductivas ?
						<Deductiva
							title={this.props.code}
							formula={this.state.formula}
							pum={this.state.PUMObject}
							cump={this.state.CumplObject}
							formulaCump={this.state.formulaCump}
							formulaPUM={this.state.formulaPUM}
							hayFormula={this.state.hayFormula}
						/> : null
				}
			</div>
		);
	}
}
