import React, { useEffect, useState } from 'react';
import ModalComp from 'components/layouts/Modal';
import { QRCodeSVG } from 'qrcode.react';
import './ModalQr.css';
import { getUrlApps } from 'api/general/apps';
import ButtonIcon from 'components/layouts/ButtonIcon';
import NoData from 'components/layouts/NoData';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { useDisclosure } from '@chakra-ui/react';
import { Skeleton } from 'antd';

const ModalQr = (props) => {
	const { onClose, item } = props;

	const [url, setUrl] = useState(null);
	const reloadQr = useDisclosure();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getQrCode = async () => {
			setLoading(true);
			const { id, apk, package_id } = item;
			const { response } = await getUrlApps({ package_id, id, file_name: apk });
			if (response) {
				setUrl(response.signedUrl);
				reloadQr.onOpen();
			}
			setLoading(false);
		}
		if (!reloadQr.isOpen) getQrCode();
	}, [reloadQr.isOpen]);

	const handleRegenerateCode = () => {
		reloadQr.onClose();
	};
	return (
		<ModalComp
			title={'Descargar APP'}
			onClose={onClose}
			size='md'
			body={<>

				{
					!reloadQr.isOpen && loading && <Skeleton active />
				}
				{
					!reloadQr.isOpen && !loading && <>
						<p>No se encontró ejecutable para descargar.</p>
						<NoData />
					</>
				}
				{
					reloadQr.isOpen && !loading && <>
						<p>Escanea el código Qr para descargar la app.</p>
						<QRCodeSVG
							className='custom-qr'
							size={350}
							value={url}
						/>
					</>
				}
				{
					reloadQr.isOpen && (
						<div className='buttom-regenerate-qr'>
							<ButtonIcon
								icon={faRedoAlt}
								name='Actualizar Código QR'
								onClick={handleRegenerateCode}
								variant='outline-success'
							/>
						</div>
					)}
			</>}
		/>
	)
}

export default ModalQr