import moment from 'moment/moment';
import React from 'react';
import Logo from '../../../../../img/carreterass.png'
import Formulas from '../../../../../img/formulas.png'
import { Document, Page, Text, View, StyleSheet,  Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    body:{ padding: 15 },
    Head:{
        display: "flex",
        flexDirection: "row"
    },
    Head_Image:{ width: 50 },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0,
      marginBottom: 15
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    }, 
    tableCol: { 
      width: "25%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCell: { 
      margin: "auto", 
      marginTop: 5, 
      marginBottom: 5,
      fontSize: 10,
      textAlign: "center"
    },
    text_center: { fontSize: 12 },
    logo:{
        width: "15%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    d_flex:{
        width: "70%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }, txt_center: { textAlign: "center" } ,
    text_logo: {fontSize: 11},
    mb_5:  { marginBottom: 14},
    fz_12: {fontSize: 12},
    flex:{
        display: "flex",
        flexDirection: "row",
        marginVertical: 14,
        justifyContent: "space-around"
    }, w_75: { width: "75%", marginHorizontal: "auto" }
});

const DocPDF = React.memo(({dataIncident,data, tipo_deductiva, pumPrice,deductiva_percent_price, deductiva_final,tiempo, pago_mro}) => {

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.mb_5} >
                    <View style={styles.Head}>
                        <View style={styles.logo}><Image src={Logo} style={styles.Head_Image}/><Text style={styles.text_logo}> Capsa </Text></View>

                        <View style={styles.d_flex}>
                            <Text style={styles.text_center}>DEDUCTIVAS POR INCUMPLIMIENTO EN ESTANDARES MR/O</Text>
                        </View>

                        <View style={styles.logo}><Image src={Logo} style={styles.Head_Image}/><Text style={styles.text_logo}>SICT - GANA</Text></View>
                    </View>

                    <View style={styles.fz_12}>
                        <View style={styles.flex} >
                            <View>
                                <Text>Nombre del supervisor: {`${dataIncident.created_by.first_name} ${dataIncident.created_by.last_name} `}</Text>
                                <Text>Nombre del director de O/MR: {`${dataIncident.created_by.first_name} ${dataIncident.created_by.last_name} `}</Text>
                                <Text>Tramo: TRAMO I. AMOZOC-PEROTE</Text>
                            </View>
                            <View>
                                <Text>Segmento: 0+000 A 40+000</Text>
                                <Text>Fecha: { moment().format("D MMM YYYY, h:mm:ss a") } </Text>
                                <Text>Periedo mensual: 01/11/2022 - 30/11/2022</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{width: "100%", display: "flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}} >
                        <Image src={Formulas} style={styles.w_75} />
                    </View>
                </View>

                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        {
                            ["Código Incidente", "Estandar", "Tipo deductiva", "Fecha de apertura", "Fecha de cierre", "Tiempo de atención"].map((item, i) => (
                                <View style={styles.tableCol} key={i} > 
                                    <Text style={styles.tableCell}>{item}</Text> 
                                </View> 
                            ) )
                        }
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{dataIncident.id}</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{dataIncident.standard.code}</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{tipo_deductiva}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{ moment(dataIncident.creation_date).format("D MMM YYYY, h:mm:ss a") }</Text> 
                        </View>
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>  {(dataIncident?.data?.estimated_date) ? (
                                    moment(dataIncident?.data?.estimated_date).format("D MMM YYYY, h:mm:ss a") 
                                ) : ( moment(data.duracion).format("D MMM YYYY, h:mm:ss a")) }</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{tiempo} días</Text> 
                        </View> 
                    </View> 
                </View>

                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        {
                            ["Estatus Incidente", "Método deductiva", "Deductiva en %", "IT-PUM", "Deductiva"].map((item, i) => (
                                <View style={styles.tableCol} key={i}> 
                                    <Text style={styles.tableCell}>{item}</Text> 
                                </View> 
                            ) )
                        }
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{dataIncident.status.name}</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{data.metodo_deductiva}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{dataIncident?.data?.deductive?.factor} %</Text> 
                        </View>
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>{pumPrice}</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>$ {deductiva_percent_price}</Text> 
                        </View> 
                    </View> 
                </View>

                <View>
                    <Text style={styles.txt_center} >Evaluación del incidente: </Text>
                    <View style={{ textAlign : "left", fontSize: 14 }} >    
                        <Text> {dataIncident?.data?.evaluation?.name} </Text>
                        <Text>Longitud en metros:        {data.medida}</Text>
                        <Text>Deductiva final:               $ {deductiva_final.toFixed(1)} </Text>
                        <Text>Pago mensual a MRO: $ {pago_mro.toFixed(1)} </Text>
                    </View>
                </View>

                <View style={{display: "flex", flexDirection: "row", marginTop: 100, fontSize: 12,justifyContent: "space-around"}} >
                    <View>
                        <Text>Firma del Supervisor</Text>
                        <Text>{`${dataIncident.created_by.first_name} ${dataIncident.created_by.last_name}`}</Text>
                    </View>
                    <View>
                        <Text>Firma del director de operación</Text>
                        <Text>{`${dataIncident.created_by.first_name} ${dataIncident.created_by.last_name}`}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
})

export default DocPDF