import fetcherCancelToken from "components/libs/fetcherCancelToken";
import { httpStatus } from "variables/Variables";

export const indexPumActivitySupplies = async ({ pum_activity_id }) => {
    const url = `/maintenance_standard/pum_activity_supplies`;
    let response = { data: [], totalItems: 0 };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { pum_activity_id }
        });
        // console.log("🚀 ~ file: work_orders.js:13 ~ indexPumActivitySupplies ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data;
        }
        return { response, cancel };
    } catch (error) {
        return { response, cancel: () => { } };
    }
}

export const storePumActivitySupplies = async ({ data }) => {
    const url = `/maintenance_standard/pum_activity_supplies`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:33 ~ storePumActivitySupplies ~ fetch:", fetch)
        if (fetch.status === httpStatus.created || fetch.status === httpStatus.ok)
            response = fetch.data
        return { response };
    } catch (error) {
        return { response };
    }
}

export const updatePumActivitySupplies = async ({ pum_activity_id, data }) => {
    const url = `/maintenance_standard/pum_activity_supplies/${pum_activity_id}`;
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: work_orders.js:150 ~ updatePumCostPumActivitySupplies ~ fetch:", fetch)
        if (fetch.status === httpStatus.ok) {
            response = fetch.data
        } else {
            throw fetch.statusText;
        }
        return { response };
    } catch (error) {
        return { response, error };
    }
}