import { stateItemsAssets as initialState } from '../initialState/InitialState';
import {
    SET_ITEMS_ITS,
    SET_ITEMS_EDIFICATION,
    SET_ITEMS_ENVIRONMENT,
    SET_ITEMS_INFRASTRUCTURE
} from '../actions/actionTypes';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ITEMS_ITS:
            return {
                ...state,
                its: action.items
            };
        case SET_ITEMS_EDIFICATION:
            return {
                ...state,
                edification: action.items
            };
        case SET_ITEMS_ENVIRONMENT:
            return {
                ...state,
                environment: action.items
            };
        case SET_ITEMS_INFRASTRUCTURE:
            return {
                ...state,
                infrastructure: action.items
            };
        default:
            return state;
    }
};

export default reducer;