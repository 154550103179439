import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/Proyectos.css';
import './css/Menu.css';

import estandares from './imagenes-app/estandares.jpg';
import bitacora from './imagenes-app/bitacora.jpg';
import calidad from './imagenes-app/calidad.jpg';
import historico from './imagenes-app/historico.png';
import geo from './imagenes-app/geo.png';
import alerta from './imagenes-app/alerta.png';
import entregables from './imagenes-app/entregables.png';
import planos from './imagenes-app/planos.png';
import perfil_informativo from './imagenes-app/perfil_informativo.png';
import no_image from './imagenes-app/no_image_available.png';
import icon_billing from './imagenes-app/fact.png';
import puente from './imagenes-app/puente.png';
import app_usuarios from './imagenes-app/logo.png';
import electronic_payments from './imagenes-app/electronic_payments_2.png';

import BreadCrumbRedux from './generales/BreadCrumbRedux';
import MaintenanceMainComp from './subComponents/Proyectos/Mantemiento/Index'
import { OperacionProyectos } from './subComponents/OperacionProyectos';
import InformacionGeo from './subComponents/InformacionGeoreferenciada';
import BitacoraOnline from './subComponents/BitacoraOnline';
import PerfilInformativo from './subComponents/PerfilInformativo_';
import Puentes from './subComponents/Puentes';
import Entregables from './subComponents/Entregables';
import AppUsuarios from './subComponents/AppUsuarios';
import ElectronicPayments from './subComponents/ElectronicPayments';
import Billing from './subComponents/Billing';
import AlertsComp from './subComponents/Alerts/Index';

import { Variables } from '../variables/Variables';
import { indexModules } from '../api/general/modules';
import { indexMaintenanceStandards } from '../api/general/maintenance_standards';
import { indexOperatingStandards } from 'api/general/operating_standards';
import { getProjectsByURL } from 'api/general/getProjects';

import Swal from 'sweetalert2';
import { allowModule } from './libs/allowModule';
import { BREADCRUMB_ADD, MODULE_CHOOSED, STAGE_CHOOSED, STANDARD_M_CHOOSED, STANDARD_O_CHOOSED, UPDATE_COMPONENT, UPDATE_MODULES, UPDATE_PROJECT, UPDATE_PROJECTS, UPDATE_STM, UPDATE_STO } from 'store/actions/actionTypes';

const md5 = require('md5');

const ProjectsComp = ({ getModules }) => {
	const dispatch = useDispatch();
	const projects = useSelector(state => state.proyectos.projects);
	return (
		<section className="contentPro">
			{
				projects.map((item, i) =>
					<div key={item.id} className="contentP" onClick={() => {
						let key = md5(`Proyectos_${item.name}_${item.id}_${i}`);
						let level = md5(`_BAD34AF213p`);
						Variables.project_id = item.id;
						Variables.project_code = item.code;
						dispatch({ type: UPDATE_PROJECT, project_id: item.id });
						dispatch({ type: BREADCRUMB_ADD, path: item.name, key, level });
						getModules();
					}}>
						{
							item.file_name ?
								<img className="imagenP" src={`${window.location.origin}/${item.file_name}`} />
								: <img className="imagenP" src={no_image} />
						}
						<h1 className="nombreP">{item.name}</h1>
					</div>
				)
			}
		</section>
	)
}

const ModulesComp = ({ optionChoosed, openView }) => {
	const dispatch = useDispatch();
	const modules = useSelector(state => state.proyectos.modules);

	return (
		<section className="contentPro">
			{
				modules
					.filter((item) => allowModule(item))
					.map((item, i) =>
						item.code !== "PM" ?
							<div key={item.id} className="contentP" onClick={() => {
								let key = md5(`Opciones_${item.name}_${item.id}_${i}`);
								let level = md5(`__Dasdada#2311`);
								dispatch({ type: BREADCRUMB_ADD, path: item.name, key, level });
								optionChoosed(item.code);
								dispatch({ type: MODULE_CHOOSED, moduleChoosed: item.code });
								dispatch({ type: STANDARD_O_CHOOSED, standardOChoosed: -1 });
								dispatch({ type: STANDARD_M_CHOOSED, standardMChoosed: -1 });
								openView(item.code);
							}}>
								<img className='imagenP' src={item.img}></img><h1 className="nombreP">{item.name}</h1>
							</div>
							: null
					)
			}
		</section>
	)
}

const OperationStandardsComp = ({ activeStage, getStageTag }) => {
	const dispatch = useDispatch();
	const operationStandards = useSelector(state => state.proyectos.standardsO);
	const stageChoosed = useSelector(state => state.proyectos.stageChoosed);

	const [standards, setStandards] = useState([]);

	useEffect(() => {
		if (stageChoosed) {
			setStandards(operationStandards.filter((item) => item.stage_id == stageChoosed));
		} else {
			setStandards(operationStandards);
		}
	}, [stageChoosed, operationStandards]);

	return (
		<section className="contentPro">
			{
				standards
					.filter((item) => activeStage(item.stage_id))
					.filter((item) => allowModule(item))
					.map((item, i) =>
						<div key={item.id} className="contentStage" onClick={() => {
							let key = md5(`ESTAO_${item.name}_${item.id}_${i}`);
							let level = md5(`EstandaresOd1h378728`);
							dispatch({ type: BREADCRUMB_ADD, path: item.name, key, level });
							dispatch({ type: STANDARD_O_CHOOSED, standardOChoosed: item.id });
							dispatch({ type: STANDARD_M_CHOOSED, standardMChoosed: -1 });
						}}>
							<h1 className="nombreP">{`${item.code} ${item.name}`}</h1>
							{
								item.stage_id &&
								<h1 className='nombreS'>
									{
										getStageTag(item.stage_id)
									}
								</h1>
							}
						</div>
					)
			}
		</section>
	)
}

const OperatingIndicatorsComp = ({ openView }) => {
	const dispatch = useDispatch();
	const operationStandards = useSelector(state => state.proyectos.standardsO);
	const standardOChoosed = useSelector(state => state.proyectos.standardOChoosed);
	return (
		<section className="contentPro">
			{
				operationStandards
					.filter((item) => item.id === standardOChoosed)[0].sub
					.filter((item) => allowModule(item))
					.map((item, i) =>
						<div key={item.id} className="contentP" onClick={() => {
							Variables.indicator_id = item.indicator_id; //Se obtiene el indicador de operación
							let key = md5(`INDICAO_${item.name}_${item.id}_${i}`);
							let level = md5(`standardsOM1223123`);
							dispatch({ type: BREADCRUMB_ADD, path: item.name, key, level });
							//Hacer algo, mostrar View u obtener nuevos datos
							openView(item.code, item.name, item.component, -1, item.permission);
						}}>
							<h1 className="nombreP">{`${item.code} ${item.name}`}</h1>
						</div>
					)
			}
		</section>
	)
}

const MaintenanceStandardsComp = ({ activeStage, openView, getStageTag }) => {
	const dispatch = useDispatch();
	const maintenanceStandards = useSelector(state => state.proyectos.standardsM);
	const stageChoosed = useSelector(state => state.proyectos.stageChoosed);

	const [standards, setStandards] = useState([]);

	useEffect(() => {
		if (stageChoosed) {
			setStandards(maintenanceStandards.filter((item) => item.stage_id == stageChoosed));
		} else {
			setStandards(maintenanceStandards);
		}
	}, [stageChoosed, maintenanceStandards]);

	return (
		<section className="contentPro">
			{
				standards
					.filter((item) => activeStage(item.stage_id))
					.filter((item) => allowModule(item))
					.map((item, i) =>
						<div key={item.id} className="contentStage" onClick={() => {
							let key = md5(`ESTAM_${item.name}_${item.id}_${i}`);
							let level = md5(`standardsOM1223123`);
							dispatch({ type: BREADCRUMB_ADD, path: item.name, key, level });
							dispatch({ type: STANDARD_O_CHOOSED, standardOChoosed: -1 });
							// Abrir módulo en caso de no tener indicadores
							if (item.indicators.length === 0) {
								openView(item.code, item.name, item.component, item.id, item.permission);
							} else {
								dispatch({ type: STANDARD_M_CHOOSED, standardMChoosed: item.id });
							}
						}}>
							<h1 className="nombreP">{`${item.code} ${item.name}`}</h1>
							{
								item.stage_id &&
								<h1 className="nombreS">
									{
										getStageTag(item.stage_id)
									}
								</h1>
							}
						</div>
					)
			}
		</section>
	)
}

const MaintenanceIndicatorsComp = ({ openView }) => {
	const dispatch = useDispatch();
	const maintenanceStandards = useSelector(state => state.proyectos.standardsM);
	const standardMChoosed = useSelector(state => state.proyectos.standardMChoosed);
	return (
		<section className="contentPro">
			{
				maintenanceStandards
					.filter((item) => item.id === standardMChoosed)[0].indicators
					.filter((item) => allowModule(item))
					.map((item, i) =>
						<div key={item.id} className="contentP" onClick={() => {
							Variables.maintenance_indicator_id = item.id; //Se obtiene el indicador de mantenimiento
							let key = md5(`INDICAO_${item.name}_${item.id}_${i}`);
							let level = md5(`standardsOM1223123`);
							dispatch({ type: BREADCRUMB_ADD, path: item.name, key, level });
							openView(item.code, item.name, item.component, item.id, item.permission);
						}}>
							<h1 className="nombreP">{`${item.code} ${item.name}`}</h1>
						</div>
					)
			}
		</section>
	)
}

const Proyectos = () => {
	const dispatch = useDispatch();

	const data = useSelector(state => state.login.dataUser);
	const package_id = useSelector(state => state.login.package_id);
	const project_id = useSelector(state => state.proyectos.project_id);
	const projects = useSelector(state => state.proyectos.projects);
	const allStages = useSelector(state => state.proyectos.stages);
	const componentActive = useSelector(state => state.proyectos.componentActive);
	const moduleChoosed = useSelector(state => state.proyectos.moduleChoosed);
	const modules = useSelector(state => state.proyectos.modules);
	const standardsO = useSelector(state => state.proyectos.standardsO);
	const standardsM = useSelector(state => state.proyectos.standardsM);
	const standardOChoosed = useSelector(state => state.proyectos.standardOChoosed);
	const standardMChoosed = useSelector(state => state.proyectos.standardMChoosed);
	const stageChoosed = useSelector(state => state.proyectos.stageChoosed);

	const [stages, setStages] = useState([]);

	useEffect(() => {
		if (projects.length === 0) {
			getProjects();
		} else {
			console.log("Datos cargados del Store")
		}
		// Verificar las fases que se encuentran activas
		const today = new Date();
		const filteredStages = allStages.filter((stage) => {
			const { start_at, end_at } = stage;
			return today > new Date(start_at) && today < new Date(end_at);
		});
		// Activar la primera fase por default si existe al menos una y no hay alguna seleccionada
		if (filteredStages.length > 0 && !stageChoosed)
			dispatch({ type: STAGE_CHOOSED, stage_id: filteredStages[0].id });
		setStages(filteredStages);
	}, []);

	const getModules = async () => {
		if (modules.length !== 0) return;
		const { response } = await indexModules({ package_id });
		if (response.error) {
			Swal.fire("Error", "Error con el servidor al recuperar los módulos", 'error');
		} else {
			for (const element of response.data) {
				switch (element.code) {
					case "EDO": element.img = estandares; break;
					case "EDM": element.img = estandares; break;
					case "AU": element.img = app_usuarios; break;
					case "MEP": element.img = electronic_payments; break;
					case "FE": element.img = icon_billing; break;
					case "BL": element.img = bitacora; break;
					case "SAC": element.img = calidad; break;
					case "HGC": element.img = historico; break;
					case "IG": element.img = geo; break;
					case "PEP": element.img = planos; break;
					case "A": element.img = alerta; break;
					case "E": element.img = entregables; break;
					case "PI": element.img = perfil_informativo; break;
					case "PM": element.img = historico; break;
					case "PT": element.img = puente; break;
					default: element.img = no_image; break;
				}
			}
			dispatch({ type: UPDATE_MODULES, modules: response.data });
		}
	}

	const getInfoEM = async () => {
		if (standardsM.length !== 0) return;
		const { response } = await indexMaintenanceStandards({ package_id });
		if (response.error) {
			Swal.fire("Error", "Error con el servidor al recuperar los estándares de mantenimiento", 'error');
		} else {
			dispatch({ type: UPDATE_STM, standardsM: response.data });
		}
	}

	const getInfoEO = async () => {
		if (standardsO.length !== 0) return;
		const { response } = await indexOperatingStandards({ package_id });
		if (response.error) {
			Swal.fire("Error", "Error con el servidor al recuperar los estándares de operación", 'error');
		} else {
			dispatch({ type: UPDATE_STO, standardsO: response.data });
		}
	}

	const getProjects = async () => {
		let url = '';
		const { road_section_id, project_id } = data;
		if (road_section_id !== null) {
			url += `/packages/${package_id}/road_sections/${road_section_id}/projects`;
		} else if (project_id !== null) {
			url += `/project/${project_id}`;
		} else {
			url += `/packages/${package_id}/projects`;
		}

		const { response } = await getProjectsByURL({ url });
		if (response.error) {
			Swal.fire("Error", "Error con el servidor al recuperar los projectos", 'error');
		} else {
			if (road_section_id !== null) {
				dispatch({ type: UPDATE_PROJECTS, projects: response.data.projects });
			} else {
				dispatch({ type: UPDATE_PROJECTS, projects: response.data });
			}
		}
	}

	const optionChoosed = (code) => {
		switch (code) {
			case "EDO": getInfoEO(); break;
			case "EDM": getInfoEM(); break;
			case "AU": break;
			case "MEP": break;
			case "FE": break;
			case "BL": break;
			case "SAC": break;
			case "HGC": break;
			case "IG": break;
			case "PEP": break;
			case "A": break;
			case "E": break;
			case "PI": break;
			case "PM": break;
			case "PT": break;
			default: break;
		}
	}

	const openView = (code, name = null, codeComponent = null, id = -1, permission = '') => {
		let component = null;

		if (codeComponent !== null) {
			switch (moduleChoosed) {
				case "EDO":
					const operationComps = OperacionProyectos(project_id, code, name, permission);
					let index = operationComps.findIndex(item => item.name === codeComponent);

					if (index !== -1) {
						component = operationComps[index].component;
						dispatch({ type: UPDATE_COMPONENT, componentActive: component });
					}
					break;
				case "EDM":
					// component = <MR
					// 	standard_id={id}
					// 	project_id={project_id}
					// 	package_id={package_id}
					// 	code={code}
					// 	name={name}
					// 	component={codeComponent}
					// 	permission={permission}
					// />;
					component = <MaintenanceMainComp
						standard_id={id}
						project_id={project_id}
						package_id={package_id}
						code={code}
						name={name}
						component={codeComponent}
						permission={permission}
					/>
					dispatch({ type: UPDATE_COMPONENT, componentActive: component });
					break;
				default:
					break;
			}
		} else {
			const project = projects.find(item => item.id === project_id);
			const projectName = project ? project.name : '';

			switch (code) {
				case "BL":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <BitacoraOnline data={data} project_id={project_id} package_id={package_id} />
					});
					break;
				case "AU":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <AppUsuarios data={data} project_id={project_id} />
					});
					break;
				case "MEP":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <ElectronicPayments data={data} project_id={project_id} />
					});
					break;
				case "FE":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <Billing data={data} project_id={project_id} />
					});
					break;
				case "SAC": break;
				case "HGC": break;
				case "IG":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <InformacionGeo project_id={project_id} />
					});
					break;
				case "PEP": break;
				case "A":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <AlertsComp project_id={project_id} />
					});
					break;
				case "E":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <Entregables project_id={data} projectId={project_id} />
					});
					break;
				case "PI":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <PerfilInformativo project_id={project_id} p={projectName} />
					});
					break;
				case "PM": break;
				case "PT":
					dispatch({
						type: UPDATE_COMPONENT,
						componentActive: <Puentes project_id={project_id} p={projectName} />
					});
					break;
				default: break;
			}
		}
	}
	// Método para validar las fases que se encuentran activas
	const activeStage = (stage_id) => {
		// Si es null no pertenece a ninguna fase y se muestra
		if (!stage_id) return true;
		// Si pertenece a una fase se verifica que esté activa dicha fase
		return stages.some(stage => stage.id === stage_id)
	}

	const getStageTag = (stage_id) => {
		const stage = stages.find(item => item.id === stage_id);
		return stage ? stage.name : ''
	}

	return (
		<div>
			{/* Crear un BreadCrum AQUI*/}
			<BreadCrumbRedux here={'PROYECTOS'} stages={stages} />
			{
				componentActive === null ?
					<div className="con_">
						{/* Barra de navegación de los proyectos */}
						<ProjectsComp getModules={getModules} />
						{/* Barra de navegación de módulos */}
						{
							modules.length !== 0 &&
							<ModulesComp optionChoosed={optionChoosed} openView={openView} />
						}
						{/* Barra de navegación de los estándares de operación */}
						{
							moduleChoosed === "EDO" &&
							<OperationStandardsComp activeStage={activeStage} getStageTag={getStageTag} />
						}
						{/* Barra de navegación de los indicadores de operación */}
						{
							moduleChoosed === "EDO" &&
							standardOChoosed !== -1 &&
							<OperatingIndicatorsComp openView={openView} />
						}
						{/* Barra de navegación de los estándares de mantenimiento */}
						{
							moduleChoosed === "EDM" &&
							<MaintenanceStandardsComp activeStage={activeStage} openView={openView} getStageTag={getStageTag} />
						}
						{/* Barra de navegación de los indicadores de mantenimiento */}
						{
							moduleChoosed === "EDM" &&
							standardMChoosed !== -1 &&
							<MaintenanceIndicatorsComp openView={openView} />
						}
					</div>
					: componentActive
			}
		</div>
	)
}

export default Proyectos;