import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InventoryPdf from './InventoryPdf'
import LoadingDoc from './LoadingDoc';
import moment from 'moment'
const { __SERVER__ } = require("../../../server/info");

const getDate = moment().format('YYYY-MMM-DD-HH-mm-ss')

const useStyles = makeStyles({
  root: {
    minWidth: 260,
    maxWidth: '47%',
    margin:5
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: { fontSize: 14, },
  pos: {  marginBottom: 12, },
  text_black:{ color: "#000" }
});

const Cards = React.memo(({getInventories, inventoryName, project_id}) => {
    const classes = useStyles();
    const [inventory, setinventory] = useState(null)
    const [_idButton, set_idButton] = useState(null)
    const [filesReady, setfilesReady] = useState(false)
    const [filess, setfiless] = useState(null)
    const [iten, setiten] = useState(null)
    const [NoData, setNoData] = useState(false)
    const [Err, setErr] = useState(false)

    const getFiles = async inventor => { //corregir
        let lista_of_files = [];
        inventor.map((item) => {
            lista_of_files.push(
                    fetch(`${__SERVER__}/projects/${project_id}/${inventoryName}_files/${item.id}`).then(response => response.json())
            )
        })

        try {
            Promise.allSettled(lista_of_files).then(data => {
                let result = []
                for (const key in data) {
                    for (const key2 in data[key].value?.data) {
                        if (data[key].value.data[key2].file_type === "picture") {
                            result.push({
                                id: data[key].value.data[key2]?.inventory_data_id,
                                foto: data[key].value.data[key2]?.file_path
                            })
                        }
                    }
                }
                setiten(result);
                setfiless(true);
            })
            setfilesReady(true)
        } catch (error) {
            console.log("file: Projects.js  line 73  constread_incidencias_to_inspecciones= ~ error", error)
        }
    } 

    const HandleCreateReport = async id => {
        setfiless(false)
        setNoData(false)
        setErr(false)
        set_idButton(id)
        try {
            const res = await fetch(`${__SERVER__}/projects/${project_id}/${inventoryName}/${id}/data?pageSize=10000&check_status=Verificado`);
            const data = await res.json();
            if(data.data.length > 0){
                setinventory(data.data);
                getFiles(data.data)
            } else {
                setNoData(true);
            }
        } catch (err) { 
            console.log(err)
            setErr(true)
        }
    } 

    return (
        getInventories.map(inventoryItem => 
            <Card className={classes.root} variant="outlined" key={inventoryItem.id}>
                <CardContent>
                    <Typography className={classes.text_black} variant="h5" component="h2">
                        {inventoryItem.inventory}
                    </Typography>
                    <Typography variant="body2" component="p">
                        Código: {inventoryItem.code}
                    </Typography>
                    
                    <Button onClick={()=>HandleCreateReport(inventoryItem.id)} variant="outline-danger" className="my-2"><FaFilePdf className="mr-1" /> Generar Informe de Inventario</Button>
                    {  ((filess === false &&  !NoData && !Err) && (_idButton === inventoryItem.id)) && <LoadingDoc /> }
                    {
                        ((filess && (_idButton === inventoryItem.id)) && (filesReady && inventory)) && <PDFDownloadLink 
                            document={
                                <InventoryPdf 
                                    project_id={project_id} 
                                    inventoryName={inventoryName} 
                                    files={iten}
                                    inventoryItem={inventoryItem} 
                                    inventory={inventory}
                                /> }
                            fileName={`Reporte_Inventario_${inventoryItem.inventory}_${getDate}.pdf`}>
                                {
                                    ({ blob, url, loading, error }) =>
                                    loading ? <LoadingDoc /> :  (<Button className="mx-1" variant="success" ><FaFilePdf className="mr-1" />Reporte Listo para descargar</Button>)
                                }
                        </PDFDownloadLink>  
                    }
                    {
                        (NoData && (_idButton === inventoryItem.id)) && <p className="text-center m-0">No hay datos</p>
                    }

                    {
                        (Err && (_idButton === inventoryItem.id)) && <p className="text-center m-0">Ocurrió un Error</p>
                    }
                </CardContent>
            </Card>
        )
    );
})

export default Cards