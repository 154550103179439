import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

const StandardList = ({ hookStandards }) => {
    const selectStandard = (standard) => {
        hookStandards.setDataSel(standard);
    }
    return (
        <Col id='standard-list' xs={24} sm={24} md={6} lg={8}>
            {
                hookStandards.data.map(standard =>
                    <Row
                        key={standard.id}
                        className={`budget-list-row ${standard.id === hookStandards.dataSel?.id ? 'selected' : ''}`}
                        onClick={() => selectStandard(standard)}
                    >
                        <Col className='budget-list-col'>
                            {standard.code} {standard.name}
                        </Col>
                    </Row>
                )
            }
        </Col>
    )
}

StandardList.propTypes = {
    hookStandards: PropTypes.shape({
        dataSel: PropTypes.shape({
            id: PropTypes.number.isRequired
        }).isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                code: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
        setDataSel: PropTypes.func.isRequired
    })
}

export default StandardList