import React from "react";
import { Form, Col } from "react-bootstrap";
import PropTypes from 'prop-types';

const FormIcon = ({ title, name, value, defaultValue, type, readOnly, handleChange, placeholder, onKeyDown, disabled }) => {

    const handleChangeVoid = () => { };

    return (
        <Form.Group as={Col}>
            <Form.Label>{title}</Form.Label>
            <Form.Control
                type={type || 'text'}
                name={name || ''}
                placeholder={placeholder || ''}
                defaultValue={defaultValue || ''}
                value={value}
                onChange={handleChange || handleChangeVoid}
                readOnly={readOnly || false}
                onKeyDown={onKeyDown || handleChangeVoid}
                disabled={disabled || false}
            />
        </Form.Group>
    );
}

FormIcon.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    handleChange: PropTypes.func,
    placeholder: PropTypes.string
}

export default FormIcon;