import React from 'react';
import Inventory from './Inventory';

const COMP_MR_xxxxxxE = () => {
    const table = 'structures_inventory';

    const items = [
        { label: 'PUNTO', description: 'Punto', value: 'point', type: 'Number', show: true, edit: true, add: true, width: 10, pdf: true, widthXLSX: 100 },
        { label: 'CADENAMIENTO', description: 'Kilometro inicial', value: 'stringing', type: 'String', allow: null, show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'TIPO', description: 'Tipo de Estructura', value: 'structure_type', type: 'String', allow: null, show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'METROS', description: 'Longitud en metros', value: 'long_meters', type: 'String', allow: null, show: true, edit: true, add: true, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'LATITUD', description: 'Coordenada', value: 'latitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'LONGITUD', description: 'Coordenada', value: 'longitude', type: 'Number', allow: null, edit: true, add: true, width: 15, widthXLSX: 100 },
        { label: 'FOTOGRAFIA', description: 'Fotografía', value: 'file_name', type: 'String', allow: null, width: 20, widthXLSX: 100 },
        { label: 'CONDICIONES', description: 'Cuerpo', value: 'conditions', type: 'String', allow: null, show: true, edit: true, add: true, width: 20, widthXLSX: 100 },
        { label: 'ENTIDAD', description: 'Observaciones', value: 'federal_entity', type: 'String', allow: null, edit: true, add: true, width: 20, widthXLSX: 100 },
        { label: 'Estado de Verificación', description: 'Estado de Verificación', value: 'check_status', type: 'String', allow: null, width: 20, pdf: true, widthXLSX: 100 },
        { label: 'Fecha de creación', description: 'Fecha de creación', value: 'created_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
        { label: 'Fecha de Actualización', description: 'Fecha de actualización', value: 'updated_at', type: 'Date', allow: null, width: 30, widthXLSX: 100 },
    ];

    const km = [{ index: 1 }];      // Arreglo de indices que irán con formato 000+000
    const percentage = [];          // Arreglo de indices que tendrán formato de porcentaje

    return (
        <Inventory
            table={table}
            items={items}
            km={km}
            percentage={percentage}
        />
    );
}

export default COMP_MR_xxxxxxE;