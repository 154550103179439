import React, { Fragment, useEffect, useState } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from 'components/layouts/ButtonIcon'
import ModalComp from 'components/layouts/Modal'
import { deleteFileAssets, downloadAssets, showAssets, uploadAssets } from 'api/operation_standards/assets';
import CarouselComponent from 'components/layouts/Carousel';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getKmFormat } from 'components/libs/functions';

const ModalPics = (props) => {
    const {
        onClose,
        asset,
        project_id,
        table,
        syncServer
    } = props;

    const [loading, setLoading] = useState(true);
    const [loadingUpl, setLoadingUpl] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [pictures, setPictures] = useState([]);
    const [filesUpl, setFilesUpl] = useState([]);
    const [count, setCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);


    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];

        const getAssetDetails = async () => {
            setPictures([]);
            setCount(0);
            setCurrentCount(0);
            let response = await showAssets({ table, project_id, id: asset?.id });
            if (response) {
                const { data } = response;
                setLoading(true);
                if (data.files) {
                    setCount(data.files.length);
                    for (const file of data.files) {
                        if (cancelLoop) break;
                        const { path, id } = file;
                        const { response, cancel } = await downloadAssets({ project_id, table, id: asset?.id, path });
                        cancelTokens.push({ cancel });
                        if (response) {
                            let blob_file = URL.createObjectURL(response);
                            setPictures(pictures => [...pictures, {
                                id,
                                image: blob_file,
                                created_at: `Evidencia capturada ${moment(file.created_at).format('LLLL')}`
                            }]);
                            setCurrentCount(currentCount => currentCount + 1);  // Contador del archivo obtenido
                            setLoading(false);
                        }
                    }
                }
                setLoading(false);
            }
        }

        getAssetDetails();

        return () => {
            cancelLoop = true;  // cancelar la petición del resto de las fotografías
            cancelTokens.forEach((token) => token.cancel()); // cancelar cada uno de los tokens creados
        }
    }, []);

    const deleteFile = async (item, index) => {
        const { id } = item;
        Swal.fire({
            title: '¿Está seguro que desea realizar esta operación?',
            text: "Al aceptar esta operación se eliminará el archivo de la base de datos. ¡Esta operación es irreversible!.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoadingDel(true);
                let response = await deleteFileAssets({ project_id, table, id, asset_id: asset.id });
                if (response) {
                    setPictures(prevState => prevState.filter(picture => picture.id !== id));
                    setCount(prevState => prevState - 1);
                    setCurrentCount(prevState => prevState - 1);
                    syncServer(); // Actualizar assets
                    Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
                } else
                    Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
                setLoadingDel(false);
            } else {
                Swal.fire('¡Operación abortada!', 'No se realizó ningún cambio.', 'warning')
            }
        })
    }
    // Método para subir el archivo
    const handleUploadClick = async () => {
        if (filesUpl.length === 0) {
            Swal.fire('¡Sin archivos!', 'Necesita elegir un archivo.', 'warning');
            return;
        }
        setLoadingUpl(true);
        let count = 0;
        for (const iterator of filesUpl) {
            const data = new FormData();
            data.append('file', iterator);
            const { id } = asset;
            let response = await uploadAssets({ project_id, table, data, id });
            if (response) {
                count++;
                const reader = new FileReader();
                // Asigna una función de callback para cuando se complete la lectura del archivo
                reader.onload = (e) => {
                    // Obtiene la URL de la vista previa
                    const image = e.target.result;
                    // Actualiza las fotografías del carousel con las fotografías obtenidas localmente
                    setPictures(prevState => [...prevState, { id: response[0], image, created_at: moment().format('LLLL') }]);
                    setCount(prevState => prevState + 1);
                    setCurrentCount(prevState => prevState + 1);
                };
                // Lee el contenido del archivo como una URL
                reader.readAsDataURL(iterator);
                syncServer(); // Actualizar assets
            }
        }
        switch (count) {
            case 0:
                Swal.fire('¡Operación fallida!', 'Hubo un error a tratar de agregar los archivos', 'error');
                break;
            case count < filesUpl.length:
                Swal.fire('¡Operación incompleta!', `Sólo ${count} archivos pudieron ser cargados.`, 'warning');
                break;
            default:
                Swal.fire('¡Operación exitosa!', `Los ${count} archivos fueron agregados al servidor`, 'success');
                break;
        }
        setLoadingUpl(false);
    };

    const handleLoadFile = (event) => {
        const files = event.target.files;
        setFilesUpl(files);
    }

    return (
        <ModalComp
            onClose={onClose}
            size={'lg'}
            title={'Cadenamiento ' + getKmFormat(asset?.km || '')}
            body={<Fragment>
                {
                    count > 0 &&
                    <p>Obteniendo {currentCount}/{count} fotografía{count === 1 ? '' : 's'}...</p>
                }
                <CarouselComponent
                    evidences={pictures}
                    loading={loading}
                    enableDel={true}
                    enableUpl={true}
                    handleLoadFile={handleLoadFile}
                    handleUploadClick={handleUploadClick}
                    handleDeleteThumbnail={deleteFile}
                    loadingDel={loadingDel}
                    loadingUpl={loadingUpl}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon
                    name='Cerrar'
                    onClick={onClose}
                    variant='outline-secondary'
                    icon={faTimes}
                />
            </Fragment>}
        />
    )
}

export default ModalPics