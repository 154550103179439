import fetcher from "api/fetcher";

const CREATED = 201;
const STATUS_OK = 200;
const url = `/maintenance_standard/activities`;

export const indexMaintenanceActivities = async (params) => {
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: maintenance_activities.js:14 ~ indexMaintenanceActivities ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeMaintenanceActivities = async ({ data }) => {
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = true
        return response;
    } catch (error) {
        return response;
    }
}

export const updateMaintenanceActivities = async ({ id, data }) => {
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}/${id}`,
            method: 'PUT',
            data
        });
        // console.log("🚀 ~ file: maintenance_activities.js:48 ~ updateMaintenanceActivities ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = true
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteMaintenanceActivities = async (props) => {
    const { id, project_id, maintenance_standard_id } = props;
    let response = false;
    try {
        let fetch = await fetcher({
            url: `${url}/${id}`,
            method: 'DELETE',
            params: { project_id, maintenance_standard_id }
        });
        if (fetch.status === STATUS_OK)
            response = true
        return response;
    } catch (error) {
        return response;
    }
}
