import { Progress } from '@chakra-ui/react'
import { Tooltip } from 'antd'
import React from 'react'

const ProgressBar = ({data, getColorBar}) => 
    <div className='my-3' style={{ width: '45%' }}>
        {
            data.map(bar =>
                <Tooltip title={`${bar.name} ${bar.deductive}%`} key={bar.name} className="my-2">
                    <p className='text-center'>{bar.name} {bar.deductive}%</p>
                    <Progress 
                        isAnimated={true} 
                        hasStripe={true} 
                        colorScheme={getColorBar(bar.deductive)} 
                        height='32px' 
                        value={bar.deductive} 
                    />
                </Tooltip>
            )
        }
    </div>

export default ProgressBar