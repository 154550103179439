import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { storeBaseLines } from 'api/operation_standards/assets_base_lines';
import ButtonIcon from 'components/layouts/ButtonIcon';
import FormIcon from 'components/layouts/FormIcon';
import ModalComp from 'components/layouts/Modal'
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

const AddBaseLine = (props) => {
    const { onClose, package_id, update } = props;
    const [data, setData] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    const validate = () => {
        let response = true;
        const fields = ['name', 'color', 'description'];
        for (const field of fields) {
            if (!data[field]) response = false;
        }
        return response;
    }
    const save = async () => {
        if (validate()) {
            let response = await storeBaseLines({ package_id, data });
            if (response) {
                Swal.fire('¡Operación exitosa!', 'La línea base ha sido almacenada de manera satisfactoria.', 'success');
                update();
                onClose();
            } else {
                Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de almarcenar la línea base. Intente más tarde.', 'error');
            }
        }else{
            Swal.fire('¡Formulario incompleto!', 'Verifique los datos del formulario.', 'warning');
        }
    }
    return (
        <ModalComp
            title='Agregar un tipo'
            size='md'
            onClose={onClose}
            body={<>
                <Row>
                    <FormIcon title='Nombre' name='name' value={data?.name} handleChange={handleChange} />
                </Row>
                <Row>
                    <FormIcon title='Color' name='color' value={data?.color} handleChange={handleChange} type='color' />
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Description</Form.Label>
                        <textarea
                            className='form-control'
                            name='description'
                            value={data?.description}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
            </>}
            footer={<>
                <ButtonIcon name='Cerrar' icon={faTimes} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name='Guardar' icon={faSave} variant='outline-success' onClick={save} />
            </>}
        />
    )
}

export default AddBaseLine