import React, { Component } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { __SERVER__ } from "server/info"
import Swal from "sweetalert2";
import axios from "axios";

const ModalTracing = class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			uploading: false,
			status: 0
		}
	}

	componentDidMount = () => {
		this.setState({ status: this.props.item.status });
		setTimeout(() => this.setState({ show: true }), 100);
	}

	handleClose = () => {
		if (this.state.uploading) {
			Swal.fire('Advertencia', 'La información sigue en proceso de carga, se le recomienda esperar un poco más.', 'info');
			return;
		}
		this.setState({ show: false }, () => setTimeout(this.props.onClose, 100));
	}

	handleChange = (e) => {
		this.setState({ status: e.target.value });
	}

	updateIncident = async (e) => {
		let { status } = this.state;
		let update = await axios.put(`${__SERVER__}/victum_users/victum_incidents/${this.props.item.id}`, { status });
		if (update.status === 200) {
			this.handleClose();
		}
	}

	render = () => {
		return (
			<Modal //Propiedades del modal
				animation={true}
				show={this.state.show}
				onHide={this.handleClose}
				size="md"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.item.type.name}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p><strong>Fecha de aviso: </strong>{this.props.item.report_date}</p>
					<Form>
						<select className={"form-control"} defaultChecked={this.state.status}
							onChange={this.handleChange}>
							<option value={0}>Creado</option>
							<option value={1}>Atendiendo</option>
							<option value={2}>Resuelta</option>
							<option value={3}>No Resuelta</option>
							<option value={4}>Catalogada como falsa</option>
						</select>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>Cancelar</Button>
					<Button variant="success" onClick={this.updateIncident}>Actualizar estado</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

export default ModalTracing