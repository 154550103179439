import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from './FAB';

export default class FAB extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            style: {
                width: this.props.size !== undefined ? this.props.size : 100,
                height: this.props.size !== undefined ? this.props.size : 100,
                borderRadius: this.props.size !== undefined ? this.props.size : 100,
                backgroundColor: this.props.bgColor !== undefined ? this.props.bgColor : '#1a2530',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                top: this.props.style !== undefined ? this.props.style.top !== undefined ? this.props.style.top :'none' : 'none',
                left: this.props.style !== undefined ? this.props.style.left !== undefined ? this.props.style.left :'none' : 'none',
                right: this.props.style !== undefined ? this.props.style.right !== undefined ? this.props.style.right :'none' : 'none',
                bottom: this.props.style !== undefined ? this.props.style.bottom !== undefined ? this.props.style.bottom :'none' : 'none',
                position: this.props.style !== undefined ? this.props.style.position !== undefined ? this.props.style.position : 'initial' : 'initial'
            },
            color: this.props.color !== undefined ? this.props.color : '#FFF',
            icon: this.props.icon !== undefined ? icons[this.props.icon] !== undefined ? icons[this.props.icon] : null : null
        }
    }

    onClick = (e) => {}

    render = () => {
        return(
            <div style={this.state.style} onClick={this.props.onClick !== undefined ? this.props.onClick : this.onClick}>
                {this.state.icon !== null ? 
                    <FontAwesomeIcon icon={this.state.icon} color={this.state.color} size="1x" />
                : null}
            </div>
        );
    }
}

FAB.propTypes = {
    size: PropTypes.number,
    bgColor: PropTypes.string,
    style: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
        bottom: PropTypes.number,
        position: PropTypes.oneOf(['-moz-initial', '-webkit-sticky', 'absolute', 'fixed', 'inherit', 'initial', 'relative', 'revert', 'static', 'sticky', 'unset'])
    }),
    icon: PropTypes.oneOf(Object.keys(icons)),
    color: PropTypes.string
};

//isRequired