import React from 'react'
import TabsComp from 'components/layouts/Tabs'
import List from './List'
import Dashboard from '../Proyectos/Mantemiento/Dashboard'

const AlertsComp = ({ project_id }) => {

	return (
		<div className="body_">
			<h1 className="tituloSub">Alerta de Incidentes</h1>
			<TabsComp
				tablist={['Registro', 'Dashboard']}
				tabpanels={[
					<List key='0' project_id={project_id} />,
					<Dashboard key='1' project_id={project_id} component='maintenance_alerts' />
				]}
			/>
		</div>
	)
}

export default AlertsComp